<template>
    <main class="flex md:justify-center md:items-center min-h-screen">
        <div class="md:shadow-2xl">
            <h1 class="flex bg-green-500 font-bold text-white mb-2 p-2">
                <icon-check class="mx-2" />
                {{ $t('user.confirmedCreation') }}
            </h1>
            <div class="p-2">
                {{ $t('user.mailSent') }}
            </div>
            <router-link class="flex justify-end p-2 underline text-sm" :to="{ name: 'login', query: $route.query }">
                {{ $t('user.goLogin') }}
            </router-link>
        </div>
    </main>
</template>
<script>
import IconCheck from '@/icons/IconCheck';
export default {
    components: { IconCheck },
};
</script>
