<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full flex flex-col gap-2">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="newContract()" :label="$t('contracts.newContract')" />
            </div>
            <app-filter ref="filter" v-model="filter"></app-filter>
            <div class="flex justify-between">
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="active"
                        :label="$t('contracts.active')"
                        @input="saveFilter"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="waiting"
                        :label="$t('contracts.waiting')"
                        @input="saveFilter"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="expired"
                        :label="$t('contracts.expired')"
                        @input="saveFilter"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 13rem" class="text-left border-r p-1">
                            <button class="font-bold w-full flex justify-between">
                                {{ $t('contracts.code') }}
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('createdAt')"
                            >
                                {{ $t('contracts.validatedAt') }}
                                <div v-if="sortKey === 'validatedAt'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('expireAt')"
                            >
                                <span>{{ $t('contracts.expireAt') }}</span>
                                <div v-if="sortKey === 'expireAt'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('contractor')"
                            >
                                {{ $t('contracts.contractor') }}
                                <div v-if="sortKey === 'contractor'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('billingName')"
                            >
                                {{ $t('contracts.billingName') }}
                                <div v-if="sortKey === 'billingName'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('status')"
                            >
                                {{ $t('contracts.status') }}
                                <div v-if="sortKey === 'status'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="contract in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 underline">
                                <router-link
                                    :to="{
                                        name: 'contract',
                                        params: {
                                            contractId: contract.id,
                                        },
                                    }"
                                >
                                    {{ contract.code }}
                                </router-link>
                            </td>
                            <td class="border-r p-1">
                                {{ contract.validatedAt | humanizeDate }}
                            </td>
                            <td
                                class="border-r p-1"
                                :class="{
                                    'text-red-600': contract.expireAt && contract.expireAt.getTime() < Date.now(),
                                }"
                            >
                                {{ contract.expireAt | humanizeDate }}
                            </td>
                            <td class="border-r p-1">
                                {{ contract.contractor.email }}
                            </td>
                            <td class="border-r p-1">
                                {{ contract.billingName }}
                                <span v-if="contract.billingCity">({{ contract.billingCity }})</span>
                            </td>
                            <td class="border-r p-1">
                                <span v-if="contract.expireAt && contract.expireAt.getTime() < Date.now()">
                                    {{ $t('contracts.expired') }}
                                </span>
                                <span v-else-if="contract.validatedAt">{{ $t('contracts.active') }}</span>
                                <span v-else>{{ $t('contracts.waiting') }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-popup ref="newContract" :showHeader="true" :title="$t('contracts.newContract')">
            <div class="p-2 flex flex-col">
                <app-input-text v-model="email" :required="true" :label="$t('commons.email')"></app-input-text>
                <app-footer
                    @click="createContract()"
                    :disabled="email.length === 0"
                    class="mt-2"
                    :label="$t('contracts.initContract')"
                ></app-footer>
            </div>
        </app-popup>
    </main>
</template>

<script>
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import { createContract, getContracts } from './contracts.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppFooter from '@/components/appFooter/AppFooter';
import AppButton from '@/components/appButton/AppButton';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppFilter from '@/components/appFilter/AppFilter.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import { error } from '@/features/toasts/toats.service';

export default {
    components: {
        AppCheckbox,
        AppFilter,
        AppInputText,
        AppSelect,
        AppFooter,
        AppPopup,
        AppButton,
    },
    async created() {
        this.restoreFilter();
        this.contracts = await getContracts();
        this.loading = false;
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filter);
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'validatedAt') {
                        return item.validatedAt;
                    } else if (this.sortKey === 'expireAt') {
                        return item.expireAt;
                    } else if (this.sortKey === 'contractor') {
                        return item.contractor.email;
                    } else if (this.sortKey === 'billingName') {
                        return item.billingName;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
    },
    methods: {
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
        },
        saveFilter() {
            localStorage.setItem(
                'contracts_filter',
                JSON.stringify({
                    filter: this.filter,
                    sortAsc: this.sortAsc,
                    sortKey: this.sortKey,
                    waiting: this.waiting,
                    active: this.active,
                    expired: this.expired,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('contracts_filter');
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.sortAsc = !!parsedCache.sortAsc;
                this.sortKey = parsedCache.sortKey || 'validatedAt';
                this.filter = parsedCache.filter || '';
                this.waiting = !!parsedCache.waiting;
                this.active = !!parsedCache.active;
                this.expired = !!parsedCache.expired;
            }
        },
        async createContract() {
            try {
                const result = await createContract(this.email);
                await this.$router.push({
                    name: 'contract',
                    params: {
                        contractId: result.id,
                    },
                });
            } catch (e) {
                error(this.$t('contracts.unknownEmail'));
            }
        },
        filterFn(filter) {
            this.saveFilter();
            return this.contracts.filter((item) => {
                const filterString = [item.contractor.email, item.billingName, item.code, item.billingCity].join();
                const isExpired = this.isExpired(item);
                return (
                    filterMatch(filterString, filter, true) &&
                    (isExpired
                        ? this.expired
                        : (this.active && item.validatedAt) || (this.waiting && !item.validatedAt))
                );
            });
        },
        newContract() {
            this.$refs.newContract.open();
        },
        isExpired(contract) {
            return contract.expireAt && contract.expireAt.getTime() < Date.now();
        },
    },
    data() {
        return {
            filter: '',
            waiting: true,
            active: true,
            email: '',
            expired: false,
            sortKey: 'validatedAt',
            sortAsc: false,
            loading: true,
            readOnly: true,
            contracts: [],
        };
    },
};
</script>
