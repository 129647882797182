var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"page-break-before":"always"}},[_c('app-separator',{attrs:{"tag":"h1","label":_vm.$t('project.editions.meetingReports.generalProgress')}}),_c('div',{staticClass:"mx-16 mt-5 grid gap-2 items-center",style:({
            'max-height': '900px',
            height: '900px',
            'grid-template-columns': _vm.meeting.attachments.length <= 3 ? '1fr' : '1fr 1fr',
            'grid-template-rows':
                _vm.meeting.attachments.length <= 1
                    ? '1fr'
                    : _vm.meeting.attachments.length === 2
                    ? '1fr 1fr'
                    : _vm.meeting.attachments.length === 3
                    ? '1fr 1fr 1fr'
                    : 'repeat(' + (Math.floor(_vm.meeting.attachments.length / 2) + 1) + ', 1fr)',
        })},[_vm._l((_vm.meeting.attachments),function(attachment,index){return [_c('div',{staticClass:"w-full h-full bg-contain bg-no-repeat bg-center",class:{
                    'col-span-2':
                        _vm.meeting.attachments.length > 3 &&
                        _vm.meeting.attachments.length % 2 === 1 &&
                        index === _vm.meeting.attachments.length - 1,
                },style:('background-image: url(' + attachment.url + ')')})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }