<template>
    <main class="max-w-5xl p-2 md:p-5 w-2/3 w-full h-full max-h-main flex flex-col items-start">
        <div class="flex items-center w-full">
            <div class="flex flex-col items-center gap-2 justify-center w-full">
                <h1 class="text-lg flex-grow font-bold whitespace-nowrap hidden sm:block">
                    {{ task.service.name }}
                </h1>
                <router-link
                    :to="{
                        name: 'planning',
                        params: { projectId: $route.params.projectId },
                        query: {
                            selectedItemId: task.id,
                            minDate: (task.realStartDate || task.startDate || new Date())
                                .toISOString()
                                .substring(0, 10),
                        },
                    }"
                    class="text-xs underline hidden sm:block"
                >
                    {{ $t('project.follow.toPlanning') }}
                </router-link>
            </div>
        </div>
        <div class="w-full">
            <table>
                <tr>
                    <th scope="row" class="text-right p-2">{{ $t('commons.bundle') }} :</th>
                    <td class="p-2">
                        <span>#{{ task.service.bundle.label }}</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="p-2 flex items-end justify-end">{{ $t('services.locations') }}&nbsp;:</th>
                    <td class="p-2">{{ task.location.fullName }}</td>
                </tr>
                <tr>
                    <th scope="row" class="p-2 flex items-end justify-end">
                        {{ $t('project.follow.follow.planned') }}&nbsp;:
                    </th>
                    <td class="p-2">
                        {{ task.startDate | humanizeDate }}
                        +
                        {{ task.duration }} {{ $t('commons.dayUnit') }} = {{ task.endDate | humanizeDate }}
                    </td>
                    <td class="p-2">
                        <div class="flex gap-1 justify-center" :title="$t('project.follow.planning.relativeLateTips')">
                            <span class="font-bold text-red-700" v-if="task.late > 0">J+{{ task.late }}</span>
                            <span class="font-bold text-green-600" v-else-if="task.late === 0">0</span>
                            <span class="font-bold text-green-600" v-else-if="task.late <= 0">J{{ task.late }}</span>
                        </div>
                    </td>
                </tr>
                <tr v-if="task.realStartDate">
                    <th scope="row" class="p-2 flex items-end justify-end">
                        {{ $t('project.follow.follow.real') }}&nbsp;:
                    </th>
                    <td class="p-2">
                        {{ task.realStartDate | humanizeDate }}
                        <span :class="{ 'text-gray-500 italic': !task.realEndDate }">
                            +
                            {{ task.duration }} {{ $t('commons.dayUnit') }} =
                            <span>{{ (task.realEndDate || task.estimatedEndDate) | humanizeDate }}</span>
                        </span>
                    </td>
                    <td class="p-2">
                        <div class="flex gap-1 justify-center" :title="$t('project.follow.planning.criticalLateTips')">
                            <span class="font-bold text-red-700" v-if="task.criticalLate > 0">
                                J+{{ task.criticalLate }}
                            </span>
                            <span v-else-if="task.criticalLate === 0" class="font-bold text-green-600">0</span>
                            <span v-else-if="task.criticalLate <= 0" class="font-bold text-green-600">
                                J{{ task.criticalLate }}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th scope="row" class="p-2 flex items-end justify-end">
                        {{ $t('project.follow.planning.reference') }}&nbsp;:
                    </th>
                    <td class="p-2">
                        {{ task.referenceStartDate | humanizeDate }}
                        <span>
                            +
                            {{ task.referenceDuration }} {{ $t('commons.dayUnit') }} =
                            <span>{{ task.referenceEndDate | humanizeDate }}</span>
                        </span>
                    </td>
                    <td class="p-2">
                        <div class="flex gap-1 justify-center" :title="$t('project.follow.planning.absoluteLateTips')">
                            <span class="font-bold text-red-700" v-if="task.referenceLate > 0">
                                J+{{ task.referenceLate }}
                            </span>
                            <span v-else-if="task.referenceLate === 0" class="font-bold text-green-600">0</span>
                            <span
                                v-else-if="task.referenceLate <= 0"
                                class="font-bold text-green-600 whitespace-no-wrap"
                            >
                                J{{ task.referenceLate }}
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer" class="w-full">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-5" :label="$t('commons.status')">
                    <app-date-input
                        class="col-span-2 sm:col-span-1"
                        v-model="realStartDate"
                        :required="true"
                        :label="$t('project.follow.planning.startDate')"
                    />
                    <app-date-input
                        class="col-span-2 sm:col-span-1"
                        v-model="realEndDate"
                        :label="$t('project.follow.planning.endDate')"
                        @input="onEndDate"
                        :required="progress === 100"
                    />
                    <div class="flex flex-col items-start col-span-2 sm:col-span-1">
                        <app-number-input
                            class="w-full mb-1"
                            v-model="progress"
                            :showTips="false"
                            format="integer"
                            rules="numeric|min_value:0|max_value:100"
                            :label="$t('project.follow.planning.progress') + ' (%)'"
                            :disabled="!!realEndDate"
                        />
                        <app-slider
                            v-model="progress"
                            :show-label="false"
                            :label="$t('project.follow.planning.progress') + ' (%)'"
                            :disabled="!!realEndDate"
                        ></app-slider>
                    </div>
                </app-fieldset>

                <app-footer
                    :ok-label="$t('project.follow.updateTask')"
                    @click="save()"
                    :disabled="invalid"
                    class="p-2"
                ></app-footer>
                <AppWarnOnLeave :errors="errors" :dirty="dirty" :invalid="invalid" ref="warnOnLeave" />
            </form>
        </ValidationObserver>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput';
import AppSlider from '@/components/appSlider/AppSlider';
import AppFooter from '@/components/appFooter/AppFooter';
import AppWarnOnLeave from '@/components/AppWarnOnLeave';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { updateTask } from '@/features/tasks/tasks.service';
import { BehaviorSubject } from 'rxjs';
export default {
    components: { AppWarnOnLeave, AppFooter, AppSlider, AppNumberInput, AppDateInput, AppFieldset },
    async created() {
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((calendar) => (this.agenda = calendar)),
            getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())).subscribe((tasks) => {
                this.tasks = tasks;
                this.init();
            }),
        ];
    },
    watch: {
        $route() {
            this.init();
        },
    },
    methods: {
        init() {
            const task = this.tasks.find((task) => task.id === this.$route.params.taskId);
            this.progress = task.progress;
            this.realEndDate = task.realEndDate;
            this.realStartDate = task.realStartDate;
            updateBreadCrumbs({ taskName: task.name });
            localStorage.setItem(
                'planning.lastVisitedTaskId.' + this.$route.params.projectId,
                this.$route.params.taskId,
            );
            this.task = task;
        },
        onEndDate(value) {
            if (value) {
                this.progress = 100;
            } else {
                this.progress = this.task.progress;
            }
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.warnOnLeave.beforeRouteLeave(
                () => this.save(true),
                () => this.init(),
                next,
            );
        },
        async save() {
            let taskEntity = {
                id: this.task.id,
                realStartDate: this.realStartDate,
                realEndDate: this.realEndDate,
                progress: this.progress,
            };
            await updateTask(this.$route.params.projectId, taskEntity);
        },
    },
    data() {
        return {
            subscriptions: [],
            tasks: [],
            agenda: [],
            realStartDate: null,
            realEndDate: null,
            progress: 0,
            task: {
                service: { bundle: {} },
                location: { folder: {} },
            },
        };
    },
};
</script>
