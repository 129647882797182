<template>
    <div>
        <div class="flex mx-2">
            <app-button
                size="mini"
                class="p-2 shadow"
                @click="previous()"
                :aria-label="$t('project.follow.planning.previous')"
                :title="$t('project.follow.planning.previous')"
                icon="icon-chevron-left"
            />
            <div class="flex flex-grow items-center justify-center text-2xl font-bold">
                {{ value.getFullYear ? value.getFullYear() : '' }}
            </div>
            <app-button
                size="mini"
                class="p-2 shadow"
                @click="next()"
                :aria-label="$t('project.follow.planning.next')"
                :title="$t('project.follow.planning.next')"
                icon="icon-chevron-right"
            />
        </div>
        <div class="flex flex-row flex-wrap justify-center text-xs mb-5">
            <template v-for="month in months">
                <app-month
                    :month="month"
                    :key="month.getUTCMonth()"
                    :dayClass="dayClassMap"
                    @dayClick="$emit('dayClick', $event)"
                    class="m-2"
                ></app-month>
            </template>
        </div>
        <div class="flex gap-3 flex-wrap justify-around md:justify-between mb-2" v-if="showLegend">
            <div>
                <span class="border p-1 text-xs">10</span>
                {{ $t('project.follow.agenda.officialWorkingDay') }}
            </div>
            <div>
                <span class="border p-1 text-xs bg-gray-300">10</span>
                {{ $t('project.follow.agenda.officialOffDay') }}
            </div>
            <div>
                <span class="border p-1 text-xs bg-gray-300" style="border-bottom: red solid">10</span>
                {{ $t('project.follow.agenda.weatherIssue') }}
            </div>
            <div>
                <span class="border p-1 text-xs bg-gray-300 font-bold">10</span>
                {{ $t('project.follow.agenda.customOffDay') }}
            </div>
        </div>
    </div>
</template>
<script>
import AppMonth from './AppMonth';
import AppButton from '../appButton/AppButton';
import calendar from './calendar';
import { getMapBy } from '@/services/sanitize.service';
export default {
    components: { AppButton, AppMonth },
    props: {
        value: {
            type: Date,
            default: () => {
                const date = new Date();
                date.setDate(2);
                date.setHours(12, 0, 0, 0);
                return date;
            },
        },
        calendar: { type: Array, default: () => [] },
        showLegend: { type: Boolean, default: true },
    },
    computed: {
        months() {
            return calendar.getMonthsInYear(this.value);
        },
        dayClassMap() {
            return calendar.getDaysStyle(this.value, this.exceptions);
        },
        exceptions() {
            return getMapBy(this.calendar, 'time');
        },
    },
    methods: {
        previous() {
            this.$emit('input', new Date(this.value.getFullYear() - 1, 1, 1));
        },
        next() {
            this.$emit('input', new Date(this.value.getFullYear() + 1, 1, 1));
        },
    },
};
</script>
