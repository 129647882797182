import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapPreparationAttachment(item) {
    if (item) {
        return item.toMutableJSON();
    } else {
        return null;
    }
}

export function getPreparationAttachments(projectId, preparationId) {
    return db
        .getProjectCollections(projectId)
        .preparationAttachments.find({ selector: { preparationId } })
        .$.pipe(map((preparationAttachments) => preparationAttachments.map(mapPreparationAttachment)));
}
export function getPreparationAttachment(projectId, preparationAttachmentId) {
    return db
        .getProjectCollections(projectId)
        .preparationAttachments.findOne({ selector: { id: preparationAttachmentId } })
        .$.pipe(map(mapPreparationAttachment));
}

export function removePreparationAttachment(projectId, preparationAttachmentId) {
    return db
        .getProjectCollections(projectId)
        .preparationAttachments.findOne({ selector: { id: preparationAttachmentId } })
        .remove();
}

export async function updatePreparationAttachment(projectId, preparationAttachment) {
    const dbPreparation = await db
        .getProjectCollections(projectId)
        .preparationAttachments.findOne({ selector: { id: preparationAttachment.id } })
        .exec();
    return dbPreparation.atomicPatch({ rotation: preparationAttachment.rotation });
}
