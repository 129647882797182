<template>
    <app-input-text
        :rules="rules"
        :disabled="disabled"
        :required="required"
        :label="label"
        :uid="uid"
        :value="internalValue"
        @input="onInput($event)"
        @blur="$emit('blur', $event)"
        @keypress="$emit('keypress', $event)"
        @keyup="$emit('keyup', $event)"
        :showErrors="showErrors"
        :showLabel="showLabel"
        type="time"
        step="900"
    >
        <template slot="companion"><slot name="companion"></slot></template>
        <template slot="tip"><slot name="tip"></slot></template>
        <template slot="link"><slot name="link"></slot></template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';
import format from 'date-fns/format';

export default {
    components: { AppInputText },
    created() {
        this.refreshInternalValue();
    },
    watch: {
        value(value) {
            this.refreshInternalValue();
        },
    },
    methods: {
        refreshInternalValue() {
            if (this.value) {
                this.internalValue = format(this.value, 'HH:mm');
            }
        },
        onInput(value) {
            if (value) {
                const result = new Date(this.value);
                result.setHours(value.substring(0, 2), value.substring(3, 5));
                this.$emit('input', result);
            }
        },
    },
    data() {
        return { internalValue: null };
    },
    props: {
        rules: String,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: Date,
        uid: String | Number,
        showTips: {
            type: Boolean,
            default: true,
        },
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
