<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full flex">
            <div v-if="isAdmin">
                <app-select @input="onAction" :disabled="selection.length === 0 || loading" class="text-xs">
                    <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                    <option value="delete">{{ $t('commons.actionDelete') }}</option>
                </app-select>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <table class="table-fixed">
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="isAdmin">
                            <app-checkbox
                                :disabled="loading"
                                :value="selection.length === planningExports.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < planningExports.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 6rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('date')"
                            >
                                {{ 'Date' }}
                                <div v-if="sortKey === 'date'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: auto" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                {{ $t('commons.name') }}
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('minDate')"
                            >
                                {{ $t('commons.from') }}
                                <div v-if="sortKey === 'minDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('maxDate')"
                            >
                                {{ $t('commons.to') }}
                                <div v-if="sortKey === 'maxDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <icon-file-pdf-outline whidth="16" height="16" />
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="isAdmin">
                            <icon-email-outline whidth="16" height="16" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="planningExport in filteredPlanningExports">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="isAdmin">
                                <a :id="'uuid_' + planningExport.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="planningExport.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(planningExport)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-center">
                                    {{ planningExport.createdAt | humanizeDateTimeShort }}
                                </div>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <div class="flex justify-start">
                                    <span>{{ planningExport.name }}</span>
                                </div>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <div class="flex justify-center">
                                    {{ planningExport.minDate | humanizeDate }}
                                </div>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <div class="flex justify-center">
                                    {{ planningExport.maxDate | humanizeDate }}
                                </div>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <app-file-link
                                    :url="planningExport.url"
                                    v-if="planningExport.generationEndDate"
                                ></app-file-link>
                                <icon-rotate-right v-else width="16" height="16" class="animate animate-spin" />
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell" v-if="isAdmin">
                                <app-button
                                    v-if="planningExport.generationEndDate"
                                    size="mini"
                                    icon="icon-email-edit-outline"
                                    @click="$refs.emailPopup.open(planningExport)"
                                ></app-button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <planning-export-mail-popup ref="emailPopup"></planning-export-mail-popup>
        </div>
    </main>
</template>

<script>
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import AppSelect from '@/components/appSelect/AppSelect';
import { confirm } from '@/features/dialogs/dialogs.service';
import IconMenuDown from '@/icons/IconMenuDown';
import IconMenuUp from '@/icons/IconMenuUp';
import IconFilePdfOutline from '@/icons/IconFilePdfOutline';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { getPlanningExports, removePlanningExports } from '@/features/planning/planning/planningExport.service';
import IconEmailOutline from '@/icons/IconEmailOutline.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import { queryProject } from '@/features/projects/projects.service';
import PlanningExportMailPopup from '@/features/planningExports/PlanningExportMailPopup.vue';
import { sortBy } from '@/services/sanitize.service';

export default {
    components: {
        PlanningExportMailPopup,
        AppButton,
        IconEmailOutline,
        IconMenuDown,
        IconMenuUp,
        AppSelect,
        AppFileLink,
        IconFilePdfOutline,
        AppCheckbox,
    },
    async created() {
        this.restoreSelection();
        this.subscriptions = [
            queryProject(this.$route.params.projectId).then((project) => {
                this.isAdmin = project.me.allowedFeatures.includes('project_planning');
                this.me = project.me;
            }),
            getPlanningExports(this.$route.params.projectId).subscribe((planningExports) => {
                this.planningExports = planningExports;
                this.loading = false;
            }),
        ];
    },
    computed: {
        filteredPlanningExports() {
            let result = this.planningExports.map((planningExport) => ({
                ...planningExport,
                isSelected: this.selection.includes(planningExport.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, this.sortKey);
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
    },
    methods: {
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
        },
        async onAction() {
            const selectedItems = this.planningExports
                .filter((item) => this.selection.includes(item.id))
                .map((item) => item.id);

            if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                await removePlanningExports(this.$route.params.projectId, selectedItems);
                this.selection = [];
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredPlanningExports.length) {
                this.selection = this.filteredPlanningExports.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'planningExports.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('planningExports.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            sortKey: 'name',
            sortAsc: true,
            selection: [],
            planningExports: [],
            loading: true,
            isAdmin: false,
            me: null,
        };
    },
};
</script>
