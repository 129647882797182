<template>
    <l-rectangle
        :color="shape.style.color"
        :l-style="shape.style"
        :fill="true"
        :fillOpacity="0"
        :options="{ interactive: true }"
        :weight="weight"
        :bounds="shape.bounds"
        @click="$emit('click', $event)"
        @editable:dragend="onEditEnd"
        @editable:vertex:dragend="onEditEnd"
        ref="rectangle"
    ></l-rectangle>
</template>

<script>
import { LRectangle } from 'vue2-leaflet';
export default {
    components: { LRectangle },
    props: {
        shape: {
            type: Object,
            default: () => ({}),
        },
        weight: {
            type: Number,
            default: 3,
        },
    },
    watch: {
        shape() {
            if (this.$refs.rectangle.mapObject.editEnabled()) {
                this.$refs.rectangle.mapObject.disableEdit();
                this.$nextTick(() => this.$refs.rectangle.mapObject.enableEdit());
            }
        },
    },
    methods: {
        onEditEnd() {
            const latLngs = this.$refs.rectangle.mapObject.getLatLngs();
            this.$emit('updateX1Y1X2Y2', {
                x1: latLngs[0][0].lng,
                x2: latLngs[0][2].lng,
                y1: latLngs[0][0].lat,
                y2: latLngs[0][2].lat,
            });
        },
        select() {
            this.$emit('click', { target: this.$refs.rectangle.mapObject });
        },
    },
};
</script>
