<template>
    <ValidationProvider v-slot="{ errors, classes }">
        <app-picker
            ref="picker"
            :value="value"
            :options="options"
            :show-label="showLabel"
            :label="label"
            :title="pickerTitle"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
            :label-function="(bundle) => (bundle.reference ? bundle.reference + ' - ' + bundle.name : bundle.name)"
            :filterStringFunction="
                (bundle) => bundle.reference + ' ' + bundle.name + ' ' + (bundle.company ? bundle.company.name : '')
            "
            :placeholder="placeholder"
            :required="required"
            :disabled="disabled"
        >
            <template v-slot:tip v-if="showTip">
                <div class="text-xs flex">
                    <div v-if="!value"></div>
                    <template v-else-if="get(value, 'company.id')">
                        <span>{{ companyLabel }} :</span>
                        <router-link
                            class="mx-2 text-xs underline"
                            :to="{
                                name: 'company',
                                params: {
                                    companyId: get(value, 'company.id', ''),
                                    projectId: $route.params.projectId,
                                },
                            }"
                        >
                            {{ get(value, 'company.name', '') }}
                        </router-link>
                    </template>
                    <router-link
                        v-else-if="value.id"
                        :to="{
                            name: 'bundle',
                            params: {
                                bundleId: value.id,
                                projectId: $route.params.projectId,
                            },
                        }"
                    >
                        <span class="mx-2 underline italic text-xs">
                            {{ emptyLabel }}
                        </span>
                    </router-link>
                </div>
            </template>
            <template v-slot:option="{ option }">
                <app-bundle :bundle="option" class="p-2" />
            </template>
            <template v-slot:footer>
                <slot name="footer"></slot>
            </template>
        </app-picker>
        <app-errors :errors="errors" />
    </ValidationProvider>
</template>
<script>
import AppPicker from './appPicker/AppPicker';
import AppErrors from './appErrors/AppErrors';
import { get } from '@/services/sanitize.service';
import AppBundle from '@/components/app-bundle/appBundle';

export default {
    components: { AppBundle, AppErrors, AppPicker },
    props: {
        disabled: { type: Boolean, default: false },
        showLabel: { type: Boolean, default: true },
        showTip: { type: Boolean, default: true },
        value: Object,
        required: Boolean,
        companyLabel: {
            type: String,
            default: function () {
                return this.$t('bundles.emitter');
            },
        },
        pickerTitle: {
            type: String,
            default: function () {
                return this.$t('bundles.chooseBundle');
            },
        },
        label: {
            type: String,
            default: function () {
                return this.$t('bundles.emitter');
            },
        },
        emptyLabel: {
            type: String,
            default: '',
        },
        placeholder: String,
        options: Array,
    },
    methods: {
        get,
        open() {
            this.$refs.picker.open();
        },
    },
};
</script>
