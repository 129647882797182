<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <AutoControlForm ref="form" v-if="isAdmin" />
        <read-only-auto-control v-else></read-only-auto-control>
        <app-separator :label="$t('observations.title')" class="mt-4"></app-separator>
        <div class="flex flex-col gap-2 w-full p-5">
            <div class="flex">
                <router-link
                    class="text-sm flex font-bold hover:border-black border-gray-300 items-center disabled:opacity-50 focus:outline-none focus:ring py-2 px-4 border"
                    :to="{
                        name: 'followAutoControlObservations',
                        params: {
                            projectId: this.$route.params.projectId,
                            autoControlId: this.$route.params.autoControlId,
                        },
                    }"
                >
                    {{ $t('autoControls.consultObservations') }}
                </router-link>
            </div>
            <FollowObservationStats class="w-full" :items="observations" :bundles="bundles" />
        </div>
        <app-separator class="col-span-2 mt-4" :label="$t('autoControls.report')" v-if="observationsReport" />
        <observations-report-files
            v-if="observationsReport"
            :observationsReport="observationsReport"
            :readOnly="!isAdmin && !isEmitter"
            :observations="observations"
            :showGenerateAPDFByBundleOption="false"
            @generatePDF="onGeneratePDF"
        ></observations-report-files>
        <app-quick-actions :options="quickActions" @choose="$event.run()" v-if="isAdmin"></app-quick-actions>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AutoControlForm from './AutoControlForm';
import { createAutoControl, getAutoControl, updateAutoControl } from './autoControls.service';
import ReadOnlyAutoControl from '@/features/autoControls/ReadOnlyAutoControl';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getObservationsForRelatedContent } from '@/features/observations/observation.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { uniq } from '@/services/sanitize.service';
import FollowObservationStats from '@/features/follow/FollowObservationStats.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { getObservationsReports } from '@/features/observationsReports/observationsReports.service';
import ObservationsReportFiles from '@/features/observationsReports/ObservationsReportFiles.vue';
export default {
    components: {
        ObservationsReportFiles,
        AppSeparator,
        AppButton,
        FollowObservationStats,
        ReadOnlyAutoControl,
        AutoControlForm,
        AppQuickActions,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        onGeneratePDF() {
            if (!this.autoControl.emissionDate) {
                updateAutoControl(this.$route.params.projectId, { id: this.autoControl.id, emissionDate: new Date() });
            }
        },
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getAutoControl(this.$route.params.projectId, this.$route.params.autoControlId),
                    getObservationsForRelatedContent(this.$route.params.projectId, this.$route.params.autoControlId),
                    getBundleMap(this.$route.params.projectId),
                    getObservationsReports(this.$route.params.projectId, this.$route.params.autoControlId),
                ]).subscribe(([project, autoControl, observations, bundleMap, observationsReports]) => {
                    this.observations = observations;
                    this.autoControl = autoControl;
                    this.isAdmin = project.me.allowedFeatures.includes('project_autoControls');
                    this.isEmitter = project.me.bundleIds.includes(autoControl.bundleId);
                    this.bundles = uniq(
                        observations.reduce((acc, observation) => [...acc, ...observation.recipientIds], []),
                    )
                        .map((id) => bundleMap[id])
                        .filter((a) => !!a);
                    this.observationsReport =
                        observationsReports.length > 0
                            ? {
                                  ...observationsReports[0],
                                  name:
                                      this.autoControl.name !== this.$t('autoControls.newName')
                                          ? this.$t('autoControls.newName') + ' ' + this.autoControl.name
                                          : this.autoControl.name,
                                  date: this.autoControl.emissionDate ? this.autoControl.emissionDate : new Date(),
                              }
                            : null;
                    updateBreadCrumbs({ autoControlName: autoControl.name });
                }),
            ];
        },
    },
    data() {
        return {
            observationsReport: null,
            subscriptions: [],
            observations: [],
            bundles: [],
            autoControl: {},
            isEmitter: false,
            isAdmin: false,
            quickActions: [
                {
                    name: this.$t('autoControls.newAutoControl'),
                    run: async () => {
                        const autoControl = await createAutoControl(this.$route.params.projectId, {
                            name: this.$t('autoControls.newAutoControl'),
                        });
                        await this.$router.push({
                            name: 'followAutoControl',
                            params: {
                                ...this.$route.params,
                                autoControlId: autoControl.id,
                            },
                        });
                    },
                },
                {
                    name: this.$t('observations.newObservation'),
                    run: () => this.$refs.observations.onNewObservation(),
                },
            ],
        };
    },
};
</script>
