import VueI18n from 'vue-i18n';
import frFR from '../translations/fr_FR.json';
const messages = {
    'fr-fr': frFR,
};
class i18nService {
    init() {
        return new VueI18n({
            locale: 'fr-fr',
            messages,
            numberFormats: messages,
        });
    }
}
export default new i18nService();
