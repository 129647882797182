<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addRequest()" :label="$t('project.follow.requests.newRequest')" v-if="!readOnly" />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                    <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                    <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    <option value="duplicate" v-if="selection.length === 1">{{ $t('commons.actionDuplicate') }}</option>
                </app-select>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('index')"
                            >
                                #
                                <div v-if="sortKey === 'index'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('project.follow.requests.description') }}</span>
                        </th>
                        <th style="width: 30%" class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('zone')"
                            >
                                <span>{{ $t('project.follow.observation.zone') }}</span>
                                <div v-if="sortKey === 'zone'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="request in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + request.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="request.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(request)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ request.index | index }}
                            </td>
                            <td class="border-r p-1">
                                <router-link
                                    class="hover:underline"
                                    :to="{
                                        name: 'request',
                                        params: {
                                            ...$route.params,
                                            requestId: request.id,
                                        },
                                    }"
                                >
                                    <app-clamp :htmlContent="request.description" :number-of-lines="2" />
                                    <span v-if="!request.description || request.description.trim().length === 0">
                                        ...
                                    </span>
                                </router-link>
                            </td>
                            <td class="border-r p-1">
                                <span v-if="request.zone">{{ request.zone.fullName }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';
import { createRequest, getRequests, removeRequest } from '@/features/requests/requests.service';
import AppClamp from '@/components/appClamp/AppClamp.vue';
import { combineLatest } from 'rxjs';
import { getObservations } from '@/features/observations/observation.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { index } from '@/filters/indexFilter';

export default {
    components: {
        AppClamp,
        AppSelect,
        AppQuickActions,
        AppCheckbox,
        AppMultiPicker,
        AppButton,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        this.restoreSort();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly =
                !project.me.allowedFeatures.includes('project_requests') &&
                !project.me.allowedFeatures.includes('project_isDeliveryContact');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'index') {
                        return item.index;
                    } else if (this.sortKey === 'zone') {
                        return item.zone ? item.zone.fullName : null;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('project.follow.requests.status'),
                    id: this.$t('project.follow.requests.status'),
                    children: [
                        {
                            id: 'validatedMOE',
                            name: this.$t('project.follow.requests.validatedMOE'),
                            firstOfCriteriaType: true,
                            _isStatus: true,
                        },
                        {
                            id: 'validatedMOA',
                            name: this.$t('project.follow.requests.validatedMOA'),
                            firstOfCriteriaType: false,
                            _isStatus: true,
                        },
                        {
                            id: 'noMOEStatus',
                            name: this.$t('project.follow.requests.noMOEStatus'),
                            firstOfCriteriaType: true,
                            _isStatus: true,
                        },
                        {
                            id: 'noMOAStatus',
                            name: this.$t('project.follow.requests.noMOAStatus'),
                            firstOfCriteriaType: false,
                            _isStatus: true,
                        },
                        {
                            id: 'rejectedMOE',
                            name: this.$t('project.follow.requests.rejectedMOE'),
                            firstOfCriteriaType: true,
                            _isStatus: true,
                        },
                        {
                            id: 'rejectedMOA',
                            name: this.$t('project.follow.requests.rejectedMOA'),
                            firstOfCriteriaType: false,
                            _isStatus: true,
                        },
                        {
                            id: 'resolved',
                            name: this.$t('project.follow.requests.resolved'),
                            firstOfCriteriaType: false,
                            _isStatus: true,
                        },
                        {
                            id: 'obsolete',
                            name: this.$t('project.follow.requests.obsolete'),
                            firstOfCriteriaType: false,
                            _isStatus: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('project.follow.requests.observationsStatus'),
                    id: this.$t('project.follow.requests.observationsStatus'),
                    children: [
                        {
                            id: 'noObservation',
                            name: this.$t('project.follow.requests.noObservation'),
                            firstOfCriteriaType: true,
                            _isObservationStatus: true,
                        },
                        {
                            id: 'withObservation',
                            name: this.$t('project.follow.requests.withObservations'),
                            firstOfCriteriaType: false,
                            _isObservationStatus: true,
                        },
                        {
                            id: 'observationsPending',
                            name: this.$t('project.follow.requests.observationsPending'),
                            firstOfCriteriaType: true,
                            _isObservationStatus: true,
                        },
                        {
                            id: 'noPendingObservation',
                            name: this.$t('project.follow.requests.noPendingObservation'),
                            firstOfCriteriaType: false,
                            _isObservationStatus: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: [
                        {
                            id: 'noLocation',
                            path: 'noLocation',
                            name: this.$t('commons.noLocation'),
                            firstOfCriteriaType: true,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        },
                        ...this.locationOptions.map((location) => ({
                            ...location,
                            name: location.fullName,
                            firstOfCriteriaType: false,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        })),
                    ],
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getRequests(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                    getObservations(this.$route.params.projectId),
                ]).subscribe(([requests, folders, observations]) => {
                    this.locationOptions = locationService.buildLocationOptions(folders);
                    const locationMap = locationService.getLocationMap(folders);
                    this.items = requests.map((request) => {
                        return {
                            ...request,
                            isSelected: this.selection.includes(request.id),
                            zone: locationMap[request.zoneId],
                            observations: observations.filter(
                                (observation) => observation.relatedContentId === request.id,
                            ),
                        };
                    });
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        saveSort() {
            localStorage.setItem(
                'requests_sort_' + this.$route.params.projectId,
                JSON.stringify({
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreSort() {
            const cache = localStorage.getItem('requests_sort_' + this.$route.params.projectId);
            if (cache) {
                const sortCache = JSON.parse(cache);
                this.sortKey = sortCache.sortKey || 'index';
                this.sortAsc = sortCache.sortAsc !== false;
            }
        },
        matchStatus(statusCriteria, item) {
            return statusCriteria.find(
                (criterium) =>
                    (criterium.id === 'validatedMOE' && item.validatedMOEAt) ||
                    (criterium.id === 'validatedMOA' && item.validatedMOAAt) ||
                    (criterium.id === 'noMOEStatus' && !item.validatedMOEAt && !item.rejectedMOEAt) ||
                    (criterium.id === 'noMOAStatus' && !item.validatedMOAAt && !item.rejectedMOAAt) ||
                    (criterium.id === 'rejectedMOE' && item.rejectedMOEAt) ||
                    (criterium.id === 'rejectedMOA' && item.rejectedMOAAt) ||
                    (criterium.id === 'resolved' && item.resolvedAt) ||
                    (criterium.id === 'obsolete' && item.obsoleteAt),
            );
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) =>
                filterMatch(index(item.index + 1) + ' ' + item.description, criteria, true),
            );
        },
        scrollToLastVisited() {
            const lastVisitedRequestId = localStorage.getItem('requests.lastVisitedId.' + this.$route.params.projectId);
            if (lastVisitedRequestId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedRequestId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        async onAction(action) {
            if (action === 'delete') {
                const selectedItems = this.items.filter((item) => this.selection.includes(item.id) && item.isCustom);
                if (await confirm(this.$t('commons.confirmMessageAll', { number: selectedItems.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeRequest(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'duplicate') {
                const selectedItem = this.items.find((item) => this.selection[0] === item.id);
                await createRequest(this.$route.params.projectId, {
                    description: selectedItem.description + ' copie',
                    zoneId: selectedItem.zoneId,
                    supportId: selectedItem.supportId,
                    roomId: selectedItem.roomId,
                    footprint: selectedItem.footprint,
                    page: selectedItem.page,
                });
            }
        },
        toggleSelectAll() {
            const customItems = this.filteredItems.filter((item) => item.isCustom).map((item) => item.id);
            if (this.selection.length < customItems.length) {
                this.selection = customItems;
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'lastVisitedRequest.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('lastVisitedRequest.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addRequest() {
            const result = await createRequest(this.$route.params.projectId, {
                description: this.$t('project.follow.requests.newRequest'),
            });
            await this.$router.push({
                name: 'request',
                params: {
                    projectId: this.$route.params.projectId,
                    requestId: result.id,
                },
            });
        },
        saveFilter(filterValue) {
            localStorage.setItem('requests_filter_' + this.$route.params.projectId, JSON.stringify({ filterValue }));
        },
        restoreFilter() {
            const cache = localStorage.getItem('requests_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const statusCriteria = filter.filter((aCriteria) => aCriteria._isStatus).map((criteria) => criteria.id);
            const observationCriteria = filter
                .filter((aCriteria) => aCriteria._isObservationStatus)
                .map((criteria) => criteria.id);

            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);

            return this.items.filter((item) => {
                const fullCriteria = {
                    matchStatusCriteria: statusCriteria.length === 0 || this.matchStatus(statusCriteria, item),
                    matchObservationCriteria:
                        observationCriteria.length === 0 || this.matchObservationsStatus(statusCriteria, item),
                    matchString: this.matchString(stringCriteria, item),
                    locationCriteria: locationService.matchLocationCriteria(
                        locationCriteria,
                        [item.zone, item.room].filter((a) => !!a),
                    ),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        matchObservationsStatus(statusCriteria, item) {
            return statusCriteria.find((criteria) => {
                (criteria === 'noObservation' && item.observations.length === 0) ||
                    (criteria === 'withObservation' && item.observations.length > 0) ||
                    (criteria === 'observationsPending' &&
                        item.observations.filter((observation) => !observation.resolvedAt).length) ||
                    (criteria === 'noPendingObservation' &&
                        item.observations.filter((observation) => !!observation.resolvedAt).length);
            });
        },
    },
    data() {
        return {
            sortKey: 'index',
            sortAsc: true,
            loading: true,
            readOnly: true,
            observations: [],
            locationOptions: [],
            selection: [],
            subscriptions: [],
            items: [],
            filterValue: [],
            quickActions: [
                {
                    name: this.$t('project.follow.requests.newRequest'),
                    run: () => this.addRequest(),
                },
            ],
        };
    },
};
</script>
