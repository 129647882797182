<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <WeatherIssueForm ref="form" :weatherIssue="weatherIssue" :isAdmin="isAdmin" />
        <WeatherIssueAttachments :weatherIssue="weatherIssue" :isAdmin="isAdmin"></WeatherIssueAttachments>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import AppQuickActions from '../../../components/appQuickActions/AppQuickActions';
import WeatherIssueForm from './WeatherIssueForm';
import { createWeatherIssue, getWeatherIssue } from './weatherIssues.service';
import ReadOnlyWeatherIssueForm from '@/features/planning/weatherIssues/ReadOnlyWeatherIssueForm';
import WeatherIssueAttachments from '@/features/planning/weatherIssues/WeatherIssueAttachments';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { updateBreadCrumbs } from '@/state/state';

export default {
    components: {
        WeatherIssueAttachments,
        ReadOnlyWeatherIssueForm,
        WeatherIssueForm,
        AppQuickActions,
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getWeatherIssue(this.$route.params.projectId, this.$route.params.weatherIssueId),
                    getBundleMap(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(async ([weatherIssue, bundleMap, project]) => {
                    this.isAdmin = this.isAdmin = project.me.allowedFeatures.includes('project_planning');
                    this.weatherIssue = {
                        ...weatherIssue,
                        emitters: weatherIssue.emitterIds.map((id) => bundleMap[id]),
                        isEmitter: project.me.bundleIds.find((bundleId) => weatherIssue.emitterIds.includes(bundleId)),
                    };
                    updateBreadCrumbs({ weatherIssueName: weatherIssue.name });
                }),
                getProject(this.$route.params.projectId).subscribe((project) => {
                    this.readOnly = !project.me.allowedFeatures.includes('project_planning');
                }),
            ];
        },
        backToList() {
            this.$router.push({ name: 'weatherIssues', hash: '#uuid_' + this.weatherIssue.id });
        },
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    async created() {
        this.init();
    },
    data() {
        return {
            readOnly: true,
            isAdmin: false,
            weatherIssue: { attachments: [], emitterId: [] },
            subscriptions: [],
            quickActions: [
                {
                    name: this.$t('project.follow.weatherIssues.newWeatherIssue'),
                    run: async () => {
                        const result = await createWeatherIssue(this.$route.params.projectId, {
                            name: this.$t('project.follow.weatherIssues.newName'),
                        });
                        await this.$router.push({
                            name: 'weatherIssue',
                            params: { projectId: this.$route.params.projectId, weatherIssueId: result.id },
                        });
                    },
                },
            ],
        };
    },
};
</script>
