import getISODay from 'date-fns/getISODay';
import addDays from 'date-fns/addDays';
import { combineLatest, BehaviorSubject, map } from 'rxjs';
import { startOfDay } from 'date-fns';
import { sortBy } from '@/services/sanitize.service';

const offDaysMapByProjectId = {};
function sync(projectId, periodsCollection, weatherIssuesCollections) {
    const observable = new BehaviorSubject([]);
    offDaysMapByProjectId[projectId] = observable;
    combineLatest([
        periodsCollection.find().$.pipe(map((periods) => periods.map((period) => period.toMutableJSON()))),
        weatherIssuesCollections.find({ selector: { conclusion: 'validated' } }).$,
    ]).subscribe(async ([periods, weatherIssues]) => {
        observable.next(mergeOffDaysBundleIds(agendaToOffDays([...periods, ...weatherIssuesToAgenda(weatherIssues)])));
    });
}
function find(projectId) {
    return offDaysMapByProjectId[projectId];
}
export function agendaToOffDays(agenda) {
    return agenda.reduce((acc, period) => [...acc, ...periodToOffDays(period)], []);
}
export function weatherIssuesToAgenda(weatherIssues) {
    return weatherIssues.map((weatherIssue) => {
        const date = startOfDay(new Date(weatherIssue.date));
        return {
            id: weatherIssue.id,
            name: weatherIssue.name,
            type: 'weatherIssue',
            startDate: date,
            endDate: addDays(date, weatherIssue.duration - 1),
            time: date.getTime(),
            bundleIds: weatherIssue.emitterIds,
        };
    });
}

export function mergeOffDaysBundleIds(offDays) {
    const daysByDate = {};
    for (let offDay of offDays) {
        let cached = daysByDate[offDay.date];
        if (!cached) {
            daysByDate[offDay.date] = offDay;
        } else {
            cached.bundleIds = [...cached.bundleIds, ...offDay.bundleIds];
        }
    }
    return sortBy(Object.values(daysByDate), 'date');
}

export function periodToOffDays(period) {
    const startDate = startOfDay(new Date(period.startDate));
    const endDate = startOfDay(new Date(period.endDate));
    if (period.startDate === period.endDate && getISODay(startDate) < 6) {
        return [
            {
                date: startDate.toISOString(),
                time: startDate.getTime(),
                bundleIds: period.bundleIds.length ? period.bundleIds : ['all'],
            },
        ];
    }
    const result = [];
    let currentDate = startDate;
    const bundleIds = period.bundleIds.length === 0 ? ['all'] : period.bundleIds;
    while (currentDate.getTime() <= endDate.getTime()) {
        if (getISODay(currentDate) < 6) {
            result.push({ date: currentDate.toISOString(), time: currentDate.getTime(), bundleIds, type: period.type });
        }
        currentDate = addDays(currentDate, 1);
    }
    return result;
}
export default {
    sync,
    find,
};
