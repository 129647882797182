import { getAPIHeaders } from '@/services/api.service';

async function getTasks(projectId, planningId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders(),
    });
    return result.json();
}

async function createTasks(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function deleteTasks(projectId, planningId, taskId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${taskId}`, {
        headers: getAPIHeaders(),
        method: 'DELETE',
    });
    return result.json();
}

async function updateTasks(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${task.id}`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'PUT',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function getPlannings(projectId) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders(),
    });
    return result.json();
}

async function createPlanning(projectId, planning) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(planning),
    });
    return result.json();
}
function importCSV(projectId, planningId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        method: 'PUT',
        body: formData,
        headers: getAPIHeaders(),
    });
}
function importXML(projectId, planningId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        method: 'PUT',
        body: formData,
        headers: getAPIHeaders(),
    });
}

export function treeToLines(roots) {
    const result = [];
    for (const node of roots) {
        treeToLines_recursive(node, result);
    }
    return result;
}
export function treeToLines_recursive(node, result) {
    result.push(node);
    if (node.children) {
        for (const child of node.children) {
            treeToLines_recursive(child, result);
        }
    }
}

export default {
    getTasks,
    createTasks,
    updateTasks,
    getPlannings,
    createPlanning,
    deleteTasks,
    importCSV,
    importXML,
};
