<template>
    <button @click="upgrade()" :disabled="!upgradeAvailable" class="flex items-center gap-1">
        <slot></slot>
        <icon-arrow-up-bold v-if="upgradeAvailable && showIcon" width="16" height="16"></icon-arrow-up-bold>
    </button>
</template>

<script>
import { upgradeStatus } from '@/state/state';
import AppButton from '@/components/appButton/AppButton';
import IconArrowUpBoldCircleOutline from '@/icons/IconArrowUpBoldCircleOutline';

export default {
    components: { IconArrowUpBoldCircleOutline, AppButton },
    props: {
        showIcon: { type: Boolean },
    },
    created() {
        this.subscriptions = [
            upgradeStatus.subscribe((upgradeAvailable) => (this.upgradeAvailable = upgradeAvailable)),
        ];
    },
    data: () => ({
        subscriptions: [],
        upgradeAvailable: false,
    }),
    methods: {
        upgrade() {
            if (window.upgrade) {
                window.upgrade();
            }
        },
    },
};
</script>
