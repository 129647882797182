<template>
    <label class="flex" @drop.prevent="dropFile" @dragover.prevent="dragover" @dragleave.prevent="dragleave">
        <a
            class="font-bold cursor-pointer flex items-center btn"
            :class="{
                'opacity-25': disabled,
                'px-2': icon,
                'p-3': size !== 'mini',
                'p-1': size === 'mini',
                'bg-gray-300': isDraggingOver,
                'bg-white': !isDraggingOver,
            }"
        >
            <Icon
                :class="{ 'mx-2': size !== 'mini', 'mr-2': size === 'mini' }"
                width="16"
                height="16"
                :name="icon"
                v-if="icon"
            />
            {{ label }}
            <icon-rotate-right class="animate animate-spin" v-if="loading"></icon-rotate-right>
        </a>
        <input
            ref="input"
            type="file"
            class="hidden"
            tabindex="-1"
            v-on:change="onSelect"
            accept="image/*"
            :disabled="disabled"
        />
    </label>
</template>
<script>
import Icon from '../../icons/Icon';
import ImageBlobReduce from 'image-blob-reduce';
import IconRotateRight from '../../icons/IconRotateRight';
export default {
    components: { IconRotateRight, Icon },
    data() {
        return {
            loading: false,
            imageBlobReduce: new ImageBlobReduce(),
            isDraggingOver: false,
        };
    },
    props: {
        label: String,
        size: String,
        icon: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        resize: {
            type: Number,
            default: 1000,
        },
    },
    methods: {
        dragover() {
            this.isDraggingOver = true;
        },
        dragleave() {
            this.isDraggingOver = false;
        },
        dropFile(event) {
            this.onSelect({ target: event.dataTransfer });
            this.isDraggingOver = false;
        },
        async onSelect(event) {
            const file = event.target.files[0];
            this.loading = true;
            try {
                let result;
                if (this.resize) {
                    result = await this.imageBlobReduce.toBlob(file, { max: this.resize });
                } else {
                    result = file;
                }
                this.$emit('select', { dataUrl: await this.toBase64(result) });
            } finally {
                this.loading = false;
            }
        },
        click() {
            this.$refs.input.click();
        },
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
    },
};
</script>
<style scoped>
.btn {
    border-color: #e5e5e5;
    border-width: 1px;
}
.btn:disabled {
    opacity: 0.3;
}
.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
    background-color: #f0f0f0;
    border-color: #e3e3e3;
}
</style>
