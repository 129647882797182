<template>
    <div>
        <div class="flex flex-col">
            <div class="m-2 flex items-center gap-4" v-if="!readOnly">
                <app-button
                    @click="generatePDF"
                    :disabled="disableGeneratePDF"
                    :icon-class="disableGeneratePDF ? 'animate animate-spin' : ''"
                    :label="$t('project.editions.observationsReports.generateFile')"
                ></app-button>
                <app-checkbox
                    v-if="showGenerateAPDFByBundleOption"
                    class="text-xs"
                    @input="save"
                    :disabled="disableGeneratePDF"
                    v-model="observationsReport.generateAPDFByBundle"
                    :label="$t('project.editions.observationsReports.generateAPDFByBundle')"
                />
            </div>
            <div class="flex flex-col gap-2 m-2">
                <div v-if="pendingSync" class="flex">
                    <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                    {{ $t('project.editions.observationsReports.pendingProjectSync') }}
                </div>
                <template v-else>
                    <app-file-link
                        icon="icon-archive-arrow-down"
                        v-if="
                            observationsReport.files &&
                            observationsReport.files.length > 1 &&
                            observationsReport.files.every((file) => file.generationEndDate && file.generationSucceed)
                        "
                        :file-name="$t('project.editions.observationsReports.zipFile')"
                        :url="`/api/observationsReports/${$route.params.projectId}/${$route.params.observationsReportId}/file.pdf`"
                        class="underline my-3"
                    ></app-file-link>
                    <template v-for="file in observationsReport.files">
                        <div class="grid grid-cols-3">
                            <app-file-link
                                v-if="file.generationEndDate && file.generationSucceed"
                                :file-name="file.name"
                                :url="
                                    file.type
                                        ? `/api/projects/${$route.params.projectId}/observationsReports/${
                                              observationsReport.id
                                          }/${file.bundleId || 'zones'}/report.pdf`
                                        : file.url
                                "
                                class="underline ml-2 col-span-2"
                            />
                            <div class="flex" v-if="file.generationEndDate && file.generationSucceed && !readOnly">
                                <app-button
                                    icon="icon-email-edit-outline"
                                    size="mini"
                                    @click="
                                        $refs.emailPopup.open(
                                            observationsReport,
                                            file,
                                            file.type === 'zones'
                                                ? uniq(
                                                      observations.reduce(
                                                          (acc, observation) => [...acc, ...observation.recipientIds],
                                                          [],
                                                      ),
                                                  )
                                                : [file.bundleId],
                                        )
                                    "
                                ></app-button>
                            </div>
                            <div class="flex col-span-2" v-if="!file.generationEndDate">
                                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                                {{ file.name }}
                            </div>
                        </div>
                        <div
                            class="flex text-red-500 items-center gap-2 col-span-2"
                            v-if="file.generationEndDate && !file.generationSucceed"
                        >
                            <icon-alert-circle width="16"></icon-alert-circle>
                            {{ file.name }}
                            <span class="text-xs italic">
                                {{ $t('project.editions.observationsReports.generationFailed') }}
                            </span>
                        </div>
                    </template>
                </template>
            </div>
        </div>
        <observations-report-mail-popup ref="emailPopup"></observations-report-mail-popup>
    </div>
</template>

<script>
import {
    generateObservationsReportFiles,
    updateObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import { uniq } from '@/services/sanitize.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import { replicateProject } from '@/rxdb/database';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import IconRotateRight from '@/icons/IconRotateRight.vue';
import IconAlertCircle from '@/icons/IconAlertCircle.vue';
import ObservationsReportMailPopup from '@/features/observationsReports/ObservationsReportMailPopup.vue';

export default {
    components: {
        ObservationsReportMailPopup,
        IconAlertCircle,
        IconRotateRight,
        AppFileLink,
        AppCheckbox,
        AppButton,
        AppSeparator,
    },
    props: {
        observationsReport: { type: Object },
        observations: { type: Array, default: () => [] },
        readOnly: { type: Boolean, default: true },
        showGenerateAPDFByBundleOption: { type: Boolean, default: true },
    },
    data() {
        return { disableGeneratePDF: false, pendingSync: false };
    },
    methods: {
        uniq,
        async generatePDF() {
            this.disableGeneratePDF = true;
            if (
                this.observationsReport.files.length === 0 ||
                (await confirm(this.$t('project.editions.confirmRegenerate')))
            ) {
                this.pendingSync = true;
                await replicateProject(this.$route.params.projectId);
                this.pendingSync = false;
                this.observationsReport.files = await generateObservationsReportFiles(
                    this.$route.params.projectId,
                    this.observationsReport,
                );
                this.$emit('generatePDF');
            }
            this.disableGeneratePDF = false;
        },
        async save() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                generateAPDFByBundle: this.observationsReport.generateAPDFByBundle,
            });
        },
    },
};
</script>
