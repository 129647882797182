<template>
    <div class="app-picker">
        <div class="relative">
            <app-input-text
                :value="inputPlaceholder"
                @click="onClick"
                @keyup="onKeyUp"
                @keypress="$emit('keypress', $event)"
                @input="onTriggerInput"
                autocomplete="off"
                :disabled="disabled"
                :showLabel="showLabel"
                :label="label"
                :showErrors="showErrors"
                :uid="uid"
                :required="required"
                :placeholder="placeholder"
                ref="trigger"
                :immediate="immediate"
                :size="size"
                @blur="onTriggerBlur"
            >
                <template v-slot:link>
                    <div class="flex items-center">
                        <slot name="link" />
                        <div
                            @click="$emit('input', null)"
                            class="cursor-pointer text-gray-700 m-2"
                            v-if="value && !required && !disabled"
                        >
                            <IconCloseCircle width="16" />
                        </div>
                        <div @click="onClick" class="cursor-pointer text-gray-700" :class="{ 'opacity-50': disabled }">
                            <IconChevronDown />
                        </div>
                    </div>
                </template>
                <template v-slot:tip>
                    <slot name="tip" />
                </template>
            </app-input-text>
        </div>
        <app-autocomplete
            @close="onClose"
            :bindById="bindById"
            :value="value"
            @input="onInput"
            :options="options"
            :labelKey="labelKey"
            :allowStringCriteria="allowCustomOption"
            :stringCriteriaPrefix="allowCustomOption ? customOptionPrefix || '' : null"
            ref="autocomplete"
            :labelFunction="labelFunction"
            :filterStringFunction="filterStringFunction"
        >
            <template v-slot:title>
                <app-separator v-if="title" :label="title" />
            </template>
            <template v-slot:filter="scope"></template>
            <template v-slot:item="{ item }">
                <slot :option="item" name="option"></slot>
            </template>
            <template v-slot:footer>
                <slot name="footer"></slot>
            </template>
        </app-autocomplete>
    </div>
</template>
<script>
import IconChevronDown from '../../icons/IconChevronDown';
import AppLabel from '../appLabel/AppLabel';
import AppSelect from '../appSelect/AppSelect';
import AppFilter from '../appFilter/AppFilter';
import AppInputText from '../appInputText/AppInputText';
import AppSeparator from '../appSeparator/AppSeparator';
import AppAutocomplete from '../app-autocomplete/AppAutocomplete';
import IconAlertCircle from '../../icons/IconAlertCircle';
import IconCloseCircle from '../../icons/IconCloseCircle';
export default {
    components: {
        IconCloseCircle,
        IconAlertCircle,
        AppAutocomplete,
        AppSeparator,
        AppInputText,
        AppFilter,
        AppSelect,
        IconChevronDown,
        AppLabel,
    },
    props: {
        immediate: {
            type: Boolean,
            default: false,
        },
        value: Object | String | Number,
        uid: String | Number,
        disabled: Boolean,
        showLabel: Boolean,
        showErrors: Boolean,
        label: String,
        placeholder: String,
        required: Boolean,
        options: {
            type: Array,
            default: () => [],
        },
        bindById: Boolean,
        allowCustomOption: Boolean,
        customOptionPrefix: String,
        title: String,
        size: String | Number,
        labelKey: {
            type: String,
            default: 'name',
        },
        labelFunction: {
            type: Function,
        },
        filterStringFunction: {
            type: Function,
        },
    },
    data() {
        return {
            inputPlaceholder: '',
        };
    },
    watch: {
        value(value) {
            this.updatePlaceHolder(value);
        },
    },
    mounted() {
        this.updatePlaceHolder(this.value);
    },
    methods: {
        onTriggerBlur(event) {
            if (!this.$refs.autocomplete.isOpen) {
                this.$emit('blur', event);
            }
        },
        updatePlaceHolder(value) {
            if (!value) {
                this.inputPlaceholder = '';
                return;
            }
            const option = this.options.find((option) =>
                this.bindById ? option.id === value : option.id === value.id,
            );
            this.inputPlaceholder = this.getLabel(option || value);
        },
        getLabel(value) {
            if (this.labelFunction) {
                return this.labelFunction(value);
            } else if (typeof value === 'object') {
                return value[this.labelKey] || '';
            } else {
                return value;
            }
        },
        open() {
            this.$refs.autocomplete.open();
        },
        onTriggerInput() {
            this.$refs.autocomplete.open();
        },
        onInput(event) {
            this.$emit('input', event);
            this.$nextTick(() => {
                this.$refs.trigger.setDirty();
            });
        },
        onClose() {
            this.focus();
        },
        focus() {
            this.$refs.trigger.focus();
        },
        scrollTo() {
            this.$refs.trigger.scrollTo();
        },
        onClick() {
            this.$refs.autocomplete.open();
        },
        onKeyUp(event) {
            this.$refs.autocomplete.onTriggerKeyUp(event);
            this.$emit('keyup', event);
        },
    },
};
</script>
