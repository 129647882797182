<template>
    <main class="max-w-5xl m-5 h-full w-full">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form>
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        v-model="observationsReport.name"
                        class="col-span-2"
                        :required="true"
                        @blur="save"
                        :label="$t('commons.name')"
                        :disabled="readOnly"
                    />
                    <app-date-input
                        v-model="observationsReport.date"
                        class="col-span-2"
                        @input="save"
                        :disabled="readOnly"
                        :label="$t('project.editions.observationsReports.date')"
                    />
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-4" :label="$t('project.editions.observationsReports.content')">
                    <div class="m-4 col-span-2 flex flex-col items-start">
                        <ul>
                            <li class="my-4">
                                <span class="font-bold">{{ $t('project.editions.observationsReports.cover') }}</span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showStats"
                                            :label="$t('project.editions.observationsReports.showStats')"
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4">
                                <span class="font-bold">{{ $t('project.editions.observationsReports.zones') }}</span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showEmptyZones"
                                            :label="$t('project.editions.observationsReports.showEmptyZones')"
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4">
                                <span class="font-bold">
                                    {{ $t('project.editions.observationsReports.observationsWithoutZone') }}
                                </span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showObservationsWithoutZone"
                                            :label="
                                                $t('project.editions.observationsReports.showObservationsWithoutZone')
                                            "
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4">
                                <span class="font-bold">
                                    {{ $t('project.editions.observationsReports.zoneContent') }}
                                </span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showMaps"
                                            :label="$t('project.editions.observationsReports.showMaps')"
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showTables"
                                            :label="$t('project.editions.observationsReports.showTables')"
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showPhotos"
                                            :label="$t('project.editions.observationsReports.showPhotos')"
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4">
                                <span class="font-bold">
                                    {{ $t('project.editions.observationsReports.observationsByState') }}
                                </span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showPending"
                                            :label="$t('project.editions.observationsReports.showPending')"
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showResolved"
                                            :label="$t('project.editions.observationsReports.showResolved')"
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showValidated"
                                            :label="$t('project.editions.observationsReports.showValidated')"
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4">
                                <span class="font-bold">
                                    {{ $t('project.editions.observationsReports.observationsByType') }}
                                </span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2 mt-2"
                                            @input="save"
                                            v-model="observationsReport.showAdministrative"
                                            :label="$t('project.editions.observationsReports.showAdministrative')"
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showObservationsOnTodoWork"
                                            :label="
                                                $t('project.editions.observationsReports.showObservationsOnTodoWork')
                                            "
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showObservationsOnDoneWork"
                                            :label="
                                                $t('project.editions.observationsReports.showObservationsOnDoneWork')
                                            "
                                            :disabled="readOnly"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showOtherObservations"
                                            :label="$t('project.editions.observationsReports.showOtherObservations')"
                                            :disabled="readOnly"
                                        />
                                    </li>
                                </ul>
                            </li>
                            <li class="my-4" v-if="project_multiple">
                                <span class="font-bold">
                                    {{ $t('project.editions.observationsReports.observationsByPhase') }}
                                </span>
                                <ul>
                                    <li>
                                        <app-checkbox
                                            class="col-span-2 mt-2"
                                            @input="save"
                                            v-model="observationsReport.showEXEObservations"
                                            :label="$t('project.editions.observationsReports.showEXEObservations')"
                                            :disabled="readOnly"
                                            v-if="project_EXE"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showOPRObservations"
                                            :label="$t('project.editions.observationsReports.showOPRObservations')"
                                            :disabled="readOnly"
                                            v-if="project_OPR"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showReceiptObservations"
                                            :label="$t('project.editions.observationsReports.showReceiptObservations')"
                                            :disabled="readOnly"
                                            v-if="project_OPR"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showOPLObservations"
                                            :label="$t('project.editions.observationsReports.showOPLObservations')"
                                            :disabled="readOnly"
                                            v-if="project_OPL"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showDeliveryObservations"
                                            :label="$t('project.editions.observationsReports.showDeliveryObservations')"
                                            :disabled="readOnly"
                                            v-if="project_OPL"
                                        />
                                        <app-checkbox
                                            class="col-span-2"
                                            @input="save"
                                            v-model="observationsReport.showAPAObservations"
                                            :label="$t('project.editions.observationsReports.showAPAObservations')"
                                            :disabled="readOnly"
                                            v-if="project_OPL"
                                        />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="m-4 col-span-2 flex flex-col items-start">
                        <app-checkbox
                            v-model="enableDateRange"
                            :label="$t('project.editions.observationsReports.dateRangeTitle')"
                            :show-label="false"
                            @input="onDateRangeChange"
                        >
                            <div class="flex font-bold gap-1 text-gray-700">
                                {{ $t('project.editions.observationsReports.dateRangeTitle') }}
                                <app-date-link
                                    :disabled="!enableDateRange"
                                    v-model="observationsReport.startDate"
                                    @input="saveStartDate()"
                                />
                                {{ $t('project.editions.observationsReports.andTo') }}
                                <app-date-link
                                    :disabled="!enableDateRange"
                                    v-model="observationsReport.endDate"
                                    @input="saveEndDate()"
                                />
                            </div>
                        </app-checkbox>
                    </div>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="observationsReport.bundles"
                        :options="bundles"
                        :label="$t('bundles.bundles')"
                        :placeholder="$t('commons.all')"
                        @close="saveBundles"
                        @removeChips="saveBundles"
                        labelKey="label"
                        :disabled="readOnly"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="observationsReport.emitters"
                        :options="bundles"
                        :label="$t('project.editions.observationsReports.emitters')"
                        :placeholder="$t('commons.all')"
                        @close="saveEmitters"
                        @removeChips="saveEmitters"
                        labelKey="label"
                        :disabled="readOnly"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="sortedSelectedZones"
                        :options="sortedZoneOptions"
                        :label="$t('zones.zones')"
                        :placeholder="$t('commons.allFemale')"
                        @close="saveZones"
                        @removeChips="saveZones"
                        label-key="fullName"
                        :disabled="readOnly"
                    ></app-multi-picker>
                </app-fieldset>
                <app-save-on-leave :dirty="dirty" :saveFn="save"></app-save-on-leave>
            </form>
        </ValidationObserver>

        <app-separator class="col-span-2 mt-4" :label="$t('project.editions.observationsReports.files')" />
        <observations-report-files
            v-if="observationsReport"
            :observationsReport="observationsReport"
            :readOnly="readOnly"
            :observations="observations"
            :showGenerateAPDFByBundleOption="true"
        ></observations-report-files>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppLegend from '@/components/appLegend/AppLegend';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppInputText from '@/components/appInputText/AppInputText';
import {
    filterObservationFromObservationReportConfig,
    getObservationsReport,
    updateObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import AppFooter from '@/components/appFooter/AppFooter';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppButton from '@/components/appButton/AppButton';
import IconAlertCircle from '@/icons/IconAlertCircle';
import { getMapById, sortBy, uniq } from '@/services/sanitize.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import locationService from '@/services/location.service';
import AppBundle from '@/components/app-bundle/appBundle';
import { combineLatest } from 'rxjs';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getBundles } from '@/features/bundles/bundles.service';
import IconEmailDownloadOutline from '@/icons/IconEmailDownloadOutline';
import IconEmailEditOutline from '@/icons/IconEmailEditOutline.vue';
import ObservationsReportMailPopup from '@/features/observationsReports/ObservationsReportMailPopup.vue';
import { getObservations } from '@/features/observations/observation.service';
import ObservationsReportFiles from '@/features/observationsReports/ObservationsReportFiles.vue';
import { getProject } from '@/features/projects/projects.service';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';
import { endOfDay } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';

export default {
    components: {
        AppDateLink,
        ObservationsReportFiles,
        ObservationsReportMailPopup,
        IconEmailEditOutline,
        IconEmailDownloadOutline,
        AppBundle,
        AppDateInput,
        IconAlertCircle,
        AppButton,
        AppFileLink,
        AppSeparator,
        AppFooter,
        AppInputText,
        AppSaveOnLeave,
        AppMultiPicker,
        AppCheckbox,
        AppFieldset,
        AppLegend,
    },
    created() {
        this.init();
    },
    data() {
        return {
            bundles: [],
            observations: [],
            sortedZoneOptions: [],
            subscriptions: [],
            sortedSelectedZones: [],
            observationsReport: false,
            project_EXE: false,
            project_OPR: false,
            project_OPL: false,
            project_multiple: false,
            readOnly: true,
            enableDateRange: false,
        };
    },
    methods: {
        uniq,
        init() {
            this.subscriptions = [
                getProject(this.$route.params.projectId).subscribe((project) => {
                    this.project_EXE = project.projectFeatures.includes('project_EXE');
                    this.project_OPR = project.projectFeatures.includes('project_OPR');
                    this.project_OPL = project.projectFeatures.includes('project_OPL');
                    const count = [this.project_EXE, this.project_OPR, this.project_OPL].filter((a) => !!a).length;
                    this.project_multiple = count > 1 || (count === 1 && !this.project_EXE);
                    this.readOnly = !project.me.allowedFeatures.includes('project_observationsReports');
                }),
                combineLatest([
                    getObservationsReport(this.$route.params.projectId, this.$route.params.observationsReportId),
                    getLocationsTree(this.$route.params.projectId),
                    getBundles(this.$route.params.projectId),
                    getObservations(this.$route.params.projectId),
                ]).subscribe(async ([observationsReport, folders, bundles, observations]) => {
                    this.bundles = sortBy(bundles, 'label');
                    const bundleMap = getMapById(bundles);
                    const zones = locationService
                        .buildLocationOptions(folders)
                        .filter((location) => location.type === 'zone');
                    this.sortedZoneOptions = sortBy(zones, 'name');
                    this.observationsReport = {
                        ...observationsReport,
                        files: sortBy(observationsReport.files, 'name'),
                        bundles: sortBy(
                            observationsReport.bundleIds.map((bundleId) => bundleMap[bundleId]),
                            'reference',
                        ),
                        emitters: observationsReport.emitterIds
                            ? sortBy(
                                  observationsReport.emitterIds.map((bundleId) => bundleMap[bundleId]),
                                  'reference',
                              )
                            : [],
                    };
                    this.enableDateRange = !!(observationsReport.startDate || observationsReport.endDate);
                    this.sortedSelectedZones = zones.filter((aZone) =>
                        this.observationsReport.zoneIds.includes(aZone.id),
                    );

                    let zoneIds;
                    if (observationsReport.zoneIds.length === 0) {
                        zoneIds = zones.map((zone) => zone.id);
                    } else {
                        zoneIds = this.observationsReport.zoneIds;
                    }
                    let bundleIds;
                    if (observationsReport.bundleIds.length === 0) {
                        bundleIds = bundles.map(({ id }) => id);
                    } else {
                        bundleIds = this.observationsReport.bundleIds;
                    }
                    this.observations = filterObservationFromObservationReportConfig(
                        observations,
                        zoneIds,
                        bundleIds,
                        this.observationsReport,
                    );
                    updateBreadCrumbs({ observationsReportName: this.observationsReport.name });
                }),
            ];
        },
        async saveBundles() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                bundleIds: this.observationsReport.bundles.map((bundle) => bundle.id),
            });
        },
        async saveEmitters() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                emitterIds: this.observationsReport.emitters.map((bundle) => bundle.id),
            });
        },
        async saveStartDate() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                startDate: startOfDay(this.observationsReport.startDate),
            });
        },
        async onDateRangeChange(event) {
            if (event) {
                return updateObservationsReport(this.$route.params.projectId, {
                    id: this.observationsReport.id,
                    startDate: startOfDay(new Date()),
                    endDate: endOfDay(new Date()),
                });
            } else {
                return updateObservationsReport(this.$route.params.projectId, {
                    id: this.observationsReport.id,
                    startDate: null,
                    endDate: null,
                });
            }
        },
        async saveEndDate() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                endDate: endOfDay(this.observationsReport.endDate),
            });
        },
        async saveZones() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                zoneIds: this.sortedSelectedZones.map((zone) => zone.id),
            });
        },
        async save() {
            return updateObservationsReport(this.$route.params.projectId, {
                id: this.observationsReport.id,
                name: this.observationsReport.name,
                showObservationsWithNoZone: this.observationsReport.showObservationsWithNoZone,
                showEmptyZones: this.observationsReport.showEmptyZones,
                showTables: this.observationsReport.showTables,
                showPhotos: this.observationsReport.showPhotos,
                showPending: this.observationsReport.showPending,
                showAdministrative: this.observationsReport.showAdministrative,
                showObservationsWithoutZone: this.observationsReport.showObservationsWithoutZone,
                showObservationsOnTodoWork: this.observationsReport.showObservationsOnTodoWork,
                showObservationsOnDoneWork: this.observationsReport.showObservationsOnDoneWork,
                showOtherObservations: this.observationsReport.showOtherObservations,
                showEXEObservations: this.observationsReport.showEXEObservations,
                showOPRObservations: this.observationsReport.showOPRObservations,
                showOPLObservations: this.observationsReport.showOPLObservations,
                showAPAObservations: this.observationsReport.showAPAObservations,
                showReceiptObservations: this.observationsReport.showReceiptObservations,
                showDeliveryObservations: this.observationsReport.showDeliveryObservations,
                showMaps: this.observationsReport.showMaps,
                showResolved: this.observationsReport.showResolved,
                showStats: this.observationsReport.showStats,
                showValidated: this.observationsReport.showValidated,
                generateAPDFByBundle: this.observationsReport.generateAPDFByBundle,
                date: this.observationsReport.date,
            });
        },
    },
};
</script>
