import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getProjectSyncStatus } from '@/state/state';

export function getProjects() {
    return db.projects
        .find()
        .sort({ code: 1, name: 1 })
        .$.pipe(
            map((projects) =>
                projects.map((project) => ({
                    ...project.toJSON(),
                    tryAllRemaining: project.expireAt
                        ? Math.round((new Date(project.expireAt).getTime() - new Date().getTime()) / 3600 / 1000 / 24)
                        : null,
                    syncState: getProjectSyncStatus(project.id),
                })),
            ),
        );
}

function mapProject(dbProject) {
    if (dbProject) {
        return {
            ...dbProject.toJSON(),
            startDate: dbProject.startDate ? new Date(dbProject.startDate) : null,
            endDate: dbProject.endDate ? new Date(dbProject.endDate) : null,
            expireAt: dbProject.expireAt ? new Date(dbProject.expireAt) : null,
        };
    } else {
        return null;
    }
}
export function getProject(id) {
    return db.projects.findOne({ selector: { id } }).$.pipe(map(mapProject));
}
export async function queryProject(id) {
    const dbProject = await db.projects
        .findOne({
            selector: { id },
        })
        .exec();
    return mapProject(dbProject);
}

function sanitizeProject(project) {
    const result = { ...project };
    if (project.startDate) {
        result.startDate = project.startDate.toISOString();
    }
    if (project.endDate) {
        result.endDate = project.endDate.toISOString();
    }
    if (project.expireAt) {
        result.expireAt = project.expireAt.toISOString();
    }
    return result;
}
export async function updateProject({ id, ...project }) {
    const dbProject = await db.projects
        .findOne({
            selector: { id },
        })
        .exec();
    return dbProject.atomicPatch(sanitizeProject(project));
}

export async function createProject(project, bundle, modules) {
    const token = localStorage.getItem('token');
    const response = await fetch(`/api/projects`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({ project, bundle, modules }),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}
