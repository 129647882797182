var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes }){return [_c('app-picker',{ref:"picker",attrs:{"value":_vm.value,"options":_vm.options,"show-label":_vm.showLabel,"label":_vm.label,"title":_vm.pickerTitle,"label-function":(bundle) => (bundle.reference ? bundle.reference + ' - ' + bundle.name : bundle.name),"filterStringFunction":(bundle) => bundle.reference + ' ' + bundle.name + ' ' + (bundle.company ? bundle.company.name : ''),"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$emit('blur', $event)}},scopedSlots:_vm._u([(_vm.showTip)?{key:"tip",fn:function(){return [_c('div',{staticClass:"text-xs flex"},[(!_vm.value)?_c('div'):(_vm.get(_vm.value, 'company.id'))?[_c('span',[_vm._v(_vm._s(_vm.companyLabel)+" :")]),_c('router-link',{staticClass:"mx-2 text-xs underline",attrs:{"to":{
                            name: 'company',
                            params: {
                                companyId: _vm.get(_vm.value, 'company.id', ''),
                                projectId: _vm.$route.params.projectId,
                            },
                        }}},[_vm._v("\n                        "+_vm._s(_vm.get(_vm.value, 'company.name', ''))+"\n                    ")])]:(_vm.value.id)?_c('router-link',{attrs:{"to":{
                        name: 'bundle',
                        params: {
                            bundleId: _vm.value.id,
                            projectId: _vm.$route.params.projectId,
                        },
                    }}},[_c('span',{staticClass:"mx-2 underline italic text-xs"},[_vm._v("\n                        "+_vm._s(_vm.emptyLabel)+"\n                    ")])]):_vm._e()],2)]},proxy:true}:null,{key:"option",fn:function({ option }){return [_c('app-bundle',{staticClass:"p-2",attrs:{"bundle":option}})]}},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)}),_c('app-errors',{attrs:{"errors":errors}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }