<template>
    <app-popup :title="$t('impacts.title')" variant="alert" ref="popup" :show-header="true">
        <div v-if="impacts === null" class="flex gap-3 w-full p-6">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            {{ $t('impacts.loading') }}
        </div>
        <div class="w-full p-6" v-if="!!impacts">
            <div class="flex flex-col w-full" v-if="Object.values(impacts).find((list) => list.length > 0)">
                <ul class="flex-col flex gap-3">
                    <li>
                        <span>{{ $t('impacts.selectionTip') }} :</span>
                        <ul class="list-disc p-2">
                            <app-impact
                                :length="impacts.services.length"
                                :label="impacts.services.length === 1 ? $t('impacts.service') : $t('impacts.services')"
                                :tooltip="impacts.services.map((service) => service.name).join('\n')"
                                ::no-item-label="$t('impacts.noService')"
                            ></app-impact>
                            <app-impact
                                :length="impacts.directories.length"
                                :label="
                                    impacts.directories.length === 1
                                        ? $t('impacts.directory')
                                        : $t('impacts.directories')
                                "
                                :tooltip="impacts.directories.map((directory) => directory.name).join('\n')"
                            ></app-impact>
                            <app-impact
                                :length="impacts.directTasks.length"
                                :label="
                                    impacts.directTasks.length === 1
                                        ? $t('impacts.directTask')
                                        : $t('impacts.directTasks')
                                "
                                :tooltip="impacts.directTasks.map((task) => task.label).join('\n')"
                                ::no-item-label=" $t('impacts.noDirectTask')"
                            ></app-impact>
                        </ul>
                    </li>
                    <li
                        v-if="
                            impacts.linkedTasks.length ||
                            impacts.certificates.length ||
                            impacts.autoControls.length ||
                            impacts.receptions.length
                        "
                    >
                        {{ $t('impacts.impactsTip') }} :
                        <ul class="list-disc p-2">
                            <app-impact
                                :length="impacts.linkedTasks.length"
                                :label="
                                    impacts.linkedTasks.length === 1
                                        ? $t('impacts.linkedTask')
                                        : $t('impacts.linkedTasks')
                                "
                                :tooltip="impacts.linkedTasks.map((task) => task.label).join('\n')"
                            />
                            <app-impact
                                :length="impacts.certificates.length"
                                :label="
                                    impacts.certificates.length === 1
                                        ? $t('impacts.certificate')
                                        : $t('impacts.certificates')
                                "
                                :tooltip="impacts.certificates.map((certificate) => certificate.name).join('\n')"
                            />
                            <app-impact
                                :length="impacts.autoControls.length"
                                :label="
                                    impacts.autoControls.length === 1
                                        ? $t('impacts.autoControl')
                                        : $t('impacts.autoControls')
                                "
                                :tooltip="impacts.autoControls.map((autoControl) => autoControl.name).join('\n')"
                            />
                            <app-impact
                                :length="impacts.receptions.length"
                                :label="
                                    impacts.receptions.length === 1 ? $t('impacts.reception') : $t('impacts.receptions')
                                "
                                :tooltip="impacts.receptions.map((reception) => reception.name).join('\n')"
                            />
                        </ul>
                    </li>
                </ul>
            </div>
            <span v-else>{{ $t('impacts.noImpact') }}</span>
        </div>
        <app-footer
            @click="
                $refs.popup.close();
                $emit('deleteConfirmed');
            "
            :ok-label="$t('impacts.confirm')"
            class="w-full p-2"
            variant="danger"
            :disabled="impacts === null"
        >
            <template v-slot:left>
                <app-button variant="default" :label="$t('commons.cancel')" size="mini" @click="$refs.popup.close()" />
            </template>
        </app-footer>
    </app-popup>
</template>

<script>
import AppFooter from '@/components/appFooter/AppFooter';
import AppButton from '@/components/appButton/AppButton';
import AppPopup from '@/components/app-popup/AppPopup';
import AppImpact from '@/components/app-impact/AppImpact';

export default {
    components: { AppImpact, AppPopup, AppButton, AppFooter },
    props: ['impacts'],
    methods: {
        open() {
            this.$refs.popup.open();
        },
    },
};
</script>
