import { Quill } from 'vue2-editor';
import ResizeModule from '@ssumo/quill-resize-module';
let Inline = Quill.import('blots/inline');
export class TagBlot extends Inline {
    static blotName = 'tag';
    static className = 'template_keyword';
    static tagName = 'span';
    static formats() {
        return true;
    }
}
Quill.register(TagBlot, true);
Quill.register('modules/resize', ResizeModule);

let BlockEmbed = Quill.import('blots/block/embed');
class TableBlot extends BlockEmbed {
    static blotName = 'table';
    static tagName = 'table';
    static className = 'table';
    constructor(node, html) {
        super(node);
        this.domNode.setAttribute('contenteditable', 'false');
        this.domNode.classList.add('table');
        if (html) {
            this.domNode.innerHTML = html;
        }
    }
    length() {
        return 1;
    }
}
Quill.register(TableBlot);
