<template>
    <main class="w-full px-2 overflow-hidden">
        <iframe :src="meeting.url" class="w-full min-h-main"></iframe>
    </main>
</template>

<script>
import { getMeeting } from './meetings.service';
import { updateBreadCrumbs } from '@/state/state';
export default {
    created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
                updateBreadCrumbs({ meetingName: this.meeting.name });
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            meeting: {},
        };
    },
};
</script>
