import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

export function isDeliveryContactOnly(user) {
    return user.bundleIds.length === 0 && user.allowedFeatures.includes('project_isDeliveryContact');
}

export function getDeliveryContacts(projectId) {
    return getCachedObservable('getDeliveryContacts_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .deliveryContacts.find()
            .$.pipe(map((items) => items.map((item) => (item ? item.toMutableJSON() : null)))),
    );
}
export function removeDeliveryContact(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .deliveryContacts.findOne({ selector: { id: id } })
        .remove();
}

export async function createDeliveryContact(projectId, item) {
    const result = await db.getProjectCollections(projectId).deliveryContacts.insert(item);
    return result.toJSON();
}

export async function updateDeliveryContact(projectId, deliveryContact) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .deliveryContacts.findOne({ selector: { id: deliveryContact.id } })
        .exec();
    return dbAutoControl.atomicPatch(deliveryContact);
}

export function getDeliveryContact(projectId, deliveryContactId) {
    return db
        .getProjectCollections(projectId)
        .deliveryContacts.findOne({ selector: { id: deliveryContactId } })
        .$.pipe(map((item) => (item ? item.toMutableJSON() : null)));
}
