import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapCertificate(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              emissionDueDate: item.emissionDueDate ? new Date(item.emissionDueDate) : null,
              emissionDate: item.emissionDate ? new Date(item.emissionDate) : null,
              obsoleteAt: item.obsoleteAt ? new Date(item.obsoleteAt) : null,
          }
        : null;
}

export function getCertificates(projectId) {
    return getCachedObservable('getCertificates_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .certificates.find()
            .$.pipe(map((certificates) => certificates.map(mapCertificate))),
    );
}
export function removeCertificate(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .certificates.findOne({ selector: { id: id } })
        .remove();
}

export async function createCertificate(projectId, item) {
    const result = await db.getProjectCollections(projectId).certificates.insert({
        locationIds: [],
        attachments: [],
        ...item,
    });
    return result.toJSON();
}

export async function updateCertificate(projectId, certificate) {
    const dbCertificate = await db
        .getProjectCollections(projectId)
        .certificates.findOne({ selector: { id: certificate.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(certificate)) };
    if (certificate.emissionDate === null || !!certificate.emissionDate) {
        patch.emissionDate = certificate.emissionDate ? certificate.emissionDate.toISOString() : null;
    }
    if (certificate.emissionDueDate === null || !!certificate.emissionDueDate) {
        patch.emissionDueDate = certificate.emissionDueDate ? certificate.emissionDueDate.toISOString() : null;
    }
    return dbCertificate.atomicPatch(patch);
}

export function getCertificate(projectId, certificateId) {
    return db
        .getProjectCollections(projectId)
        .certificates.findOne({ selector: { id: certificateId } })
        .$.pipe(map(mapCertificate));
}

export async function filterLocationIds(projectId, certificate, filterFn) {
    const dbCertificate = await db
        .getProjectCollections(projectId)
        .certificates.findOne({ selector: { id: certificate.id } })
        .exec();
    return dbCertificate.atomicPatch({
        locationIds: dbCertificate.locationIds.filter(filterFn),
    });
}

export function createCertificatePredecessors(projectId, certificate, predecessors) {
    return updateCertificate(projectId, {
        id: certificate.id,
        predecessors: [
            ...certificate.predecessors.map((predecessor) => ({
                taskId: predecessor.taskId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
            ...predecessors,
        ],
    });
}

export function removeCertificatePredecessors(projectId, certificate, taskIds) {
    return updateCertificate(projectId, {
        id: certificate.id,
        predecessors: certificate.predecessors
            .filter((predecessor) => !taskIds.includes(predecessor.taskId))
            .map((predecessor) => ({
                taskId: predecessor.taskId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}

export function updateCertificatePredecessor(projectId, certificate, predecessor) {
    return updateCertificate(projectId, {
        id: certificate.id,
        predecessors: certificate.predecessors
            .map((aPredecessor) =>
                aPredecessor.taskId === predecessor.taskId ? { ...aPredecessor, ...predecessor } : aPredecessor,
            )
            .map((predecessor) => ({
                taskId: predecessor.taskId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}
export default {
    filterLocationIds,
    updateCertificate,
};
