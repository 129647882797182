var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col",staticStyle:{"page-break-before":"always"}},[_c('app-separator',{attrs:{"tag":"h1","label":_vm.$t('project.planning.planningTitle') +
            ' ' +
            _vm.$t('commons.from') +
            ' ' +
            _vm.humanizeDate(_vm.meeting.planningStartDate) +
            ' ' +
            _vm.$t('commons.to') +
            ' ' +
            _vm.humanizeDate(_vm.meeting.planningEndDate)}}),_c('table-gantt',{staticClass:"overflow-hidden w-full mx-1 text-2xs",attrs:{"tasks":_vm.tasks,"options":_vm.options,"agenda":_vm.agenda,"minDate":_vm.meeting.planningStartDate,"maxDate":_vm.meeting.planningEndDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }