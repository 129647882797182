<template>
    <div class="px-4">
        <table v-if="service">
            <tr v-if="service.reference">
                <th role="rowheader" class="p-2 text-right">{{ $t('commons.name') }} :</th>
                <td class="p-2">{{ service.name }}</td>
            </tr>
            <tr v-if="service.reference">
                <th role="rowheader" class="p-2 text-right">{{ $t('services.reference') }} :</th>
                <td class="p-2">{{ service.reference }}</td>
            </tr>
            <tr v-if="service.amount">
                <th role="rowheader" class="p-2 text-right">{{ $t('services.amount') }} :</th>
                <td class="p-2">{{ $n(service.amount, 'currency') }}</td>
            </tr>
            <tr>
                <th role="rowheader" class="p-2 text-right">{{ $t('services.isExecution') }} :</th>
                <td class="p-2" v-if="service.isExecution">{{ $t('services.duringExecution') }}</td>
                <td class="p-2" v-else>{{ $t('services.duringPreparation') }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getService } from './services.service';
export default {
    async created() {
        this.init();
    },
    watch: {
        $route() {
            this.init();
        },
    },
    props: ['serviceId'],
    data() {
        return { service: {} };
    },
    methods: {
        init() {
            getService(this.$route.params.projectId, this.$route.params.serviceId || this.serviceId).subscribe(
                (service) => {
                    this.service = service;
                },
            );
        },
    },
};
</script>
