<template>
    <div>
        <div v-if="!isOpen && (!value || !value.trim().length)" @click="open()" :class="disabled ? '' : 'underline'">
            ...
        </div>
        <div
            v-else-if="!isOpen && value && value.trim().length"
            @click="open()"
            class="whitespace-pre hover:underline"
            v-text="value.trim()"
        ></div>
        <div
            :class="{ hidden: !isOpen }"
            :contenteditable="isOpen && !disabled"
            ref="editable"
            @input="onInput"
            @blur="close"
            style="min-height: 2rem; min-width: 20rem"
            class="border p-1"
        >
            {{ value }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: String,
        disabled: Boolean,
    },
    mounted() {
        this.$refs.editable.innerText = this.value ? this.value.trim() : '';
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.innerText.trim());
        },
        open() {
            if (!this.disabled) {
                this.isOpen = true;
                this.$nextTick(() => this.$refs.editable.focus());
            }
        },
        close() {
            this.isOpen = false;
        },
    },
};
</script>
