<template>
    <app-popup :title="$t('impacts.title')" variant="alert" ref="popup" :show-header="true">
        <div v-if="impacts === null" class="flex gap-3 w-full p-6">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            {{ $t('impacts.loading') }}
        </div>
        <div class="flex flex-col w-full p-6" v-if="!!impacts">
            <ul class="flex-col flex gap-3">
                <li>
                    <span>{{ $t('impacts.selectionTip') }} :</span>
                    <ul class="list-disc p-2">
                        <app-impact
                            :length="1"
                            :label="$t('impacts.directTask')"
                            :tooltip="impacts.removedTask.label"
                        ></app-impact>
                        <app-impact
                            :length="impacts.linkedTasks.length"
                            :label="
                                impacts.linkedTasks.length === 1 ? $t('impacts.linkedTask') : $t('impacts.linkedTasks')
                            "
                            :tooltip="impacts.linkedTasks.map((task) => task.label).join('\n')"
                            :no-item-label="$t('impacts.noPredecessor')"
                        />
                    </ul>
                </li>
            </ul>
        </div>
        <app-footer
            @click="
                $refs.popup.close();
                $emit('deleteConfirmed');
            "
            :ok-label="$t('impacts.confirm')"
            class="w-full p-2"
            variant="danger"
            :disabled="impacts === null"
        >
            <template v-slot:left>
                <app-button variant="default" :label="$t('commons.cancel')" size="mini" @click="$refs.popup.close()" />
            </template>
        </app-footer>
    </app-popup>
</template>

<script>
import AppFooter from '@/components/appFooter/AppFooter';
import AppButton from '@/components/appButton/AppButton';
import AppPopup from '@/components/app-popup/AppPopup';
import AppImpact from '@/components/app-impact/AppImpact';

export default {
    components: { AppImpact, AppPopup, AppButton, AppFooter },
    props: ['impacts'],
    methods: {
        open() {
            this.$refs.popup.open();
        },
    },
};
</script>
