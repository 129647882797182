<template>
    <app-input-text
        :label="$t('users.phone')"
        :value="value"
        :uid="uid"
        rules="numeric"
        size="10"
        placeholder="0600000000"
        @input="onInput"
        @paste="onPaste"
        @keyup="$emit('keyup', $event)"
        :show-label="showLabel"
        :show-errors="showErrors"
        :disabled="disabled"
        :required="required"
        :minLength="10"
        :maxLength="10"
    >
        <template v-slot:tip>
            <slot name="tip" />
        </template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';
export default {
    props: {
        disabled: Boolean,
        required: Boolean,
        value: String | Number,
        uid: String | Number,
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    components: { AppInputText },
    methods: {
        onInput(value) {
            this.$emit('input', value ? value.replace('+', '00').replace(/[^0-9]/g, '') : value);
        },
        onPaste(event) {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            paste = paste.replace('+', '00').replace(/[^0-9]/g, '');
            this.$emit('input', paste);
            event.preventDefault();
        },
    },
};
</script>
