var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full m-4"},[_c('app-report-header',{attrs:{"document-name":_vm.preparation.phase === 'exe'
                ? _vm.$t('project.editions.preparationVisa.documentNameExe')
                : _vm.$t('project.editions.preparationVisa.documentNameCon'),"documentTitle":_vm.documentTitle}}),_c('preparation-visa-summary-print'),_vm._l((_vm.pages),function(page){return _c('div',{staticStyle:{"page-break-before":"always"}},[_c('a',{attrs:{"href":page.url}},[_c('app-separator',{staticClass:"font-bold mb-2",attrs:{"variant":"underline","label":page.name + (page.page ? ' (page ' + page.page + ')' : ''),"icon":"icon-file-document-outline"}})],1),(page.page)?_c('div',{staticStyle:{"height":"1380px","width":"100%"}},[_c('app-leaflet-viewer',{staticClass:"w-full h-full text-xs",attrs:{"src":page.url,"page":page.page,"shapes":page.observations
                        .filter((obs) => obs.footprint)
                        .map((obs) => ({
                            ...obs.footprint,
                            label: obs.index + 1 + '',
                            style: { color: 'red', stroke: 1 },
                        })),"preview":true}})],1):_vm._e(),(page.observations.length)?_c('table',{staticClass:"border-2 border-collapse m-5 table-fixed",style:({ 'page-break-before': page.page ? 'always' : 'none', width: 'calc(100% - 1.25 * 2rem)' })},[_vm._l((page.observations),function(observation){return [_c('tr',{staticClass:"border-2",staticStyle:{"page-break-inside":"avoid"}},[_c('td',{staticClass:"border-2"},[_c('div',{staticClass:"flex flex-wrap p-2"},[_c('div',[_c('div',{staticClass:"font-bold text-lg"},[_vm._v("#"+_vm._s(observation.index + 1))]),_c('div',[_c('span',{staticClass:"whitespace-pre-wrap html-breakline",domProps:{"innerHTML":_vm._s(observation.title)}})])]),(observation.attachments.length > 0)?_c('app-leaflet-viewer',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":observation.attachments[0].url,"shapes":observation.attachments[0].shapes,"preview":true,"image-full-size":true}}):_vm._e()],1)])])]})],2):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }