import { getAPIHeaders } from '@/services/api.service';

export async function approveCGU() {
    const response = await fetch(
        new Request('/api/approveCGU', {
            method: 'POST',
            headers: getAPIHeaders(),
        }),
    );
    return response.ok;
}
