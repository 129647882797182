<template>
    <div class="flex flex-col" style="page-break-before: always">
        <app-separator
            tag="h1"
            :label="
                $t('project.planning.planningTitle') +
                ' ' +
                $t('commons.from') +
                ' ' +
                humanizeDate(meeting.planningStartDate) +
                ' ' +
                $t('commons.to') +
                ' ' +
                humanizeDate(meeting.planningEndDate)
            "
        ></app-separator>
        <table-gantt
            :tasks="tasks"
            :options="options"
            class="overflow-hidden w-full mx-1 text-2xs"
            :agenda="agenda"
            :minDate="meeting.planningStartDate"
            :maxDate="meeting.planningEndDate"
        ></table-gantt>
    </div>
</template>

<script>
import { queryMeeting } from './meetings.service';
import TableGantt from '@/components/gantt/TableGantt';
import { populateTree } from '@/features/planning/planning/planning.service';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { excludeOutOfDateRangeTasks, mapTasks } from '@/components/gantt/ganttService';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { humanizeDate } from '@/filters/dateFilter';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getProject } from '@/features/projects/projects.service';
import { getLastReference } from '@/features/planning/references/references.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getDirectories } from '@/features/services/directories.service';

export default {
    components: {
        AppSeparator,
        TableGantt,
    },
    async created() {
        const meeting = await queryMeeting(this.$route.params.projectId, this.$route.params.meetingId);
        this.subscriptions = [
            combineLatest([
                getBundles(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getLocationsTree(this.$route.params.projectId),
                getLastReference(this.$route.params.projectId),
                getDirectories(this.$route.params.projectId),
            ]).subscribe(([bundles, project, tasks, folders, lastReference, directories]) => {
                this.project = project;
                this.bundles = bundles;
                this.meeting = {
                    ...meeting,
                    planningStartDate: meeting.planningStartDate || subDays(meeting.date, 7),
                    planningEndDate: meeting.planningEndDate || addDays(meeting.date, 21),
                    planningStructure: meeting.planningStructure || 'FLB',
                    planningScale: meeting.planningScale || 'months',
                };
                const taskTree = populateTree(
                    tasks,
                    lastReference,
                    bundles,
                    folders,
                    directories,
                    this.meeting.planningStructure,
                    this.agenda,
                    this.meeting.date,
                    'date',
                );
                this.tasks = excludeOutOfDateRangeTasks(
                    mapTasks(taskTree, this.meeting.date),
                    this.meeting.planningStartDate,
                    this.meeting.planningEndDate,
                );
            }),
        ];
    },
    computed: {
        options() {
            return {
                height: 1024,
                scale: this.meeting.planningScale || 'weeks',
                structure: this.meeting.planningStructure || 'FLB',
                minDate: this.meeting.planningStartDate,
                maxDate: this.meeting.planningEndDate,
                showLineNumbers: true,
                showProgressLine: true,
                refDate: this.meeting.date,
                showCollapseButtons: false,
                showReference: true,
                showReferenceData: true,
                showRealData: true,
                showLateColumn: false,
                showQuantityColumn: false,
                showDurationColumn: false,
                showPlannedData: true,
                showPlanned: true,
                showReal: true,
                showProgressColumn: true,
                progressReportedTo: 'reference',
            };
        },
    },
    methods: {
        humanizeDate,
    },
    data() {
        return { subscriptions: [], agenda: [], meeting: {}, tasks: [] };
    },
};
</script>
