<template>
    <div class="flex justify-center p-1">
        <div class="flex-grow"></div>
        <app-button
            class=""
            size="mini"
            icon="icon-format-list-bulleted"
            :variant="value === 'list' ? 'selected' : 'default'"
            :label="$t('observations.tourLists.list.title')"
            @click="changeViewMode('list')"
        ></app-button>
        <app-button
            class=""
            icon="icon-map-marker"
            size="mini"
            :variant="value === 'map' ? 'selected' : 'default'"
            :label="$t('observations.tourLists.map.title')"
            @click="changeViewMode('map')"
        ></app-button>
        <div class="flex-grow"></div>
    </div>
</template>

<script>
import AppButton from '@/components/appButton/AppButton';

export default {
    components: { AppButton },
    props: ['value'],
    methods: {
        changeViewMode(to) {
            this.$emit('input', to);
        },
    },
};
</script>
