<template>
    <app-mail-popup ref="popup" label="Envoyer le compte-rendu">
        <div>
            <app-select
                :value="selectedMailTemplateId"
                @change="onSelectedMailTemplateChanged"
                :label="$t('project.diffusion.chooseMailTemplateLabel')"
            >
                <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">{{ mailTemplate.name }}</option>
            </app-select>
        </div>
    </app-mail-popup>
</template>

<script>
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppMailPopup from '@/features/planningExports/AppMailPopup.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';

export default {
    components: {
        AppMailPopup,
        AppSelect,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'preparationsReport');
            }),
        ];
    },
    methods: {
        open(preparationsReport, relevantBundleIds) {
            this.preparationsReport = preparationsReport;
            this.relevantBundleIds = relevantBundleIds;
            this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
            this.$refs.popup.open();
        },
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            const reportLink = `https://app.atex-info.eu/api/projects/${this.$route.params.projectId}/preparationsReports/${this.preparationsReport.id}/report.pdf`;
            return string
                .replaceAll('NomRapport', this.preparationsReport.name)
                .replaceAll('LienRapport', `<a href="${reportLink}">${this.preparationsReport.name + '.pdf'}</a>`);
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: [...template.to, ...this.relevantBundleIds],
                copyIds: template.cc,
                attachments: [
                    {
                        fileName: this.preparationsReport.name + '.pdf',
                        url: this.preparationsReport.url,
                    },
                ],
            });
        },
    },
    data() {
        return {
            cacheKey: 'preparationsReport.lastMailTemplate.' + this.$route.params.projectId,
            preparationsReport: null,
            subscriptions: [],
            mailTemplates: [],
            selectedMailTemplateId: null,
        };
    },
};
</script>
