export function saveLastMailTemplateId(cacheKey, id) {
    localStorage.setItem(cacheKey, id);
}
export function getLastMailTemplateId(cacheKey, mailTemplates) {
    const lastSelectedMailTemplateId = localStorage.getItem(cacheKey);
    let result;
    if (lastSelectedMailTemplateId) {
        result = mailTemplates.find((mailTemplate) => mailTemplate.id === lastSelectedMailTemplateId);
    }
    if (!result) {
        result = mailTemplates.find((mailTemplate) => !mailTemplate.isCustom);
    }
    return result.id;
}
