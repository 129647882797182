<template>
    <ValidationObserver v-slot="{ errors, invalid, touched }" class="h-full block">
        <form class="p-2 flex flex-col">
            <app-multi-picker
                v-if="!readOnly"
                :value="selectedPredecessors"
                @check="createPredecessors($event)"
                @uncheck="removePredecessorsFromServiceId($event)"
                :options="serviceOptions"
                variant="button"
                :show-label="false"
                :show-input="false"
                :label="$t('services.newPredecessor')"
                :title="$t('services.newPredecessor')"
            ></app-multi-picker>
            <table class="flex-grow mb-10 text-sm table-fixed">
                <thead>
                    <tr class="border-b">
                        <th scope="col" class="p-2">{{ $t('services.predecessor') }}</th>
                        <th
                            scope="col"
                            class="p-2"
                            :style="{
                                width: '72px',
                            }"
                        >
                            {{ $t('services.predecessorType') }}
                        </th>
                        <th
                            scope="col"
                            class="p-2"
                            :style="{
                                width: $t('services.delay').length + 'ch',
                            }"
                        >
                            {{ $t('services.delay') }}
                        </th>
                        <th scope="col" class="p-2" style="width: 35px"></th>
                    </tr>
                </thead>
                <tr v-if="service && service.predecessors.length === 0">
                    <td colspan="4" class="p-2 text-sm italic text-center">
                        {{ $t('services.emptyPredecessors') }}
                    </td>
                </tr>
                <template v-for="predecessor in predecessors">
                    <tr :v-key="predecessor.id" class="border-b">
                        <td class="p-2" :class="{ 'text-red-700': predecessor.isLoopError }">
                            <div class="flex items-center gap-1">
                                <div :title="predecessor.path">{{ predecessor.path }}</div>
                                <span
                                    v-if="predecessor.isLoopError"
                                    :title="$t('project.follow.planning.error.predecessor-loop')"
                                >
                                    <icon-all-inclusive width="16" height="16" />
                                </span>
                            </div>
                        </td>
                        <td class="p-2 text-center">
                            <app-drop-down-button
                                :label="predecessor.typeLabel"
                                :show-label="false"
                                :disabled="readOnly"
                                :icon="'icon-gantt' + predecessor.type.toLowerCase()"
                                :title="predecessor.typeLabel"
                                @input="updatePredecessor({ ...predecessor, type: $event })"
                            >
                                <option value="ES">
                                    {{ $t('services.predecessorTypeES') }}
                                </option>
                                <option value="SS">
                                    {{ $t('services.predecessorTypeSS') }}
                                </option>
                                <option value="EE">
                                    {{ $t('services.predecessorTypeEE') }}
                                </option>
                            </app-drop-down-button>
                        </td>
                        <td class="p-2 text-center">
                            <div class="flex justify-center">
                                <app-number-link
                                    v-model="predecessor.delay"
                                    :disabled="readOnly"
                                    :positiveOnly="false"
                                    @input="updatePredecessor(predecessor)"
                                >
                                    <span :class="{ underline: !readOnly }">{{ predecessor.delay }}</span>
                                </app-number-link>
                            </div>
                        </td>
                        <td class="p-2 flex items-center">
                            <app-button
                                size="mini"
                                @click.prevent="removePredecessor(predecessor)"
                                icon="icon-trash-can-outline"
                                variant="danger"
                                aria-label="delete predecessor"
                                v-if="!readOnly"
                            />
                        </td>
                    </tr>
                </template>
            </table>
        </form>
    </ValidationObserver>
</template>

<script>
import { confirm } from '../dialogs/dialogs.service';
import AppButton from '../../components/appButton/AppButton';
import {
    createServicePredecessors,
    getServices,
    removeServicePredecessor,
    removeServicePredecessors,
    updateServicePredecessor,
} from './services.service';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { getServiceSuccessorIds } from '@/features/tasks/plannedTasks.service';
import { reportError } from '@/features/tracker/tracker.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { sortBy } from '@/services/sanitize.service';

export default {
    components: { AppDropDownButton, AppNumberLink, AppMultiPicker, AppButton },
    props: ['serviceId', 'readOnly'],
    data() {
        return { subscriptions: [], services: [] };
    },
    created() {
        this.subscriptions = [
            combineLatest([
                getServices(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
            ]).subscribe(([services, bundleMap]) => {
                this.services = services.map((service) => ({ ...service, bundle: bundleMap[service.bundleId] }));
            }),
        ];
    },
    computed: {
        service() {
            return this.services.find((service) => service.id === this.serviceId);
        },
        successors() {
            if (this.service) {
                return getServiceSuccessorIds(this.service, this.services);
            } else {
                return [];
            }
        },
        serviceOptions() {
            return sortBy(
                this.services
                    .map((service) => ({
                        ...service,
                        name: service.bundle.label + ' > ' + service.name,
                        disabled: this.successors.includes(service.id),
                    }))
                    .filter((service) => service.id !== this.serviceId),
                'name',
            );
        },
        selectedPredecessors() {
            if (this.service) {
                return this.serviceOptions.filter((serviceOption) =>
                    this.service.predecessors.find((predecessor) => predecessor.serviceId === serviceOption.id),
                );
            } else {
                return [];
            }
        },
        predecessors() {
            if (this.service) {
                return this.service.predecessors
                    .map((predecessor) => {
                        const service = this.services.find((aService) => aService.id === predecessor.serviceId);
                        if (service) {
                            const path = service ? service.bundle.label + ' > ' + service.name : '';
                            const typeLabel = {
                                EE: this.$t('services.predecessorTypeEE'),
                                ES: this.$t('services.predecessorTypeES'),
                                SS: this.$t('services.predecessorTypeSS'),
                            }[predecessor.type];

                            return {
                                ...predecessor,
                                typeLabel,
                                path,
                                isLoopError: this.successors.includes(predecessor.serviceId),
                            };
                        } else {
                            reportError(
                                'missing service ' + predecessor.serviceId + ' referenced by ' + this.service.id,
                            );
                            return null;
                        }
                    })
                    .filter((a) => !!a);
            } else {
                return [];
            }
        },
    },
    methods: {
        async removePredecessor(predecessor) {
            if (await confirm(this.$t('services.confirmPredecessorMessage'))) {
                return removeServicePredecessor(this.$route.params.projectId, this.service, predecessor.serviceId);
            }
        },
        async removePredecessorsFromServiceId(services) {
            return removeServicePredecessors(
                this.$route.params.projectId,
                this.service,
                services.map((service) => service.id),
            );
        },
        createPredecessors(services) {
            return createServicePredecessors(
                this.$route.params.projectId,
                this.service,
                services.map((service) => ({
                    serviceId: service.id,
                    type: 'ES',
                    delay: 0,
                })),
            );
        },
        updatePredecessor(predecessor) {
            return updateServicePredecessor(this.$route.params.projectId, this.service, {
                serviceId: predecessor.serviceId,
                type: predecessor.type,
                delay: predecessor.delay,
            });
        },
    },
};
</script>
