import subDays from 'date-fns/subDays';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import addDays from 'date-fns/addDays';
import { BehaviorSubject, map } from 'rxjs';
import database from '@/rxdb/database';
import db from '@/rxdb/database';
import { groupBy } from '@/services/sanitize.service';
import { v4 as uuidv4 } from 'uuid';

export const isMobile = Math.min(window.innerWidth, window.innerHeight) <= 640;
console.log(Math.min(window.innerWidth, window.innerHeight), window.innerWidth, window.innerHeight);
export const isTablet = Math.min(window.innerWidth, window.innerHeight) <= 768;

let breadCrumbs = {};
let breadCrumbsObservable = new BehaviorSubject({});
export const updateBreadCrumbs = (crumb) => {
    breadCrumbs = { ...breadCrumbs, ...crumb };
    breadCrumbsObservable.next(breadCrumbs);
};
export function getBreadCrumbs(result) {
    breadCrumbsObservable.subscribe(result);
}

let menuState = {
    isOpen: true,
    isPortrait: false,
};
export let menuStateObservable = new BehaviorSubject(menuState);
export const updateMenuState = (state) => {
    menuState = { ...menuState, ...state };
    menuStateObservable.next(menuState);
};
export function getMenuState(result) {
    return menuStateObservable.subscribe(result);
}

export const updatePlanningState = (projectId, planningState) => {
    localStorage.setItem(
        'planningState_' + projectId,
        JSON.stringify({ ...queryPlanningState(projectId), ...planningState }),
    );
};
export const networkStatus = new BehaviorSubject(navigator.onLine);
window.addEventListener('online', () => networkStatus.next(true));
window.addEventListener('offline', () => networkStatus.next(false));

export const upgradeStatus = new BehaviorSubject(false);

export function getProjectsSyncStatus() {
    return db.syncStates.find({}).$.pipe(
        map((states) => {
            const groups = groupBy(
                states.map((state) => state.toJSON()),
                'projectId',
            );
            return groups.map((group) => collectionsStatesToProjectStates(group[0].projectId, group));
        }),
    );
}
export function getProjectSyncStatus(projectId) {
    return db.syncStates.find({ selector: { projectId } }).$.pipe(
        map((states) =>
            collectionsStatesToProjectStates(
                projectId,
                states.map((state) => state.toJSON()),
            ),
        ),
    );
}

function collectionsStatesToProjectStates(projectId, collectionsStates) {
    if (!collectionsStates || collectionsStates.length === 0) {
        return { projectId, state: 'unknown' };
    }
    return collectionsStates
        .map((state) => ({
            ...state,
            lastSuccess: state.lastSuccess ? new Date(state.lastSuccess) : null,
            lastTry: state.lastTry ? new Date(state.lastTry) : null,
        }))
        .reduce(
            (acc, state) => {
                let lastSuccess = acc.lastSuccess;
                if (state.lastSuccess && lastSuccess && state.lastSuccess < lastSuccess) {
                    lastSuccess = state.lastSuccess;
                }
                let worseState = acc.state;
                if (state.state === 'error') {
                    worseState = state.state;
                } else if (state.state === 'pending' && acc.state !== 'error') {
                    worseState = state.state;
                }
                return { projectId, lastSuccess, state: worseState, collectionsStates };
            },
            { projectId, lastSuccess: new Date(), state: 'ok', collectionsStates },
        );
}
let lastUpdate = Promise.resolve();
export function setCollectionSyncStatus(projectId, collectionName, { state }) {
    lastUpdate = lastUpdate.then(async () => {
        const stateToAdd = {
            projectId,
            collectionName,
            state,
            lastSuccess: null,
            lastTry: new Date().toISOString(),
        };
        const collectionState = await database.syncStates.findOne({ selector: { projectId, collectionName } }).exec();
        if (collectionState) {
            if (state === 'ok') {
                await collectionState.atomicPatch({ ...stateToAdd, lastSuccess: new Date().toISOString() });
            } else {
                await collectionState.atomicPatch(stateToAdd);
            }
        } else {
            await database.syncStates.insert({ id: uuidv4(), ...stateToAdd });
        }
    });
}

export async function setProjectsCollectionSyncStatus(state) {
    const projects = await database.syncStates.find({ selector: { collectionName: 'projects' } }).exec();
    return Promise.all(projects.map((project) => project.atomicPatch(state)));
}
export function resetPending() {
    return database.syncStates.find({ selector: { state: 'pending' } }).update({ $set: { state: 'unknown' } });
}

export function queryPlanningState(projectId) {
    const item = localStorage.getItem('planningState_' + projectId);
    return item
        ? JSON.parse(item)
        : {
              detailIsOpen: true,
              detailTabOpen: null,
              scale: 'days',
              structure: 'FLB',
              minDate: new Date(),
              selectedItemId: null,
              filter: null,
              refDate: new Date(),
              showProgressLine: true,
              showLateColumn: true,
              showQuantityColumn: false,
              showDurationColumn: false,
              showProgressColumn: true,
              showCollapseButtons: true,
              showLineNumbers: false,
              showReference: false,
              progressReportedTo: 'reference',
              showReal: true,
              showPlanned: true,
              showReferenceData: false,
              showRealData: true,
              showPlannedData: true,
              showOutOfRangeLines: true,
              considerCollapsed: true,
              considerFilter: true,
              serviceDetailTabOpen: null,
              referenceId: null,
              sortKey: 'date',
          };
}

export function saveDateRange(projectId, startDate, endDate) {
    if (startDate && endDate) {
        localStorage.setItem('DateRange.startDate.' + projectId, startDate.toISOString());
        localStorage.setItem('DateRange.endDate.' + projectId, endDate.toISOString());
    } else {
        localStorage.setItem('DateRange.startDate.' + projectId, subDays(startOfISOWeek(new Date()), 7).toISOString());
        localStorage.setItem('DateRange.endDate.' + projectId, addDays(startOfISOWeek(new Date()), 13).toISOString());
    }
}

export function getLastDateRange(projectId) {
    const cachedStartDate = localStorage.getItem('DateRange.startDate.' + projectId);
    const cachedEndDate = localStorage.getItem('DateRange.endDate.' + projectId);
    if (cachedStartDate && cachedEndDate) {
        return {
            startDate: new Date(cachedStartDate),
            endDate: new Date(cachedEndDate),
        };
    } else {
        return {
            startDate: subDays(startOfISOWeek(new Date()), 7),
            endDate: addDays(startOfISOWeek(new Date()), 13),
        };
    }
}
