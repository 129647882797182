<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col gap-2 items-start min-h-main">
        <div class="flex w-full gap-2">
            <app-button
                icon="icon-chevron-left"
                :label="$t('contracts.backToList')"
                @click="$router.push({ name: 'contracts' })"
            />
        </div>
        <div class="flex w-full flex-grow flex-col relative items-center">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <div v-else class="flex flex-col gap-2 w-1/2">
                <app-separator>{{ $t('contracts.contract') }}</app-separator>
                <table>
                    <tr>
                        <th class="p-2 text-right">
                            <app-label :label="$t('contracts.code') + ' : '" for="code"></app-label>
                        </th>
                        <td class="p-2">
                            <input id="for" :value="contract.code" type="text" size="26" readonly="readonly" />
                        </td>
                    </tr>
                    <tr>
                        <th class="p-2 text-right align-top">
                            <app-label :label="$t('contracts.contractor') + ' : '"></app-label>
                        </th>
                        <td class="p-2">
                            <div class="flex flex-col">
                                {{ contract.contractor.firstName }} {{ contract.contractor.lastName }}
                                <a :href="'mailto:' + contract.contractor.email">{{ contract.contractor.email }}</a>
                                <app-phone :number="contract.contractor.phone" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="p-2 text-right">
                            <app-label :label="$t('contracts.created') + ' : '"></app-label>
                        </th>
                        <td class="p-2">
                            {{ contract.createdAt | humanizeDate }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-2 text-right">
                            <app-label :label="$t('contracts.proposedAt') + ' : '"></app-label>
                        </th>
                        <td class="p-2">
                            <app-date-link v-model="contract.proposedAt" @input="save"></app-date-link>
                        </td>
                    </tr>
                    <tr>
                        <th class="p-2 text-right">
                            <app-label :label="$t('contracts.expireAt') + ' : '"></app-label>
                        </th>
                        <td
                            class="p-2"
                            :class="{
                                'text-red-600': contract.expireAt && contract.expireAt.getTime() < Date.now(),
                            }"
                        >
                            <app-date-link v-model="contract.expireAt" @input="save" />
                        </td>
                    </tr>
                </table>

                <app-separator :label="$t('contracts.prices')"></app-separator>
                <table class="text-sm mx-2 border-black">
                    <tr>
                        <th class="border border-r-black p-1">Modules</th>
                        <th class="border p-1">
                            Coût fixe
                            <br />
                            publique (€)
                        </th>
                        <th class="border p-1">Coût fixe (€)</th>
                        <th class="border border-r-black p-1">Ecart</th>
                        <th class="border p-1">
                            Coût Variable
                            <br />
                            publique (€/m²)
                        </th>
                        <th class="border p-1">
                            Coût Variable
                            <br />
                            (€/m²)
                        </th>
                        <th class="border border-r-black p-1">Ecart</th>
                    </tr>
                    <tr>
                        <td class="border border-r-black p-1">
                            <div class="flex gap-2">
                                <app-checkbox v-model="isCONSelected" />
                                Conception
                            </div>
                        </td>
                        <td class="border p-1 text-center">{{ $n(CONDefaultPrice.price, currencyFormat) }}</td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="CONPrice.price" class="underline">
                                {{ $n(CONPrice.price, currencyFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((CONPrice.price - CONDefaultPrice.price) / CONDefaultPrice.price) * 100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                        <td class="border p-1 text-center">
                            {{ $n(CONDefaultPrice.projectSizePrice, decimalFormat) }}
                        </td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="CONPrice.projectSizePrice" class="underline" format="decimal">
                                {{ $n(CONPrice.projectSizePrice, decimalFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((CONPrice.projectSizePrice - CONDefaultPrice.projectSizePrice) /
                                        CONDefaultPrice.projectSizePrice) *
                                        100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                    </tr>
                    <tr>
                        <td class="border border-r-black p-1">
                            <div class="flex gap-2">
                                <app-checkbox v-model="isEXESelected" />
                                Execution
                            </div>
                        </td>
                        <td class="border p-1 text-center">{{ $n(EXEDefaultPrice.price, currencyFormat) }}</td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="EXEPrice.price" class="underline">
                                {{ $n(EXEPrice.price, currencyFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((EXEPrice.price - EXEDefaultPrice.price) / EXEDefaultPrice.price) * 100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                        <td class="border p-1 text-center">
                            {{ $n(EXEDefaultPrice.projectSizePrice, decimalFormat) }}
                        </td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="EXEPrice.projectSizePrice" class="underline" format="decimal">
                                {{ $n(EXEPrice.projectSizePrice, decimalFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((EXEPrice.projectSizePrice - EXEDefaultPrice.projectSizePrice) /
                                        EXEDefaultPrice.projectSizePrice) *
                                        100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                    </tr>
                    <tr>
                        <td class="border border-r-black p-1">
                            <div class="flex gap-2">
                                <app-checkbox v-model="isOPRSelected" />
                                OPR & Réception
                            </div>
                        </td>
                        <td class="border p-1 text-center">{{ $n(OPRDefaultPrice.price, currencyFormat) }}</td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="OPRPrice.price" class="underline">
                                {{ $n(OPRPrice.price, currencyFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((OPRPrice.price - OPRDefaultPrice.price) / OPRDefaultPrice.price) * 100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                        <td class="border p-1 text-center">
                            {{ $n(OPRDefaultPrice.projectSizePrice, decimalFormat) }}
                        </td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="OPRPrice.projectSizePrice" class="underline" format="decimal">
                                {{ $n(OPRPrice.projectSizePrice, decimalFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((OPRPrice.projectSizePrice - OPRDefaultPrice.projectSizePrice) /
                                        OPRDefaultPrice.projectSizePrice) *
                                        100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                    </tr>
                    <tr>
                        <td class="border border-r-black p-1">
                            <div class="flex gap-2">
                                <app-checkbox v-model="isOPLSelected" />
                                OPL & Livraison
                            </div>
                        </td>
                        <td class="border p-1 text-center">{{ $n(OPLDefaultPrice.price, currencyFormat) }}</td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="OPLPrice.price" class="underline">
                                {{ $n(OPLPrice.price, currencyFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((OPLPrice.price - OPLDefaultPrice.price) / OPLDefaultPrice.price) * 100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                        <td class="border p-1 text-center">
                            {{ $n(OPLDefaultPrice.projectSizePrice, decimalFormat) }}
                        </td>
                        <td class="border p-1 text-center">
                            <app-number-link v-model="OPLPrice.projectSizePrice" class="underline" format="decimal">
                                {{ $n(OPLPrice.projectSizePrice, decimalFormat) }}
                            </app-number-link>
                        </td>
                        <td class="border border-r-black p-1 text-center">
                            {{
                                $n(
                                    ((OPLPrice.projectSizePrice - OPLDefaultPrice.projectSizePrice) /
                                        OPLDefaultPrice.projectSizePrice) *
                                        100,
                                    decimalFormat,
                                )
                            }}%
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7">
                            <div class="flex gap-2">
                                Exemple pour
                                <app-number-link v-model="projectSize" class="underline" />
                                (m²)
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-right">Tarif proposé :</td>
                        <td class="text-left">
                            <span>
                                {{ $n(total, currencyFormat) }} ({{ $n(total - defaultTotal, currencyFormat) }})
                            </span>
                        </td>
                        <td colspan="6">
                            <div class="flex gap-2">
                                <div class="flex gap-1">
                                    <span v-if="isCONSelected">
                                        CON({{ CONPrice.price + CONPrice.projectSizePrice * projectSize }})
                                        <span v-if="isEXESelected || isOPRSelected || isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isEXESelected">
                                        EXE({{ EXEPrice.price + EXEPrice.projectSizePrice * projectSize }})
                                        <span v-if="isOPRSelected || isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isOPRSelected">
                                        OPR({{ OPRPrice.price + OPRPrice.projectSizePrice * projectSize }})
                                        <span v-if="isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isOPLSelected">
                                        OPL({{ OPLPrice.price + OPLPrice.projectSizePrice * projectSize }})
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-right">Tarif Publique :</td>
                        <td class="text-left">
                            <span>{{ $n(defaultTotal, currencyFormat) }}</span>
                        </td>
                        <td colspan="5">
                            <div class="flex gap-2">
                                <div class="flex gap-1">
                                    <span v-if="isCONSelected">
                                        CON({{
                                            CONDefaultPrice.price + CONDefaultPrice.projectSizePrice * projectSize
                                        }})
                                        <span v-if="isEXESelected || isOPRSelected || isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isEXESelected">
                                        EXE({{
                                            EXEDefaultPrice.price + EXEDefaultPrice.projectSizePrice * projectSize
                                        }})
                                        <span v-if="isOPRSelected || isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isOPRSelected">
                                        OPR({{
                                            OPRDefaultPrice.price + OPRDefaultPrice.projectSizePrice * projectSize
                                        }})
                                        <span v-if="isOPLSelected">+</span>
                                    </span>
                                    <span v-if="isOPLSelected">
                                        OPL({{
                                            OPLDefaultPrice.price + OPLDefaultPrice.projectSizePrice * projectSize
                                        }})
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>

                <app-separator :label="$t('contracts.designation')"></app-separator>

                <div class="flex gap-2">
                    <app-input-text
                        :label="$t('contracts.billingName') + ' : '"
                        v-model="contract.billingName"
                        @blur="save"
                    ></app-input-text>
                </div>
                <div class="flex gap-2">
                    <app-input-text
                        :label="$t('contracts.siren') + ' : '"
                        v-model="contract.siren"
                        @blur="save"
                    ></app-input-text>
                </div>
                <div class="flex gap-2">
                    <app-textarea
                        :label="$t('contracts.billingAddress') + ' : '"
                        v-model="contract.billingAddress"
                        @blur="save"
                    ></app-textarea>
                </div>
                <div class="flex gap-2">
                    <app-input-text
                        :label="$t('contracts.billingPostalCode') + ' : '"
                        v-model="contract.billingPostalCode"
                        @blur="save"
                    ></app-input-text>
                </div>
                <div class="flex gap-2">
                    <app-input-text
                        :label="$t('contracts.billingCity') + ' : '"
                        v-model="contract.billingCity"
                        @blur="save"
                    ></app-input-text>
                </div>

                <app-separator :label="$t('contracts.documents')"></app-separator>
                <div class="flex flex-col w-full gap-2 p-2">
                    <div class="flex gap-2 w-full">
                        <app-label :label="$t('contracts.emptyPDFContract') + ' : '"></app-label>
                        <icon-rotate-right
                            class="animate animate-spin"
                            v-if="contractTemplateLoading"
                        ></icon-rotate-right>
                        <template v-else>
                            <div v-if="contract.templateUrl" class="flex justify-between w-full">
                                <app-file-link
                                    icon="icon-file-pdf-outline"
                                    :url="contract.templateUrl"
                                    file-name="Contrat.pdf"
                                    class="text-sm underline"
                                />
                                <app-button
                                    size="mini"
                                    :label="$t('contracts.regeneratePdf')"
                                    @click="generateContract"
                                ></app-button>
                            </div>
                            <div v-else class="flex">
                                <app-button
                                    size="mini"
                                    icon="icon-file-pdf-outline"
                                    :label="$t('contracts.generatePdf')"
                                    @click="generateContract"
                                ></app-button>
                            </div>
                        </template>
                    </div>
                    <div class="flex gap-2 flex-col">
                        <div class="flex gap-2">
                            <app-label :label="$t('contracts.signedPDFContract') + ' : '"></app-label>
                            <div class="flex justify-between w-full">
                                <app-file-link
                                    icon="icon-file-pdf-outline"
                                    :url="contract.signedUrl"
                                    file-name="Contrat_signe.pdf"
                                    class="text-sm underline"
                                    v-if="contract.signedUrl"
                                />
                                <app-upload-button
                                    class="text-sm"
                                    size="mini"
                                    icon="icon-paperclip"
                                    method="PUT"
                                    :label="contract.signedUrl ? $t('contracts.uploadPDF') : $t('contracts.replacePDF')"
                                    @select="onSignedContractSelected"
                                    scope="contracts"
                                    :end-point="`/api/contracts/${$route.params.contractId}/signedPdf`"
                                />
                            </div>
                        </div>
                        <app-date-link
                            v-model="contract.signedAt"
                            :label="$t('contracts.signedAt')"
                            @input="save"
                        ></app-date-link>
                        <app-date-link
                            v-model="contract.validatedAt"
                            :label="$t('contracts.validatedAt')"
                            @input="save"
                        ></app-date-link>
                    </div>
                </div>

                <app-separator :label="$t('contracts.sepas')"></app-separator>
                <table class="text-xs border">
                    <thead>
                        <th class="p-1 border">{{ $t('contracts.rum') }}</th>
                        <th class="p-1 border">{{ $t('contracts.sepaTemplate') }}</th>
                        <th class="p-1 border">{{ $t('contracts.signedSepa') }}</th>
                        <th class="p-1 border">{{ $t('contracts.iban') }}</th>
                    </thead>
                    <tr v-for="sepa in contract.sepas">
                        <td class="p-1 border">{{ sepa.rum }}</td>
                        <td class="p-1 border">
                            <div class="flex justify-center w-full">
                                <app-file-link
                                    icon="icon-file-pdf-outline"
                                    :url="sepa.templateUrl"
                                    v-if="sepa.templateUrl"
                                />
                            </div>
                        </td>
                        <td class="p-1 border">
                            <div class="flex justify-center">
                                <div v-if="sepa.signedUrl">
                                    <app-file-link icon="icon-file-pdf-outline" :url="sepa.signedUrl" />
                                    {{ sepa.validatedAt | humanizeDate }}
                                </div>
                                <div v-else>
                                    <app-upload-button
                                        icon="icon-paperclip"
                                        size="mini"
                                        :label="$t('contracts.uploadPDF')"
                                        :end-point="`/api/contracts/${$route.params.contractId}/sepas/${sepa.id}/signedPdf`"
                                        method="PUT"
                                        @select="onSignedSepaSelected"
                                    />
                                </div>
                            </div>
                        </td>
                        <td class="p-1 border">
                            <div class="flex justify-between">
                                <div v-if="sepa.ibanUrl">
                                    <app-file-link icon="icon-file-pdf-outline" :url="sepa.ibanUrl" />
                                </div>
                                <div v-else>
                                    <app-upload-button
                                        icon="icon-paperclip"
                                        size="mini"
                                        :label="$t('contracts.uploadPDF')"
                                        :end-point="`/api/contracts/${$route.params.contractId}/sepas/${sepa.id}/iban`"
                                        method="PUT"
                                        @select="onSignedIbanSelected"
                                    />
                                </div>
                            </div>
                        </td>
                        <td class="p-1 border">
                            <div class="flex justify-between">
                                <app-drop-down-button
                                    @input="onAction(sepa, $event)"
                                    :value="null"
                                    :show-label="false"
                                    ref="select"
                                    v-if="sepa.ibanUrl || sepa.signedUrl"
                                    style="width: 3rem"
                                >
                                    <option value="">...</option>
                                    <option value="removeSignedSepa" v-if="sepa.signedUrl">
                                        {{ $t('contracts.removeSignedSepa') }}
                                    </option>
                                    <option value="removeIban" v-if="sepa.ibanUrl">
                                        {{ $t('contracts.removeIban') }}
                                    </option>
                                </app-drop-down-button>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="sepaGenerating">
                        <td class="p-1 border" colspan="4">
                            <div class="flex gap-2 justify-center items-center">
                                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                                <span>{{ $t('contracts.sepaGenerating') }}</span>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="flex gap-2 p-2">
                    <app-button
                        size="mini"
                        icon="icon-file-pdf-outline"
                        :label="$t('contracts.newSEPA')"
                        @click="newSepa"
                    ></app-button>
                </div>
                <app-separator :label="$t('contracts.projects')"></app-separator>
                <table class="text-xs border">
                    <thead>
                        <th class="p-1 border">{{ $t('commons.code') }}</th>
                        <th class="p-1 border">{{ $t('commons.name') }}</th>
                        <th class="p-1 border">{{ $t('commons.city') }}</th>
                    </thead>
                    <tr v-for="project in projects">
                        <td class="p-1 border">{{ project.code }}</td>
                        <td class="p-1 border">{{ project.name }}</td>
                        <td class="p-1 border">{{ project.city }}</td>
                    </tr>
                </table>
                <div class="flex gap-2 p-2">
                    <app-button
                        size="mini"
                        icon="icon-file-pdf-outline"
                        :label="$t('contracts.getSchedule')"
                        @click="getSchedule"
                    ></app-button>
                </div>
            </div>
        </div>

        <app-popup ref="newSepa" :showHeader="true" :title="$t('contracts.newSEPA')">
            <div class="p-2 flex flex-col">
                <div class="flex gap-2 flex-col">
                    <app-input-text :label="$t('contracts.iban') + ' : '" v-model="sepa.iban"></app-input-text>

                    <div class="flex gap-2">
                        <app-input-text
                            :label="$t('contracts.billingName') + ' : '"
                            v-model="sepa.name"
                            @blur="save"
                        ></app-input-text>
                    </div>
                    <div class="flex gap-2">
                        <app-textarea
                            :label="$t('contracts.billingAddress') + ' : '"
                            v-model="sepa.address"
                            @blur="save"
                        ></app-textarea>
                    </div>
                </div>
                <app-footer
                    @click="createSepa()"
                    :disabled="
                        !sepa.name ||
                        sepa.name.length === 0 ||
                        !sepa.iban ||
                        sepa.iban.length === 0 ||
                        !sepa.address ||
                        sepa.address.length === 0
                    "
                    class="mt-2"
                    :label="$t('contracts.generateSEPA')"
                ></app-footer>
            </div>
        </app-popup>
    </main>
</template>

<script>
import AppSelect from '@/components/appSelect/AppSelect';
import AppPopup from '@/components/app-popup/AppPopup';
import AppFooter from '@/components/appFooter/AppFooter';
import AppButton from '@/components/appButton/AppButton';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppFilter from '@/components/appFilter/AppFilter.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import IconChevronLeft from '@/icons/IconChevronLeft.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';
import {
    generateContractTemplate,
    getContract,
    updateContract,
    getPrices,
    getContractProjects,
} from '@/features/contracts/contracts.service';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton.vue';
import sepasService from '@/features/contracts/sepas.service';
import IconFilePdfOutline from '@/icons/IconFilePdfOutline.vue';
import AppPhone from '@/components/appPhone/AppPhone.vue';
import AppTipsIcon from '@/components/appTipsIcon/AppTipsIcon.vue';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton.vue';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';
import { getDefaultPrices } from '@/features/contracts/prices.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink.vue';

export default {
    components: {
        AppNumberLink,
        AppDropDownButton,
        AppMultipleUploadButton,
        AppTipsIcon,
        AppPhone,
        IconFilePdfOutline,
        AppUploadButton,
        AppFileLink,
        AppTextarea,
        AppLabel,
        AppDateLink,
        AppSeparator,
        IconChevronLeft,
        AppCheckbox,
        AppFilter,
        AppInputText,
        AppSelect,
        AppFooter,
        AppPopup,
        AppButton,
    },
    computed: {
        total() {
            let result = 0;
            if (this.isCONSelected) {
                result += this.CONPrice.price + this.CONPrice.projectSizePrice * this.projectSize;
            }
            if (this.isEXESelected) {
                result += this.EXEPrice.price + this.EXEPrice.projectSizePrice * this.projectSize;
            }
            if (this.isOPRSelected) {
                result += this.OPRPrice.price + this.OPRPrice.projectSizePrice * this.projectSize;
            }
            if (this.isOPLSelected) {
                result += this.OPLPrice.price + this.OPLPrice.projectSizePrice * this.projectSize;
            }
            return result;
        },
        defaultTotal() {
            let result = 0;
            if (this.isCONSelected) {
                result += this.CONDefaultPrice.price + this.CONDefaultPrice.projectSizePrice * this.projectSize;
            }
            if (this.isEXESelected) {
                result += this.EXEDefaultPrice.price + this.EXEDefaultPrice.projectSizePrice * this.projectSize;
            }
            if (this.isOPRSelected) {
                result += this.OPRDefaultPrice.price + this.OPRDefaultPrice.projectSizePrice * this.projectSize;
            }
            if (this.isOPLSelected) {
                result += this.OPLDefaultPrice.price + this.OPLDefaultPrice.projectSizePrice * this.projectSize;
            }
            return result;
        },
    },
    async created() {
        this.contract = await getContract(this.$route.params.contractId);
        this.projects = await getContractProjects(this.$route.params.contractId);
        this.sepa.name = this.contract.name;
        this.sepa.address =
            (this.contract.billingAddress ? this.contract.billingAddress + '\n' : '') +
            (this.contract.billingPostalCode ? this.contract.billingPostalCode + ' ' : '') +
            (this.contract.billingCity ? this.contract.billingCity : '');
        this.loading = false;
        const defaultPrices = await getDefaultPrices();
        this.CONDefaultPrice = defaultPrices.find((price) => price.moduleType === 'CON');
        this.EXEDefaultPrice = defaultPrices.find((price) => price.moduleType === 'EXE');
        this.OPRDefaultPrice = defaultPrices.find((price) => price.moduleType === 'OPR');
        this.OPLDefaultPrice = defaultPrices.find((price) => price.moduleType === 'OPL');
        const prices = await getPrices(this.$route.params.contractId);
        this.CONPrice = prices.find((price) => price.moduleType === 'CON');
        this.EXEPrice = prices.find((price) => price.moduleType === 'EXE');
        this.OPRPrice = prices.find((price) => price.moduleType === 'OPR');
        this.OPLPrice = prices.find((price) => price.moduleType === 'OPL');
    },
    methods: {
        async generateContract() {
            if (!this.contract.templateUrl || (await confirm(this.$t('contracts.replaceTemplate')))) {
                this.contractTemplateLoading = true;
                const result = await generateContractTemplate(this.contract);
                this.contract.templateUrl = result.templateUrl;
                this.contractTemplateLoading = false;
            }
        },
        async save() {
            const contractor = this.contract.contractor;
            const sepas = this.contract.sepas;
            this.contract = await updateContract({
                id: this.contract.id,
                name: this.contract.name,
                siren: this.contract.siren,
                proposedAt: this.contract.proposedAt,
                signedAt: this.contract.signedAt,
                validatedAt: this.contract.validatedAt,
                expireAt: this.contract.expireAt,
                billingName: this.contract.billingName,
                billingAddress: this.contract.billingAddress,
                billingCity: this.contract.billingCity,
                billingPostalCode: this.contract.billingPostalCode,
            });
            this.contract.contractor = contractor;
            this.contract.sepas = sepas;
        },
        async onSignedContractSelected(contract) {
            this.contract.signedUrl = contract.signedUrl;
        },
        async onSignedSepaSelected(sepa) {
            this.contract.sepas = this.contract.sepas.map((aSepa) => (aSepa.id === sepa.id ? sepa : aSepa));
        },
        async onSignedIbanSelected(sepa) {
            this.contract.sepas = this.contract.sepas.map((aSepa) => (aSepa.id === sepa.id ? sepa : aSepa));
        },
        async onAction(sepa, action) {
            if (action === 'removeSignedSepa' && (await confirm(this.$t('contracts.removeSignedSepa')))) {
                const newSepas = await sepasService.updateSepa(this.contract.id, { id: sepa.id, signedUrl: null });
                this.contract.sepas = this.contract.sepas.map((aSepa) => (aSepa.id === sepa.id ? newSepas : sepa));
            } else if (action === 'removeIban' && (await confirm(this.$t('contracts.removeIban')))) {
                const newSepas = await sepasService.updateSepa(this.contract.id, { id: sepa.id, ibanUrl: null });
                this.contract.sepas = this.contract.sepas.map((aSepa) => (aSepa.id === sepa.id ? newSepas : sepa));
            }
        },
        newSepa() {
            this.sepa.name = this.contract.billingName;
            this.sepa.address =
                (this.contract.billingAddress ? this.contract.billingAddress + '\n' : '') +
                (this.contract.billingPostalCode ? this.contract.billingPostalCode + ' ' : '') +
                (this.contract.billingCity ? this.contract.billingCity : '');
            this.$refs.newSepa.open();
        },
        getSchedule() {},
        async createSepa() {
            this.$refs.newSepa.close();
            this.sepaGenerating = true;
            this.contract.sepas.push(
                await sepasService.createSepa(this.contract.id, {
                    contractId: this.contract.id,
                    name: this.sepa.name,
                    iban: this.sepa.iban,
                    address: this.sepa.address,
                }),
            );
            this.sepaGenerating = false;
            this.sepa.name = this.contract.name;
            this.sepa.address =
                (this.contract.billingAddress ? this.contract.billingAddress + '\n' : '') +
                (this.contract.billingPostalCode ? this.contract.billingPostalCode + ' ' : '') +
                (this.contract.billingCity ? this.contract.billingCity : '');
        },
    },
    data() {
        return {
            projects: [],
            isCONSelected: true,
            isOPRSelected: true,
            isEXESelected: true,
            isOPLSelected: true,
            defaultPrices: [],
            loading: true,
            sepaGenerating: false,
            contractTemplateLoading: false,
            readOnly: true,
            contract: {},
            sepa: {
                address: '',
                name: '',
                iban: '',
            },
            CONDefaultPrice: 0,
            EXEDefaultPrice: 0,
            OPRDefaultPrice: 0,
            OPLDefaultPrice: 0,
            CONPrice: 0,
            EXEPrice: 0,
            OPRPrice: 0,
            OPLPrice: 0,
            currencyFormat: {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                style: 'currency',
                minimumFractionDigits: 2,
            },
            decimalFormat: {
                style: 'decimal',
                minimumFractionDigits: 2,
            },
            projectSize: 2000,
        };
    },
};
</script>
