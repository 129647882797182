import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapPreparationVisa(item) {
    if (item) {
        const plainItem = item.toMutableJSON();
        return {
            ...plainItem,
            emissionDueDate: plainItem.emissionDueDate ? new Date(plainItem.emissionDueDate) : null,
            emissionDate: plainItem.emissionDate ? new Date(plainItem.emissionDate) : null,
        };
    } else {
        return null;
    }
}

export function getPreparationVisas(projectId, preparationId) {
    return db
        .getProjectCollections(projectId)
        .preparationVisas.find({ selector: { preparationId } })
        .$.pipe(map((preparationVisas) => preparationVisas.map(mapPreparationVisa)));
}
export function getAllPreparationVisas(projectId) {
    return db
        .getProjectCollections(projectId)
        .preparationVisas.find({})
        .$.pipe(map((preparationVisas) => preparationVisas.map(mapPreparationVisa)));
}
export function getPreparationVisa(projectId, preparationVisaId) {
    return db
        .getProjectCollections(projectId)
        .preparationVisas.findOne({ selector: { id: preparationVisaId } })
        .$.pipe(map(mapPreparationVisa));
}
export async function queryPreparationVisa(projectId, preparationVisaId) {
    return mapPreparationVisa(
        await db
            .getProjectCollections(projectId)
            .preparationVisas.findOne({ selector: { id: preparationVisaId } })
            .exec(),
    );
}

export function removePreparationVisa(projectId, preparationVisaId) {
    return db
        .getProjectCollections(projectId)
        .preparationVisas.findOne({ selector: { id: preparationVisaId } })
        .remove();
}

export async function updatePreparationVisa(projectId, preparationVisa) {
    const dbPreparation = await db
        .getProjectCollections(projectId)
        .preparationVisas.findOne({ selector: { id: preparationVisa.id } })
        .exec();

    const patch = { ...JSON.parse(JSON.stringify(preparationVisa)) };
    return dbPreparation.atomicPatch(patch);
}

export async function createPreparationVisa(projectId, preparationVisa) {
    const result = await db.getProjectCollections(projectId).preparationVisas.insert({
        ...preparationVisa,
        projectId,
        emissionDueDate: preparationVisa.emissionDueDate ? preparationVisa.emissionDueDate.toISOString() : null,
    });
    return result.toJSON();
}

export async function bulkDelete(projectId, preparationVisaIds) {
    return await db.getProjectCollections(projectId).preparationVisas.bulkRemove(preparationVisaIds);
}
export default { bulkDelete };
