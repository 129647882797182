import { getAPIHeaders } from '@/services/api.service';

export async function getUser(userId) {
    const response = await fetch(
        new Request('/api/users/' + userId, {
            method: 'GET',
            headers: getAPIHeaders(),
        }),
    );
    if (!response.ok) {
        throw new Error('getUserError');
    }
    return response.json();
}

export async function updateUser(user) {
    const response = await fetch(
        new Request('/api/users/' + user.id, {
            method: 'PUT',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(user),
        }),
    );
    if (!response.ok) {
        throw new Error('updateUserError');
    }
}
export async function resetPassword(email) {
    const response = await fetch(
        new Request('/api/resetPasswords/?email=' + encodeURI(email), {
            method: 'POST',
        }),
    );
    if (!response.ok) {
        throw new Error('updateUserError');
    }
}

export async function createUser(user) {
    const response = await fetch(
        new Request('/api/users', {
            method: 'POST',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(user),
        }),
    );
    if (!response.ok) {
        throw new Error('createUserError');
    } else {
        localStorage.setItem('token', response.headers.get('token'));
    }
}
