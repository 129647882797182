<template>
    <app-popup ref="popup" :showHeader="true" :title="options.title">
        <form class="flex flex-col gap-4 p-2">
            <app-bundle-picker :options="bundles" v-model="selectedBundle" :disabled="!!options.bundleId" />
            <app-picker
                v-model="selectedService"
                :options="limitedServices"
                :show-label="true"
                :label="$t('services.title')"
                :allow-custom-option="!options.serviceId"
                :disabled="!!options.serviceId"
                :customOptionPrefix="$t('companies.creationPrefix') + ' : '"
            />
            <app-picker
                :value="selectedLocation"
                :options="limitedLocations"
                :show-label="true"
                :label="$t('services.locations')"
                :disabled="!!options.locationId"
                label-key="fullName"
                v-model="selectedLocation"
            />
            <app-footer
                @click="validate()"
                :disabled="!selectedBundle || !selectedService || !selectedLocation"
            ></app-footer>
        </form>
        <div style="padding-bottom: 40px"></div>
    </app-popup>
</template>

<script>
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { createTask, getTasks } from '@/features/tasks/tasks.service';
import AppBundlePicker from '@/components/appBundlePicker.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { createService, getServices, reOrder } from '@/features/services/services.service';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppPicker from '@/components/appPicker/AppPicker.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import addDays from 'date-fns/addDays';
import { moveItem } from '@/features/services/itemUtils';
import locationService from '@/services/location.service';
import { getCompanies } from '@/features/companies/companies.service';
import { getMapBy, getMapById } from '@/services/sanitize.service';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppFooter,
        AppPicker,
        AppMultiPicker,
        AppSeparator,
        AppPopup,
        AppBundlePicker,
    },
    async created() {
        this.project = await queryProject(this.$route.params.projectId);
        this.subscriptions = [
            combineLatest([
                getBundles(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getTasks(this.$route.params.projectId),
                getServices(this.$route.params.projectId),
            ]).subscribe(([bundles, companies, folders, tasks, services]) => {
                const companyMap = getMapById(companies);
                this.bundles = bundles.map((bundle) => ({ ...bundle, company: companyMap[bundle.companyId] }));
                this.locations = locationService.buildLocationOptions(folders);
                const serviceMapById = getMapById(services);
                const locationMapById = getMapById(this.locations);
                this.tasks = tasks
                    .map((task) => ({
                        ...task,
                        service: serviceMapById[task.serviceId],
                        location: locationMapById[task.locationId],
                    }))
                    .filter((task) => !!task.service && !!task.location);
                this.services = services;
            }),
        ];
    },
    data() {
        return {
            project: {},
            bundles: [],
            locations: [],
            tasks: [],
            services: [],
            options: {},
            selectedBundle: null,
            selectedLocation: null,
            selectedService: null,
        };
    },
    computed: {
        limitedLocations() {
            const serviceId = this.selectedService?.id || this.options.serviceId;
            const folderId = this.options.folderId;
            const bundleId = this.selectedBundle?.id;
            const relatedTasksByLocationId = getMapBy(
                this.tasks.filter(
                    (task) =>
                        task.service.bundleId === bundleId &&
                        (!serviceId || task.serviceId === serviceId) &&
                        (!folderId || task.location.parentId === folderId),
                ),
                'locationId',
            );
            return this.locations.filter(
                (location) =>
                    (this.options.folderId ? location.parentId === this.options.folderId : true) &&
                    location.type === 'location' &&
                    !relatedTasksByLocationId[location.id],
            );
        },
        limitedServices() {
            const serviceId = this.selectedService?.id || this.options.serviceId;
            const locationId = this.selectedLocation?.id || this.options.locationId;
            const bundleId = this.selectedBundle?.id;
            const relatedTaskMapByServiceIf = getMapBy(
                this.tasks.filter(
                    (task) =>
                        (!serviceId || task.serviceId === serviceId) &&
                        (!locationId || task.locationId === locationId) &&
                        task.service.bundleId === bundleId,
                ),
                'serviceId',
            );
            return this.services.filter(
                (service) => service.bundleId === bundleId && (!locationId || !relatedTaskMapByServiceIf[service.id]),
            );
        },
    },
    methods: {
        async validate() {
            let service;
            if (this.selectedService._isStringCriteria) {
                service = await this.newService(this.selectedService.content);
            } else {
                service = this.selectedService;
            }
            await createTask(this.$route.params.projectId, {
                serviceId: service.id,
                duration: 1,
                startDate: this.project.startDate,
                endDate: addDays(this.project.startDate, 1),
                progress: 0,
                quantity: 0,
                locationId: this.selectedLocation.id,
                predecessors: [],
            });
            this.$refs.popup.close();
        },
        async newService(name) {
            const projectId = this.$route.params.projectId;
            const bundleId = this.selectedBundle.id;
            const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
            const index = bundle.children.length + 1;
            const newService = await createService(projectId, {
                name,
                unit: 'm²',
                amount: 0,
                parentDirectoryId: null,
                index,
                isExecution: true,
                reference: '',
                bundleId,
                predecessors: [],
            });
            const newChildList = moveItem(bundle.children, newService, newService.index);
            await reOrder(this.$route.params.projectId, newChildList);
            return newService;
        },
        open(options) {
            this.options = options;
            this.selectedBundle = options.bundleId
                ? this.bundles.find((bundle) => bundle.id === options.bundleId)
                : null;
            this.selectedLocation = options.locationId
                ? this.locations.find((location) => location.id === options.locationId)
                : null;
            this.selectedService = options.serviceId
                ? this.services.find((service) => service.id === options.serviceId)
                : null;
            this.$refs.popup.open();
        },
    },
};
</script>
