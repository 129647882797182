import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const receptions = {
    schema: {
        title: 'reception schema',
        version: 0,
        description: 'describes a reception',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            name: {
                type: ['string', 'null'],
            },
            groupId: {
                type: ['string', 'null'],
            },
            index: {
                type: ['string', 'number', 'null'],
            },
            code: {
                type: ['string', 'null'],
            },
            conclusion: {
                type: ['string', 'null'],
            },
            bundleId: {
                type: ['string', 'null'],
            },
            serviceId: {
                type: ['string', 'null'],
            },
            emissionDueDate: {
                type: ['string', 'null'],
            },
            emissionDate: {
                type: ['string', 'null'],
            },
            locationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
