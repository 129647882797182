import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const directories = {
    schema: {
        title: 'directory schema',
        version: 0,
        description: 'describes a directory',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: {
                type: 'string',
            },
            bundleId: {
                type: 'string',
                final: true,
            },
            parentDirectoryId: {
                type: ['string', 'null'],
            },
            name: {
                type: ['string', 'null'],
            },
            index: {
                type: ['number', 'null'],
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    autoMigrate: false,
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
