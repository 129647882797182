import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapReference(referenceDb) {
    if (!referenceDb) {
        return null;
    } else {
        return {
            ...referenceDb.toMutableJSON(),
            createdAt: referenceDb.createdAt ? new Date(referenceDb.createdAt) : null,
            dates: referenceDb.dates.map((date) => ({
                ...date,
                startDate: date.startDate ? new Date(date.startDate) : null,
                endDate: date.endDate ? new Date(date.endDate) : null,
            })),
        };
    }
}
function mapReferences(referenceDb) {
    return referenceDb.map(mapReference);
}
export function getReferences(projectId) {
    return db
        .getProjectCollections(projectId)
        .references.find({ selector: {}, sort: [{ createdAt: 'desc' }] })
        .$.pipe(map(mapReferences));
}
export function getLastReference(projectId) {
    return db
        .getProjectCollections(projectId)
        .references.findOne({ selector: {}, sort: [{ createdAt: 'desc' }] })
        .$.pipe(map(mapReference));
}
export function getReference(projectId, referenceId) {
    return db
        .getProjectCollections(projectId)
        .references.findOne({ selector: { id: referenceId } })
        .$.pipe(map(mapReference));
}

export async function createReference(projectId, reference) {
    const result = await db.getProjectCollections(projectId).references.insert(reference);
    return result.toJSON();
}

export async function updateReference(projectId, reference) {
    const dbLocation = await db
        .getProjectCollections(projectId)
        .references.findOne({ selector: { id: reference.id } })
        .exec();
    return dbLocation.atomicPatch({
        name: reference.name,
    });
}
export async function removeReference(projectId, referenceId) {
    const entity = await db
        .getProjectCollections(projectId)
        .references.findOne({ selector: { id: referenceId } })
        .exec();
    return entity.remove();
}
