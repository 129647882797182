<template>
    <app-input-text
        ref="textField"
        :rules="rules"
        :disabled="disabled"
        :required="required"
        :label="label"
        :uid="uid"
        :value="value"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
        @keypress="$emit('keypress', $event)"
        @keyup="$emit('keyup', $event)"
        :showErrors="showErrors"
        :showLabel="showLabel"
    >
        <template slot="companion"></template>
        <template slot="tip"><slot name="tip"></slot></template>
        <template slot="link">
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex pr-1 items-center text-gray-700"
                :class="{ 'opacity-50': disabled }"
            >
                <IconChevronDown :disabled="disabled" />
            </div>

            <ValidationProvider>
                <select
                    style="max-width: 32px"
                    ref="select"
                    class="cursor-pointer appearance-none block bg-transparent text-transparent"
                    :name="label"
                    :required="required"
                    v-model="internalValue"
                    :disabled="disabled"
                    v-on:input="onInput($event.target.value)"
                    @change="$emit('change', $event.target.value)"
                    :aria-label="label"
                    :title="label"
                >
                    <slot></slot>
                </select>
            </ValidationProvider>
        </template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';

export default {
    components: { AppInputText },
    props: {
        rules: String,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: String,
        uid: String | Number,
        showTips: {
            type: Boolean,
            default: true,
        },
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        value() {
            this.internalValue = '';
        },
    },
    data() {
        return { internalValue: '' };
    },
    methods: {
        onInput(event) {
            if (event !== this.value) {
                this.$emit('input', event);
                this.$refs.textField.focus();
            }
        },
    },
};
</script>
