<template>
    <header class="min-h-toolbar text-white flex items-center bg-main px-2">
        <template v-if="isMobile">
            <nav v-if="$route.params.projectId" aria-label="Breadcrumb" class="flex flex-grow">
                <div v-if="isOpen" class="w-full flex justify-between items-center">
                    <router-link class="mx-2" :to="{ name: 'home' }" :aria-label="$t('home.title')">
                        <icon-home />
                    </router-link>
                    <h1 class="flex items-center justify-center w-full truncate">{{ mapTitle(':projectName') }}</h1>
                    <app-sync-state />
                </div>
                <template v-else>
                    <button class="mr-4" @click="toggleIsOpen()" aria-label="toggle menu" v-if="$route.meta.backToMenu">
                        <icon-menu />
                    </button>
                    <router-link
                        class="mr-4"
                        v-else
                        :to="{
                            name: $route.meta.parentName,
                            params: $route.params,
                        }"
                        aria-label="back"
                    >
                        <icon-chevron-left></icon-chevron-left>
                    </router-link>
                    <h1>{{ getLastDefinedTitle() }}</h1>
                </template>
            </nav>
            <nav v-else class="w-full">
                <div class="flex w-full justify-between items-center">
                    <router-link
                        class="mx-2"
                        :to="{ name: 'home' }"
                        :aria-label="$t('home.title')"
                        v-if="$route.name === 'user'"
                    >
                        <icon-home />
                    </router-link>
                    <div class="flex gap-2">
                        <span class="">Atex</span>
                    </div>
                    <div class="flex gap-2 items-center">
                        <app-upgrade-button>
                            <span class="text-xs text-blue-400">v2.1.PATCH_VERSION</span>
                        </app-upgrade-button>
                        <app-button
                            v-if="$route.name !== 'user'"
                            icon="icon-user"
                            size="mini"
                            @click="$router.push({ name: 'user' })"
                        ></app-button>
                    </div>
                </div>
            </nav>
        </template>
        <template v-if="!isMobile">
            <div class="" v-if="!$route.meta.hideMenu">
                <button class="m-1 -p2 rounded-full" @click="toggleIsOpen()" aria-label="toggle menu">
                    <icon-menu v-if="!isOpen"></icon-menu>
                    <icon-back-burger v-if="isOpen"></icon-back-burger>
                </button>
            </div>
            <nav aria-label="Breadcrumb" class="flex flex-grow md:flex-grow-0">
                <div class="flex md:hidden">
                    <router-link
                        class="mx-4"
                        v-if="!isOpen && $route.matched[$route.matched.length - 2]"
                        :to="$route.matched[$route.matched.length - 2]"
                    >
                        <icon-chevron-left></icon-chevron-left>
                    </router-link>
                </div>
                <slot name="breadCrumbRoot"></slot>
                <div v-for="(crumb, index) in $route.matched">
                    <span v-if="index < $route.matched.length - 1" class="hidden md:flex">
                        <router-link
                            class="mr-2"
                            :to="{ path: mapPath(crumb.path) }"
                            v-if="mapTitle(crumb.meta.title).length > 0"
                        >
                            <h1 class="line-clamp-1">{{ mapTitle(crumb.meta.title) }}</h1>
                        </router-link>
                        <icon-chevron-right v-if="mapTitle(crumb.meta.title).length > 0"></icon-chevron-right>
                    </span>
                    <template
                        v-if="
                            index === $route.matched.length - 1 &&
                            mapTitle(crumb.meta.title) &&
                            mapTitle(crumb.meta.title).length > 0
                        "
                    >
                        <h1 :class="{ hidden: isOpen }" class="md:line-clamp-1">
                            {{ mapTitle(crumb.meta.title) }}
                        </h1>
                        <h1 class="md:hidden" :class="{ hidden: !isOpen }">
                            {{ mapTitle(':projectName') }}
                        </h1>
                    </template>
                </div>
            </nav>
            <div class="flex-grow hidden md:flex"></div>
            <app-sync-state v-if="$route.params.projectId" class="offline:hidden hidden md:flex" />
            <icon-cloud-off-outline
                class="hidden offline:block text-yellow-400"
                width="16"
                height="16"
            ></icon-cloud-off-outline>
            <user-dropdown class="hidden md:block"></user-dropdown>
        </template>
    </header>
</template>
<script>
import UserDropdown from './UserDropdown';
import { getBreadCrumbs, isMobile } from '@/state/state';
import AppButton from './appButton/AppButton';
import { convert } from 'html-to-text';
import IconCloudOffOutline from '@/icons/IconCloudOffOutline';
import IconCloudCheck from '@/icons/IconCloudCheck';
import IconCloudRefresh from '@/icons/IconCloudRefresh';
import IconCloudAlert from '@/icons/IconCloudAlert';
import AppSyncState from '@/components/appSyncState/AppSyncState';
import AppUpgradeButton from '@/components/appUpgradeButton/AppUpgradeButton';

export default {
    components: {
        AppUpgradeButton,
        AppSyncState,
        IconCloudAlert,
        IconCloudRefresh,
        IconCloudCheck,
        IconCloudOffOutline,
        AppButton,
        UserDropdown,
    },
    created() {
        getBreadCrumbs((breadCrumbs) => {
            this.breadCrumbs = breadCrumbs;
        });
    },
    props: ['isOpen'],
    data: () => ({
        breadCrumbs: {},
        isMobile,
    }),
    methods: {
        getLastDefinedTitle() {
            return this.mapTitle([...this.$route.matched.filter((route) => route.meta.title)].pop().meta.title);
        },
        mapTitle: function (title) {
            if (!title) {
                return '';
            }
            if (title.startsWith(':')) {
                return convert(this.breadCrumbs[title.substring(1)] || '');
            } else {
                return this.$t(title);
            }
        },
        mapPath: function (path) {
            let result = path;
            Object.keys(this.$route.params).forEach((key) => {
                result = result.replace(':' + key, convert(this.$route.params[key]));
            });
            return result;
        },
        toggleIsOpen: function () {
            this.$emit('toggle-menu');
        },
    },
};
</script>
