<template>
    <app-label
        :tag="tag"
        :showLabel="showLabel"
        :label="label"
        :required="required"
        class="w-full relative flex items-center"
    >
        <div class="flex w-full items-center" style="height: 30px" @click="onLineClick">
            <div ref="line" class="line w-full" :class="{ disabled }"></div>
        </div>
        <div ref="handle" class="handle absolute" :class="{ isDragging, disabled, 'cursor-grab': !disabled }"></div>
    </app-label>
</template>
<script>
import AppLabel from '../appLabel/AppLabel';
import { throttle } from '../../services/sanitize.service';
export default {
    components: { AppLabel },
    props: {
        label: String,
        tag: String,
        required: Boolean,
        value: Number,
        size: Number | String,
        disabled: Boolean,
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 10,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isDragging: false,
            clickVsHandleXDiff: 0,
        };
    },
    watch: {
        value(newValue) {
            if (!this.isDragging) {
                this.setHandlePosition(newValue);
            }
        },
    },

    mounted() {
        const handle = this.$refs.handle;
        const line = this.$refs.line;

        handle.addEventListener('mousedown', this.mousedown);
        handle.addEventListener('touchstart', this.mousedown);

        document.addEventListener('mousemove', this.mousemove);
        document.addEventListener('touchmove', this.mousemove);

        document.addEventListener('mouseup', this.mouseup);
        document.addEventListener('touchend', this.mouseup);

        const lineRect = line.getBoundingClientRect();

        handle.style.left = `${(this.value / 100) * lineRect.width}px`;

        this.setHandlePosition(this.value);
    },
    beforeDestroy() {
        const handle = this.$refs.handle;

        handle.removeEventListener('mousedown', this.mousedown);
        document.removeEventListener('mousemove', this.mousemove);
        document.removeEventListener('mouseup', this.mouseup);

        handle.removeEventListener('touchstart', this.mousedown);
        document.removeEventListener('touchmove', this.mousemove);
        document.removeEventListener('touchend', this.mouseup);
    },
    methods: {
        setHandlePosition(value) {
            if (value >= this.min && value <= this.max) {
                const line = this.$refs.line;
                const handle = this.$refs.handle;
                const lineRect = line.getBoundingClientRect();
                const handleRect = handle.getBoundingClientRect();
                handle.style.left = `${(value / 100) * (lineRect.width - handleRect.width)}px`;
            }
        },
        mousedown(e) {
            if (!this.disabled) {
                this.isDragging = true;
                this.clickVsHandleXDiff =
                    e.x -
                    this.$refs.line.getBoundingClientRect().left -
                    parseInt(this.$refs.handle.style.left.substring(0), 10);
                e.preventDefault();
            }
        },
        onLineClick(e) {
            if (!this.disabled && !this.isDragging) {
                this.onNewX(e.x - this.$refs.handle.getBoundingClientRect().width / 2 || e.touches[0].pageX, true);
            }
        },
        mousemove(e) {
            if (this.isDragging) {
                this.onNewX(e.x - this.clickVsHandleXDiff || e.touches[0].pageX, false);
            }
        },
        onNewX(newX, isChooseAction) {
            const line = this.$refs.line;
            const handle = this.$refs.handle;
            const lineRect = line.getBoundingClientRect();
            const handleRect = handle.getBoundingClientRect();
            const minX = lineRect.left;
            const maxX = lineRect.right - handleRect.width;
            if (newX <= minX) {
                handle.style.left = `0px`;
                this.fireInput(0, isChooseAction);
            } else if (newX >= maxX) {
                handle.style.left = `${lineRect.width - handleRect.width}px`;
                this.fireInput(100, isChooseAction);
            } else {
                handle.style.left = newX - minX + 'px';
                this.fireInput(
                    Math.round((((newX - minX) / (maxX - minX)) * 100) / this.step) * this.step,
                    isChooseAction,
                );
            }
        },
        mouseup() {
            if (this.isDragging) {
                this.$emit('choose', this.value);
            }
            this.isDragging = false;
        },
        fireInput: throttle(function (value, isChooseAction) {
            this.$emit('input', value);
            if (isChooseAction) {
                this.$emit('choose', value);
            }
        }, 10),
    },
};
</script>

<style scoped>
.handle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #2b6cb0;
}
.handle.disabled {
    background-color: #7b99b8;
}
.isDragging {
    cursor: grabbing;
}

.line {
    height: 2px;
    background: #2b6cb0;
}
.line.disabled {
    background: #7b99b8;
}
</style>
