import { isOffDay, isOffWeek } from '../../services/duration.service';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getISOWeek from 'date-fns/getISOWeek';
import add from 'date-fns/add';
import format from 'date-fns/format';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import getISODay from 'date-fns/getISODay';
import startOfDay from 'date-fns/startOfDay';
import startOfYear from 'date-fns/startOfYear';
import getDayOfYear from 'date-fns/getDayOfYear';
import getDaysInYear from 'date-fns/getDaysInYear';
import endOfYear from 'date-fns/endOfYear';

const START_ON_MONDAY = { weekStartsOn: 1 };

/*
 * Return the number of days included in the first level step (ie min 7 for a week, 1 for a day..)
 * first level could not be split so we only use full Weeks/Months
 */
export function getNumberOfDays(scale, minDate, maxDate) {
    if (scale === 'weeks') {
        return differenceInDays(endOfWeek(maxDate, START_ON_MONDAY), startOfWeek(minDate, START_ON_MONDAY)) + 1;
    } else if (scale === 'months') {
        return differenceInDays(endOfMonth(maxDate), startOfMonth(minDate)) + 1;
    } else {
        return differenceInDays(maxDate, minDate) + 1;
    }
}

export function getDays(minDate, maxDate, agenda) {
    const result = [];
    const numberOfDay = getNumberOfDays('days', minDate, maxDate);
    for (let index = 0; index < numberOfDay; index++) {
        const dayDate = add(minDate, { days: index });
        result.push({
            id: dayDate.toISOString(),
            label: dayDate.getDate(),
            colspan: 1,
            offsetInDays: index,
            classes: {
                'is-weekend': isOffDay(dayDate, agenda),
                'is-current-step': format(dayDate, 'MM/dd/yyyy') === format(new Date(), 'MM/dd/yyyy'),
            },
        });
    }
    return result;
}

export function getMonths(minDate, maxDate) {
    const result = [];
    const numberOfMonth = differenceInMonths(endOfMonth(maxDate), startOfMonth(minDate));
    let offsetInDays = 0;
    for (let index = 0; index <= numberOfMonth; index++) {
        const dayDate = add(startOfMonth(minDate), { months: index });
        const colspan = getDaysInMonth(dayDate) - (index === 0 ? minDate.getDate() : 0);
        result.push({
            id: dayDate.toISOString(),
            label: format(dayDate, 'MMM'),
            colspan,
            offsetInDays,
            classes: {
                'is-current-step': format(dayDate, 'MM/yyyy') === format(new Date(), 'MM/yyyy'),
            },
        });
        offsetInDays += colspan;
    }
    return result;
}

export function getMonthsAsSuperStep(minDate, maxDate, scale) {
    const result = [];
    const numberOfMonth = differenceInMonths(endOfMonth(maxDate), startOfMonth(minDate));

    let yearShowed = false;
    for (let index = 0; index <= numberOfMonth; index++) {
        const dayDate = add(startOfMonth(minDate), { months: index });
        const colspan = getMonthColspan(minDate, maxDate, dayDate, index === 0, index === numberOfMonth);
        const nbDayMinToShowLabel = scale === 'weeks' ? 14 : 3;
        result.push({
            id: dayDate.toISOString(),
            label: colspan > nbDayMinToShowLabel ? format(dayDate, yearShowed ? 'MMM' : 'MMM yyyy') : '',
            colspan,
        });
        if (index === 0 && colspan > nbDayMinToShowLabel) {
            yearShowed = true;
        } else if (index === 1 && !yearShowed) {
            yearShowed = true;
        }
    }
    return result;
}
export function getYearsAsSuperStep(minDate, maxDate) {
    const result = [];
    const numberOfYears = parseInt(maxDate.getFullYear()) - parseInt(minDate.getFullYear());

    for (let index = 0; index <= numberOfYears; index++) {
        const dayDate = add(startOfYear(minDate), { years: index });
        result.push({
            id: dayDate.toISOString(),
            label: format(dayDate, 'yyyy'),
            colspan: getYearColspan(minDate, maxDate, dayDate, index === 0, index === numberOfYears),
        });
    }
    return result;
}

export function getMonthColspan(minDate, maxDate, dayDate, isFirst, isLast) {
    let colspan = getDaysInMonth(dayDate);
    if (isFirst && minDate.getDate() > 1) {
        colspan = colspan - minDate.getDate() + 1;
    }
    const endOfMonthDayDate = endOfMonth(dayDate);
    if (isLast && maxDate < endOfMonthDayDate) {
        colspan = colspan - differenceInDays(endOfMonthDayDate, maxDate);
    }
    return colspan;
}
export function getYearColspan(minDate, maxDate, dayDate, isFirst, isLast) {
    let colspan = getDaysInYear(dayDate);
    if (isFirst && getDayOfYear(minDate) > 1) {
        colspan = colspan - getDayOfYear(minDate) + 1;
    }
    const endOfYearDayDate = endOfYear(dayDate);
    if (isLast && maxDate < endOfYearDayDate) {
        colspan = colspan - differenceInDays(endOfYearDayDate, maxDate);
    }
    return colspan;
}

export function getWeeks(minDate, maxDate, agenda) {
    const result = [];
    const numberOfWeeks = differenceInWeeks(endOfWeek(maxDate, START_ON_MONDAY), startOfWeek(minDate, START_ON_MONDAY));
    let offsetInDays = 0;
    for (let index = 0; index <= numberOfWeeks; index++) {
        const dayDate = add(startOfWeek(minDate, START_ON_MONDAY), { days: 7 * index });
        const colspan = 7 - (index === 0 ? getISODay(minDate) : 0);
        result.push({
            id: dayDate.toISOString(),
            label: getISOWeek(dayDate),
            colspan,
            offsetInDays,
            classes: {
                'is-weekend': isOffWeek(dayDate, agenda),
                'is-current-step': format(dayDate, 'II/yyyy') === format(new Date(), 'II/yyyy'),
            },
        });
        offsetInDays += colspan;
    }
    return result;
}

export function getSteps(scale, minDate, maxDate, agenda) {
    if (scale === 'weeks') {
        return getWeeks(minDate, maxDate, agenda);
    } else if (scale === 'months') {
        return getMonths(minDate, maxDate);
    } else {
        return getDays(minDate, maxDate, agenda);
    }
}
export function getSuperSteps(scale, minDate, maxDate) {
    if (scale === 'weeks') {
        return getMonthsAsSuperStep(
            startOfDay(startOfWeek(minDate, START_ON_MONDAY)),
            endOfWeek(maxDate, START_ON_MONDAY),
            scale,
        );
    } else if (scale === 'months') {
        return getYearsAsSuperStep(startOfMonth(minDate), endOfMonth(maxDate));
    } else {
        return getMonthsAsSuperStep(minDate, maxDate, scale);
    }
}
