<template>
    <div style="display: none"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { findRealParent } from 'vue2-leaflet';
export default {
    props: {
        paths: {
            type: Array,
            default: () => [],
        },
        pixelSize: { type: String | Number, default: 10 },
        weight: { type: Number, default: 3 },
        color: { type: String, default: '#ff0000' },
    },
    data() {
        return {
            polyline: null,
            polylineHandle: null,
            map: null,
        };
    },
    watch: {
        paths() {
            this.polyline.setLatLngs(this.paths);
            this.polylineHandle.setLatLngs(this.paths);

            if (this.polylineHandle.editEnabled()) {
                this.polylineHandle.disableEdit();
                this.$nextTick(() => this.polylineHandle.enableEdit());
            }
        },
        color(color) {
            this.polyline.setStyle({ color });
        },
    },
    mounted() {
        this.polyline = L.polyline(this.paths, { stroke: true, color: this.color, weight: this.weight });
        this.polylineHandle = L.polyline(this.paths, { stroke: true, weight: 15, opacity: 0 });
        L.DomEvent.on(this.polylineHandle, this.$listeners);

        this.polylineHandle.on('editable:drag editable:dragend editable:editing', () => {
            this.polyline.setLatLngs(this.polylineHandle.getLatLngs());
        });
        this.polylineHandle.on('editable:dragend editable:vertex:dragend', () => {
            const latLngs = this.polylineHandle.getLatLngs();
            this.$emit('updateX1Y1X2Y2', {
                x1: latLngs[0].lng,
                x2: latLngs[1].lng,
                y1: latLngs[0].lat,
                y2: latLngs[1].lat,
            });
        });

        this.map = findRealParent(this.$parent).mapObject;
        this.map.addLayer(this.polyline);
        this.map.addLayer(this.polylineHandle);
    },
    beforeDestroy() {
        this.map.removeLayer(this.polyline);
        this.map.removeLayer(this.polylineHandle);
    },
    methods: {
        getPatterns() {
            return [];
        },
        editEnabled() {
            return this.polylineHandle.editEnabled();
        },
        disableEdit() {
            return this.polylineHandle.disableEdit();
        },
        enableEdit() {
            return this.polylineHandle.enableEdit();
        },
        getLatLngs() {
            return this.polylineHandle.getLatLngs();
        },
        select() {
            this.$emit('click', { target: this.polylineHandle });
        },
    },
};
</script>
