<template>
    <app-photo-editor
        :attachment="attachment"
        class="min-h-main"
        :read-only="!(isAdmin || (observation && me && attachment.createdBy === me.id && !observation.validatedAt))"
    />
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor';
import { getObservation } from '@/features/observations/observation.service';
import { combineLatest } from 'rxjs';
import { getObservationAttachmentsByObservationId } from '@/features/observations/observationAttachments.service';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppPhotoEditor,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.me = project.me;
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
        });
        this.subscriptions = [
            combineLatest([
                getObservation(this.$route.params.projectId, this.$route.params.observationId),
                getObservationAttachmentsByObservationId(
                    this.$route.params.projectId,
                    this.$route.params.observationId,
                ),
            ]).subscribe(([observation, attachments]) => {
                this.observation = observation;
                this.attachment = attachments.find((attachment) => attachment.id === this.$route.params.attachmentId);
                updateBreadCrumbs({ observationName: this.observation.title });
            }),
        ];
    },
    data() {
        return {
            observation: null,
            attachment: { shapes: [] },
            subscriptions: [],
            isAdmin: false,
            me: null,
        };
    },
};
</script>
