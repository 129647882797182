var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"p-2 flex flex-col mb-24 gap-4"},[_c('span',{},[_c('observation-status-buttons',{attrs:{"observation":_vm.observation},on:{"input":function($event){return _vm.updateObservation($event)},"remove":function($event){return _vm.deleteObservation()}}})],1),_c('app-text-editor',{attrs:{"label":_vm.$t('observations.titleLabel'),"disabled":!(_vm.isAdmin || _vm.isReporter)},on:{"blur":_vm.onTitle},model:{value:(_vm.observation.title),callback:function ($$v) {_vm.$set(_vm.observation, "title", $$v)},expression:"observation.title"}}),_c('app-multi-picker',{attrs:{"options":_vm.bundles,"label-key":"label","label":_vm.$t('preparations.recipients'),"disabled":!(_vm.isAdmin || _vm.isReporter)},on:{"close":_vm.updateRecipients,"removeChips":_vm.updateRecipients},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('app-bundle',{attrs:{"bundle":option}})]}}]),model:{value:(_vm.observation.recipients),callback:function ($$v) {_vm.$set(_vm.observation, "recipients", $$v)},expression:"observation.recipients"}}),_c('app-label',{staticClass:"flex flex-col gap-2",attrs:{"label":_vm.$t('preparations.relatedObservations') + ' :'}}),_vm._l((_vm.relatedObservations),function(item){return _c('div',{staticClass:"flex my-2"},[_c('div',{staticClass:"flex"},[(!item.footprint)?_c('span',{staticClass:"p-1 px-2 text-xs mr-2 mt-1",class:{
                    [item.classes]: true,
                    border: item.type === 'general' || item.type === 'administrative',
                }},[_vm._v("\n                "+_vm._s((item.index + 1).toString().padStart(3, '0'))+"\n            ")]):_c('span',{staticClass:"px-2"},[_c('app-static-marker',{staticClass:"text-xs mr-2 -mt-1",attrs:{"observation":item}})],1)]),_c('div',{staticClass:"flex text-xs flex-col"},[_c('div',{staticClass:"flex gap-2"},[(item.recipients.length > 0)?_c('app-bundle',{attrs:{"bundle":item.recipients[0]}}):_vm._e(),(item.preparation)?_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'preparationDocument',
                        params: {
                            projectId: _vm.$route.params.projectId,
                            documentId: item.preparationDocumentId,
                            preparationId: item.visa.preparationId,
                            preparationVisaId: item.relatedContentId,
                        },
                    }}},[_c('span',[_vm._v(_vm._s(item.preparation.name))]),_c('span',[_vm._v("\n                        v"+_vm._s(item.preparation.versions.length -
                            item.preparation.versions.findIndex((p) => p.id === item.preparation.id))+"\n                    ")])]):_vm._e()],1),_c('app-clamp',{attrs:{"html-content":item.title}})],1),_c('div',{staticClass:"flex flex-grow"}),_c('div',[(_vm.isAdmin || _vm.isReporter)?_c('app-button',{attrs:{"size":"mini","icon":"icon-trash-can-outline","variant":"danger"},on:{"click":function($event){return _vm.onRemoveRelatedObservation(item.id)}}}):_vm._e()],1)])}),_c('div',{staticClass:"flex justify-start"},[_c('app-button',{attrs:{"size":"mini","label":_vm.$t('preparations.addRelatedObservation'),"icon":"icon-plus","disabled":_vm.loading},on:{"click":function($event){return _vm.$refs.autocomplete.open()}}}),_c('app-autocomplete',{ref:"autocomplete",attrs:{"options":_vm.observationOptions,"allowStringCriteria":false,"multiple":true,"max-options":100,"filterStringFunction":_vm.filterStringFunction},on:{"input":_vm.onRelatedObservationsInput},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('app-separator',[_c('h2',[_vm._v(_vm._s(_vm.$t('preparations.addRelatedObservation')))]),_c('div',{staticClass:"flex flex-grow"}),_c('app-button',{attrs:{"size":"mini","icon":"icon-close","aria-label":"close popup"},on:{"click":function($event){return _vm.$refs.autocomplete.close()}}})],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"flex text-xs gap-2"},[_c('span',{staticClass:"p-1 px-2",class:item.classes},[_vm._v("\n                        "+_vm._s((item.index + 1).toString().padStart(3, '0').padStart(4, ' '))+"\n                    ")]),_c('app-clamp',{attrs:{"html-content":item.title}})],1)]}}]),model:{value:(_vm.relatedObservations),callback:function ($$v) {_vm.relatedObservations=$$v},expression:"relatedObservations"}})],1),(_vm.loading)?_c('div',{staticClass:"flex justify-center"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }