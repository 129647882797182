<template>
    <div class="w-full" :class="state">
        <app-separator variant="underline" class="text-lg my-4" v-if="$route.params.showTitle || !$route.params.zoneId">
            {{ zone.fullName }}
        </app-separator>
        <table class="w-full text-sm border border-gray-500">
            <thead>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('commons.index') }}
                    </div>
                </th>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('project.editions.observationsReports.recipient') }}
                    </div>
                </th>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('project.editions.observationsReports.room') }}
                    </div>
                </th>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('project.editions.observationsReports.title') }}
                    </div>
                </th>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('project.editions.observationsReports.dueDate') }}
                    </div>
                </th>
                <th class="p-2">
                    <div class="font-bold">
                        {{ $t('commons.status') }}
                    </div>
                </th>
            </thead>
            <template v-for="observation in observations">
                <tr class="border border-gray-500">
                    <td class="p-1 align-top">
                        <div>{{ (observation.index + 1).toString().padStart(3, '0') }}</div>
                    </td>
                    <td class="p-1">
                        <div class="flex flex-col">
                            <template v-for="recipient in observation.recipients">
                                <div class="flex flex-col">
                                    <span>
                                        <span v-if="recipient" class="font-bold mr-2">#{{ recipient.reference }}</span>
                                        <span v-if="recipient">{{ recipient.name }}</span>
                                    </span>
                                    <span>
                                        <span class="text-xs" v-if="recipient">
                                            {{ recipient.company ? recipient.company.name : '' }}
                                        </span>
                                    </span>
                                </div>
                            </template>
                        </div>
                    </td>
                    <td class="p-1">
                        <div>{{ observation.room ? observation.room.name : '' }}</div>
                    </td>
                    <td class="p-1">
                        <div v-html="observation.title" class="whitespace-pre-wrap html-breakline" />
                    </td>
                    <td class="p-1">
                        {{ observation.dueDate | humanizeDate }}
                    </td>
                    <td class="p-1 text-center" :class="getColor(observation)">
                        {{ getStatus(observation) }}
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>
<script>
import { getObservations, getObservationsForRelatedContent } from '@/features/observations/observation.service';
import locationService from '@/services/location.service';
import { getMapById, sortBy } from '@/services/sanitize.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { combineLatest } from 'rxjs';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getCompanies } from '@/features/companies/companies.service';
import {
    filterObservationFromObservationReportConfig,
    getObservationsReport,
} from '@/features/observationsReports/observationsReports.service';

export default {
    components: { AppSeparator },
    async created() {
        this.subscriptions = [
            combineLatest([
                getObservationsReport(this.$route.params.projectId, this.$route.params.observationsReportId),
                getLocationsTree(this.$route.params.projectId),
                this.$route.query.relatedContentId
                    ? getObservationsForRelatedContent(this.$route.params.projectId, this.$route.query.relatedContentId)
                    : getObservations(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
            ]).subscribe(([observationReport, folders, observations, bundleMap, companies]) => {
                this.observationsReport = observationReport;
                const companyMap = getMapById(companies);
                const zones = locationService
                    .buildLocationOptions(folders)
                    .filter((location) => location.type === 'zone');
                this.zone = zones.find((zone) => zone.id === this.$route.params.zoneId) || {
                    fullName: this.$t('project.editions.observationsReports.unlocatedObservations'),
                };
                let bundleIds;
                if (this.$route.query.bundleId) {
                    bundleIds = [this.$route.query.bundleId];
                } else {
                    bundleIds = this.observationsReport.bundleIds;
                }

                this.observations = sortBy(
                    filterObservationFromObservationReportConfig(
                        observations,
                        [this.$route.params.zoneId === 'null' ? null : this.$route.params.zoneId],
                        bundleIds,
                        this.observationsReport,
                    ).map((observation) => ({
                        ...observation,
                        recipients: observation.recipientIds.map((recipientId) => ({
                            ...bundleMap[recipientId],
                            company: companyMap[bundleMap[recipientId].companyId],
                        })),
                    })),
                    'index',
                );
                this.$nextTick(() => {
                    this.state = 'ready';
                });
            }),
        ];
    },
    methods: {
        getStatus(observation) {
            if (observation.validatedAt) {
                return this.$t('project.editions.observationsReports.validated');
            } else if (observation.resolvedAt) {
                return this.$t('project.editions.observationsReports.done');
            } else if (observation.type === 'onTodoWork') {
                return this.$t('project.editions.observationsReports.toDo');
            } else if (observation.type === 'onDoneWork') {
                return this.$t('project.editions.observationsReports.toFix');
            } else if (observation.type === 'other') {
                return this.$t('project.editions.observationsReports.toFix');
            }
        },
        getColor(observation) {
            if (observation.validatedAt) {
                return 'observations-validated';
            } else if (observation.resolvedAt) {
                return 'observations-done';
            } else if (observation.type === 'onTodoWork') {
                return 'observations-to-fix';
            } else if (observation.type === 'onDoneWork') {
                return 'observations-to-fix';
            } else if (observation.type === 'other') {
                return 'observations-to-fix';
            }
        },
    },
    data() {
        return {
            observations: [],
            subscriptions: [],
            observationsReport: { showMaps: false },
            zone: {},
            state: 'loading',
            bundles: [],
        };
    },
};
</script>
