<template>
    <div class="w-full">
        <app-separator class="cursor-pointer mb-0" @click.prevent="onToggleDetail">
            <div class="flex items-center font-normal w-full gap-1">
                <icon class="mr-3" :name="detailIsOpen ? 'icon-chevron-down' : 'icon-chevron-up'"></icon>
                <div class="flex items-center mr-3" v-if="structure === 'FLB'">
                    <router-link
                        :to="{
                            name: 'folders',
                            params: { projectId: $route.params.projectId },
                        }"
                        class="underline flex"
                    >
                        {{ task.location.fullName }}
                    </router-link>
                    <icon-chevron-right />

                    <router-link
                        :to="{
                            name: 'bundle',
                            params: { bundleId: task.service.bundleId, projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ task.service.bundle.label }}
                    </router-link>
                    <icon-chevron-right />
                    <router-link
                        :to="{
                            name: 'service',
                            params: {
                                bundleId: task.service.bundleId,
                                serviceId: task.serviceId,
                                projectId: $route.params.projectId,
                            },
                        }"
                        class="underline"
                    >
                        <span>{{ task.name }}</span>
                    </router-link>
                </div>
                <div class="flex items-center mr-3" v-else-if="structure === 'FBL'">
                    <router-link
                        :to="{
                            name: 'folders',
                            params: { projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ task.location.fullName }}
                    </router-link>
                    <icon-chevron-right />
                    <router-link
                        :to="{
                            name: 'bundle',
                            params: { bundleId: task.service.bundleId, projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ task.service.bundle.label }}
                    </router-link>
                    <icon-chevron-right />
                    <router-link
                        :to="{
                            name: 'service',
                            params: {
                                bundleId: task.service.bundleId,
                                serviceId: task.serviceId,
                                projectId: $route.params.projectId,
                            },
                        }"
                        class="underline"
                    >
                        <span>{{ task.name }}</span>
                    </router-link>
                </div>
                <div class="flex items-center mr-3" v-else>
                    <router-link
                        :to="{
                            name: 'bundle',
                            params: { bundleId: task.service.bundleId, projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ task.service.bundle.label }}
                    </router-link>
                    <icon-chevron-right />

                    <router-link
                        :to="{
                            name: 'folders',
                            params: { projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ task.location.fullName }}
                    </router-link>
                    <icon-chevron-right />

                    <router-link
                        :to="{
                            name: 'service',
                            params: {
                                bundleId: task.service.bundleId,
                                serviceId: task.serviceId,
                                projectId: $route.params.projectId,
                            },
                        }"
                        class="underline"
                    >
                        <span>{{ task.name }}</span>
                    </router-link>
                </div>
                <div class="flex gap-1 items-center text-sm">
                    <span>{{ $t('commons.from') }}</span>
                    <span class="underline" @click.stop="$emit('navigateToDate', startDate)">
                        {{ startDate | humanizeDate }}
                    </span>
                    <span v-if="endDate">{{ $t('commons.to') }}</span>
                    <span class="underline" @click.stop="$emit('navigateToDate', endDate)">
                        {{ endDate | humanizeDate }}
                    </span>
                </div>
            </div>
        </app-separator>
        <div v-if="detailIsOpen">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import AppSeparator from '../../../components/appSeparator/AppSeparator';
import { updatePlanningState, queryPlanningState } from '@/state/state';

export default {
    components: {
        AppSeparator,
    },
    props: ['task', 'structure'],
    created() {
        this.detailIsOpen = queryPlanningState(this.$route.params.projectId).detailIsOpen;
    },
    methods: {
        async onToggleDetail() {
            this.detailIsOpen = !this.detailIsOpen;
            this.$nextTick(() => this.$emit('change'));
            updatePlanningState(this.$route.params.projectId, {
                detailIsOpen: this.detailIsOpen,
            });
        },
    },
    computed: {
        startDate() {
            return this.task.realStartDate || this.task.startDate || new Date();
        },
        endDate() {
            return this.task.estimatedEndDate;
        },
    },
    data() {
        return {
            detailIsOpen: true,
        };
    },
};
</script>
