var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-none"},[_c('div',{class:{
            'hover:bg-gray-300': _vm.isSelected,
            'hover:bg-gray-200': !_vm.isSelected && !_vm.dragged,
            'bg-gray-300': _vm.isSelected || _vm.isDropZoneInOver,
            'pb-2 border-b': !(!_vm.item.isOpen || !_vm.item.children || _vm.item.children.length === 0),
            'opacity-25': _vm.dragged,
        }},[(_vm.index === 0)?_c('drop',{staticClass:"p-1",class:{
                'bg-gray-300 p-2': _vm.isDropZoneBeforeOver,
            },on:{"dragover":_vm.onDragOverBefore,"dragenter":_vm.onDragOverBefore,"dragleave":_vm.resetDragStatus,"drop":_vm.onDropBefore}},[(_vm.isDropZoneBeforeOver)?_c('span',[_vm._v(" ")]):_vm._e()]):_vm._e(),_c(_vm.item.allowDropIn ? 'drop' : 'div',{tag:"component",on:{"dragover":_vm.onDragOverIn,"dragenter":_vm.onDragOverIn,"dragleave":_vm.resetDragStatus,"drop":_vm.onDropIn}},[_c('div',{staticClass:"flex items-center w-full px-2",class:{ 'pt-2': _vm.index > 0 },style:({
                    'padding-left': (_vm.level - 1) * 2 + 'rem',
                }),on:{"click":function($event){return _vm.$emit('select', _vm.item)},"dblclick":function($event){return _vm.$emit('edit', _vm.item)}}},[(_vm.isFolder)?_c('button',{staticClass:"mx-2",attrs:{"aria-label":_vm.item.isOpen ? 'collapse ' + _vm.item.name : 'expand ' + _vm.item.name},on:{"click":_vm.toggle}},[(_vm.item.isOpen)?_c('icon-minus-box-outline',{staticClass:"cursor-pointer",attrs:{"width":"16px","height":"16px"}}):_c('icon-plus-box-outline',{staticClass:"cursor-pointer",attrs:{"width":"16px","height":"16px"}})],1):_c('span',{staticClass:"mx-2",staticStyle:{"width":"16px"}}),_vm._t("item",function(){return [(_vm.item.icon)?_c('icon',{attrs:{"name":_vm.item.icon,"width":"16px","height":"16px"}}):_vm._e(),_c('span',{staticClass:"pl-2 whitespace-nowrap truncate",attrs:{"title":_vm.item.name}},[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"flex-grow"})]},{"item":_vm.item}),_c('drag',{staticClass:"cursor-grab",class:{ 'opacity-0': !_vm.item.isDraggable, 'cursor-grab': _vm.item.isDraggable },attrs:{"draggable":!!_vm.item.isDraggable,"transfer-data":_vm.item},on:{"dragstart":_vm.onDrag,"dragend":function($event){_vm.dragged = false}}},[_c('IconDrag',{staticClass:"text-gray-400"})],1)],2)]),(!_vm.item.isOpen || !_vm.isFolder || _vm.item.children.length === 0)?_c('drop',{staticClass:"p-1 border-b",class:{
                'bg-gray-300 p-2': _vm.isDropZoneAfterOver,
            },on:{"dragover":_vm.onDragOverAfter,"dragenter":_vm.onDragOverAfter,"dragleave":_vm.resetDragStatus,"drop":_vm.onDropAfter},nativeOn:{"click":function($event){return _vm.$emit('select', _vm.item)}}},[(_vm.isDropZoneAfterOver)?_c('span',[_vm._v(" ")]):_vm._e()]):_vm._e()],1),(_vm.item.isOpen && !_vm.dragged && _vm.isFolder)?_c('ul',{staticClass:"p-0"},_vm._l((_vm.item.children),function(child,index){return _c('app-node',{key:index,ref:"children",refInFor:true,staticClass:"item",attrs:{"selectedId":_vm.selectedId,"level":_vm.level + 1,"index":index,"item":child},on:{"select":function($event){return _vm.$emit('select', $event)},"drop":function($event){return _vm.$emit('drop', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"open":function($event){return _vm.$emit('open', $event)},"close":function($event){return _vm.$emit('close', $event)}},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }