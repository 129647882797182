<template>
    <main class="max-w-5xl m-5 mb-10 w-full p-1">
        <ReadOnlyPreparation v-if="!isAdmin" :is-emitter="isEmitter" />
        <PreparationForm ref="form" v-else />
        <PreparationAttachments
            ref="document"
            :is-admin="isAdmin"
            :allow-new-attachment="isLastVersion && !hasConcludedVisa"
            :me="project ? project.me : null"
        />
        <PreparationVisas ref="visa" :editable="isAdmin" />
        <div>
            <app-button
                v-if="
                    isAdmin &&
                    preparation.versions &&
                    preparation.versions.length > 1 &&
                    preparation.versions[preparation.versions.length - 1].id !== preparation.id
                "
                size="mini"
                icon="icon-trash-can-outline"
                variant="danger"
                @click="removePreparation()"
                :label="$t('commons.removeThisVersion')"
                class="m-2"
            />
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import PreparationForm from './PreparationForm';
import PreparationVisas from './PreparationVisas';
import ReadOnlyPreparation from './ReadOnlyPreparation';
import { getPreparation, removePreparation } from './preparations.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import { getProject } from '@/features/projects/projects.service';
import PreparationAttachments from '@/features/preparations/PreparationAttachments';
import AppButton from '@/components/appButton/AppButton.vue';
import { combineLatest } from 'rxjs';
import { getPreparationVisas } from '@/features/preparations/preparationVisas.service';

export default {
    components: {
        AppButton,
        PreparationAttachments,
        PreparationForm,
        PreparationVisas,
        AppQuickActions,
        ReadOnlyPreparation,
    },

    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisas(this.$route.params.projectId, this.$route.params.preparationId),
                ]).subscribe(([project, preparation, visas]) => {
                    this.project = project;
                    this.preparation = preparation;
                    this.isLastVersion =
                        preparation.versions.findIndex((version) => version.id === this.$route.params.preparationId) ===
                        0;
                    this.hasConcludedVisa = visas.find((visa) => visa.conclusion);
                    this.isAdmin =
                        (preparation.phase === 'exe' && project.me.allowedFeatures.includes('project_preparations')) ||
                        (preparation.phase === 'con' && project.me.allowedFeatures.includes('project_conceptions'));
                    this.isEmitter = project.me.bundleIds.includes(preparation.bundleId);
                    updateBreadCrumbs({
                        preparationName: preparation.name,
                    });
                }),
            ];
        },
        async removePreparation() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                removePreparation(this.$route.params.projectId, this.$route.params.preparationId);
                if (this.preparation.versions.length > 1) {
                    this.$router.push({
                        name: this.$route.name,
                        params: {
                            projectId: this.$route.params.projectId,
                            preparationId: this.preparation.versions[1].id,
                        },
                    });
                } else {
                    this.$router.push({
                        name: this.$route.meta.parentName,
                        params: {
                            projectId: this.$route.params.projectId,
                        },
                    });
                }
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            preparation: { versions: [] },
            project: null,
            isAdmin: false,
            isEmitter: false,
            isLastVersion: false,
            hasConcludedVisa: false,
        };
    },
    computed: {
        quickActions() {
            if (!this.isAdmin) {
                return [];
            }
            const defaultOptions = [
                {
                    name: this.$t('preparations.newPreparation'),
                    run: () => this.$refs.form.createNew(),
                },
                {
                    name: this.$t('preparations.copy'),
                    run: () => this.$refs.form.duplicate(),
                },
                {
                    name: this.$t('preparations.copyByBundles'),
                    run: () => this.$refs.form.copyByBundles(),
                },
                {
                    name: this.$t('preparations.newVisa'),
                    run: () => this.$refs.visa.addVisa(),
                },
                {
                    name: this.$t('preparations.newDocument'),
                    run: () => this.$refs.document.newDocument(),
                },
                {
                    name: this.$t('commons.newVersion'),
                    run: () => this.$refs.form.onNewVersion(),
                },
            ];
            if (
                this.preparation.versions &&
                this.preparation.versions.length > 0 &&
                this.preparation.versions[0].id === this.preparation.id
            ) {
                defaultOptions.push({
                    name:
                        this.preparation.versions.length === 1
                            ? this.$t('commons.removeThisGroup')
                            : this.$t('commons.removeThisVersion'),
                    run: async () => {
                        this.removePreparation();
                    },
                });
            }
            return defaultOptions;
        },
    },
};
</script>
