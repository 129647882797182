<template>
    <div class="w-full flex flex-col items-center justify-center text-xs gap-4 sm:max-w-2xl" v-if="tasks.length > 0">
        <h3 class="sm:text-lg font-bold">{{ $t('dashboard.lateByBundle') }}</h3>

        <div class="flex flex-col sm:flex-row gap-4">
            <label :title="$t('project.follow.planning.relativeLateTips')">
                <input type="radio" name="lateType" v-model="lateType" value="relativeLate" />
                {{ $t('project.follow.planning.relativeLate') }}
            </label>
            <label :title="$t('project.follow.planning.absoluteLateTips')">
                <input type="radio" name="lateType" v-model="lateType" value="absoluteLate" />
                {{ $t('project.follow.planning.absoluteLate') }}
            </label>
            <template v-if="false">
                <label :title="$t('project.follow.planning.criticalLateTips')">
                    <input type="radio" name="lateType" v-model="lateType" value="criticalLate" />
                    {{ $t('project.follow.planning.criticalLate') }}
                </label>
            </template>
        </div>
        <div class="flex flex-col w-full items-center">
            <app-double-bar-chart class="" :data="data"></app-double-bar-chart>
            <div class="flex mt-2">
                <div>
                    <span class="px-2 bg-green-500 border border-black mx-2"></span>
                    <span class="mr-4">{{ $t('dashboard.advancedInDays') }}</span>
                </div>
                <div>
                    <span class="px-2 bg-red-500 border border-black mx-2"></span>
                    <span class="mr-4">{{ $t('dashboard.lateInDays') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppBarChart from '@/components/app-barChart/AppBarChart';
import { sortBy } from '@/services/sanitize.service';
import AppDoubleBarChart from '@/components/appDoubleBarChart/AppDoubleBarChart';
import AppTips from '@/components/app-tips/AppTips';
import AppBarChartTable from '@/components/appBarChartTable/AppBarChartTable';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import groupsService from '@/features/planning/planning/groups.service';

export default {
    components: { AppBarChartTable, AppTips, AppDoubleBarChart, AppBarChart },
    props: {
        bundles: { type: Array, default: () => [] },
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getProject(this.$route.params.projectId),
                getCalendar(this.$route.params.projectId),
            ]).subscribe(([tasks, project, calendar]) => {
                this.project.agenda = calendar;
                this.project.startDate = project.startDate;
                this.project.endDate = project.endDate;
                this.project.tasks = tasks;
            }),
        ];
    },
    methods: {
        getLate(task) {
            if (this.lateType === 'relativeLate') {
                return task.late;
            } else if (this.lateType === 'absoluteLate') {
                return task.referenceLate;
            } else if (this.lateType === 'criticalLate') {
                return task.criticalLate;
            }
        },
    },
    computed: {
        tasks() {
            const selectedBundleIds = this.bundles.map((bundle) => bundle.id);
            return this.project.tasks.filter(
                (task) => this.bundles.length === 0 || selectedBundleIds.includes(task.service.bundleId),
            );
        },
        data() {
            const result = {
                series: [],
                options: {
                    classes: ['bg-green-500', 'bg-red-500'],
                },
            };
            for (const bundle of this.bundles) {
                const bundleTasks = this.tasks.filter((task) => task.service.bundleId === bundle.id);
                let late = 0;
                if (this.lateType === 'absoluteLate') {
                    late = groupsService.getAbsoluteGroupLate(bundleTasks);
                } else {
                    late = groupsService.getGroupLate({ descendants: bundleTasks });
                }
                result.series.push({
                    values: [late < 0 ? -late : 0, late > 0 ? late : 0],
                    late,
                    label: (bundle.reference ? '#' + bundle.reference + ' ' : '') + bundle.name,
                });
            }
            result.series = sortBy(result.series, (serie) => serie.late).reverse();
            return result;
        },
    },
    data() {
        return {
            lateType: 'relativeLate',
            project: { bundles: [], agenda: [], tasks: [], certificates: [], receptions: [] },
            subscriptions: [],
        };
    },
};
</script>
