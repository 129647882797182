<template>
    <main class="max-w-5xl m-5 h-full w-full">
        <AppLegend />
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form>
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        v-model="preparationsReport.name"
                        class="col-span-2"
                        :required="true"
                        @blur="save"
                        :label="$t('commons.name')"
                        :disabled="readOnly"
                    />
                    <app-date-input
                        v-model="preparationsReport.date"
                        class="col-span-2"
                        @input="save"
                        :label="$t('project.editions.preparationsReports.date')"
                        :disabled="readOnly"
                    />
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.content')">
                    <div class="m-4 col-span-2 flex flex-col items-start">
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showToEmit"
                            :label="$t('project.editions.preparationsReports.showToEmit')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showToVisa"
                            :label="$t('project.editions.preparationsReports.showToVisa')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showApproved"
                            :label="$t('project.editions.preparationsReports.showApproved')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showApprovedWithComments"
                            :label="$t('project.editions.preparationsReports.showApprovedWithComments')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showRejected"
                            :label="$t('project.editions.preparationsReports.showRejected')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showVised"
                            :label="$t('project.editions.preparationsReports.showVised')"
                            :disabled="readOnly"
                        />
                        <app-checkbox
                            class="col-span-2"
                            @input="save"
                            v-model="preparationsReport.showAllVersions"
                            :label="$t('project.editions.preparationsReports.showAllVersions')"
                            :disabled="readOnly"
                        />
                    </div>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="preparationsReport.bundles"
                        :options="bundles"
                        :label="$t('bundles.bundles')"
                        :placeholder="$t('commons.all')"
                        @close="updateBundles"
                        label-key="label"
                        @removeChips="updateBundles"
                        :disabled="readOnly"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="preparationsReport.locations"
                        :options="sortedLocationOptions"
                        :label="$t('project.editions.preparationsReports.locations')"
                        :placeholder="$t('commons.allFemale')"
                        @close="updateLocations"
                        label-key="fullName"
                        @removeChips="updateLocations"
                        :disabled="readOnly"
                    ></app-multi-picker>
                </app-fieldset>
                <app-save-on-leave :dirty="dirty" :saveFn="save"></app-save-on-leave>
            </form>
        </ValidationObserver>

        <app-separator class="col-span-2 mt-4" :label="$t('project.editions.preparationsReports.files')" />
        <div class="flex flex-col">
            <div class="m-2 flex items-center">
                <app-button
                    v-if="!readOnly"
                    @click="generatePDF"
                    :label="
                        preparationsReport.generationStartDate
                            ? $t('project.editions.preparationsReports.regenerateFile')
                            : $t('project.editions.preparationsReports.generateFile')
                    "
                ></app-button>
            </div>
            <div class="flex flex-col gap-2 m-2">
                <template>
                    <div
                        class="flex"
                        v-if="!preparationsReport.generationEndDate && preparationsReport.generationStartDate"
                    >
                        <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                        {{ preparationsReport.name }}
                    </div>
                    <div
                        class="flex gap-2"
                        v-else-if="preparationsReport.generationEndDate && preparationsReport.generationSucceed"
                    >
                        <app-file-link
                            :file-name="preparationsReport.name + '.pdf'"
                            :url="preparationsReport.url"
                            class="underline"
                        />
                        <app-button
                            icon="icon-email-edit-outline"
                            size="mini"
                            @click="$refs.emailPopup.open(preparationsReport, relevantBundleIds)"
                            v-if="!readOnly"
                        ></app-button>
                    </div>

                    <div
                        class="flex text-red-500 items-center gap-2"
                        v-else-if="preparationsReport.generationEndDate && !preparationsReport.generationSucceed"
                    >
                        <icon-alert-circle width="16"></icon-alert-circle>
                        {{ preparationsReport.name }}
                        <span class="text-xs italic">
                            {{ $t('project.editions.preparationsReports.generationFailed') }}
                        </span>
                    </div>
                </template>
            </div>
        </div>
        <preparations-report-mail-popup ref="emailPopup"></preparations-report-mail-popup>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppLegend from '@/components/appLegend/AppLegend';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppInputText from '@/components/appInputText/AppInputText';
import { getPreparationsReport, updatePreparationsReport } from './preparationsReports.service';
import AppFooter from '@/components/appFooter/AppFooter';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppButton from '@/components/appButton/AppButton';
import IconAlertCircle from '@/icons/IconAlertCircle';
import { getMapById, sortBy } from '@/services/sanitize.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppBundle from '@/components/app-bundle/appBundle';
import { confirm } from '@/features/dialogs/dialogs.service';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getAPIHeaders } from '@/services/api.service';
import locationService from '@/services/location.service';
import PreparationsReportMailPopup from '@/features/preparationsReports/PreparationsReportMailPopup.vue';
import { getPreparations, getConclusionIds, matchMultiFilter } from '@/features/preparations/preparations.service';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        PreparationsReportMailPopup,
        AppBundle,
        AppDateInput,
        IconAlertCircle,
        AppButton,
        AppFileLink,
        AppSeparator,
        AppFooter,
        AppInputText,
        AppSaveOnLeave,
        AppMultiPicker,
        AppCheckbox,
        AppFieldset,
        AppLegend,
    },
    created() {
        this.subscriptions = [
            combineLatest([
                getPreparationsReport(this.$route.params.projectId, this.$route.params.preparationsReportId),
                getBundles(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getPreparations(this.$route.params.projectId, 'exe'),
                getProject(this.$route.params.projectId),
            ]).subscribe(([preparationsReport, bundles, folders, preparations, project]) => {
                this.readOnly = !project.me.allowedFeatures.includes('project_preparationsReports');
                this.bundles = bundles;
                const bundleMap = getMapById(bundles);
                const locationMap = getMapById(locationService.buildLocationOptions(folders));
                this.preparationsReport = {
                    ...preparationsReport,
                    bundles: sortBy(
                        preparationsReport.bundleIds.map((bundleId) => bundleMap[bundleId]),
                        'reference',
                    ),
                    locations: preparationsReport.locationIds.map((locationId) => locationMap[locationId]),
                };
                this.sortedLocationOptions = sortBy(
                    folders.reduce((acc, folder) => [...acc, ...folder.locations], []),
                    (location) => location.fullName,
                );

                const matchingPreparations = preparations.filter((preparation) =>
                    matchMultiFilter(
                        preparation,
                        [],
                        preparationsReport.bundleIds,
                        preparationsReport.locationIds,
                        getConclusionIds(this.preparationsReport),
                    ),
                );
                this.relevantBundleIds = matchingPreparations.reduce(
                    (acc, preparation) => [
                        ...acc,
                        preparation.bundleId,
                        ...preparation.visas.map((visa) => visa.emitterId),
                    ],
                    [],
                );
                updateBreadCrumbs({ preparationsReportName: this.preparationsReport.name });
            }),
        ];
    },
    data() {
        return {
            readOnly: true,
            relevantBundleIds: [],
            bundles: [],
            sortedLocationOptions: [],
            sortedSelectedLocations: [],
            preparationsReport: { bundles: [], locations: [] },
        };
    },
    methods: {
        async generatePDF() {
            if (!this.preparationsReport.url || (await confirm(this.$t('project.editions.confirmRegenerate')))) {
                await fetch(
                    new Request(
                        `/api/projects/${this.$route.params.projectId}/preparationsReports/${this.preparationsReport.id}/report`,
                        {
                            method: 'PUT',
                            headers: getAPIHeaders(),
                        },
                    ),
                );
            }
        },
        updateBundles() {
            return updatePreparationsReport(this.$route.params.projectId, {
                id: this.preparationsReport.id,
                bundleIds: this.preparationsReport.bundles.map((bundle) => bundle.id),
            });
        },
        updateLocations() {
            return updatePreparationsReport(this.$route.params.projectId, {
                id: this.preparationsReport.id,
                locationIds: this.preparationsReport.locations.map((location) => location.id),
            });
        },
        save() {
            return updatePreparationsReport(this.$route.params.projectId, {
                id: this.preparationsReport.id,
                date: this.preparationsReport.date,
                name: this.preparationsReport.name,
                showApproved: this.preparationsReport.showApproved,
                showApprovedWithComments: this.preparationsReport.showApprovedWithComments,
                showRejected: this.preparationsReport.showRejected,
                showToEmit: this.preparationsReport.showToEmit,
                showToVisa: this.preparationsReport.showToVisa,
                showVised: this.preparationsReport.showVised,
                showLocations: this.preparationsReport.showLocations,
                showAllVersions: this.preparationsReport.showAllVersions,
            });
        },
    },
};
</script>
