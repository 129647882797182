<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button
                    @click="addPreparationsReport()"
                    :label="$t('project.editions.preparationsReports.new')"
                    v-if="!readOnly"
                />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                </template>
            </app-multi-picker>
            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 6rem" class="text-left border-r p-1">
                            {{ $t('commons.date') }}
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('commons.name') }}</span>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('commons.pdf') }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="preparationsReport in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + preparationsReport.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="preparationsReport.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(preparationsReport)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ preparationsReport.date | humanizeDate }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: isCon ? 'conceptionsReport' : 'preparationsReport',
                                            params: {
                                                ...$route.params,
                                                preparationsReportId: preparationsReport.id,
                                            },
                                        }"
                                    >
                                        {{ preparationsReport.name }}
                                        <span
                                            v-if="
                                                !preparationsReport.name || preparationsReport.name.trim().length === 0
                                            "
                                        >
                                            ...
                                        </span>
                                    </router-link>
                                </div>
                            </td>
                            <td class="text-center border-r p-1">
                                <div class="flex" v-if="preparationsReport.url">
                                    <app-file-link :url="preparationsReport.url" :showName="false"></app-file-link>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { humanizeDate } from '@/filters/dateFilter';
import {
    createPreparationsReport,
    getPreparationsReports,
    removePreparationsReport,
} from '@/features/preparationsReports/preparationsReports.service';
import { format } from 'date-fns';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';

export default {
    components: {
        AppFileLink,
        AppButton,
        AppMultiPicker,
        AppCheckbox,
        AppSelect,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        this.isCon = this.$route.name === 'conceptionsReports';
        this.init();
    },
    computed: {
        filteredItems() {
            return this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
        },
        filterOptions() {
            return [];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getPreparationsReports(this.$route.params.projectId, this.isCon ? 'con' : 'exe'),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([preparationsReports, project]) => {
                    this.items = sortBy(preparationsReports, 'date')
                        .reverse()
                        .map((item) => ({
                            ...item,
                            filterString: humanizeDate(item.date) + ' ' + item.name,
                        }));
                    this.readOnly = !project.me.allowedFeatures.includes('project_preparationsReports');
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        async addPreparationsReport() {
            const result = await createPreparationsReport(this.$route.params.projectId, {
                name: this.$t('project.editions.preparationsReports.newName') + ' ' + format(new Date(), 'dd/MM/yyyy'),
                date: new Date(),
                showToEmit: true,
                showToVisa: true,
                showVised: true,
                showApproved: true,
                showApprovedWithComments: true,
                showRejected: true,
                showLocations: false,
                showAllVersions: false,
                locationIds: [],
                bundleIds: [],
                phase: this.isCon ? 'con' : 'exe',
            });
            this.$router.push({
                name: this.isCon ? 'conceptionsReport' : 'preparationsReport',
                params: {
                    projectId: this.$route.params.projectId,
                    preparationsReportId: result.id,
                },
            });
        },
        scrollToLastVisited() {
            const lastVisitedPreparationsReportId = localStorage.getItem(
                'preparationsReports.lastVisitedPreparationsReportId.' + this.$route.params.projectId,
            );
            if (lastVisitedPreparationsReportId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedPreparationsReportId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removePreparationsReport(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'preparationsReports.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('preparationsReports.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'preparationsReports_filter_' + this.$route.params.projectId,
                JSON.stringify({ filterValue }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('preparationsReports_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchString: this.matchString(stringCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
    },
    data() {
        return {
            loading: true,
            readOnly: true,
            selection: [],
            items: [],
            filterValue: [],
        };
    },
};
</script>
