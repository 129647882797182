import { treeToLines } from '@/features/planning/planning2/planning2.service';

const defaultLocation = { id: 'default', fullName: 'Ensemble' };

export function toTaskLBSLocationNode(id, name) {
    return {
        id,
        name,
        type: 'location',
        level: 0,
        children: [],
    };
}

export function toTaskLBS(branches, definedLocations) {
    const locations = [defaultLocation, ...definedLocations];
    return treeToLines(
        locations
            .map((location) => {
                const locationNode = toTaskLBSLocationNode(location.id, location.fullName);
                const locationBranches = branches.filter(
                    (task) =>
                        (task.locationId && location.id === task.locationId) ||
                        (!task.locationId && location.id === defaultLocation.id),
                );
                appendBranches(locationNode, locationBranches);
                return locationNode;
            })
            .filter((node) => node.children.length > 0),
    );
}
export function isLocationNode(location, node) {
    return location.name.includes(node.name);
}
export function appendBranches(locationNode, branches) {
    const cache = {};
    for (const branch of branches) {
        appendBranch(locationNode, branch, locationNode, cache);
    }
}
export function appendBranch(parentNode, nodeRest, location, cache) {
    const node = nodeRest[0];
    let line = cache[node.id] || cache[location.id + node.id];
    if (!line) {
        if (!isLocationNode(location, node)) {
            const newNode = {
                id: nodeRest.length === 1 ? node.id : location.id + node.id,
                name: node.name,
                type: node.type,
                level: parentNode.level + 1,
                children: [],
            };
            cache[newNode.id] = newNode;
            parentNode.children.push(newNode);
            if (nodeRest.length > 1) {
                nodeRest.shift();
                appendBranch(newNode, nodeRest, location, cache);
            }
        } else {
            if (nodeRest.length > 1) {
                nodeRest.shift();
                appendBranch(parentNode, nodeRest, location, cache);
            }
        }
    }
    if (nodeRest.length > 1) {
        nodeRest.shift();
        appendBranch(line, nodeRest, location, cache);
    }
}

export default {
    toTaskLBS,
};
