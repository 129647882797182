import { create } from 'vue-modal-dialogs';
import Confirm from './Confirm';
import SaveBeforeLeave from './SaveBeforeLeave';
import InvalidForm from './InvalidForm';

const confirmFn = create(Confirm, 'content');
const saveBeforeLeaveFn = create(SaveBeforeLeave, 'content');
const invalidFormFn = create(InvalidForm, 'content');

export function confirm(message) {
    return confirmFn(message);
}
export function saveBeforeLeave(message) {
    return saveBeforeLeaveFn(message);
}
export function invalidForm(message) {
    return invalidFormFn(message);
}
