<template>
    <div class="w-full m-4">
        <app-report-header
            :document-name="
                preparation.phase === 'exe'
                    ? $t('project.editions.preparationVisa.documentNameExe')
                    : $t('project.editions.preparationVisa.documentNameCon')
            "
            :documentTitle="documentTitle"
        ></app-report-header>
        <preparation-visa-summary-print></preparation-visa-summary-print>
        <div v-for="page in pages" style="page-break-before: always">
            <a :href="page.url">
                <app-separator
                    variant="underline"
                    :label="page.name + (page.page ? ' (page ' + page.page + ')' : '')"
                    icon="icon-file-document-outline"
                    class="font-bold mb-2"
                />
            </a>
            <div style="height: 1380px; width: 100%" v-if="page.page">
                <app-leaflet-viewer
                    :src="page.url"
                    :page="page.page"
                    :shapes="
                        page.observations
                            .filter((obs) => obs.footprint)
                            .map((obs) => ({
                                ...obs.footprint,
                                label: obs.index + 1 + '',
                                style: { color: 'red', stroke: 1 },
                            }))
                    "
                    :preview="true"
                    class="w-full h-full text-xs"
                ></app-leaflet-viewer>
            </div>
            <table
                class="border-2 border-collapse m-5 table-fixed"
                :style="{ 'page-break-before': page.page ? 'always' : 'none', width: 'calc(100% - 1.25 * 2rem)' }"
                v-if="page.observations.length"
            >
                <template v-for="observation in page.observations">
                    <tr class="border-2" style="page-break-inside: avoid">
                        <td class="border-2">
                            <div class="flex flex-wrap p-2">
                                <div>
                                    <div class="font-bold text-lg">#{{ observation.index + 1 }}</div>
                                    <div>
                                        <span v-html="observation.title" class="whitespace-pre-wrap html-breakline" />
                                    </div>
                                </div>
                                <app-leaflet-viewer
                                    style="width: 100%; height: 100%"
                                    v-if="observation.attachments.length > 0"
                                    :src="observation.attachments[0].url"
                                    :shapes="observation.attachments[0].shapes"
                                    :preview="true"
                                    :image-full-size="true"
                                ></app-leaflet-viewer>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import AppReportHeader from '@/components/appReportHeader/AppReportHeader';
import PreparationVisaSummaryPrint from '@/features/preparations/preparationVisaPrint/PreparationVisaSummaryPrint';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import { getMapById, groupBy, sortBy } from '@/services/sanitize.service';
import { getPreparationVisa } from '@/features/preparations/preparationVisas.service';
import { getPreparation } from '@/features/preparations/preparations.service';
import { combineLatest } from 'rxjs';
import { getPreparationAttachments } from '@/features/preparations/preparationAttachments.service';
import { getObservationsForRelatedContent } from '@/features/observations/observation.service';
import { getObservationAttachments } from '@/features/observations/observationAttachments.service';

export default {
    components: {
        AppLeafletViewer,
        AppSeparator,
        PreparationVisaSummaryPrint,
        AppReportHeader,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPreparationVisa(this.$route.params.projectId, this.$route.params.preparationVisaId),
                getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                getPreparationAttachments(this.$route.params.projectId, this.$route.params.preparationId),
                getObservationsForRelatedContent(this.$route.params.projectId, this.$route.params.preparationVisaId),
                getObservationAttachments(this.$route.params.projectId),
            ]).subscribe(
                ([preparationVisa, preparation, preparationAttachments, observations, observationAttachments]) => {
                    this.preparation = preparation;
                    this.visa = preparationVisa;
                    this.documentTitle =
                        (this.preparation.code ? '#' + this.preparation.code : '') + this.preparation.name;
                    const relevantObservations = sortBy(
                        observations.map((observation) => ({
                            ...observation,
                            attachments: observationAttachments.filter(
                                (attachment) => attachment.observationId === observation.id,
                            ),
                            pageId: observation.page
                                ? observation.preparationDocumentId + '_' + observation.page
                                : observation.preparationDocumentId,
                        })),
                        'index',
                    );
                    const preparationAttachmentMap = getMapById(preparationAttachments);
                    this.pages = sortBy(groupBy(relevantObservations, 'pageId'), (item) => item[0].pageId)
                        .map((group) => ({
                            ...preparationAttachmentMap[group[0].preparationDocumentId],
                            observations: group,
                            page: group[0].page,
                        }))
                        .filter((page) => !!page.url);
                },
            ),
        ];
    },
    data() {
        return {
            documentTitle: '',
            preparation: { service: {}, visa: [] },
            pages: [],
            subscriptions: [],
        };
    },
};
</script>
