<template>
    <nav role="navigation">
        <ul class="border-r text-black app-menu max-h-main min-h-main overflow-y-auto flex-col flex pb-10 md:pb-0">
            <slot name="beforeItems"></slot>
            <li
                class="flex flex-col"
                v-for="item in menuItems"
                :key="item.title"
                :class="{
                    'selected-group': $route.matched.some((route) => startWithPath(route.meta.menu, item.menu)),
                    'pb-2': item.items.length > 0,
                }"
            >
                <a
                    href="#"
                    class="pl-3 flex items-center cursor-pointer"
                    @keypress.enter="navigateToGroup(item)"
                    @click="navigateToGroup(item)"
                >
                    <icon :name="item.icon" v-if="item.icon" width="16px"></icon>
                    <div
                        class="p-2"
                        :class="{
                            'font-bold': $route.matched.some((route) => startWithPath(route.meta.menu, item.menu)),
                        }"
                    >
                        {{ $t(item.title) }}
                    </div>
                </a>
                <ul v-if="$route.matched.some((route) => startWithPath(route.meta.menu, item.menu))">
                    <li
                        role="menuitem"
                        v-for="subItem in item.items"
                        :key="subItem.title"
                        :class="{
                            'font-bold selected-item': $route.matched.some((route) =>
                                startWithPath(route.meta.menu, subItem.menu),
                            ),
                        }"
                        class="text-sm cursor-pointer flex pl-2"
                    >
                        <router-link
                            :to="{ name: subItem.route }"
                            class="pl-6 p-2 w-full"
                            @click.native="navigate(item)"
                        >
                            {{ $t(subItem.title) }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <li class="flex flex-col m-4 p-1 text-xs justify-center items-center">
                <a
                    href=""
                    class="flex items-center justify-center underline pointer p-1"
                    @click="$router.push({ name: 'help' })"
                >
                    <icon-lifebuoy width="16px" class="inline" />
                    <span class="p-2">{{ $t('menu.needHelp') }}</span>
                </a>
            </li>
            <li class="flex flex-col items-center text-center justify-end flex-grow text-xs gap-1 text-gray-700">
                <img src="/logoAtex.svg" style="width: 32px" alt="Atex Logo" />
                <app-upgrade-button :show-icon="true">
                    <span class="">v2.1.PATCH_VERSION</span>
                </app-upgrade-button>
                <span class="my-2 text-sm">&copy; 2020-2022 Atex</span>
            </li>
        </ul>
    </nav>
</template>
<script>
import { isMobile, updateMenuState } from '@/state/state';
import IconUndo from '@/icons/IconUndo';
import AppPopup from '@/components/app-popup/AppPopup';
import IconLifebuoy from '@/icons/IconLifebuoy';
import AppUpgradeButton from '@/components/appUpgradeButton/AppUpgradeButton';
import IconChat from '@/icons/IconChat.vue';
import { startWithPath } from '@/components/mainMenu.service';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import Support from '@/features/projects/Support.vue';
export default {
    name: 'main-menu',
    components: { Support, AppSeparator, IconChat, AppUpgradeButton, IconLifebuoy, AppPopup, IconUndo },
    props: {
        menuItems: { type: Array, default: () => [] },
    },
    data() {
        return { window };
    },
    methods: {
        startWithPath,
        navigate: function () {
            if (isMobile) {
                updateMenuState({ isOpen: false });
            }
        },
        navigateToGroup(item) {
            const route = item.items.length > 0 ? item.items[0].route : item.route;
            if (this.$route.name !== route) {
                this.$router.push({ name: route });
            }
            if (isMobile && item.items.length === 0) {
                updateMenuState({ isOpen: false });
            }
        },
    },
};
</script>

<style scoped>
nav {
    width: 100%;
    left: 0;
    transition-property: left, width;
    transition-duration: 0.25s;
}

@media (min-width: 640px) {
    nav {
        width: 220px;
    }
}
.app-menu {
    background-color: #fafafa;
}

.app-menu li.selected-group {
    background-color: #f0f0f0;
}

.app-menu li > a:hover,
.app-menu li li:hover {
    background-color: #e7e7e7;
}

.app-menu li.selected-group > div:hover {
    box-shadow: inset 4px 0 0 #00547a;
}

.app-menu li li.selected-item {
    box-shadow: inset 4px 0 0 #00547a;
    background-color: #e7e7e7;
    color: #00547a;
}
.app-menu li.selected-group,
.app-menu li.selected-group li {
    box-shadow: inset 4px 0 0 #00547a;
}
.app-menu .selected-group div {
    color: #00547a;
}
.aside-opened nav {
}
.aside-closed nav {
    left: -900px;
    opacity: 0;
}
</style>
