<template>
    <div
        class="flex w-full col-span-2"
        :class="{
            'border-b-2 border-secondary': variant === 'underline',
            'p-2 bg-red-500 text-white font-bold': variant === 'alert',
            'p-2 bg-secondary text-white font-bold': variant !== 'underline' && variant !== 'alert',
        }"
        @click="$emit('click', $event)"
    >
        <slot>
            <div class="flex w-full items-center">
                <icon class="mr-3" v-if="icon" :name="icon"></icon>
                <component v-bind:is="tag" v-if="label">{{ label }}</component>
            </div>
        </slot>
    </div>
</template>
<script>
import Icon from '../../icons/Icon';
export default {
    components: { Icon },
    props: {
        label: String,
        icon: String,
        variant: {
            type: String,
            default: 'default',
            enum: ['default', 'underline', 'alert'],
        },
        tag: {
            type: String,
            default: 'h2',
        },
    },
};
</script>
