import { v4 as uuidv4 } from 'uuid';

export const preRemove = function (doc) {
    const updatedBy = localStorage.getItem('userId');
    doc.updatedAt = new Date().toISOString();
    doc.updatedBy = updatedBy;
    doc.deletedAt = new Date().toISOString();
    doc.deletedBy = updatedBy;
};
export const preInsert = function (doc) {
    doc.id = doc.id || uuidv4();
    doc.createdBy = localStorage.getItem('userId');
    doc.createdAt = new Date().toISOString();
};
export const preSave = function (doc) {
    doc.updatedAt = new Date().toISOString();
    doc.updatedBy = localStorage.getItem('userId');
};
