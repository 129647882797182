/* eslint-disable no-undef */
import Vue from 'vue';
import App from './App.vue';
import appRouter from './appRouter';
import i18nService from './services/i18n.service';
import VueI18n from 'vue-i18n';
import './style.css';
import './icons/BaseIcons';
import './services/validation.service';
import dateFilter from './filters/dateFilter';
import * as ModalDialogs from 'vue-modal-dialogs';
import { reportError } from './features/tracker/tracker.service';
import chartist from 'vue-chartist';
import 'chartist/dist/chartist.min.css';
import VueCompositionAPI from '@vue/composition-api';
import { Icon } from 'leaflet';
import 'leaflet-editable';
import 'leaflet.path.drag';
import 'leaflet-polylinedecorator';
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import 'leaflet/dist/leaflet.css';
import { initGlobalDatabase } from './rxdb/database';
import './services/socket.service';
import { updateMenuState, upgradeStatus } from '@/state/state';
import { parseToken } from '@/features/tokens/token.service';
import rxJsMixin from '@/RxJsMixin';
import { Workbox } from 'workbox-window';
import { disconnect } from '@/services/socket.service';
import 'quillConfig';
import indexFilter from '@/filters/indexFilter';
import contactFilter from '@/filters/contactFitler';
import * as VueMenu from '@hscmap/vue-menu';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(VueMenu);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.use(VueCompositionAPI);
Vue.use(chartist);
Vue.use(ModalDialogs);
Vue.use(dateFilter);
Vue.use(indexFilter);
Vue.use(contactFilter);
Vue.use(VueI18n);
Vue.mixin(rxJsMixin);
Vue.config.productionTip = false;

GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const i18n = i18nService.init();

const urlSearchParams = new URLSearchParams(window.location.search);
const queryToken = Object.fromEntries(urlSearchParams.entries()).token;
const token = queryToken || localStorage.getItem('token');
if (token) {
    localStorage.setItem('token', token);
    const user = parseToken(token);
    localStorage.setItem('userId', user?.id);
    initGlobalDatabase();
}

const mediaQueryList = window.matchMedia('(orientation: portrait)');
const handler = (mql) => updateMenuState({ isPortrait: mql.matches });
handler(mediaQueryList);
mediaQueryList.addListener(handler);

new Vue({
    i18n,
    router: appRouter,
    render: (h) => h(App),
}).$mount('#app');

window.push = (route) => {
    return appRouter.push(route);
};
Vue.config.errorHandler = function (err, vm, info) {
    console.error(err, `Error: ${err.toString()}\nInfo: ${info}`);
    return reportError({ err, info, str: `Error: ${err.toString()}\nInfo: ${info}` });
};

if ('serviceWorker' in navigator && !window.location.search.includes('noToolBar')) {
    const wb = new Workbox('/sw.js');
    const showSkipWaitingPrompt = async () => {
        wb.addEventListener('controlling', async () => {
            await window.push({ name: 'home' });
            window.location.reload();
        });
        upgradeStatus.next(true);
        window.upgrade = function () {
            disconnect();
            wb.messageSkipWaiting();
            delete window.upgrade;
            upgradeStatus.next(false);
            window.push({ name: 'upgrading' });
        };
    };
    wb.addEventListener('waiting', (event) => {
        showSkipWaitingPrompt(event);
    });
    wb.register();
} else {
    console.log('no sw to register');
}
