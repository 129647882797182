import { sortBy } from '@/services/sanitize.service';
import { getXSPredecessor } from './plannedTasks.service';

export function getCriticalPaths(tasks, taskMapById) {
    const leaves = sortBy(getLeaves(tasks), 'estimatedEndDate').reverse();
    const branches = leaves.map((leaf) => getCriticalTreeToTask(leaf, taskMapById, {}));
    const sizedBranches = branches.map((branch) => {
        const firstDate = branch[0].estimatedEndDate;
        const lastDate = branch[branch.length - 1].estimatedEndDate;
        if (firstDate && lastDate) {
            branch.long = branch.reduce((acc, task) => acc + task.estimatedRealDuration, 0);
        }
        return branch;
    });
    if (sizedBranches.length > 0) {
        const largerBranchesDate = sortBy(branches, 'length').pop();
        return sizedBranches.filter((branch) => branch.length === largerBranchesDate.length);
    }
    return [];
}

/*
 * Get leaves : tasks which are not predecessor to any another.
 * EEPredecessor are not considered as links in the criticalPath scope because this imply retro-planning so the task will not cause late.
 */
export function getLeaves(tasks) {
    const predecessorIds = tasks.reduce((acc, task) => {
        if (task.allPredecessors) {
            for (const predecessor of getXSPredecessor(task.allPredecessors)) {
                acc.add(predecessor.taskId);
            }
        }
        return acc;
    }, new Set());
    return tasks.filter((task) => !predecessorIds.has(task.id));
}

export function getCriticalTreeToTask(task, taskMapById, cache = {}) {
    if (cache[task.id]) {
        return [];
    } else {
        cache[task.id] = true;
    }
    if (!task.allPredecessors || task.allPredecessors.length === 0) {
        return [task];
    } else {
        const predecessors = (task.allPredecessors || [])
            .map((predecessor) => taskMapById[predecessor.taskId])
            .filter((a) => !!a);
        const lastPredecessorDate = predecessors.reduce(
            (acc, predecessor) => {
                const date = predecessor.estimatedEndDate;
                return date > acc ? date : acc;
            },
            predecessors[0] ? predecessors[0].estimatedEndDate : null,
        );
        const lastPredecessors = predecessors.filter(
            (predecessor) =>
                predecessor.estimatedEndDate &&
                lastPredecessorDate &&
                predecessor.estimatedEndDate.getTime() === lastPredecessorDate.getTime(),
        );
        return [
            task,
            ...lastPredecessors.reduce((acc, predecessor) => {
                return [...acc, ...getCriticalTreeToTask(predecessor, taskMapById, cache)];
            }, []),
        ];
    }
}
