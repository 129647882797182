<template>
    <main class="h-full w-full m-auto flex flex-col items-center justify-center">
        <div class="m-5 mt-4 flex flex-col gap-4">
            <app-separator :label="$t('users.personalInformation')" />
            <ValidationObserver v-slot="{ invalid }" class="p-2 flex justify-center w-full">
                <form class="grid grid-cols-2 gap-4 max-w-lg">
                    <app-input-text
                        :label="$t('users.firstName')"
                        :required="true"
                        v-model="user.firstName"
                        class="col-span-2 md:col-span-1"
                    />

                    <app-input-text
                        :label="$t('users.lastName')"
                        :required="true"
                        v-model="user.lastName"
                        class="col-span-2 md:col-span-1"
                    />

                    <app-input-text
                        :label="$t('users.email')"
                        :required="true"
                        type="email"
                        v-model="user.email"
                        class="col-span-2"
                    />

                    <app-input-text
                        rules="numeric|length:10"
                        :label="$t('users.phone')"
                        v-model="user.phone"
                        class="col-span-2"
                    />

                    <app-input-text
                        :label="$t('user.password')"
                        rules="min:6"
                        type="password"
                        v-model="user.password"
                        class="col-span-2"
                        ref="password"
                    />

                    <app-footer @click.prevent="saveUser()" :disabled="invalid">
                        <template v-slot:left>
                            <a href="#" class="ml-1 text-sm underline" @click="logout">{{ $t('order.logout') }}</a>
                        </template>
                    </app-footer>
                </form>
            </ValidationObserver>
            <app-separator :label="$t('users.myContracts')" class="mt-6" />
            <div class="p-2">
                <div class="flex flex-col gap-2 items-center" v-if="contractLoaded && contracts.length === 0">
                    <span>{{ $t('users.noContract') }}</span>
                    <app-tips>{{ $t('users.noContractTip') }}</app-tips>
                    <span>{{ $t('users.noContractTip2') }}</span>
                    <div class="flex flex-col justify-center items-left">
                        <a
                            class="text-gray-700 hover:underline p-2 flex gap-2"
                            target="_blank"
                            :href="
                                'mailto:info@atex-info.eu?subject=Contrat%20Cadre&body=Bonjour,%0AJe%20souhaiterais%20en%20savoir%20plus%20sur%20les%20contrats%20cadre.%0AVous%20pouvez%20me%20contacter%20au%20' +
                                (user.phone || '06') +
                                '%0AMerci%0A' +
                                user.firstName +
                                ' ' +
                                user.lastName
                            "
                        >
                            <icon-email-outline></icon-email-outline>
                            info@atex-info.eu
                        </a>
                        <a class="text-gray-700 p-2 flex gap-2" href="tel://+33645296415">
                            <icon-phone></icon-phone>
                            06 45 29 64 15
                        </a>
                    </div>
                </div>
                <div
                    class="flex flex-col gap-6 items-center text-xs"
                    v-else-if="contractLoaded && contracts.length > 0"
                >
                    <table>
                        <tr>
                            <th class="border p-1">{{ $t('contracts.code') }}</th>
                            <th class="border p-1">{{ $t('contracts.billingName') }}</th>
                            <th class="border p-1">{{ $t('contracts.status') }}</th>
                            <th class="border p-1">{{ $t('contracts.sepaTemplate') }}</th>
                            <th class="border p-1">{{ $t('contracts.PDFContract') }}</th>
                        </tr>
                        <tr v-for="contract in contracts" :key="contract.id">
                            <td class="border p-1">
                                {{ contract.code }}
                            </td>
                            <td class="border p-1">
                                {{ contract.billingName }}
                                <span v-if="contract.billingCity">({{ contract.billingCity }})</span>
                            </td>
                            <td
                                class="border p-1"
                                :class="{
                                    'text-red-600': contract.expireAt && contract.expireAt.getTime() < Date.now(),
                                }"
                            >
                                <span v-if="contract.expireAt && contract.expireAt.getTime() < Date.now()">
                                    {{ $t('contracts.expired') }}
                                </span>
                                <span v-else-if="contract.validatedAt">{{ $t('contracts.active') }}</span>
                                <span v-else>{{ $t('contracts.waiting') }}</span>
                            </td>
                            <td class="border p-1">
                                <app-file-link
                                    icon="icon-file-pdf-outline"
                                    :url="contract.sepaURL"
                                    file-name="SEPA.pdf"
                                    class="text-sm underline"
                                    v-if="contract.sepaURL"
                                />
                            </td>
                            <td class="border p-1">
                                <app-file-link
                                    v-if="contract.templateUrl"
                                    icon="icon-file-pdf-outline"
                                    :url="contract.signedUrl || contract.templateUrl"
                                    file-name="Contrat.pdf"
                                    class="text-sm underline"
                                />
                            </td>
                        </tr>
                    </table>
                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-col gap-2 items-left">
                            <a
                                class="text-gray-700 hover:underline flex gap-2"
                                target="_blank"
                                :href="
                                    'mailto:info@atex-info.eu?subject=Contrat%20Cadre&body=Bonjour,%0AJe%20souhaiterais%20en%20savoir%20plus%20sur%20les%20contrats%20cadre.%0AVous%20pouvez%20me%20contacter%20au%20' +
                                    (user.phone || '06') +
                                    '%0AMerci%0A' +
                                    user.firstName +
                                    ' ' +
                                    user.lastName
                                "
                            >
                                <icon-email-outline></icon-email-outline>
                                info@atex-info.eu
                            </a>
                            <a class="text-gray-700 flex gap-2 items-center" href="tel://+33645296415">
                                <icon-phone></icon-phone>
                                06 45 29 64 15
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { getLoggedUser } from '../tokens/token.service';
import AppErrors from '../../components/appErrors/AppErrors';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFooter from '../../components/appFooter/AppFooter';
import { getUser, updateUser } from './users.service';
import { info } from '@/features/toasts/toats.service';
import database from '@/rxdb/database';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
import { getContracts } from '@/features/contracts/contracts.service';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';

export default {
    components: { AppFileLink, AppTips, AppSeparator, AppFooter, AppInputText, AppErrors },
    async created() {
        getUser(this.$route.query.userId || getLoggedUser().id).then((user) => {
            this.user = user;
            if (this.$route.query.changePassword) {
                setTimeout(() => this.$refs.password.focus(), 1000);
            }
        });
        getContracts()
            .then((contracts) => {
                this.contracts = contracts.map((contract) => ({
                    ...contract,
                    sepaURL: this.getSEPAURL(contract.sepas),
                }));
                this.contractLoaded = true;
            })
            .catch(() => {
                this.contracts = [];
                this.contractLoaded = true;
            });
    },
    methods: {
        getSEPAURL: function (sepas) {
            if (!sepas || sepas.length === 0) {
                return '';
            } else {
                const reversed = sepas.toReversed();
                const lastSigned = reversed.find((sepa) => sepa.signedUrl);
                const lastTemplate = reversed.find((sepa) => sepa.templateUrl);
                return lastSigned ? lastSigned.signedUrl : lastTemplate ? lastTemplate.templateUrl : '';
            }
        },
        saveUser: async function () {
            await updateUser({ ...this.user, id: this.$route.query.userId || getLoggedUser().id });
            info(this.$t('commons.saveSucceed'));
        },
        logout: function () {
            localStorage.removeItem('token');
            database.logout();
            this.$router.push({ name: 'login' });
        },
    },
    data: () => {
        return {
            contractLoaded: false,
            contracts: true,
            user: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            },
        };
    },
};
</script>
