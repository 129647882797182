<template>
    <main class="max-w-5xl m-5 h-full w-full pb-10">
        <template>
            <AppLegend />
            <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
                <form>
                    <ReadOnlyBundle v-if="!isAdmin"></ReadOnlyBundle>
                    <template v-else>
                        <div class="flex justify-end">
                            <app-button
                                @click="remove()"
                                variant="danger"
                                size="mini"
                                :label="$t('bundles.remove')"
                                icon="icon-trash-can-outline"
                                :disabled="bundle.hasLicense"
                                :title="bundle.hasLicense ? $t('bundles.bundleWithLicense') : ''"
                            />
                        </div>
                        <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                            <app-input-text
                                :label="isCompanyBundle ? $t('commons.index') : $t('bundles.bundleReference')"
                                v-model="bundle.reference"
                                class="col-span-2"
                                style="width: 10rem"
                                :required="true"
                                @blur="save()"
                            >
                                <template v-slot:companion v-if="!isCompanyBundle">
                                    <div class="flex items-center mx-2" :title="$t('bundles.bundleReferenceTip')">
                                        <icon-information-outline class="text-gray-500" width="24" height="24" />
                                    </div>
                                </template>
                            </app-input-text>

                            <app-input-text
                                :label="isCompanyBundle ? $t('commons.name') : $t('bundles.missionName')"
                                v-model="bundle.name"
                                class="col-span-2"
                                :required="true"
                                @blur="save()"
                            />
                            <div class="col-span-2 flex">
                                <app-date-input
                                    :label="$t('bundles.marketDate')"
                                    v-model="bundle.marketDate"
                                    v-if="isCompanyBundle"
                                    @input="save()"
                                    size="20"
                                />
                            </div>
                            <div class="col-span-2 flex">
                                <app-date-input
                                    :label="$t('bundles.serviceOrderDate')"
                                    v-model="bundle.serviceOrderDate"
                                    class="col-span-2"
                                    v-if="isCompanyBundle"
                                    @input="save()"
                                    size="20"
                                />
                            </div>
                            <div class="col-span-2">
                                <app-duration-input
                                    size="4"
                                    :pattern="$t('integerPattern')"
                                    :label="$t('bundles.visaDelay')"
                                    v-model="bundle.visaDelay"
                                    placeholder="5"
                                    @blur="save()"
                                ></app-duration-input>
                            </div>
                            <div v-if="isMarketAmountAllowed">
                                <app-number-input
                                    :label="$t('bundles.marketAmount') + ' (€)'"
                                    v-model="bundle.marketAmount"
                                    class="col-span-2"
                                    v-if="isCompanyBundle"
                                    @blur="save()"
                                    size="4"
                                ></app-number-input>
                            </div>
                            <app-checkbox
                                :label="$t('bundles.hasAtexAccess')"
                                v-model="bundle.hasAtexAccess"
                                class="col-span-2"
                                @input="save()"
                            ></app-checkbox>
                        </app-fieldset>

                        <app-fieldset class="col-span-2 mt-4" icon="icon-account-group" :label="$t('users.users')">
                            <ValidationProvider v-slot="{ errors, classes }" class="col-span-2">
                                <app-picker
                                    :value="bundle.company"
                                    :options="companyOptions"
                                    :show-label="true"
                                    :label="$t('companies.company')"
                                    :title="$t('companies.chooseCompany')"
                                    @input="onCompanySelected($event)"
                                    :allow-custom-option="true"
                                    :customOptionPrefix="$t('companies.creationPrefix') + ' : '"
                                    :disabled="!isAdmin"
                                >
                                    <template v-slot:option="{ option }">
                                        <div class="flex items-baseline p-2">
                                            <span>{{ option.name }}</span>
                                            <span class="text-xs text-gray-700 italic ml-2">{{ option.city }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:tip>
                                        <div
                                            v-if="bundle.company"
                                            class="text-xs text-gray-700 italic ml-2 mt-1 flex items-center"
                                        >
                                            {{ bundle.company.postalCode }}
                                            {{ bundle.company.city }}
                                            <span v-if="bundle.company.phone">
                                                <span class="ml-1">-</span>
                                                <app-phone class="ml-1" :number="bundle.company.phone"></app-phone>
                                            </span>
                                            <span v-if="bundle.company.email" class="ml-0">
                                                <span class="ml-1">-</span>
                                                <app-mail class="ml-1" :email="bundle.company.email"></app-mail>
                                            </span>
                                            <span class="ml-1" v-if="isAdmin || isMember">-</span>
                                            <router-link
                                                :to="{
                                                    name: 'bundleCompany',
                                                    params: {
                                                        bundleId: bundle.id,
                                                        companyId: bundle.company.id,
                                                        projectId: $route.params.projectId,
                                                    },
                                                }"
                                                class="cursor-pointer underline text-xs text-gray-700 italic flex ml-1 items-center"
                                                v-if="bundle.company"
                                            >
                                                <app-button
                                                    v-if="isAdmin || isMember"
                                                    class="ml-2"
                                                    size="mini"
                                                    icon="icon-pencil"
                                                    aria-label="edit"
                                                    @click="
                                                        $router.push({
                                                            name: 'bundleCompany',
                                                            params: {
                                                                bundleId: bundle.id,
                                                                companyId: bundle.company.id,
                                                                projectId: $route.params.projectId,
                                                            },
                                                        })
                                                    "
                                                ></app-button>
                                            </router-link>
                                        </div>
                                    </template>
                                </app-picker>

                                <app-errors :errors="errors" />
                            </ValidationProvider>
                            <bundle-contacts
                                :contacts="bundle.contacts"
                                :bundle="bundle"
                                :project="project"
                                :company="bundle.company"
                                :show-responsible="true"
                                :responsible-id="bundle.responsibleId"
                                @save="saveContact($event)"
                                @responsible="changeResponsible($event)"
                                @remove="removeContact($event)"
                                ref="contacts"
                                :read-only="!isAdmin && !isMember"
                            ></bundle-contacts>
                            <div class="mt-4" v-if="isAdmin || isMember">
                                <app-drop-down-button
                                    :label="$t('users.newUser')"
                                    @input="addContact"
                                    :disabled="!bundle.company"
                                    :show-label="true"
                                    :placeholder="$t('users.pickUser')"
                                    :value="selectedContact"
                                >
                                    <template v-for="contact in contactOptions">
                                        <option :value="contact.id">
                                            {{ contact.firstName }} {{ contact.lastName }}
                                            {{ contact.role ? ' - ' + contact.role : '' }}
                                        </option>
                                    </template>
                                    <option :value="'new'">
                                        {{ $t('companies.createNewUser') }}
                                    </option>
                                </app-drop-down-button>
                            </div>
                        </app-fieldset>
                        <app-save-on-leave :dirty="dirty" :saveFn="save"></app-save-on-leave>
                    </template>
                </form>
            </ValidationObserver>
            <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        </template>
        <bundle-cascade-popup
            :impacts="impacts"
            ref="confirmPopup"
            @deleteConfirmed="onDeleteConfirmed"
        ></bundle-cascade-popup>
    </main>
</template>

<script>
import AppErrors from '../../components/appErrors/AppErrors';
import { updateBreadCrumbs } from '@/state/state';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFooter from '../../components/appFooter/AppFooter';
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppLegend from '../../components/appLegend/AppLegend';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import ReadOnlyBundle from './ReadOnlyBundle';
import { createBundle, getBundleOffline, getBundles, initNewBundle, updateBundle } from './bundles.service';
import AppBackButton from '../../components/appBackButton/AppBackButton';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppSelect from '@/components/appSelect/AppSelect';
import AppPhone from '@/components/appPhone/AppPhone';
import AppButton from '@/components/appButton/AppButton';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import {
    copyCompanyAndContacts,
    createCompany,
    getCompanies,
    getKnownCompanies,
} from '@/features/companies/companies.service';
import { sortBy } from '@/services/sanitize.service';
import AppMail from '@/components/appMail/AppMail';
import AppDurationInput from '@/components/appDurationInput/AppDurationInput';
import AppTips from '@/components/app-tips/AppTips';
import IconInformationOutline from '@/icons/IconInformationOutline';
import AppTipsIcon from '@/components/appTipsIcon/AppTipsIcon';
import AppLabel from '@/components/appLabel/AppLabel';
import BundleContacts from '@/features/bundles/BundleContacts';
import { queryProject } from '@/features/projects/projects.service';
import { combineLatest, from } from 'rxjs';
import { createContact, getContacts, updateContact } from '@/features/contacts/contacts.service';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import { getCascadeImpacts, removeCascade } from '@/features/bundles/bundleCascades.service';
import BundleCascadePopup from '@/features/bundles/BundleCascadePopup.vue';
export default {
    name: 'bundle',
    components: {
        BundleCascadePopup,
        AppCheckbox,
        AppNumberInput,
        BundleContacts,
        AppLabel,
        AppTipsIcon,
        IconInformationOutline,
        AppTips,
        ReadOnlyBundle,
        AppDurationInput,
        AppMail,
        AppDropDownButton,
        AppButton,
        AppPhone,
        AppSelect,
        AppSaveOnLeave,
        AppBackButton,
        AppQuickActions,
        AppPicker,
        AppDateInput,
        AppFieldset,
        AppSeparator,
        AppFooter,
        AppInputText,
        AppErrors,
        AppLegend,
    },
    created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.project = project;
            this.isAdmin = project.me.allowedFeatures.includes('project_bundles');
            this.isMember = project.me.bundleIds.includes(this.$route.params.bundleId);
            this.isMarketAmountAllowed = project.me.allowedFeatures.includes('project_bundles_markerAmount');
        });
        this.init();
    },
    data() {
        return {
            selectedContact: null,
            impacts: null,
            isAdmin: false,
            isMember: false,
            isMarketAmountAllowed: false,
            projectCompanies: [],
            bundles: [],
            project: null,
            subscriptions: [],
            isCompanyBundle: false,
            bundle: { services: [], company: null, contacts: [], visaDelay: 5 },
            quickActions: [
                {
                    name: this.$t('bundles.newLabel.company'),
                    run: () => {
                        this.addBundle('company');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.MOA'),
                    run: () => {
                        this.addBundle('MOA');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.AMOA'),
                    run: () => {
                        this.addBundle('AMOA');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.MOE'),
                    run: () => {
                        this.addBundle('MOE');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.SPS'),
                    run: () => {
                        this.addBundle('SPS');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.BE'),
                    run: () => {
                        this.addBundle('BE');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.distributor'),
                    run: () => {
                        this.addBundle('distributor');
                    },
                },
            ],
            companyProposal: [],
        };
    },
    computed: {
        companyOptions() {
            return [
                { isGroup: true, name: this.$t('bundles.bundleFromProject'), children: this.projectCompanies },
                {
                    isGroup: true,
                    name: this.$t('bundles.importBundle'),
                    children: sortBy(this.companyProposal, 'name'),
                },
            ];
        },
        contactOptions() {
            if (!this.bundle.company) {
                return [];
            } else {
                return this.bundle.company.contacts.filter(
                    (contact) => !this.bundle.contacts.find(({ id }) => id === contact.id),
                );
            }
        },
    },
    watch: {
        $route() {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getBundles(this.$route.params.projectId).subscribe((bundles) => {
                    this.bundles = bundles;
                }),
                combineLatest([
                    getBundleOffline(this.$route.params.projectId, this.$route.params.bundleId),
                    getContacts(this.$route.params.projectId),
                    getCompanies(this.$route.params.projectId),
                    from(getKnownCompanies('')),
                ]).subscribe(([bundle, contacts, companies, knownCompanies]) => {
                    if (!bundle) {
                        return this.$router.push({
                            name: 'bundles',
                            params: { projectId: this.$route.params.projectId },
                        });
                    }
                    const company = companies.find((company) => company.id === bundle.companyId);
                    const aBundle = {
                        ...bundle,
                        contacts: sortBy(
                            contacts.filter((contact) => bundle.contactIds.includes(contact.id)),
                            (contact) => (contact.id === bundle.responsibleId ? 'a_' : 'z_') + contact.lastName,
                        ),
                    };
                    if (bundle.responsibleId) {
                        aBundle.responsible = contacts.find((contact) => contact.id === bundle.responsibleId);
                    }
                    if (company) {
                        aBundle.company = {
                            ...company,
                            contacts: contacts.filter((contact) => contact.companyId === company.id),
                        };
                    }
                    this.isCompanyBundle = bundle.category === 'company';
                    updateBreadCrumbs({ bundleName: bundle.name });
                    this.companyProposal = knownCompanies.filter(
                        (company) =>
                            !companies.find(
                                (aCompany) =>
                                    (aCompany.name && aCompany.name === company.name) ||
                                    (aCompany.siret === company.siret && aCompany.siret),
                            ),
                    );
                    this.projectCompanies = companies
                        .filter((company) => company.projectId === this.$route.params.projectId)
                        .map((company) => ({
                            ...company,
                            contacts: contacts.filter((contact) => company.id === contact.companyId),
                        }));
                    this.bundle = aBundle;
                }),
            ];
        },
        async onDeleteConfirmed() {
            if (this.isAdmin) {
                await removeCascade(this.$route.params.projectId, this.impacts);
            }
        },
        async addBundle(type) {
            const result = await createBundle(
                this.$route.params.projectId,
                initNewBundle(type, this.bundles, (key) => this.$t(key)),
            );
            if (result) {
                await this.$router.push({
                    name: 'bundle',
                    params: { projectId: this.$route.params.projectId, bundleId: result.id },
                });
            }
        },
        async addContact(contactId) {
            if (contactId === '') return;
            if (contactId !== 'new') {
                const contactIds = [...this.bundle.contactIds, contactId];
                await updateBundle(this.$route.params.projectId, {
                    id: this.bundle.id,
                    contactIds,
                    responsibleId: this.bundle.responsibleId
                        ? this.bundle.responsibleId
                        : contactIds.length
                        ? contactIds[0]
                        : null,
                });
            } else {
                this.$refs.contacts.editContact({});
            }
            this.$nextTick(() => (this.selectedContact = ''));
            this.$refs.observer.reset();
        },
        async saveContact(contact) {
            if (!contact.id) {
                const result = await createContact(this.$route.params.projectId, {
                    ...contact,
                    companyId: this.bundle.company.id,
                });
                await this.addContact(result.id);
            } else {
                await updateContact(this.$route.params.projectId, contact);
                if (!this.bundle.contacts.find((contact) => contact.id === contact.id)) {
                    await this.addContact(contact.id);
                }
            }
        },
        async removeContact(contact) {
            const contactIds = this.bundle.contactIds.filter((id) => id !== contact.id);
            await updateBundle(this.$route.params.projectId, {
                id: this.bundle.id,
                contactIds,
                responsibleId:
                    this.bundle.responsibleId !== contact.id
                        ? this.bundle.responsibleId
                        : contactIds.length > 0
                        ? contactIds[0]
                        : null,
            });
        },
        async changeResponsible(contact) {
            if (this.bundle.responsibleId !== contact.id) {
                await updateBundle(this.$route.params.projectId, {
                    id: this.bundle.id,
                    responsibleId: contact.id,
                });
                this.$refs.observer.reset();
            }
        },
        async onCompanySelected(company) {
            let newCompany = company;
            if (company && company._isStringCriteria) {
                newCompany = await createCompany(this.$route.params.projectId, { name: company.content });
            } else if (company && !this.projectCompanies.find((aCompany) => aCompany.id === company.id)) {
                newCompany = await copyCompanyAndContacts(this.$route.params.projectId, company);
            }
            await updateBundle(this.$route.params.projectId, {
                id: this.bundle.id,
                companyId: newCompany ? newCompany.id : null,
                contactIds: newCompany?.contacts?.length > 0 ? [newCompany.contacts[0].id] : [],
                responsibleId: newCompany?.contacts?.length > 0 ? newCompany.contacts[0].id : null,
            });
            this.$refs.observer.reset();
        },
        save() {
            return updateBundle(this.$route.params.projectId, {
                id: this.bundle.id,
                reference: this.bundle.reference,
                name: this.bundle.name,
                visaDelay: this.bundle.visaDelay,
                marketDate: this.bundle.marketDate,
                serviceOrderDate: this.bundle.serviceOrderDate,
                marketAmount: this.bundle.marketAmount,
                hasAtexAccess: this.bundle.hasAtexAccess,
            });
        },

        async remove() {
            if (this.isAdmin) {
                this.impacts = null;
                this.$refs.confirmPopup.open();
                this.impacts = await getCascadeImpacts(this.$route.params.projectId, this.bundle);
            }
        },
    },
};
</script>
