<template>
    <div class="flex flex-col gap-4 p-2">
        <div class="flex gap-4 p-4 items-baseline">
            <div class="flex flex-col gap-2 align-top">
                <span
                    v-if="!observation.footprint"
                    class="p-1 px-2 text-xs mr-2"
                    :class="{
                        [getObservationClass(observation)]: true,
                        border:
                            observation.type === 'other' ||
                            observation.type === 'general' ||
                            observation.type === 'administrative',
                    }"
                >
                    #{{ (observation.index + 1).toString().padStart(3, '0') }}
                </span>
                <app-static-marker
                    :observation="observation"
                    v-if="observation.footprint"
                    class="text-xs mr-2"
                ></app-static-marker>
            </div>
            <div class="pt-2">
                <p v-html="observation.title" class="whitespace-pre-wrap html-breakline"></p>
            </div>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr v-if="observation.recipients">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('observations.recipient') }} :</th>
                    <td class="p-2 flex flex-col">
                        <template v-for="bundle in observation.recipients">
                            <app-bundle :bundle="bundle" :key="bundle.id" />
                        </template>
                    </td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('commons.phase') }} :</th>
                    <td class="p-2">{{ $t('commons.phases.' + observation.phase) }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('observations.type') }} :</th>
                    <td class="p-2">
                        <span v-if="observation.type">{{ $t('observations.types.' + observation.type) }}</span>
                    </td>
                </tr>
                <tr v-if="observation.zone && !observation.room">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('observations.location') }} :</th>
                    <td class="p-2">{{ observation.zone.fullName }}</td>
                </tr>
                <tr v-if="observation.zone && observation.room">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('observations.location') }} :</th>
                    <td class="p-2">{{ observation.room.fullName }}</td>
                </tr>
                <tr v-if="observation.dueDate">
                    <th role="rowheader" class="p-2 text-right align-top">
                        {{ $t('project.follow.observation.dueDate') }} :
                    </th>
                    <td class="p-2">{{ observation.dueDate | humanizeDate }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="pt-2 text-right align-top">
                        {{ $t('project.follow.observation.status') }} :
                    </th>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <observation-status :observation="observation" @input="saveStatus($event)"></observation-status>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-span-2 flex">
            <app-multiple-upload-button
                ref="upload"
                icon="icon-paperclip"
                size="mini"
                :label="$t('observations.newAttachment')"
                :end-point="`/api/projects/${$route.params.projectId}/observations/${observation.id}/attachment`"
                :resize="1024"
                :name="$t('project.follow.weatherIssue.newAttachment')"
                class="text-sm offline:hidden"
                v-if="isAdmin || !observation.validatedAt"
                @loaded="onNewAttachment"
            />
            <app-offline-upload-button
                ref="fileInput"
                icon="icon-paperclip"
                size="mini"
                v-if="isAdmin || !observation.validatedAt"
                :label="$t('observations.newAttachment')"
                class="text-sm hidden offline:block"
                @select="onNewOfflineAttachment"
            />
        </div>
        <div class="col-span-2 flex gap-2 flex-wrap">
            <template v-for="attachment in observation.attachments">
                <app-file-link
                    :fileName="attachment.name"
                    :url="attachment.url"
                    v-if="attachment.url && attachment.url.endsWith('pdf')"
                    :to="{
                        name: 'fileViewer',
                        params: {
                            projectId: $route.params.projectId,
                        },
                        query: {
                            url: attachment.url,
                            backName: $t('observations.title'),
                        },
                    }"
                    :removable="isAdmin || (attachment.createdBy === me.id && !observation.validatedAt)"
                    @delete="onDeleteAttachment(attachment)"
                ></app-file-link>
                <app-photo
                    v-else
                    @click.native="openPhotoPopup(attachment)"
                    :url="attachment.url || attachment.dataUrl"
                    :removable="isAdmin || (attachment.createdBy === me.id && !observation.validatedAt)"
                    class="m-2 cursor-pointer"
                    @delete="onDeleteAttachment(attachment)"
                ></app-photo>
            </template>
        </div>
        <div class="col-span-2 flex gap-2 flex-wrap" v-if="observation.footprint">
            <app-leaflet-viewer
                class="col-span-2 mx-5 min-h-main"
                v-if="observation.support"
                :src="observation.support.url"
                :crop-box="observation.support.cropBox"
                :page="1"
                :markers="markers"
                :rotation="observation.support.rotation"
            ></app-leaflet-viewer>
        </div>
        <app-popup ref="popup" :show-header="true">
            <app-photo-editor
                v-if="selectedAttachment"
                :attachment="selectedAttachment"
                :read-only="!(isAdmin || (selectedAttachment.createdBy === me.id && !observation.validatedAt))"
            ></app-photo-editor>
        </app-popup>
    </div>
</template>

<script>
import ZoneObservationForm from '@/features/tours/ZoneObservationForm';
import { updateBreadCrumbs } from '@/state/state';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker';
import AppBundle from '@/components/app-bundle/appBundle';
import {
    getObservation,
    getObservationClass,
    sanitizeObservation,
    updateObservation,
} from '@/features/observations/observation.service';
import AppPhoto from '@/components/appPhoto/AppPhoto';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppButton from '@/components/appButton/AppButton';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import ObservationStatus from '@/features/observations/ObservationStatus.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import {
    createObservationAttachment,
    getObservationAttachmentsByObservationId,
    removeObservationAttachment,
} from '@/features/observations/observationAttachments.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppWarnOnLeave from '@/components/AppWarnOnLeave.vue';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor.vue';
import { getSupports } from '@/features/supports/supports.service';
import { getMapById, omit } from '@/services/sanitize.service';
import AppOfflineUploadButton from '@/components/app-offlineUploadButton/AppOfflineUploadButton.vue';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton.vue';

export default {
    components: {
        AppMultipleUploadButton,
        AppOfflineUploadButton,
        AppPhotoEditor,
        AppWarnOnLeave,
        AppPopup,
        AppFileLink,
        ObservationStatus,
        AppButton,
        AppDateInput,
        AppLeafletViewer,
        AppPhoto,
        AppBundle,
        AppStaticMarker,
        ZoneObservationForm,
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getObservation(this.$route.params.projectId, this.$route.params.observationId),
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                    getObservationAttachmentsByObservationId(
                        this.$route.params.projectId,
                        this.$route.params.observationId,
                    ),
                    getSupports(this.$route.params.projectId),
                ]).subscribe(([observation, bundleMap, folders, project, attachments, supports]) => {
                    const supportMap = getMapById(supports);
                    this.me = project.me;
                    this.isAdmin = project.me.allowedFeatures.includes('project_observations');
                    this.isRecipient = project.me.bundleIds.find(
                        (bundleId) =>
                            observation.recipientIds &&
                            !!observation.recipientIds.find((recipientId) => recipientId === bundleId),
                    );
                    const locations = locationService.buildLocationOptions(folders);
                    this.observation = {
                        ...observation,
                        recipients: observation.recipientIds.map((recipientId) => bundleMap[recipientId]),
                        zone: observation.zoneId
                            ? locations.find((location) => location.id === observation.zoneId)
                            : null,
                        room: observation.roomId
                            ? locations.find((location) => location.id === observation.roomId)
                            : null,
                        validator: bundleMap[observation.validatedBy],
                        reporter: bundleMap[observation.reportedBy],
                        obsoleter: bundleMap[observation.obsoleteBy],
                        resolver: bundleMap[observation.resolvedBy],
                        attachments,
                        support: supportMap[observation.supportId],
                    };
                    if (this.selectedAttachment) {
                        this.selectedAttachment = this.observation.attachments.find(
                            (attachment) => attachment.url === this.selectedAttachment.url,
                        );
                    }
                    updateBreadCrumbs({
                        observationName: '#' + (this.observation.index + 1) + ' ' + this.observation.title,
                        zoneName: this.observation.zone?.name || '',
                        locationName: this.observation.zone?.parent?.name || '',
                        folderName: this.observation.zone?.parent?.parent?.name || '',
                        phase: this.$route.params.phase,
                    });
                }),
            ];
        },
        getObservationClass,
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return removeObservationAttachment(this.$route.params.projectId, attachment.id);
            }
        },
        openPhotoPopup(attachment) {
            this.selectedAttachment = attachment;
            this.$refs.popup.open();
        },
        cleanEntity(observation) {
            return {
                ...omit(observation, ['attachments', 'validator', 'reporter', 'obsoleter', 'resolver', 'recipients']),
            };
        },
        onNewAttachment(attachment) {
            createObservationAttachment(this.$route.params.projectId, attachment);
        },
        onNewOfflineAttachment({ dataUrl }) {
            createObservationAttachment(this.$route.params.projectId, { dataUrl, observationId: this.observation.id });
        },
        async saveStatus(observation) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                ...sanitizeObservation(this.cleanEntity(observation)),
            });
        },
    },
    computed: {
        markers() {
            return this.observation.footprint
                ? [
                      {
                          ...this.observation.footprint,
                          title: this.observation.title,
                          label: this.observation.index + 1,
                          classes: getObservationClass(this.observation),
                          draggable: true,
                      },
                  ]
                : [];
        },
    },
    created() {
        this.init();
    },
    data() {
        return {
            observation: { recipients: [], recipientIds: [] },
            isRecipient: false,
            isAdmin: false,
            me: {},
            selectedAttachment: null,
        };
    },
};
</script>
