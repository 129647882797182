<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4 shadow-md p-4 border">
            <div class="flex flex-col gap-2">
                <span class="text-xl font-bold">{{ company.name }}</span>
                <span class="text-sm text-gray-500">{{ company.siret }}</span>
            </div>
            <div class="flex flex-col gap-2">
                <div v-if="company.address1">
                    <p>{{ company.address1 }}</p>
                    <p v-if="company.address2">{{ company.address2 }}</p>
                    <p v-if="company.address3">{{ company.address3 }}</p>
                    <p>{{ company.postalCode }} {{ company.city }}</p>
                </div>
                <app-phone v-if="company.phone" :number="company.phone"></app-phone>
                <app-mail v-if="company.email" :email="company.email"></app-mail>
            </div>
        </div>

        <table class="w-full mb-10">
            <thead>
                <tr class="border-b">
                    <th scope="col" class="text-left py-2 px-4">
                        {{ $t('users.lastName') }}
                    </th>
                    <th scope="col" class="text-left py-2 px-4">
                        {{ $t('users.firstName') }}
                    </th>
                    <th scope="col" class="text-left py-2 px-4">{{ $t('users.role') }}</th>
                    <th scope="col" class="text-left py-2 px-4">
                        {{ $t('users.phone') }}
                    </th>
                    <th scope="col" class="text-left py-2 px-4">{{ $t('users.email') }}</th>
                    <td></td>
                </tr>
            </thead>
            <tr v-if="company.contacts.length === 0">
                <td colspan="5" class="p-2 px-4 text-sm italic text-center">
                    {{ $t('users.noUser') }}
                </td>
            </tr>
            <template v-for="user in company.contacts" :v-key="user.id">
                <tr class="border-b hover:bg-gray-200">
                    <td class="p-2 px-4">{{ user.lastName }}</td>
                    <td class="p-2 px-4">{{ user.firstName }}</td>
                    <td class="p-2 px-4">{{ user.role }}</td>
                    <td class="p-2 px-4"><app-phone :number="user.phone" /></td>
                    <td class="p-2 px-4">{{ user.email }}</td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import AppPhone from '../../components/appPhone/AppPhone';
import { getCompany } from './companies.service';
import { updateBreadCrumbs } from '../../state/state';
import AppMail from '@/components/appMail/AppMail';
import { combineLatest } from 'rxjs';
import { getContacts } from '@/features/contacts/contacts.service';
import { sortBy } from '@/services/sanitize.service';
export default {
    components: {
        AppMail,
        AppPhone,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getCompany(this.$route.params.projectId, this.$route.params.companyId),
                getContacts(this.$route.params.projectId),
            ]).subscribe(([company, contacts]) => {
                this.company = {
                    ...company,
                    contacts: sortBy(
                        contacts.filter((contact) => contact.companyId === this.$route.params.companyId),
                        (contact) => contact.lastName,
                    ),
                };
                return updateBreadCrumbs({
                    companyName: this.company.name,
                });
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            company: { contacts: [], siret: '' },
        };
    },
};
</script>
