<template>
    <div style="display: none"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { findRealParent } from 'vue2-leaflet';
export default {
    props: {
        latLngs: {
            type: Array,
            default: () => [],
        },
        pixelSize: { type: String | Number, default: 10 },
        weight: { type: Number, default: 3 },
        color: { type: String, default: '#ff0000' },
    },
    data() {
        return {
            polygon: null,
            polygonHandle: null,
            map: null,
        };
    },
    watch: {
        latLngs() {
            this.polygon.setLatLngs(this.latLngs);
            this.polygonHandle.setLatLngs(this.latLngs);

            if (this.polygonHandle.editEnabled()) {
                this.polygonHandle.disableEdit();
                this.$nextTick(() => this.polygonHandle.enableEdit());
            }
        },
        color(color) {
            this.polygon.setStyle({ color });
        },
    },
    mounted() {
        this.polygon = L.polygon(this.latLngs, {
            stroke: true,
            color: this.color,
            weight: this.weight,
            fillOpacity: 0,
            fill: false,
        });
        this.polygonHandle = L.polygon(this.latLngs, {
            stroke: true,
            weight: 15,
            opacity: 0,
            fillOpacity: 0,
            fill: false,
        });
        L.DomEvent.on(this.polygonHandle, this.$listeners);

        this.polygonHandle.on('editable:drag editable:dragend editable:editing', () => {
            this.polygon.setLatLngs(this.polygonHandle.getLatLngs());
        });
        this.polygonHandle.on('editable:dragend editable:vertex:dragend', () => {
            const latLngs = this.polygonHandle.getLatLngs();
            this.$emit(
                'updateLatLngs',
                latLngs.map((innerPath) => innerPath.map((latLng) => [latLng.lat, latLng.lng])),
            );
        });

        this.map = findRealParent(this.$parent).mapObject;
        this.map.addLayer(this.polygon);
        this.map.addLayer(this.polygonHandle);
    },
    beforeDestroy() {
        this.map.removeLayer(this.polygon);
        this.map.removeLayer(this.polygonHandle);
    },
    methods: {
        getPatterns() {
            return [];
        },
        editEnabled() {
            return this.polygonHandle.editEnabled();
        },
        disableEdit() {
            return this.polygonHandle.disableEdit();
        },
        enableEdit() {
            return this.polygonHandle.enableEdit();
        },
        getLatLngs() {
            return this.polygonHandle.getLatLngs();
        },
        select() {
            this.$emit('click', { target: this.polygonHandle });
        },
    },
};
</script>
