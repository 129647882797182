<template>
    <div class="truncate align-bottom">
        <div class="text-center" :class="{ hidden: icon }">{{ title }}</div>
        <div class="text-center" :title="title">
            <icon v-if="icon" :name="icon" :width="iconSize" :height="iconSize" class="m-auto"></icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderCell',
    props: {
        icon: String,
        title: String,
        iconSize: { type: String, default: '12px' },
    },
};
</script>
