import tasksService from '../../tasks/plannedTasks.service';
import tree, { linear } from '../../../features/planning/planning/tree';
import groupsService from '../../../features/planning/planning/groups.service';
import locationService from '../../../services/location.service';
import { filterMatch, sortBy } from '@/services/sanitize.service';
import { sortTasks } from '@/features/tasks/tasks.service';

export function populateTree(tasks, lastReference, bundles, folders, directories, structure, agenda, refDate, sortKey) {
    console.time('populateTree');
    tasksService.addReferenceDates(tasks, lastReference, agenda, refDate);

    if (structure === 'BFL') {
        tasks = tree.populateBFL(bundles, folders, directories, tasks);
    } else if (structure === 'FLB') {
        tasks = tree.populateFLB(bundles, folders, directories, tasks);
    } else if (structure === 'BESL') {
        tasks = tree.populateBESL(bundles, folders, directories, tasks);
    } else {
        tasks = tree.populateFBL(bundles, folders, directories, tasks);
    }
    groupsService.computeDates(tasks, agenda);
    groupsService.updateGroupReferenceDates(tasks, agenda);
    const result = [];
    linear(result, 0, sortTasks(tasks, sortKey), sortKey);
    console.timeEnd('populateTree');
    return result;
}

export function filterTasks(tasks, filterAsArray) {
    const bundleIds = filterAsArray.filter((aCriteria) => aCriteria.type === 'bundle').map((bundle) => bundle.id);
    const locationCriteria = filterAsArray.filter(
        (aCriteria) => aCriteria.type === 'location' || aCriteria.type === 'folder',
    );

    const stateCriteria = filterAsArray.filter((aCriteria) => aCriteria.type === 'state');
    const stringCriteria = filterAsArray.filter((aCriteria) => aCriteria._isStringCriteria);
    return tasks.filter(
        (task) =>
            (bundleIds.length === 0 || bundleIds.includes(task.service.bundle.id)) &&
            locationService.matchLocationCriteria(locationCriteria, [task.location]) &&
            matchStateCriteria(stateCriteria, task) &&
            matchString(stringCriteria, task),
    );
}
function matchString(stringCriteria, item) {
    if (!stringCriteria || stringCriteria.length === 0) {
        return true;
    }
    return stringCriteria.find((criteria) => filterMatch(item.service.name, criteria.content, true));
}
export function matchStateCriteria(stateCriteria, task) {
    if (stateCriteria.length === 0) {
        return true;
    }
    return !!stateCriteria.find((stateCriterion) => {
        if (stateCriterion.id === 'notStarted') {
            return !task.progress;
        } else if (stateCriterion.id === 'started') {
            return task.progress > 0 && task.progress < 100;
        } else if (stateCriterion.id === 'finished') {
            return task.progress === 100;
        } else if (stateCriterion.id === 'errored') {
            return task.isError;
        } else if (stateCriterion.id === 'noPredecessor') {
            return task.allPredecessors.length === 0;
        } else if (stateCriterion.id === 'critical') {
            return task.isCritical;
        }
    });
}
export function getFilterOptions(translateFn, folders, bundles) {
    const locationOptions = folders.reduce((acc, folder) => [...acc, ...folder.locations], []);
    const entityOptions = folders;
    const locationCriteriaType = translateFn('commons.locationCriteriaType');
    const bundleCriteriaType = translateFn('commons.bundleCriteriaType');
    const stateCriteriaType = translateFn('commons.stateCriteriaType');
    return [
        {
            isGroup: true,
            name: stateCriteriaType,
            id: 'state',
            children: [
                {
                    id: 'notStarted',
                    firstOfCriteriaType: true,
                    criteriaType: 'state',
                    name: translateFn('project.planning.notStartedState'),
                    type: 'state',
                },
                {
                    id: 'started',
                    firstOfCriteriaType: false,
                    criteriaType: 'state',
                    name: translateFn('project.planning.startedState'),
                    type: 'state',
                },
                {
                    id: 'finished',
                    firstOfCriteriaType: false,
                    criteriaType: 'state',
                    type: 'state',
                    name: translateFn('project.planning.finishedState'),
                },
                {
                    id: 'errored',
                    firstOfCriteriaType: false,
                    criteriaType: 'state',
                    name: translateFn('project.planning.erroredState'),
                    type: 'state',
                },
                {
                    id: 'noPredecessor',
                    firstOfCriteriaType: false,
                    criteriaType: 'state',
                    name: translateFn('project.planning.noPredecessorState'),
                    type: 'state',
                },
                {
                    id: 'critical',
                    firstOfCriteriaType: false,
                    criteriaType: 'state',
                    name: translateFn('project.planning.onCriticalPath'),
                    type: 'state',
                },
            ],
        },
        {
            isGroup: true,
            name: bundleCriteriaType,
            id: bundleCriteriaType,
            children: bundles.map((bundle, index) => ({
                ...bundle,
                name: bundle.label,
                firstOfCriteriaType: index === 0,
                criteriaType: bundleCriteriaType,
                type: 'bundle',
            })),
        },
        {
            isGroup: true,
            name: locationCriteriaType,
            id: locationCriteriaType,
            children: sortBy(
                [
                    ...entityOptions.map((entity, index) => ({
                        name: entity.fullName,
                        id: entity.id,
                        path: entity.path,
                        firstOfCriteriaType: index === 0,
                        criteriaType: locationCriteriaType,
                        type: 'folder',
                    })),
                    ...locationOptions.map((location) => ({
                        name: location.fullName,
                        id: location.id,
                        path: location.path,
                        criteriaType: locationCriteriaType,
                        type: 'location',
                    })),
                ],
                (result) => (result.fullName || result.name).replace('+', 'z+'),
            ),
        },
    ];
}
