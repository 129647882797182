import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapWeatherIssue(weatherIssue) {
    return weatherIssue
        ? {
              ...weatherIssue.toMutableJSON(),
              date: weatherIssue.date ? new Date(weatherIssue.date) : null,
          }
        : null;
}
export function getWeatherIssue(projectId, weatherIssueId) {
    return db
        .getProjectCollections(projectId)
        .weatherIssues.findOne({ selector: { id: weatherIssueId } })
        .$.pipe(map(mapWeatherIssue));
}
export function getWeatherIssues(projectId) {
    return db
        .getProjectCollections(projectId)
        .weatherIssues.find({ selector: {}, sort: [{ date: 'asc' }] })
        .$.pipe(
            map((items) => {
                return items.map(mapWeatherIssue);
            }),
        );
}

export async function removeWeatherIssue(projectId, weatherIssueId) {
    const entity = await db
        .getProjectCollections(projectId)
        .weatherIssues.findOne({ selector: { id: weatherIssueId } })
        .exec();
    return entity.remove();
}

export async function updateWeatherIssue(projectId, weatherIssue) {
    const dbWeatherIssue = await db
        .getProjectCollections(projectId)
        .weatherIssues.findOne({ selector: { id: weatherIssue.id } })
        .exec();
    return dbWeatherIssue.atomicPatch({
        ...weatherIssue,
        date: weatherIssue.date ? weatherIssue.date.toISOString() : dbWeatherIssue.date,
        attachments: weatherIssue.attachments
            ? weatherIssue.attachments.map(({ url, fileName }) => ({
                  url,
                  fileName,
              }))
            : dbWeatherIssue.attachments,
    });
}

export async function createWeatherIssue(projectId, weatherIssue) {
    const result = await db.getProjectCollections(projectId).weatherIssues.insert({
        name: weatherIssue.name,
        duration: 1,
        emitterIds: weatherIssue.emitterIds || [],
        date: weatherIssue.date?.toISOString() || new Date().toISOString(),
        attachments: [],
    });
    return result.toJSON();
}

export async function filterEmitterIds(projectId, period, bundleId) {
    const dbWeatherIssues = await db
        .getProjectCollections(projectId)
        .weatherIssues.findOne({ selector: { id: period.id } })
        .exec();
    return dbWeatherIssues.atomicPatch({
        emitterIds: dbWeatherIssues.emitterIds.filter((id) => id !== bundleId),
    });
}
export default { filterEmitterIds };
