<template>
    <div class="app-popup">
        <div
            v-if="isOpen && overlay"
            class="bg-black opacity-50 z-50 cursor-default fixed w-full h-full inset-0"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            @click="onClick"
        ></div>
        <transition :name="transition">
            <div ref="popup" v-if="isOpen" class="z-50 w-full md:w-1/2 text-sm fixed" :class="popupClass">
                <div class="md:min-h-toolbar" ref="toolbarSpacer" @click="onClick"></div>
                <div class="bg-white">
                    <app-separator v-if="showHeader" :variant="variant">
                        <h2>{{ title }}</h2>
                        <div class="flex flex-grow"></div>
                        <app-button size="mini" icon="icon-close" aria-label="close popup" @click="close()" />
                    </app-separator>
                    <div class="flex h-full flex-col overflow-y-auto max-h-main" @click.stop>
                        <slot />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import AppButton from '../../components/appButton/AppButton';
import AppSeparator from '@/components/appSeparator/AppSeparator';

export default {
    components: { AppSeparator, AppButton },
    props: {
        overlay: {
            type: Boolean,
            default: true,
        },
        title: String,
        showHeader: {
            type: Boolean,
            default: false,
        },
        popupClass: {
            type: String,
            default: 'left-50 min-h-screen md:min-h-0 top-0 max-h-screen md:max-h-main ',
        },
        variant: {
            type: String,
            default: 'default',
            enum: ['default', 'alert'],
        },
        transition: { type: String, default: 'fade' },
    },
    methods: {
        onClick(e) {
            this.close();
            this.$emit('dismiss');
            e.preventDefault();
        },
        close() {
            this.isOpen = false;
            this.$emit('close');
            this.filter = '';
        },
        open() {
            this.isOpen = true;
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
}
.fade-enter,
.fade-leave-to {
}
</style>
