import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const supports = {
    schema: {
        title: 'support schema',
        version: 3,
        description: 'describes a support',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            groupId: {
                type: 'string',
                final: true,
            },
            name: {
                type: ['string', 'null'],
            },
            code: {
                type: ['string', 'null'],
            },
            type: {
                type: ['string', 'null'],
            },
            emissionDate: {
                type: ['string', 'null'],
            },
            emissionDueDate: {
                type: ['string', 'null'],
            },
            locationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            url: {
                type: ['string', 'null'],
            },
            fileName: {
                type: ['string', 'null'],
            },
            pageCount: {
                type: ['number', 'null'],
            },
            isLastVersion: {
                type: ['boolean', 'null'],
            },
            rotation: {
                type: ['number', 'null'],
            },
            emitterId: {
                type: ['string', 'null'],
            },
            cropBox: {
                type: ['array', 'null'],
                items: { type: 'number' },
            },
            sharedWithDeliveryContacts: {
                type: ['boolean', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
        required: ['id'],
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId, {
                cacheURLsFn: (documents) =>
                    documents
                        .filter((document) => document.isLastVersion && document.type === 'graphic' && document.url)
                        .map((document) => {
                            return document.pageCount === 1 && document.url.endsWith('pdf')
                                ? document.url + '_001_high.png'
                                : document.url;
                        }),
            });
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: function (oldDoc) {
            return oldDoc;
        },
        2: function (oldDoc) {
            return { ...oldDoc, pageCount: 0, isLastVersion: false };
        },
        3: function (oldDoc) {
            return { ...oldDoc, sharedWithDeliveryContacts: false };
        },
    },
};
