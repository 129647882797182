import { getAPIHeaders } from '@/services/api.service';

export async function getEMLFile(projectId, emlConfig) {
    const response = await fetch(
        new Request(`/api/projects/${projectId}/emls`, {
            method: 'POST',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(emlConfig),
        }),
    );
    if (!response.ok) {
        throw new Error('createEMLError');
    }
    const data = await response.blob();
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = 'email.eml';
    a.click();
}
