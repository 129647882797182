<template>
    <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
        <form class="grid grid-cols-2">
            <app-multi-picker
                :value="selectedConvocation"
                @check="onCheck($event)"
                @uncheck="onUncheck($event)"
                :options="convocationOptions"
                variant="button"
                :show-label="false"
                :show-input="false"
                :label="$t('project.follow.meetings.addConvocation')"
                :title="$t('project.follow.meetings.addConvocation')"
            >
                <template v-slot:option="{ option }">
                    <app-bundle :bundle="option" />
                </template>
            </app-multi-picker>
            <div class="col-span-2">
                <ul class="flex-grow overflow-auto">
                    <li v-if="meeting.convocations.length === 0" class="text-center italic text-gray-700 text-sm">
                        {{ $t('commons.emptyList') }}
                    </li>
                </ul>
                <app-bundle-groups :groups="convocationGroups" tag="ul">
                    <template v-slot:item="{ item }">
                        <li :v-key="item.id" class="border p-2 flex hover:bg-gray-200 cursor-pointer">
                            <a :id="'uuid_' + item.id"></a>
                            <div class="flex justify-between">
                                <div class="w-full flex text-xs items-baseline">
                                    <meeting-attendance-select :convocation="item" @blur="updateConvocation(item)" />
                                    <app-bundle :bundle="item.bundle"></app-bundle>
                                </div>
                            </div>
                            <span class="flex-grow" />
                            <app-button
                                size="mini"
                                @click="onUncheck([item.bundle])"
                                variant="danger"
                                aria-label="remove item"
                                icon="icon-trash-can-outline"
                            />
                        </li>
                    </template>
                </app-bundle-groups>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { getMapById, sortBy } from '@/services/sanitize.service';
import { getMeeting, updateMeeting } from './meetings.service';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppBundle from '@/components/app-bundle/appBundle';
import AppBundleGroups from '@/components/appBundleGroups/AppBundleGroups';
import MeetingAttendanceSelect from '@/features/meetings/MeetingAttendanceSelect';
import { combineLatest } from 'rxjs';
import AppButton from '@/components/appButton/AppButton';
export default {
    components: { AppButton, MeetingAttendanceSelect, AppBundleGroups, AppBundle, AppMultiPicker },
    async created() {
        this.subscriptions = [
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([meeting, bundles]) => {
                const bundleMap = getMapById(bundles);
                this.meeting = {
                    ...meeting,
                    convocations: sortBy(
                        meeting.convocations.map((convocation) => ({
                            ...convocation,
                            bundle: bundleMap[convocation.bundleId],
                        })),
                        (bundle) => (bundle.type === 'company' ? bundle.reference : bundle.index),
                    ),
                };
                this.selectedConvocation = this.meeting.convocations.map((convocation) => ({ ...convocation.bundle }));
                this.convocationGroups = bundlesToGroups(this.meeting.convocations, 'bundle.category');
                const bundleGroups = bundlesToGroups(
                    bundles.map((bundle) => ({
                        ...bundle,
                        checked: this.selectedConvocation.find((convocation) => convocation.id === bundle.id),
                    })),
                );
                this.convocationOptions = [
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.MOA'),
                        children: bundleGroups.MOA,
                    },
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.AMOA'),
                        children: bundleGroups.AMOA,
                    },
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.MOE'),
                        children: bundleGroups.MOE,
                    },
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.generalContractor'),
                        children: bundleGroups.generalContractor,
                    },
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.distributor'),
                        children: bundleGroups.distributor,
                    },
                    {
                        isGroup: true,
                        name: this.$t('bundles.categories.company'),
                        children: bundleGroups.company,
                    },
                ];
            }),
        ];
    },
    methods: {
        onUncheck(bundles) {
            const convocations = this.meeting.convocations
                .filter(
                    (convocation) =>
                        convocation.bundle && !bundles.find((bundle) => bundle.id === convocation.bundle.id),
                )
                .map((convocation) => ({
                    convocationDate: convocation.convocationDate,
                    attendance: convocation.attendance,
                    bundleId: convocation.bundleId,
                }));
            updateMeeting(this.$route.params.projectId, { id: this.meeting.id, convocations });
        },
        onCheck(bundles) {
            const convocations = [
                ...this.meeting.convocations.map((convocation) => ({
                    convocationDate: convocation.convocationDate,
                    attendance: convocation.attendance,
                    bundleId: convocation.bundleId,
                })),
                ...bundles.map((bundle) => ({
                    convocationDate: this.meeting.date,
                    bundleId: bundle.id,
                    attendance: 'present',
                })),
            ];
            updateMeeting(this.$route.params.projectId, { id: this.meeting.id, convocations });
        },
        updateConvocation(convocation) {
            updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                convocations: this.meeting.convocations.map((aConvocation) =>
                    aConvocation.bundleId === convocation.bundleId ? convocation : aConvocation,
                ),
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            project: { bundles: [] },
            selectedConvocation: [],
            convocationOptions: [],
            convocationGroups: {
                MOA: [],
                AMOA: [],
                MOE: [],
                distributor: [],
                company: [],
            },
            meeting: {
                convocations: [],
            },
        };
    },
};
</script>
