<template>
    <main class="flex flex-col gap-4 max-w-screen-2xl w-full p-2 max-h-main overflow-hidden" v-if="meeting">
        <div class="flex flex-col gap-4 shadow-md border p-4">
            <p class="flex-grow text-lg font-bold flex">
                <span class="border-0 font-bold">{{ meeting.name }}</span>
            </p>
            <div class="flex items-center w-full flex-col md:flex-row gap-2">
                <div class="text-sm text-gray-700 flex-grow whitespace-nowrap flex gap-1 items-center">
                    <span>{{ meeting.date | humanizeDateTimeShort }}</span>
                    <span>{{ meeting.location }}</span>
                </div>
            </div>
            <div class="mr-4 flex flex-grow flex-col md:flex-row gap-2">
                <app-file-link
                    class="text-xs mr-4"
                    v-if="meeting.generationSucceeded"
                    :fileName="meeting.fileName"
                    :url="meeting.url"
                    :removable="false"
                    :to="{
                        name: 'meetingReportViewerDeep',
                        params: {
                            projectId: $route.params.projectId,
                            meetingId: meeting.id,
                        },
                    }"
                ></app-file-link>
                <div
                    v-if="meeting.generationEndDate && !meeting.generationSucceeded"
                    class="text-red-500 flex items-center"
                >
                    <icon-alert-circle width="16"></icon-alert-circle>
                    <span class="text-xs italic">
                        {{ $t('project.editions.meetingReports.generationFailed') }}
                    </span>
                </div>
                <div v-if="meeting.generationStartDate && !meeting.generationEndDate" class="flex items-center">
                    <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                    <span class="text-xs italic">
                        {{ $t('project.editions.meetingReports.generationPending') }}
                    </span>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import AppButton from '@/components/appButton/AppButton.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppTimeLink from '@/components/appTimeLink/AppTimeLink.vue';

export default {
    components: { AppTimeLink, AppInputText, AppFileLink, AppDateLink, AppDropDownButton, AppSelect, AppButton },
    props: {
        meeting: Object,
    },
};
</script>
