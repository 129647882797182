<template>
    <div class="app-filter flex relative shadow focus-within:ring-2">
        <div class="absolute left-0 absolute inset-y-0 pl-2 text-gray-500 flex items-center">
            <IconMagnify title="fitler"></IconMagnify>
        </div>
        <div
            class="absolute cursor-pointer right-0 absolute inset-y-0 pr-5 text-gray-500 flex items-center"
            @click="resetField()"
            v-if="value && value.length > 0"
        >
            <IconCloseCircle width="16" title="clear filter"></IconCloseCircle>
        </div>
        <label class="w-full">
            <span class="hidden">filter</span>
            <input
                ref="input"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                v-bind:value="value"
                @input="$emit('input', $event.target.value)"
                @keypress="$emit('keypress', $event)"
                @keydown="$emit('keydown', $event)"
                @keyup="$emit('keyup', $event)"
                name="filter"
                class="w-full pl-8 pr-6 py-2 shadow appearance-none focus:outline-none focus:ring"
                type="text"
                autocomplete="off"
            />
        </label>
    </div>
</template>
<script>
import IconMagnify from '../../icons/IconMagnify';
import IconCloseCircle from '../../icons/IconCloseCircle';
export default {
    components: {
        IconMagnify,
        IconCloseCircle,
    },
    props: ['value', 'placeholder', 'autocomplete'],
    methods: {
        resetField() {
            this.$emit('input', '');
            this.$refs.input.focus();
        },
        focus(value) {
            if (value) {
                this.$emit('input', value);
            }
            this.$refs.input.focus();
        },
    },
};
</script>
