<template>
    <app-button variant="borderless" size="mini" class="m-1 block" v-if="syncState" @click="syncProject()">
        <div
            :title="'Dernière synchro le ' + humanizeDate(new Date(syncState.lastSuccess))"
            v-if="
                syncState.state === 'ok' && new Date(syncState.lastSuccess).getTime() - 8 * 3600 > new Date().getTime()
            "
        >
            <icon-cloud-check width="16" height="16" class="text-yellow-500"></icon-cloud-check>
        </div>
        <div title="Synchro en cours" v-if="syncState.state === 'pending'">
            <icon-cloud-refresh width="16" height="16" class="text-blue-500"></icon-cloud-refresh>
        </div>
        <div title="Dernière synchro en erreur" v-if="syncState.state === 'error'">
            <icon-cloud-alert width="16" height="16" class="text-red-500"></icon-cloud-alert>
        </div>
        <div
            :title="'Dernière synchro le ' + humanizeDate(new Date(syncState.lastSuccess))"
            v-if="
                syncState.state === 'ok' && new Date(syncState.lastSuccess).getTime() - 8 * 3600 <= new Date().getTime()
            "
        >
            <icon-cloud-check width="16" height="16" class="text-green-600"></icon-cloud-check>
        </div>
    </app-button>
</template>

<script>
import { getProjectsSyncStatus } from '@/state/state';
import { humanizeDate } from '@/filters/dateFilter';
import database from '@/rxdb/database';
import { error, info } from '@/features/toasts/toats.service';
import AppButton from '@/components/appButton/AppButton';

export default {
    components: { AppButton },
    created() {
        this.subscriptions = [
            getProjectsSyncStatus().subscribe((syncStates) => {
                this.syncStates = syncStates;
                this.refreshSyncState();
            }),
        ];
    },
    watch: {
        $route() {
            this.refreshSyncState();
        },
    },
    data: () => ({
        subscriptions: [],
        syncState: { state: 'unknown' },
        syncStates: [],
    }),
    methods: {
        refreshSyncState() {
            if (this.$route.params.projectId) {
                this.syncState = this.syncStates.find((state) => state.projectId === this.$route.params.projectId);
            } else {
                this.syncState = null;
            }
        },
        humanizeDate,
        syncProject() {
            if (navigator.onLine) {
                database.replicateProject(this.$route.params.projectId);
                info(this.$t('sync.syncStart'));
            } else {
                error(this.$t('sync.syncCantStart'));
            }
        },
    },
};
</script>
