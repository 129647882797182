import { io } from 'socket.io-client';
let socket = { on() {}, disconnect: () => {} };
export function initSocket() {
    if (!document.location.search.includes('noToolBar')) {
        socket = io({ withCredentials: true, reconnectionDelay: 5000 });
        socket.on('connect_error', (err) => {
            if (!window.navigator.onLine) {
                console.error(err);
            }
        });
        socket.on('connection', (socket) => {
            console.log('connected' + socket.id);
        });

        window.addEventListener('online', function () {
            socket.connect();
        });
    }
}
export default function onMessage(eventName, callback) {
    return socket.on(eventName, callback);
}
export function disconnect() {
    socket.disconnect();
}
