import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const timeFormatter = new Intl.DateTimeFormat('fr-Fr', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
});
const hourFormatter = new Intl.DateTimeFormat('fr-Fr', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
});

const formatterFull = new Intl.DateTimeFormat('fr-Fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: false,
});

export default {
    install: (Vue) => {
        Vue.filter('humanizeDate', humanizeDate);
        Vue.filter('humanizeTime', humanizeTime);
        Vue.filter('humanizeHour', humanizeHour);
        Vue.filter('humanizeDateFull', humanizeDateFull);
        Vue.filter('humanizeDateTimeShort', humanizeDateTimeShort);
    },
};
export function humanizeDate(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return format(new Date(value), 'dd/MM/yy');
    }
}
export function humanizeDateTimeShort(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return format(new Date(value), 'dd/MM HH:mm');
    }
}
export function humanizeDateTimeFileSafe(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return format(new Date(value), "yyyy-MM-dd HH'h'mm");
    }
}
export function humanizeDateFull(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return formatterFull.format(new Date(value));
    }
}
export function humanizeTime(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return timeFormatter.format(new Date(value));
    }
}

export function humanizeHour(value) {
    if (!value) {
        return '';
    } else if (!isValid(new Date(value))) {
        return value;
    } else {
        return hourFormatter.format(new Date(value));
    }
}
