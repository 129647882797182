<template>
    <div class="w-full flex flex-col" :class="state">
        <app-report-header
            :document-name="$t('project.editions.observationsReports.documentName')"
            :document-title="observationsReport.name"
            :date="observationsReport.date"
        />
        <div class="text-xs flex flex-wrap gap-1 w-full justify-center mt-5 p-5" v-if="observationsReport.showStats">
            <FollowObservationStats class="w-full" :items="observations" :bundles="bundles" />
        </div>
    </div>
</template>
<script>
import { getObservations, getObservationsForRelatedContent } from '@/features/observations/observation.service';
import FollowObservationStats from '@/features/follow/FollowObservationStats';
import AppReportHeader from '@/components/appReportHeader/AppReportHeader';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { combineLatest } from 'rxjs';
import {
    filterObservationFromObservationReportConfig,
    getObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import { getBundleMap } from '@/features/bundles/bundles.service';

export default {
    components: { AppReportHeader, FollowObservationStats },
    async created() {
        this.subscriptions = [
            combineLatest([
                getObservationsReport(this.$route.params.projectId, this.$route.params.observationsReportId),
                getLocationsTree(this.$route.params.projectId),
                this.$route.query.relatedContentId
                    ? getObservationsForRelatedContent(this.$route.params.projectId, this.$route.query.relatedContentId)
                    : getObservations(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
            ]).subscribe(([observationReport, folders, observations, bundleMap]) => {
                this.observationsReport = observationReport;
                let zoneIds;
                if (this.observationsReport.zoneIds.length === 0) {
                    zoneIds = [
                        null,
                        ...locationService
                            .buildLocationOptions(folders)
                            .filter((location) => location.type === 'zone')
                            .map((zone) => zone.id),
                    ];
                } else {
                    zoneIds = this.observationsReport.zoneIds;
                }
                let bundleIds;
                if (this.$route.query.bundleId) {
                    bundleIds = [this.$route.query.bundleId];
                } else {
                    bundleIds = this.observationsReport.bundleIds;
                }
                if (bundleIds.length) {
                    this.bundles = bundleIds.map((id) => bundleMap[id]);
                } else {
                    this.bundles = Object.values(bundleMap);
                }

                this.observations = filterObservationFromObservationReportConfig(
                    observations,
                    zoneIds,
                    bundleIds,
                    this.observationsReport,
                );
                this.$nextTick(() => {
                    this.state = 'ready';
                });
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            observations: [],
            bundles: [],
            observationsReport: {},
            state: 'loading',
        };
    },
};
</script>
