<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex gap-2 flex-col items-start min-h-main">
        <div class="w-full flex-col">
            <div class="flex gap-2 flex-col sm:flex-row">
                <div class="flex flex-col flex-grow">
                    <div><app-button @click="addMeeting(1)" :label="$t('meetings.newMeeting')" v-if="!readOnly" /></div>
                    <div class="flex flex-grow">
                        <app-multi-picker
                            ref="filter"
                            icon="icon-magnify"
                            :allowStringCriteria="true"
                            class="w-full my-2"
                            v-model="filterValue"
                            :options="filterOptions"
                            :strictMatching="true"
                        >
                            <template v-slot:option="{ option }">
                                <span>{{ option.name }}</span>
                                <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                            </template>
                        </app-multi-picker>
                    </div>
                </div>
                <div class="flex gap-2 justify-center items-end">
                    <div class="flex gap-2 justify-center my-2">
                        <app-button
                            size="mini"
                            icon="icon-chevron-left"
                            :title="$t('project.follow.byBundle.previousWeeks')"
                            @click="previousWeeks()"
                        ></app-button>
                        <div class="text-sm md:flex-col gap-2 md:gap-0 flex flex-row">
                            <div class="flex items-center">
                                <app-date-input
                                    size="mini"
                                    class="ml-2"
                                    :label="$t('commons.from')"
                                    :inline="true"
                                    v-model="startDate"
                                    @input="refresh"
                                ></app-date-input>
                            </div>
                            <div class="flex items-center">
                                <app-date-input
                                    size="mini"
                                    :inline="true"
                                    :label="$t('commons.to')"
                                    class="ml-2"
                                    v-model="endDate"
                                    @input="refresh"
                                ></app-date-input>
                            </div>
                        </div>
                        <app-button
                            size="mini"
                            icon="icon-chevron-right"
                            :title="$t('project.follow.byBundle.nextWeeks')"
                            @click="nextWeeks()"
                        ></app-button>
                    </div>
                </div>
            </div>
            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('index')"
                            >
                                {{ $t('commons.index') }}
                                <div v-if="sortKey === 'index'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('date')"
                            >
                                {{ $t('commons.date') }}
                                <div v-if="sortKey === 'date'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                {{ $t('commons.name') }}
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <span :title="$t('meetings.report')">
                                {{ $t('meetings.reportShort') }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="meeting in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + meeting.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="meeting.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(meeting)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                <template v-if="meeting.index || meeting.index === 0">
                                    #{{ (meeting.index + 1).toString().padStart(3, '0') }}
                                </template>
                            </td>
                            <td class="border-r p-1">{{ meeting.date | humanizeDate }}</td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'followMeeting',
                                            params: { ...$route.params, meetingId: meeting.id },
                                        }"
                                    >
                                        {{ meeting.name }}
                                        <span v-if="!meeting.name || meeting.name.trim().length === 0">...</span>
                                    </router-link>
                                </div>
                            </td>
                            <td class="text-center border-r p-1">
                                <div class="flex" v-if="meeting.url">
                                    <app-file-link
                                        :url="meeting.url"
                                        :fileName="meeting.name"
                                        :showName="false"
                                    ></app-file-link>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { getLastDateRange, isMobile, saveDateRange } from '@/state/state';
import { sortBy, filterMatch } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { getMeetings, removeMeeting, initNewMeeting, createMeetings } from './meetings.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import { humanizeDate } from '@/filters/dateFilter';
import { queryProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';

export default {
    components: { AppQuickActions, AppFileLink, AppCheckbox, AppSelect, AppDateInput, AppMultiPicker, AppButton },
    async created() {
        this.backToLastDates();
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.project = project;
            this.readOnly = !project.me.allowedFeatures.includes('project_meetings');
        });
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
            getMeetings(this.$route.params.projectId).subscribe((meetings) => {
                this.restoreSelection();
                this.items = meetings.map((meeting) => {
                    return {
                        ...meeting,
                        filterString: [meeting.code, meeting.name, humanizeDate(meeting.date)].join(),
                        isSelected: this.selection.includes(meeting.id),
                    };
                });
                this.cleanupSavedSelection();
                const lastVisitedMeetingId = localStorage.getItem(
                    'meeting.lastVisitedMeetingId.' + this.$route.params.projectId,
                );
                if (lastVisitedMeetingId) {
                    this.$nextTick(() => {
                        const element = this.$el.querySelector('#uuid_' + lastVisitedMeetingId);
                        if (element) element.scrollIntoView();
                    });
                }
                this.loading = false;
            }),
        ];
    },
    computed: {
        quickActions() {
            const newMeeting = this.$t('meetings.newMeeting');
            const createMultipleFn = (times) => this.addMeeting(times);
            return [
                {
                    // /!\ Use of 'this' as pointer to the current option not to the component and fill the param
                    name(item, filter) {
                        if (filter) {
                            const numberOfTime = filter.match(/\d+/g);
                            if (numberOfTime) {
                                this.times = parseInt(numberOfTime);
                                return numberOfTime[0] + ' ' + newMeeting;
                            }
                        }
                        return newMeeting;
                    },
                    // /!\ Use of 'this' as pointer to the current option not to the component and fill the param
                    run() {
                        createMultipleFn(this.times || 1);
                        this.times = null;
                    },
                },
            ];
        },
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'index') {
                        return item.index;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    } else if (this.sortKey === 'date') {
                        return item.date;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    name: this.$t('commons.noCR'),
                    id: 'noCR',
                },
            ];
        },
    },
    methods: {
        saveDates() {
            saveDateRange(this.$route.params.projectId, this.startDate, this.endDate);
        },
        backToLastDates() {
            const cachedDateRange = getLastDateRange(this.$route.params.projectId);
            this.startDate = cachedDateRange.startDate;
            this.endDate = cachedDateRange.endDate;
        },
        nextWeeks() {
            this.startDate = addDays(this.startDate, 7);
            this.endDate = addDays(this.endDate, 7);
            this.refresh();
        },
        previousWeeks() {
            this.startDate = subDays(this.startDate, 7);
            this.endDate = subDays(this.endDate, 7);
            this.refresh();
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeMeeting(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem('meetings.selection.' + this.$route.params.projectId, JSON.stringify(this.selection));
        },
        restoreSelection() {
            const cache = localStorage.getItem('meetings.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        refresh() {
            this.saveDates();
        },
        async addMeeting(times) {
            const existingMeetings = [...this.items];
            const meetings = [];
            for (let index = 0; index < times; index++) {
                const newMeeting = await initNewMeeting(this.project, this.agenda, existingMeetings, (key) =>
                    this.$t(key),
                );
                existingMeetings.push(newMeeting);
                meetings.push(newMeeting);
            }
            return createMeetings(this.$route.params.projectId, meetings);
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        matchDateRange(item) {
            return this.startDate < item.date && item.date <= this.endDate;
        },
        matchType(typeCriteria, item) {
            return typeCriteria.length === 0 || !item.url;
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const typeCriteria = filter.filter((aCriteria) => aCriteria.id === 'noCR').map((criteria) => criteria.id);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchString: this.matchString(stringCriteria, item),
                    matchType: this.matchType(typeCriteria, item),
                    matchDateRange: this.matchDateRange(item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        saveFilter(filterValue) {
            localStorage.setItem('meetings_filter_' + this.$route.params.projectId, JSON.stringify({ filterValue }));
        },
        restoreFilter() {
            const cache = localStorage.getItem('meetings_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
    },
    data() {
        return {
            times: null,
            loading: true,
            startDate: new Date(),
            endDate: new Date(),
            sortKey: 'date',
            sortAsc: true,
            readOnly: true,
            selection: [],
            isMobile,
            items: [],
            agenda: [],
            project: {},
            filterValue: [],
        };
    },
};
</script>
