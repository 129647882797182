<template>
    <div
        role="dialog"
        ref="confirm"
        tabindex="-1"
        class="app-mask fixed inset-0 flex bg-black z-50"
        @click="$close(false)"
    >
        <div class="app-popup m-auto bg-gray-100" @click.stop="">
            <header class="bg-red-500 p-3"></header>
            <div class="px-4 py-3">
                <p>{{ content }}</p>
            </div>
            <footer class="flex my-3 justify-center">
                <app-button
                    variant="danger"
                    :shortcut="this.$t('commons.yesKeyCode')"
                    class="mx-3"
                    @click="$close(true)"
                >
                    <app-shortcut :label="$t('commons.yes')" :shortcut="this.$t('commons.yesKeyCode')"></app-shortcut>
                </app-button>
                <app-button class="mx-3 px-10" :shortcut="this.$t('commons.noKeyCode')" @click="$close(false)">
                    <app-shortcut :label="$t('commons.no')" :shortcut="this.$t('commons.noKeyCode')"></app-shortcut>
                </app-button>
            </footer>
        </div>
    </div>
</template>
<style scoped>
.app-mask {
    background-color: rgba(0, 0, 0, 0.33);
}
.app-popup {
    min-width: 240px;
}
</style>
<script>
import AppButton from '../../components/appButton/AppButton';
import AppShortcut from '../../components/appShortcut/AppShortcut';
export default {
    components: { AppShortcut, AppButton },
    props: ['content'],
    mounted() {
        this.$refs.confirm.focus();
    },
    created() {
        const handleKeydown = (e) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                this.$close(false);
            }
        };
        document.addEventListener('keydown', handleKeydown);
        this.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleKeydown);
        });
    },
};
</script>
