<template>
    <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
        <div class="col-span-2 flex text-sm">
            <app-button
                @click="checkParticipants()"
                :label="$t('project.editions.meetingReports.checkParticipants')"
                size="mini"
            />
            <app-button class="mx-2" @click="checkAll()" :label="$t('commons.all')" size="mini" />
            <app-button @click="checkNone()" :label="$t('commons.none')" size="mini" />
        </div>
        <div class="col-span-2">
            <app-bundle-groups :groups="recipientOptionsGroups">
                <template v-slot:item="{ item }">
                    <app-checkbox :value="item.isSelected" @input="onRecipientChange(item, $event)">
                        <app-bundle :bundle="item" />
                    </app-checkbox>
                </template>
            </app-bundle-groups>
        </div>
    </ValidationObserver>
</template>
<script>
import { getMapById, uniq } from '@/services/sanitize.service';
import { getMeeting, updateMeeting } from './meetings.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppBundle from '@/components/app-bundle/appBundle';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import AppBundleGroups from '@/components/appBundleGroups/AppBundleGroups';
import AppButton from '@/components/appButton/AppButton';
import { combineLatest } from 'rxjs';
export default {
    components: { AppBundle, AppCheckbox, AppBundleGroups, AppButton },
    created() {
        this.subscriptions = [
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([meeting, bundles]) => {
                const bundleMap = getMapById(bundles);
                this.meeting = {
                    ...meeting,
                    recipients: meeting.recipientIds.map((recipientId) => bundleMap[recipientId]),
                };
                this.recipientOptions = bundles.map((bundle) => ({
                    ...bundle,
                    isSelected: !!this.meeting.recipientIds.includes(bundle.id),
                }));
                this.recipientOptionsGroups = bundlesToGroups(this.recipientOptions);
            }),
        ];
    },
    methods: {
        saveMeeting(meeting) {
            return updateMeeting(this.$route.params.projectId, {
                id: meeting.id,
                recipientIds: this.meeting.recipients.map((bundle) => bundle.id),
            });
        },
        addRecipientIfNeeded(bundle) {
            if (!this.meeting.recipientIds.includes(bundle.id)) {
                return updateMeeting(this.$route.params.projectId, {
                    id: this.meeting.id,
                    recipientIds: [...this.meeting.recipientIds, bundle.id],
                });
            }
        },
        removeRecipientIfNeeded(bundle) {
            if (this.meeting.recipientIds.includes(bundle.id)) {
                return updateMeeting(this.$route.params.projectId, {
                    id: this.meeting.id,
                    recipientIds: this.meeting.recipientIds.filter((recipientId) => recipientId !== bundle.id),
                });
            }
        },
        checkParticipants() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                recipientIds: uniq([
                    ...this.meeting.recipientIds,
                    ...this.meeting.convocations.map((convocation) => convocation.bundleId),
                ]),
            });
        },
        checkNone() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                recipientIds: [],
            });
        },
        checkAll() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                recipientIds: this.recipientOptions.map((bundle) => bundle.id),
            });
        },
        onRecipientChange(bundle, checked) {
            if (checked) {
                this.addRecipientIfNeeded(bundle);
            } else {
                this.removeRecipientIfNeeded(bundle);
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            recipientOptions: [],
            recipientOptionsGroups: {
                MOA: [],
                AMOA: [],
                MOE: [],
                distributor: [],
                company: [],
            },
            meeting: {
                recipients: [],
            },
        };
    },
};
</script>
