<template>
    <div class="absolute top-0 w-full min-h-full" ref="viewport" :style="{ left: tableWidth }">
        <svg
            ref="svg"
            :width="(steps[steps.length - 1].offsetInDays + steps[steps.length - 1].colspan) * dayWidth"
            class="absolute min-h-full"
            v-if="steps.length"
        >
            <rect y="0" fill="#e2e8f0" fill-opacity="0.5" height="10000" :width="width" :x="x" />
        </svg>
    </div>
</template>

<script>
import { throttle } from '@/services/sanitize.service';

export default {
    props: ['steps', 'dayWidth', 'tableWidth'],

    beforeMount() {
        window.addEventListener('mousemove', this.onMousemove);
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.onMousemove);
    },
    data: function () {
        return {
            x: -100,
            width: 0,
        };
    },
    methods: {
        onMousemove: throttle(
            function (event) {
                if (this.$refs.svg) {
                    const viewportRect = this.$refs.viewport.getBoundingClientRect();
                    if (
                        event.clientY > viewportRect.top &&
                        event.clientY < viewportRect.bottom &&
                        event.clientX > viewportRect.left &&
                        event.clientX < viewportRect.right
                    ) {
                        const mouseXOnSVG = event.clientX - this.$refs.svg.getBoundingClientRect().left;
                        const nextStep = this.steps.find((step) => mouseXOnSVG < step.offsetInDays * this.dayWidth);
                        const hoveredStep = this.steps[this.steps.indexOf(nextStep) - 1];
                        if (hoveredStep) {
                            this.x = hoveredStep.offsetInDays * this.dayWidth;
                            this.width = hoveredStep.colspan * this.dayWidth;
                        } else {
                            this.x = -100;
                            this.width = 0;
                        }
                    } else {
                        this.x = -100;
                        this.width = 0;
                    }
                }
            },
            100,
            true,
        ),
    },
};
</script>
