import add from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import { isTablet } from '@/state/state';
import { eachWeekendOfInterval } from 'date-fns';

export function getTaskLeft(minDate, startDate) {
    return getTaskWidth(minDate, startDate, false);
}
const dayInMs = 3600 * 1000 * 24;
function getTaskWidth(startDate, endDate, includesEndDay = true) {
    if (!endDate || !startDate) {
        return 0;
    }
    return (endDate.getTime() - startDate.getTime()) / dayInMs + (includesEndDay ? 1 : 0);
}
function isParentCollapsed(task, collapsedLevel) {
    const currentLevel = task.level || 0;
    collapsedLevel[currentLevel] = task.collapsed;
    for (let index = currentLevel - 1; index >= 0; index--) {
        if (collapsedLevel[index]) {
            return true;
        }
    }
    return false;
}

export function cleanMinDate(minDate) {
    if (minDate) {
        return startOfDay(new Date(minDate));
    } else {
        return new Date();
    }
}
export function cleanOrDefineMaxDate(minDate, maxDate, scale) {
    if (maxDate) {
        return new Date(maxDate);
    } else {
        return add(new Date(minDate), { [scale]: isTablet ? 20 : 30 });
    }
}
export function excludeOutOfDateRangeTasks(tasks, startDate, endDate) {
    return tasks.filter((task) => {
        const taskStartDate = task.realStartDate || task.startDate;
        const taskEndDate = task.estimatedEndDate;
        return !(taskEndDate < startDate || taskStartDate > endDate);
    });
}
export function convertEmToPixels(rem, parentElement) {
    return rem * parseFloat(getComputedStyle(parentElement).lineHeight);
}
export function applyCollapse(tree) {
    const collapsedLevel = {};
    return tree.filter((task) => !isParentCollapsed(task, collapsedLevel)).map((aTask) => ({ ...aTask }));
}
export function getWeekEnds(start, end) {
    return eachWeekendOfInterval({ start, end }).map((weekEndDay) => ({
        date: weekEndDay,
        time: weekEndDay.getTime(),
        id: weekEndDay.getTime(),
        isSunday: weekEndDay.getDay() === 0,
    }));
}
export function mapAgenda(agenda, refDate = new Date()) {
    const relativeToDate = startOfDay(refDate);
    return agenda.map((period) => ({
        ...period,
        left: getTaskLeft(relativeToDate, new Date(period.date)),
    }));
}
export function mapTasks(tasks, refDate = new Date()) {
    //console.time('filterTasks');
    const relativeToDate = startOfDay(refDate);
    const result = tasks
        .map((aTask) => ({ ...aTask }))
        .map((task) => {
            if (task.summarize) {
                task.children = mapTasks(task.children, relativeToDate);
            }
            task.plannedLeft = getTaskLeft(relativeToDate, task.startDate);
            task.plannedWidth = getTaskWidth(task.startDate, task.endDate);

            if (task.realStartDate || task.startDate < relativeToDate) {
                task.realWidth = getTaskWidth(task.realStartDate || relativeToDate, task.estimatedEndDate);
                task.realLeft = getTaskLeft(relativeToDate, task.realStartDate || relativeToDate);
            } else {
                task.realWidth = task.plannedWidth;
                task.realLeft = task.plannedLeft;
            }

            if (task.realEndDate || (!task.realStartDate && task.startDate > new Date())) {
                task.progressLeft = task.realLeft;
            } else {
                task.progressLeft = task.realStartDate ? task.realLeft : task.plannedLeft;
            }
            const progress = task.progress;
            task.progressWidth = progress ? (progress / 100) * task.realWidth : 0;

            if (task.referenceStartDate) {
                task.referenceLeft = getTaskLeft(relativeToDate, task.referenceStartDate);
                task.referenceWidth = getTaskWidth(task.referenceStartDate, task.referenceEndDate);
            } else {
                task.referenceLeft = 0;
                task.referenceWidth = 0;
            }
            if (task.realEndDate || (!task.realStartDate && task.startDate > new Date())) {
                task.progressRight = 0;
            } else {
                task.progressRight = task.progressLeft + task.progressWidth;
            }
            return task;
        });
    //console.timeEnd('filterTasks'); ~15-25ms
    return result;
}
