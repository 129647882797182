<template>
    <div class="app-autocomplete">
        <div ref="handle" class="z-50 hidden md:block md:max-w-screen/2">
            <div v-show="!isOpen">
                <slot name="handle" />
            </div>
        </div>
        <div
            v-if="isOpen && overlay"
            class="bg-black opacity-50 z-50 cursor-default fixed w-full h-full inset-0"
            :style="{
                left: '-100vw',
                width: '200vw',
                height: '100vw',
            }"
            aria-haspopup="true"
            :aria-expanded="isOpen"
            @click="onClick"
        ></div>
        <transition :name="transition">
            <div
                ref="popup"
                role="menu"
                v-if="isOpen"
                class="z-50 w-full md:max-w-screen/2 text-sm bg-white fixed"
                :class="popupClass"
            >
                <div class="flex h-full flex-col" @click.stop>
                    <slot name="title" />
                    <app-filter
                        ref="filter"
                        v-model="filter"
                        class="m-2"
                        @keypress.enter.prevent=""
                        autocomplete="off"
                        @keyup.stop="onFilterKeyUp($event)"
                        @keydown.tab="close()"
                    ></app-filter>
                    <div class="flex ml-2 mb-2" v-if="multiple && filteredOptions.length > 0">
                        <app-button size="mini" @click="onAll">{{ $t('commons.all') }}</app-button>
                        <app-button size="mini" class="ml-2" @click="onNone">
                            {{ $t('commons.none') }}
                        </app-button>
                    </div>
                    <div class="h-full overflow-x-hidden overflow-y-auto">
                        <slot v-bind:filter="filter" name="">
                            <div
                                v-if="options.length === 0 && !(filter.length > 0 && allowStringCriteria)"
                                class="m-2 text-center italic text-gray-700 text-sm truncate"
                            >
                                {{ $t('commons.noOptions') }}
                            </div>
                            <template v-for="item in limitedFilteredOptions">
                                <template v-if="item.isGroup">
                                    <div
                                        class="bg-secondary font-bold text-sm text-white px-1 w-full truncate whitespace-nowrap"
                                        :key="item.id"
                                        v-if="item.children.length > 0"
                                    >
                                        <slot>{{ getLabel(item) }}</slot>
                                    </div>
                                    <template v-for="subItem in item.children">
                                        <div
                                            class="whitespace-nowrap block px-2 hover:bg-gray-200 truncate"
                                            :class="{
                                                'bg-gray-300':
                                                    highlighted &&
                                                    (bindById
                                                        ? subItem === highlighted
                                                        : subItem.id === highlighted.id),
                                                'text-gray-500': item.disabled === true,
                                            }"
                                            :key="subItem.id"
                                        >
                                            <label v-if="multiple" class="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    class="m-2"
                                                    :disabled="subItem.disabled"
                                                    :checked="subItem.checked"
                                                    @change="onChoose(subItem)"
                                                />
                                                <slot :item="subItem" :filter="filter" name="item">
                                                    {{ getLabel(subItem) }}
                                                </slot>
                                            </label>
                                            <div v-else @click="onChoose(subItem)">
                                                <slot :item="subItem" :filter="filter" name="item">
                                                    {{ getLabel(subItem) }}
                                                </slot>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <div
                                    v-else
                                    class="whitespace-nowrap block px-2 hover:bg-gray-200 truncate"
                                    :class="{
                                        'bg-gray-300':
                                            highlighted === item || (highlighted && highlighted.id === item.id),
                                        'text-gray-500': item.disabled === true,
                                    }"
                                    :key="item.id"
                                    :title="disableToolTips"
                                >
                                    <label v-if="multiple" class="flex items-center">
                                        <input
                                            type="checkbox"
                                            class="m-2"
                                            :checked="item.checked"
                                            :disabled="item.disabled"
                                            @change="onChoose(item)"
                                        />
                                        <slot :item="item" :filter="filter" name="item">
                                            {{ getLabel(item) }}
                                        </slot>
                                    </label>
                                    <div v-else @click="onChoose(item)" class="flex">
                                        <slot :item="item" :filter="filter" name="item">
                                            <span class="py-2">{{ getLabel(item) }}</span>
                                        </slot>
                                    </div>
                                </div>
                            </template>
                        </slot>
                    </div>
                    <slot name="footer" />
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import IconChevronDown from '../../icons/IconChevronDown';
import AppLabel from '../appLabel/AppLabel';
import AppSelect from '../appSelect/AppSelect';
import AppFilter from '../appFilter/AppFilter';
import AppInputText from '../appInputText/AppInputText';
import AppSeparator from '../appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import { filterMatch } from '@/services/sanitize.service';

export default {
    components: { AppButton, AppSeparator, AppInputText, AppFilter, AppSelect, IconChevronDown, AppLabel },
    props: {
        value: Object | String | Number | Array,
        maxOptions: { type: Number, default: 0 },
        allowStringCriteria: { type: Boolean, default: false },
        stringCriteriaPrefix: { type: String, default: null },
        options: {
            type: Array,
            default: () => [],
        },
        labelKey: {
            type: String,
            default: 'name',
        },
        bindById: Boolean,
        disableToolTips: String,
        labelFunction: {
            type: Function,
        },
        filterStringFunction: {
            type: Function,
        },
        overlay: {
            type: Boolean,
            default: true,
        },
        strictMatching: {
            type: Boolean,
            default: true,
        },
        popupClass: { type: String, default: 'absolute-center' },
        transition: { type: String, default: 'fade' },
        multiple: { type: Boolean, default: false },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onClick);
    },
    watch: {
        value(value) {
            if (Array.isArray(value)) {
                this.highlighted = value[value.length - 1];
            } else {
                this.highlighted = value;
            }
        },
    },
    computed: {
        flattenFilteredOptions() {
            let allOptions = [];
            for (const option of this.filteredOptions) {
                allOptions = option.isGroup ? [...allOptions, ...option.children] : [...allOptions, option];
            }
            return allOptions;
        },
        limitedFilteredOptions() {
            if (this.maxOptions) {
                return this.filteredOptions.slice(0, this.maxOptions);
            } else {
                return this.filteredOptions;
            }
        },
        filteredOptions() {
            const options = this.options
                .map((item) => {
                    if (item.isGroup) {
                        const children = item.children
                            .map((subItem) => ({
                                ...subItem,
                                checked: this.multiple ? this.isSelected(subItem) : false,
                            }))
                            .filter((subItem) => this.doesMatch(this.filter, this.getFilterString(subItem)));
                        return { ...item, children };
                    } else {
                        return { ...item, checked: this.multiple ? this.isSelected(item) : false };
                    }
                })
                .filter((item) => {
                    if (item.isGroup && this.filter.length > 0) {
                        return item.children.length > 0;
                    } else {
                        return this.filter.length === 0 || this.doesMatch(this.filter, this.getFilterString(item));
                    }
                });
            if (this.allowStringCriteria && this.multiple) {
                return [
                    ...(this.filter.length > 0 &&
                    !this.value.find((item) => item._isStringCriteria && item.content === this.filter)
                        ? [
                              {
                                  id: 'stringCriteria_' + Math.random().toString().replace('.', ''),
                                  _isStringCriteria: true,
                                  content: this.filter,
                                  name:
                                      this.stringCriteriaPrefix !== null
                                          ? this.stringCriteriaPrefix + ' ' + this.filter
                                          : this.$t('commons.contains') + ` : "${this.filter}"`,
                              },
                          ]
                        : []),
                    ...this.value
                        .filter((item) => item._isStringCriteria && this.doesMatch(this.filter, item.content))
                        .reverse(),
                    ...options,
                ];
            } else if (this.allowStringCriteria && !this.multiple) {
                return [
                    ...(this.filter.length > 0
                        ? [
                              {
                                  id: 'stringCriteria_' + Math.random().toString().replace('.', ''),
                                  _isStringCriteria: true,
                                  content: this.filter,
                                  name:
                                      this.stringCriteriaPrefix !== null
                                          ? this.stringCriteriaPrefix + ' ' + this.filter
                                          : this.$t('commons.contains') + ` : "${this.filter}"`,
                              },
                          ]
                        : []),
                    ...options,
                ];
            } else {
                return options;
            }
        },
    },
    methods: {
        getActiveOptions() {
            return this.filteredOptions.reduce((acc, item) => {
                if (item.isGroup) {
                    return [...acc, ...item.children];
                } else {
                    return [...acc, item];
                }
            }, []);
        },
        onAll() {
            const options = this.getActiveOptions();
            let itemsToCheck = options.filter((item) => !this.isSelected(item) && item.disabled !== true);
            let result = [...this.value, ...itemsToCheck];
            this.$emit(
                'check',
                itemsToCheck.map((item) => (this.bindById ? item.id : item)),
            );
            this.$emit('input', result);
        },
        onNone() {
            const options = this.getActiveOptions();
            let itemsToUncheck = options.filter((item) => this.isSelected(item));
            let result = this.value.filter(
                (item) =>
                    !itemsToUncheck.find((itemToUncheck) => itemToUncheck.id === item.id || itemToUncheck === item),
            );
            this.$emit(
                'uncheck',
                itemsToUncheck.map((item) => (this.bindById ? item.id : item)),
            );
            this.$emit('input', result);
        },
        isSelected(item) {
            if (this.multiple) {
                return !!this.value.find(
                    (element) =>
                        element === item ||
                        element.id === item.id ||
                        (item.content && item.content === element.content),
                );
            } else {
                return item === this.value || (this.value.id && this.value.id === item.id);
            }
        },
        doesMatch(filter, value) {
            return filterMatch(value, this.filter, this.strictMatching);
        },
        onClick(e) {
            if (this.$refs.popup && !this.$refs.popup.contains(e.target)) {
                this.close();
                e.preventDefault();
            }
        },
        getLabel(option) {
            if (!option) {
                return '';
            }
            if (option._isStringCriteria) {
                return option.name;
            } else if (this.labelFunction) {
                return this.labelFunction(option, this.filter);
            } else {
                return option[this.labelKey] || '';
            }
        },
        getFilterString(option) {
            if (!option) {
                return '';
            }
            if (this.filterStringFunction) {
                return this.filterStringFunction(option, this.filter);
            } else {
                return this.getLabel(option);
            }
        },
        close() {
            document.removeEventListener('click', this.onClick);
            this.isOpen = false;
            this.$emit('close');
            this.filter = '';
        },
        onChoose(item) {
            this.onHighlighted(item);
            if (!this.multiple) {
                if (item._isStringCriteria) {
                    this.$emit('input', this.bindById ? item.content : item);
                } else {
                    this.$emit('input', this.bindById ? item.id : item);
                }
                this.close();
            } else {
                item.checked = !item.checked;
                const isSelected = this.isSelected(item);
                this.$emit(isSelected ? 'uncheck' : 'check', this.bindById ? [item.id] : [item]);
                if (isSelected) {
                    this.$emit(
                        'input',
                        this.value.filter((element) => element.id !== item.id),
                    );
                } else {
                    this.$emit('input', [...this.value, item]);
                }
                if (item._isStringCriteria) {
                    this.filter = '';
                }
            }
        },
        onHighlighted(item) {
            this.highlighted = item;
        },
        open(filterValue) {
            this.isOpen = true;
            if (filterValue) {
                this.filter = filterValue;
            }
            this.$nextTick(() => {
                if (this.$refs.filter) {
                    this.$refs.filter.focus();
                }
                setTimeout(
                    () =>
                        this.$nextTick(() => {
                            document.addEventListener('click', this.onClick);
                        }),
                    250,
                );
            });
        },
        onTriggerKeyUp(event) {
            if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
                return;
            }
            if (event.code === 'ArrowDown') {
                this.onDown();
            } else if (event.code === 'ArrowUp') {
                this.onUp();
            } else if (event.code === 'Space') {
                this.open();
            }
        },
        onFilterKeyUp(event) {
            if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
                return;
            }
            if (this.isOpen && (event.key === 'Esc' || event.key === 'Escape')) {
                this.close();
                event.stopPropagation();
            } else if (event.code === 'ArrowDown') {
                this.onDown();
            } else if (event.code === 'ArrowUp') {
                this.onUp();
            } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                this.onChoose(this.highlighted || this.findFirstOption());
            } else {
                this.onChange();
            }
        },
        findFirstOption() {
            let firstOption = this.filteredOptions[0];
            for (const option of this.filteredOptions) {
                if (option.isGroup) {
                    if (option.children.length > 0) {
                        return option.children[0];
                    }
                } else {
                    return option;
                }
            }
            return firstOption;
        },
        onChange() {
            this.onHighlighted(this.findFirstOption());
        },
        onDown() {
            if (this.filteredOptions.length > 0) {
                if (!this.highlighted) {
                    this.onHighlighted(this.findFirstOption());
                } else {
                    const currentIndex = this.flattenFilteredOptions.indexOf(this.highlighted);
                    if (currentIndex < this.flattenFilteredOptions.length - 1) {
                        this.onHighlighted(this.flattenFilteredOptions[currentIndex + 1]);
                    } else {
                        this.onHighlighted(this.findFirstOption());
                    }
                }
            }
        },
        onUp() {
            if (this.filteredOptions.length > 0) {
                if (!this.highlighted) {
                    this.onHighlighted(this.findFirstOption());
                } else {
                    const currentIndex = this.flattenFilteredOptions.indexOf(this.highlighted);
                    if (currentIndex > 0) {
                        this.onHighlighted(this.flattenFilteredOptions[currentIndex - 1]);
                    } else {
                        this.onHighlighted(this.flattenFilteredOptions[this.flattenFilteredOptions.length - 1]);
                    }
                }
            }
        },
    },
    data() {
        return {
            isOpen: false,
            filter: '',
            highlighted: Array.isArray(this.value) ? this.value[0] : this.value,
        };
    },
};
</script>
<style scoped>
.absolute-center {
    left: 50%;
    top: 5%;
    height: 80vh;
    transform: translate(-50%);
}
.fade-enter-active,
.fade-leave-active {
}
.fade-enter,
.fade-leave-to {
}
</style>
