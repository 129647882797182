<template>
    <div class="flex flex-row">
        <div class="flex flex-col border-r">
            <a
                :key="state.id"
                v-for="state in states"
                href="#"
                class="pl-2 pr-4 py-1"
                :class="{
                    'bg-gray-300': currentState === state.id,
                    'hover:bg-gray-200': currentState !== state.id,
                }"
                @click.prevent="changeState(state)"
            >
                {{ state.title }}
            </a>
            <div class="mt-2 p-2" v-if="!readOnly">
                <app-button
                    :label="$t('project.follow.planning.editService')"
                    @click="$emit('editService')"
                ></app-button>
            </div>
        </div>
        <div class="flex-grow">
            <TaskPlanningDetail
                :agenda="agenda"
                :tasks="tasks"
                :task="task"
                v-if="currentState === 'dates'"
                @editService="$emit('editService')"
            />
            <TaskPredecessors
                class="mb-8 mx-4"
                :task="task"
                :tasks="tasks"
                v-if="currentState === 'predecessors'"
            ></TaskPredecessors>
            <TaskSuccessors
                class="mb-8 mx-4"
                :task="task"
                :tasks="tasks"
                v-if="currentState === 'successors'"
            ></TaskSuccessors>
        </div>
    </div>
</template>

<script>
import TaskPlanningDetail from './TaskPlanningDetail';
import { queryPlanningState, updatePlanningState } from '@/state/state';
import TaskPredecessors from '@/features/planning/planning/TaskPredecessors';
import AppButton from '@/components/appButton/AppButton';
import TaskSuccessors from '@/features/planning/planning/TaskSuccessors';
import tasksService from '@/features/tasks/plannedTasks.service';
export default {
    components: { TaskSuccessors, AppButton, TaskPredecessors, TaskPlanningDetail },
    props: ['task', 'tasks', 'agenda', 'readOnly'],
    created() {
        this.currentState = queryPlanningState(this.$route.params.projectId).detailTabOpen || 'dates';
    },
    computed: {
        successors() {
            return tasksService.getDirectSuccessors(this.task, this.tasks);
        },
        states() {
            return [
                {
                    id: 'dates',
                    title: this.$t('project.follow.planning.taskDetails'),
                },
                {
                    id: 'predecessors',
                    title:
                        this.$t('project.follow.planning.taskPredecessorDetailTitle') +
                        ' (' +
                        this.task.allPredecessors.length +
                        ')',
                },
                {
                    id: 'successors',
                    title:
                        this.$t('project.follow.planning.taskSuccessorsDetailTitle') +
                        ' (' +
                        this.successors.length +
                        ')',
                },
            ];
        },
    },
    data() {
        return {
            currentState: 'dates',
        };
    },
    methods: {
        async changeState(state) {
            this.currentState = state.id;
            updatePlanningState(this.$route.params.projectId, {
                detailTabOpen: state.id,
            });
        },
    },
};
</script>
