<template>
    <a
        href="#"
        @click.prevent="$emit('click', $event)"
        class="text-gray-700"
        aria-label="Cancel creation"
        :title="$t('commons.cancel')"
    >
        <IconUndo width="16" height="16"></IconUndo>
    </a>
</template>
<script>
import IconUndo from '../../icons/IconUndo';
export default {
    components: { IconUndo },
};
</script>
