<template>
    <read-only-support-form v-if="readOnly && !loading" class="max-w-5xl m-5 mb-10 w-full"></read-only-support-form>
    <main class="max-w-5xl m-5 mb-10 w-full" v-else-if="!loading">
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <div class="flex justify-between flex-col-reverse sm:flex-row sm:items-center">
            <AppLegend />
            <div class="flex sm:items-center flex-col sm:flex-row">
                <table class="mx-2">
                    <tr>
                        <th scope="row" class="text-right text-sm whitespace-nowrap">{{ $t('commons.version') }} :</th>
                        <td class="px-3">
                            <app-select
                                :label="$t('commons.version')"
                                :value="support.id"
                                class=""
                                :show-label="false"
                                :disabled="!support.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in support.versions"
                                    :key="version.id"
                                >
                                    {{ index + 1 }} ({{ (version.emissionDate || version.createdAt) | humanizeDate }})
                                </option>
                            </app-select>
                        </td>
                    </tr>
                </table>

                <div class="m-2 sm:my-0">
                    <app-button @click="onNewVersion" :disabled="!support.id">
                        {{ $t('commons.newVersion') }}
                    </app-button>
                </div>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="support.name"
                        class="col-span-2"
                        :required="true"
                        @blur="save()"
                    />
                    <app-input-text
                        :label="$t('supports.code')"
                        v-model="support.code"
                        class="col-span-2"
                        @blur="save()"
                    />
                    <app-select :label="$t('supports.type')" v-model="support.type" class="col-span-2" @input="save()">
                        <option></option>
                        <option value="text">{{ $t('supports.types.text') }}</option>
                        <option value="graphic">{{ $t('supports.types.graphic') }}</option>
                        <option value="other">{{ $t('supports.types.other') }}</option>
                    </app-select>

                    <app-date-input
                        class="col-span-2"
                        v-model="support.emissionDueDate"
                        :label="$t('supports.emissionDueDate')"
                        @input="save()"
                        size="10"
                    />
                    <app-date-input
                        class="col-span-2"
                        v-model="support.emissionDate"
                        :label="$t('supports.emissionDate')"
                        @input="save()"
                        size="10"
                    />

                    <app-bundle-picker
                        class="col-span-2"
                        v-model="support.emitter"
                        :options="bundles"
                        @input="save()"
                    />

                    <app-multi-picker
                        :label="$t('supports.locations')"
                        :title="$t('supports.locations')"
                        v-model="support.locations"
                        :options="locationOptions"
                        class="col-span-2"
                        @input="save()"
                        label-key="fullName"
                    ></app-multi-picker>
                    <div class="flex flex-col" v-if="support.locations.length && support.type === 'graphic'">
                        <app-checkbox
                            v-model="support.sharedWithDeliveryContacts"
                            :label="$t('supports.sharedWithDeliveryContacts')"
                            @input="save()"
                        ></app-checkbox>
                        <app-tips>{{ $t('supports.sharedWithDeliveryContactsTip') }}</app-tips>
                    </div>
                </app-fieldset>
                <app-separator class="col-span-2 my-5" :label="$t('supports.attachments')" />
                <div class="col-span-2 flex justify-between">
                    <app-upload-button
                        ref="upload"
                        icon="icon-paperclip"
                        :label="!support.url ? $t('commons.uploadFile') : $t('supports.replaceDocument')"
                        @select="onUploaded"
                        :name="$t('supports.newAttachment')"
                        class="text-sm offline:hidden"
                        method="PUT"
                        :end-point="`/api/projects/${$route.params.projectId}/supports/${support.id}/attachment`"
                    />
                    <app-button
                        icon="icon-overscan"
                        :disabled="!support.url"
                        :label="$t('commons.fullScreen')"
                        @click="
                            $router.push({
                                name: 'supportAttachmentViewer',
                            })
                        "
                    ></app-button>
                </div>
                <div class="flex items-center col-span-2 max-w-full overflow-hidden">
                    <app-viewport
                        class="w-full mx-5"
                        v-if="
                            support.url && (isImage(support.url) || (isPDF(support.url) && support.type === 'graphic'))
                        "
                    >
                        <app-leaflet-viewer
                            :show-rotation="true"
                            v-if="support.url"
                            :src="support.url"
                            :page-count="support.pageCount"
                            :rotation="support.rotation"
                            @rotate="onRotate"
                            :page="1"
                            ref="leafletViewer"
                        ></app-leaflet-viewer>
                    </app-viewport>
                    <iframe
                        v-if="support.url && support.type === 'text' && isPDF(support.url)"
                        :src="support.url"
                        class="w-full min-h-screen my-5"
                    ></iframe>
                    <app-unknown-viewer
                        v-if="support.url && !isImage(support.url) && !isPDF(support.url)"
                        :document="support"
                        class="w-full"
                    ></app-unknown-viewer>
                </div>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        </ValidationObserver>
    </main>
</template>

<script>
import AppLegend from '../../components/appLegend/AppLegend';
import { error } from '../toasts/toats.service';
import { omit } from '@/services/sanitize.service';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppButton from '../../components/appButton/AppButton';
import AppUploadButton from '../../components/app-uploadButton/AppUploadButton';
import { createSupport, getSupport, removeSupport, updateSupport } from './supports.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppFileLink from '../../components/appFileLink/AppFileLink';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import { updateBreadCrumbs } from '@/state/state';
import AppBackButton from '../../components/appBackButton/AppBackButton';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import AppSeparator from '../../components/appSeparator/AppSeparator';
import locationService from '../../services/location.service';
import AppViewport from '@/components/AppViewport';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppBundlePicker from '@/components/appBundlePicker';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import ReadOnlySupportForm from '@/features/supports/ReadOnlySupportForm';
import { queryProject } from '@/features/projects/projects.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { reportError } from '@/features/tracker/tracker.service';
import { isImage, isPDF } from '@/services/file.service';
import AppUnknownViewer from '@/components/appUnkownViewer/AppUnknownViewer';
import { confirm } from '@/features/dialogs/dialogs.service';
import { getBundles } from '@/features/bundles/bundles.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppTips from '@/components/app-tips/AppTips.vue';

export default {
    components: {
        AppTips,
        AppCheckbox,
        AppUnknownViewer,
        ReadOnlySupportForm,
        AppLeafletViewer,
        AppBundlePicker,
        AppSaveOnLeave,
        AppViewport,
        AppSeparator,
        AppMultiPicker,
        AppBackButton,
        AppQuickActions,
        AppFileLink,
        AppSelect,
        AppUploadButton,
        AppButton,
        AppDateInput,
        AppFooter,
        AppFieldset,
        AppInputText,
        AppLegend,
    },
    created() {
        this.loading = true;
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_supports');
        });
        localStorage.setItem(
            'support.lastVisitedSupportId.' + this.$route.params.projectId,
            this.$route.params.supportId,
        );
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        quickActions() {
            const result = [];
            if (
                this.support &&
                this.support.versions &&
                this.support.versions[this.support.versions.length - 1].id === this.support.id
            ) {
                result.push({
                    name:
                        this.support.versions.length === 1
                            ? this.$t('commons.removeThisGroup')
                            : this.$t('commons.removeThisVersion'),
                    run: async () => {
                        if (await confirm(this.$t('commons.confirmMessage'))) {
                            await removeSupport(this.$route.params.projectId, this.$route.params.supportId);
                            if (this.support.versions.length > 1) {
                                await this.$router.push({
                                    name: 'support',
                                    params: {
                                        projectId: this.$route.params.projectId,
                                        supportId: this.support.versions[this.support.versions.length - 1].id,
                                    },
                                });
                            } else {
                                await this.$router.push({
                                    name: 'supports',
                                    params: {
                                        projectId: this.$route.params.projectId,
                                    },
                                });
                            }
                        }
                    },
                });
            }
            return result;
        },
    },
    methods: {
        isImage,
        isPDF,
        init() {
            this.loading = true;
            this.subscriptions = [
                combineLatest([
                    getSupport(this.$route.params.projectId, this.$route.params.supportId),
                    getLocationsTree(this.$route.params.projectId),
                    getBundles(this.$route.params.projectId),
                ]).subscribe({
                    next: async ([support, folders, bundles]) => {
                        this.bundles = bundles;
                        this.locationOptions = locationService
                            .buildLocationOptions(folders)
                            .filter((location) => location.type === 'zone');
                        const locationMap = locationService.getLocationMap(folders);
                        this.support = {
                            ...support,
                            locations: support.locationIds.map((id) => locationMap[id]),
                            emitter: bundles.find((bundle) => bundle.id === support.emitterId),
                        };
                        updateBreadCrumbs({
                            supportName: this.support.name,
                        });
                        this.loading = false;
                    },
                    error: async (err) => {
                        error(this.$t('errors.UNKNOWN_ELEMENT'));
                        await reportError(err);
                        await this.$router.push({
                            name: 'supports',
                            params: {
                                projectId: this.$route.params.projectId,
                            },
                        });
                    },
                }),
            ];
        },
        async onRotate(rotation) {
            this.support.rotation = rotation;
            this.save();
        },
        async onVersionSelected(supportId) {
            await this.$router.push({
                name: 'support',
                params: {
                    projectId: this.$route.params.projectId,
                    supportId,
                },
            });
        },
        async onNewVersion() {
            const supportEntity = {
                groupId: this.support.groupId,
                name: this.support.name,
                type: this.support.type,
                code: this.support.code,
                emitterId: this.support.emitter ? this.support.emitter.id : null,
                locationIds: this.support.locations.map((location) => location.id),
            };
            const result = await createSupport(this.$route.params.projectId, supportEntity);
            this.$refs.observer.reset();
            await this.$router.push({
                name: 'support',
                params: {
                    projectId: this.$route.params.projectId,
                    supportId: result.id,
                },
            });
        },
        backToList() {
            this.$router.push({ name: 'supports', hash: '#uuid_' + this.support.id });
        },
        onUploaded(fileResult) {
            this.support.fileName = fileResult.name;
            this.support.url = fileResult.url;
            if (!this.support.emissionDate) {
                this.support.emissionDate = new Date();
            }
            return this.save();
        },
        async save() {
            const supportEntity = {
                ...omit(this.support, ['emitter', 'locations', 'versions']),
                emitterId: this.support.emitter ? this.support.emitter.id : null,
                locationIds: this.support.locations ? this.support.locations.map((item) => item.id) : [],
            };
            await updateSupport(this.$route.params.projectId, supportEntity);
            this.$refs.observer.reset();
        },
    },
    data() {
        return {
            loading: true,
            readOnly: true,
            bundles: [],
            subscriptions: [],
            locationOptions: [],
            support: {
                name: this.$t('supports.newName'),
                type: null,
                emissionDate: new Date(),
                emitter: null,
                locationIds: [],
            },
        };
    },
};
</script>
