import compareAsc from 'date-fns/compareAsc';
import { getMapBy, getMapById, groupBy } from '@/services/sanitize.service';
import { applyDelay, applyDuration, differenceInWorkingDays, getDuration } from '@/services/duration.service';
import compareDesc from 'date-fns/compareDesc';
import { getCriticalPaths } from '@/features/tasks/criticalPath.service';
import { combineLatest, map } from 'rxjs';
import { getTasks as getDBTasks } from '@/features/tasks/tasks.service';
import { getServices } from '@/features/services/services.service';
import { getProject } from '@/features/projects/projects.service';
import { getLastReference } from '@/features/planning/references/references.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { computeCycleDetection } from '@/features/tasks/cycleDetector.service';
import { getCachedObservable } from '@/rxdb/observablesCache';

export function getPlannedTasks(projectId, refDateObservable) {
    const key = 'getPlannedTasks_' + projectId;
    return getCachedObservable(key, () =>
        combineLatest([
            refDateObservable,
            getProject(projectId),
            getDBTasks(projectId),
            getServices(projectId),
            getLastReference(projectId),
            getBundleMap(projectId),
            getCalendar(projectId),
            getLocationsTree(projectId),
        ]).pipe(
            map(([refDate, project, tasks, services, reference, bundleMap, calendar, folders]) => {
                tasks = tasks.map((task) => ({ ...task }));
                const locationMap = locationService.getLocationMap(folders);
                console.time('getTasks');
                const taskMapByServiceId = getMapBy(groupBy(tasks, 'serviceId'), (group) =>
                    group[0] ? group[0].serviceId : 'null',
                );
                const serviceWithTaskMap = getMapById(
                    services
                        .map((service) => ({
                            ...service,
                            tasks: taskMapByServiceId[service.id],
                            bundle: bundleMap[service.bundleId],
                        }))
                        .filter((service) => !!service.bundle),
                );
                let normalizedTasks = tasks
                    .filter((task) => !!serviceWithTaskMap[task.serviceId] && !!locationMap[task.locationId])
                    .map((task) => {
                        task.service = serviceWithTaskMap[task.serviceId];
                        task.name = task.service.name;
                        task.index = task.service.index;
                        task.location = locationMap[task.locationId];
                        addServicePredecessors(serviceWithTaskMap, task);
                        resetComputed(project.startDate, task);
                        return task;
                    })
                    .filter((task) => !!task.service);
                computeDates(normalizedTasks, calendar, refDate);
                addReferenceDates(normalizedTasks, reference, calendar, refDate);
                console.timeEnd('getTasks');
                return normalizedTasks;
            }),
        ),
    );
}

export function addServicePredecessors(serviceMap, task) {
    const resultAsTaskPredecessors = task.predecessors ? [...task.predecessors] : [];
    for (const predecessor of task.service.predecessors) {
        const service = serviceMap[predecessor.serviceId];
        if (predecessor.serviceId === task.serviceId) {
            console.warn('loop with service ' + task.serviceId + ' ' + service?.name);
        } else if (task.locationId && !task.ignoredServicePredecessorIds.includes(predecessor.serviceId)) {
            if (service && service.tasks) {
                const predecessorTask = service.tasks.find((aTask) => aTask.locationId === task.locationId);
                if (predecessorTask && predecessorTask.locationId === task.locationId) {
                    resultAsTaskPredecessors.unshift({ ...predecessor, taskId: predecessorTask.id });
                }
            }
        }
    }
    task.allPredecessors = resultAsTaskPredecessors;
}

export function resetComputed(projectStartDate, task) {
    const XSPredecessors = getXSPredecessor(task.allPredecessors);
    const hasXSPredecessor = XSPredecessors.length > 0;
    const hasEEPredecessor = task.allPredecessors.length > XSPredecessors.length;
    if (hasXSPredecessor) {
        task.startDate = null;
        task.hasXSPredecessor = true;
    } else if (!task.startDate) {
        task.startDate = projectStartDate;
    }
    task.endDate = null;
    if (hasEEPredecessor && hasXSPredecessor) {
        task.duration = null;
    }
    if (hasEEPredecessor && !hasXSPredecessor) {
        task.startDate = null;
    }
    if (hasEEPredecessor) {
        task.hasEEPredecessor = true;
    }
}

export function getEEPredecessor(predecessors) {
    return predecessors ? predecessors.filter((predecessor) => predecessor.type === 'EE') : [];
}
export function getXSPredecessor(predecessors) {
    return predecessors
        ? predecessors.filter((predecessor) => predecessor.type === 'ES' || predecessor.type === 'SS')
        : [];
}
export function computeDates(tasks, offDays, refDate) {
    console.time('computeDates');
    fillEndDateFromDuration(tasks, offDays, refDate);
    fillExpectedStartDates(tasks, offDays);
    const taskMapById = getMapById(tasks);
    console.time('computeDates/recursiveCompute');
    recursiveCompute(
        tasks.filter((task) => !task.startDate),
        tasks.filter((task) => !task.endDate),
        taskMapById,
        offDays,
        refDate,
    );
    console.timeEnd('computeDates/recursiveCompute');
    console.time('computeDates/fillDurations');
    for (const task of tasks) {
        fillDurations(task, offDays);
        logErrors(task);
        task.expectedProgress = getExpectedProgress(task, offDays, refDate);
        task.late = getLate(task, offDays, refDate);
        fillEstimatedRealDurations(task, offDays);
    }
    console.timeEnd('computeDates/fillDurations');
    console.time('computeDates/computeCriticalPath');
    computeCriticalPath(tasks, taskMapById);
    computeCycleDetection(tasks, taskMapById);
    console.timeEnd('computeDates/computeCriticalPath');
    console.timeEnd('computeDates');
}
function computeCriticalPath(tasks, taskMapById) {
    const branches = getCriticalPaths(tasks, taskMapById);
    for (const branch of branches) {
        for (const task of branch) {
            task.isCritical = true;
            task.criticalLate = task.late;
        }
    }
}
function logErrors(task) {
    if (task.isError) {
        console.log(task.id, task.name || task.service.name, task.errorMessage);
    }
}
export function getEndDate(task, agenda = [], reportDate) {
    if (task.realEndDate) {
        return task.realEndDate;
    } else {
        let duration =
            task.realDuration ||
            task.duration ||
            getDuration(task.endDate, task.startDate, agenda, task.service ? task.service.bundleId : null);
        const startDate = task.realStartDate || task.startDate;
        const couldBeLate = reportDate && startDate < reportDate;
        if (couldBeLate && !task.realDuration) {
            const remainingDuration = ((100 - (task.progress || 0)) / 100) * duration + 1;
            return applyDuration(reportDate, remainingDuration, agenda, task.service ? task.service.bundleId : null);
        } else {
            return applyDuration(startDate, duration, agenda, task.service ? task.service.bundleId : null);
        }
    }
}

export function isPendingTask(task, fromDate, toDate) {
    const startDate = task.realStartDate || task.startDate;
    const endDate = task.realEndDate || task.endDate;
    return (
        (fromDate <= startDate && startDate <= toDate) ||
        (fromDate <= endDate && endDate <= toDate) ||
        (startDate <= fromDate && toDate <= endDate) ||
        (endDate <= fromDate && !task.realEndDate)
    );
}

export function fillDurations(task, agenda) {
    let duration;
    if (task.duration) {
        duration = task.duration;
    } else if (task.endDate && task.startDate) {
        duration = getDuration(task.endDate, task.startDate, agenda, task.service ? task.service.bundle.id : null);
    } else {
        duration = null;
    }
    if (duration != null && duration > 0) {
        task.duration = duration;
    } else if (duration != null && duration <= 0) {
        task.duration = null;
        task.isError = true;
        task.errorMessage = 'start-before-end';
    } else {
        task.duration = null;
    }
    return task.duration;
}
export function fillEstimatedRealDurations(task, agenda) {
    let estimatedRealDuration;
    if (!task.realStartDate) {
        estimatedRealDuration = task.duration;
    } else {
        estimatedRealDuration =
            getDuration(
                task.estimatedEndDate,
                task.realStartDate || task.startDate,
                agenda,
                task.service ? task.service.bundle.id : null,
            ) + 1;
    }
    task.estimatedRealDuration = estimatedRealDuration;
}

export function fillStartDatesFromXSPredecessors(task, taskMapById, agenda) {
    const XSPredecessors = getXSPredecessor(task.allPredecessors);
    const resolvedXSPredecessors = XSPredecessors.filter((predecessor) => {
        if (!taskMapById[predecessor.taskId]) {
            task.isError = true;
            task.errorMessage = 'missing-predecessor-error';
            task.detail = predecessor.taskId;
            return false;
        }
        return (
            (taskMapById[predecessor.taskId] && predecessor.type === 'ES' && taskMapById[predecessor.taskId].endDate) ||
            (predecessor.type === 'SS' && taskMapById[predecessor.taskId].startDate)
        );
    });
    if (XSPredecessors.length > 0 && resolvedXSPredecessors.length === XSPredecessors.length) {
        const dates = resolvedXSPredecessors
            .map((predecessor) => {
                const task = taskMapById[predecessor.taskId];
                if (task) {
                    if (predecessor.type === 'ES') {
                        const endDate = task.estimatedEndDate;
                        return applyDelay(endDate, (predecessor.delay || 0) + 1, agenda, task.service.bundle.id);
                    } else {
                        const startDate = task.realStartDate || task.startDate;
                        return applyDelay(startDate, predecessor.delay || 0, agenda, task.service.bundle.id);
                    }
                }
            })
            .sort(compareAsc);
        task.startDate = dates.pop();
        const expectedStartDates = resolvedXSPredecessors
            .map((predecessor) => {
                const task = taskMapById[predecessor.taskId];
                if (task) {
                    if (predecessor.type === 'ES') {
                        const endDate = task.expectedEndDate;
                        return applyDelay(endDate, (predecessor.delay || 0) + 1, agenda, task.service.bundle.id);
                    } else {
                        const startDate = task.expectedStartDate;
                        return applyDelay(startDate, predecessor.delay || 0, agenda, task.service.bundle.id);
                    }
                }
            })
            .sort(compareAsc);
        task.expectedStartDate = expectedStartDates.pop();
    }
}

export function fillStartDatesFromEEPredecessors(task, agenda, refDate) {
    const EEPredecessors = getEEPredecessor(task.allPredecessors);
    const hasOnlyEE = EEPredecessors.length > 0 && task.allPredecessors.length === EEPredecessors.length;
    if (hasOnlyEE && task.endDate) {
        if (task.duration) {
            task.startDate = applyDuration(task.endDate, -task.duration, agenda, task.service.bundle.id);
            task.estimatedEndDate = getEndDate(task, agenda, refDate);
            task.expectedStartDate = applyDuration(
                task.expectedEndDate,
                -task.duration,
                agenda,
                task.service.bundle.id,
            );
        } else {
            task.isError = true;
            task.errorMessage = 'predecessor-error';
        }
    }
}

export function fillEndDatesFromEEPredecessors(task, taskMapById, agenda) {
    const EEPredecessors = getEEPredecessor(task.allPredecessors);
    const resolvedEEPredecessors = EEPredecessors.filter((predecessor) => {
        if (!taskMapById[predecessor.taskId]) {
            task.isError = true;
            task.errorMessage = 'missing-predecessor-error';
            task.detail = predecessor.taskId;
        }
        return taskMapById[predecessor.taskId] && taskMapById[predecessor.taskId].endDate;
    });

    const dates = resolvedEEPredecessors
        .map((predecessor) => {
            const endDate = taskMapById[predecessor.taskId].estimatedEndDate;
            return applyDelay(endDate, predecessor.delay, agenda, task.service.bundle.id);
        })
        .sort(compareAsc);
    task.endDate = dates.pop();
    const expectedEndDates = resolvedEEPredecessors
        .map((predecessor) => {
            const endDate = taskMapById[predecessor.taskId].expectedEndDate;
            return applyDelay(endDate, predecessor.delay, agenda, task.service.bundle.id);
        })
        .sort(compareAsc);
    task.expectedEndDate = expectedEndDates.pop();
    task.estimatedEndDate = task.endDate;
    if (task.startDate && task.startDate > task.endDate) {
        task.endDate = applyDuration(task.startDate, task.duration || 1, agenda, task.service.bundle.id);
        task.isError = true;
        task.errorMessage = 'ee-before-start';
    }
}

function recursiveCompute(notSolvedStart, notSolvedEnd, taskMapById, agenda, refDate) {
    const stillNotStartSolved = [];
    for (const task of notSolvedStart) {
        fillStartDatesFromXSPredecessors(task, taskMapById, agenda);
        fillStartDatesFromEEPredecessors(task, agenda, refDate);
        if (!task.startDate) {
            stillNotStartSolved.push(task);
        }
    }
    const stillNotEndSolved = [];
    for (const task of notSolvedEnd) {
        fillEndDatesFromEEPredecessors(task, taskMapById, agenda);
        fillEndDateFromDuration([task], agenda, refDate);
        if (!task.endDate) {
            stillNotEndSolved.push(task);
        }
    }
    if (
        (stillNotStartSolved.length > 0 || stillNotEndSolved.length > 0) &&
        (stillNotEndSolved.length !== notSolvedEnd.length || stillNotStartSolved.length !== notSolvedStart.length)
    ) {
        recursiveCompute(stillNotStartSolved, stillNotEndSolved, taskMapById, agenda, refDate);
    }
}

export function fillEndDateFromDuration(tasks, agenda, refDate) {
    for (const task of tasks) {
        const EEPredecessors = getEEPredecessor(task.allPredecessors);
        if (task.startDate && EEPredecessors.length === 0) {
            if (task.duration) {
                task.endDate = applyDuration(task.startDate, task.duration, agenda, task.service.bundleId);
                task.estimatedEndDate = getEndDate(task, agenda, refDate);
                task.expectedEndDate = applyDuration(
                    task.expectedStartDate,
                    task.duration,
                    agenda,
                    task.service.bundleId,
                );
            } else {
                task.isError = true;
                task.errorMessage = 'missing-duration';
            }
        }
    }
}
export function fillExpectedStartDates(tasks, agenda) {
    for (const task of tasks) {
        if (getXSPredecessor(task.allPredecessors).length === 0) {
            task.expectedStartDate = task.startDate;
            task.expectedEndDate = applyDuration(task.startDate, task.duration || 1, agenda, task.service.bundleId);
        }
    }
}

export function addReferenceDates(tasks, selectedReference, agenda, ref) {
    if (selectedReference && selectedReference.dates) {
        const referenceDatesByTaskId = {};
        for (const referenceDates of selectedReference.dates) {
            referenceDatesByTaskId[referenceDates.taskId] = referenceDates;
        }
        for (const task of tasks) {
            const reference = referenceDatesByTaskId[task.id];
            if (reference && reference.startDate && reference.endDate) {
                task.referenceStartDate = reference.startDate ? new Date(reference.startDate) : null;
                task.referenceEndDate = reference.endDate ? new Date(reference.endDate) : null;
                task.referenceDuration =
                    reference.endDate && reference.startDate
                        ? getDuration(
                              task.referenceEndDate,
                              task.referenceStartDate,
                              agenda,
                              task.service ? task.service.bundleId : null,
                          )
                        : null;
                task.referenceProgress = getReferenceProgress(task, agenda, ref);
                task.referenceLate =
                    getDuration(task.estimatedEndDate, task.referenceEndDate, agenda, task.service.bundleId) - 1;
            } else {
                task.referenceStartDate = null;
                task.referenceEndDate = null;
                task.referenceDuration = null;
            }
        }
    } else {
        for (const task of tasks) {
            task.referenceStartDate = null;
            task.referenceEndDate = null;
        }
    }
}

export function getSuccessorIds(task, tasks, alreadySeenMap) {
    const result = [];
    if (!alreadySeenMap) alreadySeenMap = {};
    if (alreadySeenMap[task.id]) {
        return [];
    } else {
        alreadySeenMap[task.id] = true;
    }
    for (const candidate of tasks) {
        for (const predecessor of candidate.allPredecessors) {
            if (predecessor.taskId === task.id) {
                result.push(candidate.id);
                result.push.apply(result, getSuccessorIds(candidate, tasks, alreadySeenMap));
            }
        }
    }
    return result;
}
export function getPredecessorIds(task, tasks, alreadySeenMap, taskMap) {
    const result = [];
    if (!alreadySeenMap) alreadySeenMap = {};
    if (alreadySeenMap[task.id]) {
        return [];
    } else {
        alreadySeenMap[task.id] = true;
    }
    if (!taskMap) {
        taskMap = getMapById(tasks);
    }
    for (const predecessor of task.allPredecessors) {
        if (!alreadySeenMap[predecessor.taskId]) {
            result.push(predecessor.taskId);
        }
        result.push.apply(result, getPredecessorIds(taskMap[predecessor.taskId], tasks, alreadySeenMap, taskMap));
    }
    return result;
}
export function getDirectSuccessors(predecessorTask, tasks) {
    return tasks.filter(
        (task) => !!task.allPredecessors.find((predecessor) => predecessor.taskId === predecessorTask.id),
    );
}
export function getServiceSuccessorIds(service, services, alreadySeenMap) {
    const result = [];
    if (!alreadySeenMap) alreadySeenMap = {};
    if (alreadySeenMap[service.id]) {
        return [];
    } else {
        alreadySeenMap[service.id] = true;
    }
    for (const candidate of services) {
        for (const predecessor of candidate.predecessors) {
            if (predecessor.serviceId === service.id) {
                result.push(candidate.id);
                result.push.apply(result, getServiceSuccessorIds(candidate, services, alreadySeenMap));
            }
        }
    }
    return result;
}

export function getFirstExecutionTask(planning, bundle, services, locations) {
    return filterTasksByLocations(planning, bundle, services, locations)
        .sort((taskA, taskB) =>
            compareDesc(taskA.realStartDate || taskA.startDate, taskB.realStartDate || taskB.startDate),
        )
        .pop();
}

export function getLastExecutionTask(planning, bundle, services, locations) {
    return filterTasksByLocations(planning, bundle, services, locations)
        .sort((taskA, taskB) => compareAsc(taskA.estimatedEndDate, taskB.estimatedEndDate))
        .pop();
}

export function filterTasksByLocations(planning, bundle, services, locations) {
    const candidateTaskAsLastExecutionTask = [];
    for (const task of planning) {
        const bundleNotMatch = bundle && task.service.bundleId !== bundle.id;
        const serviceNotMatch =
            services &&
            services.length > 0 &&
            (!task.service || !services.find((service) => service.id === task.service.id));
        const locationNotMatch =
            locations &&
            locations.length > 0 &&
            (!task.location || !locations.find((location) => location.id === task.location.id));
        if (!(bundleNotMatch || serviceNotMatch || locationNotMatch)) {
            candidateTaskAsLastExecutionTask.push(task);
        }
    }
    return candidateTaskAsLastExecutionTask;
}

export function getExpectedProgress(task, offDays, ref = new Date()) {
    if (task.startDate > ref) {
        return 0;
    } else if (task.endDate <= ref) {
        return 100;
    } else if (task.startDate) {
        return Math.min(
            100,
            Math.round((getDuration(ref, task.startDate, offDays, task.service.bundleId) / task.duration) * 100),
        );
    } else {
        return null;
    }
}
export function getReferenceProgress(task, offDays, ref = new Date()) {
    if (task.referenceStartDate > ref) {
        return 0;
    } else if (task.referenceEndDate <= ref) {
        return 100;
    } else {
        return Math.min(
            100,
            Math.round(
                (getDuration(ref, task.referenceStartDate, offDays, task.service.bundleId) / task.referenceDuration) *
                    100,
            ),
        );
    }
}
export function getLate(task, offDays, ref = new Date()) {
    if (!task.realStartDate && ref && task.startDate > ref) {
        return 0;
    } else if (task.estimatedEndDate < (task.realEndDate || task.endDate)) {
        return -(
            differenceInWorkingDays(task.endDate, task.estimatedEndDate, offDays, task.service.bundleId) +
            task.duration -
            1
        );
    } else {
        return differenceInWorkingDays(task.estimatedEndDate, task.endDate, offDays, task.service.bundleId);
    }
}
export default {
    addReferenceDates,
    getEndDate,
    getSuccessorIds,
    getFirstExecutionTask,
    getLate,
    getDirectSuccessors,
};
