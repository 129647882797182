<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start">
        <div class="w-full">
            <div
                class="flex w-full items-start sm:flex-row gap-2"
                :class="{ 'my-2': !filterFn }"
                v-if="$slots.toolbar || newItemLabel"
            >
                <app-button class="" @click="$emit('newItem')" :label="newItemLabel" v-if="newItemLabel && !disabled" />
                <slot name="toolbar" :items="items" :filteredItems="filteredItems" />
            </div>
            <template v-if="filterFn">
                <app-filter
                    ref="filter"
                    v-if="!filterOptions"
                    class="my-2 w-full"
                    v-model="filterAsString"
                ></app-filter>
                <app-multi-picker
                    ref="filter"
                    v-else
                    icon="icon-magnify"
                    :allowStringCriteria="true"
                    class="w-full my-2"
                    v-model="filterAsArray"
                    :options="filterOptions"
                    @input="$emit('filterChange', filterAsArray)"
                    :strictMatching="strictMatching"
                >
                    <template v-slot:option="{ option }">
                        <slot :option="option" name="option"></slot>
                    </template>
                </app-multi-picker>
            </template>
            <slot name="headers" />
        </div>
        <div class="flex w-full flex-grow overflow-hidden" :class="{ 'flex-col': !verticalSplit }">
            <slot name="beforeList" :filteredItems="filteredItems" />
            <slot name="list" :filteredItems="filteredItems">
                <ul
                    class="flex flex-col flex-grow overflow-hidden"
                    :class="{
                        'w-full': !verticalSplit,
                        'max-w-1/2': verticalSplit,
                    }"
                >
                    <li v-if="items.length === 0" class="text-center italic text-gray-700 text-sm">
                        {{ $t('commons.emptyList') }}
                    </li>
                    <li
                        v-if="items.length > 0 && filteredItems.length === 0"
                        class="text-center italic text-gray-700 text-sm"
                    >
                        <span v-if="!filterOptions">{{ $t('commons.emptyMatchesString') }} "{{ filterAsString }}"</span>
                        <span v-else>{{ $t('commons.emptyMatches') }}</span>
                    </li>
                    <virtualized-list
                        :items="filteredItems"
                        :bench="30"
                        :item-height="lineHeight"
                        outerContainerEl="ul"
                        innerContainerEl="li"
                        class="mb-24 flex-grow"
                    >
                        <template v-slot="{ item, index }">
                            <li
                                class="border p-2 flex flex-col"
                                :class="{ 'bg-gray-300': index === selectedItemIndex, 'hover:bg-gray-200': !disabled }"
                                @click="onClick(item)"
                                :style="'height: ' + lineHeight + 'px; max-height: ' + lineHeight + 'px'"
                            >
                                <a :id="'uuid_' + item.id"></a>
                                <div class="flex justify-between">
                                    <slot name="item" :item="item"></slot>
                                    <div class="flex items-center justify-end" v-if="removable && !disabled">
                                        <app-button
                                            size="mini"
                                            @click="$emit('removeItem', item)"
                                            variant="danger"
                                            aria-label="remove item"
                                            icon="icon-trash-can-outline"
                                        />
                                    </div>
                                </div>
                            </li>
                        </template>
                    </virtualized-list>
                </ul>
            </slot>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()" v-if="!disabled"></app-quick-actions>
    </main>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AppFilter from '../../components/appFilter/AppFilter';
import AppMultiPicker from '../appMultiPicker/AppMultiPicker';
import VirtualizedList from 'vue-virtualized-list';

export default {
    props: {
        verticalSplit: { type: Boolean, default: false },
        newItemLabel: String,
        quickActions: { type: Array, default: () => [] },
        items: { type: Array, default: () => [] },
        filterOptions: { type: Array, default: () => null },
        filterFn: { type: Function },
        filterValue: { type: Array, default: () => null },
        removable: { type: Boolean, default: true },
        strictMatching: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        lineHeight: { type: Number, default: 98 },
    },
    components: { AppMultiPicker, AppFilter, AppQuickActions, AppButton, VirtualizedList },
    created() {
        document.addEventListener('keyup', this.onKeyUp);
        const storedFilter = this.filterValue || this.getStoredFilter();
        this.filterAsString = !this.filterOptions ? storedFilter || '' : '';
        this.filterAsArray = this.filterOptions ? storedFilter || [] : [];
    },
    watch: {
        $route() {
            this.initSelectionIndexFromHash();
        },
        filterValue(filterValue) {
            this.filterAsString = !this.filterOptions ? filterValue || '' : '';
            this.filterAsArray = this.filterOptions ? filterValue || [] : [];
        },
    },
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
        document.removeEventListener('keyup', this.onKeyUp);
    },
    methods: {
        async initSelectionIndexFromHash() {
            if (window.location.hash && window.location.hash.length > 1) {
                this.selectedItemIndex = this.filteredItems.indexOf(
                    this.items.find((item) => item.id === window.location.hash.substring('#uuid_'.length)),
                );
            }
        },
        async updateHash() {
            const selectionId = this.filteredItems[this.selectedItemIndex]
                ? this.filteredItems[this.selectedItemIndex].id
                : undefined;
            window.location.hash = '#uuid_' + selectionId;
        },
        onKeyUp(event) {
            if (event.key.length === 1 && event.target.nodeName === 'BODY' && this.$refs.filter) {
                this.$refs.filter.focus(event.key);
                return;
            }
            if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
                return;
            }
            if (this.disabled) {
                return;
            }
            if (
                (this.$refs.filter && this.$refs.filter.$el.contains(event.target)) ||
                event.target.nodeName === 'BODY'
            ) {
                if (event.code === 'ArrowDown') {
                    this.onDown();
                    event.preventDefault();
                } else if (event.code === 'ArrowUp') {
                    this.onUp();
                    event.preventDefault();
                } else if (event.code === 'Enter') {
                    this.$emit('navigateTo', this.filteredItems[this.selectedItemIndex]);
                } else if (event.code === 'Esc') {
                    this.$emit('esc');
                }
            }
        },
        onClick(item) {
            if (!this.disabled) {
                this.selectedItemIndex = this.filteredItems.indexOf(this.items.find((item) => item.id === item.id));
                this.$emit('navigateTo', item);
            }
        },
        onDown() {
            if (this.selectedItemIndex < this.filteredItems.length - 1) {
                this.selectedItemIndex++;
            } else {
                this.selectedItemIndex = 0;
            }
            this.updateHash();
        },
        onUp() {
            if (this.selectedItemIndex > 0) {
                this.selectedItemIndex--;
            } else {
                this.selectedItemIndex = this.filteredItems.length - 1;
            }
            this.updateHash();
        },
        storeFilter(criteria) {
            if (criteria) {
                localStorage.setItem(this.$route.name + '_' + this.$route.params.projectId, JSON.stringify(criteria));
            } else {
                localStorage.removeItem(this.$route.name + '_' + this.$route.params.projectId);
            }
        },
        getStoredFilter() {
            const filter = localStorage.getItem(this.$route.name + '_' + this.$route.params.projectId);
            if (filter) {
                return JSON.parse(filter);
            } else {
                return null;
            }
        },
    },
    computed: {
        filteredItems() {
            this.$nextTick(() => {
                this.initSelectionIndexFromHash();
                if (window.location.hash && window.location.hash.length > 0) {
                    const element = this.$el.querySelector(window.location.hash);
                    if (element) element.scrollIntoView();
                }
            });
            if (!this.filterOptions && this.filterAsString !== undefined) {
                this.storeFilter(this.filterAsString);
                return this.filterFn ? this.filterFn(this.filterAsString) : this.items;
            } else {
                this.storeFilter(this.filterAsArray);
                return this.filterFn ? this.filterFn(this.filterAsArray) : this.items;
            }
        },
    },
    data() {
        return {
            filterAsString: !this.filterOptions ? this.filterValue || '' : '',
            filterAsArray: this.filterOptions ? this.filterValue || [] : [],
            selectedItemIndex: null,
        };
    },
};
</script>
