import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const tasks = {
    schema: {
        title: 'task schema',
        version: 3,
        description: 'describes a task',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: {
                type: 'string',
            },
            locationId: {
                type: ['string', 'null'],
            },
            serviceId: {
                type: 'string',
            },
            ignoredServicePredecessorIds: {
                type: 'array',
                items: { type: 'string' },
            },
            predecessors: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        type: { type: 'string' },
                        delay: { type: 'number' },
                        taskId: { type: 'string' },
                    },
                },
            },
            quantity: {
                type: ['number', 'null'],
            },
            duration: {
                type: ['number', 'null'],
            },
            realDuration: {
                type: ['number', 'null'],
            },
            progress: {
                type: ['number', 'null'],
            },
            startDate: {
                type: ['string', 'null'],
            },
            endDate: {
                type: ['string', 'null'],
            },
            realStartDate: {
                type: ['string', 'null'],
            },
            realEndDate: {
                type: ['string', 'null'],
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    autoMigrate: false,
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: function (oldDoc) {
            return oldDoc;
        },
        2: function (oldDoc) {
            return oldDoc;
        },
        3: function (oldDoc) {
            return oldDoc;
        },
    },
};
