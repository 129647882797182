import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getMapById } from '@/services/sanitize.service';
import autoControlsService, { getAutoControls } from '@/features/autoControls/autoControls.service';
import receptionsService, { getReceptions } from '@/features/receptions/receptions.service';
import certificatesService, { getCertificates } from '@/features/certificates/certificates.service';
import tasksService from '@/features/tasks/tasks.service';
import servicesService from '@/features/services/services.service';
import directoriesService from '@/features/services/directories.service';

export function getDescendantServices(node) {
    if (node.__typename === 'Service') {
        return [node];
    } else {
        return node.children.reduce((acc, child) => [...acc, ...getDescendantServices(child)], []);
    }
}
export function getDescendantDirectories(node) {
    if (node.__typename === 'Service') {
        return [];
    } else {
        return node.children.reduce((acc, child) => [...acc, node, ...getDescendantDirectories(child)], []);
    }
}

export async function getCascadeImpacts(projectId, node) {
    const services = getDescendantServices(node);
    const directories =
        node.__typename === 'Directory' ? [node, ...getDescendantDirectories(node)] : getDescendantDirectories(node);
    const serviceMap = getMapById(services);
    const plannedTasks = await firstValueFrom(getPlannedTasks(projectId, new BehaviorSubject(new Date())));
    const directTasks = plannedTasks
        .filter((task) => serviceMap[task.serviceId])
        .map((task) => ({
            ...task,
            label: task.location.fullName + ' > #' + task.service?.bundle?.label + '>' + task.name,
        }));
    const directTasksIds = getMapById(directTasks);
    const linkedTasks = plannedTasks
        .filter((task) => task.predecessors.find((predecessor) => directTasksIds[predecessor.taskId]))
        .map((task) => ({ ...task, label: task.location.fullName + ' > ' + task.name }));
    const autoControls = (await firstValueFrom(getAutoControls(projectId))).filter(
        (element) => serviceMap[element.serviceId],
    );
    const receptions = (await firstValueFrom(getReceptions(projectId))).filter(
        (element) => serviceMap[element.serviceId],
    );
    const certificates = (await firstValueFrom(getCertificates(projectId))).filter(
        (element) => serviceMap[element.serviceId],
    );
    return {
        directories,
        receptions,
        autoControls,
        certificates,
        services,
        directTasks,
        linkedTasks,
        serviceMap,
    };
}

export function removeCascade(
    projectId,
    { receptions, autoControls, certificates, services, directTasks, linkedTasks, serviceMap, directories },
) {
    const toBeRemovedTaskIdMap = getMapById(directTasks);
    return Promise.all([
        servicesService.bulkDelete(
            projectId,
            services.map((service) => service.id),
        ),
        directoriesService.bulkDelete(
            projectId,
            directories.map((directory) => directory.id),
        ),
        tasksService.bulkRemoveTasks(
            projectId,
            directTasks.map((task) => task.id),
        ),
        ...linkedTasks.map((task) =>
            tasksService
                .filterTaskPredecessor(projectId, task, (predecessor) => !toBeRemovedTaskIdMap[predecessor.taskId])
                .catch((e) => e),
        ),
        ...receptions.map((reception) =>
            receptionsService.updateReception(projectId, { id: reception.id, serviceId: null, locationIds: [] }),
        ),
        ...autoControls.map((autoControl) =>
            autoControlsService.filterServiceIds(projectId, autoControl, (serviceId) => !serviceMap[serviceId]),
        ),
        ...certificates.map((certificate) => {
            return certificatesService.updateCertificate(projectId, { id: certificate, serviceId: null });
        }),
    ]);
}
