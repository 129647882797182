import Vue from 'vue';
import { upperFirst } from '../services/sanitize.service';

// eslint-disable-next-line no-undef
const requireComponent = require.context('.', false, /\.vue$/);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''),
    );

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig,
    );
});
