var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('h2',{staticClass:"text-xs font-bold"},[_vm._v(_vm._s(_vm.$t('project.editions.preparationList.legend.title')))]),_c('div',{staticClass:"text-xs border w-full flex flex-col sm:flex-row sm:justify-between gap-1 p-1"},[_c('div',{staticClass:"flex sm:flex-row items-center"},[_c('icon-timelapse',{staticClass:"text-secondary"}),_vm._v("\n            "+_vm._s(_vm.$t('project.editions.preparationList.legend.incompleteVisa'))+"\n        ")],1),_c('div',{staticClass:"flex sm:flex-row items-center"},[_c('icon-check-circle-outline',{staticClass:"text-green-600"}),_c('span',[_vm._v(_vm._s(_vm.$t('project.editions.preparationList.legend.approved')))])],1),_c('div',{staticClass:"flex sm:flex-row items-center"},[_c('icon-alert-circle-outline',{staticClass:"text-yellow-500"}),_c('div',{attrs:{"title":_vm.$t('project.editions.preparationList.legend.approvedWithCommentsTips') +
                    ' ' +
                    _vm.$t('project.editions.preparationList.legend.approvedWithCommentsTips2')}},[_vm._v("\n                "+_vm._s(_vm.$t('project.editions.preparationList.legend.approvedWithComments'))+"\n            ")])],1),_c('div',{staticClass:"flex sm:flex-row items-center"},[_c('icon-close-circle-outline',{staticClass:"text-red-700"}),_c('div',{attrs:{"title":_vm.$t('project.editions.preparationList.legend.rejectedTips') +
                    ' ' +
                    _vm.$t('project.editions.preparationList.legend.rejectedTips2')}},[_vm._v("\n                "+_vm._s(_vm.$t('project.editions.preparationList.legend.rejected'))+"\n            ")])],1),_c('div',{staticClass:"flex sm:flex-row items-center"},[_c('icon-close-octagon',{staticClass:"text-red-700"}),_c('div',{attrs:{"title":_vm.$t('project.editions.preparationList.legend.nonCompliantTips') +
                    ' ' +
                    _vm.$t('project.editions.preparationList.legend.nonCompliantTips2')}},[_vm._v("\n                "+_vm._s(_vm.$t('project.editions.preparationList.legend.nonCompliant'))+"\n            ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }