<template>
    <div class="flex flex-row">
        <div class="flex flex-col border-r">
            <a
                :key="state.id"
                v-for="state in states"
                href="#"
                class="pl-2 pr-4 py-1"
                :class="{
                    'bg-gray-300': currentState === state.id,
                    'hover:bg-gray-200': currentState !== state.id,
                }"
                @click.prevent="changeState(state)"
            >
                {{ state.title }}
            </a>
        </div>
        <div class="flex-grow text-sm overflow-auto" style="max-height: 25vh">
            <ServiceInfo :serviceId="service.serviceId" :readOnly="readOnly" v-if="currentState === 'info'" />
            <ServiceTasks
                v-if="currentState === 'locations'"
                :serviceId="service.serviceId"
                :readOnly="readOnly"
            ></ServiceTasks>
            <ServicePredecessors
                :serviceId="service.serviceId"
                :readOnly="readOnly"
                v-if="currentState === 'predecessors'"
            ></ServicePredecessors>
        </div>
    </div>
</template>

<script>
import TaskPlanningDetail from './TaskPlanningDetail';
import { queryPlanningState, updatePlanningState } from '@/state/state';
import TaskPredecessors from '@/features/planning/planning/TaskPredecessors';
import AppButton from '@/components/appButton/AppButton';
import ServiceTasks from '@/features/services/ServiceTasks';
import ServiceInfo from '@/features/services/ServiceInfo';
import ServicePredecessors from '@/features/services/ServicePredecessors';

export default {
    components: { ServicePredecessors, ServiceInfo, ServiceTasks, AppButton, TaskPredecessors, TaskPlanningDetail },
    props: ['service', 'readOnly'],
    created() {
        this.currentState = queryPlanningState(this.$route.params.projectId).serviceDetailTabOpen || 'info';
    },
    computed: {
        states() {
            return [
                {
                    id: 'info',
                    title: this.$t('project.follow.planning.serviceDetails'),
                },
                {
                    id: 'locations',
                    title:
                        this.$t('project.follow.planning.serviceLocationsDetail') +
                        ' (' +
                        this.service.tasks.length +
                        ')',
                },
                {
                    id: 'predecessors',
                    title:
                        this.$t('project.follow.planning.servicePredecessorDetail') +
                        ' (' +
                        this.service.predecessors.length +
                        ')',
                },
            ];
        },
    },
    data() {
        return {
            currentState: 'info',
        };
    },
    methods: {
        async changeState(state) {
            this.currentState = state.id;
            updatePlanningState(this.$route.params.projectId, {
                serviceDetailTabOpen: state.id,
            });
        },
    },
};
</script>
