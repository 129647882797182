<template>
    <app-slider
        v-model="internalValue"
        :showLabel="showLabel"
        :label="label"
        :required="required"
        :size="size"
        :step="step"
        @input="percentToDates"
    ></app-slider>
</template>
<script>
import AppSlider from '../appSlider/AppSlider';
import differenceInDays from 'date-fns/differenceInDays';
import add from 'date-fns/add';
export default {
    components: { AppSlider },
    props: {
        label: String,
        required: Boolean,
        value: Date,
        size: Number | String,
        min: Date,
        max: Date,
        showLabel: {
            type: Boolean,
            default: true,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            internalValue: 0,
            range: 0,
        };
    },
    created() {
        this.refreshRange();
    },
    watch: {
        value(newDateValue) {
            this.datesToPercent(newDateValue);
        },
        min() {
            this.refreshRange();
        },
        max() {
            this.refreshRange();
        },
    },
    methods: {
        refreshRange() {
            if (this.max && this.min) {
                this.range = differenceInDays(this.max, this.min);
            }
        },
        datesToPercent(newDateValue) {
            if (newDateValue >= this.min && newDateValue <= this.max) {
                this.internalValue =
                    (differenceInDays(newDateValue, this.min) / differenceInDays(this.max, this.min)) * 100;
            } else {
                this.internalValue = 0;
            }
        },
        percentToDates(value) {
            this.$emit('input', add(this.min, { days: Math.round((this.range * value) / 100) }));
        },
    },
};
</script>
