import db from '@/rxdb/database';
import { map } from 'rxjs';

export async function createRequestAttachment(projectId, requestAttachment) {
    const result = await db.getProjectCollections(projectId).requestAttachments.insert({
        projectId,
        id: requestAttachment.id,
        requestId: requestAttachment.requestId,
        name: requestAttachment.url,
        dataUrl: requestAttachment.dataUrl,
        url: requestAttachment.url,
        shapes: requestAttachment.shapes ? JSON.parse(JSON.stringify(requestAttachment.shapes)) : [],
    });
    return result.toJSON();
}

export function getRequestAttachments(projectId) {
    return db
        .getProjectCollections(projectId)
        .requestAttachments.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapRequestAttachment)));
}

function mapRequestAttachment(requestDb) {
    return requestDb ? requestDb.toMutableJSON() : null;
}

export async function updateRequestAttachments(projectId, requestAttachment) {
    const dbRequest = await db
        .getProjectCollections(projectId)
        .requestAttachments.findOne({ selector: { id: requestAttachment.id } })
        .exec();
    return dbRequest.atomicPatch({ shapes: JSON.parse(JSON.stringify(requestAttachment.shapes)) });
}

export function getRequestAttachmentsByRequestId(projectId, requestId) {
    return db
        .getProjectCollections(projectId)
        .requestAttachments.find({ selector: { requestId } })
        .$.pipe(map((items) => items.map(mapRequestAttachment)));
}

export async function removeRequestAttachment(projectId, requestAttachmentId) {
    const entity = await db
        .getProjectCollections(projectId)
        .requestAttachments.findOne({ selector: { id: requestAttachmentId } })
        .exec();
    return entity.remove();
}
