<template>
    <div :class="{ rootOffline: isOffline }" class="root-component">
        <div class="flex flex-col min-h-main items-center justify-center" v-if="!dataLoaded">
            <div class="flex flex-col items-center mb-10 mb-10">
                <img src="/logoAtex.svg" alt="Atex logo" class="w-16 mb-1" />
                <h1 style="letter-spacing: 0.2rem" class="text-center">
                    <b>A</b>
                    <span class="mr-2">ssistance</span>
                    <b>T</b>
                    <span>echnique d'</span>
                    <b>EX</b>
                    <span>écution</span>
                </h1>
            </div>
            {{ $t('project.loading') }}
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
        <template v-else>
            <ToolBar
                class="fixed top-0 w-full z-40"
                :isOpen="isOpen"
                v-on:toggle-menu="onToggleMenu"
                v-if="!$route.query.noToolBar"
            >
                <template v-slot:breadCrumbRoot>
                    <router-link
                        class="mx-2 hidden md:flex"
                        :to="{ name: 'home' }"
                        :aria-label="$t('home.title')"
                        v-if="$route.name !== 'home'"
                    >
                        <icon-home />
                    </router-link>
                </template>
            </ToolBar>
            <div
                class="flex"
                :class="{
                    'aside-opened': isOpen && $route.meta.hideMenu !== true,
                    'aside-closed': !isOpen || $route.meta.hideMenu === true,
                }"
            >
                <router-view
                    name="aside"
                    class="fixed min-h-main"
                    :class="{ 'mt-toolbar': !$route.query.noToolBar }"
                ></router-view>
                <router-view
                    class="left-w-full left-aside items-center flex flex-col"
                    :class="{ 'mt-toolbar': !$route.query.noToolBar }"
                ></router-view>
            </div>
            <dialogs-wrapper></dialogs-wrapper>
        </template>
    </div>
</template>

<script>
import ToolBar from '../components/ToolBar.vue';
import Menu from '../components/MainMenu.vue';
import { getMenuState, networkStatus, updateMenuState } from '@/state/state';
import database, { initProjectCollections, isReadyProject } from '@/rxdb/database';

export default {
    components: { ToolBar, Menu },
    created() {
        this.subscriptions = [
            getMenuState((menuState) => (this.isOpen = menuState.isOpen)),
            networkStatus.subscribe((isOnline) => (this.isOffline = !isOnline)),
        ];
        return this.init(true);
    },
    methods: {
        async replicate(projectId, routeName) {
            if (routeName && (routeName.includes('print') || routeName.includes('Print'))) {
                await database.replicateProject(projectId);
            } else {
                database.replicateProject(projectId).catch((err) => console.error(err));
            }
        },
        async init(isOnCreate) {
            const projectId = this.$route.params.projectId;
            const isNewProject = projectId && !isReadyProject(projectId);
            if (isOnCreate || isNewProject) {
                this.lock();
            }
            await database.initDb;
            if (projectId) {
                if (isNewProject) {
                    await initProjectCollections(projectId);
                }
                await this.replicate(projectId, this.$route.name);
            }
            this.unlock();
        },
        lock() {
            this.dataLoaded = false;
            document.body.classList.add('loading');
        },
        unlock() {
            document.body.classList.remove('loading');
            this.dataLoaded = true;
        },
        onToggleMenu: async function () {
            this.isOpen = !this.isOpen;
            updateMenuState({ isOpen: this.isOpen });
        },
    },
    watch: {
        $route(newRoute, oldRoute) {
            if (newRoute.params.projectId && newRoute.params.projectId !== oldRoute.params.projectId) {
                return this.init(false);
            }
        },
    },
    data() {
        return {
            dataLoaded: false,
            isOpen: true,
            isOffline: !navigator.onLine,
            subscriptions: [],
        };
    },
};
</script>
