<template>
    <app-mail-popup ref="popup" label="Envoyer un mail de convocations">
        <div>
            <app-select
                :value="selectedMailTemplateId"
                @change="onSelectedMailTemplateChanged"
                :label="$t('project.diffusion.chooseMailTemplateLabel')"
            >
                <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">{{ mailTemplate.name }}</option>
            </app-select>
        </div>
    </app-mail-popup>
</template>

<script>
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { humanizeDateFull, humanizeHour } from '@/filters/dateFilter';
import AppMailPopup from '@/features/planningExports/AppMailPopup.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';
import { getCompanies } from '@/features/companies/companies.service';
import { getMapById } from '@/services/sanitize.service';

export default {
    components: {
        AppMailPopup,
        AppSelect,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'convocations');
            }),
            getCompanies(this.$route.params.projectId).subscribe((companies) => {
                this.companyMap = getMapById(companies);
            }),
        ];
    },
    methods: {
        open(meeting) {
            this.meeting = meeting;
            this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
            this.$refs.popup.open();
        },
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            return string
                .replaceAll('DateReunion', humanizeDateFull(this.meeting.date))
                .replaceAll('NomReunion', this.meeting.name)
                .replaceAll(
                    'TableauConvocations',
                    `<table style="border: black solid 1px; margin: 0.1em; border-collapse: collapse;">
                      <tr>
                         <th style="border: black solid 1px; padding: 0.1em">Lot/Mission</th>
                         <th style="border: black solid 1px; padding: 0.1em">Entreprise</th>
                         <th  style="border: black solid 1px; padding: 0.1em">Attendu pour</th>
                      </tr>
                    ${this.meeting.convocations
                        .map(
                            (convocation) =>
                                `<tr>
                                   <td style="border: black solid 1px; padding: 0.1em">${convocation.bundle.label}</td>
                                   <td style="border: black solid 1px; padding: 0.1em">${
                                       convocation.bundle.companyId
                                           ? this.companyMap[convocation.bundle.companyId].name
                                           : ''
                                   }</td>
                                   <td style="border: black solid 1px; padding: 0.1em; text-align:center">${humanizeHour(
                                       convocation.convocationDate,
                                   )}</td></tr>`,
                        )
                        .join('')}
                    </table>`,
                );
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: [...template.to, ...this.meeting.convocations.map((convocation) => convocation.bundleId)],
                copyIds: template.cc,
                attachments: [],
            });
        },
    },
    data() {
        return {
            cacheKey: 'convocations.lastMailTemplate.' + this.$route.params.projectId,
            meeting: null,
            subscriptions: [],
            mailTemplates: [],
            selectedMailTemplateId: null,
        };
    },
};
</script>
