var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"aria-label":"Tabs"}},[_c('ul',{staticClass:"h-full",class:{ flex: !_vm.vertical, 'border-b': !_vm.vertical }},[_vm._l((_vm.values),function(value,index){return _c('li',{key:index,staticClass:"mr-1 bg-white flex-grow flex cursor-pointer hover:bg-gray-200",class:{
                '-mb-px hover:bg-transparent border-l border-r border-t font-bold':
                    !_vm.vertical && (_vm.selectedId === value.id || (!_vm.selectedId && index === 0)),
                'hover:bg-transparent border-b border-t font-bold border-l-4 border-main text-main ':
                    _vm.vertical && (_vm.selectedId === value.id || (!_vm.selectedId && index === 0)),
                ' border-r border-main': _vm.vertical && (_vm.selectedId !== value.id || (!_vm.selectedId && index !== 0)),
                'opacity-50 disabled': value.disabled,
                'flex justify-center': !_vm.vertical,
            },on:{"click":function($event){$event.preventDefault();!value.disabled ? _vm.onSelectTab(value) : null}}},[(value.disabled)?_c('span',{staticClass:"px-4",class:{ 'py-2 inline-block': !_vm.vertical, 'py-4': _vm.vertical }},[_vm._v("\n                "+_vm._s(value[_vm.labelProp])+"\n            ")]):_c('a',{staticClass:"px-4",class:{ 'py-2 inline-block': !_vm.vertical, 'py-4': _vm.vertical },attrs:{"href":"#"}},[_vm._v("\n                "+_vm._s(value[_vm.labelProp])+"\n            ")])])}),(_vm.vertical)?_c('li',{staticClass:"mr-1 bg-white flex-grow flex border-r min-h-full border-main"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }