<template>
    <main ref="main" class="h-full w-main relative min-h-main max-h-main flex flex-col">
        <div v-if="loading" class="flex justify-center">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
        <template v-else>
            <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
                <form class="flex flex-col gap-4 p-2">
                    <app-separator variant="underline" :label="$t('project.planning.referenceChoice')"></app-separator>
                    <app-select v-model="referenceId">
                        <option v-for="reference in references" :value="reference.id">
                            {{ reference.createdAt | humanizeDate }} - {{ reference.name }}
                        </option>
                    </app-select>
                    <app-separator variant="underline" :label="$t('project.planning.tableSettings')"></app-separator>
                    <fieldset>
                        <legend class="text-gray-700 font-bold">{{ $t('project.follow.planning.structure') }}</legend>
                        <div class="flex flex-col ml-2">
                            <label>
                                <input
                                    type="radio"
                                    name="groupedBy"
                                    v-model="structure"
                                    value="FLB"
                                    @input="onStructureChange('FLB')"
                                />
                                {{ $t('project.follow.planning.FLBTips') }}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="groupedBy"
                                    v-model="structure"
                                    value="FBL"
                                    @input="onStructureChange('FBL')"
                                />
                                {{ $t('project.follow.planning.FBLTips') }}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="groupedBy"
                                    v-model="structure"
                                    value="BFL"
                                    @input="onStructureChange('BFL')"
                                />
                                {{ $t('project.follow.planning.BFLTips') }}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="groupedBy"
                                    v-model="structure"
                                    value="BESL"
                                    @input="onStructureChange('BESL')"
                                />
                                {{ $t('project.follow.planning.BESL') }}
                            </label>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend class="text-gray-700 font-bold">{{ $t('project.planning.showHide') }}</legend>
                        <div class="px-2 gap-2">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showProgressColumn" @input="updateState">
                                                <div class="flex gap-1 items-center">
                                                    <icon-percent width="16" height="16" />
                                                    {{ $t('project.planning.showProgressColumn') }}
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showQuantityColumn" @input="updateState">
                                                <div class="flex gap-1 items-center">
                                                    <icon-weight width="16" height="16" />
                                                    {{ $t('project.planning.showQuantityColumn') }}
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showDurationColumn" @input="updateState">
                                                <div class="flex gap-1 items-center">
                                                    <icon-arrow-expand-horizontal width="16" height="16" />
                                                    {{ $t('project.planning.showDurationColumn') }}
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showLateColumn" @input="updateState">
                                                <div class="flex gap-1 items-center">
                                                    <icon-clock-alert-outline width="16" height="16" />
                                                    {{ $t('project.planning.showLateColumn') }}
                                                </div>
                                            </app-checkbox>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showReferenceData" @input="updateState">
                                                <div class="flex gap-1 items-center" style="color: #f74c14">
                                                    {{ $t('project.planning.showReferenceData') }} (Ref)
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                    </tr>
                                    <tr>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showPlannedData" @input="updateState">
                                                <div class="flex gap-1 items-center text-blue-700">
                                                    {{ $t('project.planning.showPlannedData') }} (P)
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                    </tr>
                                    <tr>
                                        <th class="border pr-1 font-normal">
                                            <app-checkbox v-model="options.showRealData" @input="updateState">
                                                <div class="flex gap-1 items-center">
                                                    {{ $t('project.planning.showRealData') }} (R)
                                                </div>
                                            </app-checkbox>
                                        </th>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                        <td class="border"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                    <app-separator variant="underline" :label="$t('project.planning.chartSettings')"></app-separator>
                    <fieldset>
                        <legend class="text-gray-700 font-bold">{{ $t('project.planning.showHide') }}</legend>
                        <div class="px-2 flex flex-col gap-2">
                            <div class="flex flex-col gap-2">
                                <app-checkbox v-model="options.showProgressLine" @input="updateState">
                                    {{ $t('project.planning.showProgressLine') }}
                                </app-checkbox>

                                <div class="pl-10 flex gap-2">
                                    <app-label>
                                        <input
                                            :disabled="!options.showProgressLine"
                                            type="radio"
                                            value="reference"
                                            name="progressReportedTo"
                                            v-model="options.progressReportedTo"
                                            @change="updateState"
                                        />
                                        {{ $t('project.planning.progressReportedToReference') }}
                                    </app-label>
                                    <app-label>
                                        <input
                                            :disabled="!options.showProgressLine"
                                            type="radio"
                                            value="planned"
                                            name="progressReportedTo"
                                            v-model="options.progressReportedTo"
                                            @change="updateState"
                                        />
                                        {{ $t('project.planning.progressReportedToPlanned') }}
                                    </app-label>
                                </div>
                            </div>
                            <div class="flex">
                                <app-checkbox v-model="options.showReference" @input="updateState">
                                    <span style="color: #f74c14">
                                        {{ $t('project.planning.showReference') }}
                                    </span>
                                </app-checkbox>
                            </div>
                            <div class="flex">
                                <app-checkbox v-model="options.showPlanned" @input="updateState">
                                    <span class="text-blue-700">
                                        {{ $t('project.planning.showPlanned') }}
                                    </span>
                                </app-checkbox>
                            </div>
                            <div class="flex">
                                <app-checkbox v-model="options.showReal" @input="updateState">
                                    <span>{{ $t('project.planning.showReal') }}</span>
                                </app-checkbox>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend class="text-gray-700 font-bold">{{ $t('project.follow.planning.scale') }}</legend>
                        <div class="flex flex-col ml-2">
                            <label>
                                <input
                                    type="radio"
                                    name="scale"
                                    v-model="options.scale"
                                    value="days"
                                    @input="onScaleChange('days')"
                                />
                                {{ $t('project.follow.planning.scaleDays') }}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="scale"
                                    v-model="options.scale"
                                    value="weeks"
                                    @input="onScaleChange('weeks')"
                                />
                                {{ $t('project.follow.planning.scaleWeeks') }}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="scale"
                                    v-model="options.scale"
                                    value="months"
                                    @input="onScaleChange('months')"
                                />
                                {{ $t('project.follow.planning.scaleMonths') }}
                            </label>
                        </div>
                    </fieldset>

                    <app-label :label="$t('project.follow.planning.datePicker')" tag="div">
                        <div class="flex gap-1 sm:gap-2">
                            <app-date-input
                                size="10"
                                v-model="options.minDate"
                                class="mx-1"
                                :show-label="false"
                                :label="$t('project.follow.planning.datePicker')"
                                :title="$t('project.follow.planning.datePicker')"
                                @input="updateState"
                                ref="date"
                            >
                                <template v-slot:tip>
                                    <app-tips>{{ $t('project.planning.minDateTip') }}</app-tips>
                                </template>
                            </app-date-input>
                        </div>
                    </app-label>
                    <app-footer @click="$refs.settings.close()"></app-footer>
                </form>
                <div style="padding-bottom: 40px"></div>
            </app-popup>
            <div ref="planningToolbar" class="flex" v-if="hasTasks">
                <div class="p-1 bg-white text-xs flex flex-grow items-end gap-4">
                    <app-expand :show-label="true" @expand="expandTo" ref="expand" class="mx-4"></app-expand>
                    <app-multi-picker
                        ref="filter"
                        icon="icon-magnify"
                        :show-label="true"
                        :allowStringCriteria="true"
                        class="mx-4 flex-grow"
                        size="mini"
                        :label="$t('commons.filter')"
                        v-model="filterAsArray"
                        :options="filterOptions"
                        @input="onFilterChange"
                        :filterStringFunction="filterStringFunctionForOptions"
                        :labelFunction="filterStringFunctionForOptions"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle v-if="option.type === 'bundle'" :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                    <div class="flex flex-row text-center gap-1">
                        <div class="flex flex-col items-center border p-1 gap-1 xl:gap-2">
                            <span class="font-bold" v-click-helper="refDateToToday">
                                {{ $t('project.follow.planning.refDate') }}
                            </span>
                            <app-date-link
                                class="text-blue-500"
                                v-model="options.refDate"
                                @input="updateRefDate"
                            ></app-date-link>
                        </div>
                        <div class="flex flex-col border p-1 border-blue-500 bg-gray-100 gap-1 xl:gap-2">
                            <span class="font-bold">{{ $t('dashboard.realEndDate') }}</span>
                            <span class="text-blue-500">
                                {{ lastPlannedDate | humanizeDate }}
                            </span>
                        </div>
                        <div
                            class="flex flex-col border p-1 border-green-500 bg-gray-100 gap-1 xl:gap-2"
                            v-if="reference"
                        >
                            <span class="font-bold">{{ $t('dashboard.referenceEndDate') }}</span>
                            <span class="text-green-500">
                                {{ lastReferenceDate | humanizeDate }}
                            </span>
                        </div>
                        <div
                            class="flex flex-col border p-1 bg-gray-100 gap-1 xl:gap-2"
                            :class="{ 'border-green-500': late <= 0, 'border-red-500': late > 0 }"
                            v-if="reference"
                        >
                            <span class="font-bold" v-if="late > 0">{{ $t('dashboard.lateTitle') }}</span>
                            <span class="font-bold" v-else>{{ $t('dashboard.advanceTitle') }}</span>
                            <span class="" :class="{ 'text-green-500': late <= 0, 'text-red-500': late > 0 }">
                                {{ late > 0 ? '+' + late : -late }}jo
                            </span>
                        </div>
                    </div>
                    <a
                        :href="lastPlanningExport ? lastPlanningExport.url : '#'"
                        ref="lastPdfLink"
                        class="hidden"
                        download
                        target="_blank"
                    ></a>
                    <app-button
                        size="mini"
                        class="p-2 shadow"
                        v-click-helper="openPdf"
                        :aria-label="$t('project.follow.planning.pdf')"
                        :title="$t('project.follow.planning.pdf')"
                        :disabled="lastPlanningExport && !lastPlanningExport.generationEndDate"
                        :icon="
                            lastPlanningExport && !lastPlanningExport.generationEndDate
                                ? 'icon-rotate-right'
                                : 'icon-file-pdf-outline'
                        "
                        :icon-class="
                            lastPlanningExport && !lastPlanningExport.generationEndDate ? 'animate animate-spin' : ''
                        "
                    />
                    <app-button
                        size="mini"
                        class="p-2 shadow"
                        @click="$refs.settings.open()"
                        :aria-label="$t('commons.settings')"
                        :title="$t('commons.settings')"
                        icon="icon-tune"
                    />
                </div>
            </div>
            <gantt
                v-if="hasTasks && !loading"
                ref="gantt"
                :tasks="filteredLines"
                :options="options"
                @select="onSelect"
                :selectedItem="selectedItem"
                :style="{ 'max-height': ganttMaxHeight, height: ganttMaxHeight }"
                class="overflow-hidden w-main px-1 text-xs"
                :agenda="agendaWithWeekEnds"
                :minDate="options.minDate"
                :maxDate="options.maxDate"
                @navigateToDate="navigateToDate($event)"
                @expand="expand"
                @expandDeep="expandDeep"
                @collapseDeep="collapseDeep"
                @collapse="collapse"
                @updateDuration="updateTaskDuration"
                @updateStartDate="updateTaskStartDate"
                @updateEndDate="updateTaskEndDate"
                @updateProgress="updateTaskProgress"
                @updateRealStartDate="updateTaskRealStartDate"
                @updateRealEndDate="updateTaskRealEndDate"
                @sortBy="onSortBy"
            ></gantt>
            <div v-else class="flex flex-col gap-1 justify-center items-center min-h-main">
                <div>
                    <app-label class="my-4" tag="div" :label="$t('project.follow.planning.noTasks.title')"></app-label>
                    <ol class="list-decimal ml-6">
                        <li class="my-1">
                            {{ $t('project.follow.planning.noTasks.bundles') }}
                            <router-link :to="{ name: 'bundles' }" class="underline">
                                {{ $t('project.follow.planning.noTasks.bundlesLinkContent') }}
                            </router-link>
                        </li>
                        <li class="my-1">
                            {{ $t('project.follow.planning.noTasks.locations') }}
                            <router-link :to="{ name: 'folders' }" class="underline">
                                {{ $t('project.follow.planning.noTasks.locationsLinkContent') }}
                            </router-link>
                        </li>
                        <li class="my-1">
                            {{ $t('project.follow.planning.noTasks.services') }}
                            <router-link :to="{ name: 'services' }" class="underline">
                                {{ $t('project.follow.planning.noTasks.servicesLinkContent') }}
                            </router-link>
                            <app-tips>{{ $t('project.follow.planning.noTasks.servicesTips') }}</app-tips>
                        </li>
                    </ol>
                </div>
            </div>
            <div
                ref="details"
                v-if="selectedItem && selectedItem.type === 'task'"
                class="w-main bg-white bottom-0 fixed"
            >
                <task-detail-header
                    ref="detailHeader"
                    :task="selectedItem"
                    :agenda="agenda"
                    :structure="structure"
                    @navigateToDate="navigateToDate"
                    @change="updated"
                >
                    <task-detail
                        ref="detail"
                        :task="selectedItem"
                        :tasks="tasks"
                        :agenda="agenda"
                        v-if="selectedItem && selectedItem.type === 'task'"
                        @editService="openService(selectedItem.serviceId)"
                        :readOnly="readOnly"
                    />
                </task-detail-header>
            </div>
            <div
                ref="details"
                v-if="
                    (selectedItem && selectedItem.level === 2 && this.structure === 'FLB') ||
                    (selectedItem && selectedItem.level === 1 && this.structure === 'FBL') ||
                    (selectedItem &&
                        selectedItem.level === 0 &&
                        (this.structure === 'BFL' || this.structure === 'BESL'))
                "
                class="w-main bg-white bottom-0 fixed"
            >
                <bundle-detail-header
                    ref="detailHeader"
                    :bundle="selectedItem"
                    :structure="structure"
                    :node="selectedItem"
                >
                    <bundle-detail
                        ref="detail"
                        :bundle-id="selectedItem.bundleId || selectedItem.id"
                        :node="selectedItem"
                        @editService="openService($event)"
                        v-if="!readOnly"
                    />
                </bundle-detail-header>
            </div>
            <div
                ref="details"
                v-if="
                    (selectedItem && selectedItem.level === 2 && this.structure === 'FBL') ||
                    (selectedItem && selectedItem.level === 2 && this.structure === 'BESL')
                "
                class="w-main bg-white bottom-0 fixed"
            >
                <service-detail-header ref="detailHeader" :service="selectedItem">
                    <planning-service-detail ref="detail" :service="selectedItem" :readOnly="readOnly" />
                </service-detail-header>
            </div>
        </template>
        <app-popup ref="pdfPopup" :title="$t('project.planning.PDFExportLabel')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="flex text-xs underline gap-4 p-1 px-4 justify-center">
                    <router-link :to="{ name: 'planningExports', params: $route.params }">
                        {{ $t('project.planning.allExports') }}
                    </router-link>
                    <a class="underline" :href="lastPlanningExport.url" target="_blank" v-if="lastPlanningExport">
                        {{ $t('project.planning.lastPlanningExport') }} ({{ lastPlanningExport.name }})
                    </a>
                </div>
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text v-model="options.name" :label="$t('project.planning.exportName')">
                        <template v-slot:tip>
                            <app-tips>{{ options.fileName || defaultFileName }}.pdf</app-tips>
                        </template>
                    </app-input-text>
                    <app-date-input
                        v-model="pdf.minDate"
                        :label="$t('project.planning.PDFMinDate')"
                        :title="$t('project.planning.PDFMinDate')"
                        :required="true"
                    ></app-date-input>
                    <app-date-input
                        v-model="pdf.maxDate"
                        :label="$t('project.planning.PDFMaxDate')"
                        :title="$t('project.planning.PDFMaxDate')"
                        :required="true"
                    />
                    <div class="flex flex-col">
                        <app-checkbox v-model="options.showOutOfRangeLines" @input="updateState">
                            {{ $t('project.planning.showOutOfRangeLines') }}
                        </app-checkbox>
                        <app-checkbox v-model="options.considerCollapsed" @input="updateState">
                            {{ $t('project.planning.considerCollapsed') }} ({{ collapsedCount }})
                        </app-checkbox>
                        <app-checkbox v-model="options.considerFilter" @input="updateState">
                            {{ $t('project.planning.considerFilter') }}
                        </app-checkbox>
                    </div>
                    <app-select :label="$t('project.planning.PDFFormat')" v-model="pdf.format">
                        <option value="a4">A4</option>
                        <option value="a3">A3</option>
                        <option value="a2">A2</option>
                        <option value="a1">A1</option>
                        <option value="a0">A0</option>
                    </app-select>
                    <fieldset>
                        <legend class="text-gray-700 font-bold">{{ $t('project.planning.PDFOrientation') }}</legend>
                        <div class="flex flex-col ml-2">
                            <label>
                                <input type="radio" name="pdfLandscape" v-model="pdf.landscape" :value="true" />
                                {{ $t('project.planning.PDFLandscape') }}
                            </label>
                            <label>
                                <input type="radio" name="pdfLandscape" v-model="pdf.landscape" :value="false" />
                                {{ $t('project.planning.PDFPortrait') }}
                            </label>
                        </div>
                    </fieldset>
                    <app-select :label="$t('project.planning.PDFMaxLevel')" v-model="maxLevel">
                        <option value="1">Niveau 1</option>
                        <option value="2">Niveau 2</option>
                        <option value="3">Niveau 3</option>
                        <option value="4">Niveau 4</option>
                        <option value="5">Niveau 5</option>
                        <option value="6">Niveau 6</option>
                        <option value="99">Tout</option>
                    </app-select>
                    <app-footer
                        @click="
                            $refs.pdfPopup.close();
                            exportPDF();
                        "
                        :label="$t('project.planning.exportPDF')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.pdfPopup.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="servicePopup" :title="$t('project.planning.service')" :show-header="true">
            <service-details :service-id="selectedServiceId"></service-details>
        </app-popup>
        <add-planning-line-popup ref="addTaskPopup"></add-planning-line-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import format from 'date-fns/format';
import Gantt from '../../../components/gantt/Gantt';
import AppSelect from '../../../components/appSelect/AppSelect';
import AppButton from '../../../components/appButton/AppButton';
import AppLabel from '../../../components/appLabel/AppLabel';
import TaskDetail from './TaskDetail';
import AppDateInput from '../../../components/appDateInput/AppDateInput';
import TaskDetailHeader from './TaskDetailHeader';
import AppTimeSlider from '../../../components/appTimeSlider/AppTimeSlider';
import { getMenuState, queryPlanningState, updatePlanningState } from '@/state/state';
import AppQuickActions from '../../../components/appQuickActions/AppQuickActions';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { filterTasks, getFilterOptions, populateTree } from './planning.service';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import { isInViewport, sortBy } from '@/services/sanitize.service';
import AppExpand from '@/components/appExpand/AppExpand';
import { applyCollapse, cleanOrDefineMaxDate, getWeekEnds, mapAgenda, mapTasks } from '@/components/gantt/ganttService';
import AppPopup from '@/components/app-popup/AppPopup';
import AppFooter from '@/components/appFooter/AppFooter';
import hotkeys from 'hotkeys-js';
import AppBundle from '@/components/app-bundle/appBundle';
import AppTips from '@/components/app-tips/AppTips';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import startOfDay from 'date-fns/startOfDay';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import IconCalendar from '@/icons/IconCalendar';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import vueClickHelper from 'vue-click-helper';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { createPlanningExport, getLastPlanningExport } from '@/features/planning/planning/planningExport.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getReferences } from '@/features/planning/references/references.service';
import { getDirectories } from '@/features/services/directories.service';
import { updateTask } from '@/features/tasks/tasks.service';
import IconPercent from '@/icons/IconPercent';
import IconWeight from '@/icons/IconWeight';
import IconArrowExpandHorizontal from '@/icons/IconArrowExpandHorizontal';
import IconClockAlertOutline from '@/icons/IconClockAlertOutline';
import { error, info } from '@/features/toasts/toats.service';
import AppInputText from '@/components/appInputText/AppInputText';
import sanitizeFileName from 'sanitize-filename';
import { humanizeDateTimeFileSafe } from '@/filters/dateFilter';
import ServiceDetails from '@/features/services/ServiceDetails';
import PlanningServiceDetail from '@/features/planning/planning/PlanningServiceDetail';
import ServiceDetailHeader from '@/features/planning/planning/ServiceDetailHeader';
import { replicateProjects } from '@/rxdb/database';
import BundleDetailHeader from '@/features/planning/planning/BundleDetailHeader.vue';
import BundleDetail from '@/features/planning/planning/BundleDetail.vue';
import AddPlanningLinePopup from '@/features/planning/planning/AddPlanningLinePopup.vue';
import AppTipsIcon from '@/components/appTipsIcon/AppTipsIcon.vue';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';

export default {
    components: {
        AppDateLink,
        AppTipsIcon,
        AddPlanningLinePopup,
        BundleDetail,
        BundleDetailHeader,
        ServiceDetailHeader,
        PlanningServiceDetail,
        ServiceDetails,
        AppInputText,
        IconClockAlertOutline,
        IconArrowExpandHorizontal,
        IconWeight,
        IconPercent,
        AppFileLink,
        IconCalendar,
        AppSeparator,
        AppCheckbox,
        AppTips,
        AppBundle,
        AppFooter,
        AppPopup,
        AppExpand,
        AppMultiPicker,
        AppQuickActions,
        AppTimeSlider,
        TaskDetailHeader,
        AppDateInput,
        TaskDetail,
        AppLabel,
        AppButton,
        AppSelect,
        Gantt,
    },
    directives: { 'click-helper': vueClickHelper },
    async created() {
        getMenuState(() => this.resizeGantt());
        queryProject(this.$route.params.projectId).then((project) => {
            this.project = project;
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
        this.restorePlanningState();
        this.plannedTaskTrigger.next(this.options.refDate);
        this.subscriptions = [
            combineLatest([
                getDirectories(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getCalendar(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getReferences(this.$route.params.projectId),
                getPlannedTasks(this.$route.params.projectId, this.plannedTaskTrigger),
            ]).subscribe(([directories, bundles, agenda, folders, references, tasks]) => {
                this.bundles = bundles;
                this.agenda = agenda;
                this.directories = directories;
                this.folders = folders;
                this.references = references;
                this.filterOptions = getFilterOptions((a) => this.$t(a), folders, bundles);

                this.tasks = mapTasks(
                    tasks.map((task) => {
                        task.type = 'task';
                        return task;
                    }),
                    startOfDay(this.options.refDate),
                );
                this.restoreSelection();
                this.hasTasks = tasks.length > 0;
                this.lastPlannedDate = this.tasks
                    .map((child) => child.estimatedEndDate)
                    .reduce((acc, date) => (acc ? (date && date > acc ? date : acc) : date), null);

                this.loading = false;
            }),
            getLastPlanningExport(this.$route.params.projectId).subscribe((lastPlanningExport) => {
                if (
                    this.lastPlanningExport &&
                    lastPlanningExport &&
                    this.lastPlanningExport.generationEndDate !== lastPlanningExport.generationEndDate
                ) {
                    if (lastPlanningExport.generationSucceeded) {
                        info(
                            this.$t('project.planning.generationSucceed') +
                                `<br/><a href="${lastPlanningExport.url}"><u>Télécharger le pdf</u></a>`,
                            5000,
                        );
                    } else {
                        error(this.$t('project.planning.generationFailed'));
                    }
                }
                this.lastPlanningExport = lastPlanningExport;
            }),
        ];
    },
    updated: function () {
        this.updated();
    },
    data() {
        return {
            plannedTaskTrigger: new BehaviorSubject(new Date()),
            bundles: [],
            folders: [],
            directories: [],
            agenda: [],
            loading: true,
            hasTasks: false,
            readOnly: true,
            pdf: {
                format: 'a4',
                landscape: true,
            },
            lastPlanningExport: null,
            locationOptions: [],
            filterAsArray: [],
            filterOptions: [],
            structure: 'FLB',
            tasks: [],
            collapsedLines: {},
            selectedItem: null,
            lastReference: null,
            project: {},
            ganttMaxHeight: '100%',
            maxLevel: '99',
            options: {
                print: false,
                scale: 'months',
                name: '',
                fileName: null,
                maxDate: null,
                minDate: null,
                showCollapseButtons: true,
                showLineNumbers: false,
                showProgressLine: false,
                showReference: false,
                refDate: new Date(),
                showReal: false,
                showPlanned: false,
                progressReportedTo: 'reference',
                showReferenceData: false,
                showRealData: true,
                showLateColumn: true,
                showQuantityColumn: false,
                showDurationColumn: false,
                showPlannedData: true,
                showProgressColumn: true,
                showOutOfRangeLines: false,
                considerFilter: true,
                considerCollapsed: true,
                sortKey: 'date',
            },
            lastPlannedDate: null,
            references: [],
            selectedServiceId: null,
            referenceId: null,
        };
    },
    async beforeCreate() {
        if (Object.keys(this.$route.query).length > 0) {
            await updatePlanningState(this.$route.params.projectId, {
                minDate: this.$route.query.minDate ? new Date(this.$route.query.minDate) : startOfDay(new Date()),
                selectedItemId: this.$route.query.selectedItemId,
            });
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', this.onResize);
        hotkeys('down,ctrl+down', this.selectNextTask);
        hotkeys('up,ctrl+up', this.selectPreviousTask);
        hotkeys('right', this.next);
        hotkeys('left', this.previous);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        hotkeys.unbind('down,ctrl+down');
        hotkeys.unbind('up,ctrl+up');
        hotkeys.unbind('left');
        hotkeys.unbind('right');
        document.body.style.overflow = 'auto';
    },
    computed: {
        agendaWithWeekEnds() {
            return mapAgenda(
                sortBy([...getWeekEnds(this.options.minDate, this.options.maxDate), ...this.agenda], 'time'),
                this.options.refDate,
            );
        },
        lastReferenceDate() {
            return this.reference
                ? this.tasks
                      .map((child) => child.referenceEndDate)
                      .reduce((acc, date) => (acc ? (date && date > acc ? date : acc) : date), null)
                : null;
        },
        late() {
            return this.reference && this.lastReferenceDate && this.lastPlannedDate
                ? differenceInBusinessDays(this.lastPlannedDate, this.lastReferenceDate)
                : null;
        },
        defaultFileName() {
            return sanitizeFileName(this.options.name);
        },
        collapsedCount() {
            return this.filteredLines.filter((line) => line.collapsed).length;
        },
        reference() {
            return this.referenceId ? this.references.find((reference) => reference.id === this.referenceId) : null;
        },
        lines() {
            const refDate = startOfDay(this.options.refDate || new Date());
            return mapTasks(
                populateTree(
                    filterTasks(this.tasks, this.filterAsArray),
                    this.reference || [],
                    this.bundles,
                    this.folders,
                    this.directories,
                    this.structure,
                    this.agenda,
                    refDate,
                    this.options.sortKey,
                ),
                refDate,
            );
        },
        filteredLines() {
            return applyCollapse(
                this.lines.map((line) => ({
                    ...line,
                    collapsed: this.collapsedLines[line.id] === true,
                })),
            );
        },
        quickActions() {
            return [
                ...(!this.readOnly &&
                this.structure === 'FLB' &&
                this.selectedItem &&
                this.selectedItem.nodeType === 'bundle'
                    ? [
                          {
                              name: this.$t('project.follow.planning.addTask') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addTask'),
                                      bundleId: this.selectedItem.bundleId,
                                      serviceId: null,
                                      folderId: this.selectedItem.folderId,
                                      locationId: this.selectedItem.locationId,
                                  });
                              },
                          },
                      ]
                    : []),
                ...(!this.readOnly &&
                this.structure === 'FBL' &&
                this.selectedItem &&
                this.selectedItem.nodeType === 'bundle'
                    ? [
                          {
                              name: this.$t('project.follow.planning.addService') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addService'),
                                      bundleId: this.selectedItem.bundleId,
                                      serviceId: null,
                                      folderId: this.selectedItem.folderId,
                                      locationId: null,
                                  });
                              },
                          },
                      ]
                    : []),
                ...(!this.readOnly &&
                this.structure === 'FBL' &&
                this.selectedItem &&
                this.selectedItem.nodeType === 'service'
                    ? [
                          {
                              name: this.$t('project.follow.planning.addLocation') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addLocation'),
                                      bundleId: this.selectedItem.bundleId,
                                      serviceId: this.selectedItem.serviceId,
                                      folderId: this.selectedItem.folderId,
                                      locationId: null,
                                  });
                              },
                          },
                      ]
                    : []),
                ...(!this.readOnly &&
                this.structure === 'BFL' &&
                this.selectedItem &&
                this.selectedItem.nodeType === 'location'
                    ? [
                          {
                              name: this.$t('project.follow.planning.addTask') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addLocation'),
                                      bundleId: this.selectedItem.bundleId,
                                      serviceId: null,
                                      folderId: this.selectedItem.folderId,
                                      locationId: this.selectedItem.locationId,
                                  });
                              },
                          },
                      ]
                    : []),
                ...(!this.readOnly &&
                (this.structure === 'BESL' || this.structure === 'BFL') &&
                this.selectedItem &&
                (this.selectedItem.nodeType === 'service' || this.selectedItem.nodeType === 'task')
                    ? [
                          {
                              name: this.$t('project.follow.planning.addLocation') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addLocation'),
                                      bundleId: this.selectedItem.bundleId,
                                      serviceId: this.selectedItem.serviceId,
                                      folderId: this.selectedItem.folderId,
                                      locationId: null,
                                  });
                              },
                          },
                      ]
                    : []),
                ...(!this.readOnly &&
                (this.structure === 'BESL' || this.structure === 'BFL') &&
                this.selectedItem &&
                (this.selectedItem.nodeType === 'folder' || this.selectedItem.nodeType === 'bundle')
                    ? [
                          {
                              name: this.$t('project.follow.planning.addService') + '...',
                              run: () => {
                                  this.$refs.addTaskPopup.open({
                                      title: this.$t('project.follow.planning.addService'),
                                      bundleId: this.selectedItem.bundleId || this.selectedItem.id,
                                      folderId: this.selectedItem.folderId,
                                      serviceId: null,
                                      locationId: null,
                                  });
                              },
                          },
                      ]
                    : []),
                {
                    name: this.$t('commons.openClose') + '...',
                    run: () => this.$refs.expand.focus(),
                },
                {
                    name: this.$t('commons.collapseAll'),
                    run: () => this.collapseAll(),
                },
                {
                    name: this.$t('project.follow.planning.groupedBy') + '...',
                    run: () => this.$refs.structure.focus(),
                },
                {
                    name: this.$t('project.follow.planning.scale') + '...',
                    run: () => this.$refs.scale.focus(),
                },
                {
                    name: this.$t('project.follow.planning.previous'),
                    run: () => this.previous(),
                },
                {
                    name: this.$t('project.follow.planning.next'),
                    run: () => this.next(),
                },
                {
                    name: this.$t('project.follow.planning.datePicker') + '...',
                    run: () => this.$refs.date.focus(),
                },
                {
                    name: this.$t('project.follow.planning.today'),
                    run: () => this.today(),
                },
            ];
        },
    },
    methods: {
        onSortBy(sortKey) {
            this.options.sortKey = sortKey;
            this.updateState();
        },
        openService(serviceId) {
            this.selectedServiceId = serviceId;
            this.$refs.servicePopup.open();
        },
        restoreSelection() {
            const planningState = queryPlanningState(this.$route.params.projectId);
            if (planningState.selectedItemId) {
                this.selectedItem = this.filteredLines.find((line) => line.id === planningState.selectedItemId);
            }
            if (this.selectedItem) {
                this.$nextTick(() => this.strollTo(this.selectedItem));
            }
        },
        restorePlanningState() {
            const planningState = queryPlanningState(this.$route.params.projectId);
            if (planningState.filter) {
                this.filterAsArray = [
                    ...this.filterOptions
                        .reduce((acc, item) => [...acc, ...item.children], [])
                        .filter(
                            (criterion) =>
                                !!planningState.filter.find((item) => {
                                    return item.id === criterion.id;
                                }),
                        ),
                    ...planningState.filter.filter((criterion) => criterion._isStringCriteria),
                ];
            }
            this.options.scale = planningState.scale || 'months';
            this.options.minDate = planningState.minDate ? new Date(planningState.minDate) : new Date();
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            this.options.refDate = startOfDay(this.options.refDate || new Date());
            this.options.showProgressLine =
                typeof planningState.showProgressLine === 'boolean' ? planningState.showProgressLine : true;
            this.options.showReference = !!planningState.showReference;
            this.options.showReal = !!planningState.showReal;
            this.options.progressReportedTo = planningState.progressReportedTo || 'reference';
            this.options.showPlanned = !!planningState.showPlanned;
            this.options.showLateColumn = planningState.showLateColumn;
            this.options.showQuantityColumn = planningState.showQuantityColumn;
            this.options.showDurationColumn = planningState.showDurationColumn;
            this.options.showProgressColumn = planningState.showProgressColumn;
            this.options.showReferenceData = planningState.showReferenceData;
            this.options.sortKey = planningState.sortKey || 'date';
            this.options.showPlannedData =
                typeof planningState.showPlannedData === 'boolean' ? planningState.showPlannedData : true;
            this.options.showRealData =
                typeof planningState.showRealData === 'boolean' ? planningState.showRealData : true;
            this.options.showOutOfRangeLines =
                typeof planningState.showOutOfRangeLines === 'boolean' ? planningState.showOutOfRangeLines : false;
            this.options.considerCollapsed =
                typeof planningState.considerCollapsed === 'boolean' ? planningState.considerCollapsed : true;
            this.options.considerFilter =
                typeof planningState.considerFilter === 'boolean' ? planningState.considerFilter : true;

            this.structure = planningState.structure || 'BFL';
            this.referenceId = planningState.referenceId || null;
            this.collapsedLines = planningState.collapsedLines || {};
        },
        updateTaskStartDate({ id, startDate }) {
            updateTask(this.$route.params.projectId, { id, startDate });
        },
        updateTaskRealEndDate({ id, realStartDate, realEndDate }) {
            updateTask(this.$route.params.projectId, {
                id,
                realEndDate,
                progress: 100,
                realStartDate: realStartDate || realEndDate,
            });
        },
        updateTaskEndDate({ id, endDate }) {
            updateTask(this.$route.params.projectId, { id, endDate });
        },
        updateTaskRealStartDate({ id, realStartDate }) {
            updateTask(this.$route.params.projectId, { id, realStartDate });
        },
        updateTaskDuration({ id, duration }) {
            updateTask(this.$route.params.projectId, { id, duration });
        },
        updateTaskProgress({ id, realStartDate, realEndDate, progress }) {
            if ((progress > 0 && progress < 100 && realStartDate) || progress === 0) {
                updateTask(this.$route.params.projectId, { id, progress });
            } else if (progress > 0 && progress < 100 && !realStartDate) {
                updateTask(this.$route.params.projectId, { id, progress, realStartDate: startOfDay(new Date()) });
            } else if (progress >= 100 && !realEndDate) {
                updateTask(this.$route.params.projectId, { id, progress: 100, realEndDate: startOfDay(new Date()) });
            } else if (progress >= 100) {
                updateTask(this.$route.params.projectId, { id, progress: 100 });
            }
        },
        selectNextTask(event) {
            if (event.target.nodeName === 'BODY') {
                const currentIndex = this.filteredLines.indexOf(
                    this.filteredLines.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const nextTask = this.filteredLines.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (nextTask) {
                        this.onSelect(nextTask);
                        event.preventDefault();
                        this.strollTo(nextTask);
                    }
                }
            }
        },
        selectPreviousTask(event) {
            if (event.target.nodeName === 'BODY') {
                const reversedTasks = [...this.filteredLines].reverse();
                const currentIndex = reversedTasks.indexOf(
                    reversedTasks.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const previousTask = reversedTasks.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (previousTask) {
                        this.onSelect(previousTask);
                        event.preventDefault();
                        this.strollTo(previousTask);
                    }
                }
            }
        },
        strollTo(task) {
            const element = document.querySelector('#uuid_' + task.id);
            if (element && !isInViewport(element)) {
                element.scrollIntoView();
            }
        },
        collapse: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: true };
            this.updateState();
        },
        collapseAll: function () {
            for (const line of this.lines) {
                this.collapsedLines[line.id] = true;
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandToLevel: function (level) {
            for (const line of this.lines) {
                if (line.level < level) {
                    this.collapsedLines[line.id] = false;
                } else {
                    this.collapsedLines[line.id] = true;
                }
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = false;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        collapseDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = true;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandAll: function () {
            this.collapsedLines = {};
            this.updateState();
        },
        expand: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: false };
            this.updateState();
        },
        openPDFExporter() {
            if (!this.pdf.minDate) {
                this.pdf.minDate = this.options.minDate;
            }
            if (!this.pdf.maxDate) {
                this.pdf.maxDate = this.options.maxDate;
            }
            this.options.name = 'Planning au ' + humanizeDateTimeFileSafe(new Date());
            this.$refs.pdfPopup.open();
        },
        openPdf(e, isDoubleClick) {
            if (isDoubleClick) {
                this.$refs.lastPdfLink.click();
            } else {
                this.openPDFExporter();
            }
        },
        async exportPDF() {
            await replicateProjects(this.$route.params.projectId);
            this.lastPlanningExport = await createPlanningExport(this.project.id, {
                minDate: this.pdf.minDate.toISOString(),
                maxDate: this.pdf.maxDate.toISOString(),
                format: this.pdf.format,
                landscape: this.pdf.landscape === true || this.pdf.landscape === 'true',
                scale: this.options.scale,
                name: this.options.name,
                fileName: (this.options.fileName || this.defaultFileName) + '.pdf',
                structure: this.structure,
                expandToLevel: parseInt(this.maxLevel),
                showProgressLine: this.options.showProgressLine,
                sortKey: this.options.sortKey,
                showReferenceData: this.options.showReferenceData,
                showPlannedData: this.options.showPlannedData,
                showReference: this.options.showReference,
                refDate: this.options.refDate.toISOString(),
                showReal: this.options.showReal,
                progressReportedTo: this.options.progressReportedTo,
                showPlanned: this.options.showPlanned,
                showLateColumn: this.options.showLateColumn,
                showQuantityColumn: this.options.showQuantityColumn,
                showDurationColumn: this.options.showDurationColumn,
                showProgressColumn: this.options.showProgressColumn,
                showRealData: this.options.showRealData,
                showOutOfRangeLines: this.options.showOutOfRangeLines,
                filter: this.options.considerFilter ? this.filterAsArray : [],
                collapsed: this.options.considerCollapsed
                    ? this.filteredLines.filter((task) => task.collapsed).map((task) => task.id)
                    : [],
                url: null,
                generationStartDate: new Date().toISOString(),
                generationEndDate: null,
                generationSucceeded: null,
            });
        },
        async navigateToDate(date) {
            this.options.minDate = date;
            await this.updateState();
        },
        resizeGantt() {
            this.updated();
            if (this.$refs.gantt) {
                this.$refs.gantt.onResize();
            }
        },
        updateRefDate(date) {
            if (date.getTime() !== this.plannedTaskTrigger.getValue().getTime()) {
                this.updateState();
                this.plannedTaskTrigger.next(date);
            }
        },
        refDateToToday() {
            const date = new Date();
            this.options.refDate = date;
            this.updateRefDate(date);
        },
        async updateState() {
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            updatePlanningState(this.$route.params.projectId, {
                scale: this.options.scale,
                structure: this.structure,
                minDate: format(this.options.minDate, 'yyyy-MM-dd'),
                refDate: this.options.refDate.toISOString(),
                selectedItemId: this.selectedItem?.id,
                showProgressLine: this.options.showProgressLine,
                showReference: this.options.showReference,
                showReal: this.options.showReal,
                progressReportedTo: this.options.progressReportedTo,
                showPlanned: this.options.showPlanned,
                showLateColumn: this.options.showLateColumn,
                showQuantityColumn: this.options.showQuantityColumn,
                showDurationColumn: this.options.showDurationColumn,
                showProgressColumn: this.options.showProgressColumn,
                showPlannedData: this.options.showPlannedData,
                showReferenceData: this.options.showReferenceData,
                showRealData: this.options.showRealData,
                sortKey: this.options.sortKey || 'date',
                showOutOfRangeLines: this.options.showOutOfRangeLines,
                filter: this.filterAsArray.map((item) =>
                    item._isStringCriteria ? item : { id: item.id, _isBundleCriteria: item._isBundleCriteria },
                ),
                considerFilter: this.options.considerFilter,
                considerCollapsed: this.options.considerCollapsed,
                collapsedLines: this.collapsedLines,
                referenceId: this.referenceId,
            });
        },
        updated: function () {
            if (this.$refs.main && this.$refs.planningToolbar) {
                this.ganttMaxHeight =
                    this.$refs.main.offsetHeight -
                    this.$refs.planningToolbar.offsetHeight -
                    (this.$refs.details ? this.$refs.details.offsetHeight : 0) +
                    'px';
                this.$refs.gantt.onResize();
            }
        },
        refreshSelectedItem() {
            if (this.selectedItem) {
                this.selectedItem = this.tasks.find((task) => task.id === this.selectedItem.id);
            }
        },
        onSelect(item) {
            this.selectedItem = item;
            this.updateState();
        },
        today() {
            this.options.minDate = sub(startOfDay(new Date()), { [this.options.scale]: 8 });
            this.updateState();
        },
        onStructureChange(structure) {
            this.structure = structure;
            this.updateState();
            this.refreshSelectedItem();
        },
        onScaleChange(scale) {
            this.options.scale = scale;
            this.updateState();
        },
        filterStringFunctionForOptions(item) {
            return item.label || item.name;
        },
        onFilterChange() {
            this.updateState();
            this.refreshSelectedItem();
        },
        next() {
            if (this.options.scale === 'days') {
                this.options.minDate = add(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = add(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = add(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        previous() {
            if (this.options.scale === 'days') {
                this.options.minDate = sub(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = sub(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = sub(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        expandTo(level) {
            if (level === 'all') {
                this.expandAll();
            } else {
                this.expandToLevel(parseInt(level));
            }
        },
    },
};
</script>
