<template>
    <main class="flex flex-col justify-center w-full sm:max-w-sm m-auto min-h-screen">
        <div class="md:shadow-2xl">
            <h1 class="flex bg-main font-bold text-white mb-2 p-2">{{ $t('user.resetPasswordTitle') }}</h1>
            <ValidationObserver v-slot="{ invalid }">
                <div class="p-2">
                    <p v-if="error" class="text-red-600">{{ $t('user.resetError') }}</p>
                </div>
                <form class="max-w-md grid grid-cols-2 gap-4 p-4">
                    <app-legend class="col-span-2"></app-legend>
                    <app-input-text
                        :label="$t('users.email')"
                        :required="true"
                        type="email"
                        v-model="email"
                        class="col-span-2"
                    >
                        <template v-slot:tip>
                            <app-tips>{{ $t('users.resetEmailTip') }}</app-tips>
                        </template>
                    </app-input-text>
                    <app-footer
                        @click.prevent="resetPassword()"
                        :disabled="invalid"
                        :ok-label="$t('user.resetPasswordOk')"
                    />
                </form>
            </ValidationObserver>
            <div class="mx-5 mb-5">
                <router-link :to="{ name: 'login', query: { email } }" class="underline text-sm">
                    {{ $t('login.title') }}
                </router-link>
            </div>
        </div>
    </main>
</template>

<script>
import AppErrors from '../../components/appErrors/AppErrors';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFooter from '../../components/appFooter/AppFooter';
import { resetPassword } from './users.service';
import AppLegend from '@/components/appLegend/AppLegend';
import AppTips from '@/components/app-tips/AppTips';
import { info } from '@/features/toasts/toats.service';

export default {
    components: { AppTips, AppLegend, AppFooter, AppInputText, AppErrors },
    methods: {
        resetPassword: async function () {
            try {
                await resetPassword(this.email);
                info(this.$t('user.resetConfirmation'), 3000);
                return this.$router.push({ name: 'login', email: this.$route.query.email });
            } catch (err) {
                console.error(err);
            }
        },
    },
    created() {
        if (this.$route.query.error) {
            this.error = true;
        }
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
    data: () => {
        return {
            email: '',
            error: false,
        };
    },
};
</script>
