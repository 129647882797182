export default {
    watch: {
        subscriptions(newValue, oldValue) {
            this.clearSubscription(oldValue);
        },
    },
    methods: {
        clearSubscription(subscriptions) {
            if (Array.isArray(subscriptions)) {
                for (const subscription of subscriptions) {
                    if (typeof subscription.unsubscribe === 'function') {
                        subscription.unsubscribe();
                    } else {
                        console.warn('subscriptions should be an array of RxJs subscriptions.');
                    }
                }
            }
        },
    },
    beforeDestroy: function () {
        this.clearSubscription(this.subscriptions);
    },
};
