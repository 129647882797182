<template>
    <app-mail-popup ref="popup" label="Envoyer le compte-rendu">
        <template v-slot:default="">
            <div>
                <app-select
                    :value="selectedMailTemplateId"
                    @change="onSelectedMailTemplateChanged"
                    :label="$t('project.diffusion.chooseMailTemplateLabel')"
                >
                    <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">
                        {{ mailTemplate.name }}
                    </option>
                </app-select>
            </div>
        </template>
        <template v-slot:body-footer-toolbar>
            <div class="flex gap-1">
                <app-button
                    size="mini"
                    :label="$t('project.editions.meetingReports.addPlanningLink')"
                    :title="$t('project.editions.meetingReports.addPlanningLinkTips')"
                    @click="addPlanningLink"
                />
                <app-autocomplete @input="onPickPlanning" :options="planningOptions" ref="planningPopup">
                    <template v-slot:title>
                        <app-separator>
                            <h2>{{ $t('project.editions.meetingReports.planningChoiceTitle') }}</h2>
                            <div class="flex flex-grow"></div>
                            <app-button
                                size="mini"
                                icon="icon-close"
                                aria-label="close popup"
                                @click="$refs.planningPopup.close()"
                            />
                        </app-separator>
                    </template>
                    <template v-slot:item="{ item }">
                        <div class="flex p-1 gap-1 items-center">
                            {{ item.name }}
                            <app-tips>{{ item.generationStartDate | humanizeDate }}</app-tips>
                        </div>
                    </template>
                </app-autocomplete>
                <app-button
                    size="mini"
                    :label="$t('project.editions.meetingReports.addObservationReportLink')"
                    :title="$t('project.editions.meetingReports.addObservationReportLinkTips')"
                    @click="addObservationsReportLink"
                />

                <app-autocomplete
                    @input="onPickObservationsReport"
                    :options="observationsReportOptions"
                    ref="observationsReportsPopup"
                >
                    <template v-slot:title>
                        <app-separator>
                            <h2>{{ $t('project.editions.meetingReports.observationsReportChoiceTitle') }}</h2>
                            <div class="flex flex-grow"></div>
                            <app-button
                                size="mini"
                                icon="icon-close"
                                aria-label="close popup"
                                @click="$refs.observationsReportsPopup.close()"
                            />
                        </app-separator>
                    </template>
                    <template v-slot:item="{ item }">
                        <div class="flex p-1 gap-1 items-center">
                            {{ item.name }}
                            <app-tips>{{ item.date | humanizeDate }}</app-tips>
                        </div>
                    </template>
                </app-autocomplete>
            </div>
        </template>
    </app-mail-popup>
</template>

<script>
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { humanizeDateFull, humanizeHour } from '@/filters/dateFilter';
import AppMailPopup from '@/features/planningExports/AppMailPopup.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';
import AppButton from '@/components/appButton/AppButton.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppPicker from '@/components/appPicker/AppPicker.vue';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { getPlanningExports } from '@/features/planning/planning/planningExport.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import { getObservationsReports } from '@/features/observationsReports/observationsReports.service';

export default {
    components: {
        AppTips,
        AppSeparator,
        AppAutocomplete,
        AppPicker,
        AppPopup,
        AppButton,
        AppMailPopup,
        AppSelect,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'meetingReport');
            }),
            getPlanningExports(this.$route.params.projectId).subscribe((planningExports) => {
                this.planningOptions = planningExports.filter(
                    (planningExport) => planningExport.generationSucceeded && planningExport.url,
                );
            }),
            getObservationsReports(this.$route.params.projectId, null).subscribe((observationsReports) => {
                this.observationsReportOptions = observationsReports.filter(
                    (observationReport) =>
                        observationReport.files && observationReport.files.find((file) => file.generationSucceed),
                );
            }),
        ];
    },
    methods: {
        onPickPlanning(planning) {
            this.$refs.popup.insert(this.lastIndex, planning.name, 'link', `https://app.atex-info.eu${planning.url}`);
        },
        onPickObservationsReport(observationsReport) {
            const files = observationsReport.files.filter((file) => file.generationSucceed);
            if (files.length > 0) {
                this.$refs.popup.insertEmbed(
                    this.lastIndex,
                    'table',
                    observationsReport.files
                        .map(
                            (file) =>
                                `<tr><td><a href="https://app.atex-info.eu${file.url}">${file.name}</a></td></tr>`,
                        )
                        .join(''),
                );
            } else {
                this.$refs.popup.insert(
                    this.lastIndex,
                    files[0].name,
                    'link',
                    `https://app.atex-info.eu${files[0].url}`,
                );
            }
        },
        addPlanningLink() {
            this.lastIndex = this.$refs.popup.getIndex();
            this.$refs.planningPopup.open();
        },
        addObservationsReportLink() {
            this.lastIndex = this.$refs.popup.getIndex();
            this.$refs.observationsReportsPopup.open();
        },
        open(meetingReport) {
            this.meetingReport = meetingReport;
            this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
            this.$refs.popup.open();
        },
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            let nextMeetingTokenValue = '';
            if (this.meetingReport.nextMeeting && this.meetingReport.nextMeeting.date) {
                nextMeetingTokenValue = this.$t('project.editions.meetingReports.mailBody2', {
                    date: humanizeDateFull(this.meetingReport.nextMeeting.date),
                    hour: humanizeHour(this.meetingReport.nextMeeting.date),
                    location: this.meetingReport.nextMeeting.location,
                });
            }

            const reportLink = `https://app.atex-info.eu/api/projects/${this.$route.params.projectId}/meetings/${this.meetingReport.id}/cr.pdf`;
            return string
                .replaceAll('ProchaineReunion', nextMeetingTokenValue)
                .replaceAll('NomReunion', this.meetingReport.name)
                .replaceAll(
                    'TableauConvocations',
                    nextMeetingTokenValue &&
                        this.meetingReport.nextMeeting &&
                        this.meetingReport.nextMeeting.convocations.length
                        ? `<table style="border: black solid 1px; margin: 0.1em; border-collapse: collapse;">
                      <tr>
                         <th style="border: black solid 1px; padding: 0.1em">Lot/Mission</th>
                         <th  style="border: black solid 1px; padding: 0.1em">Attendu pour</th>
                      </tr>
                    ${this.meetingReport.nextMeeting.convocations
                        .map(
                            (convocation) =>
                                `<tr>
                                   <td style="border: black solid 1px; padding: 0.1em">${convocation.bundle?.label}</td>
                                   <td style="border: black solid 1px; padding: 0.1em; text-align:center">${humanizeHour(
                                       convocation.convocationDate,
                                   )}</td></tr>`,
                        )
                        .join('')}
                    </table>`
                        : '',
                )
                .replaceAll('DateReunion', humanizeDateFull(this.meetingReport.date))
                .replaceAll('LienCR', `<a href="${reportLink}">${this.meetingReport.fileName}</a>`);
        },
        replaceRecipientTokens(recipientIds = []) {
            return recipientIds.reduce(
                (acc, bundleId) =>
                    bundleId === 'meetingReport.recipients'
                        ? [...acc, ...this.meetingReport.recipientIds]
                        : [...acc, bundleId],
                [],
            );
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: [...this.replaceRecipientTokens(template.to), ...this.meetingReport.recipientIds],
                copyIds: this.replaceRecipientTokens(template.cc),
                attachments: [
                    {
                        fileName: this.meetingReport.fileName,
                        url: this.meetingReport.url,
                    },
                ],
            });
        },
    },
    data() {
        return {
            cacheKey: 'meetingReport.lastMailTemplate.' + this.$route.params.projectId,
            meetingReport: null,
            subscriptions: [],
            mailTemplates: [],
            observationsReportOptions: [],
            planningOptions: [],
            selectedMailTemplateId: null,
            lastIndex: 0,
        };
    },
};
</script>
