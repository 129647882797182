<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
            <div class="flex gap-2">
                <span class="text-lg">{{ reception.code }}</span>
                <span class="text-xl font-bold">{{ reception.name }}</span>
            </div>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('receptions.emissionDueDate') }} :</th>
                    <td class="p-2">{{ reception.emissionDueDate | humanizeDate }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('receptions.emissionDate') }} :</th>
                    <td class="p-2">{{ reception.emissionDate | humanizeDate }}</td>
                </tr>
                <tr v-if="reception.bundle">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.emitter') }} :</th>
                    <td class="p-2"><app-bundle :bundle="reception.bundle" /></td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('receptions.service') }} :</th>
                    <td class="p-2">
                        {{ reception.service && reception.service.name }}
                    </td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('receptions.conclusion') }} :</th>
                    <td class="p-2">
                        <app-select
                            :label="$t('receptions.conclusion')"
                            v-model="reception.conclusion"
                            @blur="save()"
                            :disabled="!isEmitter"
                            :show-label="false"
                        >
                            <option value="">{{ $t('receptions.conclusions.pending') }}</option>
                            <option value="approvedWithComments">
                                {{ $t('receptions.conclusions.approvedWithObservations') }}
                            </option>
                            <option value="approved">{{ $t('receptions.conclusions.approved') }}</option>
                            <option value="rejected">{{ $t('receptions.conclusions.rejected') }}</option>
                            <option value="tacitApproval">{{ $t('receptions.conclusions.tacitApproval') }}</option>
                        </app-select>
                    </td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('receptions.locations') }} :</th>
                    <td class="p-2">
                        <div v-for="location in reception.locations">
                            {{ location.fullName }}
                        </div>
                        <div v-if="reception.locations.length === 0">{{ $t('receptions.AllLocations') }}</div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import locationService from '@/services/location.service';
import { getReception, updateReception } from './receptions.service';
import AppBundle from '@/components/app-bundle/appBundle';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getServices } from '@/features/services/services.service';
import { getMapById } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { getProject } from '@/features/projects/projects.service';
export default {
    components: {
        AppSelect,
        AppBundle,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        async save() {
            await updateReception(this.$route.params.projectId, {
                id: this.reception.id,
                conclusion: this.reception.conclusion,
            });
        },
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                    getReception(this.$route.params.projectId, this.$route.params.receptionId),
                    getServices(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([bundleMap, folders, reception, services, project]) => {
                    const serviceMap = getMapById(services);
                    const locationMap = locationService.getLocationMap(folders);
                    this.reception = {
                        ...reception,
                        bundle: bundleMap[reception.bundleId],
                        locations: reception.locationIds.map((id) => locationMap[id]),
                        service: serviceMap[reception.serviceId],
                    };
                    this.isEmitter = project.me.bundleIds.includes(reception.bundleId);
                }),
            ];
        },
    },
    data() {
        return {
            subscriptions: [],
            isEmitter: false,
            reception: {
                name: this.$t('receptions.newName'),
                bundle: null,
                code: '',
                service: null,
                locations: [],
                emissionDueDate: null,
                emissionDate: null,
            },
        };
    },
};
</script>
