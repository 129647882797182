import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const checkListItems = {
    schema: {
        title: 'checkListItem schema',
        version: 0,
        description: 'describes a checkListItem',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                format: 'uuid',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'], format: 'uuid' },
            type: { type: ['string', 'null'] },
            title: { type: ['string', 'null'] },
            recipientIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                    format: 'uuid',
                },
            },
            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'], format: 'uuid' },
            updatedBy: { type: ['string', 'null'], format: 'uuid' },
            deletedBy: { type: ['string', 'null'], format: 'uuid' },
            rootVersion: { type: 'string', format: 'date-time' },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
