<template>
    <div class="AppSaveOnLeave hidden"></div>
</template>

<script>
import lifecycle from 'page-lifecycle/dist/lifecycle.es5';
export default {
    components: {},
    beforeMount() {
        lifecycle.addEventListener('statechange', this.handleVisibilityChange);
        window.addEventListener('beforeunload', this.unload);
    },
    beforeDestroy() {
        this.saveOnLeave();
        lifecycle.removeEventListener('statechange', this.handleVisibilityChange);
        window.removeEventListener('beforeunload', this.unload);
    },
    methods: {
        unload() {
            this.saveOnLeave();
        },
        async handleVisibilityChange(event) {
            if (event.newState === 'hidden') {
                await this.saveOnLeave();
            }
        },
        saveOnLeave() {
            if (this.dirty) {
                this.saveFn();
            }
        },
    },
    name: 'AppSaveOnLeave',
    props: {
        dirty: Boolean,
        invalid: Boolean,
        saveFn: Function,
    },
};
</script>
