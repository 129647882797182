export default {
    install: (Vue) => {
        Vue.filter('contact', contactFilter);
    },
};
export function contactFilter(contact) {
    if (!contact) {
        return '';
    }
    return (
        (contact.firstName && contact.firstName.length > 0 ? contact.firstName[0].toUpperCase() + '. ' : '') +
        (contact.lastName ? contact.lastName.toUpperCase() : '')
    );
}
