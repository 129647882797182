<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addCertificate()" :label="$t('certificates.newCertificate')" v-if="!readOnly" />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        :label="$t('commons.emitted') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="far"
                        :label="$t('project.follow.follow.far') + ' (' + farCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="obsolete"
                        :label="$t('project.follow.follow.obsolete')"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('code')"
                            >
                                {{ $t('certificates.code') }}
                                <div v-if="sortKey === 'code'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                <span>{{ $t('commons.name') }}</span>
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 10rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emitter')"
                            >
                                {{ $t('bundles.emitter') }}
                                <div v-if="sortKey === 'emitter'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDueDate')"
                            >
                                {{ $t('commons.expectedOn') }}
                                <div v-if="sortKey === 'emissionDueDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDate')"
                            >
                                {{ $t('commons.emittedOn') }}
                                <div v-if="sortKey === 'emissionDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="certificate in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + certificate.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="certificate.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(certificate)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ certificate.code }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'followCertificate',
                                            params: {
                                                ...$route.params,
                                                certificateId: certificate.id,
                                            },
                                        }"
                                    >
                                        {{ certificate.name }}
                                        <span v-if="!certificate.name || certificate.name.trim().length === 0">
                                            ...
                                        </span>
                                    </router-link>
                                    <div class="flex" v-if="certificate.emissionDate">
                                        <template v-for="attachment in certificate.attachments">
                                            <app-file-link
                                                :url="attachment.url"
                                                :fileName="attachment.name"
                                                :showName="false"
                                            ></app-file-link>
                                        </template>
                                    </div>
                                </div>
                            </td>
                            <td class="border-r p-1 truncate hidden sm:table-cell">
                                <div v-if="certificate.emitter" style="max-width: 10rem" class="truncate">
                                    <span class="">
                                        {{
                                            certificate.emitter.reference
                                                ? '#' + certificate.emitter.reference + ' ' + certificate.emitter.name
                                                : certificate.emitter.name
                                        }}
                                    </span>
                                </div>
                            </td>
                            <td
                                class="text-center border-r p-1 hidden sm:table-cell hidden sm:table-cell"
                                :class="{
                                    'text-red-600 font-bold':
                                        !certificate.emissionDate && certificate.emissionDueDate < new Date(),
                                }"
                            >
                                <app-date-link
                                    @enter="focusToNextEmissionDueDate(certificate)"
                                    :ref="'emissionDueDate_' + certificate.id"
                                    :disabled="readOnly"
                                    :label="$t('certificates.emissionDueDate')"
                                    :show-label="false"
                                    v-model="certificate.emissionDueDate"
                                    @input="updateEmissionDueDate(certificate, $event)"
                                ></app-date-link>
                            </td>
                            <td class="text-center border-r p-1 hidden sm:table-cell">
                                <app-date-link
                                    @enter="focusToNextDate(certificate)"
                                    :ref="'date_' + certificate.id"
                                    :disabled="readOnly"
                                    :default-date="certificate.emissionDate"
                                    :label="$t('certificates.emissionDate')"
                                    :show-label="false"
                                    v-model="certificate.emissionDate"
                                    @input="updateEmissionDate(certificate, $event)"
                                ></app-date-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-bundle-picker v-model="editedCertificate.emitter" :options="bundles"></app-bundle-picker>

                <app-date-input
                    v-model="editedCertificate.emissionDueDate"
                    :label="$t('certificates.emissionDueDate')"
                ></app-date-input>
                <app-date-input v-model="editedCertificate.emissionDate" :label="$t('certificates.emissionDate')" />
                <div class="p-2">
                    <app-checkbox
                        v-model="editedCertificate.applyRecommended"
                        :label="$t('preparations.applyRecommended')"
                    ></app-checkbox>
                    <app-tips>
                        {{ $t('preparations.applyRecommendedTip') }}
                    </app-tips>
                </div>
                <app-footer @click="saveMultiple()" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import { filterMatch, sortBy } from '@/services/sanitize.service';
import { differenceInWorkingDays } from '@/services/duration.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { createCertificate, getCertificates, removeCertificate, updateCertificate } from './certificates.service';

import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import IconFileDocumentOutline from '@/icons/IconFileDocumentOutline';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { queryProject } from '@/features/projects/projects.service';
import { getServices } from '@/features/services/services.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';

export default {
    components: {
        AppTips,
        AppFileLink,
        IconFileDocumentOutline,
        AppButton,
        AppMultiPicker,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppQuickActions,
        AppDateLink,
        AppCheckbox,
        AppSelect,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_certificates');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'code') {
                        return item.code;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    } else if (this.sortKey === 'emissionDueDate') {
                        return item.emissionDueDate;
                    } else if (this.sortKey === 'emissionDate') {
                        return item.emissionDate;
                    } else if (this.sortKey === 'emitter') {
                        return item.emitter ? item.emitter.reference + ' ' + item.emitter.name : '';
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: 'statusCriteriaType',
                    children: [
                        {
                            name: this.$t('project.follow.noEmitter'),
                            id: 'noEmitter',
                            criteriaType: '',
                            firstOfCriteriaType: false,
                            _isStatusTypeCriteriaType: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.reference ? bundle.reference + ' - ' + bundle.name : +bundle.name,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getCertificates(this.$route.params.projectId),
                    getServices(this.$route.params.projectId),
                    getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                ]).subscribe(([bundleMap, certificates, services, planning]) => {
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.items = certificates.map((certificate) => {
                        return {
                            ...certificate,
                            bundle: bundleMap[certificate.bundleId],
                            filterString: [
                                certificate.code,
                                certificate.name,
                                certificate.emitter
                                    ? certificate.emitter.reference + '' + certificate.emitter.name
                                    : '',
                            ].join(),
                            isSelected: this.selection.includes(certificate.id),
                            emitter: bundleMap[certificate.emitterId],
                            service: services.find((service) => service.id === certificate.serviceId),
                            taskPredecessors: certificate.predecessors.map((predecessor) => ({
                                ...predecessor,
                                ...(planning.find((task) => task.id === predecessor.taskId) || {}),
                            })),
                        };
                    });
                    this.doneCount = this.items.filter((item) => this.isDone(item)).length;
                    this.farCount = this.items.filter((item) => this.isFar(item)).length;
                    this.comingCount = this.items.filter(
                        (item) => !item.obsoleteAt && !this.isDone(item) && !this.isFar(item),
                    ).length;
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        scrollToLastVisited() {
            const lastVisitedCertificateId = localStorage.getItem(
                'certificate.lastVisitedCertificateId.' + this.$route.params.projectId,
            );
            if (lastVisitedCertificateId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedCertificateId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
        },
        saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            const patch = {};
            if (
                this.editedCertificate.emissionDueDate &&
                this.editedCertificate.emissionDueDate !== this.getSelectionCommonEmissionDueDate(selectedItems)
            ) {
                patch.emissionDueDate = this.editedCertificate.emissionDueDate;
            }
            if (
                this.editedCertificate.emitter &&
                this.editedCertificate.emitter !== this.getSelectionCommonEmitter(selectedItems)
            ) {
                patch.emitterId = this.editedCertificate.emitter.id;
            }
            if (
                this.editedCertificate.emissionDate &&
                this.editedCertificate.emissionDate !== this.getSelectionCommonEmissionDate(selectedItems)
            ) {
                patch.emissionDate = this.editedCertificate.emissionDate;
            }

            this.selection.map(async (itemId) => {
                const selectedItem = this.items.find((item) => itemId === item.id);
                if (this.editedCertificate.applyRecommended && !selectedItem.emissionDate) {
                    let recommendedDate = null;
                    if (selectedItem.taskPredecessors.length > 0) {
                        recommendedDate = selectedItem.taskPredecessors
                            .map((taskPredecessor) => {
                                if (taskPredecessor.type === 'ES') {
                                    return taskPredecessor.estimatedEndDate;
                                } else if (taskPredecessor.type === 'SS') {
                                    return taskPredecessor.realStartDate || taskPredecessor.startDate;
                                }
                            })
                            .filter((a) => !!a)
                            .sort()
                            .pop();
                    }
                    if (recommendedDate && recommendedDate > new Date()) {
                        patch.emissionDueDate = recommendedDate;
                    }
                }
                if (Object.keys(patch).length > 0) {
                    await updateCertificate(this.$route.params.projectId, { id: itemId, ...patch });
                }
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeCertificate(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedCertificate = {
                    emissionDueDate: this.getSelectionCommonEmissionDueDate(selectedItems),
                    emissionDate: this.getSelectionCommonEmissionDate(selectedItems),
                    emitter: this.getSelectionCommonEmitter(selectedItems),
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        getSelectionCommonEmitter(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emitter);
            if (firstItem && selectedItems.every((item) => item.emitter && item.emitter.id === firstItem.emitter.id)) {
                return firstItem.emitter;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.emissionDate && item.emissionDate.getTime() === firstItem.emissionDate.getTime(),
                )
            ) {
                return firstItem.emissionDate;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDueDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) =>
                        item.emissionDueDate && item.emissionDueDate.getTime() === firstItem.emissionDueDate.getTime(),
                )
            ) {
                return firstItem.emissionDueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'certificates.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('certificates.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addCertificate() {
            const result = await createCertificate(this.$route.params.projectId, {
                name: this.$t('certificates.newName'),
            });
            await this.$router.push({
                name: 'followCertificate',
                params: {
                    projectId: this.$route.params.projectId,
                    certificateId: result.id,
                },
            });
        },
        isDone(item) {
            return !item.obsoleteAt && item.emissionDate;
        },
        isFar(item) {
            if (item.obsoleteAt || this.isDone(item)) {
                return false;
            }
            const diff = differenceInWorkingDays(
                item.emissionDueDate,
                new Date(),
                this.agenda,
                item.emitter ? item.emitter.id : null,
            );
            return !item.emissionDate && diff > 1;
        },
        matchBundleFilter(item, bundleIds) {
            return bundleIds.includes(item.emitterId);
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        matchStatusFilter(item) {
            const isFar = this.isFar(item);
            const isDone = this.isDone(item);
            const isObsolete = !!item.obsoleteAt;
            return (
                (!isObsolete || this.obsolete) &&
                ((!isDone && !isFar && this.coming) || (isFar && this.far) || (isDone && this.done))
            );
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'certificates_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    done: this.done,
                    coming: this.coming,
                    far: this.far,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('certificates_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.done = !!parsedCache.done;
                this.coming = !!parsedCache.coming;
                this.far = !!parsedCache.far;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const noEmitterCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'noEmitter',
            );
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchString: this.matchString(stringCriteria, item),
                    matchNoEmitter: this.matchNoEmitter(noEmitterCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        matchNoEmitter(noEmitterCriteria, item) {
            return noEmitterCriteria.length === 0 || !item.emitter;
        },
        updateEmissionDueDate(certificate, date) {
            updateCertificate(this.$route.params.projectId, { id: certificate.id, emissionDueDate: date });
        },
        updateEmissionDate(certificate, date) {
            if (certificate.emissionDueDate) {
                updateCertificate(this.$route.params.projectId, { id: certificate.id, emissionDate: date });
            } else {
                updateCertificate(this.$route.params.projectId, {
                    id: certificate.id,
                    emissionDueDate: date,
                    emissionDate: date,
                });
            }
        },
        focusToNextDate(certificate) {
            let index = this.filteredItems.findIndex((aCertificate) => aCertificate.id === certificate.id);
            let nextCertificate = this.filteredItems[index + 1];
            while (!nextCertificate && index < this.filteredItems.length) {
                nextCertificate = this.filteredItems[++index];
            }
            if (nextCertificate) {
                this.$emit('select', nextCertificate);
                const element = this.$refs['date_' + nextCertificate.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextEmissionDueDate(certificate) {
            let index = this.filteredItems.findIndex((aCertificate) => aCertificate.id === certificate.id);
            let nextCertificate = this.filteredItems[index + 1];
            while (!nextCertificate && index < this.filteredItems.length) {
                nextCertificate = this.filteredItems[++index];
            }
            if (nextCertificate) {
                this.$emit('select', nextCertificate);
                const element = this.$refs['emissionDueDate_' + nextCertificate.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            loading: true,
            quickActions: [
                {
                    name: this.$t('certificates.newCertificate'),
                    run: () => this.addCertificate(),
                },
            ],
            doneCount: 0,
            comingCount: 0,
            farCount: 0,
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            editedCertificate: {
                emissionDueDate: null,
                emissionDate: null,
                type: null,
                locations: [],
                service: null,
                emitter: null,
            },
            items: [],
            bundles: [],
            agenda: [],
            done: false,
            coming: true,
            far: false,
            obsolete: false,
            filterValue: [],
        };
    },
};
</script>
