import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
export const mailTemplates = {
    schema: {
        title: 'mailTemplate schema',
        version: 1,
        description: 'describes a mailTemplate',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: {
                type: ['string', 'null'],
            },
            type: {
                type: ['string', 'null'],
            },
            name: {
                type: ['string', 'null'],
            },
            isCustom: {
                type: ['boolean', 'null'],
            },
            subject: {
                type: ['string', 'null'],
            },
            body: {
                type: ['string', 'null'],
            },
            to: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            cc: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            requiredFeatures: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    autoMigrate: false,
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId, {
                pullLimit: 0,
                mapFn: (collection, projectId, documents) =>
                    documents.map((doc) => {
                        if (doc.projectId && doc.projectId !== projectId) {
                            doc.deletedAt = new Date().toISOString();
                        }
                        return doc;
                    }),
            });
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: { 1: (doc) => doc },
};
