<template>
    <app-popup ref="popup">
        <app-separator label="Ecrire un mail"></app-separator>
        <form class="p-2 flex gap-2 flex-col">
            <slot></slot>
            <app-multi-picker
                v-model="recipients"
                :options="bundles"
                :label="$t('project.follow.observation.recipients')"
            >
                <template v-slot:option="{ option }">
                    <app-bundle :bundle="option" />
                </template>
            </app-multi-picker>
            <app-multi-picker v-model="copy" :options="bundleCopyOptions" label="En Copie">
                <template v-slot:option="{ option }">
                    <app-bundle :bundle="option" />
                </template>
            </app-multi-picker>
            <app-input-text v-model="subject" label="Objet"></app-input-text>
            <app-text-editor
                v-model="body"
                ref="input"
                label="Corps"
                :showToolbar="true"
                end-point="documents?scope=planningExportEmail"
            ></app-text-editor>
            <slot name="body-footer-toolbar"></slot>
            <app-label label="Pièces-jointes" v-if="attachments.length">
                <div class="flex flex-col gap-2 mt-2">
                    <app-file-link
                        v-for="attachment in attachments"
                        :key="attachment.url"
                        :file-name="attachment.fileName"
                        :url="attachment.url"
                    ></app-file-link>
                    <!--     <app-multiple-upload-button
                        class="text-sm"
                        size="mini"
                        icon="icon-paperclip"
                        label="Ajouter une pièce jointe"
                        name="Ajouter une pièce jointe"
                        :resize="1024"
                        @loaded="onNewAttachment"
                    ></app-multiple-upload-button> -->
                </div>
            </app-label>
            <app-footer ok-label="Télécharger le mail" @click="validate"></app-footer>
        </form>
    </app-popup>
</template>

<script>
import { getBundles } from '@/features/bundles/bundles.service';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import { queryProject } from '@/features/projects/projects.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import { getEMLFile } from '@/features/emls/emls.service';

export default {
    components: {
        AppFileLink,
        AppMultipleUploadButton,
        AppTips,
        AppFooter,
        AppTextEditor,
        AppInputText,
        AppBundle,
        AppMultiPicker,
        AppLabel,
        AppSeparator,
        AppPopup,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.me = project.me;
        });
        this.subscriptions = [
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                this.bundles = bundles;
            }),
        ];
    },
    methods: {
        getIndex() {
            return this.$refs.input.getIndex();
        },
        insert(index, text, format, value) {
            this.$refs.input.insert(index, text, format, value);
        },
        insertEmbed(index, type, value) {
            return this.$refs.input.insertEmbed(index, type, value);
        },
        updateMail(defaultConfig) {
            this.subject = defaultConfig.subject;
            this.body = defaultConfig.body;
            this.attachments = defaultConfig.attachments || [];
            this.recipients =
                defaultConfig.recipientIds && defaultConfig.recipientIds.length
                    ? this.bundles.filter((bundle) => defaultConfig.recipientIds.includes(bundle.id))
                    : [];
            this.copy =
                defaultConfig.copyIds && defaultConfig.copyIds.length
                    ? this.bundles.filter((bundle) => defaultConfig.copyIds.includes(bundle.id))
                    : [];
        },
        open() {
            this.$refs.popup.open();
        },
        onNewAttachment(attachments) {
            this.attachments = [...this.attachments, ...attachments];
        },
        validate() {
            getEMLFile(this.$route.params.projectId, {
                subject: this.subject,
                body: this.body,
                attachments: this.attachments,
                recipientIds: this.recipients.map((item) => item.id),
                copyIds: this.copy.map((item) => item.id),
            });
        },
    },
    computed: {
        bundleCopyOptions() {
            return this.bundles.map((bundle) => ({
                ...bundle,
                disabled: this.recipients.find((recipient) => recipient.id === bundle.id),
            }));
        },
    },
    data() {
        return {
            subject: '',
            body: '',
            recipients: [],
            attachments: [],
            copy: [],
            bundles: [],
        };
    },
};
</script>
