<template>
    <div class="relative m-auto w-2/3 overflow-y-auto">
        <img :src="src" class="m-auto" />
        <div class="overflow-hidden inset-0 absolute">
            <template v-for="marker in markers">
                <AppMarker
                    width="60"
                    height="60"
                    :marker="marker"
                    :label="marker.index || marker.index === 0 ? marker.index + 1 : ''"
                    :key="marker.id"
                    :active="!marker.id"
                    :draggable="marker.draggable"
                    @move="$emit('move', marker)"
                ></AppMarker>
            </template>
        </div>
    </div>
</template>
<script>
import AppMarker from '../appMarker/AppMarker';

export default {
    components: {
        AppMarker,
    },
    props: {
        src: String,
        markers: Array,
        activeMarkerId: String,
    },
};
</script>
