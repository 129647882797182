import { getAPIHeaders } from '@/services/api.service';

function postData(data, uri) {
    if (navigator.onLine) {
        const token = localStorage.getItem('token');
        if (token) {
            let headers = getAPIHeaders();
            headers.append('content-type', `application/json`);
            return fetch(
                new Request(uri, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                    credentials: 'omit',
                }),
            );
        }
    }
}
export function track(data) {
    return postData(data, '/api/pages');
}
export function reportError(data) {
    return postData(data, '/api/errors');
}
