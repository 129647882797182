<template>
    <span class="ellipsis">
        <span v-html="internalHTMLContent"></span>
    </span>
</template>
<script>
import { reduceContent } from '@/components/appClamp/appClamp.service';

export default {
    props: ['htmlContent', 'numberOfLines'],
    computed: {
        internalHTMLContent() {
            return reduceContent(this.htmlContent, this.numberOfLines);
        },
    },
};
</script>
