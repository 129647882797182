import { ImageOverlay, DomUtil, Util } from 'leaflet';

const PDFLayer = ImageOverlay.extend({
    options: {
        customOptions: {
            pdf: null,
            rotation: 0,
            maxArea: null,
            mapBound: null,
        },
    },
    _initImage: function () {
        this.customOptions = this.options.customOptions;

        this._image = document.createElement('canvas');
        this.imageContext = this._image.getContext('2d');

        DomUtil.addClass(this._image, 'leaflet-image-layer');
        if (this._zoomAnimated) {
            DomUtil.addClass(this._image, 'leaflet-zoom-animated');
        }
        if (this.options.className) {
            DomUtil.addClass(this._image, this.options.className);
        }

        this._image.onselectstart = Util.falseFn;
        this._image.onmousemove = Util.falseFn;

        // @event load: Event
        // Fired when the ImageOverlay layer has loaded its image
        Util.bind(this.fire, this, 'load');
        Util.bind(this._overlayOnError, this, 'error');

        if (this.options.zIndex) {
            this._updateZIndex();
        }
        this._image.alt = this.options.alt;
    },
    async loadPage(pageNumber, rotation = this.customOptions.rotation || 0) {
        if (pageNumber < 0 || pageNumber > this.customOptions.pdf.numPages) {
            return;
        }
        const page = await this.customOptions.pdf.getPage(pageNumber);
        const finalRotation = rotation + (page._pageInfo.rotate || 0);
        const pdfSize = page.getViewport({
            scale: 1,
            rotation: finalRotation,
        });
        let maxArea = this.customOptions.maxArea || { width: 4096, height: 4096 };
        const hScale = Math.round((maxArea.width / pdfSize.width) * 1000) / 1000;
        const vScale = Math.round((maxArea.height / pdfSize.height) * 1000) / 1000;
        this.scale = Math.min(9, hScale, vScale);
        const viewport = page.getViewport({
            scale: this.scale,
            rotation: finalRotation,
        });
        const width = viewport.width;
        const height = viewport.height;
        this.canvas = document.createElement('canvas');
        this.canvas.height = height;
        this.canvas.width = width;
        this._image.height = height;
        this._image.width = width;

        const mapBound = this.customOptions.mapBound;
        const mapWidth = mapBound.getEast() - mapBound.getWest();
        const mapHeight = mapBound.getNorth() - mapBound.getSouth();
        const hMapScale = mapWidth / width;
        const vMapScale = mapHeight / height;
        const mapScale = Math.min(hMapScale, vMapScale);

        let bounds = [
            [mapBound.getNorth() - height * mapScale, mapBound.getWest() + mapWidth / 2 - (width * mapScale) / 2],
            [mapBound.getNorth(), mapBound.getWest() + width * mapScale + mapWidth / 2 - (width * mapScale) / 2],
        ];
        this.setBounds(bounds);

        this.imageContext.fillStyle = 'white';
        this.imageContext.fillRect(0, 0, width, height);

        const render_context = {
            canvasContext: this.canvas.getContext('2d'),
            viewport: viewport,
        };
        return page.render(render_context).promise.then(() => {
            this._image.getContext('2d').drawImage(this.canvas, 0, 0);
        });
    },
});

export default function AppLeafletPDFOverlay(customOptions) {
    return new PDFLayer(
        null,
        [
            [0, 0],
            [100, 100],
        ],
        {
            customOptions,
            zIndex: 10,
        },
    );
}
