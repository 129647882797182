<template>
    <div class="flex items-center justify-center w-full text-xs flex-wrap">
        <span class="p-1 observations-to-do">
            {{ filteredToDo.toString().padStart(3, ' ') }}
            <span>{{ this.$t('observations.toDo') }}</span>
        </span>
        <span class="p-1 mx-4 observations-to-fix">
            {{ filteredToFix.toString().padStart(3, ' ') }}
            <span>{{ this.$t('observations.toFix') }}</span>
        </span>
        <span class="p-1 observations-done">
            {{ filteredDone.toString().padStart(3, ' ') }}
            <span>{{ this.$t('observations.done') }}</span>
        </span>
        <span class="p-1 mx-4 bg-green-500 observations-validated">
            {{ filteredValidated.toString().padStart(3, ' ') }}
            <span>{{ this.$t('observations.validated') }}</span>
        </span>
        <span class="p-1">
            {{ filteredItems.length }}/{{ items.length }}
            <span>{{ this.$t('commons.total') }}</span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        filteredItems: { type: Array, default: () => [] },
    },
    computed: {
        observations() {
            return this.items.filter((item) => item.__typename === 'Observation');
        },
        filteredToDo() {
            return this.filteredItems.filter(
                (item) => item.__typename === 'Observation' && !item.resolvedAt && item.type === 'onTodoWork',
            ).length;
        },
        filteredToFix() {
            return this.filteredItems.filter(
                (item) =>
                    item.__typename === 'Observation' &&
                    !item.resolvedAt &&
                    item.type === 'onDoneWork' &&
                    item.type === 'other',
            ).length;
        },
        filteredDone() {
            return this.filteredItems.filter(
                (item) => item.__typename === 'Observation' && item.resolvedAt && !item.validatedAt,
            ).length;
        },
        filteredValidated() {
            return this.filteredItems.filter(
                (item) => item.__typename === 'Observation' && item.resolvedAt && item.validatedAt,
            ).length;
        },
    },
};
</script>
