import { sortBy } from './sanitize.service';

function matchLocationCriteria(criteria, locationCandidates) {
    if (!criteria || criteria.length === 0) {
        return true;
    }
    if (criteria.find((criterion) => criterion.id === 'noLocation') && locationCandidates.length === 0) {
        return true;
    }
    if (!locationCandidates || locationCandidates.length === 0) {
        return false;
    }
    for (const criterion of criteria) {
        const criteriaPath = criterion.path || locationToPath(criterion);
        for (const location of locationCandidates) {
            const locationPath = location.path || locationToPath(location);
            if (locationPath.startsWith(criteriaPath)) {
                return true;
            }
        }
    }
    return false;
}

function filterFromPerimeter(bundle, services, locations, supports) {
    let allowedLocations = getAllowedLocationFromPerimeter(bundle, services, locations);
    if (allowedLocations.length === 0) {
        return supports;
    } else {
        return supports.filter((support) => {
            if (support.locations) {
                for (const supportLocation of support.locations) {
                    for (const location of allowedLocations) {
                        if (locationToPath(location).startsWith(locationToPath(supportLocation))) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
    }
}

function getLocationFromService(service) {
    return service.tasks.reduce((acc, task) => [...acc, task.location], []);
}

function getAllowedLocationFromPerimeter(bundle, services, locations) {
    let allowedLocations = [];
    if (locations && locations.length > 0) {
        allowedLocations = locations;
    } else if (services && services.length > 0) {
        allowedLocations = services.reduce((acc, service) => [...acc, ...getLocationFromService(service)], []);
    } else if (bundle && bundle) {
        allowedLocations = bundle.services.reduce((acc, service) => [...acc, ...getLocationFromService(service)], []);
    }
    return allowedLocations;
}
export function mergeLocationsPathsByFolders(locations) {
    const folders = {};
    for (const location of locations) {
        if (!folders[location.parentId]) {
            folders[location.parentId] = { locations: [location] };
        } else {
            folders[location.parentId].locations.push(location);
        }
    }
    return Object.keys(folders).map(
        (folderId) =>
            (folders[folderId].locations[0].folderName
                ? folders[folderId].locations[0].folderName + ' > '
                : folders[folderId].locations[0].parent
                ? folders[folderId].locations[0].parent.name + ' > '
                : '') +
            sortBy(folders[folderId].locations, (location) => location.name.replace('+', 'z+'))
                .map((location) => location.name)
                .join(', '),
    );
}
function locationToPath(location) {
    if (!location) {
        return '';
    }
    const type = (location.__typename || location.type).toLowerCase();
    if (type === 'folder') {
        return location.id;
    } else if (type === 'location') {
        return [(location.folder || location.parent).id, location.id].join();
    } else if (type === 'zone') {
        return [
            location.location?.folder?.id || location.parent?.parent?.id,
            (location.location || location.parent).id,
            location.id,
        ].join();
    } else if (type === 'room') {
        return [
            location.zone?.location?.folder?.id || location.parent?.parent?.parent?.id,
            location.zone?.location?.id || location.parent?.parent?.id,
            (location.zone || location.parent).id,
            location.id,
        ].join();
    }
}
function buildLocationOptions(locationTree) {
    const folders = locationTree;
    const locations = folders.reduce((acc, folder) => [...acc, ...folder.locations], []);
    const zones = locations.reduce((acc, location) => [...acc, ...location.zones], []);
    const rooms = zones.reduce((acc, zone) => [...acc, ...zone.rooms], []);
    return [
        ...folders.map((folder) => ({ ...folder, locations: [] })),
        ...locations.map((location) => ({
            id: location.id,
            parentId: location.parentId,
            type: location.type,
            __typename: location.__typename,
            name: location.name,
            fullName: location.fullName,
            path: location.path,
            folderName: location.parent.name,
        })),
        ...zones.map((zone) => ({
            id: zone.id,
            type: zone.type,
            __typename: zone.__typename,
            name: zone.name,
            fullName: zone.fullName,
            path: zone.path,
        })),
        ...rooms.map((room) => ({
            id: room.id,
            type: room.type,
            __typename: room.__typename,
            name: room.name,
            fullName: room.fullName,
            path: room.path,
        })),
    ];
}
function getLocationMap(locationTree) {
    const folders = locationTree;
    const locations = folders.reduce((acc, folder) => [...acc, ...folder.locations], []);
    const zones = locations.reduce((acc, location) => [...acc, ...location.zones], []);
    const rooms = zones.reduce((acc, zone) => [...acc, ...zone.rooms], []);
    return [...folders, ...locations, ...zones, ...rooms].reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
}

export default {
    buildLocationOptions,
    matchLocationCriteria,
    getLocationFromService,
    getAllowedLocationFromPerimeter,
    filterFromPerimeter,
    mergeLocationsPathsByFolders,
    getLocationMap,
};
