<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <div class="flex items-center w-full relative">
            <app-back-button @back="$emit('back')" class="mb-2 mr-2" />
            <div class="absolute left-50 flex flex-col items-center">
                <h1 class="text-lg flex-grow font-bold whitespace-nowrap">
                    {{ observation.name }}
                </h1>
            </div>
        </div>
        <ObservationForm
            v-if="isAdmin || (!observation.validatedAt && isMember)"
            ref="form"
            :saveFn="save"
            :id="observation.id"
            @input="$emit('input', $event)"
            @deleted="$emit('deleted', $event)"
        />
        <read-only-zone-observation v-else></read-only-zone-observation>
    </main>
</template>

<script>
import AppBackButton from '../../components/appBackButton/AppBackButton';
import ObservationForm from './ObservationForm';
import { getObservation, sanitizeObservation, updateObservation } from '@/features/observations/observation.service';
import { updateBreadCrumbs } from '@/state/state';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import ReadOnlyZoneObservation from '@/features/tours/ReadOnlyZoneObservation.vue';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        ReadOnlyZoneObservation,
        ObservationForm,
        AppBackButton,
    },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    beforeRouteLeave(to, from, next) {
        return this.$refs.form.beforeRouteLeave(to, from, next);
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getObservation(this.$route.params.projectId, this.$route.params.observationId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([bundleMap, observation, project]) => {
                    if (!observation) {
                        this.$emit('back');
                    } else {
                        this.observation = {
                            ...observation,
                            validatedBy: bundleMap[observation.validatedBy],
                            obsoleteBy: bundleMap[observation.obsoleteBy],
                            resolvedBy: bundleMap[observation.resolvedBy],
                            reportedBy: bundleMap[observation.reportedBy],
                        };
                    }
                    this.isAdmin = project.me.allowedFeatures.includes('project_bundles');
                    this.isMember =
                        project.me.bundleIds.includes(observation.reportedBy) ||
                        project.me.bundleIds.some((bundleId) => observation.recipientIds.includes(bundleId));
                    updateBreadCrumbs({
                        observationName: this.observation.title,
                    });
                }),
            ];
        },
        save(observation) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                ...sanitizeObservation(observation),
            });
        },
    },
    data() {
        return { observation: {}, isAdmin: false, isMember: false };
    },
};
</script>
