<template>
    <ValidationObserver v-slot="{ invalid, dirty }" class="h-full block" ref="observer">
        <form class="mx-5">
            <app-fieldset :label="$t('services.info')" :show-legend="false">
                <app-input-text
                    :label="$t('commons.name')"
                    v-model="directory.name"
                    class="mt-4"
                    :required="true"
                    @blur="save"
                />
            </app-fieldset>
            <table class="mx-5">
                <tbody>
                    <tr>
                        <th scope="row" class="p-2 text-right">
                            {{ $t('services.amount') }} :
                            <p class="italic font-light text-gray-700 text-xs">
                                {{ $t('services.amountTooltip') }}
                            </p>
                        </th>
                        <td class="p-2 flex">
                            {{ $n(amount, 'currency') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
    </ValidationObserver>
</template>

<script>
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import AppFooter from '../../components/appFooter/AppFooter';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppInputText from '../../components/appInputText/AppInputText';
import AppLegend from '../../components/appLegend/AppLegend';
import { getServices } from './services.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { getDirectories, updateDirectory } from '@/features/services/directories.service';
import { combineLatest } from 'rxjs';
export default {
    components: { AppSaveOnLeave, AppInputText, AppFieldset, AppFooter, AppButton, AppSeparator, AppLegend },
    async created() {
        this.init();
    },
    watch: {
        async $route() {
            if (this.$refs.observer && this.$refs.observer.flags.dirty) {
                await this.save();
            }
            this.init();
        },
    },
    data() {
        return {
            directory: {},
            amount: {},
            subscriptions: [],
        };
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getDirectories(this.$route.params.projectId),
                    getServices(this.$route.params.projectId),
                ]).subscribe(([directories, services]) => {
                    this.directory = directories.find((directory) => directory.id === this.$route.params.directoryId);
                    this.amount = this.getIncludedServices(this.directory, services, directories).reduce(
                        (acc, service) => acc + service.amount,
                        0,
                    );
                }),
            ];
        },
        getIncludedServices(directory, services, directories) {
            const deepChild = directories
                .filter((aDirectory) => aDirectory.parentDirectoryId === directory.id)
                .map((aDirectory) => this.getIncludedServices(aDirectory, services, directories))
                .reduce((acc, items) => [...acc, ...items], []);
            const serviceChild = services.filter((service) => service.parentDirectoryId === directory.id);
            return [...deepChild, ...serviceChild];
        },
        save() {
            if (this.$refs.observer) {
                this.$refs.observer.reset();
            }
            return updateDirectory(this.$route.params.projectId, {
                id: this.directory.id,
                name: this.directory.name,
            });
        },
    },
};
</script>
