<template>
    <div>
        <div class="flex justify-center items-center" v-if="numPages">
            <app-button
                size="mini"
                icon="icon-chevron-left"
                :disabled="value <= 1 || disabled"
                @click="$emit('input', value - 1)"
            />
            <div class="mx-2 flex justify-center items-center whitespace-nowrap">
                <app-number-input
                    :value="value"
                    @input="$emit('input', $event)"
                    :disabled="disabled"
                    style="width: 50px"
                    id="page"
                    type="number"
                    format="integer"
                    :show-label="false"
                    :label="$t('pdf.currentPage')"
                    :show-tips="false"
                />
                / {{ numPages }}
            </div>
            <app-button
                size="mini"
                icon="icon-chevron-right"
                :disabled="value >= numPages || disabled"
                @click="$emit('input', value + 1)"
            />
        </div>
    </div>
</template>
<script>
import AppNumberInput from '../appNumberInput/AppNumberInput';
import AppButton from '../appButton/AppButton';

export default {
    components: {
        AppButton,
        AppNumberInput,
    },
    props: {
        numPages: Number,
        value: Number,
        disabled: Boolean,
    },
};
</script>
