<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <title v-if="title">{{ title }}</title>
        <path
            d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        title: String,
    },
};
</script>
