var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('app-separator',{staticClass:"cursor-pointer mb-0",on:{"click":function($event){$event.preventDefault();return _vm.onToggleDetail.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center font-normal w-full gap-1"},[_c('icon',{staticClass:"mr-3",attrs:{"name":_vm.detailIsOpen ? 'icon-chevron-down' : 'icon-chevron-up'}}),_c('div',{staticClass:"flex items-center mr-3"},[_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'bundle',
                        params: { bundleId: _vm.service.bundleId, projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.service && _vm.service.bundle ? _vm.service.bundle.label : '')+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'service',
                        params: {
                            bundleId: _vm.service.bundleId,
                            serviceId: _vm.service.id,
                            projectId: _vm.$route.params.projectId,
                        },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.service.name))])])],1)],1)]),(_vm.detailIsOpen)?_c('div',[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }