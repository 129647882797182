<template>
    <component v-bind:is="tag" class="app-label block" :[forKey]="labelFor">
        <span class="whitespace-nowrap" :class="labelClass" v-show="showLabel">
            {{ label }}
            <span v-if="required" class="text-red-700 ml-1" :title="$t('commons.required')">*</span>
        </span>
        <slot></slot>
    </component>
</template>
<script>
export default {
    props: {
        label: String,
        labelClass: { type: String, default: 'text-gray-700 font-bold' },
        labelFor: String,
        showLabel: { type: Boolean, default: true },
        required: { type: Boolean, default: false },
        tag: { type: String, default: 'label' },
    },
    computed: {
        forKey() {
            return this.labelFor ? 'for' : null;
        },
    },
};
</script>
