<template>
    <read-only-meeting-form v-if="readOnly"></read-only-meeting-form>
    <main class="max-w-5xl m-5 mb-10 w-full" v-else>
        <meeting-form :meeting-id="meeting.id"></meeting-form>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { createMeeting, getMeetings, initNewMeeting } from './meetings.service';
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import MeetingForm from '@/features/meetings/MeetingForm';
import ReadOnlyMeetingForm from '@/features/meetings/ReadOnlyMeetingForm';
import { queryProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
export default {
    components: {
        ReadOnlyMeetingForm,
        MeetingForm,
        AppQuickActions,
    },
    async created() {
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
            getMeetings(this.$route.params.projectId).subscribe((meetings) => {
                this.meetings = meetings;
                this.init();
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.project = project;
            this.readOnly = !project.me.allowedFeatures.includes('project_meetings');
        });
    },
    watch: {
        $route() {
            this.init();
        },
    },
    methods: {
        init() {
            this.meeting = this.meetings.find((meeting) => meeting.id === this.$route.params.meetingId);
            updateBreadCrumbs({ meetingName: this.meeting.name });
        },
    },
    data() {
        return {
            readOnly: true,
            meeting: {},
            project: null,
            quickActions: [
                {
                    name: this.$t('meetings.newMeeting'),
                    run: async () => {
                        const meeting = await initNewMeeting(this.project, this.agenda, this.meetings, (key) =>
                            this.$t(key),
                        );
                        const result = await createMeeting(this.$route.params.projectId, meeting);
                        this.$router.push({
                            name: 'followMeeting',
                            params: {
                                projectId: this.$route.params.projectId,
                                meetingId: result.id,
                            },
                        });
                    },
                },
            ],
        };
    },
};
</script>
