<template>
    <AppList
        :items="references"
        :filterFn="filterFn"
        @removeItem="remove"
        @navigateTo="navigateTo"
        :removable="!readOnly"
        class="max-w-5xl"
        :line-height="50"
        :new-item-label="$t('project.follow.planning.newReference')"
        @newItem="createNewReference"
    >
        <template v-slot:item="{ item }">
            <div>
                <span class="text-lg font-bold mx-1">{{ item.name }}</span>
                <span class="text-xs text-gray-700">{{ item.createdAt | humanizeDate }}</span>
            </div>
        </template>
    </AppList>
</template>

<script>
import { confirm } from '../../dialogs/dialogs.service';
import { getReferences, removeReference, createReference } from './references.service';
import AppList from '../../../components/appList/AppList';
import { error } from '@/features/toasts/toats.service';
import { queryProject } from '@/features/projects/projects.service';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export default {
    components: { AppList },
    created() {
        this.subscriptions = [
            getReferences(this.$route.params.projectId).subscribe((references) => {
                this.references = references;
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
    },
    methods: {
        async createNewReference() {
            const tasks = await firstValueFrom(
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
            );
            if (tasks.length === 0) {
                error(this.$t('project.follow.planning.newReferenceDisabledWhenNoTasks'));
            } else {
                return createReference(this.$route.params.projectId, {
                    name:
                        this.$t('project.follow.planning.newReferenceNamePrefix') + ' ' + (this.references.length + 1),
                    dates: tasks.map((task) => {
                        if (!task.realStartDate) {
                            return {
                                taskId: task.id,
                                startDate: task.startDate?.toISOString(),
                                endDate: task.endDate?.toISOString(),
                            };
                        } else {
                            return {
                                taskId: task.id,
                                startDate: (task.referenceStartDate || task.startDate)?.toISOString(),
                                endDate: (task.referenceEndDate || task.endDate)?.toISOString(),
                            };
                        }
                    }),
                });
            }
        },
        filterFn(filter) {
            if (filter.length > 0) {
                return this.references.filter(({ name }) => name.includes(filter));
            } else {
                return this.references;
            }
        },
        async remove(reference) {
            if (await confirm(this.$t('project.follow.references.confirmMessage'))) {
                await removeReference(this.$route.params.projectId, reference.id);
            }
        },
        navigateTo: function (event) {
            this.$router.push({
                name: 'reference',
                params: { projectId: this.$route.params.projectId, referenceId: event.id },
            });
        },
    },
    data() {
        return {
            references: [],
            readOnly: false,
            subscriptions: [],
        };
    },
};
</script>
