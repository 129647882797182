<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full flex flex-col gap-2">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button
                    @click="addDeliveryContact()"
                    :label="$t('project.information.deliveryContacts.newDeliveryContact')"
                    v-if="!readOnly"
                />
            </div>
            <div class="flex flex-col gap-1">
                <app-multi-picker
                    ref="filter"
                    icon="icon-magnify"
                    :allowStringCriteria="true"
                    class="w-full my-2"
                    v-model="filterValue"
                    :options="filterOptions"
                    :strictMatching="true"
                >
                    <template v-slot:option="{ option }">
                        <span>{{ option.name || option.label }}</span>
                    </template>
                </app-multi-picker>
                <app-checkbox
                    class="text-xs"
                    v-if="!hideShowArchived"
                    v-model="showArchived"
                    :label="$t('project.information.deliveryContacts.showArchived')"
                ></app-checkbox>
            </div>
            <div class="flex justify-between">
                <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                    <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                    <option value="modify">{{ $t('commons.actionModify') }}</option>
                    <option value="delete">{{ $t('commons.actionDelete') }}</option>
                </app-select>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>

                        <th style="width: 2rem" class="text-left border-r p-1" v-if="showArchived || hideShowArchived">
                            <span :title="$t('project.information.deliveryContacts.isArchived')">
                                {{ $t('project.information.deliveryContacts.isArchivedShort') }}
                            </span>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <span :title="$t('project.information.deliveryContacts.isOccupant')">
                                {{ $t('project.information.deliveryContacts.isOccupantShort') }}
                            </span>
                        </th>
                        <th style="width: 15rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                {{ $t('commons.name') }}
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('type')"
                            >
                                {{ $t('commons.type') }}
                                <div v-if="sortKey === 'type'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 15rem" class="text-left border-r p-1">
                            {{ $t('commons.coordinate') }}
                        </th>
                        <th style="width: 15rem" class="text-left border-r p-1">
                            {{ $t('zones.zones') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="deliveryContact in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + deliveryContact.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="deliveryContact.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(deliveryContact)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1" v-if="showArchived">
                                <div
                                    :title="$t('project.information.deliveryContacts.isArchived')"
                                    class="flex justify-center"
                                >
                                    <icon-archive
                                        width="16"
                                        height="16"
                                        v-if="deliveryContact.isArchived"
                                    ></icon-archive>
                                </div>
                            </td>
                            <td class="border-r p-1">
                                <div
                                    :title="$t('project.information.deliveryContacts.isOccupant')"
                                    class="flex justify-center"
                                >
                                    <icon-home-account
                                        width="16"
                                        height="16"
                                        v-if="deliveryContact.isOccupant"
                                    ></icon-home-account>
                                </div>
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'deliveryContact',
                                            params: {
                                                ...$route.params,
                                                deliveryContactId: deliveryContact.id,
                                            },
                                        }"
                                    >
                                        {{
                                            (deliveryContact.companyName ? deliveryContact.companyName + ' - ' : '') +
                                            (deliveryContact.lastName ? deliveryContact.lastName + ' ' : '') +
                                            (deliveryContact.firstName ? deliveryContact.firstName : '')
                                        }}
                                    </router-link>
                                </div>
                            </td>
                            <td class="border-r p-1">
                                {{ $t('project.information.deliveryContacts.types.' + deliveryContact.type) }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex flex-col">
                                    <div><app-phone :number="deliveryContact.phone"></app-phone></div>
                                    <div><app-mail :email="deliveryContact.email"></app-mail></div>
                                </div>
                            </td>
                            <td class="border-r p-1">
                                <div class="flex flex-col" v-if="deliveryContact.locations.length <= 3">
                                    <template v-for="zone in deliveryContact.locations">
                                        <p>{{ zone.fullName }}</p>
                                    </template>
                                </div>
                                <app-tips
                                    v-else
                                    :title="deliveryContact.locations.map((zone) => zone.fullName).join('\n')"
                                >
                                    {{ deliveryContact.locations.length }} {{ $t('zones.zones') }}
                                </app-tips>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-input-text
                    :label="$t('project.information.deliveryContacts.companyName')"
                    v-model="editedContact.companyName"
                    class="col-span-2"
                ></app-input-text>
                <app-select :label="$t('commons.type')" v-model="editedContact.type" class="col-span-2">
                    <option></option>
                    <option value="manager">
                        {{ $t('project.information.deliveryContacts.types.manager') }}
                    </option>
                    <option value="owner">
                        {{ $t('project.information.deliveryContacts.types.owner') }}
                    </option>
                    <option value="tenant">{{ $t('project.information.deliveryContacts.types.tenant') }}</option>
                    <option value="other">
                        {{ $t('project.information.deliveryContacts.types.other') }}
                    </option>
                </app-select>
                <app-select
                    :label="$t('project.information.deliveryContacts.isOccupant')"
                    v-model="editedContact.isOccupant"
                    class="col-span-2"
                >
                    <option></option>
                    <option value="true">
                        {{ $t('project.information.deliveryContacts.isOccupant') }}
                    </option>
                    <option value="false">
                        {{ $t('project.information.deliveryContacts.NotOccupant') }}
                    </option>
                </app-select>
                <app-select
                    :label="$t('project.information.deliveryContacts.isArchived')"
                    v-model="editedContact.isArchived"
                    class="col-span-2"
                >
                    <option></option>
                    <option value="true">
                        {{ $t('project.information.deliveryContacts.isArchived') }}
                    </option>
                    <option value="false">
                        {{ $t('project.information.deliveryContacts.notArchived') }}
                    </option>
                </app-select>
                <app-select
                    :label="$t('project.information.deliveryContacts.hasAtexAccess')"
                    v-model="editedContact.hasAtexAccess"
                    class="col-span-2"
                >
                    <option></option>
                    <option value="true">
                        {{ $t('project.information.deliveryContacts.hasAtexAccess') }}
                    </option>
                    <option value="false">
                        {{ $t('project.information.deliveryContacts.noAtexAccess') }}
                    </option>
                </app-select>
                <app-footer @click="saveMultiple()" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { filterMatch, getMapById, sortBy } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';
import {
    createDeliveryContact,
    getDeliveryContacts,
    removeDeliveryContact,
    updateDeliveryContact,
} from '@/features/deliveryContacts/deliveryContacts.service';
import { getLocationsTree, sortByFullName } from '@/features/locations/locations.service';
import IconHomeAccount from '@/icons/IconHomeAccount.vue';
import AppPhone from '@/components/appPhone/AppPhone.vue';
import AppMail from '@/components/appMail/AppMail.vue';
import { combineLatest } from 'rxjs';
import IconCircleMedium from '@/icons/IconCircleMedium.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import IconArchive from '@/icons/IconArchive.vue';
import AppTips from '@/components/app-tips/AppTips';

export default {
    components: {
        AppTips,
        IconArchive,
        AppInputText,
        AppFooter,
        AppPopup,
        IconCircleMedium,
        AppMail,
        AppPhone,
        IconHomeAccount,
        AppSelect,
        AppQuickActions,
        AppCheckbox,
        AppMultiPicker,
        AppButton,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        this.restoreSort();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_deliveryContacts');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'type') {
                        return item.type;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.type'),
                    id: this.$t('commons.type'),
                    children: ['owner', 'manager', 'tenant', 'other'].map((type, index) => ({
                        id: type,
                        name: this.$t('project.information.deliveryContacts.types.' + type),
                        firstOfCriteriaType: index === 0,
                        _isTypeCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: this.$t('commons.status'),
                    children: [
                        {
                            id: 'isOccupant',
                            name: this.$t('project.information.deliveryContacts.isOccupant'),
                            firstOfCriteriaType: true,
                            _isStatusCriteria: true,
                        },
                        {
                            id: 'hasAtexAccess',
                            name: this.$t('project.information.deliveryContacts.hasAtexAccess'),
                            firstOfCriteriaType: true,
                            _isStatusCriteria: true,
                        },
                        {
                            id: 'isArchived',
                            name: this.$t('project.information.deliveryContacts.isArchived'),
                            firstOfCriteriaType: true,
                            _isStatusCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('zones.zones'),
                    id: this.$t('zones.zones'),
                    children: this.zones.map((zone, index) => ({
                        id: zone.id,
                        name: zone.fullName,
                        firstOfCriteriaType: index === 0,
                        _isZoneCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getDeliveryContacts(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                ]).subscribe(([deliveryContacts, folders]) => {
                    this.zones = folders
                        .reduce((acc, folder) => [...acc, ...folder.locations], [])
                        .reduce((acc, location) => [...acc, ...location.zones], []);
                    const zoneMap = getMapById(this.zones);
                    this.items = deliveryContacts.map((deliveryContact) => {
                        return {
                            ...deliveryContact,
                            isSelected: this.selection.includes(deliveryContact.id),
                            locations: sortByFullName(
                                deliveryContact.locationIds.map((id) => zoneMap[id]).filter((a) => !!a),
                                'fullName',
                            ),
                        };
                    });
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        saveSort() {
            localStorage.setItem(
                'deliveryContacts_sort_' + this.$route.params.projectId,
                JSON.stringify({
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreSort() {
            const cache = localStorage.getItem('deliveryContacts_sort_' + this.$route.params.projectId);
            if (cache) {
                const sortCache = JSON.parse(cache);
                this.sortKey = sortCache.sortKey || 'index';
                this.sortAsc = sortCache.sortAsc !== false;
            }
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) =>
                filterMatch(item.companyName + item.firstName + item.lastName, criteria, true),
            );
        },
        scrollToLastVisited() {
            const lastVisitedDeliveryContactId = localStorage.getItem(
                'deliveryContacts.lastVisitedAutoControlId.' + this.$route.params.projectId,
            );
            if (lastVisitedDeliveryContactId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedDeliveryContactId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: selectedItems.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeDeliveryContact(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                const commonIsArchived = this.getSelectionCommonIsArchived(selectedItems);
                const commonIsOccupant = this.getSelectionCommonIsOccupant(selectedItems);
                const commonCompanyName = this.getSelectionCommonCompanyName(selectedItems);
                this.editedContact = {
                    companyName: commonCompanyName || null,
                    isArchived: commonIsArchived === true ? 'true' : commonIsArchived === false ? 'false' : null,
                    isOccupant: commonIsOccupant === true ? 'true' : commonIsOccupant === false ? 'false' : null,
                    type: this.getSelectionCommonType(selectedItems),
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        toggleSelectAll() {
            const customItems = this.filteredItems.map((item) => item.id);
            if (this.selection.length < customItems.length) {
                this.selection = customItems;
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'lastVisitedDeliveryContacts.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('lastVisitedDeliveryContacts.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addDeliveryContact() {
            const result = await createDeliveryContact(this.$route.params.projectId, {
                firstName: this.$t('project.information.deliveryContacts.newName'),
                type: 'owner',
                locationIds: [],
                isOccupant: false,
            });
            await this.$router.push({
                name: 'deliveryContact',
                params: {
                    projectId: this.$route.params.projectId,
                    deliveryContactId: result.id,
                },
            });
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'deliveryContacts_filter_' + this.$route.params.projectId,
                JSON.stringify({ filterValue, showArchived: this.showArchived }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('deliveryContacts_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.showArchived = parsedCache.showArchived || false;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria).map((criteria) => criteria.id);
            const occupantCriteria = filter.find(
                (aCriteria) => aCriteria._isStatusCriteria && aCriteria.id === 'isOccupant',
            );
            const accessCriteria = filter.find(
                (aCriteria) => aCriteria._isStatusCriteria && aCriteria.id === 'hasAtexAccess',
            );
            const archivedCriteria = filter.find(
                (aCriteria) => aCriteria._isStatusCriteria && aCriteria.id === 'isArchived',
            );
            this.hideShowArchived = !!archivedCriteria;
            const zoneCriteria = filter.filter((aCriteria) => aCriteria._isZoneCriteria).map((criteria) => criteria.id);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchTypeCriteria: typeCriteria.length === 0 || typeCriteria.includes(item.type),
                    matchZoneCriteria:
                        zoneCriteria.length === 0 || zoneCriteria.find((zoneId) => item.locationIds.includes(zoneId)),
                    matchString: this.matchString(stringCriteria, item),
                    occupantCriteria: !occupantCriteria || item.isOccupant,
                    accessCriteria: !accessCriteria || item.hasAtexAccess,
                    archiveCriteria:
                        (item.isArchived && (archivedCriteria || (!archivedCriteria && this.showArchived))) ||
                        (!archivedCriteria && !item.isArchived),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        getSelectionCommonType(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.type);

            if (firstItem && selectedItems.every((item) => item.type === firstItem.type)) {
                return firstItem.type;
            } else {
                console.log('no common type');
                return null;
            }
        },
        getSelectionCommonIsOccupant(selectedItems) {
            const firstItem = selectedItems.length > 0 ? selectedItems[0] : null;
            if (firstItem !== null && selectedItems.every((item) => item.isOccupant === firstItem.isOccupant)) {
                return firstItem.isOccupant;
            } else {
                return null;
            }
        },
        getSelectionCommonCompanyName(selectedItems) {
            const firstItem = selectedItems.length > 0 ? selectedItems[0] : null;
            if (firstItem !== null && selectedItems.every((item) => item.companyName === firstItem.companyName)) {
                return firstItem.companyName;
            } else {
                return null;
            }
        },
        getSelectionCommonIsArchived(selectedItems) {
            const firstItem = selectedItems.length > 0 ? selectedItems[0] : null;
            if (firstItem !== null && selectedItems.every((item) => item.isArchived === firstItem.isArchived)) {
                return firstItem.isArchived;
            } else {
                return null;
            }
        },
        saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            const patch = {};
            if (
                this.editedContact.type !== '' &&
                this.editedContact.type !== this.getSelectionCommonType(selectedItems)
            ) {
                patch.type = this.editedContact.type;
            }
            if (this.editedContact.isOccupant === 'true' || this.editedContact.isOccupant === 'false') {
                patch.isOccupant = this.editedContact.isOccupant === 'true';
            }
            if (this.editedContact.isArchived === 'true' || this.editedContact.isArchived === 'false') {
                patch.isArchived = this.editedContact.isArchived === 'true';
            }
            if (this.editedContact.hasAtexAccess === 'true' || this.editedContact.hasAtexAccess === 'false') {
                patch.hasAtexAccess = this.editedContact.hasAtexAccess === 'true';
            }
            if (this.editedContact.companyName) {
                patch.companyName = this.editedContact.companyName;
            }
            this.selection.map(async (itemId) => {
                if (Object.keys(patch).length > 0) {
                    await updateDeliveryContact(this.$route.params.projectId, { id: itemId, ...patch });
                }
            });
            this.$refs.modifyAllPopup.close();
        },
    },
    data() {
        return {
            sortKey: 'name',
            showArchived: false,
            sortAsc: true,
            loading: true,
            readOnly: true,
            hideShowArchived: false,
            selection: [],
            subscriptions: [],
            items: [],
            filterValue: [],
            zones: [],
            quickActions: [
                {
                    name: this.$t('project.information.deliveryContacts.newDeliveryContact'),
                    run: () => this.addDeliveryContact(),
                },
            ],
            editedContact: {
                type: 'owner',
                isOccupant: 'true',
                isArchived: 'true',
                hasAtexAccess: 'false',
            },
        };
    },
};
</script>
