<template>
    <div class="block app-input-text">
        <div class="flex flex-col w-full">
            <div class="flex w-full">
                <div :class="{ 'w-full': !size || size === 'mini', 'flex gap-1 items-center': inline }">
                    <app-label :showLabel="showLabel" :label="label" :required="required" :label-for="uid"></app-label>
                    <div class="flex">
                        <div class="relative focus-within:ring-2" :class="{ 'w-full': !size || size === 'mini' }">
                            <ValidationProvider
                                :rules="rules"
                                v-slot="{ errors, classes }"
                                ref="root"
                                class="w-full validated"
                                :immediate="immediate"
                            >
                                <slot name="default" v-bind:classes="classes" v-bind:defaultClasses="defaultClasses">
                                    <input
                                        :id="uid"
                                        class="default-slot-content outline-none bg-inherit"
                                        :class="{ ...classes, ...defaultClasses, warning }"
                                        :disabled.prop="disabled"
                                        :name="label"
                                        :required="required"
                                        :type="type"
                                        :step="step"
                                        :min="min"
                                        :value="value"
                                        :maxlength="maxLength"
                                        :minlength="minLength"
                                        :size="size"
                                        :placeholder="placeholder"
                                        :pattern="pattern"
                                        @blur="$emit('blur', $event)"
                                        @focus="$emit('focus', $event)"
                                        @click="$emit('click', $event)"
                                        @mousedown="$emit('mousedown', $event)"
                                        @keypress="$emit('keypress', $event)"
                                        @keyup="$emit('keyup', $event)"
                                        @keydown="$emit('keydown', $event)"
                                        @paste="$emit('paste', $event)"
                                        @input="onInput"
                                        :list="list"
                                        :fake="setErrors(errors)"
                                        :autocomplete="autocomplete"
                                        ref="input"
                                    />
                                </slot>
                            </ValidationProvider>
                            <div
                                class="absolute right-0 mr-2"
                                v-if="!!$slots.link"
                                style="top: 50%; transform: translate(0, -50%)"
                            >
                                <slot name="link"></slot>
                            </div>
                        </div>
                        <slot name="companion"></slot>
                    </div>
                </div>
            </div>
            <slot name="tip"></slot>
        </div>
        <app-errors :errors="errors" v-if="showErrors" />
    </div>
</template>
<script>
import AppErrors from '../appErrors/AppErrors';
import AppLabel from '../appLabel/AppLabel';
import { ValidationProvider } from 'vee-validate';
export default {
    methods: {
        setDirty() {
            this.$refs.root.setFlags({ dirty: true, pristine: false });
        },
        setErrors(errors) {
            this.errors = errors;
        },
        onInput($event) {
            this.$emit('input', $event.target.value);
            this.$emit('rawInput', $event);
        },
        focus() {
            this.$refs.input.focus();
        },
        scrollTo() {
            this.$refs.input.scrollTo();
        },
        select() {
            this.$refs.input.select();
        },
    },
    components: { AppLabel, AppErrors, ValidationProvider },
    computed: {
        defaultClasses() {
            return {
                'shadow appearance-none border leading-tight': true,
                'p-1': this.size === 'mini',
                'py-2 px-3': this.size !== 'mini',
                'w-full': !this.size || this.size === 'mini',
                'pr-8': !!this.$slots.link,
            };
        },
    },
    data() {
        return {
            errors: [],
        };
    },
    props: {
        step: String,
        rules: String,
        maxLength: Number,
        minLength: Number,
        size: Number | String,
        min: Number | Object,
        placeholder: String,
        pattern: String,
        list: String | Number,
        type: {
            type: String,
            default: 'text',
        },
        disabled: Boolean,
        inline: Boolean,
        required: Boolean,
        label: String,
        value: String | Number,
        showErrors: {
            type: Boolean,
            default: true,
        },
        immediate: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: 'on',
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        warning: {
            type: Boolean,
            default: false,
        },
        uid: {
            type: String | Number,
            default: function () {
                return Math.random().toString().substring(2);
            },
        },
    },
};
</script>
<style>
.app-input-text .validated input:not(.default-slot-content) {
    @apply border p-1;
}

.app-input-text .validated input:disabled {
    box-shadow: none;
    opacity: 0.5;
}
.app-input-text .validated input.invalid {
    border-color: red;
    border-width: 2px;
}
.app-input-text .validated input.warning {
    border-color: orange;
    border-width: 2px;
}
</style>
