import db from '@/rxdb/database';
import { map } from 'rxjs';

export async function createPreparationsReport(projectId, preparationsReport) {
    const result = await db.getProjectCollections(projectId).preparationsReports.insert({
        ...preparationsReport,
        locationIds: preparationsReport.locationIds ? JSON.parse(JSON.stringify(preparationsReport.locationIds)) : [],
        bundleIds: preparationsReport.bundleIds ? JSON.parse(JSON.stringify(preparationsReport.bundleIds)) : [],
        date: preparationsReport.date ? preparationsReport.date.toISOString() : new Date().toISOString(),
    });
    return result.toJSON();
}
export function getPreparationsReports(projectId, phase) {
    return db
        .getProjectCollections(projectId)
        .preparationsReports.find({ selector: { phase } })
        .$.pipe(map((items) => items.map(mapPreparationsReport)));
}

function mapPreparationsReport(preparationsReportDb) {
    return preparationsReportDb
        ? {
              ...preparationsReportDb.toMutableJSON(),
              date: preparationsReportDb.date ? new Date(preparationsReportDb.date) : null,
          }
        : null;
}

export async function updatePreparationsReport(projectId, preparationsReport) {
    const dbPreparationsReport = await db
        .getProjectCollections(projectId)
        .preparationsReports.findOne({ selector: { id: preparationsReport.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(preparationsReport)) };
    if (preparationsReport.date === null || !!preparationsReport.date) {
        patch.date = preparationsReport.date ? preparationsReport.date.toISOString() : null;
    }
    return dbPreparationsReport.atomicPatch(patch);
}

export function getPreparationsReport(projectId, preparationsReportId) {
    return db
        .getProjectCollections(projectId)
        .preparationsReports.findOne({ selector: { id: preparationsReportId } })
        .$.pipe(map(mapPreparationsReport));
}
export async function removePreparationsReport(projectId, preparationsReportId) {
    const entity = await db
        .getProjectCollections(projectId)
        .preparationsReports.findOne({ selector: { id: preparationsReportId } })
        .exec();
    return entity.remove();
}

export async function filterLocationIds(projectId, preparationsReportId, filterFn) {
    const entity = await db
        .getProjectCollections(projectId)
        .preparationsReports.findOne({ selector: { id: preparationsReportId } })
        .exec();
    return entity.atomicPatch({
        locationIds: entity.locationIds.filter(filterFn),
    });
}
export async function filterBundleIds(projectId, preparationsReportId, bundleId) {
    const entity = await db
        .getProjectCollections(projectId)
        .preparationsReports.findOne({ selector: { id: preparationsReportId } })
        .exec();
    return entity.atomicPatch({
        bundleIds: entity.bundleIds.filter((id) => id !== bundleId),
    });
}

export default {
    filterLocationIds,
    filterBundleIds,
};
