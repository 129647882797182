<template>
    <div class="col-span-2 flex justify-between items-center">
        <slot name="left"><span></span></slot>
        <slot>
            <app-button
                :disabled="disabled"
                :label="okLabel || $t('commons.validate')"
                @click.prevent="$emit('click', $event)"
                :variant="variant"
                type="submit"
            ></app-button>
        </slot>
    </div>
</template>
<script>
import AppButton from '../appButton/AppButton';
export default {
    components: { AppButton },
    props: {
        disabled: { type: Boolean, default: false },
        okLabel: { type: String, default: null },
        variant: { type: String, default: 'primary' },
    },
};
</script>
