import compareAsc from 'date-fns/compareAsc';
import fuzzysearch from 'fuzzysearch';
export function sanitize(object, fieldToOmit = [], defaultFieldToOmit = ['id', '__typename', '__obj__', 'createdAt']) {
    if (!object) {
        return object;
    }
    return Object.fromEntries(
        Object.entries(object)
            .filter(([k]) => ![...fieldToOmit, ...defaultFieldToOmit].includes(k))
            .map(([k, v]) => (isEmptyString(v) ? [k, null] : [k, v])),
    );
}
export function omit(object, fieldToOmit) {
    if (!object) {
        return object;
    }
    return Object.fromEntries(
        Object.entries(object)
            .filter(([k]) => !fieldToOmit.includes(k))
            .map(([k, v]) => (isEmptyString(v) ? [k, null] : [k, v])),
    );
}

export function replaceFollowingDot(string) {
    const firstIndex = string.indexOf('.');
    return string.substring(0, firstIndex + 1) + string.substring(firstIndex).replace(/\./gm, '');
}

export function sanitizeKeepId(object, fieldToOmit = []) {
    return sanitize(object, fieldToOmit, ['__typename', '__obj__']);
}
export function getId(object) {
    if (typeof object === 'undefined') {
        return object;
    }
    return typeof object === 'string' ? object : object?.id || null;
}
// https://dmitripavlutin.com/how-to-compare-objects-in-javascript/
export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export function uniqBy(array, key) {
    if (!array) {
        return [];
    }
    const cache = {};
    for (const item of array) {
        if (typeof key === 'function') {
            //add a letter to be sure to have a non-numeric key. otherwise the order is impacted.
            cache['a' + key(item)] = item;
        } else {
            //add a letter to be sure to have a non-numeric key. otherwise the order is impacted.
            cache['a' + item[key]] = item;
        }
    }
    return Object.values(cache);
}
export function uniq(array) {
    return [...new Set(array)];
}
export function groupBy(array, key) {
    if (!array) {
        return [];
    }
    const cache = {};
    for (const item of array) {
        let itemKey = null;
        if (typeof key === 'function') {
            itemKey = key(item);
        } else {
            itemKey = item[key];
        }
        cache[itemKey] ? cache[itemKey].push(item) : (cache[itemKey] = [item]);
    }
    return Object.values(cache);
}

export function stringifyErrors(errors) {
    return Object.values(errors)
        .reduce((a, b) => a.concat(b), [])
        .join('\n');
}

function isEmptyString(str) {
    return str && typeof str.valueOf() === 'string' && str.length === 0;
}

export function flatten(array) {
    return array.reduce((a, b) => a.concat(b), []);
}

export function throttle(func, timeFrame) {
    let lastTime = 0;
    return function () {
        const context = this;
        let now = new Date();
        if (now - lastTime >= timeFrame) {
            func.apply(context, arguments);
            lastTime = now;
        }
    };
}
// https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_sortby-and-_orderby
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare to consider number parts as numbers
const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export function sortBy(array, key = null, options = { nullIsLower: true }) {
    if (!array) {
        return [];
    }
    const sortByfn = (key) => {
        return (a, b) => {
            let aVal;
            let bVal;
            if (typeof key === 'function') {
                try {
                    aVal = key(a);
                } catch (e) {
                    aVal = null;
                }
                try {
                    bVal = key(b);
                } catch (e) {
                    bVal = null;
                }
            } else if (key === null || typeof key === 'undefined') {
                aVal = a;
                bVal = b;
            } else {
                aVal = a[key];
                bVal = b[key];
            }
            if (typeof aVal === 'number' && typeof bVal === 'number' && !isNaN(aVal) && !isNaN(bVal)) {
                return aVal > bVal ? +1 : -1;
            } else if (typeof aVal === 'number' && typeof bVal === 'number' && !isNaN(aVal) && isNaN(bVal)) {
                return options.nullIsLower ? +1 : -1;
            } else if (typeof aVal === 'number' && typeof bVal === 'number' && !isNaN(bVal) && isNaN(aVal)) {
                return options.nullIsLower ? -1 : +1;
            } else if (aVal instanceof Date && bVal instanceof Date) {
                return compareAsc(aVal, bVal);
            } else if (aVal instanceof Date && bVal === null) {
                return options.nullIsLower ? +1 : -1;
            } else if (aVal === null && bVal instanceof Date) {
                return options.nullIsLower ? -1 : +1;
            } else {
                return collator.compare(aVal, bVal);
            }
        };
    };
    return array.concat().sort(sortByfn(key));
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export const get = (obj, path, defaultValue = undefined) => {
    const travel = (regexp) =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
};

export function upperFirst(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function getMapById(array) {
    return getMapBy(array, 'id');
}
export function getMapBy(array, key) {
    const result = {};
    for (const item of array) {
        let itemKey = null;
        if (typeof key === 'function') {
            itemKey = key(item);
        } else {
            itemKey = item[key];
        }
        result[itemKey] = item;
    }
    return result;
}

export function toCamelCase(str) {
    return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function filterMatch(value, filter, strict) {
    if (!value && !filter) {
        return true;
    } else if (!value) {
        return false;
    }
    const normalizedValue = value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase();
    const normalizedFilter = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase();
    if (strict) {
        return (
            normalizedValue.includes(normalizedFilter) ||
            normalizedFilter.split(' ').every((filterToken) => normalizedValue.includes(filterToken))
        );
    } else {
        return fuzzysearch(normalizedFilter, normalizedValue);
    }
}

export function isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    const viewport = elem.closest('.viewport') || window;
    const viewportBounding = viewport.getBoundingClientRect();
    return (
        bounding.top >= viewportBounding.top &&
        bounding.left >= viewportBounding.left &&
        bounding.bottom <= viewportBounding.bottom &&
        bounding.right <= viewportBounding.right
    );
}

export function chunk(input, size) {
    return input.reduce((arr, item, idx) => {
        return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
    }, []);
}
