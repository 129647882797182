<template>
    <div class="w-full" :class="state" style="max-width: calc(768px - 2cm)">
        <app-separator variant="underline" class="text-lg my-4" v-if="$route.params.showTitle && zone">
            {{ zone.fullName }}
        </app-separator>
        <table class="w-full table-fixed border-separate" style="border-spacing: 1em 1em">
            <thead>
                <tr>
                    <td style="width: 32%"></td>
                    <td style="width: 32%"></td>
                    <td style="width: 32%"></td>
                </tr>
            </thead>
            <tr v-for="row in table" class="h-full">
                <template v-for="cell in row">
                    <td :colspan="cell.attachments.length" class="h-full">
                        <div class="h-full px-2 flex flex-col border border-gray-500">
                            <div class="flex">
                                <span class="text-lg font-bold">
                                    #{{ (cell.index + 1).toString().padStart(3, '0') }}
                                </span>
                            </div>
                            <div class="flex w-full">
                                <div
                                    v-for="attachment in cell.attachments"
                                    class="flex w-full"
                                    style="min-height: 300px"
                                >
                                    <app-leaflet-viewer
                                        class="flex-grow"
                                        :src="attachment.url"
                                        :shapes="attachment.shapes"
                                        :preview="true"
                                    />
                                </div>
                            </div>
                            <span class="text-xs text-center h-8 line-clamp-2" v-html="cell.title" />
                        </div>
                    </td>
                </template>
            </tr>
        </table>
    </div>
</template>
<script>
import { getObservations, getObservationsForRelatedContent } from '@/features/observations/observation.service';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import { combineLatest } from 'rxjs';
import { getObservationAttachments } from '@/features/observations/observationAttachments.service';
import {
    filterObservationFromObservationReportConfig,
    getObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { getBundles } from '@/features/bundles/bundles.service';

export default {
    components: { AppSeparator, AppLeafletViewer },
    async created() {
        this.subscriptions = [
            combineLatest([
                getObservationsReport(this.$route.params.projectId, this.$route.params.observationsReportId),
                this.$route.query.relatedContentId
                    ? getObservationsForRelatedContent(this.$route.params.projectId, this.$route.query.relatedContentId)
                    : getObservations(this.$route.params.projectId),
                getObservationAttachments(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([observationsReport, observations, observationAttachments, folders, allBundles]) => {
                const locations = locationService.buildLocationOptions(folders);
                this.zone = locations.find((location) => location.id === this.$route.params.zoneId);
                const NUMBER_BY_ROW = 3;
                this.observationsReport = observationsReport;
                let bundles;
                if (this.$route.query.bundleId) {
                    bundles = [this.$route.query.bundleId];
                } else {
                    bundles = observationsReport.bundleIds;
                }
                if (bundles.length === 0) {
                    bundles = allBundles.map((bundle) => bundle.id);
                }
                this.observations = filterObservationFromObservationReportConfig(
                    observations,
                    [this.$route.params.zoneId === 'null' ? null : this.$route.params.zoneId],
                    bundles,
                    this.observationsReport,
                ).map((observation) => ({
                    ...observation,
                    attachments: observationAttachments
                        .filter((attachment) => attachment.observationId === observation.id)
                        .filter(
                            (_, index) =>
                                index < NUMBER_BY_ROW /* limited to NUMBER_BY_ROW number of photo by observation */,
                        ),
                }));
                this.table = [];
                let currentRow = [];
                let currentRowSpan = 0;
                for (const observation of this.observations) {
                    const numberOfPhoto = observation.attachments.length;
                    if (numberOfPhoto === 0) {
                        // nop
                    } else if (currentRowSpan + numberOfPhoto < NUMBER_BY_ROW) {
                        currentRow.push(observation);
                        currentRowSpan = currentRowSpan + numberOfPhoto;
                    } else if (currentRowSpan + numberOfPhoto === NUMBER_BY_ROW) {
                        currentRow.push(observation);
                        this.table.push(currentRow);
                        currentRow = [];
                        currentRowSpan = 0;
                    } else if (currentRowSpan + numberOfPhoto > NUMBER_BY_ROW) {
                        this.table.push(currentRow);
                        currentRow = [];
                        currentRowSpan = numberOfPhoto;
                        currentRow.push(observation);
                    }
                }
                this.table.push(currentRow);
                this.$nextTick(() => {
                    this.state = 'ready';
                });
            }),
        ];
    },
    data() {
        return {
            observations: [],
            table: [],
            state: 'loading',
            zone: null,
        };
    },
};
</script>
