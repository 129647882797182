import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { isMobile } from '@/state/state';

export const notyf = new Notyf({
    duration: 2000,
    position: isMobile
        ? {
              x: 'center',
              y: 'bottom',
          }
        : {
              x: 'center',
              y: 'top',
          },
});

export function error(message) {
    return notyf.error({ message });
}

export function info(message, duration = 2000) {
    return notyf.success({
        message,
        duration,
    });
}
