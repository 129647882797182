<template>
    <ul>
        <li class="list-none">
            <div class="flex p-2 border-b sticky bg-white items-center gap-2" :class="rootClasses">
                <template v-if="root.name">
                    <span class="pl-2">{{ root.name }}</span>
                    <div class="flex-grow"></div>
                </template>
                <slot name="toolbar"></slot>
            </div>
            <ul class="p-0">
                <app-node
                    ref="tree"
                    :selectedId="selectedId"
                    :level="1"
                    class="item"
                    v-for="(child, index) in root.children"
                    :key="index"
                    :index="index"
                    :item="child"
                    @select="$emit('select', $event)"
                    @drop="$emit('drop', $event)"
                    @edit="$emit('edit', $event)"
                >
                    <template v-slot:item="{ item }">
                        <slot :item="item" name="item"></slot>
                    </template>
                </app-node>
            </ul>
        </li>
    </ul>
</template>

<script>
import AppNode from './AppNode';
import AppButton from './appButton/AppButton';
export default {
    components: { AppButton, AppNode },
    name: 'AppTree',
    props: {
        root: Object,
        selectedId: String,
        rootClasses: String,
    },
    created() {
        if (this.root) {
            this.parentCache = [];
            this.applyCacheOpenState(this.root);
        }
    },
    methods: {
        getItemById(id) {
            return this.itemCache[id];
        },
        expandTo(id) {
            const parent = this.parentCache[id];

            if (parent) {
                this.changeState(parent, true);
                this.expandTo(parent.id);
            }
        },
        changeState(item, state) {
            this.$set(item, 'isOpen', state);
        },
        collapseAll(node = this.root) {
            if (node.children) {
                for (const item of node.children) {
                    this.changeState(item, false);
                    this.collapseAll(item);
                }
            }
        },
        expandAll(node = this.root) {
            if (node.children) {
                for (const item of node.children) {
                    this.changeState(item, true);
                    this.expandAll(item);
                }
            }
        },
        expandToLevel(toLevel, node = this.root, level = 0) {
            if (node.children) {
                for (const item of node.children) {
                    this.changeState(item, level < toLevel);
                    this.expandToLevel(toLevel, item, level + 1);
                }
            }
        },
        applyCacheOpenState(node) {
            if (!node.children) {
                return;
            }
            for (const item of node.children) {
                if (this.itemCache[item.id]) {
                    this.changeState(item, this.itemCache[item.id].isOpen);
                } else {
                    this.changeState(item, false);
                }
                this.itemCache[item.id] = item;
                this.parentCache[item.id] = node;
                this.applyCacheOpenState(item);
            }
        },
    },
    watch: {
        root(value) {
            this.parentCache = [];
            this.applyCacheOpenState(value);
        },
    },
    data() {
        return {
            itemCache: {},
            parentCache: {},
        };
    },
};
</script>

<style scoped></style>
