<template>
    <div class="w-full">
        <app-separator class="cursor-pointer mb-0" @click.prevent="onToggleDetail">
            <div class="flex items-center font-normal w-full gap-1">
                <icon class="mr-3" :name="detailIsOpen ? 'icon-chevron-down' : 'icon-chevron-up'"></icon>
                <div class="flex items-center mr-3">
                    <router-link
                        :to="{
                            name: 'bundle',
                            params: { bundleId: service.bundleId, projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ service && service.bundle ? service.bundle.label : '' }}
                    </router-link>
                    <icon-chevron-right />

                    <router-link
                        :to="{
                            name: 'service',
                            params: {
                                bundleId: service.bundleId,
                                serviceId: service.id,
                                projectId: $route.params.projectId,
                            },
                        }"
                        class="underline"
                    >
                        <span>{{ service.name }}</span>
                    </router-link>
                </div>
            </div>
        </app-separator>
        <div v-if="detailIsOpen">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import AppSeparator from '../../../components/appSeparator/AppSeparator';
import { queryPlanningState, updatePlanningState } from '@/state/state';

export default {
    components: {
        AppSeparator,
    },
    props: ['service'],
    created() {
        this.detailIsOpen = queryPlanningState(this.$route.params.projectId).detailIsOpen;
    },
    methods: {
        async onToggleDetail() {
            this.detailIsOpen = !this.detailIsOpen;
            this.$nextTick(() => this.$emit('change'));
            updatePlanningState(this.$route.params.projectId, {
                detailIsOpen: this.detailIsOpen,
            });
        },
    },
    data() {
        return {
            detailIsOpen: true,
        };
    },
};
</script>
