<template>
    <div class="p-2">
        <h2 class="text-xs font-bold">{{ $t('project.editions.preparationList.legend.title') }}</h2>
        <div class="text-xs border w-full flex flex-col sm:flex-row sm:justify-between gap-1 p-1">
            <div class="flex sm:flex-row items-center">
                <icon-timelapse class="text-secondary" />
                {{ $t('project.editions.preparationList.legend.incompleteVisa') }}
            </div>
            <div class="flex sm:flex-row items-center">
                <icon-check-circle-outline class="text-green-600" />
                <span>{{ $t('project.editions.preparationList.legend.approved') }}</span>
            </div>
            <div class="flex sm:flex-row items-center">
                <icon-alert-circle-outline class="text-yellow-500" />
                <div
                    :title="
                        $t('project.editions.preparationList.legend.approvedWithCommentsTips') +
                        ' ' +
                        $t('project.editions.preparationList.legend.approvedWithCommentsTips2')
                    "
                >
                    {{ $t('project.editions.preparationList.legend.approvedWithComments') }}
                </div>
            </div>
            <div class="flex sm:flex-row items-center">
                <icon-close-circle-outline class="text-red-700" />
                <div
                    :title="
                        $t('project.editions.preparationList.legend.rejectedTips') +
                        ' ' +
                        $t('project.editions.preparationList.legend.rejectedTips2')
                    "
                >
                    {{ $t('project.editions.preparationList.legend.rejected') }}
                </div>
            </div>
            <div class="flex sm:flex-row items-center">
                <icon-close-octagon class="text-red-700" />
                <div
                    :title="
                        $t('project.editions.preparationList.legend.nonCompliantTips') +
                        ' ' +
                        $t('project.editions.preparationList.legend.nonCompliantTips2')
                    "
                >
                    {{ $t('project.editions.preparationList.legend.nonCompliant') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconTimelapse from '@/icons/IconTimelapse';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline';
import AppTips from '@/components/app-tips/AppTips';
import IconAlertCircleOutline from '@/icons/IconAlertCircleOutline';
import IconCloseCircleOutline from '@/icons/IconCloseCircleOutline';
import IconCloseOctagon from '@/icons/IconCloseOctagon';
import IconFileHidden from '@/icons/IconFileHidden';
export default {
    components: {
        IconFileHidden,
        IconCloseOctagon,
        IconCloseCircleOutline,
        IconAlertCircleOutline,
        AppTips,
        IconCheckCircleOutline,
        IconTimelapse,
    },
};
</script>
