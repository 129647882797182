<template>
    <AppList
        :items="zones"
        :filterFn="filterFn"
        :filter-value="filterValue"
        :filterOptions="filterOptions"
        @navigateTo="navigateTo"
        class="max-w-5xl"
        :removable="false"
        :line-height="60"
    >
        <template v-slot:headers>
            <div class="font-bold mb-4">{{ $t('observations.tourZonesTips') }}</div>
        </template>
        <template v-slot:option="{ option }">
            <span>{{ option.name }}</span>
            <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
        </template>
        <template v-slot:item="{ item }">
            <div class="w-full flex">
                <div class="w-full flex flex-col">
                    <span class="text-lg">{{ item.name }}</span>
                    <div class="text-xs text-yellow-500 flex items-center" v-if="item.supports.length === 0">
                        <icon-alert-circle width="16" class="mr-1" />
                        {{ $t('tour.noSupport') }}
                    </div>
                </div>
                <div class="flex items-center">
                    <observations-counter-badge
                        :items="item.openObservations"
                        class="px-1 observations-to-do-late text-white text-xs"
                    />
                    <observations-counter-badge
                        :items="item.fixedObservations"
                        class="ml-2 px-1 observations-done text-white text-xs"
                    />
                    <observations-counter-badge
                        :items="item.closedObservations"
                        class="ml-2 px-1 observations-validated text-xs"
                    />
                </div>
            </div>
        </template>
    </AppList>
</template>

<script>
import AppList from '@/components/appList/AppList';
import IconAlertCircle from '@/icons/IconAlertCircle';
import { isMobile, updateBreadCrumbs } from '@/state/state';
import ObservationsCounterBadge from '@/features/tours/ObservationsCounterBadge';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { getObservations } from '@/features/observations/observation.service';
import { getSupports } from '@/features/supports/supports.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { filterMatch } from '@/services/sanitize.service';
import { getSettings, updateSettings } from '@/features/tours/tours.service';

export default {
    components: { ObservationsCounterBadge, IconAlertCircle, AppList },
    async created() {
        this.subscriptions = [
            combineLatest([
                getLocationsTree(this.$route.params.projectId),
                getObservations(this.$route.params.projectId),
                getSupports(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([folders, observations, supports, bundles]) => {
                const folder = folders.find((folder) => folder.id === this.$route.params.folderId);
                this.location = folder.locations.find((location) => location.id === this.$route.params.locationId);
                this.observations = observations;
                this.supports = supports;
                this.filterOptions = [
                    {
                        isGroup: true,
                        name: this.$t('commons.status'),
                        id: this.$t('commons.status'),
                        children: [
                            {
                                id: 'hasObservation',
                                name: this.$t('tour.hasObservation'),
                                firstOfCriteriaType: true,
                            },
                            {
                                id: 'noObservation',
                                name: this.$t('tour.noObservation'),
                                firstOfCriteriaType: false,
                            },
                            {
                                id: 'noSupport',
                                name: this.$t('tour.noSupport'),
                                firstOfCriteriaType: false,
                            },
                        ],
                    },
                    {
                        isGroup: true,
                        name: this.$t('commons.bundleCriteriaType'),
                        id: this.$t('commons.bundleCriteriaType'),
                        children: bundles.map((bundle, index) => ({
                            ...bundle,
                            name: bundle.reference ? bundle.reference + ' - ' + bundle.name : +bundle.name,
                            firstOfCriteriaType: index === 0,
                            criteriaType: this.$t('commons.bundleCriteriaType'),
                            _isBundleCriteria: true,
                        })),
                    },
                ];
                this.filterValue = getSettings(this.$route.params.projectId).filterValue;
                if (isMobile) {
                    updateBreadCrumbs({ locationName: this.location.parent.name + ' > ' + this.location.name });
                } else {
                    updateBreadCrumbs({ locationName: this.location.name, folderName: this.location.parent.name });
                }
            }),
        ];
    },
    computed: {
        zones() {
            if (!this.location) {
                return [];
            }
            return this.location.zones.map((zone) => {
                const bundleIds = (this.filterValue || [])
                    .filter((aCriteria) => aCriteria._isBundleCriteria)
                    .map((bundle) => bundle.id);
                const zoneObservations = this.observations.filter(
                    (observation) =>
                        observation.zoneId === zone.id &&
                        (bundleIds.length === 0 || observation.recipientIds.some((id) => bundleIds.includes(id))),
                );
                return {
                    ...zone,
                    observations: zoneObservations,
                    openObservations: zoneObservations.filter(
                        (observation) => !observation.obsoleteAt && !observation.resolvedAt,
                    ),
                    fixedObservations: zoneObservations.filter(
                        (observation) => !observation.obsoleteAt && observation.resolvedAt && !observation.validatedAt,
                    ),
                    closedObservations: zoneObservations.filter(
                        (observation) => !observation.obsoleteAt && observation.resolvedAt && observation.validatedAt,
                    ),
                    supports: this.supports.filter((support) => support.locationIds.includes(zone.id)),
                };
            });
        },
    },
    methods: {
        matchString(stringCriteria, fullName) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(fullName, criteria, true));
        },
        saveFilter(filterValue) {
            const currentSettings = getSettings(this.$route.params.projectId);
            updateSettings(this.$route.params.projectId, { ...currentSettings, filterValue });
        },
        filterFn(filter) {
            this.filterValue = filter;
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const noObservationCriteria = filter.filter((aCriteria) => aCriteria.id === 'noObservation');
            const hasObservationCriteria = filter.filter((aCriteria) => aCriteria.id === 'hasObservation');
            return this.zones.filter((item) => {
                const fullCriteria = {
                    matchString: stringCriteria.length === 0 || this.matchString(stringCriteria, item.fullName),
                    matchNoObservation: noObservationCriteria.length === 0 || item.observations.length === 0,
                    matchHasObservation: hasObservationCriteria.length === 0 || item.observations.length > 0,
                };
                return Object.values(fullCriteria).every((value) => !!value);
            });
        },
        navigateTo: function (zone) {
            this.$router.push({
                name: 'zoneTour',
                params: { ...this.$route.params, zoneId: zone.id },
            });
        },
    },
    data() {
        return {
            filterOptions: [],
            subscriptions: [],
            location: null,
            observations: [],
            supports: [],
            filterValue: null,
        };
    },
};
</script>
