<template>
    <app-input-text
        :label="$t('commons.siret')"
        rules="alpha_num|length:14"
        size="14"
        placeholder="0000000000000"
        pattern="[A-Za-z0-9]{14}"
        :maxLength="14"
        :required="required"
        :value="value"
        :uid="uid"
        @input="onInput"
        @paste="onPaste"
        :disabled="disabled"
        :showErrors="showErrors"
        :showLabel="showLabel"
    >
        <template v-slot:companion>
            <div class="flex items-center ml-4">
                <span class="text-sm whitespace-nowrap text-gray-600">{{ value ? value.length : '0' }} / 14</span>
            </div>
        </template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';
export default {
    props: {
        disabled: Boolean,
        required: Boolean,
        value: String | Number,
        uid: String | Number,
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
    components: { AppInputText },
    methods: {
        onInput(value) {
            this.$emit('input', value ? value.replace(/[^0-9A-Z]/g, '') : value);
        },
        onPaste(event) {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            paste = paste.toUpperCase().replace(/[^0-9A-Z]/g, '');
            this.$emit('input', paste);
            event.preventDefault();
        },
    },
};
</script>
