<template>
    <div v-if="bundle" class="flex gap-1">
        <span class="mx-1 text-xs" v-if="bundle.reference">#{{ bundle.reference.toString().padStart(3, '0') }}</span>
        <span class="font-bold">{{ bundle.name }}</span>
        <span class="text-gray-500 italic mr-2" v-if="bundle.company">
            {{ bundle.company.name }}
        </span>
        <span class="text-gray-500 italic">
            {{ bundle.user ? bundle.user.firstName + ' ' + bundle.user.lastName : '' }}
        </span>
    </div>
</template>
<script>
export default {
    props: {
        bundle: Object,
    },
};
</script>
