<template>
    <read-only-zone-observation v-if="!isAdmin"></read-only-zone-observation>
    <main class="flex flex-col w-full p-4 max-h-main min-h-main max-w-5xl" v-else>
        <zone-observation-form
            :observationId="$route.params.observationId"
            @openPhoto="openPhoto"
            @deleted="onDeleted"
            @resolved="onResolvedOrReopen"
            @reopen="onResolvedOrReopen"
        ></zone-observation-form>
    </main>
</template>

<script>
import ZoneObservationForm from '@/features/tours/ZoneObservationForm';
import ReadOnlyZoneObservation from '@/features/tours/ReadOnlyZoneObservation';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        ReadOnlyZoneObservation,
        ZoneObservationForm,
    },
    methods: {
        onResolvedOrReopen(observation) {
            this.$router.push({
                name: this.$route.meta.parentName,
                params: { ...this.$route.params },
                hash: '#uuid_' + observation.id,
            });
        },
        onDeleted() {
            this.$router.push({
                name: this.$route.meta.parentName,
                params: { ...this.$route.params },
            });
        },
        openPhoto(attachment) {
            this.$router.push({
                name: 'observationTourPhoto',
                params: { ...this.$route.params, attachmentId: attachment.id },
            });
        },
    },
    created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
        });
    },
    data() {
        return { isAdmin: false };
    },
};
</script>
