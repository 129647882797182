<template>
    <div class="max-w-5xl flex flex-col gap-2 flex-grow">
        <ul>
            <li v-if="observations.length === 0">
                <div class="flex flex-grow justify-center">
                    <app-tips>{{ $t('commons.emptyList') }}</app-tips>
                </div>
            </li>
            <li
                class="hover:bg-gray-200 flex py-2 border items-center w-full"
                @click="navigateTo(item)"
                v-for="item in observations"
            >
                <div class="flex flex-grow items-center">
                    <span
                        v-if="!item.footprint"
                        class="p-1 px-2 text-xs mr-2 ml-1"
                        :class="{
                            [getObservationClass(item)]: true,
                            border: item.type === 'general' || item.type === 'administrative',
                        }"
                    >
                        {{ (item.index + 1).toString().padStart(3, '0') }}
                    </span>
                    <span class="px-2" v-else>
                        <app-static-marker :observation="item" class="text-xs mr-2 -mt-1"></app-static-marker>
                    </span>
                    <div class="flex flex-col md:flex-row flex-wrap gap-1 md:items-center">
                        <icon-incognito class="text-violet-900" v-if="item.type === 'private'" />
                        <app-clamp :html-content="item.title" />
                        <span
                            class="text-xs text-gray-500 mx-2 line-clamp-1"
                            v-if="item.recipients && item.recipients.length === 1"
                        >
                            #{{ item.recipients[0].reference }} {{ item.recipients[0].name }}
                        </span>
                        <span
                            class="text-xs text-gray-500 mx-2 line-clamp-1 gap-1"
                            v-if="item.recipients && item.recipients.length > 1"
                        >
                            <span>{{ item.recipients.map((recipient) => '#' + recipient.reference).join(', ') }}</span>
                        </span>
                    </div>
                    <div class="flex flex-grow justify-end px-3">
                        <app-button
                            v-if="!item.resolvedAt"
                            icon="icon-check"
                            iconClass="text-green-600 mr-1"
                            size="mini"
                            @click="onResolve(item)"
                            :label="$t('observations.resolve')"
                        ></app-button>
                        <app-button
                            v-else-if="!item.validatedAt && isMOE"
                            icon="icon-thumb-up"
                            iconClass="text-green-600  mr-1"
                            size="mini"
                            @click="validate(item)"
                            :label="$t('observations.validateMOE')"
                        ></app-button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker';
import { getObservationClass, updateObservation } from '@/features/observations/observation.service';
import AppTips from '@/components/app-tips/AppTips';
import AppClamp from '@/components/appClamp/AppClamp';
import AppButton from '@/components/appButton/AppButton';
import IconIncognito from '@/icons/IconIncognito.vue';

export default {
    components: { IconIncognito, AppButton, AppClamp, AppTips, AppStaticMarker },
    props: {
        observations: {
            type: Array,
            default() {
                return [];
            },
        },
        isMOE: {
            type: Boolean,
        },
        me: {
            type: Object,
        },
    },
    methods: {
        getObservationClass,
        async onResolve(observation) {
            await updateObservation(this.$route.params.projectId, {
                id: observation.id,
                resolvedAt: new Date(),
                resolvedBy: this.me.bundleIds[0],
                resolvedOnPhase: observation.phase,
            });
        },
        validate(observation) {
            updateObservation(this.$route.params.projectId, {
                id: observation.id,
                validatedAt: new Date(),
                validatedBy: this.me.bundleIds[0],
                validatedOnPhase: observation.phase,
            });
        },
        navigateTo: function (item) {
            this.$router.push({
                name: 'observationTour',
                params: {
                    ...this.$route.params,
                    observationId: item.id,
                },
            });
        },
    },
};
</script>
