<template>
    <div class="flex flex-col gap-4 p-2">
        <div class="flex flex-col gap-4">
            <div class="flex gap-2">
                <span class="text-lg">{{ bundle.reference }}</span>
                <span class="text-xl font-bold">{{ bundle.name }}</span>
            </div>
        </div>
        <div class="shadow-md border p-4" v-if="isCompanyBundle || bundle.company">
            <table>
                <tr v-if="isCompanyBundle && bundle.marketDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.marketDate') }} :</th>
                    <td class="p-2">{{ bundle.marketDate | humanizeDate }}</td>
                </tr>
                <tr v-if="isCompanyBundle && bundle.serviceOrderDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.serviceOrderDate') }} :</th>
                    <td class="p-2">{{ bundle.serviceOrderDate | humanizeDate }}</td>
                </tr>
                <tr v-if="bundle.visaDelay">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.visaDelay') }} :</th>
                    <td class="p-2">{{ bundle.visaDelay }}</td>
                </tr>
                <tr v-if="bundle.company">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('companies.company') }} :</th>
                    <td class="p-2">
                        <div class="flex flex-col gap-2">
                            <div class="text-lg font-bold">{{ bundle.company.name }}</div>
                            <div v-if="bundle.company.address1">
                                <p>{{ bundle.company.address1 }}</p>
                                <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                                <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                                <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                            </div>
                            <app-phone v-if="bundle.company.phone" :number="bundle.company.phone"></app-phone>
                            <app-mail v-if="bundle.company.email" :email="bundle.company.email"></app-mail>
                        </div>
                    </td>
                </tr>
            </table>
            <bundle-contacts
                :contacts="bundle.contacts"
                :bundle="bundle"
                :project="project"
                :company="bundle.company"
                :show-responsible="true"
                :responsible-id="bundle.responsibleId"
                ref="contacts"
                :read-only="true"
            ></bundle-contacts>
        </div>
    </div>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import { getBundleOffline } from './bundles.service';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';
import { sortBy } from '@/services/sanitize.service';
import BundleContacts from '@/features/bundles/BundleContacts';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import { getContacts } from '@/features/contacts/contacts.service';
import { queryProject } from '@/features/projects/projects.service';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';
export default {
    name: 'bundle',
    components: {
        AppDropDownButton,
        BundleContacts,
        AppMail,
        AppPhone,
    },
    created() {
        this.init();
    },
    data() {
        return {
            project: {},
            subscriptions: [],
            isCompanyBundle: false,
            bundle: { services: [], company: null, contacts: [], visaDelay: 5 },
        };
    },
    watch: {
        $route() {
            this.init();
        },
    },
    methods: {
        init() {
            queryProject(this.$route.params.projectId).then((project) => (this.project = project));
            this.subscriptions = [
                combineLatest([
                    getBundleOffline(this.$route.params.projectId, this.$route.params.bundleId),
                    getContacts(this.$route.params.projectId),
                    getCompanies(this.$route.params.projectId),
                ]).subscribe(([bundle, contacts, companies]) => {
                    const company = companies.find((company) => company.id === bundle.companyId);
                    this.bundle = {
                        ...bundle,
                        contacts: sortBy(
                            contacts.filter((contact) => bundle.contactIds.includes(contact.id)),
                            (contact) => (contact.id === bundle.responsibleId ? 'a_' : 'z_') + contact.lastName,
                        ),
                    };
                    if (bundle.responsibleId) {
                        this.bundle.responsible = contacts.find((contact) => contact.id === bundle.responsibleId);
                    }
                    if (company) {
                        this.bundle.company = {
                            ...company,
                            contacts: contacts.filter((contact) => contact.companyId === company.id),
                        };
                    }
                    this.isCompanyBundle = this.bundle.category === 'company';
                    updateBreadCrumbs({ bundleName: this.bundle.name });
                }),
            ];
        },
    },
};
</script>
