var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"flex",on:{"drop":function($event){$event.preventDefault();return _vm.dropFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.dragover.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.dragleave.apply(null, arguments)}}},[_c('a',{staticClass:"font-bold cursor-pointer flex items-center btn",class:{
            'opacity-25': _vm.disabled,
            'px-2': _vm.icon,
            'p-3': _vm.size !== 'mini',
            'p-1': _vm.size === 'mini',
            'bg-gray-300': _vm.isDraggingOver,
            'bg-white': !_vm.isDraggingOver,
        }},[(_vm.icon)?_c('Icon',{class:{ 'mx-2': _vm.size !== 'mini', 'mr-2': _vm.size === 'mini' },attrs:{"width":"16","height":"16","name":_vm.icon}}):_vm._e(),_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.loading)?_c('icon-rotate-right',{staticClass:"animate animate-spin"}):_vm._e()],1),_c('input',{ref:"input",staticClass:"hidden",attrs:{"type":"file","tabindex":"-1","accept":"image/*","disabled":_vm.disabled},on:{"change":_vm.onSelect}})])
}
var staticRenderFns = []

export { render, staticRenderFns }