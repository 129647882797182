<template>
    <div class="flex items-end">
        <div :style="{ 'max-height': maxHeight }" ref="viewport" class="overflow-hidden">
            <div ref="content" v-html="htmlContent"></div>
        </div>
        <div class="text-xs" v-if="truncated">...</div>
    </div>
</template>
<script>
export default {
    props: ['htmlContent', 'numberOfLines'],
    data() {
        return {
            truncated: false,
            maxHeight: null,
        };
    },
    mounted() {
        this.measure();
    },
    watch: {
        htmlContent() {
            this.measure();
        },
    },
    methods: {
        measure() {
            this.maxHeight =
                parseInt(getComputedStyle(this.$el, null).getPropertyValue('line-height')) * (this.numberOfLines || 1) +
                'px';
            this.$nextTick(() => (this.truncated = this.$refs.viewport.offsetHeight < this.$refs.content.offsetHeight));
        },
    },
};
</script>
