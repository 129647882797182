<template>
    <fieldset>
        <!-- chrome bug with fieldSet & displayGrid-->
        <div>
            <legend :class="{ hidden: !showLegend }" class="flex w-full bg-secondary font-bold text-white p-2">
                <div class="flex" v-if="label || icon">
                    <icon class="mr-3" v-if="icon" :name="icon"></icon>
                    <h2 v-if="label">{{ label }}</h2>
                </div>
            </legend>
            <div class="p-2 grid grid-cols-2 gap-4">
                <slot></slot>
            </div>
        </div>
    </fieldset>
</template>
<script>
import Icon from '../../icons/Icon';
export default {
    components: { Icon },
    props: { label: String, icon: String, showLegend: { type: Boolean, default: true } },
};
</script>
<style scoped></style>
