<template>
    <app-drop-down-button
        :label="$t('commons.openClose')"
        @input="$emit('expand', $event)"
        :show-label="showLabel"
        icon="icon-plus-box-outline"
        ref="select"
    >
        <option value="0">{{ $t('project.follow.planning.level0') }}</option>
        <optgroup :label="$t('project.follow.planning.levelTo')">
            <option value="1">{{ $t('project.follow.planning.level1') }}</option>
            <option value="2">{{ $t('project.follow.planning.level2') }}</option>
            <option value="3">{{ $t('project.follow.planning.level3') }}</option>
            <option value="4">{{ $t('project.follow.planning.level4') }}</option>
            <option value="5">{{ $t('project.follow.planning.level5') }}</option>
            <option value="6">{{ $t('project.follow.planning.level6') }}</option>
        </optgroup>
        <option value="all">{{ $t('project.follow.planning.levelAll') }}</option>
    </app-drop-down-button>
</template>
<script>
import AppDropDownButton from '../appDropDownButton/AppDropDownButton';
export default {
    components: { AppDropDownButton },
    props: {
        showLabel: Boolean,
    },
    methods: {
        focus() {
            this.$refs.select.focus();
        },
    },
};
</script>
