<template>
    <read-only-zone-observation v-if="!isAdmin"></read-only-zone-observation>
    <div v-else class="flex items-center flex-col w-full">
        <ObservationForm @deleted="back" ref="form" :saveFn="save" :id="$route.params.observationId"></ObservationForm>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </div>
</template>

<script>
import { createObservation, sanitizeObservation, updateObservation } from '@/features/observations/observation.service';
import ObservationForm from '@/features/observations/ObservationForm';
import ReadOnlyZoneObservation from '@/features/tours/ReadOnlyZoneObservation';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: { AppQuickActions, ReadOnlyZoneObservation, ObservationForm },
    created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.projectPhase = project.phase;
            this.defaultReporterId = project.me.bundleIds[0];
        });
        localStorage.setItem(
            'observation.lastVisitedObservationId.' + this.$route.params.projectId,
            this.$route.params.observationId,
        );
    },
    computed: {
        quickActions() {
            if (this.$route.params.observationId === 'new') {
                return [];
            } else {
                return [
                    {
                        name: this.$t('observations.duplicate'),
                        run: () => this.duplicate(),
                    },
                    {
                        name: this.$t('observations.newObservation'),
                        run: () => this.newObservation(),
                    },
                ];
            }
        },
    },
    methods: {
        async newObservation() {
            const observation = await createObservation(this.$route.params.projectId, {
                title: this.$t('project.follow.observation.newObservation'),
                phase: this.projectPhase,
                reportedBy: this.defaultReporterId,
            });
            await this.$router.push({
                name: 'followObservation',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        async duplicate() {
            const currentObservation = this.$refs.form.observation;
            const observationEntity = {
                title: currentObservation.title,
                page: currentObservation.page || 1,
                footprint: currentObservation.footprint || null,
                phase: currentObservation.phase || this.safePhase(),
                dueDate: currentObservation.dueDate,
                zoneId: currentObservation.zoneId,
                roomId: currentObservation.roomId,
                supportId: currentObservation.supportId,
                type: currentObservation.type,
                reportedBy: currentObservation.reporter ? currentObservation.reporter.id : null,
                recipientIds: currentObservation.recipientIds || [],
            };
            const observation = await createObservation(this.$route.params.projectId, observationEntity);
            this.$router.push({
                name: 'followObservation',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        async save(observation) {
            const observationEntity = sanitizeObservation(observation);
            return updateObservation(this.$route.params.projectId, { id: observation.id, ...observationEntity });
        },
        back() {
            this.$router.push({ name: this.$route.meta.parentName, params: this.$route.params });
        },
    },
    data() {
        return { isAdmin: false, defaultReporterId: null, projectPhase: null };
    },
};
</script>
