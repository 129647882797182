var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-popup',{ref:"popup",attrs:{"title":_vm.$t('impacts.title'),"variant":"alert","show-header":true}},[(_vm.impacts === null)?_c('div',{staticClass:"flex gap-3 w-full p-6"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"}),_vm._v("\n        "+_vm._s(_vm.$t('impacts.loading'))+"\n    ")],1):_vm._e(),(!!_vm.impacts)?_c('div',{staticClass:"flex flex-col w-full p-6"},[(Object.values(_vm.impacts).find((list) => list.length > 0))?_c('ul',{staticClass:"flex-col flex gap-3"},[(_vm.impacts.contacts.length)?_c('li',[_c('ul',{staticClass:"list-disc p-2"},[_c('app-impact',{attrs:{"length":_vm.impacts.contacts.length,"label":_vm.$t('impacts.contacts'),"tooltip":_vm.impacts.contacts.map((contact) => contact.firstName + ' ' + contact.lastName).join('\n')}})],1)]):_vm._e(),_c('li',[_vm._v("\n                "+_vm._s(_vm.$t('impacts.impactsTip'))+" :\n                "),_c('ul',{staticClass:"list-disc p-2"},[_c('app-impact',{attrs:{"length":_vm.impacts.preparationVisas.length,"label":_vm.impacts.preparationVisas.length === 1
                                ? _vm.$t('impacts.preparationVisa')
                                : _vm.$t('impacts.preparationVisas')}}),_c('app-impact',{attrs:{"length":_vm.impacts.services.length,"label":_vm.impacts.services.length === 1
                                ? _vm.$t('impacts.removedService')
                                : _vm.$t('impacts.removedServices'),"tooltip":_vm.impacts.services.map((service) => service.name).join('\n'),":no-item-label":_vm.$t('impacts.noService')}}),_c('app-impact',{attrs:{"length":_vm.impacts.directTasks.length,"label":_vm.impacts.directTasks.length === 1 ? _vm.$t('impacts.directTask') : _vm.$t('impacts.directTasks'),"tooltip":_vm.impacts.directTasks.map((task) => task.label).join('\n'),":no-item-label":_vm.$t('impacts.noDirectTask')}}),_c('app-impact',{attrs:{"length":_vm.impacts.linkedTasks.length,"label":_vm.impacts.linkedTasks.length === 1 ? _vm.$t('impacts.linkedTask') : _vm.$t('impacts.linkedTasks'),"tooltip":_vm.impacts.linkedTasks.map((task) => task.label).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.supports.length,"label":_vm.impacts.supports.length === 1 ? _vm.$t('impacts.support') : _vm.$t('impacts.supports'),"tooltip":_vm.impacts.supports.map((support) => support.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.certificates.length,"label":_vm.impacts.certificates.length === 1
                                ? _vm.$t('impacts.certificate')
                                : _vm.$t('impacts.certificates'),"tooltip":_vm.impacts.certificates.map((certificate) => certificate.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.autoControls.length,"label":_vm.impacts.autoControls.length === 1
                                ? _vm.$t('impacts.autoControl')
                                : _vm.$t('impacts.autoControls'),"tooltip":_vm.impacts.autoControls.map((autoControl) => autoControl.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.receptions.length,"label":_vm.impacts.receptions.length === 1 ? _vm.$t('impacts.reception') : _vm.$t('impacts.receptions'),"tooltip":_vm.impacts.receptions.map((reception) => reception.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.observations.length,"label":_vm.impacts.observations.length === 1
                                ? _vm.$t('impacts.observation')
                                : _vm.$t('impacts.observations'),"tooltip":_vm.impacts.observations.map((observation) => observation.label).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.observationsReports.length,"label":_vm.impacts.observationsReports.length === 1
                                ? _vm.$t('impacts.observationsReport')
                                : _vm.$t('impacts.observationsReports'),"tooltip":_vm.impacts.observationsReports
                                .map((observationsReport) => observationsReport.name)
                                .join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.conceptions.length,"label":_vm.impacts.conceptions.length === 1
                                ? _vm.$t('impacts.conceptions')
                                : _vm.$t('impacts.conceptionss'),"tooltip":_vm.impacts.conceptions.map((conception) => conception.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.preparations.length,"label":_vm.impacts.preparations.length === 1
                                ? _vm.$t('impacts.preparation')
                                : _vm.$t('impacts.preparations'),"tooltip":_vm.impacts.preparations.map((preparation) => preparation.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.preparationsReports.length,"label":_vm.impacts.preparationsReports.length === 1
                                ? _vm.$t('impacts.preparationsReport')
                                : _vm.$t('impacts.preparationsReports'),"tooltip":_vm.impacts.preparationsReports
                                .map((preparationsReport) => preparationsReport.name)
                                .join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.meetingConvocations.length,"label":_vm.impacts.meetingConvocations.length === 1
                                ? _vm.$t('impacts.meetingConvocation')
                                : _vm.$t('impacts.meetingConvocations'),"tooltip":_vm.impacts.meetingConvocations.map((meeting) => meeting.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.meetingRecipients.length,"label":_vm.impacts.meetingRecipients.length === 1
                                ? _vm.$t('impacts.meetingRecipient')
                                : _vm.$t('impacts.meetingRecipients'),"tooltip":_vm.impacts.meetingRecipients.map((meeting) => meeting.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.periods.length,"label":_vm.impacts.periods.length === 1 ? _vm.$t('impacts.period') : _vm.$t('impacts.periods'),"tooltip":_vm.impacts.periods.map((meeting) => meeting.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.weatherIssues.length,"label":_vm.impacts.weatherIssues.length === 1
                                ? _vm.$t('impacts.weatherIssue')
                                : _vm.$t('impacts.weatherIssues'),"tooltip":_vm.impacts.weatherIssues.map((weatherIssue) => weatherIssue.name).join('\n')}})],1)])]):_c('span',[_vm._v(_vm._s(_vm.$t('impacts.noImpact')))])]):_vm._e(),_c('app-footer',{staticClass:"w-full p-2",attrs:{"ok-label":_vm.$t('impacts.confirm'),"variant":"danger","disabled":_vm.impacts === null},on:{"click":function($event){_vm.$refs.popup.close();
            _vm.$emit('deleteConfirmed');}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('app-button',{attrs:{"variant":"default","label":_vm.$t('commons.cancel'),"size":"mini"},on:{"click":function($event){return _vm.$refs.popup.close()}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }