<template>
    <div style="page-break-before: always">
        <app-separator tag="h1" :label="$t('project.editions.meetingReports.directory')"></app-separator>
        <div class="grid text-xs gap-y-2 items-center" style="grid-template-columns: repeat(3, auto)">
            <!-- MOA-->
            <app-separator variant="underline" class="col-span-3 my-2 text-base">
                {{ $t('project.editions.meetingReports.moas') }}
            </app-separator>

            <template v-for="bundle in groups.MOA">
                <div class="flex flex-col h-full">
                    <span class="font-bold">{{ bundle.company.name }}</span>
                    <span>
                        {{ bundle.name }}
                    </span>
                </div>
                <div>
                    <p>{{ bundle.company.address1 }}</p>
                    <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                    <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                    <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                </div>
                <div class="flex flex-col">
                    <app-phone :number="bundle.company.phone"></app-phone>
                    <app-mail :email="bundle.company.email"></app-mail>
                </div>
                <template v-for="contact in bundle.contacts">
                    <div></div>
                    <div>
                        <p class="font-bold">
                            {{ contact | contact }}
                        </p>
                        <p>{{ contact.role }}</p>
                    </div>
                    <div class="flex flex-col">
                        <app-phone :number="contact.phone"></app-phone>
                        <app-mail class="mx-2" :email="contact.email"></app-mail>
                    </div>
                </template>

                <div class="col-span-3 border-b border-gray-200"></div>
            </template>

            <!-- AMOA -->
            <template v-if="groups.AMOA.length > 0">
                <app-separator variant="underline" class="col-span-3 my-2 text-base">
                    {{ $t('project.editions.meetingReports.amoas') }}
                </app-separator>
                <template v-for="bundle in groups.AMOA">
                    <div class="flex flex-col h-full">
                        <span class="font-bold">{{ bundle.company.name }}</span>
                        <span>
                            {{ bundle.name }}
                        </span>
                    </div>
                    <div>
                        <p>{{ bundle.company.address1 }}</p>
                        <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                        <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                        <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                    </div>
                    <div class="flex flex-col">
                        <app-phone :number="bundle.company.phone"></app-phone>
                        <app-mail :email="bundle.company.email"></app-mail>
                    </div>
                    <template v-for="contact in bundle.contacts">
                        <div></div>
                        <div>
                            <p class="font-bold">
                                {{ contact | contact }}
                            </p>
                            <p>{{ contact.role }}</p>
                        </div>
                        <div class="flex flex-col">
                            <app-phone :number="contact.phone"></app-phone>
                            <app-mail :email="contact.email"></app-mail>
                        </div>
                    </template>

                    <div class="col-span-3 border-b border-gray-200"></div>
                </template>
            </template>

            <!-- MOE -->
            <app-separator variant="underline" class="col-span-3 my-2 text-base">
                {{ $t('project.editions.meetingReports.moes') }}
            </app-separator>
            <template v-for="bundle in groups.MOE">
                <div class="flex flex-col h-full">
                    <span class="font-bold">{{ bundle.company.name }}</span>
                    <span>
                        {{ bundle.name }}
                    </span>
                </div>
                <div>
                    <p>{{ bundle.company.address1 }}</p>
                    <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                    <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                    <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                </div>
                <div class="flex flex-col">
                    <app-phone :number="bundle.company.phone"></app-phone>
                    <app-mail :email="bundle.company.email"></app-mail>
                </div>
                <template v-for="contact in bundle.contacts">
                    <div></div>
                    <div>
                        <p class="font-bold">{{ contact | contact }}</p>
                        <p>{{ contact.role }}</p>
                    </div>
                    <div class="flex flex-col">
                        <app-phone :number="contact.phone"></app-phone>
                        <app-mail :email="contact.email"></app-mail>
                    </div>
                </template>

                <div class="col-span-3 border-b border-gray-200"></div>
            </template>
            <!-- distributors -->
            <template v-if="groups.distributor.length > 0">
                <app-separator variant="underline" class="col-span-3 my-2 text-base">
                    {{ $t('project.editions.meetingReports.distributors') }}
                </app-separator>
                <template v-for="bundle in groups.distributor">
                    <div class="flex flex-col h-full">
                        <span class="font-bold">{{ bundle.company.name }}</span>
                        <span>
                            {{ bundle.name }}
                        </span>
                    </div>
                    <div>
                        <p>{{ bundle.company.address1 }}</p>
                        <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                        <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                        <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                    </div>
                    <div class="flex flex-col">
                        <app-phone :number="bundle.company.phone"></app-phone>
                        <app-mail :email="bundle.company.email"></app-mail>
                    </div>
                    <template v-for="contact in bundle.contacts">
                        <div></div>
                        <div>
                            <p class="font-bold">
                                {{ contact | contact }}
                            </p>
                            <p>{{ contact.role }}</p>
                        </div>
                        <div class="flex flex-col">
                            <app-phone :number="contact.phone"></app-phone>
                            <app-mail :email="contact.email"></app-mail>
                        </div>
                    </template>

                    <div class="col-span-3 border-b border-gray-200"></div>
                </template>
            </template>
            <!-- companies -->
            <app-separator variant="underline" class="col-span-3 my-2 text-base">
                {{ $t('project.editions.meetingReports.companies') }}
            </app-separator>
            <template v-for="bundle in groups.company">
                <div class="flex flex-col h-full">
                    <span class="font-bold">{{ bundle.reference }} {{ bundle.name }}</span>
                    <span v-if="bundle.company">{{ bundle.company.name }}</span>
                </div>
                <div>
                    <template v-if="bundle.company">
                        <p>{{ bundle.company.address1 }}</p>
                        <p v-if="bundle.company.address2">{{ bundle.company.address2 }}</p>
                        <p v-if="bundle.company.address3">{{ bundle.company.address3 }}</p>
                        <p>{{ bundle.company.postalCode }} {{ bundle.company.city }}</p>
                    </template>
                </div>
                <div class="flex flex-col">
                    <template v-if="bundle.company">
                        <app-phone :number="bundle.company.phone"></app-phone>
                        <app-mail :email="bundle.company.email"></app-mail>
                    </template>
                </div>

                <template v-for="contact in bundle.contacts">
                    <div></div>
                    <div>
                        <p class="font-bold">{{ contact | contact }}</p>
                        <p>{{ contact.role }}</p>
                    </div>
                    <div class="flex flex-col">
                        <app-phone :number="contact.phone"></app-phone>
                        <app-mail :email="contact.email"></app-mail>
                    </div>
                </template>

                <div class="col-span-3 border-b border-gray-200"></div>
            </template>
        </div>
    </div>
</template>
<script>
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import { getMapById } from '@/services/sanitize.service';
import { getContactMap } from '@/features/contacts/contacts.service';

export default {
    components: { AppMail, AppPhone, AppSeparator },
    async created() {
        this.subscriptions = [
            combineLatest([
                getBundles(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getContactMap(this.$route.params.projectId),
            ]).subscribe(([bundles, companies, contactMap]) => {
                const companyMap = getMapById(companies);
                this.bundles = bundles
                    .filter((bundle) => !!bundle.companyId)
                    .map((bundle) => ({
                        ...bundle,
                        company: companyMap[bundle.companyId] || {},
                        contacts: bundle.contactIds.map((contactId) => contactMap[contactId]).filter((a) => !!a),
                    }));
                this.groups = bundlesToGroups(this.bundles);
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            groups: {
                MOA: [],
                AMOA: [],
                MOE: [],
                distributor: [],
                company: [],
            },
        };
    },
};
</script>
