<template>
    <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
        <form class="flex items-start flex-col gap-4">
            <app-checkbox
                :label="$t('project.follow.meetings.showCoverPage')"
                v-model="meeting.showCoverPageWithPhoto"
                @input="onCheck('showCoverPageWithPhoto')"
            />
            <app-checkbox
                :label="$t('project.follow.meetings.showParticipantsIndex')"
                v-model="meeting.showParticipantsIndex"
                @input="onCheck('showParticipantsIndex')"
            />

            <app-checkbox
                :label="$t('project.follow.meetings.showGeneralProgress')"
                v-model="meeting.showGeneralProgress"
                @input="onCheck('showGeneralProgress')"
            />

            <app-checkbox
                :label="$t('project.follow.meetings.showTasksTable')"
                v-model="meeting.showTasksTable"
                @input="onCheck('showTasksTable')"
                v-if="isMOE && project_EXE"
            />
            <app-checkbox
                :label="$t('project.follow.meetings.showPlanning')"
                v-model="meeting.showPlanning"
                @input="onCheck('showPlanning')"
                v-if="isMOE && project_EXE"
            />
            <app-checkbox
                :label="$t('project.follow.meetings.showGeneralObservations')"
                v-model="meeting.showGeneralObservations"
                @input="onCheck('showGeneralObservations')"
                v-if="isMOE && project_EXE"
            />
        </form>
    </ValidationObserver>
</template>

<script>
import { getMeeting, updateMeeting } from './meetings.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
export default {
    components: {
        AppCheckbox,
    },
    created() {
        this.subscriptions = [
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getProject(this.$route.params.projectId),
            ]).subscribe(([meeting, project]) => {
                this.meeting = meeting;
                this.isMOE = meeting.type === 'MOE';
                this.project_EXE = project.projectFeatures.includes('project_EXE');
            }),
        ];
    },
    methods: {
        onCheck(key) {
            this.saveMeeting({ [key]: this.meeting[key] });
        },
        async saveMeeting(meetingEntity) {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, ...meetingEntity });
        },
        onChangeCoverPhoto(fileResult) {
            this.saveMeeting({ coverPhotoUrl: fileResult.url });
        },
    },
    data() {
        return {
            meeting: {},
            subscriptions: [],
            isMOE: false,
            project_EXE: false,
        };
    },
};
</script>
