<template>
    <main class="m-5 mb-10 w-full flex flex-col items-center">
        <span class="text-xl" v-if="$route.name === 'followByBundlePreparationVisa'">
            {{ $t('preparations.visaOn') }}
            <router-link
                class="underline"
                :to="{ name: 'followPreparation', params: { ...$route.params, preparationId: preparation.id } }"
            >
                {{ preparation.name }}
            </router-link>
        </span>
        <ValidationObserver
            v-slot="{ invalid, errors, dirty }"
            ref="observer"
            class="flex max-w-5xl flex-col w-full justify-center"
        >
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-bundle-picker
                        :title="$t('bundles.emitter')"
                        :required="true"
                        v-model="visa.emitter"
                        :options="bundles"
                        @input="save"
                        class="col-span-2"
                        :disabled="!isAdmin"
                    />
                    <app-date-input
                        v-model="visa.emissionDueDate"
                        @input="save"
                        :label="$t('preparations.emissionDueDate')"
                        class="col-span-2"
                        :disabled="!isAdmin"
                    />
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-4" :label="$t('preparations.observations')">
                    <table class="border w-full col-span-2">
                        <thead>
                            <tr class="border">
                                <th class="border p-1 text-left">{{ $t('preparations.document') }}</th>
                                <th class="border p-1 text-left">{{ $t('preparations.observationCount') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="attachment in preparationAttachments">
                                <tr class="border">
                                    <td class="border p-1">
                                        <router-link
                                            :to="{
                                                name:
                                                    preparation.phase === 'exe'
                                                        ? 'preparationDocument'
                                                        : 'conceptionDocument',
                                                params: { ...$route.params, documentId: attachment.id },
                                            }"
                                            class="underline"
                                        >
                                            {{ attachment.name }}
                                        </router-link>
                                    </td>
                                    <td class="border p-1">
                                        <router-link
                                            :to="{
                                                name:
                                                    preparation.phase === 'exe'
                                                        ? 'preparationDocument'
                                                        : 'conceptionDocument',
                                                params: { ...$route.params, documentId: attachment.id },
                                            }"
                                            class=""
                                        >
                                            {{ attachment.observations.length }}
                                        </router-link>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </app-fieldset>
                <app-fieldset :label="$t('preparations.visaConclusion')" class="col-span-2">
                    <app-date-input
                        v-model="visa.emissionDate"
                        @input="save"
                        :label="$t('preparations.emissionDate')"
                        class="col-span-2"
                        :disabled="!isAdmin && !isEmitter"
                    />
                    <preparation-visa-picker
                        v-model="visa.conclusion"
                        @input="save"
                        class="col-span-2 md:col-span-1"
                        :disabled="!isAdmin && !isEmitter"
                    />
                    <app-label :label="$t('preparations.visaFile')" class="flex flex-col col-span-2"></app-label>
                    <div class="flex items-center gap-4">
                        <div
                            v-if="visa.conclusion && visa.generationStartDate && !visa.generationEndDate"
                            class="flex items-center"
                        >
                            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                            <span class="text-xs italic">
                                {{ $t('project.editions.meetingReports.generationPending') }}
                            </span>
                        </div>
                        <span
                            v-else-if="
                                visa.conclusion &&
                                visa.generationSucceed &&
                                visa.generationStartDate &&
                                visa.generationEndDate
                            "
                        >
                            <app-file-link :url="visa.url" class="underline">
                                {{ $t('preparations.visaFile') }} au {{ visa.generationStartDate | humanizeDate }}
                            </app-file-link>
                        </span>
                        <span
                            v-else-if="
                                visa.conclusion &&
                                !visa.generationSucceed &&
                                visa.generationStartDate &&
                                visa.generationEndDate
                            "
                            class="text-red-700 italic"
                        >
                            {{ $t('commons.error') }}
                        </span>
                        <app-button
                            :disabled="
                                (!isAdmin && !isEmitter) ||
                                (visa.generationStartDate && !visa.generationEndDate) ||
                                !visa.conclusion
                            "
                            v-if="!visa.url"
                            size="mini"
                            class="p-2 shadow"
                            @click="exportPDF()"
                            icon="icon-file-pdf-outline"
                            :label="$t('commons.generatePDF')"
                        />
                        <app-drop-down-button
                            label="action"
                            @input="onAction"
                            :show-label="false"
                            ref="select"
                            :disabled="visa.generationStartDate && !visa.generationEndDate && justExported"
                            v-if="(isAdmin || isEmitter) && visa.conclusion && visa.url"
                            size="mini"
                        >
                            <option value="regenerate">{{ $t('commons.regeneratePDF') }}</option>
                            <option
                                value="sendByMail"
                                :disabled="!visa.generationSucceed"
                                v-if="
                                    (isAdmin || isEmitter) &&
                                    visa.conclusion &&
                                    visa.url &&
                                    visa.generationStartDate &&
                                    visa.generationEndDate
                                "
                            >
                                {{ $t('commons.sendByMail') }}
                            </option>
                            <option value="delete" v-if="visa.generationEndDate">{{ $t('commons.deletePdf') }}</option>
                        </app-drop-down-button>
                    </div>
                </app-fieldset>
                <app-fieldset :label="$t('preparations.attachments')" class="flex flex-col col-span-2 mt-4">
                    <div class="col-span-2 flex">
                        <app-multiple-upload-button
                            ref="upload"
                            icon="icon-paperclip"
                            :label="$t('commons.newAttachment')"
                            :end-point="`/api/projects/${$route.params.projectId}/preparationVisas/${$route.params.preparationVisaId}/attachments`"
                            :name="$t('commons.newAttachment')"
                            class="text-sm offline:hidden"
                            :disabled="!isAdmin && !isEmitter"
                        />
                    </div>
                    <div>
                        <template v-for="attachment in visa.attachments">
                            <app-file-link
                                :url="attachment.url"
                                :fileName="attachment.fileName"
                                class="underline"
                                :to="{
                                    name: 'fileViewer',
                                    params: {
                                        projectId: $route.params.projectId,
                                    },
                                    query: {
                                        url: attachment.url,
                                        backName: $t('preparations.visas'),
                                    },
                                }"
                                :removable="isAdmin"
                                @delete="onDeleteAttachment(attachment)"
                            >
                                {{ attachment.fileName }}
                            </app-file-link>
                        </template>
                    </div>
                </app-fieldset>
                <app-save-on-leave :dirty="dirty" :saveFn="() => save(true)"></app-save-on-leave>
                <preparation-visa-mail-popup
                    ref="emailPopup"
                    :visa="visa"
                    :preparation="preparation"
                    :preparationAttachments="preparationAttachments"
                ></preparation-visa-mail-popup>
            </form>
        </ValidationObserver>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import { getPreparation } from './preparations.service';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppSelect from '@/components/appSelect/AppSelect';
import { getMapById } from '@/services/sanitize.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppLabel from '@/components/appLabel/AppLabel';
import IconRotateRight from '@/icons/IconRotateRight';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPicker from '@/components/appPicker/AppPicker';
import PreparationVisaLegend from '@/features/preparations/PreparationVisaLegend';
import PreparationVisaPicker from '@/features/preparations/PreparationVisaPicker';
import AppButton from '@/components/appButton/AppButton';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { getAPIHeaders } from '@/services/api.service';
import { getPreparationVisa, updatePreparationVisa } from '@/features/preparations/preparationVisas.service';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getProject } from '@/features/projects/projects.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import { getCompanies } from '@/features/companies/companies.service';
import { getContactMap } from '@/features/contacts/contacts.service';
import { getPreparationAttachments } from '@/features/preparations/preparationAttachments.service';
import { getObservationsForRelatedContent } from '@/features/observations/observation.service';
import { replicateProjects } from '@/rxdb/database';
import PreparationVisaMailPopup from '@/features/preparations/PreparationVisaMailPopup.vue';

export default {
    components: {
        PreparationVisaMailPopup,
        AppMultipleUploadButton,
        AppDropDownButton,
        AppButton,
        PreparationVisaPicker,
        PreparationVisaLegend,
        AppPicker,
        AppUploadButton,
        AppFileLink,
        IconRotateRight,
        AppLabel,
        AppSeparator,
        AppSaveOnLeave,
        AppFooter,
        AppSelect,
        AppDateInput,
        AppBundlePicker,
        AppFieldset,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    data() {
        return {
            isAdmin: false,
            justExported: false,
            isEmitter: false,
            project: null,
            preparation: {},
            subscriptions: [],
            visa: {},
            bundles: [],
            preparationAttachments: [],
        };
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getBundles(this.$route.params.projectId),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisa(this.$route.params.projectId, this.$route.params.preparationVisaId),
                    getCompanies(this.$route.params.projectId),
                    getContactMap(this.$route.params.projectId),
                    getPreparationAttachments(this.$route.params.projectId, this.$route.params.preparationId),
                    getObservationsForRelatedContent(
                        this.$route.params.projectId,
                        this.$route.params.preparationVisaId,
                    ),
                ]).subscribe(
                    ([
                        project,
                        bundles,
                        preparation,
                        visa,
                        companies,
                        contactMap,
                        preparationAttachments,
                        observations,
                    ]) => {
                        this.project = project;
                        const companyMap = getMapById(companies);
                        this.bundles = bundles.map((bundle) => ({
                            ...bundle,
                            company: companyMap[bundle.companyId],
                            responsible: contactMap[bundle.responsibleId],
                            contacts: bundle.contactIds.map((id) => contactMap[id]).filter((a) => !!a),
                        }));
                        const bundleMap = getMapById(this.bundles);
                        this.preparation = {
                            ...preparation,
                            bundle: bundleMap[preparation.bundleId],
                        };
                        this.visa = {
                            ...visa,
                            emitter: bundleMap[visa.emitterId],
                        };
                        this.isEmitter = !!project.me.bundleIds.find(
                            (bundleId) => this.visa.emitterId && bundleId === this.visa.emitterId,
                        );
                        this.isAdmin =
                            this.$route.name === 'preparationVisa' ||
                            this.$route.name === 'followByBundlePreparationVisa'
                                ? project.me.allowedFeatures.includes('project_preparations')
                                : project.me.allowedFeatures.includes('project_conceptions');

                        updateBreadCrumbs({
                            preparationName: this.preparation.name,
                            visaName:
                                this.$t('preparations.visaFrom') +
                                ' ' +
                                (this.visa.emitter ? this.visa.emitter.label : ''),
                        });
                        this.preparationAttachments = preparationAttachments.map((preparationAttachment) => ({
                            ...preparationAttachment,
                            observations: observations.filter(
                                (observation) => observation.preparationDocumentId === preparationAttachment.id,
                            ),
                        }));
                        this.loading = false;
                    },
                ),
            ];
        },
        async onAction(action) {
            if (action === 'regenerate') {
                await this.exportPDF();
            } else if (action === 'sendByMail') {
                this.$refs.emailPopup.open(this.visa);
            } else if (action === 'delete') {
                await this.clearPDF();
            }
        },
        async clearPDF() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await fetch(
                    new Request(
                        `/api/projects/${this.project.id}/preparations/${this.preparation.id}/visas/${this.visa.id}/report`,
                        {
                            method: 'DELETE',
                            headers: getAPIHeaders(),
                        },
                    ),
                );
            }
        },
        async exportPDF() {
            if (
                !this.visa.generationSucceed ||
                !this.visa.url ||
                (await confirm(this.$t('project.editions.confirmRegenerate')))
            ) {
                this.visa.generationStartDate = new Date();
                this.visa.generationEndDate = null;
                await replicateProjects(this.$route.params.projectId);
                this.justExported = true;
                await fetch(
                    new Request(
                        `/api/projects/${this.project.id}/preparations/${this.preparation.id}/visas/${this.visa.id}/report`,
                        {
                            method: 'PUT',
                            headers: getAPIHeaders(),
                        },
                    ),
                );
            }
        },
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return updatePreparationVisa(this.$route.params.projectId, {
                    id: this.visa.id,
                    attachments: this.visa.attachments.filter((anAttachment) => anAttachment.url !== attachment.url),
                });
            }
        },
        async save() {
            await updatePreparationVisa(this.$route.params.projectId, {
                id: this.visa.id,
                emitterId: this.visa.emitter ? this.visa.emitter.id : null,
                emissionDueDate: this.visa.emissionDueDate,
                emissionDate: this.visa.emissionDate || (this.visa.conclusion ? new Date() : null),
                conclusion: this.visa.conclusion || null,
            });
            this.$refs.observer.reset();
        },
    },
};
</script>
