<template>
    <div>
        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-4">
                <span class="text-lg">{{ support.code }}</span>
                <span class="text-xl font-bold">{{ support.name }}</span>
            </div>
            <div class="shadow-md border p-4">
                <table>
                    <tr>
                        <th role="rowheader" class="p-2 text-right">{{ $t('commons.version') }} :</th>
                        <td class="p-2">
                            <app-select
                                :label="$t('commons.version')"
                                :value="support.id"
                                class=""
                                :show-label="false"
                                :disabled="!support.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in support.versions"
                                    :key="version.id"
                                >
                                    {{ support.versions.length - index }} ({{ version.createdAt | humanizeDate }})
                                </option>
                            </app-select>
                        </td>
                    </tr>
                    <tr v-if="support.type">
                        <th role="rowheader" class="p-2 text-right">{{ $t('supports.type') }} :</th>
                        <td class="p-2">{{ $t('supports.types.' + support.type) }}</td>
                    </tr>
                    <tr v-if="support.emissionDueDate">
                        <th role="rowheader" class="p-2 text-right">{{ $t('supports.emissionDueDate') }} :</th>
                        <td class="p-2">{{ support.emissionDueDate | humanizeDate }}</td>
                    </tr>
                    <tr v-if="support.emissionDate">
                        <th role="rowheader" class="p-2 text-right">{{ $t('supports.emissionDate') }} :</th>
                        <td class="p-2">{{ support.emissionDate | humanizeDate }}</td>
                    </tr>
                    <tr v-if="support.emitter">
                        <th role="rowheader" class="p-2 text-right">{{ $t('bundles.emitter') }} :</th>
                        <td class="p-2"><app-bundle :bundle="support.emitter"></app-bundle></td>
                    </tr>
                    <tr v-if="support.locations">
                        <th role="rowheader" class="p-2 text-right align-top">{{ $t('supports.locations') }} :</th>
                        <td class="p-2 text-sm">
                            <div v-for="location in support.locations">
                                {{ location.fullName }}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <template v-if="isEmitter">
                <app-separator class="col-span-2 my-5" :label="$t('supports.attachments')" />
                <div class="col-span-2 flex justify-between">
                    <app-upload-button
                        ref="upload"
                        icon="icon-paperclip"
                        :label="!support.url ? $t('commons.uploadFile') : $t('supports.replaceDocument')"
                        @select="onUploaded"
                        :name="$t('supports.newAttachment')"
                        class="text-sm offline:hidden"
                        method="PUT"
                        :end-point="`/api/projects/${$route.params.projectId}/supports/${support.id}/attachment`"
                    />
                </div>
            </template>
            <div class="flex flex-col">
                <div class="col-span-2 flex justify-between"></div>
                <div class="flex items-center col-span-2 max-w-full overflow-hidden">
                    <app-viewport class="w-full mx-5" v-if="support.type === 'graphic'">
                        <app-leaflet-viewer
                            :show-rotation="true"
                            v-if="support.url"
                            :src="support.url"
                            :rotation="support.rotation"
                            :cropBox="support.cropBox"
                            @rotate="onRotate"
                            :page="1"
                            ref="leafletViewer"
                        >
                            <template v-slot:extraToolbar>
                                <app-button
                                    size="mini"
                                    icon="icon-overscan"
                                    :label="$t('commons.fullScreen')"
                                    @click="
                                        $router.push({
                                            name: 'supportAttachmentViewer',
                                        })
                                    "
                                ></app-button>
                            </template>
                        </app-leaflet-viewer>
                    </app-viewport>
                    <iframe v-if="support.type === 'text'" :src="support.url" class="w-full min-h-screen my-5"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import { getSupport, updateSupport } from './supports.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppFileLink from '../../components/appFileLink/AppFileLink';
import { updateBreadCrumbs } from '@/state/state';
import AppBackButton from '../../components/appBackButton/AppBackButton';
import AppViewport from '@/components/AppViewport';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';

import AppBundle from '@/components/app-bundle/appBundle';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppSeparator,
        AppUploadButton,
        AppBundle,
        AppLeafletViewer,
        AppViewport,
        AppBackButton,
        AppFileLink,
        AppSelect,
        AppButton,
    },
    created() {
        this.init();
    },
    watch: {
        $route() {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getSupport(this.$route.params.projectId, this.$route.params.supportId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([support, project]) => {
                    this.support = support;
                    this.isEmitter = project.me.bundleIds.includes(support.emitterId);
                    updateBreadCrumbs({
                        supportName: this.support.name,
                    });
                }),
            ];
        },
        async onRotate(rotation) {
            this.support.rotation = rotation;
        },
        async onVersionSelected(supportId) {
            await this.$router.push({
                name: 'support',
                params: {
                    projectId: this.$route.params.projectId,
                    supportId,
                },
            });
        },
        async onUploaded(fileResult) {
            this.support.fileName = fileResult.name;
            this.support.url = fileResult.url;
            if (!this.support.emissionDate) {
                this.support.emissionDate = new Date();
            }
            await updateSupport(this.$route.params.projectId, {
                id: this.support.id,
                fileName: this.support.name,
                url: this.support.url,
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            isEmitter: false,
            support: {
                name: this.$t('supports.newName'),
                type: null,
                emissionDate: new Date(),
                emitter: null,
                locationIds: [],
            },
        };
    },
};
</script>
