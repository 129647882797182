<template>
    <div style="page-break-before: always">
        <app-separator>
            <h1>{{ $t('observations.types.generals') }}</h1>
        </app-separator>
        <table class="mt-4 w-full text-xs border">
            <thead class="table-header-group font-bold table-fixed">
                <tr class="border">
                    <td class="p-1" style="width: 8ch">{{ $t('commons.date') }}</td>
                    <td class="p-1">{{ $t('project.editions.observationsReports.title') }}</td>
                </tr>
            </thead>
            <tbody>
                <template v-for="observation in generalObservations">
                    <tr class="border">
                        <td class="p-1 align-top">
                            {{ observation.createdAt | humanizeDate }}
                        </td>
                        <td class="p-1">
                            <div class="whitespace-pre-wrap html-breakline" v-html="observation.title" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { getObservations } from '@/features/observations/observation.service';
import { sortBy } from '@/services/sanitize.service';
export default {
    components: { AppSeparator },
    async created() {
        this.subscriptions = [
            getObservations(this.$route.params.projectId).subscribe((observations) => {
                this.generalObservations = sortBy(
                    observations.filter((observation) => observation.type === 'general' && !observation.resolvedAt),
                    'createdAt',
                );
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            generalObservations: [],
        };
    },
};
</script>
