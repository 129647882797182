var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.meeting)?_c('main',{staticClass:"flex flex-col gap-4 max-w-screen-2xl w-full p-2 max-h-main overflow-hidden"},[_c('div',{staticClass:"flex flex-col gap-4 shadow-md border p-4"},[_c('p',{staticClass:"flex-grow text-lg font-bold flex"},[_c('span',{staticClass:"border-0 font-bold"},[_vm._v(_vm._s(_vm.meeting.name))])]),_c('div',{staticClass:"flex items-center w-full flex-col md:flex-row gap-2"},[_c('div',{staticClass:"text-sm text-gray-700 flex-grow whitespace-nowrap flex gap-1 items-center"},[_c('span',[_vm._v(_vm._s(_vm._f("humanizeDateTimeShort")(_vm.meeting.date)))]),_c('span',[_vm._v(_vm._s(_vm.meeting.location))])])]),_c('div',{staticClass:"mr-4 flex flex-grow flex-col md:flex-row gap-2"},[(_vm.meeting.generationSucceeded)?_c('app-file-link',{staticClass:"text-xs mr-4",attrs:{"fileName":_vm.meeting.fileName,"url":_vm.meeting.url,"removable":false,"to":{
                    name: 'meetingReportViewerDeep',
                    params: {
                        projectId: _vm.$route.params.projectId,
                        meetingId: _vm.meeting.id,
                    },
                }}}):_vm._e(),(_vm.meeting.generationEndDate && !_vm.meeting.generationSucceeded)?_c('div',{staticClass:"text-red-500 flex items-center"},[_c('icon-alert-circle',{attrs:{"width":"16"}}),_c('span',{staticClass:"text-xs italic"},[_vm._v("\n                    "+_vm._s(_vm.$t('project.editions.meetingReports.generationFailed'))+"\n                ")])],1):_vm._e(),(_vm.meeting.generationStartDate && !_vm.meeting.generationEndDate)?_c('div',{staticClass:"flex items-center"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"}),_c('span',{staticClass:"text-xs italic"},[_vm._v("\n                    "+_vm._s(_vm.$t('project.editions.meetingReports.generationPending'))+"\n                ")])],1):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }