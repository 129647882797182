<template>
    <div class="flex flex-col gap-4">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2 gap-4">
                <div class="flex col-span-2">
                    <app-checkbox
                        v-model="meeting.showEmptyBundlePage"
                        :label="$t('project.editions.meetingReports.showEmptyBundlePage')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2">
                    <app-checkbox
                        v-model="meeting.showEmptySectionBundlePage"
                        :label="$t('project.editions.meetingReports.showEmptySectionBundlePage')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <app-multi-picker
                    class="col-span-2 p-2"
                    v-model="meeting.emitters"
                    :options="bundles"
                    :label="$t('project.editions.observationsReports.emitters')"
                    :placeholder="$t('commons.all')"
                    @close="saveEmitters"
                    @removeChips="saveEmitters"
                    labelKey="label"
                >
                    <template v-slot:option="{ option }">
                        <app-bundle :bundle="option"></app-bundle>
                    </template>
                </app-multi-picker>
                <div class="flex col-span-2" v-if="project_CON">
                    <app-checkbox
                        v-model="meeting.showConceptions"
                        :label="$t('project.editions.meetingReports.showConceptions')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_CON">
                    <app-checkbox
                        v-model="meeting.showConceptionVisas"
                        :label="$t('project.editions.meetingReports.showConceptionVisas')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="isMOE && project_EXE">
                    <app-checkbox
                        v-model="meeting.showPreparations"
                        :label="$t('project.editions.meetingReports.showPreparations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="isMOE && project_EXE">
                    <app-checkbox
                        v-model="meeting.showPreparationVisas"
                        :label="$t('project.editions.meetingReports.showPreparationVisas')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2">
                    <app-checkbox
                        v-model="meeting.showCertificates"
                        :label="$t('project.editions.meetingReports.showCertificates')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="isMOE && project_EXE">
                    <app-checkbox
                        v-model="meeting.showAutoControls"
                        :label="$t('project.editions.meetingReports.showAutoControls')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="isMOE && project_EXE">
                    <app-checkbox
                        v-model="meeting.showReceptions"
                        :label="$t('project.editions.meetingReports.showReceptions')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="isMOE && project_EXE">
                    <app-checkbox
                        v-model="meeting.showTasksSectionBundlePage"
                        :label="$t('project.editions.meetingReports.showTasksSectionBundlePage')"
                        :show-label="false"
                        @input="save"
                    >
                        <div class="flex font-bold gap-1 text-gray-700">
                            {{ $t('project.editions.meetingReports.showTasksSectionBundlePage') }}
                            {{ $t('commons.from') }}
                            <app-date-link
                                :disabled="!meeting.showTasksSectionBundlePage"
                                v-model="meeting.bundleTasksStartDate"
                                @input="save()"
                            />
                            {{ $t('commons.to') }}
                            <app-date-link
                                :disabled="!meeting.showTasksSectionBundlePage"
                                v-model="meeting.bundleTasksEndDate"
                                @input="save()"
                            />
                        </div>
                    </app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_EXE">
                    <app-checkbox
                        v-model="meeting.showEXEObservations"
                        :label="$t('observations.EXEObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_OPR">
                    <app-checkbox
                        v-model="meeting.showOPRObservations"
                        :label="$t('observations.OPRObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_OPR">
                    <app-checkbox
                        v-model="meeting.showReceiptObservations"
                        :label="$t('observations.ReceiptObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_OPL">
                    <app-checkbox
                        v-model="meeting.showOPLObservations"
                        :label="$t('observations.OPLObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_OPL">
                    <app-checkbox
                        v-model="meeting.showDeliveryObservations"
                        :label="$t('observations.DeliveryObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
                <div class="flex col-span-2" v-if="project_OPL">
                    <app-checkbox
                        v-model="meeting.showAPAObservations"
                        :label="$t('observations.APAObservations')"
                        @input="save"
                    ></app-checkbox>
                </div>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        </ValidationObserver>
    </div>
</template>

<script>
import { getMeeting, updateMeeting } from './meetings.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppTips from '@/components/app-tips/AppTips';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import { getMapById, sortBy } from '@/services/sanitize.service';
import { getBundles } from '@/features/bundles/bundles.service';

export default {
    components: { AppMultiPicker, AppBundle, AppDateLink, AppCheckbox, AppTips, AppSaveOnLeave, AppDateInput },

    created() {
        this.subscriptions = [
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getProject(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([meeting, project, bundles]) => {
                this.bundles = sortBy(bundles, 'label');
                const bundleMap = getMapById(bundles);
                this.meeting = {
                    ...meeting,
                    emitters: meeting.emitterIds
                        ? sortBy(
                              meeting.emitterIds.map((bundleId) => bundleMap[bundleId]),
                              'reference',
                          )
                        : [],
                };
                this.isMOE = meeting.type === 'MOE';
                this.project_EXE = project.projectFeatures.includes('project_EXE');
                this.project_CON = project.projectFeatures.includes('project_CON');
                this.project_OPR = project.projectFeatures.includes('project_OPR');
                this.project_OPL = project.projectFeatures.includes('project_OPL');
            }),
        ];
    },
    methods: {
        async saveEmitters() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                emitterIds: this.meeting.emitters.map((bundle) => bundle.id),
            });
        },
        save() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                showEmptyBundlePage: this.meeting.showEmptyBundlePage,
                showEmptySectionBundlePage: this.meeting.showEmptySectionBundlePage,
                showPreparations: this.meeting.showPreparations,
                showPreparationVisas: this.meeting.showPreparationVisas,
                showTasksSectionBundlePage: this.meeting.showTasksSectionBundlePage,
                bundleTasksStartDate: this.meeting.bundleTasksStartDate,
                bundleTasksEndDate: this.meeting.bundleTasksEndDate,
                showEXEObservations: this.meeting.showEXEObservations,
                showOPRObservations: this.meeting.showOPRObservations,
                showReceiptObservations: this.meeting.showReceiptObservations,
                showOPLObservations: this.meeting.showOPLObservations,
                showDeliveryObservations: this.meeting.showDeliveryObservations,
                showAPAObservations: this.meeting.showAPAObservations,
                showConceptions: this.meeting.showConceptions,
                showConceptionVisas: this.meeting.showConceptionVisas,
                showCertificates: this.meeting.showCertificates,
                showReceptions: this.meeting.showReceptions,
                showAutoControls: this.meeting.showAutoControls,
            });
        },
    },
    data() {
        return {
            meeting: {},
            project_CON: false,
            project_EXE: false,
            project_OPL: false,
            project_OPR: false,
            isMOE: false,
            bundles: [],
        };
    },
};
</script>
