<template>
    <div class="flex flex-col w-full px-5 gap-5">
        <template v-if="loading">
            <div class="flex justify-center my-5">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col md:flex-row gap-2 text-sm md:sticky md:top-toolbar bg-white mt-5 z-10">
                <div class="flex flex-col gap-2 flex-grow">
                    <div class="flex gap-2">
                        <div class="flex gap-2">
                            <app-button
                                size="mini"
                                icon="icon-chevron-left"
                                :title="$t('project.follow.byBundle.previousBundle')"
                                :disabled="bundleIndex === 0"
                                @click="previousBundle()"
                            ></app-button>
                            <div class="flex-grow">
                                <app-bundle-picker
                                    :value="bundle"
                                    @input="goTo($event.id)"
                                    :options="options"
                                    :label="$t('project.follow.byBundle.currentBundle')"
                                    :showLabel="false"
                                    :showTip="false"
                                    :required="true"
                                ></app-bundle-picker>
                            </div>
                            <app-button
                                size="mini"
                                icon="icon-chevron-right"
                                :title="$t('project.follow.byBundle.nextBundle')"
                                :disabled="bundleIndex === bundles.length - 1"
                                @click="nextBundle()"
                            ></app-button>
                        </div>
                        <div class="flex-grow">
                            <app-filter ref="filter" v-model="filterAsString" @input="updateState"></app-filter>
                        </div>
                        <div class="flex items-center">
                            <app-button
                                size="mini"
                                @click="$refs.settings.open()"
                                :aria-label="$t('commons.settings')"
                                :title="$t('commons.settings')"
                                icon="icon-tune"
                            />
                        </div>
                    </div>
                    <div class="flex gap-2 text-xs w-full flex-wrap" v-if="bundle">
                        <div v-if="bundle.reference" class="flex gap-1 items-center">
                            <router-link
                                class="hover:underline flex"
                                :to="{
                                    name: 'bundle',
                                    params: {
                                        ...$route.params,
                                        bundleId: bundle.id,
                                    },
                                }"
                            >
                                {{ bundle.reference }}
                            </router-link>
                        </div>
                        <div v-if="bundle.company" class="flex gap-1 items-center">
                            <icon-domain width="16" height="16" />
                            {{ bundle.company.name }}
                        </div>
                        <div v-if="bundle.responsible" class="flex gap-1 items-center">
                            <icon-user width="16" height="16" />
                            {{ bundle.responsible.firstName }} {{ bundle.responsible.lastName }}
                        </div>
                        <app-phone
                            v-if="bundle.responsible && bundle.responsible.phone"
                            :number="bundle.responsible.phone"
                        />
                        <app-mail
                            v-if="bundle.responsible && bundle.responsible.email"
                            :email="bundle.responsible.email"
                        />
                    </div>
                    <div class="text-xs gap-4 flex w-full" v-if="$route.params.bundleId !== 'generals'">
                        <span class="font-bold">
                            {{ $t('project.editions.meetingReports.attendanceStatsTitle') }} :
                        </span>
                        <span>
                            {{ attendanceStats.present }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.present') }}
                        </span>
                        <span>
                            {{ attendanceStats.excusedAbsent }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.excusedAbsent') }}
                        </span>
                        <span>
                            {{ attendanceStats.unexcusedAbsent }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.unexcusedAbsent') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" v-if="$route.params.bundleId !== 'generals'">
                <template v-if="project_EXE">
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('project.editions.meetingReports.preparationsToEmit') }}</span>
                                <span class="text-sm" v-if="filteredPreparations.length > 0">
                                    ({{ filteredPreparations.length }})
                                </span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('preparations.newPreparation')"
                                class="mb-1"
                                @click="newPreparation('exe')"
                                v-if="isPreparationAllowed()"
                            />
                        </div>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="filteredPreparations.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="filteredPreparations.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparation in filteredPreparations">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 relative">
                                        <div class="w-full flex flex-col">
                                            <div class="flex w-full gap-2">
                                                <a
                                                    class="absolute top-0"
                                                    :id="'uuid_' + preparation.id"
                                                    style="scroll-margin-top: 9em"
                                                ></a>
                                                <span class="">
                                                    {{
                                                        preparation.type
                                                            ? $t('preparations.types.' + preparation.type)
                                                            : ''
                                                    }}
                                                    :
                                                </span>
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followByBundlePreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparation.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(preparation.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === preparation.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    {{ preparation.name.length ? preparation.name : '...' }}
                                                </router-link>
                                                <div v-if="preparation.versions.length > 1">
                                                    <span class="text-red-500 text-xs italic">
                                                        {{ $t('preparations.newVersionTip') }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparation.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparation.isLate,
                                        }"
                                    >
                                        {{ preparation.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-if="project_EXE">
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <span class="flex gap-2 items-center">
                            <span>{{ $t('project.editions.meetingReports.preparationsToVisa') }}</span>
                            <span class="text-sm" v-if="preparationVisas.length > 0">
                                ({{ preparationVisas.length }})
                            </span>
                        </span>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="preparationVisas.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparationVisa in filteredPreparationVisas">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div class="flex gap-1 relative">
                                                <a
                                                    :id="'uuid_' + preparationVisa.id"
                                                    style="scroll-margin-top: 9em"
                                                    class="absolute top-0"
                                                ></a>
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followByBundlePreparationVisa',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparationVisa.id,
                                                            preparationVisaId: preparationVisa.visa.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(preparationVisa.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === preparationVisa.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    {{ preparationVisa.name }}
                                                </router-link>
                                                <app-tips class="flex gap-1" v-if="preparationVisa.bundle">
                                                    {{ preparationVisa.bundle.label }}
                                                </app-tips>
                                            </div>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparationVisa.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparationVisa.isLate,
                                        }"
                                    >
                                        {{ preparationVisa.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-if="conceptionAllowed()">
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('project.editions.meetingReports.conceptionsToEmit') }}</span>
                                <span class="text-sm" v-if="filteredConceptions.length > 0">
                                    ({{ filteredConceptions.length }})
                                </span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('preparations.newPreparation')"
                                class="mb-1"
                                @click="newPreparation('con')"
                                v-if="isConceptionAllowed()"
                            />
                        </div>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="filteredConceptions.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="filteredConceptions.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparation in filteredConceptions">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 relative">
                                        <div class="w-full flex flex-col">
                                            <div class="flex w-full gap-2">
                                                <a
                                                    class="absolute top-0"
                                                    :id="'uuid_' + preparation.id"
                                                    style="scroll-margin-top: 9em"
                                                ></a>
                                                <span class="">
                                                    {{
                                                        preparation.type
                                                            ? $t('preparations.types.' + preparation.type)
                                                            : ''
                                                    }}
                                                    :
                                                </span>
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followByBundlePreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparation.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(preparation.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === preparation.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    {{ preparation.name.length ? preparation.name : '...' }}
                                                </router-link>
                                                <div v-if="preparation.versions.length > 1">
                                                    <span class="text-red-500 text-xs italic">
                                                        {{ $t('preparations.newVersionTip') }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparation.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparation.isLate,
                                        }"
                                    >
                                        {{ preparation.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-if="conceptionAllowed()">
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <span class="flex gap-2 items-center">
                            <span>{{ $t('project.editions.meetingReports.conceptionsToVisa') }}</span>
                            <span class="text-sm" v-if="conceptionVisas.length > 0">
                                ({{ conceptionVisas.length }})
                            </span>
                        </span>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="conceptionVisas.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparationVisa in filteredConceptionVisas">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div class="flex gap-1 relative">
                                                <a
                                                    :id="'uuid_' + preparationVisa.id"
                                                    style="scroll-margin-top: 9em"
                                                    class="absolute top-0"
                                                ></a>
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followByBundlePreparationVisa',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparationVisa.id,
                                                            preparationVisaId: preparationVisa.visa.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(preparationVisa.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === preparationVisa.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    {{ preparationVisa.name.length ? preparationVisa.name : '...' }}
                                                </router-link>
                                                <app-tips class="flex gap-1" v-if="preparationVisa.bundle">
                                                    {{ preparationVisa.bundle.label }}
                                                </app-tips>
                                            </div>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparationVisa.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparationVisa.isLate,
                                        }"
                                    >
                                        {{ preparationVisa.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template>
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('certificates.title') }}</span>
                                <span class="text-sm" v-if="certificates.length > 0">({{ certificates.length }})</span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('certificates.newCertificate')"
                                class="mb-1"
                                @click="newCertificate()"
                                v-if="isCertificateAllowed()"
                            />
                        </div>
                    </app-separator>
                    <span v-if="certificates.length === 0" class="ml-2 text-xs italic text-gray-700">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="certificate in filteredCertificates">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col relative">
                                            <a
                                                :id="'uuid_' + certificate.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followCertificate',
                                                    params: {
                                                        ...$route.params,
                                                        certificateId: certificate.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(certificate.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === certificate.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                {{ certificate.name }}
                                            </router-link>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        certificate.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': certificate.isLate && !certificate.isEmitted,
                                        }"
                                    >
                                        {{ certificate.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-if="bundle && bundle.category === 'company' && project_EXE">
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('receptions.title') }}</span>
                                <span class="text-sm" v-if="receptions.length > 0">({{ receptions.length }})</span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('receptions.newReception')"
                                class="mb-1"
                                @click="newReception()"
                                v-if="isReceptionAllowed()"
                            />
                        </div>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="receptions.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="receptions.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="reception in filteredReceptions">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 relative">
                                        <div class="w-full flex flex-col">
                                            <a
                                                :id="'uuid_' + reception.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followByBundleReception',
                                                    params: {
                                                        ...$route.params,
                                                        receptionId: reception.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(reception.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === reception.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                {{ reception.name }}
                                            </router-link>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        reception.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': reception.isLate,
                                        }"
                                    >
                                        {{ reception.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('autoControls.title') }}</span>
                                <span class="text-sm" v-if="filteredAutoControls.length > 0">
                                    ({{ filteredAutoControls.length }})
                                </span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('autoControls.newAutoControl')"
                                class="mb-1"
                                @click="newAutoControl()"
                                v-if="isAutoControlAllowed()"
                            />
                        </div>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="filteredAutoControls.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="filteredAutoControls.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="autoControl in filteredAutoControls">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 relative">
                                        <div class="w-full flex flex-col">
                                            <a
                                                :id="'uuid_' + autoControl.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followByBundleAutoControl',
                                                    params: {
                                                        ...$route.params,
                                                        autoControlId: autoControl.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(autoControl.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === autoControl.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                {{ autoControl.name }}
                                            </router-link>
                                            <div class="ml-4">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        autoControl.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': autoControl.isLate && !autoControl.isEmitted,
                                        }"
                                    >
                                        {{ autoControl.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-for="group in filteredObservationsGroups">
                    <template v-if="group.observations.length > 0 || group.type !== 'private'">
                        <app-separator variant="underline" class="w-full my-2 text-base">
                            <div class="flex items-center justify-between w-full">
                                <span class="flex gap-2 items-center">
                                    <span v-if="group.type === 'private'">
                                        <icon-incognito class="text-violet-700" />
                                    </span>
                                    <span>{{ group.label }}</span>
                                    <span class="text-sm" v-if="group.observations.length > 0">
                                        ({{ group.observations.length }})
                                    </span>
                                </span>
                                <app-button
                                    size="mini"
                                    :label="$t('project.follow.observation.newObservation')"
                                    class="mb-1"
                                    @click="newObservation(group.type)"
                                    v-if="isObservationAllowed(group.type)"
                                />
                            </div>
                        </app-separator>
                        <span v-if="group.observations.length === 0" class="ml-2 text-xs italic text-gray-700">
                            {{ $t('commons.emptyList') }}
                        </span>
                        <table class="w-full text-xs border" v-else>
                            <thead class="table-header-group font-bold">
                                <tr class="border">
                                    <td class="p-1" style="width: auto">{{ $t('commons.index') }}</td>
                                    <td class="p-1" style="width: auto">{{ $t('commons.phase') }}</td>
                                    <td class="p-1" style="width: 70%">
                                        {{ $t('project.editions.observationsReports.title') }}
                                    </td>
                                    <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="observation in group.observations">
                                    <tr class="border odd:bg-blue-50">
                                        <td class="p-1 relative">
                                            <a
                                                :id="'uuid_' + observation.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            {{ (observation.index + 1).toString().padStart(3, '0') }}
                                        </td>
                                        <td class="p-1">{{ $t('commons.phases.' + observation.phase) }}</td>
                                        <td class="p-1">
                                            <div class="w-full flex flex-col">
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followByBundleObservation',
                                                        params: {
                                                            ...$route.params,
                                                            observationId: observation.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(observation.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === observation.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    <span
                                                        v-html="observation.title"
                                                        class="whitespace-pre-wrap html-breakline"
                                                    />
                                                    <span v-if="!observation.title || observation.title.length === 0">
                                                        ...
                                                    </span>
                                                </router-link>
                                                <div class="ml-4" v-if="observation.room">
                                                    {{ observation.room.fullName }}
                                                </div>
                                                <div class="ml-4" v-else-if="observation.zone">
                                                    {{ observation.zone.fullName }}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="p-1"
                                            :class="{
                                                'font-bold text-red-500': observation.isLate,
                                            }"
                                        >
                                            <app-date-link
                                                :label="$t('observations.closeDate')"
                                                show-label="onFocus"
                                                :disabled="!isObservationAdmin"
                                                @input="resolveObservation(observation, $event)"
                                                v-model="observation.resolvedAt"
                                                v-if="!observation.resolvedAt"
                                            >
                                                <span :class="{ underline: isObservationAdmin }">
                                                    {{ observation.label }}
                                                </span>
                                            </app-date-link>
                                            <app-date-link
                                                :label="$t('observations.validate')"
                                                show-label="onFocus"
                                                :disabled="!isObservationValidator"
                                                @input="validateObservation(observation, $event)"
                                                v-model="observation.validatedAt"
                                                v-if="observation.resolvedAt && !observation.validatedAt"
                                            >
                                                <span :class="{ underline: isObservationValidator }">
                                                    {{ observation.label }}
                                                </span>
                                            </app-date-link>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </template>
                <template v-if="project_EXE">
                    <app-separator variant="underline" class="w-full my-2 text-base flex gap-1 justify-between">
                        <span class="flex gap-2 items-center">
                            <span>{{ $t('project.editions.meetingReports.taskProgress') }}</span>
                            <span class="text-sm" v-if="taskTree.length > 0">({{ tasks.length }})</span>
                        </span>
                        <div class="flex text-xs gap-1 items-center">
                            {{ $t('commons.from') }}
                            <app-button
                                size="mini"
                                icon="icon-chevron-left"
                                :title="$t('project.follow.byBundle.previousWeeks')"
                                @click="previousWeeks()"
                            ></app-button>
                            <app-date-link v-model="startDate" @input="refresh()" />
                            {{ $t('commons.to') }}
                            <app-date-link v-model="endDate" @input="refresh()" />
                            <app-button
                                size="mini"
                                icon="icon-chevron-right"
                                :title="$t('project.follow.byBundle.nextWeeks')"
                                @click="nextWeeks()"
                            ></app-button>
                        </div>
                    </app-separator>
                    <table class="w-full text-xs border" v-if="taskTree.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border border-black">
                                <td class="p-1">
                                    <span>{{ $t('commons.location') }}</span>
                                </td>
                                <td class="p-1" style="" colspan="2">{{ $t('commons.task') }}</td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.startDates') }}
                                </td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.endDates') }}
                                </td>
                                <td class="p-1 text-center">%</td>
                                <td class="p-1 text-center">{{ $t('project.editions.meetingReports.diff') }} (jo)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="firstLevelNode in taskTree">
                                <template v-for="bundle in firstLevelNode.children">
                                    <template v-for="(task, index) in bundle.children">
                                        <tr
                                            class="border border-black odd:bg-blue-50"
                                            v-if="matchFilter(task.name + ' ' + firstLevelNode.location.fullName)"
                                        >
                                            <td
                                                class="p-1 align-top"
                                                :rowspan="bundle.children.length"
                                                v-if="index === 0"
                                            >
                                                {{ firstLevelNode.location.fullName }}
                                            </td>
                                            <td class="p-1 w-1/2 border border-black">
                                                <div class="flex flex-col">
                                                    <span v-if="task.path && task.path.length > 0">
                                                        {{ task.path }} >
                                                    </span>
                                                    <span class="ml-5">{{ task.service.name }}</span>
                                                </div>
                                            </td>
                                            <td class="p-1">
                                                <div class="flex flex-col">
                                                    <span class="text-right font-bold whitespace-nowrap">P:</span>
                                                    <span class="text-right font-bold whitespace-nowrap">R:</span>
                                                </div>
                                            </td>
                                            <td class="py-1">
                                                <div class="flex flex-col justify-center items-center">
                                                    <app-date-link
                                                        v-if="isTaskAdmin"
                                                        :label="$t('project.follow.planning.plannedStartDate')"
                                                        show-label="onFocus"
                                                        v-model="task.startDate"
                                                        @input="updateTaskStartDate(task, $event)"
                                                    ></app-date-link>
                                                    <app-date-link
                                                        v-if="isTaskAdmin"
                                                        :label="$t('project.follow.planning.realStartDate')"
                                                        show-label="onFocus"
                                                        v-model="task.realStartDate"
                                                        @input="updateTaskRealStartDate(task, $event)"
                                                    ></app-date-link>
                                                </div>
                                            </td>
                                            <td class="py-1">
                                                <div class="flex flex-col items-center">
                                                    <app-date-link
                                                        :label="$t('project.follow.planning.plannedEndDate')"
                                                        v-if="isTaskAdmin"
                                                        show-label="onFocus"
                                                        v-model="task.endDate"
                                                        @input="updateTaskEndDate(task, $event)"
                                                    ></app-date-link>
                                                    <app-date-link
                                                        v-if="isTaskAdmin"
                                                        :label="$t('project.follow.planning.realEndDate')"
                                                        show-label="onFocus"
                                                        class="text-center"
                                                        v-model="task.realEndDate"
                                                        @input="updateTaskRealEndDate(task, $event)"
                                                    ></app-date-link>
                                                </div>
                                            </td>
                                            <td class="py-1 text-right">
                                                <div>{{ task.expectedProgress }}%</div>
                                                <app-number-link
                                                    class="justify-end"
                                                    v-if="isTaskAdmin"
                                                    :default-value="task.progress"
                                                    :label="$t('project.follow.planning.progress')"
                                                    :show-label="false"
                                                    @input="updateTaskProgress(task, $event)"
                                                >
                                                    {{ task.progress || 0 }}%
                                                </app-number-link>
                                            </td>
                                            <td class="py-1 text-center">
                                                <span class="font-bold text-red-700" v-if="task.late > 1">
                                                    J+{{ task.late }}
                                                </span>
                                                <span v-else-if="task.late === 0" class="font-bold text-green-600">
                                                    J
                                                </span>
                                                <span v-else class="font-bold text-green-600">J{{ task.late }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>
            <div v-else>
                <template>
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <span>{{ $t('observations.generalObservations') }}</span>
                                <span class="text-sm">({{ filteredGeneralObservations.length }})</span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('observations.newObservation')"
                                class="mb-1"
                                @click="newObservation('general')"
                                v-if="isObservationAllowed('general')"
                            />
                        </div>
                    </app-separator>
                    <span v-if="filteredGeneralObservations.length === 0" class="ml-2 text-xs italic text-gray-700">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border table-fixed" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <th class="p-1" style="width: 3rem">{{ $t('commons.index') }}</th>
                                <th class="p-1" style="width: auto">
                                    {{ $t('project.editions.observationsReports.title') }}
                                </th>
                                <th class="p-1" style="width: 7rem">
                                    <span class="invisible">{{ $t('observations.resolveDate') + ' :' }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="observation in filteredGeneralObservations">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 align-top relative">
                                        <a
                                            :id="'uuid_' + observation.id"
                                            style="scroll-margin-top: 9em"
                                            class="absolute top-0"
                                        ></a>
                                        {{ (observation.index + 1).toString().padStart(3, '0') }}
                                    </td>
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followByBundleObservation',
                                                    params: {
                                                        ...$route.params,
                                                        observationId: observation.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(observation.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === observation.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                <span
                                                    v-html="observation.title"
                                                    class="whitespace-pre-wrap html-breakline"
                                                />
                                                <span v-if="!observation.title || observation.title.length === 0">
                                                    ...
                                                </span>
                                            </router-link>
                                        </div>
                                    </td>
                                    <td class="p-1">
                                        <div class="flex justify-end pr-2">
                                            <app-date-link
                                                :label="$t('observations.closeDate')"
                                                @input="updateObservation(observation, $event)"
                                                v-model="observation.resolvedAt"
                                                show-label="onFocus"
                                            ></app-date-link>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>
        </template>
        <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
            <form class="flex flex-col gap-4 p-2">
                <fieldset>
                    <legend class="text-gray-700 font-bold mb-2">{{ $t('commons.showHide') }}</legend>
                    <app-checkbox
                        :label="$t('observations.EXEObservations')"
                        v-model="showEXE"
                        @input="updateState"
                        v-if="project_EXE"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.OPRObservations')"
                        v-model="showOPR"
                        v-if="project_OPR"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.ReceiptObservations')"
                        v-model="showReceipt"
                        v-if="project_OPR"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.OPLObservations')"
                        v-model="showOPL"
                        v-if="project_OPL"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.DeliveryObservations')"
                        v-model="showDelivery"
                        v-if="project_OPL"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.APAObservations')"
                        v-model="showAPA"
                        v-if="project_OPL"
                        @input="updateState"
                    ></app-checkbox>
                </fieldset>
                <fieldset>
                    <legend class="text-gray-700 font-bold flex flex-col gap-1">
                        {{ $t('project.planning.sortTaskBy') }}
                    </legend>
                    <app-label>
                        <input type="radio" value="date" name="sort" v-model="sortKey" @change="updateState" />
                        {{ $t('project.planning.sortByDates') }}
                    </app-label>
                    <app-label>
                        <input type="radio" value="name" name="sort" v-model="sortKey" @change="updateState" />
                        {{ $t('project.planning.sortByName') }}
                    </app-label>
                </fieldset>
                <fieldset>
                    <legend class="text-gray-700 font-bold flex flex-col gap-1">
                        {{ $t('observations.filterByEmitter') }}
                    </legend>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="emitters"
                        :options="emitterOptions"
                        :label="$t('project.editions.observationsReports.emitters')"
                        :show-label="false"
                        :placeholder="$t('commons.all')"
                        labelKey="label"
                        @input="updateState"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                </fieldset>
                <app-footer @click="$refs.settings.close()"></app-footer>
            </form>
            <div style="padding-bottom: 40px"></div>
        </app-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </div>
</template>

<script>
import AppList from '@/components/appList/AppList';
import { filterMatch, getMapById, sortBy } from '@/services/sanitize.service';
import AppBundle from '@/components/app-bundle/appBundle';
import AppButton from '@/components/appButton/AppButton';
import AppSelect from '@/components/appSelect/AppSelect';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { getLastDateRange, saveDateRange, updateBreadCrumbs } from '@/state/state';
import { mergeLocationsPathsByFolders } from '@/services/location.service';
import { applyDelay } from '@/services/duration.service';
import { updateTask } from '@/features/tasks/tasks.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import AppInputText from '@/components/appInputText/AppInputText';
import AppFilter from '@/components/appFilter/AppFilter';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { createObservation, getObservations, updateObservation } from '@/features/observations/observation.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppBundlePicker from '@/components/appBundlePicker';
import { bundlesToGroups, getBundleMap, sortBundles } from '@/features/bundles/bundles.service';
import AppLabel from '@/components/appLabel/AppLabel';
import AppMail from '@/components/appMail/AppMail';
import AppPhone from '@/components/appPhone/AppPhone';
import AppTips from '@/components/app-tips/AppTips';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import { getContactMap } from '@/features/contacts/contacts.service';
import { getByBundleData, getByBundlePreparedDate } from '@/features/meetings/byBundle.service';
import { isPendingTask } from '@/features/tasks/plannedTasks.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppFooter from '@/components/appFooter/AppFooter';
import AppPopup from '@/components/app-popup/AppPopup';
import { createPreparation } from '@/features/preparations/preparations.service';
import { createCertificate } from '@/features/certificates/certificates.service';
import { createReception } from '@/features/receptions/receptions.service';
import { createAutoControl } from '@/features/autoControls/autoControls.service';
import IconCircleMedium from '@/icons/IconCircleMedium.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions.vue';

export default {
    components: {
        AppQuickActions,
        AppMultiPicker,
        IconCircleMedium,
        AppPopup,
        AppFooter,
        AppCheckbox,
        AppTips,
        AppPhone,
        AppMail,
        AppLabel,
        AppBundlePicker,
        AppNumberLink,
        AppDropDownButton,
        AppDateInput,
        AppFilter,
        AppInputText,
        AppSeparator,
        AppSelect,
        AppButton,
        AppBundle,
        AppList,
        AppDateLink,
    },
    async created() {
        this.subscriptions = [
            getByBundleData(this.$route.params.projectId).subscribe((byBundleData) => {
                this.byBundleData = byBundleData;
                this.refresh();
            }),
            combineLatest([
                getBundleMap(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getContactMap(this.$route.params.projectId),
                getObservations(this.$route.params.projectId),
            ]).subscribe(([bundleMap, project, companies, contactMap, observations]) => {
                const bundles = sortBundles(Object.values(bundleMap));
                this.emitterOptions = bundles;
                this.allowedFeatures = [...project.projectFeatures, ...project.me.allowedFeatures];
                this.generalObservations = sortBy(
                    observations.filter(
                        (observation) =>
                            observation.type === 'general' && !observation.resolvedAt && !observation.obsoleteAt,
                    ),
                    'createdAt',
                );
                this.isTaskAdmin = project.me.allowedFeatures.includes('project_planning');
                this.isObservationValidator = project.me.allowedFeatures.includes('project_observations_MOEValidation');
                this.isObservationAdmin = project.me.allowedFeatures.includes('project_observations');
                this.me = project.me;
                this.project = {
                    phase: project.phase,
                };
                const companyMap = getMapById(companies);
                const allowedBundles = bundles
                    .filter(
                        (bundle) =>
                            project.me.allowedFeatures.includes('project_followAll') ||
                            project.me.bundleIds.includes(bundle.id),
                    )
                    .map((bundle) => ({
                        ...bundle,
                        company: companyMap[bundle.companyId],
                        responsible: contactMap[bundle.responsibleId],
                    }));
                const bundleGroups = bundlesToGroups(sortBy(allowedBundles, 'code'));
                this.options = this.buildOptions(bundleGroups);
                this.bundles = this.buildBundleList(bundleGroups);
                this.restoreState();
                if (!this.$route.params.bundleId && this.bundles.length > 0) {
                    this.backToLastBundle();
                } else {
                    this.refresh();
                }
                this.loading = false;
            }),
        ];
    },
    computed: {
        project_EXE() {
            return this.allowedFeatures.includes('project_EXE');
        },
        project_OPR() {
            return this.allowedFeatures.includes('project_OPR');
        },
        project_OPL() {
            return this.allowedFeatures.includes('project_OPL');
        },
        filteredPreparations() {
            if (this.filterAsString === '') return this.preparations;
            else return this.preparations.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredConceptions() {
            if (this.filterAsString === '') return this.conceptions;
            else return this.conceptions.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredPreparationVisas() {
            if (this.filterAsString === '') return this.preparationVisas;
            else return this.preparationVisas.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredConceptionVisas() {
            if (this.filterAsString === '') return this.conceptionVisas;
            else return this.conceptionVisas.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredCertificates() {
            if (this.filterAsString === '') return this.certificates;
            else return this.certificates.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredAutoControls() {
            if (this.filterAsString === '') return this.autoControls;
            else return this.autoControls.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredReceptions() {
            if (this.filterAsString === '') return this.receptions;
            else return this.receptions.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredTasks() {
            if (this.filterAsString === '') return this.tasks;
            else return this.tasks.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredObservationsGroups() {
            return this.observationsGroups.map((group) => ({
                ...group,
                observations: group.observations.filter((item) => filterMatch(item.title, this.filterAsString, true)),
            }));
        },
        filteredGeneralObservations() {
            return this.generalObservations.filter((item) => filterMatch(item.title, this.filterAsString, true));
        },
        quickActions() {
            if (!this.bundle) {
                return [];
            }
            return this.bundle.id === 'generals'
                ? [
                      {
                          name: this.$t('project.follow.observation.newObservation'),
                          run: () => this.newObservation('general'),
                      },
                  ]
                : [
                      {
                          name: this.$t('project.follow.byBundle.newOnDoneWorkObservation'),
                          run: () => {
                              this.newObservation('onDoneWork');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newOnTodoWorkObservation'),
                          run: () => {
                              this.newObservation('onTodoWork');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newAdministrativeObservation'),
                          run: () => {
                              this.newObservation('administrative');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newPrivateObservation'),
                          run: () => {
                              this.newObservation('private');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newOtherObservation'),
                          run: () => {
                              this.newObservation('other');
                          },
                      },
                      ...(this.project?.projectFeatures?.includes('project_CON')
                          ? [
                                {
                                    name: this.$t('project.follow.byBundle.newConPreparation'),
                                    run: () => {
                                        this.newPreparation('con');
                                    },
                                },
                            ]
                          : []),
                      ...(this.project?.projectFeatures?.includes('project_EXE')
                          ? [
                                {
                                    name: this.$t('project.follow.byBundle.newExePreparation'),
                                    run: () => {
                                        this.newPreparation('exe');
                                    },
                                },
                            ]
                          : []),
                  ];
        },
    },
    watch: {
        $route: function () {
            this.refresh();
        },
    },
    methods: {
        setLastVisited(id) {
            localStorage.setItem('FollowByBundle.lastVisitedId.' + this.$route.params.projectId, id);
        },
        restoreState() {
            this.backToLastDates();
            const stateStr = localStorage.getItem('byBundleSettings_' + this.$route.params.projectId);
            const state = stateStr
                ? JSON.parse(stateStr)
                : {
                      showEXE: true,
                      showOPR: false,
                      showReceipt: false,
                      showOPL: false,
                      showDelivery: false,
                      showAPA: false,
                      sortKey: 'date',
                      filterAsString: '',
                      emitterIds: [],
                  };
            if (state) {
                this.showEXE = state.showEXE;
                this.showOPR = state.showOPR;
                this.showDelivery = state.showDelivery;
                this.showReceipt = state.showReceipt;
                this.showOPL = state.showOPL;
                this.showAPA = state.showAPA;
                this.sortKey = state.sortKey || 'date';
                this.filterAsString = state.filterAsString;
                this.emitters = (state.emitterIds || [])
                    .map((id) => this.emitterOptions.find((bundle) => bundle.id === id))
                    .filter((a) => !!a);
            }
        },
        updateState() {
            localStorage.setItem(
                'byBundleSettings_' + this.$route.params.projectId,
                JSON.stringify({
                    showEXE: this.showEXE,
                    showOPR: this.showOPR,
                    showReceipt: this.showReceipt,
                    showOPL: this.showOPL,
                    showDelivery: this.showDelivery,
                    showAPA: this.showAPA,
                    sortKey: this.sortKey,
                    filterAsString: this.filterAsString,
                    emitterIds: this.emitters.map((bundle) => bundle.id),
                }),
            );
            this.refresh();
        },
        buildBundleList(bundleGroups) {
            return [
                {
                    id: 'generals',
                },
                ...bundleGroups.MOA,
                ...bundleGroups.AMOA,
                ...bundleGroups.MOE,
                ...bundleGroups.generalContractor,
                ...bundleGroups.distributor,
                ...bundleGroups.company,
            ];
        },
        buildOptions(bundleGroups) {
            return [
                {
                    isGroup: false,
                    name: '- ' + this.$t('observations.generalObservations') + ' -',
                    id: 'generals',
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.MOA'),
                    children: bundleGroups.MOA,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.AMOA'),
                    children: bundleGroups.AMOA,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.MOE'),
                    children: bundleGroups.MOE,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.generalContractor'),
                    children: bundleGroups.generalContractor,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.distributor'),
                    children: bundleGroups.distributor,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.bundlesOnly'),
                    children: bundleGroups.company,
                },
            ];
        },
        saveDates() {
            saveDateRange(this.$route.params.projectId, this.startDate, this.endDate);
        },
        backToLastDates() {
            const cachedDateRange = getLastDateRange(this.$route.params.projectId);
            this.startDate = cachedDateRange.startDate;
            this.endDate = cachedDateRange.endDate;
        },
        isObservationAllowed(type) {
            return (
                (type === 'administrative' && this.allowedFeatures.includes('project_observations_administrative')) ||
                (type === 'general' && this.allowedFeatures.includes('project_observations_general')) ||
                (type === 'other' && this.allowedFeatures.includes('project_observations_other')) ||
                (['onDoneWork', 'onTodoWork'].includes(type) &&
                    this.allowedFeatures.includes('project_observations_work'))
            );
        },
        async newObservation(type) {
            const observation = await createObservation(this.$route.params.projectId, {
                type,
                phase: this.project.phase,
                reportedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
                title: this.$t('project.follow.observation.newObservation'),
                recipientIds: this.bundle.id === 'generals' ? null : [this.bundle.id],
                dueDate: applyDelay(this.now, 7, this.agenda, this.bundle.id),
            });
            this.setLastVisited(observation.id);
            this.$router.push({
                name: 'followByBundleObservation',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        isPreparationAllowed() {
            return this.allowedFeatures.includes('project_preparations');
        },
        async newPreparation(phase) {
            const result = await createPreparation(this.$route.params.projectId, {
                name: this.$t('preparations.newName'),
                deliveryDuration: 5,
                bundleId: this.bundle.id,
                phase,
            });
            this.setLastVisited(result.id);
            await this.$router.push({
                name: phase === 'con' ? 'conception' : 'followPreparation',
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
        isCertificateAllowed() {
            return this.allowedFeatures.includes('project_certificates');
        },
        async newCertificate() {
            const result = await createCertificate(this.$route.params.projectId, {
                name: this.$t('certificates.newName'),
                emitterId: this.bundle.id,
            });
            this.setLastVisited(result.id);
            await this.$router.push({
                name: 'followCertificate',
                params: {
                    projectId: this.$route.params.projectId,
                    certificateId: result.id,
                },
            });
        },

        isReceptionAllowed() {
            return this.allowedFeatures.includes('project_receptions');
        },
        conceptionAllowed() {
            return this.allowedFeatures.includes('project_CON');
        },
        isConceptionAllowed() {
            return this.allowedFeatures.includes('project_conceptions');
        },
        async newReception() {
            const result = await createReception(this.$route.params.projectId, {
                name: this.$t('receptions.newName'),
                bundleId: this.bundle.id,
            });
            this.setLastVisited(result.id);
            await this.$router.push({
                name: 'followReception',
                params: {
                    projectId: this.$route.params.projectId,
                    receptionId: result.id,
                },
            });
        },
        isAutoControlAllowed() {
            return this.allowedFeatures.includes('project_autoControls');
        },
        async newAutoControl() {
            const result = await createAutoControl(this.$route.params.projectId, {
                name: this.$t('autoControls.newName'),
                bundleId: this.bundle.id,
            });
            this.setLastVisited(result.id);
            await this.$router.push({
                name: 'followAutoControl',
                params: {
                    projectId: this.$route.params.projectId,
                    autoControlId: result.id,
                },
            });
        },
        updateTaskStartDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, startDate: date });
        },
        updateTaskEndDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, endDate: date });
        },
        updateTaskRealStartDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, realStartDate: date });
        },
        updateTaskProgress(task, progress) {
            if ((progress > 0 && progress < 100 && task.realStartDate) || progress === 0) {
                updateTask(this.$route.params.projectId, { id: task.id, progress });
            } else if (progress > 0 && progress < 100 && !task.realStartDate) {
                updateTask(this.$route.params.projectId, { id: task.id, progress, realStartDate: this.now });
            } else if (progress >= 100 && !task.realEndDate) {
                updateTask(this.$route.params.projectId, {
                    id: task.id,
                    progress: 100,
                    realEndDate: this.now,
                });
            } else if (progress >= 100) {
                updateTask(this.$route.params.projectId, { id: task.id, progress: 100 });
            }
        },
        updateTaskRealEndDate(task, date) {
            if (!task.realEndDate) {
                updateTask(this.$route.params.projectId, {
                    id: task.id,
                    realEndDate: null,
                    ...(task.progress === 100 ? { progress: 0 } : {}),
                });
            } else {
                updateTask(this.$route.params.projectId, { id: task.id, realEndDate: date, progress: 100 });
            }
        },
        resolveObservation(observation, $event) {
            return updateObservation(this.$route.params.projectId, {
                id: observation.id,
                resolvedAt: $event,
                resolvedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
            });
        },
        validateObservation(observation, $event) {
            return updateObservation(this.$route.params.projectId, {
                id: observation.id,
                validatedAt: $event,
                validatedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
            });
        },
        backToLastBundle() {
            if (this.bundles && this.bundles.length > 0) {
                const cache = localStorage.getItem('FollowByBundle.lastBundleId.' + this.$route.params.projectId);
                if (cache && this.bundles.find((bundle) => bundle.id === cache)) {
                    return this.goTo(cache, true);
                } else {
                    return this.goTo(this.bundles[0].id, true);
                }
            }
        },
        scrollToLastVisited() {
            this.lastVisitedId = localStorage.getItem('FollowByBundle.lastVisitedId.' + this.$route.params.projectId);
            if (this.lastVisitedId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + this.lastVisitedId);
                    if (element) element.scrollIntoView();
                    this.pulse = true;
                    setTimeout(() => (this.pulse = false), 5000);
                });
            }
        },
        async refresh() {
            if (this.byBundleData.length === 0) {
                return;
            }
            this.bundle = this.bundles.find((bundle) => bundle.id === this.$route.params.bundleId);
            if (!this.bundle && this.$route.params.bundleId !== 'generals') {
                return this.backToLastBundle();
            } else if (this.$route.params.bundleId === 'generals') {
                this.bundleIndex = 0;
                this.bundle = { id: 'generals' };
                updateBreadCrumbs({ bundleName: this.$t('observations.generalObservations') });
                localStorage.setItem('FollowByBundle.lastBundleId.' + this.$route.params.projectId, 'generals');
            } else {
                this.bundleIndex = this.bundles.indexOf(this.bundle);
                updateBreadCrumbs({ bundleName: this.bundle.reference + ' ' + this.bundle.name });
                localStorage.setItem('FollowByBundle.lastBundleId.' + this.$route.params.projectId, this.bundle.id);
            }

            this.saveDates();
            const data = getByBundlePreparedDate(
                (key) => this.$t(key),
                null,
                this.bundle.id,
                this.startDate,
                this.endDate,
                this.byBundleData,
                true,
                true,
                this.conceptionAllowed(),
                this.conceptionAllowed(),
                this.showEXE,
                this.showOPR,
                this.showReceipt,
                this.showOPL,
                this.showDelivery,
                this.showAPA,
                true,
                true,
                true,
                true,
                this.sortKey,
                this.emitters.map((bundle) => bundle.id),
            );
            this.meeting = data.meeting;
            this.attendanceStats = data.attendanceStats;
            this.hasItems = data.hasItems;
            this.taskTree = data.taskTree;
            this.tasks = data.tasks.filter(
                (task) => task.service.bundleId === this.bundle.id && isPendingTask(task, this.startDate, this.endDate),
            );
            this.observationsGroups = data.observationsGroups;
            this.certificates = data.certificates;
            this.autoControls = data.autoControls;
            this.receptions = data.receptions;
            this.preparationVisas = data.preparationVisas;
            this.conceptionVisas = data.conceptionVisas;
            this.preparations = data.preparations;
            this.conceptions = data.conceptions;

            this.scrollToLastVisited();
        },
        goTo(bundleId, replace = false) {
            const destination = {
                name: 'followByBundle',
                params: {
                    projectId: this.$route.params.projectId,
                    bundleId,
                },
            };
            if (replace) {
                this.$router.replace(destination);
            } else {
                this.$router.push(destination);
            }
        },
        nextBundle() {
            this.goTo(this.bundles[this.bundleIndex + 1].id);
        },
        previousBundle() {
            this.goTo(this.bundles[this.bundleIndex - 1].id);
        },
        nextWeeks() {
            this.startDate = addDays(this.startDate, 7);
            this.endDate = addDays(this.endDate, 7);
            this.refresh();
        },
        previousWeeks() {
            this.startDate = subDays(this.startDate, 7);
            this.endDate = subDays(this.endDate, 7);
            this.refresh();
        },
        mergeLocationsPathsByFolders,
        matchFilter(string) {
            return filterMatch(string, this.filterAsString, true);
        },
    },
    data() {
        return {
            lastVisitedId: null,
            pulse: false,
            loading: true,
            folders: [],
            emitters: [],
            emitterOptions: [],
            subscriptions: [],
            observations: [],
            now: new Date(),
            isTaskAdmin: false,
            allowedFeatures: [],
            startDate: subDays(startOfISOWeek(new Date()), 7),
            endDate: addDays(startOfISOWeek(new Date()), 13),
            options: [],
            bundles: [],
            bundle: {},
            bundleIndex: 0,
            agenda: [],
            project: { folders: [] },
            locations: [],
            meeting: {},
            tasks: [],
            byBundleData: [],
            observationsGroups: [],
            taskTree: [],
            preparations: [],
            conceptions: [],
            receptions: [],
            autoControls: [],
            certificates: [],
            preparationVisas: [],
            conceptionVisas: [],
            administrativeObservations: [],
            onToDoWorkObservations: [],
            otherObservations: [],
            onDoneWorkObservations: [],
            generalObservations: [],
            filterAsString: '',
            isObservationValidator: false,
            isObservationAdmin: false,
            me: null,
            showEXE: true,
            showOPR: true,
            showReceipt: true,
            showOPL: true,
            showDelivery: true,
            showAPA: true,
            sortKey: 'date',
            attendanceStats: { present: 0, excusedAbsent: 0, unexcusedAbsent: 0 },
        };
    },
};
</script>
