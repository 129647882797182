var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex flex-col"},[(!_vm.readOnly)?_c('div',{staticClass:"m-2 flex items-center gap-4"},[_c('app-button',{attrs:{"disabled":_vm.disableGeneratePDF,"icon-class":_vm.disableGeneratePDF ? 'animate animate-spin' : '',"label":_vm.$t('project.editions.observationsReports.generateFile')},on:{"click":_vm.generatePDF}}),(_vm.showGenerateAPDFByBundleOption)?_c('app-checkbox',{staticClass:"text-xs",attrs:{"disabled":_vm.disableGeneratePDF,"label":_vm.$t('project.editions.observationsReports.generateAPDFByBundle')},on:{"input":_vm.save},model:{value:(_vm.observationsReport.generateAPDFByBundle),callback:function ($$v) {_vm.$set(_vm.observationsReport, "generateAPDFByBundle", $$v)},expression:"observationsReport.generateAPDFByBundle"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"flex flex-col gap-2 m-2"},[(_vm.pendingSync)?_c('div',{staticClass:"flex"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"}),_vm._v("\n                "+_vm._s(_vm.$t('project.editions.observationsReports.pendingProjectSync'))+"\n            ")],1):[(
                        _vm.observationsReport.files &&
                        _vm.observationsReport.files.length > 1 &&
                        _vm.observationsReport.files.every((file) => file.generationEndDate && file.generationSucceed)
                    )?_c('app-file-link',{staticClass:"underline my-3",attrs:{"icon":"icon-archive-arrow-down","file-name":_vm.$t('project.editions.observationsReports.zipFile'),"url":`/api/observationsReports/${_vm.$route.params.projectId}/${_vm.$route.params.observationsReportId}/file.pdf`}}):_vm._e(),_vm._l((_vm.observationsReport.files),function(file){return [_c('div',{staticClass:"grid grid-cols-3"},[(file.generationEndDate && file.generationSucceed)?_c('app-file-link',{staticClass:"underline ml-2 col-span-2",attrs:{"file-name":file.name,"url":file.type
                                    ? `/api/projects/${_vm.$route.params.projectId}/observationsReports/${
                                          _vm.observationsReport.id
                                      }/${file.bundleId || 'zones'}/report.pdf`
                                    : file.url}}):_vm._e(),(file.generationEndDate && file.generationSucceed && !_vm.readOnly)?_c('div',{staticClass:"flex"},[_c('app-button',{attrs:{"icon":"icon-email-edit-outline","size":"mini"},on:{"click":function($event){_vm.$refs.emailPopup.open(
                                        _vm.observationsReport,
                                        file,
                                        file.type === 'zones'
                                            ? _vm.uniq(
                                                  _vm.observations.reduce(
                                                      (acc, observation) => [...acc, ...observation.recipientIds],
                                                      [],
                                                  ),
                                              )
                                            : [file.bundleId],
                                    )}}})],1):_vm._e(),(!file.generationEndDate)?_c('div',{staticClass:"flex col-span-2"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"}),_vm._v("\n                            "+_vm._s(file.name)+"\n                        ")],1):_vm._e()],1),(file.generationEndDate && !file.generationSucceed)?_c('div',{staticClass:"flex text-red-500 items-center gap-2 col-span-2"},[_c('icon-alert-circle',{attrs:{"width":"16"}}),_vm._v("\n                        "+_vm._s(file.name)+"\n                        "),_c('span',{staticClass:"text-xs italic"},[_vm._v("\n                            "+_vm._s(_vm.$t('project.editions.observationsReports.generationFailed'))+"\n                        ")])],1):_vm._e()]})]],2)]),_c('observations-report-mail-popup',{ref:"emailPopup"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }