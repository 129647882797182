export const safeHTMLConfig = {
    allowedTags: ['table', 'tr', 'td', 'span'],
    allowedAttributes: {},
};
export function importFromHTML(html) {
    const rows = splitRows(html);
    const table = rows.map(splitColumns);
    return toServiceNames(table);
}
export function toServiceNames(table) {
    return table.map((row) => removeSpans(row[0]));
}
export function removeSpans(cell) {
    return cell.replace(/<span>/g, '').trim();
}
export function splitRows(data) {
    return data
        .split('<tr>')
        .map((line) => line.replace(/(<\/tr>|<\/?table>)/g, '').trim())
        .filter((a) => a.length > 0);
}
export function splitColumns(row) {
    return row
        .split('<td>')
        .map((line) => line.replace(/(<\/td>|<\/?span>)/g, '').trim())
        .filter((a) => a.length > 0);
}
