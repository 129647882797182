import safeHtml from 'safe-html';
export const safeHTMLConfig = {
    allowedTags: ['table', 'tr', 'td', 'span'],
    allowedAttributes: {},
};

export function importFromPaste(event) {
    let htmlType;
    let plainType;
    event.clipboardData.types.forEach(function (type) {
        if (type.match('^text/html')) {
            htmlType = type;
        }
        if (type.match('^text/plain')) {
            plainType = type;
        }
    });
    event.preventDefault();
    if (htmlType) {
        const data = event.clipboardData.getData(htmlType);
        let rows = null;
        try {
            rows = importFromHTML(data);
        } catch (e) {
            //nop
        }
        return { isHTML: true, type: htmlType, data: data, rows };
    }
    if (plainType) {
        const data = event.clipboardData.getData(plainType);
        let rows = null;
        try {
            rows = importFromText(data);
        } catch (e) {
            //nop
        }
        return { isHTML: false, type: plainType, data, rows };
    }
}

export function importFromHTML(html) {
    const cleanHTML = safeHtml(html, safeHTMLConfig).trim();
    const rows = splitRows(cleanHTML);
    return rows.map(splitColumns).filter((rows) => rows.join('').length > 0);
}
export function importFromText(text) {
    const rows = text.split('\n').filter((line) => line.trim().length > 0);
    return rows
        .map((row) =>
            row.split(';').map((cell) => {
                let result = cell.trim();
                if (result.startsWith('"') && result.endsWith('"')) {
                    result = result.substring(1, result.length - 1);
                }
                return result;
            }),
        )
        .filter((rows) => rows.join('').length > 0);
}
function splitRows(data) {
    return data
        .split('<tr>')
        .map((line) => line.replace(/(<\/tr>|<\/?table>)/g, '').trim())
        .filter((a) => a.length > 0);
}
function splitColumns(row) {
    return row.split('<td>').map((line) => line.replace(/(<\/td>|<\/?span>)/g, '').trim());
}
