<template>
    <div
        :class="{
            'pl-2': items.length >= 10 && items.length < 100,
            'pl-4': items.length < 10,
        }"
    >
        {{ items.length }}
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
    },
};
</script>
