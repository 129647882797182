export function reduceContent(htmlContent, numberOfLines) {
    if (htmlContent.startsWith('<p>')) {
        let content = htmlContent.replace(/<img[^>]*>/gi, '').trim();
        if (numberOfLines === 2) {
            const firstLine = content.substring('<p>'.length, content.indexOf('</p>')).trim();
            const rest = content.substring(content.indexOf('</p>') + '</p>'.length).trim();
            const secondLine = rest.substring('<p>'.length, rest.indexOf('</p>')).trim();
            if (secondLine.length > 0 && firstLine.length > 0) {
                content = `<p>${firstLine}</p><p>${secondLine}...</p>`;
            } else if (secondLine.length === 0 && firstLine.length > 0) {
                content = `<p>${firstLine}...</p>`;
            } else if (secondLine.length > 0 && firstLine.length === 0) {
                content = `<p>${secondLine}...</p>`;
            } else {
                content = '<p>...</p>';
            }
        } else {
            content = content.substring('<p>'.length, content.indexOf('</p>')) + '...';
        }
        return content;
    } else {
        return htmlContent.trim();
    }
}
