<template>
    <div class="flex flex-col w-full max-h-main">
        <app-shapes-toolbar
            ref="toolbar"
            @delete="deleteShape"
            @draw="drawShape"
            @pickColor="changeColor"
            :selectedShape="selectedShape"
            :color="color"
            v-if="!readOnly"
        />
        <div class="flex flex-grow overflow-hidden">
            <div class="max-h-full flex-grow max-w-full min-w-[200px] min-h-[80vh]">
                <app-leaflet-viewer
                    v-if="attachment.url || attachment.dataUrl"
                    :src="attachment.url || attachment.dataUrl"
                    ref="leaflet"
                    :page="1"
                    :shapes="attachment.shapes"
                    class=""
                    :allow-shape-edit="!readOnly"
                    :selectedShape="selectedShape"
                    @updateShape="updateShape"
                    @stopDrawing="onStopDrawing"
                    @createShape="createShape"
                    @selectShape="onSelectShape"
                    :color="color"
                    @pickPosition="onPickPosition"
                    @markerMoved="updateShape"
                    :longPressToPick="false"
                ></app-leaflet-viewer>
            </div>
        </div>
    </div>
</template>

<script>
import { getMenuState } from '@/state/state';
import AppShapesToolbar from '@/components/appPdfViewer/AppShapesToolbar';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import { v4 as uuidv4 } from 'uuid';
import { updateObservationAttachments } from '@/features/observations/observationAttachments.service';

export default {
    components: { AppLeafletViewer, AppShapesToolbar },

    async created() {
        await getMenuState(async function (menuState) {
            this.isPortrait = menuState.isPortrait;
        });
    },
    mounted() {
        window.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp);
    },
    methods: {
        drawShape(type) {
            if (!type) {
                this.selectedShape = null;
                this.$refs.leaflet.selectShape(null);
                this.$refs.leaflet.stopDrawing();
            }
            this.markerPickingEnabled = null;
            if (type === 'arrow') {
                this.$refs.leaflet.drawArrow();
            } else if (type === 'doubleArrow') {
                this.$refs.leaflet.drawDoubleArrow();
            } else if (type === 'rectangle') {
                this.$refs.leaflet.drawRectangle();
            } else if (type === 'segment') {
                this.$refs.leaflet.drawSegment();
            } else if (type === 'polyline') {
                this.$refs.leaflet.drawPolyline();
            } else if (type === 'polygon') {
                this.$refs.leaflet.drawPolygon();
            } else if (type === 'marker') {
                this.markerPickingEnabled = 'marker';
            } else if (type === 'filledMarker') {
                this.markerPickingEnabled = 'filledMarker';
            }
        },
        changeColor(color) {
            this.color = color;
            if (this.selectedShape) {
                this.selectedShape.style.color = color;
                this.updateShape({
                    id: this.selectedShape.id,
                    type: this.selectedShape.type,
                    style: { ...this.selectedShape.style, color },
                });
            }
        },
        onSelectShape(shape) {
            this.selectedShape = shape;
            this.color = shape.style.color;
        },
        onPickPosition({ x, y }) {
            console.log(x, y);
            if (this.markerPickingEnabled) {
                this.createShape({
                    type: this.markerPickingEnabled,
                    x,
                    y,
                    style: { color: this.color },
                    label: this.markerPickingEnabled === 'marker' ? this.getNextMarkerId() : '',
                });
            } else {
                this.selectedShape = null;
            }
        },
        getNextMarkerId() {
            const maxValue = this.attachment.shapes
                .filter((shape) => shape.type === 'marker')
                .map((shape) => parseInt(shape.label))
                .reduce((acc, value) => (acc > value ? acc : value), 0);
            return maxValue + 1;
        },
        onStopDrawing() {
            this.$refs.toolbar.cancelDraw();
        },
        onKeyUp(e) {
            if (
                this.selectedShape &&
                (e.target.nodeName === 'BODY' ||
                    e.target.className.includes('leaflet-container') ||
                    e.target.className.includes('leaflet-marker-icon')) &&
                (e.key === 'Backspace' || e.key === 'Delete')
            ) {
                this.deleteShape();
            }
        },
        async deleteShape() {
            await updateObservationAttachments(this.$route.params.projectId, {
                id: this.attachment.id,
                shapes: this.attachment.shapes.filter((aShape) => aShape.id !== this.selectedShape.id),
            });
            this.selectedShape = null;
            this.$refs.leaflet.selectShape(null);
        },
        async createShape(shape) {
            const newShape = {
                id: uuidv4(),
                ...shape,
            };
            await updateObservationAttachments(this.$route.params.projectId, {
                id: this.attachment.id,
                shapes: [...this.attachment.shapes, newShape],
            });
            this.selectedShape = newShape;
            return newShape;
        },
        updateShape(shape) {
            return updateObservationAttachments(this.$route.params.projectId, {
                id: this.attachment.id,
                shapes: this.attachment.shapes.map((aShape) =>
                    aShape.id === shape.id ? { ...aShape, ...shape } : aShape,
                ),
            });
        },
    },
    watch: {
        attachment() {
            if (this.selectedShape) {
                this.$nextTick(() => this.$refs.leaflet.selectShape(this.selectedShape));
            }
        },
    },
    props: {
        attachment: {
            type: Object,
            default: () => ({
                shapes: [],
            }),
        },
        readOnly: { type: Boolean, default: true },
    },
    data() {
        return {
            selectedShape: null,
            isPortrait: true,
            markerPickingEnabled: false,
            color: '#ff0000',
        };
    },
};
</script>
