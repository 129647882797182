import { getAPIHeaders } from '@/services/api.service';

export async function getLicensesOrders(projectId) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders`, {
        headers: getAPIHeaders(),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}

export async function createLicensesOrder(projectId, body) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders`, {
        method: 'POST',
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}
export async function removeLicensesOrder(projectId, licenseOrderId) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders/${licenseOrderId}`, {
        method: 'DELETE',
        headers: getAPIHeaders(),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    }
}

export async function updateLicensesOrder(projectId, licensesOrderId, billingDate, billingReference) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders/${licensesOrderId}`, {
        method: 'PUT',
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        body: JSON.stringify({ billingDate, billingReference }),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}
export async function updateLicensesOrderDiscount(projectId, licensesOrderId, discountAmount, discountLabel) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders/${licensesOrderId}/discount`, {
        method: 'PUT',
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        body: JSON.stringify({ discountAmount, discountLabel }),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}
export async function updateLicensesOrderValidation(projectId, licensesOrderId) {
    const response = await fetch(`/api/projects/${projectId}/licensesOrders/${licensesOrderId}`, {
        method: 'PUT',
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        body: JSON.stringify({ validatedOn: new Date() }),
    });
    if (!response.ok) {
        throw new Error(await response.text());
    } else {
        return response.json();
    }
}
