<template>
    <app-label
        :show-label="showLabel === true || (showLabel === 'onFocus' && hasFocus)"
        :label="label"
        class="flex gap-1"
        :class="{ 'cursor-pointer': !disabled, 'flex-col': showLabel === 'onFocus' }"
    >
        <slot v-if="!hasFocus">
            <div class="flex" :class="{ underline: !disabled }">
                <span v-if="value">{{ value | humanizeHour }}</span>
                <span v-else>...</span>
            </div>
        </slot>
        <input
            :style="{ opacity }"
            :class="{ absolute: !opacity }"
            class="bg-inherit"
            style="left: -1000px"
            type="time"
            v-model="internalValue"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="validate"
            @keyup.esc="discard"
            ref="input"
            :disabled.prop="disabled"
        />
    </app-label>
</template>
<script>
import AppLabel from '@/components/appLabel/AppLabel';
import format from 'date-fns/format';

export default {
    components: { AppLabel },
    data() {
        return {
            opacity: 0,
            preventBlur: false,
            hasFocus: false,
            internalValue: false,
        };
    },
    watch: {
        value(newValue) {
            this.internalValue = this.valueToInternal(newValue);
        },
    },
    created() {
        this.internalValue = this.valueToInternal(this.value);
    },
    methods: {
        valueToInternal(value) {
            if (value) {
                return format(value, 'HH:mm');
            } else {
                return null;
            }
        },
        discard() {
            this.preventBlur = true;
            this.internalValue = this.valueToInternal(this.value);
            this.reset();
        },
        focus() {
            this.$refs.input.focus();
        },
        validate(event) {
            event.preventDefault();
            this.preventBlur = true;
            this.reset();
            this.save(this.$refs.input.value);
            this.$emit('enter');
        },
        reset() {
            this.opacity = 0;
            this.internalValue = this.value;
            this.$refs.input.blur();
        },
        save(value) {
            if (value) {
                const result = new Date(this.value);
                result.setHours(value.substring(0, 2), value.substring(3, 5));
                this.$emit('input', result);
            } else if (!this.required) {
                this.$emit('input', null);
            }
        },
        onFocus() {
            if (this.disabled) {
                return;
            }
            this.hasFocus = true;
            if (!this.internalValue && this.defaultDate) {
                this.internalValue = this.valueToInternal(this.defaultDate);
            }
            this.opacity = 1;
            this.preventBlur = false;
        },
        onBlur($event) {
            this.hasFocus = false;
            if (!this.preventBlur) {
                this.reset();
                this.save($event.target.value);
            }
        },
    },
    props: {
        label: String,
        defaultDate: Date,
        disabled: Boolean,
        required: Boolean,
        value: Date,
        showLabel: { type: String | Boolean, default: true },
    },
};
</script>
