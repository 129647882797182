import { getAPIHeaders } from '@/services/api.service';

function mapSepa(item) {
    return item
        ? {
              ...item,
              validatedAt: item.validatedAt ? new Date(item.validatedAt) : null,
              createdAt: item.createdAt ? new Date(item.createdAt) : null,
          }
        : null;
}

export async function removeSepa(contractId, sepa) {
    const response = await fetch(
        new Request(`/api/contracts/${contractId}/sepas/${sepa.id}`, {
            method: 'DELETE',
            headers: getAPIHeaders(),
        }),
    );
    return response.json();
}

export async function createSepa(contractId, sepa) {
    const response = await fetch(
        new Request(`/api/contracts/${contractId}/sepas`, {
            method: 'POST',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(sepa),
        }),
    );
    return response.json();
}

export async function updateSepa(contractId, sepa) {
    const response = await fetch(
        new Request(`/api/contracts/${contractId}/sepas/${sepa.id}`, {
            method: 'PUT',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(sepa),
        }),
    );
    return mapSepa(await response.json());
}
export default {
    removeSepa,
    createSepa,
    updateSepa,
};
