<template>
    <div :title="label">
        <icon-help-circle-outline width="16" height="16" class="text-gray-500" />
    </div>
</template>
<script>
import IconHelpCircleOutline from '@/icons/IconHelpCircleOutline';
export default {
    components: { IconHelpCircleOutline },
    props: ['label'],
};
</script>
