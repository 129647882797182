<template>
    <table class="w-full table-fixed text-xs mt-4">
        <thead class="table-header-group">
            <tr class="">
                <th class="p-1 ml-2 border-r text-left" style="width: auto">
                    {{ $t('commons.name') }}
                </th>
                <th class="p-1 border-r text-center" style="width: 3rem">
                    {{ $t('commons.versionShort') }}
                </th>
                <th class="p-1 border-r text-center" style="width: auto">
                    {{ $t('bundles.emitter') }}
                </th>
                <th class="p-1 border-r text-center mx-1" style="width: 6rem">
                    {{ $t('commons.deadline') }}
                </th>
            </tr>
        </thead>
        <template v-for="visa in visas">
            <tr style="page-break-inside: avoid" class="odd:bg-blue-50 border-t border-black">
                <td class="border-l">
                    <div>
                        <div class="mr-1 inline-block" v-if="visa.preparation.code">#{{ visa.preparation.code }}</div>
                        <div class="inline-block break-all">
                            {{ visa.preparation.name }}
                        </div>
                    </div>
                </td>
                <td class="border-l text-center">
                    <span class="mx-1">v{{ visa.preparation.index }}</span>
                </td>
                <td class="border-l">
                    <div class="p-1">
                        <app-bundle :bundle="visa.preparation.bundle" />
                    </div>
                </td>
                <td class="border-l">
                    <div
                        v-if="visa.emissionDueDate"
                        :class="{ 'font-bold text-red-700': visa.late > 0 }"
                        class="flex items-center justify-center"
                    >
                        {{ visa.emissionDueDate | humanizeDate }}
                    </div>
                </td>
            </tr>
        </template>
    </table>
</template>

<script>
import AppBundle from '@/components/app-bundle/appBundle';
export default {
    components: { AppBundle },
    props: ['visas', 'bundle', 'agenda'],
};
</script>
