import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapMailTemplates(item) {
    return item ? item.toMutableJSON() : null;
}

export function getMailTemplates(projectId) {
    return getCachedObservable('getMailTemplate_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .mailTemplates.find()
            .$.pipe(map((item) => item.map(mapMailTemplates))),
    );
}
export function removeMailTemplate(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .mailTemplates.findOne({ selector: { id: id } })
        .remove();
}

export async function createMailTemplate(projectId, item) {
    const result = await db
        .getProjectCollections(projectId)
        .mailTemplates.insert({ ...item, isCustom: true, projectId });
    return result.toJSON();
}

export async function updateMailTemplate(projectId, mailTemplate) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .mailTemplates.findOne({ selector: { id: mailTemplate.id } })
        .exec();
    return dbAutoControl.atomicPatch(mailTemplate);
}

export function getMailTemplate(projectId, mailTemplateId) {
    return db
        .getProjectCollections(projectId)
        .mailTemplates.findOne({ selector: { id: mailTemplateId } })
        .$.pipe(map(mapMailTemplates));
}
