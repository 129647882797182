import { sortBy } from '@/services/sanitize.service';

export function getChildrenFromParentId(node, tasks, result) {
    node.children = sortBy(
        tasks.filter((task) => task.parentId === node.id),
        'name',
    );
    for (const child of node.children) {
        child.level = node.level + 1;
        child.editable = true;
        result.push(child);
        getChildrenFromParentId(child, tasks, result);
    }
}

export function toTaskFree(tasks) {
    const result = [];
    const rootTasks = tasks.filter((task) => task.parentId === null);
    for (const node of rootTasks) {
        node.level = 1;
        node.editable = true;
        result.push(node);
        getChildrenFromParentId(node, tasks, result);
    }
    return result;
}

export default {
    toTaskFree,
};
