<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <ReceptionForm ref="form" v-if="isAdmin" />
        <read-only-reception v-else />
        <app-separator :label="$t('observations.title')" class="mt-4"></app-separator>
        <div class="flex flex-col gap-2 w-full p-5">
            <div class="flex">
                <router-link
                    v-if="reception"
                    class="text-sm flex font-bold hover:border-black border-gray-300 items-center disabled:opacity-50 focus:outline-none focus:ring py-2 px-4 border"
                    :to="{
                        name: 'followReceptionObservations',
                        params: {
                            projectId: this.$route.params.projectId,
                            receptionId: reception.id,
                        },
                    }"
                >
                    {{ $t('receptions.consultObservations') }}
                </router-link>
            </div>
            <FollowObservationStats class="w-full" :items="observations" :bundles="bundles" />
        </div>
        <app-separator class="col-span-2 mt-4" :label="$t('receptions.report')" v-if="observationsReport" />
        <observations-report-files
            v-if="observationsReport"
            :observationsReport="observationsReport"
            :readOnly="!isAdmin && !isEmitter"
            :observations="observations"
            :showGenerateAPDFByBundleOption="false"
            @generatePDF="onGeneratePDF"
        ></observations-report-files>
        <app-quick-actions :options="quickActions" @choose="$event.run()" v-if="isAdmin"></app-quick-actions>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import ReceptionForm from './ReceptionForm';
import { createReception, getReception, updateReception } from './receptions.service';
import ReadOnlyReception from '@/features/receptions/ReadOnlyReception';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import FollowObservationStats from '@/features/follow/FollowObservationStats.vue';
import ObservationsReportFiles from '@/features/observationsReports/ObservationsReportFiles.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { getObservationsForRelatedContent } from '@/features/observations/observation.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getObservationsReports } from '@/features/observationsReports/observationsReports.service';
import { uniq } from '@/services/sanitize.service';
export default {
    components: {
        AppSeparator,
        ObservationsReportFiles,
        FollowObservationStats,
        ReadOnlyReception,
        ReceptionForm,
        AppQuickActions,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        onGeneratePDF() {
            if (!this.reception.emissionDate) {
                updateReception(this.$route.params.projectId, { id: this.reception.id, emissionDate: new Date() });
            }
        },
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getReception(this.$route.params.projectId, this.$route.params.receptionId),
                    getObservationsForRelatedContent(this.$route.params.projectId, this.$route.params.receptionId),
                    getBundleMap(this.$route.params.projectId),
                    getObservationsReports(this.$route.params.projectId, this.$route.params.receptionId),
                ]).subscribe(([project, reception, observations, bundleMap, observationsReports]) => {
                    this.observations = observations;
                    this.reception = reception;
                    this.isAdmin = !project.projectFeatures.includes('project_receptions');
                    this.isEmitter = project.me.bundleIds.includes(reception.bundleId);
                    this.bundles = uniq(
                        observations.reduce((acc, observation) => [...acc, ...observation.recipientIds], []),
                    )
                        .map((id) => bundleMap[id])
                        .filter((a) => !!a);
                    this.observationsReport =
                        observationsReports.length > 0
                            ? {
                                  ...observationsReports[0],
                                  name:
                                      this.reception.name !== this.$t('receptions.newName')
                                          ? this.$t('receptions.newName') + ' ' + this.reception.name
                                          : this.reception.name,
                                  date: this.reception.emissionDate ? this.reception.emissionDate : new Date(),
                              }
                            : null;
                    updateBreadCrumbs({ receptionName: reception.name });
                }),
            ];
        },
    },
    data() {
        return {
            observationsReport: null,
            subscriptions: [],
            observations: [],
            bundles: [],
            reception: null,
            isAdmin: false,
            isEmitter: false,
            quickActions: [
                {
                    name: this.$t('receptions.newReception'),
                    run: async () => {
                        const result = await createReception(this.$route.params.projectId, {
                            name: this.$t('receptions.newReception'),
                        });
                        this.$router.push({
                            name: 'followReception',
                            params: {
                                projectId: this.$route.params.projectId,
                                receptionId: result.id,
                            },
                        });
                    },
                },
                {
                    name: this.$t('receptions.copy'),
                    run: () => this.$refs.form.duplicate(),
                },
                {
                    name: this.$t('observations.newObservation'),
                    run: () => this.$refs.observations.onNewObservation(),
                },
            ],
        };
    },
};
</script>
