var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"max-w-5xl m-5 mb-10 w-full"},[(_vm.isAdmin)?_c('AutoControlForm',{ref:"form"}):_c('read-only-auto-control'),_c('app-separator',{staticClass:"mt-4",attrs:{"label":_vm.$t('observations.title')}}),_c('div',{staticClass:"flex flex-col gap-2 w-full p-5"},[_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"text-sm flex font-bold hover:border-black border-gray-300 items-center disabled:opacity-50 focus:outline-none focus:ring py-2 px-4 border",attrs:{"to":{
                    name: 'followAutoControlObservations',
                    params: {
                        projectId: this.$route.params.projectId,
                        autoControlId: this.$route.params.autoControlId,
                    },
                }}},[_vm._v("\n                "+_vm._s(_vm.$t('autoControls.consultObservations'))+"\n            ")])],1),_c('FollowObservationStats',{staticClass:"w-full",attrs:{"items":_vm.observations,"bundles":_vm.bundles}})],1),(_vm.observationsReport)?_c('app-separator',{staticClass:"col-span-2 mt-4",attrs:{"label":_vm.$t('autoControls.report')}}):_vm._e(),(_vm.observationsReport)?_c('observations-report-files',{attrs:{"observationsReport":_vm.observationsReport,"readOnly":!_vm.isAdmin && !_vm.isEmitter,"observations":_vm.observations,"showGenerateAPDFByBundleOption":false},on:{"generatePDF":_vm.onGeneratePDF}}):_vm._e(),(_vm.isAdmin)?_c('app-quick-actions',{attrs:{"options":_vm.quickActions},on:{"choose":function($event){return $event.run()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }