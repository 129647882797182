<template>
    <div>
        <div class="flex flex-wrap">
            <div class="flex flex-grow">
                <div style="max-width: 155px; max-height: 155px">
                    <img :src="project.imageUrl" v-if="project.imageUrl" />
                </div>
                <div class="border-l-4 border-main py-2 pl-3">
                    <p class="font-bold">{{ $t('commons.project') }} {{ project.name }}</p>
                    <p class="text-xs">{{ project.address1 }}</p>
                    <p class="text-xs" v-if="project.address2">{{ project.address2 }}</p>
                    <p class="text-xs" v-if="project.address3">{{ project.address3 }}</p>
                    <p class="text-xs">{{ project.postalCode }} {{ project.city }}</p>
                </div>
            </div>
            <slot></slot>
        </div>
        <div class="my-8 flex text-lg justify-center flex-col text-center">
            <div class="font-bold">
                {{ documentName }}
            </div>
            <div>
                {{ documentTitle }}
            </div>
            <div v-if="date">{{ date | humanizeDateFull }}</div>
        </div>
    </div>
</template>
<script>
import { queryProject } from '@/features/projects/projects.service';
export default {
    components: {},
    props: {
        date: {
            type: Date,
        },
        documentName: {
            type: String,
        },
        documentTitle: {
            type: String,
        },
    },
    data() {
        return {
            project: {},
        };
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => (this.project = project));
    },
};
</script>
