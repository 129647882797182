import getDaysInYear from 'date-fns/getDaysInYear';
import startOfWeek from 'date-fns/startOfWeek';
import add from 'date-fns/add';

const getMonthsInYear = function (date) {
    if (date) {
        const currentYear = date.getFullYear();
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((index) => new Date(currentYear, index, 2, 0, 0, 0, 0));
    } else return null;
};

const getDaysStyle = function (date, exceptions) {
    const daysOfYear = Array.from({ length: getDaysInYear(date) }, (_, i) => {
        const doyDate = new Date(date.getFullYear(), 0, 2, 0, 0, 0, 0);
        doyDate.setDate(i + 1);
        return doyDate;
    });
    const result = {};
    for (const day of daysOfYear) {
        result[day.getTime()] = {
            'bg-gray-300': isOffDay(day, exceptions),
            'font-bold': isCustom(day, exceptions),
            'weather-issue': isWeatherIssue(day, exceptions),
        };
    }
    return result;
};

export const isOffDay = function (day, exceptions) {
    const isWeekEnd = day.getDay() === 0 || day.getDay() === 6;
    return isWeekEnd || !!exceptions[day.getTime()];
};

export const isCustom = function (day, exceptions) {
    const exception = exceptions[day.getTime()];
    return !!(exception && exception.type === 'custom');
};

export const isWeatherIssue = function (day, exceptions) {
    const exception = exceptions[day.getTime()];
    return !!(exception && exception.type === 'weatherIssue');
};

const getWeekDays = function () {
    const sow = startOfWeek(new Date());
    return [1, 2, 3, 4, 5, 6, 7].map((index) => add(sow, { days: index }));
};

const getDayClass = function (dayClass, month, dom) {
    const domDate = new Date(month);
    domDate.setDate(dom);
    return dayClass[domDate.getTime()] || '';
};

export default {
    getMonthsInYear,
    getDaysStyle,
    getWeekDays,
    getDayClass,
};
