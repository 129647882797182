<template>
    <app-button icon="icon-chevron-left" @click="$emit('back')">{{ name }}</app-button>
</template>
<script>
import AppButton from '../appButton/AppButton';
export default {
    components: { AppButton },
    props: {
        name: String,
    },
    async created() {
        document.addEventListener('keyup', this.onEscape);
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.onEscape);
    },
    methods: {
        onEscape(e) {
            if (e.key === 'Esc' || e.key === 'Escape') {
                this.$emit('back');
            }
        },
    },
};
</script>
