<template>
    <span>
        <span v-for="span of spans" :class="span.class">{{ span.text }}</span></span
    >
</template>
<script>
export default {
    props: {
        label: String,
        shortcut: {
            type: String,
            default: null,
        },
    },
    computed: {
        spans() {
            if (this.label) {
                if (this.shortcut) {
                    const index = this.label.toLowerCase().indexOf(this.shortcut.toLowerCase());
                    if (index === 0) {
                        return [{ text: this.label.substr(0, 1), class: 'underline' }, { text: this.label.substr(1) }];
                    } else if (index > 0) {
                        return [
                            { text: this.label.substr(0, index) },
                            { text: this.label.substr(index, 1), class: 'underline' },
                            { text: this.label.substr(index + 1) },
                        ];
                    } else {
                        return [{ text: this.label + ' (' + this.shortcut + ')' }];
                    }
                } else {
                    return [this.label];
                }
            } else {
                return [];
            }
        },
    },
};
</script>
