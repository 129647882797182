import { importFromPaste } from '@/services/paste.service';
import { getBundleIdMapByReference } from '@/features/bundles/bundles.service';

export function importObservations(event, projectId, phase, relatedContentId, reportedBy, $t, bundles) {
    const { rows } = importFromPaste(event);
    if (rows) {
        return rows.map((row) => parseObservation(row, projectId, phase, relatedContentId, reportedBy, $t, bundles));
    }
}
export function parseObservation(row, projectId, phase, relatedContentId, reportedBy, $t, bundles) {
    const bundleMap = getBundleIdMapByReference(bundles);
    const observation = {
        phase,
        projectId,
        recipientIds: [],
        reportedOnPhase: phase,
        reportedAt: new Date().toISOString(),
        relatedContentId,
        reportedBy,
        type: 'onDoneWork',
    };
    let rest = [];
    row.map((cell) => {
        if (bundleMap[cell]) {
            observation.recipientIds.push(bundleMap[cell]);
        } else {
            const type = getType(cell, $t);
            if (type) {
                observation.type = type;
            } else if (cell.length) {
                rest.push(cell);
            }
        }
    });
    observation.title = rest.join(' - ') || 'Nouvelle Observation';
    return observation;
}
const types = ['administrative', 'general', 'onDoneWork', 'onTodoWork', 'other'];
function getType(cell, $t) {
    return (
        types.find((key) => {
            const firstCol = cell.toLowerCase();
            const label = $t('observations.types.' + key)?.toLowerCase();
            return firstCol === label;
        }) || null
    );
}
