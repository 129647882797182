<template>
    <div class="m-auto fixed w-full h-full inset-0 items-center justify-center flex-col flex">
        <div class="flex flex-col items-center mb-10 mb-10">
            <img src="/logoAtex.svg" alt="Atex logo" class="w-16 mb-1" />
            <h1 style="letter-spacing: 0.2rem" class="text-center">
                <b>A</b>
                <span class="mr-2">ssistance</span>
                <b>T</b>
                <span>echnique d'</span>
                <b>EX</b>
                <span>écution</span>
            </h1>
        </div>
        <main class="w-full sm:max-w-sm md:shadow-2xl">
            <label>
                <div class="flex flex-col">
                    {{ $t('upgrading') }}
                    <div class="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden">
                        <div
                            class="bg-blue-600 h-2.5 rounded-full"
                            :style="{
                                width: progress + '%',
                                animation: progress < 0 ? 'indeterminateAnimation 1s infinite linear' : '',
                            }"
                            style="transform-origin: 0% 50%"
                        ></div>
                    </div>
                </div>
            </label>
        </main>
    </div>
</template>
<script>
export default {
    data: () => ({
        progress: 0,
        interval: null,
        subscriptions: [],
    }),
    created() {
        this.progress = 0;
        this.interval = setInterval(() => {
            if (this.progress >= 100) {
                this.progress = -1;
                clearInterval(this.interval);
                setTimeout(async () => {
                    await window.push({ name: 'home' });
                    window.location.reload();
                }, 250);
            } else {
                this.progress = this.progress + 30000 / 250 / 100;
            }
        }, 250);
    },
    methods: {},
};
</script>
<style>
@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }
    40% {
        transform: translateX(0) scaleX(0.4);
    }
    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}
</style>
