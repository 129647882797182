<template>
    <div>
        <app-separator :label="$t('project.follow.agenda.exceptions.title')" class="mb-2" />
        <div v-if="!newExceptionCreating && !editedException" class="p-2">
            <div class="col-span-2">
                <app-button
                    @click="addException()"
                    :label="$t('project.follow.agenda.exceptions.newException')"
                    v-if="!disabled"
                />
            </div>
            <div v-if="exceptions.length === 0" class="w-full text-center italic text-gray-700 text-sm">
                {{ $t('project.follow.agenda.exceptions.empty') }}
            </div>
            <ul v-if="exceptions.length > 0" class="mt-2">
                <li
                    v-for="exception of exceptions"
                    @click="editException(exception)"
                    class="w-full hover:bg-gray-200 flex p-2 border text-center"
                >
                    <div class="flex flex-col flex-grow">
                        <span class="text-xs text-gray-700 text-left">
                            {{ exception.startDate | humanizeDate }} - {{ exception.endDate | humanizeDate }}
                        </span>
                        <span class="m-1">{{ exception.name }}</span>
                        <span class="text-xs text-gray-700 text-left">
                            <span :title="getBundleTooltip(exception)">{{ getBundleLabel(exception) }}</span>
                        </span>
                    </div>
                    <div>
                        <app-button
                            size="mini"
                            @click.stop="removeException(exception)"
                            icon="icon-trash-can-outline"
                            variant="danger"
                            aria-label="delete exception"
                            v-if="!disabled"
                        />
                    </div>
                </li>
            </ul>
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty, touched }" ref="observer">
            <form>
                <exception-form
                    v-if="newExceptionCreating"
                    :exception="newException"
                    :invalid="invalid"
                    :errors="errors"
                    :touched="touched"
                    @save="validNewException"
                    @cancel="onCreateCancel"
                />
                <exception-form
                    v-if="editedException"
                    :exception="editedException"
                    :invalid="invalid"
                    :errors="errors"
                    :touched="touched"
                    @save="saveException"
                    @cancel="onCreateEdition"
                />
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import AppButton from '../../../components/appButton/AppButton';
import AppFieldset from '../../../components/appFieldset/AppFieldset';
import ExceptionForm from './ExceptionForm';
import { getMapById, omit, sanitize } from '../../../services/sanitize.service';
import { confirm } from '../../dialogs/dialogs.service';
import { createException, getAgenda, removeException, saveException } from './agenda.service';
import AppSeparator from '../../../components/appSeparator/AppSeparator';
import { getBundles } from '@/features/bundles/bundles.service';
import { combineLatest } from 'rxjs';
export default {
    components: {
        AppSeparator,
        ExceptionForm,
        AppFieldset,
        AppButton,
    },
    props: {
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            exceptions: [],
            subscriptions: [],
            bundles: {},
            newExceptionCreating: false,
            editedException: null,
            newException: { startDate: new Date(), endDate: new Date(), name: '', bundles: [] },
        };
    },
    created() {
        this.subscriptions = [
            combineLatest([
                getAgenda(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([periods, bundles]) => {
                this.bundles = bundles;
                const bundleMap = getMapById(bundles);
                this.exceptions = periods
                    .filter((period) => period.type === 'custom')
                    .map((exception) => ({
                        ...exception,
                        bundles: exception.bundleIds.map((id) => bundleMap[id]),
                    }));
            }),
        ];
    },
    methods: {
        getBundleLabel(exception) {
            if (exception.bundles.length === 0 || exception.bundles.length === this.bundles.length) {
                return this.$t('project.follow.agenda.exceptions.allBundles');
            } else if (exception.bundles.length === 1) {
                return exception.bundles.length + ' ' + this.$t('commons.bundle');
            } else if (exception.bundles.length > 1) {
                return exception.bundles.length + ' ' + this.$t('project.follow.agenda.exceptions.bundlePlural');
            }
        },
        getBundleTooltip(exception) {
            if (exception.bundles.length === 0 || exception.bundles.length === this.bundles.length) {
                return this.$t('project.follow.agenda.exceptions.allBundles');
            } else {
                return exception.bundles.map((bundle) => bundle.name).join('\n');
            }
        },
        addException() {
            this.editedException = null;
            this.newExceptionCreating = true;
            this.resetNewException();
        },
        onCreateCancel() {
            this.newExceptionCreating = false;
            this.resetNewException();
        },
        onCreateEdition() {
            this.editedException = null;
        },
        resetNewException() {
            this.newException = { startDate: new Date(), endDate: new Date(), name: '', bundles: [] };
        },
        validNewException: async function (value) {
            this.newExceptionCreating = false;
            const entity = sanitize(value, ['bundles', 'type']);
            entity.bundleIds = value.bundles.map((bundle) => bundle.id);
            await createException(this.$route.params.projectId, entity);
            this.$refs.observer.reset();
            this.resetNewException();
        },
        saveException: async function (exception) {
            this.editedException = null;
            const entity = omit(exception, ['bundles', 'type']);
            entity.bundleIds = exception.bundles.map((bundle) => bundle.id);
            await saveException(this.$route.params.projectId, entity);
            this.$refs.observer.reset();
        },
        removeException: async function (exception) {
            if (await confirm(this.$t('project.follow.agenda.exceptions.confirmMessage'))) {
                return removeException(this.$route.params.projectId, exception.id);
            }
        },
        editException: async function (exception) {
            if (!this.disabled) {
                this.editedException = exception;
            }
        },
    },
};
</script>
