<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-4 items-center">
                <app-button @click="addObservation()" :label="$t('observations.newObservation')" v-if="!readOnly" />
                <div class="flex-grow"></div>
                <div class="flex gap-2 text-xs items-center">
                    <div
                        class="px-1 border observations-to-fix-late flex items-center justify-end"
                        :title="$t('observations.toFix')"
                        style="min-width: 3em"
                    >
                        {{ toFixCount }}
                    </div>
                    <div
                        class="px-1 border observations-done flex items-center justify-end"
                        style="min-width: 3em"
                        :title="$t('observations.fixed')"
                    >
                        {{ fixedCount }}
                    </div>
                    <div
                        class="px-1 border observations-validated flex items-center justify-end"
                        style="min-width: 3em"
                        :title="$t('observations.validated')"
                    >
                        {{ validatedCount }}
                    </div>
                    <div
                        class="px-1 border border-gray-600 flex items-center justify-end"
                        style="min-width: 3em"
                        :title="$t('observations.obsolete')"
                    >
                        {{ obsoleteCount }}
                    </div>
                </div>
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.pickerName || option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify" v-if="!readOnly">{{ $t('commons.actionModify') }}</option>
                        <option value="resolve" v-if="validationAllowed">{{ $t('commons.actionResolve') }}</option>
                        <option value="validate" v-if="validationAllowed">{{ $t('commons.actionValidate') }}</option>
                        <option value="delete" v-if="!readOnly">{{ $t('commons.actionDelete') }}</option>
                        <option value="CSVExport">{{ $t('commons.actionCSVExport') }}</option>
                        <option value="copy">{{ $t('commons.actionCopy') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        labelClass="text-observations-to-fix-late font-bold"
                        v-model="pending"
                        :label="$t('observations.toFix') + (pending ? ' (' + resultingPendingCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        labelClass="text-observations-done font-bold"
                        v-model="resolved"
                        :label="$t('observations.done') + (resolved ? ' (' + resultingResolvedCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="validated"
                        labelClass="text-observations-validated font-bold"
                        :label="$t('observations.validated') + (validated ? ' (' + resultingValidatedCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="obsolete"
                        :label="
                            $t('project.follow.follow.obsolete') + (obsolete ? ' (' + resultingObsoleteCount + ')' : '')
                        "
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky z-10 top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 3.5rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('index')"
                            >
                                {{ '#' }}
                                <div v-if="sortKey === 'index'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('phase')"
                            >
                                {{ $t('commons.phase') }}
                                <div v-if="sortKey === 'phase'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 5rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('type')"
                            >
                                {{ $t('observations.type') }}
                                <div v-if="sortKey === 'type'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('project.follow.observation.titleLabel') }}</span>
                        </th>
                        <th style="width: 6rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('zone')"
                            >
                                {{ $t('project.follow.observation.zone') }}
                                <div v-if="sortKey === 'zone'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 10rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('recipients')"
                            >
                                {{ $t('project.follow.observation.recipients') }}
                                <div v-if="sortKey === 'recipients'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('dueDate')"
                            >
                                {{ $t('project.follow.observation.dueDateShort') }}
                                <div v-if="sortKey === 'dueDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('resolvedAt')"
                            >
                                {{ $t('project.follow.observation.resolvedAt') }}
                                <div v-if="sortKey === 'resolvedAt'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="observation in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell">
                                <a :id="'uuid_' + observation.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="observation.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(observation)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-center">
                                    <div
                                        v-if="!observation.footprint || observation.obsoleteAt"
                                        class="p-1 px-2"
                                        :class="{
                                            [getObservationClass(observation)]: true,
                                            border:
                                                observation.type === 'other' ||
                                                observation.type === 'general' ||
                                                observation.type === 'administrative',
                                        }"
                                    >
                                        {{ (observation.index + 1).toString().padStart(3, '0') }}
                                    </div>
                                    <button
                                        @click.stop.prevent="
                                            $router.push({ ...$route, hash: '#uuid_' + observation.id })
                                        "
                                        v-else
                                    >
                                        <app-static-marker
                                            size="mini"
                                            class="text-xs cursor-pointer"
                                            :observation="observation"
                                        ></app-static-marker>
                                    </button>
                                </div>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <span>{{ $t('commons.phases.' + observation.phase) }}</span>
                            </td>
                            <td class="border-r p-1 hidden sm:table-cell">
                                <div class="flex gap-1 items-center">
                                    <icon-incognito class="text-violet-900" v-if="observation.type === 'private'" />
                                    <span>{{ $t('project.follow.observation.statTypes.' + observation.type) }}</span>
                                </div>
                            </td>
                            <td class="border-r p-1">
                                <router-link
                                    class="hover:underline"
                                    :to="{
                                        name: $route.params.autoControlId
                                            ? 'followAutoControlObservation'
                                            : $route.params.receptionId
                                            ? 'followReceptionObservation'
                                            : 'followObservation',
                                        params: {
                                            ...$route.params,
                                            observationId: observation.id,
                                        },
                                    }"
                                >
                                    <app-clamp2 :htmlContent="observation.title" :number-of-lines="2" />
                                    <span v-if="!observation.title || observation.title.trim().length === 0">...</span>
                                </router-link>
                            </td>
                            <td class="border-r p-1">
                                <span :title="observation.zone.fullName" v-if="observation.zone">
                                    {{ observation.zone.name }}
                                </span>
                            </td>
                            <td class="border-r p-1 truncate hidden sm:table-cell">
                                <div
                                    v-if="observation.recipients.length === 1"
                                    style="max-width: 10rem"
                                    class="truncate"
                                >
                                    <span class="">
                                        {{
                                            observation.recipients[0].reference
                                                ? '#' +
                                                  observation.recipients[0].reference +
                                                  ' ' +
                                                  observation.recipients[0].name
                                                : observation.recipients[0].name
                                        }}
                                    </span>
                                </div>
                                <div
                                    class="flex flex-col truncate"
                                    v-if="observation.recipients.length === 2"
                                    style="max-width: 10rem"
                                >
                                    <span>
                                        {{
                                            observation.recipients[0].reference
                                                ? '#' +
                                                  observation.recipients[0].reference +
                                                  ' ' +
                                                  observation.recipients[0].name
                                                : observation.recipients[0].name
                                        }}
                                    </span>
                                    <span class="">
                                        {{
                                            observation.recipients[1].reference
                                                ? '#' +
                                                  observation.recipients[1].reference +
                                                  ' ' +
                                                  observation.recipients[1].name
                                                : observation.recipients[1].name
                                        }}
                                    </span>
                                </div>
                                <span
                                    v-if="observation.recipients.length >= 3"
                                    :title="
                                        observation.recipients
                                            .map((recipient) => '#' + recipient.reference + ' ' + recipient.name)
                                            .join('\n')
                                    "
                                >
                                    <app-tips>
                                        {{
                                            observation.recipients.length +
                                            ' ' +
                                            $t('project.follow.observation.recipients')
                                        }}
                                    </app-tips>
                                </span>
                            </td>
                            <td
                                class="text-center border-r p-1 hidden sm:table-cell"
                                :class="{
                                    'text-red-600 font-bold':
                                        !observation.resolvedAt && observation.dueDate < new Date(),
                                }"
                            >
                                <app-date-link
                                    @enter="focusToNextDueDate(observation)"
                                    :ref="'dueDate_' + observation.id"
                                    :disabled="readOnly"
                                    :label="$t('project.follow.observation.dueDateShort')"
                                    :show-label="false"
                                    v-model="observation.dueDate"
                                    @input="updateDueDate(observation, $event)"
                                ></app-date-link>
                            </td>
                            <td class="text-center border-r p-1 hidden sm:table-cell">
                                <app-date-link
                                    @enter="focusToNextDate(observation)"
                                    :ref="'date_' + observation.id"
                                    :disabled="readOnly"
                                    :label="$t('project.follow.observation.resolvedAt')"
                                    :show-label="false"
                                    v-model="observation.resolvedAt"
                                    @input="updateDate(observation, $event)"
                                ></app-date-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-select :label="$t('observations.type')" v-model="editedObservation.type">
                    <option value="administrative">{{ $t('observations.types.administrative') }}</option>
                    <option value="general">{{ $t('observations.types.general') }}</option>
                    <option value="onDoneWork">{{ $t('observations.types.onDoneWorkShort') }}</option>
                    <option value="onTodoWork">{{ $t('observations.types.onTodoWorkShort') }}</option>
                    <option value="private">{{ $t('observations.types.private') }}</option>
                    <option value="other">{{ $t('observations.types.other') }}</option>
                </app-select>

                <app-select :label="$t('commons.phase')" v-model="editedObservation.phase">
                    <option value="CON">{{ $t('commons.phases.CON') }}</option>
                    <option value="EXE">{{ $t('commons.phases.EXE') }}</option>
                    <option value="OPR">{{ $t('commons.phases.OPR') }}</option>
                    <option value="Receipt">{{ $t('commons.phases.Receipt') }}</option>
                    <option value="OPL">{{ $t('commons.phases.OPL') }}</option>
                    <option value="Delivery">{{ $t('commons.phases.Delivery') }}</option>
                    <option value="APA">{{ $t('commons.phases.APA') }}</option>
                </app-select>

                <app-bundle-picker v-model="editedObservation.reporter" :options="bundles"></app-bundle-picker>

                <app-multi-picker
                    class="col-span-2"
                    v-model="editedObservation.recipients"
                    :options="bundles"
                    :label="$t('project.follow.observation.recipients')"
                >
                    <template v-slot:option="{ option }">
                        <app-bundle :bundle="option" />
                    </template>
                </app-multi-picker>

                <app-date-input
                    v-model="editedObservation.dueDate"
                    :label="$t('project.follow.observation.dueDate')"
                ></app-date-input>
                <app-date-input
                    v-model="editedObservation.resolvedAt"
                    :label="$t('project.follow.observation.resolvedAt')"
                />
                <app-footer @click="saveMultiple" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="importObservationsPopup" :showHeader="true" :title="$t('observations.importObservations')">
            <app-textarea
                ref="pasteArea"
                :placeholder="$t('observations.importObservationsTips')"
                @paste="onPaste"
            ></app-textarea>
        </app-popup>
    </main>
</template>

<script>
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import { isMobile } from '@/state/state';
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppList from '../../components/appList/AppList';
import locationService from '../../services/location.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import AppButton from '@/components/appButton/AppButton';
import {
    createObservation,
    getObservations,
    getObservationsForRelatedContent,
    removeObservation,
    updateObservation,
} from './observation.service';

import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppInputText from '@/components/appInputText/AppInputText';
import AppCancel from '@/components/appCancel/AppCancel';
import IconMenuDown from '@/icons/IconMenuDown';
import IconMenuUp from '@/icons/IconMenuUp';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker';
import { getObservationClass, bulkInsert } from '@/features/observations/observation.service';
import AppClamp from '@/components/appClamp/AppClamp';
import AppTips from '@/components/app-tips/AppTips';
import ObservationStats from '@/features/follow/observations/ObservationStats';
import AppBundle from '@/components/app-bundle/appBundle';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import IconRotateRight from '@/icons/IconRotateRight.vue';
import { queryAutoControl } from '@/features/autoControls/autoControls.service';
import { queryReception } from '@/features/receptions/receptions.service';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';
import { importObservations } from '@/features/observations/pasteObservations.service';
import AppClamp2 from '@/components/appClamp/AppClamp2.vue';
import { humanizeDate } from '@/filters/dateFilter';
import * as clipboard from 'clipboard-polyfill';
export default {
    components: {
        AppClamp2,
        AppTextarea,
        IconRotateRight,
        AppBundle,
        ObservationStats,
        AppTips,
        AppClamp,
        AppStaticMarker,
        IconCheckCircleOutline,
        AppNumberLink,
        AppNumberInput,
        IconMenuUp,
        IconMenuDown,
        AppCancel,
        AppInputText,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppDateLink,
        AppButton,
        AppQuickActions,
        AppMultiPicker,
        AppSelect,
        AppList,
        AppCheckbox,
    },
    async created() {
        this.restoreFilter();
        this.restoreSort();
        this.init();
    },
    computed: {
        resultingValidatedCount() {
            return this.filteredItems.filter((item) => item.validatedAt).length;
        },
        resultingResolvedCount() {
            return this.filteredItems.filter((item) => item.resolvedAt && !item.validatedAt).length;
        },
        resultingObsoleteCount() {
            return this.filteredItems.filter((item) => item.obsoleteAt).length;
        },
        resultingPendingCount() {
            return this.filteredItems.filter((item) => !item.resolvedAt).length;
        },
        quickActions() {
            return [
                {
                    name: this.$t('observations.newObservation'),
                    run: () => this.addObservation(),
                },
                {
                    name: this.$t('observations.importObservations'),
                    run: () => this.$refs.importObservationsPopup.open(),
                },
            ];
        },
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'index') {
                        return item.index;
                    } else if (this.sortKey === 'type') {
                        return item.type ? this.$t('observations.types.' + item.type) : null;
                    } else if (this.sortKey === 'phase') {
                        return item.phase;
                    } else if (this.sortKey === 'dueDate') {
                        return item.dueDate;
                    } else if (this.sortKey === 'resolvedAt') {
                        return item.resolvedAt;
                    } else if (this.sortKey === 'recipients') {
                        return item.recipients.map((recipient) => recipient.reference + ' ' + recipient.name).join();
                    } else if (this.sortKey === 'zone') {
                        return item.zone ? item.zone.fullName : null;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: 'statusCriteriaType',
                    children: [
                        {
                            name: this.$t('project.follow.toValidate'),
                            id: 'toValidate',
                            criteriaType: '',
                            firstOfCriteriaType: true,
                            _isStatusTypeCriteriaType: true,
                        },
                        {
                            name: this.$t('project.follow.noReporter'),
                            id: 'noReporter',
                            criteriaType: '',
                            firstOfCriteriaType: false,
                            _isStatusTypeCriteriaType: true,
                        },
                        {
                            name: this.$t('project.follow.noRecipients'),
                            id: 'noRecipients',
                            criteriaType: '',
                            firstOfCriteriaType: false,
                            _isStatusTypeCriteriaType: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('observations.type'),
                    id: this.$t('observations.type'),
                    children: [
                        {
                            id: 'administrative',
                            name: this.$t('observations.types.administrative'),
                            _isTypeCriteria: true,
                        },
                        { id: 'general', name: this.$t('observations.types.general'), _isTypeCriteria: true },
                        {
                            id: 'onDoneWork',
                            name: this.$t('observations.types.onDoneWork'),
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'onTodoWork',
                            name: this.$t('observations.types.onTodoWork'),
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'private',
                            name: this.$t('observations.types.private'),
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'other',
                            name: this.$t('observations.types.other'),
                            _isTypeCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('observations.linkedToRequest'),
                    id: this.$t('observations.linkedToRequest'),
                    children: [
                        {
                            id: 'linkedToRequest',
                            name: this.$t('observations.links.linkedToRequest'),
                            _isLinkedToRequestCriteria: true,
                        },
                        {
                            id: 'notLinkedToRequest',
                            name: this.$t('observations.links.notLinkedToRequest'),
                            _isLinkedToRequestCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.phase'),
                    id: this.$t('commons.phase'),
                    children: [
                        { id: 'CON', name: this.$t('commons.phases.CON'), _isPhaseCriteria: true },
                        { id: 'EXE', name: this.$t('commons.phases.EXE'), _isPhaseCriteria: true },
                        { id: 'OPR', name: this.$t('commons.phases.OPR'), _isPhaseCriteria: true },
                        { id: 'Receipt', name: this.$t('commons.phases.Receipt'), _isPhaseCriteria: true },
                        { id: 'OPL', name: this.$t('commons.phases.OPL'), _isPhaseCriteria: true },
                        { id: 'Delivery', name: this.$t('commons.phases.Delivery'), _isPhaseCriteria: true },
                        { id: 'APA', name: this.$t('commons.phases.APA'), _isPhaseCriteria: true },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.label,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.emitterCriteriaType'),
                    id: this.$t('commons.emitterCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        id: 'emitter_' + bundle.id,
                        pickerName: bundle.label,
                        name: `[${this.$t('commons.emitterCriteriaType')}] ${bundle.label}`,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.emitterCriteriaType'),
                        _isEmitterCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: [
                        {
                            id: 'noLocation',
                            path: 'noLocation',
                            name: this.$t('commons.noLocation'),
                            firstOfCriteriaType: true,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        },
                        ...this.locationOptions.map((location) => ({
                            ...location,
                            name: location.fullName,
                            firstOfCriteriaType: false,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        })),
                    ],
                },
            ];
        },
    },
    methods: {
        async onPaste(e) {
            if (this.$refs.pasteArea.$el.contains(e.target)) {
                const observations = importObservations(
                    e,
                    this.$route.params.projectId,
                    this.projectPhase,
                    this.$route.params.autoControlId || this.$route.params.receptionId || null,
                    this.me.bundleIds[0],
                    (key) => this.$t(key),
                    this.bundles,
                );
                this.$refs.importObservationsPopup.close();
                return bulkInsert(this.$route.params.projectId, observations);
            }
        },
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((calendar) => {
                    this.calendar = calendar;
                }),
                combineLatest([
                    this.$route.params.autoControlId || this.$route.params.receptionId
                        ? getObservationsForRelatedContent(
                              this.$route.params.projectId,
                              this.$route.params.autoControlId || this.$route.params.receptionId,
                          )
                        : getObservations(this.$route.params.projectId),
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(async ([observations, bundleMap, folders, project]) => {
                    let relatedTo;
                    if (this.$route.params.autoControlId) {
                        relatedTo = await queryAutoControl(
                            this.$route.params.projectId,
                            this.$route.params.autoControlId,
                        );
                    } else if (this.$route.params.receptionId) {
                        relatedTo = await queryReception(this.$route.params.projectId, this.$route.params.receptionId);
                    }
                    this.readOnly = !(
                        project.me.allowedFeatures.includes('project_observations') ||
                        (relatedTo && project.me.bundleIds.includes(relatedTo.bundleId))
                    );
                    this.validationAllowed = project.me.allowedFeatures.includes('project_observations_MOEValidation');
                    this.me = project.me;
                    this.projectPhase = project.phase;

                    this.locationOptions = locationService
                        .buildLocationOptions(folders)
                        .filter((location) => location.type !== 'room');
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.restoreSelection();
                    this.items = observations.map((observation) => {
                        const recipients = observation.recipientIds.map((id) => bundleMap[id]);
                        return {
                            ...observation,
                            recipients,
                            filterString: [
                                observation.index + 1,
                                observation.title,
                                recipients.map((recipient) => recipient.reference + ' ' + recipient.name).join(),
                            ].join(),
                            isSelected: this.selection.includes(observation.id),
                            zone: this.locationOptions.find((option) => option.id === observation.zoneId),
                            room: this.locationOptions.find((option) => option.id === observation.roomId),
                        };
                    });
                    this.toFixCount = observations.filter(
                        (observation) => !observation.obsoleteAt && !observation.validatedAt && !observation.resolvedAt,
                    ).length;
                    this.fixedCount = observations.filter(
                        (observation) => !observation.obsoleteAt && !observation.validatedAt && observation.resolvedAt,
                    ).length;
                    this.validatedCount = observations.filter(
                        (observation) => !observation.obsoleteAt && observation.validatedAt,
                    ).length;
                    this.obsoleteCount = observations.filter((observation) => !!observation.obsoleteAt).length;

                    this.cleanupSavedSelection();

                    const lastVisitedObservationId = localStorage.getItem(
                        'observation.lastVisitedObservationId.' + this.$route.params.projectId,
                    );
                    if (lastVisitedObservationId) {
                        this.$nextTick(() => {
                            const element = this.$el.querySelector('#uuid_' + lastVisitedObservationId);
                            if (element) element.scrollIntoView();
                        });
                    }
                    this.loading = false;
                    this.$nextTick(async () => {
                        if (this.$route.query.forcePushObservations && !this.forcedOnce) {
                            const observations = this.items.filter(
                                (item) => new Date(item.updatedAt).getTime() > new Date().getTime() - 10 * 3600000 * 24,
                            );
                            if (await confirm("Forcer l'envoi au serveur (" + observations.length + ')')) {
                                this.forcedOnce = true;
                                await Promise.all(
                                    observations.map((observation) =>
                                        updateObservation(this.$route.params.projectId, {
                                            id: observation.id,
                                        }),
                                    ),
                                );
                            }
                        }
                    });
                }),
            ];
        },
        getObservationClass,
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            const patch = {};
            if (
                this.editedObservation.dueDate &&
                this.editedObservation.dueDate !== this.getSelectionCommonDueDate(selectedItems)
            ) {
                patch.dueDate = this.editedObservation.dueDate;
            }
            if (
                this.editedObservation.reporter &&
                this.editedObservation.reporter !== this.getSelectionCommonReporter(selectedItems)
            ) {
                patch.reportedBy = this.editedObservation.reporter.id;
            }
            if (
                this.editedObservation.resolvedAt &&
                this.editedObservation.resolvedAt !== this.getSelectionCommonEndDate(selectedItems)
            ) {
                patch.resolvedAt = this.editedObservation.resolvedAt;
            }
            if (
                this.editedObservation.type &&
                this.editedObservation.type !== this.getSelectionCommonType(selectedItems)
            ) {
                patch.type = this.editedObservation.type;
            }
            if (
                this.editedObservation.phase &&
                this.editedObservation.phase !== this.getSelectionCommonPhase(selectedItems)
            ) {
                patch.phase = this.editedObservation.phase;
            }
            const newRecipientIds = this.editedObservation.recipients.map((bundle) => bundle.id);
            this.selection.map(async (itemId) => {
                if (newRecipientIds.length) {
                    await updateObservation(this.$route.params.projectId, {
                        id: itemId,
                        recipientIds: newRecipientIds,
                    });
                }
                if (Object.keys(patch).length > 0) {
                    await updateObservation(this.$route.params.projectId, { id: itemId, ...patch });
                }
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeObservation(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedObservation = {
                    dueDate: this.getSelectionCommonDueDate(selectedItems),
                    resolvedAt: this.getSelectionCommonEndDate(selectedItems),
                    type: this.getSelectionCommonType(selectedItems),
                    phase: this.getSelectionCommonPhase(selectedItems),
                    reporter: this.getSelectionCommonReporter(selectedItems),
                    recipients: this.getSelectionCommonRecipients(selectedItems).map((id) => ({ id })),
                };
                this.$refs.modifyAllPopup.open();
            } else if (action === 'validate') {
                if (
                    await confirm(this.$t('observations.confirmValidateMessageAll', { number: this.selection.length }))
                ) {
                    await Promise.all(
                        selectedItems.map((item) =>
                            updateObservation(this.$route.params.projectId, {
                                id: item.id,
                                resolvedAt: item.resolvedAt || new Date(),
                                resolvedBy: item.resolvedBy || this.me.bundleIds[0],
                                resolvedOnPhase: item.resolvedOnPhase || item.phase || this.projectPhase,
                                validatedAt: item.validatedAt || new Date(),
                                validatedBy: item.validatedBy || this.me.bundleIds[0],
                                validatedOnPhase: item.validatedOnPhase || item.phase || this.projectPhase,
                            }),
                        ),
                    );
                }
            } else if (action === 'resolve') {
                if (
                    await confirm(this.$t('observations.confirmValidateMessageAll', { number: this.selection.length }))
                ) {
                    await Promise.all(
                        selectedItems.map((item) =>
                            updateObservation(this.$route.params.projectId, {
                                id: item.id,
                                resolvedAt: item.resolvedAt || new Date(),
                                resolvedBy: item.resolvedBy || this.me.bundleIds[0],
                                resolvedOnPhase: item.resolvedOnPhase || item.phase || this.projectPhase,
                            }),
                        ),
                    );
                }
            } else if (action === 'CSVExport') {
                const header = '"N°","Type","Lot","Phase","Zone","Lien","Description","Levée attendue"\n';
                this.downloadAsFile(
                    header +
                        selectedItems
                            .map((observation) =>
                                [
                                    (observation.index + 1).toString().padStart(3, '0') || '',
                                    this.$t('project.follow.observation.statTypes.' + observation.type) || '',
                                    observation.recipients
                                        .map((recipient) => '#' + recipient.reference + ' ' + recipient.name)
                                        .join(', ') || '',
                                    this.$t('commons.phases.' + observation.phase) || '',
                                    observation.zone?.fullName || '',
                                    `https://app.atex-info.eu/project/${observation.projectId}/follow/observations/${observation.id}`,
                                    observation.title.replace(/<\/p>/g, '\n').replace(/<[^>]*>/g, '') || '',
                                    observation.dueDate ? humanizeDate(observation.dueDate) : '' || '',
                                ]
                                    .map((entry) => '"' + entry.replaceAll(',', ' ') + '"')
                                    .join(),
                            )
                            .join('\n'),
                );
            } else if (action === 'copy') {
                const header = `<thead><tr><th>${[
                    'N°',
                    'Type',
                    'Lot',
                    'Phase',
                    'Zone',
                    'Levée attendue',
                    'Description',
                ].join('</th><th>')}</thead>`;
                let text = `<html><body><table>${header}<tbody>`;
                text += selectedItems
                    .map(
                        (observation) =>
                            `<tr>
                          <td><a href="${`https://app.atex-info.eu/project/${observation.projectId}/follow/observations/${observation.id}`}">${
                                (observation.index + 1).toString().padStart(3, '0') || ''
                            }</a></td>
                          <td>${this.$t('project.follow.observation.statTypes.' + observation.type) || ''}</td>
                          <td>${
                              observation.recipients
                                  .map((recipient) => '#' + (recipient.reference || '') + ' ' + recipient.name)
                                  .join(', ') || ''
                          }</td>
                          <td>${this.$t('commons.phases.' + observation.phase) || ''}</td>
                          <td>${observation.zone?.fullName || ''}</td>
                          <td>${observation.dueDate ? humanizeDate(observation.dueDate) : '' || ''}</td>
                          <td>${observation.title
                              .replace(/<img +src="([^>]*)>/g, '<a href="https://app.atex-info.eu$1>[image]</a>')
                              .replace(/<\/p><p>/g, '<br/>')
                              .replace(/<\/?p>/g, '')}</td></tr>`,
                    )
                    .join('\n');
                text += '</tbody></table></body></html>';
                let data = new clipboard.ClipboardItem({
                    'text/html': new Blob([text], { type: 'text/html' }),
                });
                await clipboard.write([data]);
            }
        },
        async downloadAsFile(content) {
            const a = document.createElement('a');
            a.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
            a.download = `observations.csv`;
            a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                URL.revokeObjectURL(a.href);
            }, 1500);
        },
        getSelectionCommonType(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.type);
            if (firstItem && selectedItems.every((item) => item.type === firstItem.type)) {
                return firstItem.type;
            } else {
                return '';
            }
        },
        getSelectionCommonReporter(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.reporter);
            if (
                firstItem &&
                selectedItems.every((item) => item.reporter && item.reporter.id === firstItem.reporter.id)
            ) {
                return firstItem.reporter;
            } else {
                return null;
            }
        },
        getSelectionCommonPhase(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.phase);
            if (firstItem && selectedItems.every((item) => item.phase === firstItem.phase)) {
                return firstItem.phase;
            } else {
                return '';
            }
        },
        getSelectionCommonRecipients(selectedItems) {
            const firstItem = selectedItems.find((item) => item.recipientIds.length > 0);
            if (
                firstItem &&
                selectedItems.every((item) => item.recipientIds.every((id) => firstItem.recipientIds.includes(id)))
            ) {
                return firstItem.recipientIds;
            } else {
                return [];
            }
        },
        getSelectionCommonEndDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.resolvedAt);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.resolvedAt && item.resolvedAt.getTime() === firstItem.resolvedAt.getTime(),
                )
            ) {
                return firstItem.resolvedAt;
            } else {
                return null;
            }
        },
        getSelectionCommonDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.dueDate);
            if (
                firstItem &&
                selectedItems.every((item) => item.dueDate && item.dueDate.getTime() === firstItem.dueDate.getTime())
            ) {
                return firstItem.dueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'observations.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('observations.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addObservation() {
            const observation = await createObservation(this.$route.params.projectId, {
                title: this.$t('project.follow.observation.newObservation'),
                phase: this.projectPhase,
                reportedBy: this.me.bundleIds[0],
                relatedContentId: this.$route.params.autoControlId || this.$route.params.receptionId || null,
            });
            if (this.$route.params.autoControlId) {
                await this.$router.push({
                    name: 'followAutoControlObservation',
                    params: {
                        projectId: this.$route.params.projectId,
                        observationId: observation.id,
                        autoControlId: this.$route.params.autoControlId,
                    },
                });
            } else if (this.$route.params.receptionId) {
                await this.$router.push({
                    name: 'followReceptionObservation',
                    params: {
                        projectId: this.$route.params.projectId,
                        observationId: observation.id,
                        receptionId: this.$route.params.receptionId,
                    },
                });
            } else {
                await this.$router.push({
                    name: 'followObservation',
                    params: {
                        projectId: this.$route.params.projectId,
                        observationId: observation.id,
                    },
                });
            }
        },
        matchBundleFilter(item, bundleIds) {
            return item.recipientIds && item.recipientIds.find((id) => bundleIds.includes(id));
        },
        matchEmitterFilter(item, bundleIds) {
            return item.reportedBy && bundleIds.includes(item.reportedBy);
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString || item.title, criteria, true));
        },
        matchType(typeCriteria, item) {
            if (!typeCriteria || typeCriteria.length === 0) {
                return true;
            }
            return typeCriteria.find((criteria) => item.type === criteria.id);
        },
        matchLinkedToRequest(criteria, item) {
            if (!criteria || criteria.length === 0) {
                return true;
            }
            return (
                (item.relatedContentType === 'request' &&
                    criteria.find((aCriteria) => aCriteria.id === 'linkedToRequest')) ||
                (item.relatedContentType !== 'request' &&
                    criteria.find((aCriteria) => aCriteria.id === 'notLinkedToRequest'))
            );
        },
        matchPhase(typeCriteria, item) {
            if (!typeCriteria || typeCriteria.length === 0) {
                return true;
            }
            return typeCriteria.find((criteria) => item.phase === criteria.id);
        },
        matchStatusFilter(item) {
            const isResolved = item.resolvedAt && !item.validatedAt;
            const isObsolete = !!item.obsoleteAt;
            const isValidated = !!item.validatedAt;
            const isPending = !item.resolvedAt;
            return (
                isObsolete === this.obsolete &&
                ((isResolved && this.resolved) || (isValidated && this.validated) || (isPending && this.pending))
            );
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'observations_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    obsolete: this.obsolete,
                    resolved: this.resolved,
                    validated: this.validated,
                    pending: this.pending,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('observations_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.obsolete = !!parsedCache.obsolete;
                this.resolved = !!parsedCache.resolved;
                this.validated = !!parsedCache.validated;
                this.pending = !!parsedCache.pending;
            }
        },
        saveSort() {
            localStorage.setItem(
                'observations_sort_' + this.$route.params.projectId,
                JSON.stringify({
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreSort() {
            const cache = localStorage.getItem('observations_sort_' + this.$route.params.projectId);
            if (cache) {
                const sortCache = JSON.parse(cache);
                this.sortKey = sortCache.sortKey || 'index';
                this.sortAsc = sortCache.sortAsc !== false;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id);
            const emitterIds = filter
                .filter((aCriteria) => aCriteria._isEmitterCriteria)
                .map((bundle) => bundle.id.substring('emitter_'.length));
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria);
            const linkedToRequestCriteria = filter.filter((aCriteria) => aCriteria._isLinkedToRequestCriteria);
            const phaseCriteria = filter.filter((aCriteria) => aCriteria._isPhaseCriteria);
            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);
            const toValidateCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'toValidate',
            );
            const noReporterCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'noReporter',
            );
            const noRecipientsCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'noRecipients',
            );
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchEmitterFilter: emitterIds.length === 0 || this.matchEmitterFilter(item, emitterIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchLocationCriteria: locationService.matchLocationCriteria(
                        locationCriteria,
                        [item.zone, item.room].filter((a) => !!a),
                    ),
                    matchString: this.matchString(stringCriteria, item),
                    matchType: this.matchType(typeCriteria, item),
                    matchPhase: this.matchPhase(phaseCriteria, item),
                    matchToValidate: this.matchToValidate(toValidateCriteria, item),
                    matchNoReporter: this.matchNoReporter(noReporterCriteria, item),
                    matchNoRecipients: this.matchNoRecipients(noRecipientsCriteria, item),
                    matchLinkedToRequest: this.matchLinkedToRequest(linkedToRequestCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        matchToValidate(toValidateCriteria, item) {
            return toValidateCriteria.length === 0 || (item.resolvedAt && !item.validatedAt);
        },
        matchNoReporter(noReporterCriteria, item) {
            return noReporterCriteria.length === 0 || !item.reporter;
        },
        matchNoRecipients(noRecipientsCriteria, item) {
            return noRecipientsCriteria.length === 0 || (item.type !== 'general' && item.recipientIds.length === 0);
        },
        updateDueDate(observation, date) {
            updateObservation(this.$route.params.projectId, { id: observation.id, dueDate: date });
        },
        updateDate(observation, date) {
            if (observation.dueDate) {
                updateObservation(this.$route.params.projectId, { id: observation.id, resolvedAt: date });
            } else {
                updateObservation(this.$route.params.projectId, {
                    id: observation.id,
                    dueDate: date,
                    resolvedAt: date,
                });
            }
        },
        focusToNextDate(observation) {
            let index = this.filteredItems.findIndex((aObservation) => aObservation.id === observation.id);
            let nextObservation = this.filteredItems[index + 1];
            while (!nextObservation && index < this.filteredItems.length) {
                nextObservation = this.filteredItems[++index];
            }
            if (nextObservation) {
                this.$emit('select', nextObservation);
                const element = this.$refs['date_' + nextObservation.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextDueDate(observation) {
            let index = this.filteredItems.findIndex((aObservation) => aObservation.id === observation.id);
            let nextObservation = this.filteredItems[index + 1];
            while (!nextObservation && index < this.filteredItems.length) {
                nextObservation = this.filteredItems[++index];
            }
            if (nextObservation) {
                this.$emit('select', nextObservation);
                const element = this.$refs['dueDate_' + nextObservation.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            forcedOnce: false,
            bundles: [],
            subscriptions: [],
            loading: true,
            validationAllowed: false,
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            projectPhase: null,
            editedObservation: {
                dueDate: null,
                emissionDate: null,
                type: null,
                locations: [],
                recipients: [],
                reporter: null,
            },
            isMobile,
            items: [],
            resolved: false,
            validated: false,
            pending: true,
            obsolete: false,
            filterValue: [],
            locationOptions: [],
            toFixCount: '',
            fixedCount: '',
            validatedCount: '',
            obsoleteCount: '',
        };
    },
};
</script>
