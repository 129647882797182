var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppList',{staticClass:"max-w-5xl",attrs:{"items":_vm.meetings,"filterFn":_vm.filterFn,"filterOptions":_vm.filterOptions,"removable":false,"new-item-label":_vm.readOnly ? '' : _vm.$t('project.editions.meetingReports.new'),"line-height":80,"disabled":_vm.readOnly},on:{"navigateTo":_vm.navigateTo,"newItem":function($event){return _vm.addMeeting()}},scopedSlots:_vm._u([{key:"headers",fn:function(){return [_c('app-checkbox',{attrs:{"label":_vm.$t('project.editions.meetingReports.hideOld')},model:{value:(_vm.hideOld),callback:function ($$v) {_vm.hideOld=$$v},expression:"hideOld"}})]},proxy:true},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex text-xs text-gray-600 justify-between w-full"},[_c('span',{staticClass:"flex gap-2"},[_c('span',[_vm._v(_vm._s(_vm._f("humanizeTime")(item.date)))]),_c('span',[_vm._v(_vm._s(item.type))])])]),_c('div',{staticClass:"flex text-xs items-baseline flex-col"},[_c('span',{staticClass:"text-lg font-bold mr-2"},[_vm._v("\n                    "+_vm._s(item.name)+"\n                ")]),_c('div',{staticClass:"w-full flex justify-end"},[(item.generationSucceeded && item.url)?_c('app-file-link',{staticClass:"text-xs",attrs:{"fileName":item.fileName,"url":item.url,"to":{
                            name: 'meetingReportViewer',
                            params: {
                                projectId: _vm.$route.params.projectId,
                                meetingId: item.id,
                            },
                        }}}):_vm._e()],1)])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }