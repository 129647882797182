var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('td',{staticClass:"border pr-1 text-right"},[_c('p',{staticStyle:{"color":"#f74c14"}},[_vm._v("Ref")])]),_c('td',{staticClass:"border text-center",style:(_vm.task.realStartDate ? 'background-color:rgba(56, 109, 165, 0.11);' : '')},[_c('span',[_vm._v(_vm._s(_vm._f("humanizeDate")(_vm.task.referenceStartDate)))])]),_c('td',{staticClass:"border text-center",style:(_vm.task.realEndDate ? 'background-color:rgba(56, 109, 165, 0.11);' : '')},[_c('span',[_vm._v(_vm._s(_vm._f("humanizeDate")(_vm.task.referenceEndDate)))])]),(_vm.options.showProgressColumn)?_c('td',{staticClass:"border text-right"},[_vm._v("\n        "+_vm._s(_vm.task.referenceProgress ? Math.round(_vm.task.referenceProgress) + '%' : '')+"\n    ")]):_vm._e(),(_vm.options.showQuantityColumn)?_c('td',{staticClass:"border"}):_vm._e(),(_vm.options.showDurationColumn)?_c('td',{staticClass:"border px-1 text-right"},[_c('span',[_vm._v("\n            "+_vm._s(_vm.task.referenceDuration === 0 || !!_vm.task.referenceDuration ? _vm.task.referenceDuration : '')+"\n        ")])]):_vm._e(),(_vm.options.showLateColumn)?_c('td',{staticClass:"border text-right px-1"},[(!_vm.task.referenceLate || _vm.task.referenceLate === 0)?_c('span',{},[_vm._v(" ")]):_c('span',{class:{
                'text-green-600 font-bold': _vm.task.referenceLate < 0,
                'text-red-600 font-bold': _vm.task.referenceLate > 0,
            }},[_vm._v("\n            "+_vm._s(_vm.task.referenceLate === 0
                    ? ''
                    : _vm.task.referenceLate > 0
                    ? '+' + _vm.task.referenceLate
                    : _vm.task.referenceLate)+"\n        ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }