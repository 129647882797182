<template>
    <div class="flex flex-col app-textarea">
        <div class="flex flex-col w-full flex-grow">
            <div class="flex flex-col w-full items-end flex-grow">
                <div class="flex flex-col w-full flex-grow">
                    <app-label :showLabel="showLabel" :label="label" :required="required" :label-for="uid"></app-label>
                    <ValidationProvider
                        :rules="rules"
                        v-slot="{ errors, classes }"
                        ref="root"
                        class="w-full validated flex flex-grow"
                    >
                        <textarea
                            class="outline-none shadow focus-within:ring-2 flex-grow"
                            :id="uid"
                            :class="{ ...classes, ...defaultClasses }"
                            :disabled.prop="disabled"
                            :name="label"
                            :required="required"
                            :value="value"
                            :placeholder="placeholder"
                            @blur="$emit('blur', $event)"
                            @click="$emit('click', $event)"
                            @mousedown="$emit('mousedown', $event)"
                            @keypress="$emit('keypress', $event)"
                            @keyup="$emit('keyup', $event)"
                            @keydown="$emit('keydown', $event)"
                            @paste="$emit('paste', $event)"
                            @input="onInput"
                            :fake="setErrors(errors)"
                            ref="input"
                        />
                    </ValidationProvider>
                </div>
                <slot name="companion"></slot>
            </div>
            <slot name="tip"></slot>
        </div>
        <app-errors :errors="errors" v-if="showErrors" />
    </div>
</template>
<script>
import AppErrors from '../appErrors/AppErrors';
import AppLabel from '../appLabel/AppLabel';
import { ValidationProvider } from 'vee-validate';
export default {
    methods: {
        setErrors(errors) {
            this.errors = errors;
        },
        onInput($event) {
            this.$emit('input', $event.target.value);
            this.$emit('rawInput', $event);
        },
        focus() {
            this.$refs.input.focus();
        },
        scrollTo() {
            this.$refs.input.scrollTo();
        },
        select() {
            this.$refs.input.select();
        },
    },
    components: { AppLabel, AppErrors, ValidationProvider },
    computed: {
        defaultClasses() {
            return {
                ' appearance-none border py-2 px-3 leading-tight w-full': true,
                'pr-8': !!this.$slots.link,
                [this.inputClass]: this.inputClass && this.inputClass.length,
            };
        },
    },
    data() {
        return {
            errors: [],
        };
    },
    props: {
        inputClass: String,
        rules: String,
        placeholder: String,
        list: String | Number,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: String | Number,
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        uid: {
            type: String | Number,
            default: function () {
                return Math.random().toString().substring(2);
            },
        },
    },
};
</script>
<style>
.app-input-text .validated input:disabled {
    box-shadow: none;
    opacity: 0.5;
}
.app-input-text .validated input.invalid {
    border-color: red;
    border-width: 2px;
}
</style>
