import {
    required,
    email,
    length,
    min,
    alpha_num,
    digits,
    numeric,
    regex,
    max,
    min_value,
    max_value,
} from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import Vue from 'vue';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize('fr', fr);

extend('email', email);
extend('required', required);
extend('min', min);
extend('length', length);
extend('alpha_num', alpha_num);
extend('digits', digits);
extend('numeric', numeric);
extend('regex', regex);
extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
