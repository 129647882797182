<template>
    <div class="text-sm p-1">
        <ServiceInfo :serviceId="service.id" :readOnly="readOnly"></ServiceInfo>
        <template v-if="service.isExecution">
            <app-separator class="my-2" :label="$t('services.locations')"></app-separator>
            <ServiceTasks :serviceId="service.id" :readOnly="readOnly"></ServiceTasks>
            <app-separator class="my-2" :label="$t('services.predecessors')"></app-separator>
            <ServicePredecessors :serviceId="service.id" :readOnly="readOnly"></ServicePredecessors>
        </template>
    </div>
</template>

<script>
import ServiceInfo from '@/features/services/ServiceInfo';
import ServiceTasks from '@/features/services/ServiceTasks';
import ServicePredecessors from '@/features/services/ServicePredecessors';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { getServices } from '@/features/services/services.service';
import { combineLatest } from 'rxjs';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';

export default {
    components: { AppSeparator, ServicePredecessors, ServiceTasks, ServiceInfo },
    props: ['serviceId'],
    async created() {
        this.subscriptions = [
            combineLatest([
                getServices(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([services, bundles]) => {
                this.services = services.map((service) => ({
                    ...service,
                    bundle: bundles.find((bundle) => bundle.id === service.bundleId),
                }));
                this.refresh();
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.project = project;
            this.readOnly = !project.me.allowedFeatures.includes('project_services');
        });
    },
    methods: {
        refresh() {
            if (this.services) {
                this.service =
                    this.services.find((service) => service.id === (this.$route.params.serviceId || this.serviceId)) ||
                    {};
            }
        },
    },
    watch: {
        $route() {
            this.refresh();
        },
        serviceId() {
            this.refresh();
        },
    },
    data() {
        return {
            service: {},
            subscriptions: [],
            services: [],
            readOnly: true,
            project: null,
        };
    },
};
</script>
