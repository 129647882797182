import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapRequests(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              obsoleteAt: item.obsoleteAt ? new Date(item.obsoleteAt) : null,
              resolvedAt: item.resolvedAt ? new Date(item.resolvedAt) : null,
              validatedMOEAt: item.validatedMOEAt ? new Date(item.validatedMOEAt) : null,
              validatedMOAAt: item.validatedMOAAt ? new Date(item.validatedMOAAt) : null,
              rejectedMOEAt: item.rejectedMOEAt ? new Date(item.rejectedMOEAt) : null,
              rejectedMOAAt: item.rejectedMOAAt ? new Date(item.rejectedMOAAt) : null,
          }
        : null;
}

export function getRequests(projectId) {
    return getCachedObservable('getRequest_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .requests.find()
            .$.pipe(map((item) => item.map(mapRequests))),
    );
}
export function removeRequest(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .requests.findOne({ selector: { id: id } })
        .remove();
}

export async function createRequest(projectId, item) {
    const result = await db.getProjectCollections(projectId).requests.insert({ ...item, projectId });
    return result.toJSON();
}

export async function updateRequest(projectId, request) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .requests.findOne({ selector: { id: request.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(request)) };
    if (request.obsoleteAt === null || !!request.obsoleteAt) {
        patch.obsoleteAt = request.obsoleteAt ? request.obsoleteAt.toISOString() : null;
    }
    if (request.resolvedAt === null || !!request.resolvedAt) {
        patch.resolvedAt = request.resolvedAt ? request.resolvedAt.toISOString() : null;
    }
    if (request.validatedMOEAt === null || !!request.validatedMOEAt) {
        patch.validatedMOEAt = request.validatedMOEAt ? request.validatedMOEAt.toISOString() : null;
    }
    if (request.validatedMOAAt === null || !!request.validatedMOAAt) {
        patch.validatedMOAAt = request.validatedMOAAt ? request.validatedMOAAt.toISOString() : null;
    }
    if (request.rejectedMOEAt === null || !!request.rejectedMOEAt) {
        patch.rejectedMOEAt = request.rejectedMOEAt ? request.rejectedMOEAt.toISOString() : null;
    }
    if (request.rejectedMOAAt === null || !!request.rejectedMOAAt) {
        patch.rejectedMOAAt = request.rejectedMOAAt ? request.rejectedMOAAt.toISOString() : null;
    }

    return dbAutoControl.atomicPatch(patch);
}

export function getRequest(projectId, requestId) {
    return db
        .getProjectCollections(projectId)
        .requests.findOne({ selector: { id: requestId } })
        .$.pipe(map(mapRequests));
}
