<template>
    <div class="flex items-start">
        <img :src="url" style="max-width: 150px; max-height: 112px" />
        <app-button
            size="mini"
            variant="danger"
            aria-label="remove photo"
            icon="icon-trash-can-outline"
            @click="$emit('delete')"
            v-if="removable"
        />
    </div>
</template>
<script>
import IconTrashCanOutline from '../../icons/IconTrashCanOutline';
import AppButton from '../appButton/AppButton';
export default {
    components: { AppButton, IconTrashCanOutline },
    props: {
        url: String,
        removable: { type: Boolean, default: true },
    },
};
</script>
