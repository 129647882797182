<template>
    <div class="w-full flex flex-col" style="min-height: 88vh">
        <div class="flex flex-wrap">
            <div class="flex flex-grow">
                <div>
                    <img :src="project.imageUrl" v-if="project.imageUrl" style="max-width: 200px; max-height: 150px" />
                </div>
                <div class="border-l-4 border-main py-2 pl-3">
                    <p class="font-bold text-xl">{{ $t('commons.project') }} {{ project.name }}</p>
                    <p class="">{{ project.address1 }}</p>
                    <p class="" v-if="project.address2">{{ project.address2 }}</p>
                    <p class="" v-if="project.address3">{{ project.address3 }}</p>
                    <p class="">{{ project.postalCode }} {{ project.city }}</p>
                </div>
            </div>
        </div>
        <div
            class="my-8 flex text-lg justify-center flex-col text-center"
            :class="{ 'flex-grow': !(meeting.showCoverPageWithPhoto && meeting.coverPhotoUrl) }"
        >
            <div class="font-bold">
                {{ $t('project.editions.meetingReports.documentName') }}
            </div>
            <div>
                {{ meeting.name }}
            </div>
            <div v-if="meeting.date">{{ meeting.date | humanizeDateFull }}</div>
        </div>
        <div v-if="nextMeeting" class="mt-4 flex text-base justify-center flex-col text-center border-2">
            <div>
                {{ $t('project.editions.meetingReports.nextMeetingTitle') }}
            </div>
            <div>{{ nextMeeting.date | humanizeTime }} {{ nextMeeting.location }}</div>
        </div>
        <template v-if="meeting.showCoverPageWithPhoto && meeting.coverPhotoUrl">
            <div class="my-4 flex flex-col items-center justify-center flex-grow">
                <img :src="meeting.coverPhotoUrl" class="max-w-2/3" style="max-height: 45vh" />
            </div>
        </template>
        <div class="flex justify-center justify-center py-4 flew-wrap" style="min-height: 15vh">
            <div class="mx-5 text-xs flex flex-col items-center" v-for="bundle in groups.MOA">
                <div style="max-width: 75px; max-height: 75px">
                    <img v-if="bundle.company.logoUrl" :src="bundle.company.logoUrl" />
                </div>
                <p class="font-bold">{{ bundle.company.name }}</p>
                <p class="">
                    {{ bundle.name }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { getMeetings } from './meetings.service';
import { updateBreadCrumbs } from '@/state/state';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import AppReportHeader from '@/components/appReportHeader/AppReportHeader';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getCompanies } from '@/features/companies/companies.service';
import { getMapById } from '@/services/sanitize.service';

export default {
    components: { AppReportHeader, AppMail, AppPhone, AppSeparator },
    async created() {
        this.subscriptions = [
            combineLatest([
                getMeetings(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
            ]).subscribe(([meetings, bundles, project, companies]) => {
                const companyMap = getMapById(companies);
                this.project = project;
                this.bundles = bundles.map((bundle) => ({ ...bundle, company: companyMap[bundle.companyId] || {} }));
                this.meeting = meetings.find((meeting) => meeting.id === this.$route.params.meetingId);
                const nextMeeting = meetings.find((meeting) => meeting.id === this.meeting.nextMeetingId);
                this.nextMeeting = nextMeeting;
                this.nextMeetings = meetings.slice(meetings.indexOf(this.meeting) + 1);
                updateBreadCrumbs({ meetingName: this.meeting.name });
                this.groups = bundlesToGroups(this.bundles);
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            groups: {
                MOA: [],
                AMOA: [],
                MOE: [],
                distributor: [],
                company: [],
            },
            bundles: [],
            nextMeeting: {},
            nextMeetings: {},
            meeting: {},
            project: {},
        };
    },
};
</script>
<style>
.vertical-text-wrapper {
    display: flex;
    width: 1.5em;
    line-height: 1.5;
}
.vertical-text:after {
    content: '';
    float: left;
    margin-top: 100%;
}
.vertical-text {
    display: inline-block;
    white-space: nowrap;
    transform: translate(0, 100%) rotate(-90deg);
    transform-origin: 0 0;
}
</style>
