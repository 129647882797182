<template>
    <ValidationObserver v-slot="{ errors, invalid, touched, dirty }" class="h-full block" ref="observer">
        <form class="flex flex-col gap-2 p-2">
            <div>
                <app-multi-picker
                    v-if="!readOnly"
                    :value="selectedLocations"
                    @check="onCheck"
                    @uncheck="onUncheck"
                    :options="locationOptions"
                    variant="button"
                    :show-label="false"
                    :show-input="false"
                    :label="$t('services.newTask')"
                    :title="$t('services.newTask')"
                >
                    <template v-slot:option="{ option }">{{ option.fullName }}</template>
                </app-multi-picker>
            </div>
            <table class="flex-grow text-sm table-fixed">
                <thead>
                    <tr class="border-b">
                        <th scope="col" class="p-2 text-left">
                            {{ $t('services.taskLocation') }}
                        </th>
                        <th
                            scope="col"
                            class="p-2 text-left"
                            :style="{
                                width: $t('services.quantity').length + (service.unit || '').length + 2 + 'ch',
                            }"
                        >
                            {{ $t('services.quantity') }} ({{ service.unit }})
                        </th>
                        <th
                            scope="col"
                            class="p-2 text-left"
                            :style="{
                                width: $t('services.duration').length + $t('commons.dayUnit').length + 4 + 'ch',
                            }"
                        >
                            {{ $t('services.duration') + ' (' + $t('commons.dayUnit') + ')' }}
                        </th>
                        <th scope="col" style="width: 35px"></th>
                    </tr>
                </thead>
                <tr v-if="tasks.length === 0">
                    <td colspan="4" class="p-2 px-4 text-sm italic text-center">
                        {{ $t('services.emptyTasks') }}
                    </td>
                </tr>
                <template v-for="task in tasks">
                    <tr :v-key="task.id" class="border-b">
                        <td class="p-2 px-4">
                            {{ task.path }}
                        </td>
                        <td class="p-2 px-4">
                            <app-number-link
                                v-model="task.quantity"
                                :disabled="readOnly"
                                format="decimal"
                                @input="onEditTask(task)"
                            >
                                <span :class="{ underline: !readOnly }">{{ $n(task.quantity, 'decimal') }}</span>
                            </app-number-link>
                        </td>
                        <td class="p-2 px-4">
                            <span v-if="task.realEndDate">{{ task.realDuration }}</span>
                            <app-number-link
                                v-else
                                v-model="task.duration"
                                :disabled="readOnly"
                                @input="onEditTask(task)"
                            ></app-number-link>
                        </td>
                        <td class="p-1 flex items-center">
                            <app-button
                                size="mini"
                                @click.prevent="onDelete(task)"
                                icon="icon-trash-can-outline"
                                variant="danger"
                                aria-label="delete task"
                                v-if="!readOnly"
                            />
                        </td>
                    </tr>
                </template>
                <tr>
                    <td class="p-2 px-4 text-sm font-bold text-right">
                        {{ $t('services.totalQuantity') }}
                    </td>
                    <td class="p-2 px-4 text-sm font-bold">{{ $n(totalQuantity, 'decimal') }} {{ service.unit }}</td>
                </tr>
            </table>
        </form>
        <tasks-cascade-popup
            :impacts="impacts"
            ref="confirmPopup"
            @deleteConfirmed="onDeleteConfirmed"
        ></tasks-cascade-popup>
    </ValidationObserver>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import AppPicker from '@/components/appPicker/AppPicker';
import AppInputText from '@/components/appInputText/AppInputText';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { sortBy } from '@/services/sanitize.service';
import AppOpenSelect from '@/components/appOpenSelect/AppOpenSelect';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import { createTask, getTasks, removeTask, updateTask } from '@/features/tasks/tasks.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest, map } from 'rxjs';
import TasksCascadePopup from '@/features/services/TasksCascadePopup';
import { getCascadeImpacts, removeCascade } from '@/features/services/taskCascades.service';
import { queryService } from '@/features/services/services.service';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        TasksCascadePopup,
        AppNumberLink,
        AppMultiPicker,
        AppOpenSelect,
        AppSaveOnLeave,
        AppInputText,
        AppPicker,
        AppButton,
    },
    props: ['serviceId', 'readOnly'],
    async created() {
        this.project = await queryProject(this.$route.params.projectId);
        this.subscriptions = [
            combineLatest([
                getLocationsTree(this.$route.params.projectId).pipe(
                    map((folders) => {
                        return folders.reduce(
                            (acc, folder) => [
                                ...acc,
                                ...folder.locations.map((location) => ({
                                    ...location,
                                    path: `${folder.name} > ${location.name}`,
                                })),
                            ],
                            [],
                        );
                    }),
                ),
                getTasks(this.$route.params.projectId),
            ]).subscribe(([locationOptions, tasks]) => {
                this.allTask = sortBy(
                    tasks
                        .filter((task) => !!task.locationId)
                        .map((task) => {
                            const location = locationOptions.find((location) => location.id === task.locationId);
                            return {
                                ...task,
                                path: location.path,
                                sortName: location.path.replace('+', 'z+'),
                            };
                        }),
                    'sortName',
                );
                this.locationOptions = locationOptions;
                return this.refresh();
            }),
        ];
    },
    watch: {
        serviceId() {
            return this.refresh();
        },
    },
    data() {
        return {
            impacts: null,
            service: {},
            subscriptions: [],
            tasks: [],
            allTask: [],
            locationOptions: [],
            project: null,
        };
    },
    methods: {
        async refresh() {
            this.tasks = this.allTask.filter((task) => task.serviceId === this.serviceId);
            this.service = await queryService(this.$route.params.projectId, this.serviceId);
        },
        onCheck(locations) {
            locations.map((location) =>
                createTask(this.$route.params.projectId, {
                    serviceId: this.serviceId,
                    duration: 1,
                    endDate: this.project.startDate,
                    startDate: this.project.startDate,
                    progress: 0,
                    quantity: 0,
                    locationId: location.id,
                }),
            );
        },
        onUncheck(locations) {
            locations.map((location) => {
                const task = this.tasks.find((task) => task.locationId === location.id);
                if (task) {
                    removeTask(this.$route.params.projectId, task.id);
                }
            });
        },
        onEditTask: function (task) {
            return updateTask(this.$route.params.projectId, {
                id: task.id,
                locationId: task.locationId,
                quantity: task.quantity,
                duration: task.duration,
            });
        },
        async onDelete(selection) {
            if (!this.readOnly) {
                this.impacts = null;
                this.$refs.confirmPopup.open();
                this.impacts = await getCascadeImpacts(this.$route.params.projectId, selection);
            }
        },
        onDeleteConfirmed() {
            if (!this.readOnly) {
                return removeCascade(this.$route.params.projectId, this.impacts);
            }
        },
    },
    computed: {
        totalQuantity() {
            return this.tasks.reduce((acc, task) => task.quantity + acc, 0);
        },
        selectedLocations() {
            return this.locationOptions.filter((location) =>
                this.tasks.find((task) => task.locationId === location.id),
            );
        },
    },
};
</script>
