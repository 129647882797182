var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-4 p-4"},[_c('div',{staticClass:"flex gap-2"},[_c('span',{staticClass:"text-xl font-bold"},[_vm._v(_vm._s(_vm.meeting.name))]),_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm._f("humanizeTime")(_vm.meeting.date)))])]),_c('div',{staticClass:"shadow-md border p-4"},[_c('table',[(_vm.meeting.location)?_c('tr',[_c('th',{staticClass:"p-2 text-right",attrs:{"role":"rowheader"}},[_vm._v(_vm._s(_vm.$t('project.follow.meetings.location'))+" :")]),_c('td',{staticClass:"p-2"},[_vm._v(_vm._s(_vm.meeting.location))])]):_vm._e(),(_vm.meeting.url && _vm.meeting.url.length > 0)?_c('tr',[_c('th',{staticClass:"p-2 text-right",attrs:{"role":"rowheader"}},[_vm._v(_vm._s(_vm.$t('project.follow.meetings.report'))+" :")]),_c('td',{staticClass:"p-2"},[_c('app-file-link',{staticClass:"text-xs mr-4",attrs:{"fileName":_vm.meeting.fileName,"url":_vm.meeting.url,"to":{
                            name: 'followMeetingReportViewerDeep',
                            params: {
                                projectId: _vm.$route.params.projectId,
                                meetingId: _vm.meeting.id,
                            },
                        }}})],1)]):_vm._e(),(_vm.meeting.convocations && _vm.meeting.convocations.length > 0)?_c('tr',[_c('th',{staticClass:"p-2 text-right align-top",attrs:{"role":"rowheader"}},[_vm._v("\n                    "+_vm._s(_vm.$t('project.follow.meetings.convocations'))+" :\n                ")]),_c('td',{staticClass:"p-2"},[_c('ul',_vm._l((_vm.meeting.convocations),function(convocation){return _c('li',{key:convocation.id,staticClass:"border p-2 flex flex-col"},[_c('div',{staticClass:"flex gap-4 justify-start"},[_c('span',[_vm._v(_vm._s(_vm._f("humanizeHour")(convocation.convocationDate)))]),_c('app-bundle',{attrs:{"bundle":convocation.bundle}})],1)])}),0)])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }