<template>
    <div>
        <table class="mx-5">
            <tbody>
                <tr>
                    <th scope="row" class="p-2 text-right">{{ $t('services.nbOfServices') }} :</th>
                    <td class="p-2">{{ servicesLength }}</td>
                </tr>
                <tr>
                    <th scope="row" class="p-2 text-right">
                        {{ $t('services.amount') }} :
                        <p class="italic font-light text-gray-700 text-xs">
                            {{ $t('services.amountTooltip') }}
                        </p>
                    </th>
                    <td class="p-2 flex">
                        {{ $n(servicesAmount, 'currency') }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { getServices } from './services.service';
export default {
    async created() {
        this.subscriptions = [
            getServices(this.$route.params.projectId).subscribe((services) => {
                this.allServices = services;
                this.refresh();
            }),
        ];
    },
    watch: {
        async $route() {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            const services = this.allServices.filter((service) => service.bundleId === this.$route.params.bundleId);
            this.servicesLength = services.length;
            this.servicesAmount = services.reduce((acc, service) => {
                return service.amount ? acc + service.amount : acc;
            }, 0);
        },
    },
    data() {
        return {
            servicesLength: 0,
            servicesAmount: 0,
            allServices: [],
            subscriptions: [],
        };
    },
};
</script>
