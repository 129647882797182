<template>
    <div class="flex flex-col">
        <label class="flex items-center p-2 border hover:bg-gray-200" :class="{ 'bg-gray-200': model === null }">
            <input
                class="ml-2 mr-4"
                type="radio"
                name="status"
                :value="null"
                v-model="model"
                @change="$emit('input', $event.target.value)"
                :disabled="disabled"
            />
            <icon-timelapse class="mr-2 text-secondary" />

            <div class="flex justify-start">
                {{ $t('project.editions.preparationList.legend.incompleteVisa') }}
            </div>
        </label>

        <label class="flex items-center p-2 border hover:bg-gray-200" :class="{ 'bg-gray-200': model === 'approved' }">
            <input
                class="ml-2 mr-4"
                type="radio"
                name="status"
                value="approved"
                v-model="model"
                @change="$emit('input', $event.target.value)"
                :disabled="disabled"
            />
            <icon-check-circle-outline class="mr-2 text-green-600" />
            <div class="flex justify-start">
                <span>{{ $t('project.editions.preparationList.legend.approved') }}</span>
            </div>
        </label>

        <label
            class="flex items-center items-center p-2 border hover:bg-gray-200"
            :class="{ 'bg-gray-200': model === 'approvedWithComments' }"
        >
            <input
                class="ml-2 mr-4"
                type="radio"
                name="status"
                value="approvedWithComments"
                v-model="model"
                @change="$emit('input', $event.target.value)"
                :disabled="disabled"
            />
            <icon-alert-circle-outline class="mr-2 text-yellow-500" />

            <div class="flex justify-start">
                <span>{{ $t('project.editions.preparationList.legend.approvedWithComments') }}</span>
            </div>
        </label>
        <label
            class="grid p-2 border hover:bg-gray-200"
            :class="{ 'bg-gray-200': model === 'rejected' }"
            style="grid-template-columns: min-content min-content auto"
        >
            <div class="flex items-center row-span-3">
                <input
                    class="ml-2 mr-4"
                    type="radio"
                    name="status"
                    value="rejected"
                    v-model="model"
                    @change="$emit('input', $event.target.value)"
                    :disabled="disabled"
                />
            </div>
            <div><icon-close-circle-outline class="mr-2 text-red-700" /></div>
            <div>
                <span>{{ $t('project.editions.preparationList.legend.rejected') }}</span>
            </div>
            <app-tips class="font-normal text-left" style="grid-column: 2 / span 2">
                {{ $t('project.editions.preparationList.legend.rejectedTips') }}
            </app-tips>
            <app-tips class="font-normal text-left" style="grid-column: 2 / span 2">
                {{ $t('project.editions.preparationList.legend.rejectedTips2') }}
            </app-tips>
        </label>
        <label
            class="grid p-2 border hover:bg-gray-200"
            :class="{ 'bg-gray-200': model === 'nonCompliant' }"
            style="grid-template-columns: min-content min-content auto"
        >
            <div class="flex items-center row-span-3">
                <input
                    class="ml-2 mr-4"
                    type="radio"
                    name="status"
                    value="nonCompliant"
                    v-model="model"
                    @change="$emit('input', $event.target.value)"
                    :disabled="disabled"
                />
            </div>
            <div><icon-close-octagon class="mr-2 text-red-700" /></div>
            <div>
                <span>{{ $t('project.editions.preparationList.legend.nonCompliant') }}</span>
            </div>
            <app-tips class="font-normal text-left" style="grid-column: 2 / span 2">
                {{ $t('project.editions.preparationList.legend.nonCompliantTips') }}
            </app-tips>
            <app-tips class="font-normal text-left" style="grid-column: 2 / span 2">
                {{ $t('project.editions.preparationList.legend.nonCompliantTips2') }}
            </app-tips>
        </label>
    </div>
</template>

<script>
import IconTimelapse from '@/icons/IconTimelapse';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline';
import AppTips from '@/components/app-tips/AppTips';
import IconAlertCircleOutline from '@/icons/IconAlertCircleOutline';
import IconCloseCircleOutline from '@/icons/IconCloseCircleOutline';
import IconCloseOctagon from '@/icons/IconCloseOctagon';
export default {
    props: {
        value: String,
        disabled: { type: Boolean, default: false },
    },
    watch: {
        value(value) {
            this.model = value;
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
    components: {
        IconCloseOctagon,
        IconCloseCircleOutline,
        IconAlertCircleOutline,
        AppTips,
        IconCheckCircleOutline,
        IconTimelapse,
    },
};
</script>
