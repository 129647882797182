<template>
    <div role="alert" class="app-mask fixed inset-0 flex bg-black" @click="$close(false)">
        <div class="app-popup m-auto bg-gray-100" @click.stop="">
            <header class="bg-red-500 text-white font-bold px-4 py-2">{{ title }}</header>
            <div class="px-4 py-3">
                <p>{{ content }}</p>
            </div>
            <footer class="flex my-3 justify-center">
                <app-button
                    :label="$t('commons.yes')"
                    variant="primary"
                    class="mx-3"
                    @click="$close(true)"
                />
            </footer>
        </div>
    </div>
</template>
<style scoped>
.app-mask {
    background-color: rgba(0, 0, 0, 0.33);
}
.app-popup {
    min-width: 240px;
}
</style>
<script>
import AppButton from '../../components/appButton/AppButton';
import IconFloppy from '../../icons/IconFloppy';
export default {
    components: { AppButton, IconFloppy },
};
</script>
