<template>
    <div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="autoControl.name"
                        class="col-span-2 md:col-span-1"
                        :required="true"
                        @blur="save()"
                    />
                    <app-input-text
                        :label="$t('autoControls.code')"
                        v-model="autoControl.code"
                        class="col-span-2 md:col-span-1"
                        @blur="save()"
                    />
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('autoControls.perimeter')">
                    <app-bundle-picker
                        class="col-span-2"
                        v-model="autoControl.bundle"
                        :options="bundles"
                        @input="onBundleChange"
                    ></app-bundle-picker>
                    <ValidationProvider v-slot="{ errors, classes }" class="col-span-2">
                        <app-multi-picker
                            :disabled="!autoControl.bundle"
                            :placeholder="$t('autoControls.AllLocations')"
                            v-model="autoControl.locations"
                            :options="locations"
                            @input="onLocationChange"
                            :show-label="true"
                            :label="$t('autoControls.locations')"
                            :title="$t('autoControls.chooseLocations')"
                            label-key="fullName"
                        ></app-multi-picker>
                        <app-errors :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors, classes }" class="col-span-2">
                        <app-multi-picker
                            :disabled="!autoControl.bundle"
                            v-model="autoControl.services"
                            @input="onServiceChange"
                            :options="services"
                            :placeholder="$t('autoControls.AllServices')"
                            :show-label="true"
                            :label="$t('autoControls.service')"
                            :title="$t('autoControls.chooseService')"
                        ></app-multi-picker>
                        <app-errors :errors="errors" />
                    </ValidationProvider>
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-5" :label="$t('autoControls.productionDates')">
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="autoControl.emissionDueDate"
                        @input="save()"
                        :label="$t('autoControls.emissionDueDate')"
                        :warning="autoControl.emissionDueDate > recommendedEmissionDate"
                    ></app-date-input>

                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="autoControl.emissionDate"
                        @input="save()"
                        :label="$t('autoControls.emissionDate')"
                    />
                    <div
                        class="col-span-2 flex items-center px-2 text-xs border"
                        v-if="lastTask && recommendedEmissionDate"
                        :class="autoControl.emissionDueDate > recommendedEmissionDate ? 'bg-yellow-100' : 'bg-green-50'"
                    >
                        {{ $t('autoControls.recommendedDate') }}
                        <app-button @click="applyRecommendedDate()" size="mini" variant="none" class="m-1">
                            {{ recommendedEmissionDate | humanizeDate }}
                        </app-button>
                    </div>
                </app-fieldset>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="() => save(true)"></app-save-on-leave>
        </ValidationObserver>
    </div>
</template>

<script>
import { getMapById, sanitize, sortBy, uniqBy } from '@/services/sanitize.service';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppInputText from '../../components/appInputText/AppInputText';
import AppButton from '../../components/appButton/AppButton';
import AppBundlePicker from '../../components/appBundlePicker';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import { getAutoControl, updateAutoControl } from './autoControls.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import locationService from '@/services/location.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getServices } from '@/features/services/services.service';
import { getTasks } from '@/features/tasks/tasks.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getPlannedTasks, getEndDate, getLastExecutionTask } from '@/features/tasks/plannedTasks.service';
export default {
    components: {
        AppSaveOnLeave,
        AppMultiPicker,
        AppBundlePicker,
        AppButton,
        AppDateInput,
        AppErrors,
        AppFooter,
        AppFieldset,
        AppInputText,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        services() {
            let result = [];
            if (this.autoControl.bundle) {
                let bundleTasks = this.tasks.filter((task) => task.service.bundleId === this.autoControl.bundle.id);
                if (this.autoControl.locations.length > 0) {
                    bundleTasks = bundleTasks.filter((task) =>
                        this.autoControl.locations.find((location) => location.id === task.locationId),
                    );
                }
                result = bundleTasks.map((task) => this.serviceMap[task.serviceId]);
            }
            return uniqBy(result, 'id');
        },
        locations() {
            if (this.autoControl.bundle) {
                let bundleTasks = this.tasks.filter((task) => task.service.bundleId === this.autoControl.bundle.id);
                return uniqBy(bundleTasks, 'locationId').map((task) => this.locationMap[task.locationId]);
            } else {
                return [];
            }
        },
        recommendedEmissionDate() {
            if (this.lastTask) {
                return getEndDate(this.lastTask, this.agenda || [], new Date());
            } else null;
        },
        lastTask() {
            if (this.planning) {
                return getLastExecutionTask(
                    this.planning,
                    this.autoControl.bundle,
                    this.autoControl.services,
                    this.autoControl.locations,
                );
            }
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())).subscribe((planning) => {
                    this.planning = planning;
                }),
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId).pipe(
                        map((folders) => locationService.getLocationMap(folders)),
                    ),
                    getAutoControl(this.$route.params.projectId, this.$route.params.autoControlId),
                    getServices(this.$route.params.projectId).pipe(map((services) => getMapById(services))),
                    getTasks(this.$route.params.projectId),
                ]).subscribe(([bundleMap, locationMap, autoControl, serviceMap, tasks]) => {
                    this.serviceMap = serviceMap;
                    this.tasks = tasks
                        .map((task) => ({ ...task, service: this.serviceMap[task.serviceId] }))
                        .filter((task) => task.service);
                    this.taskMapByServiceId = this.tasks.reduce(
                        (acc, task) => ({
                            ...acc,
                            [task.serviceId]: acc[task.serviceId] ? [...acc[task.serviceId], task] : [task],
                        }),
                        {},
                    );
                    this.locationMap = locationMap;
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.autoControl = {
                        ...autoControl,
                        bundle: bundleMap[autoControl.bundleId],
                        locations: autoControl.locationIds.map((id) => this.locationMap[id]),
                        services: autoControl.serviceIds.map((serviceId) => this.serviceMap[serviceId]),
                    };
                }),
            ];
        },
        applyRecommendedDate() {
            return updateAutoControl(this.$route.params.projectId, {
                id: this.autoControl.id,
                emissionDueDate: this.recommendedEmissionDate,
            });
        },
        onBundleChange(bundle) {
            return updateAutoControl(this.$route.params.projectId, {
                id: this.autoControl.id,
                bundleId: bundle.id,
                serviceIds: [],
                locationIds: [],
            });
        },
        onServiceChange(services) {
            return updateAutoControl(this.$route.params.projectId, {
                id: this.autoControl.id,
                serviceIds: services.map((service) => service.id),
            });
        },
        onLocationChange(locations) {
            const locationMap = getMapById(locations);
            return updateAutoControl(this.$route.params.projectId, {
                id: this.autoControl.id,
                serviceIds: this.autoControl.services
                    .filter((service) =>
                        this.taskMapByServiceId[service.id].find((task) => locationMap[task.locationId]),
                    )
                    .map((service) => service.id),
                locationIds: locations.map((location) => location.id),
            });
        },
        async save() {
            if (this.$route.params.autoControlId) {
                const autoControlEntity = {
                    id: this.$route.params.autoControlId,
                    ...sanitize(this.autoControl, ['bundle', 'locations', 'services', 'observations', 'createdAt']),
                    bundleId: this.autoControl.bundle ? this.autoControl.bundle.id : null,
                    locationIds: this.autoControl.locations.map((location) => location.id),
                    serviceIds: this.autoControl.services.map((service) => service.id),
                    emissionDueDate: this.autoControl.emissionDueDate || this.recommendedEmissionDate || null,
                };
                return updateAutoControl(this.$route.params.projectId, autoControlEntity);
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            planning: [],
            tasks: [],
            locationMap: {},
            serviceMap: {},
            bundles: [],
            autoControl: {
                name: this.$t('autoControls.newName'),
                bundle: null,
                code: '',
                services: [],
                locations: [],
                emissionDueDate: null,
                emissionDate: null,
            },
        };
    },
};
</script>
