export function isImage(url) {
    return (
        url &&
        (url.endsWith('.jpg') ||
            url.endsWith('.jpeg') ||
            url.endsWith('.png') ||
            url.endsWith('.gif') ||
            url.endsWith('.svg'))
    );
}

export function isPDF(url) {
    return url && url.endsWith('.pdf');
}
