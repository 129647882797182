<template>
    <div class="w-full m-4">
        <table class="w-full">
            <tr>
                <td colspan="2">
                    <app-separator
                        icon="icon-file-document-outline"
                        variant="underline"
                        class="font-bold my-2 mt-5"
                        :label="$t('project.editions.preparationVisa.preparationTitle')"
                    ></app-separator>
                </td>
            </tr>
            <tr v-if="preparation.code">
                <td class="p-2 text-right font-bold">{{ $t('preparations.code') }}</td>
                <td class="p-2">{{ preparation.code }}</td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('preparations.name') }}</td>
                <td class="p-2">{{ preparation.name }}</td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('preparations.type') }}</td>
                <td class="p-2">{{ preparation.type ? $t('preparations.types.' + preparation.type) : '' }}</td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('preparations.emissionDateShort') }}</td>
                <td class="p-2">{{ preparation.emissionDate | humanizeDate }}</td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('bundles.emitter') }}</td>
                <td class="p-2"><app-bundle :bundle="preparation.bundle" /></td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold align-top">{{ $t('commons.locations') }}</td>
                <td class="p-2">
                    <template v-if="preparation.locations && preparation.locations.length">
                        <div class="flex flex-col">
                            <template v-for="location in preparation.locations">
                                <span>
                                    {{ location.fullName }}
                                </span>
                            </template>
                        </div>
                    </template>
                    <template v-else>{{ $t('commons.allFemale') }}</template>
                </td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold align-top">
                    {{ $t('project.editions.preparationVisa.emittedFiles') }}
                </td>
                <td class="p-2">
                    <template v-if="preparation.attachments && preparation.attachments.length">
                        <div class="flex flex-col">
                            <template v-for="attachment in preparation.attachments">
                                <a :href="attachment.url" :download="attachment.name" class="underline">
                                    {{ attachment.name }}
                                </a>
                            </template>
                        </div>
                    </template>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <app-separator
                        icon="icon-clipboard-check"
                        variant="underline"
                        :label="$t('project.editions.preparationVisa.visa')"
                        class="font-bold my-2"
                    ></app-separator>
                </td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('bundles.emitter') }}</td>
                <td><app-bundle :bundle="visa.emitter" /></td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('preparations.emissionDateShort') }}</td>
                <td>{{ visa.emissionDate | humanizeDate }}</td>
            </tr>
            <tr>
                <td class="p-2 text-right font-bold">{{ $t('project.editions.preparationVisa.observationNumber') }}</td>
                <td>{{ observationNumber }}</td>
            </tr>
            <tr>
                <td colspan="2" class="p-8">
                    <div class="flex justify-center text-lg">
                        <div class="w-1/3 border-2 p-5 flex items-center font-bold justify-center">
                            <app-preparation-visa-status
                                class="inline-block mr-2"
                                :visa="visa"
                                :preparation-emission-date="preparation.emissionDate"
                            ></app-preparation-visa-status>
                            <span v-if="visa.conclusion === 'approved'">
                                {{ $t('preparations.conclusionValues.' + visa.conclusion) }}
                            </span>
                            <span v-if="visa.conclusion === 'rejected'">
                                {{ $t('preparations.conclusionValues.' + visa.conclusion) }}
                            </span>
                            <span v-if="visa.conclusion === 'nonCompliant'">
                                {{ $t('preparations.conclusionValues.' + visa.conclusion) }}
                            </span>
                            <span v-if="visa.conclusion === 'approvedWithComments'">
                                {{ $t('preparations.conclusionValues.' + visa.conclusion) }}
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="">
                    <preparation-visa-legend />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import AppReportHeader from '@/components/appReportHeader/AppReportHeader';
import AppBundle from '@/components/app-bundle/appBundle';
import IconCheck from '@/icons/IconCheck';
import IconCloseCircle from '@/icons/IconCloseCircle';
import IconCommentCheck from '@/icons/IconCommentCheck';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import PreparationVisaLegend from '@/features/preparations/PreparationVisaLegend';
import AppPreparationVisaStatus from '@/components/appPreparationVisaStatus/AppPreparationVisaStatus';
import { combineLatest } from 'rxjs';
import { getPreparationVisa } from '@/features/preparations/preparationVisas.service';
import { getPreparation } from '@/features/preparations/preparations.service';
import { getObservationsForRelatedContent } from '@/features/observations/observation.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { getServices } from '@/features/services/services.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getPreparationAttachments } from '@/features/preparations/preparationAttachments.service';

export default {
    components: {
        AppPreparationVisaStatus,
        PreparationVisaLegend,
        AppSeparator,
        IconCommentCheck,
        IconCloseCircle,
        IconCheck,
        AppBundle,
        AppReportHeader,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPreparationVisa(this.$route.params.projectId, this.$route.params.preparationVisaId),
                getPreparationAttachments(this.$route.params.projectId, this.$route.params.preparationId),
                getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                getObservationsForRelatedContent(this.$route.params.projectId, this.$route.params.preparationVisaId),
                getLocationsTree(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getServices(this.$route.params.projectId),
            ]).subscribe(([preparationVisa, attachments, preparation, observations, folders, bundles, services]) => {
                const locationMap = locationService.getLocationMap(folders);
                const service = services.find((service) => service.id === preparation.serviceId);
                const bundle = bundles.find((bundle) => bundle.id === preparation.bundleId);
                this.preparation = {
                    ...preparation,
                    bundle,
                    service,
                    attachments,
                    locations: preparation.locationIds.map((locationId) => locationMap[locationId]),
                };
                const emitter = bundles.find((bundle) => bundle.id === preparationVisa.emitterId);
                this.visa = { ...preparationVisa, emitter };
                this.observationNumber = observations.length;
            }),
        ];
    },
    data() {
        return {
            preparation: { service: {} },
            visa: {},
            observationNumber: 0,
        };
    },
};
</script>
