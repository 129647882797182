<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addReception()" :label="$t('receptions.newReception')" v-if="!readOnly" />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        :label="$t('commons.emitted') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="far"
                        :label="$t('project.follow.follow.far') + ' (' + farCount + ')'"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('code')"
                            >
                                {{ $t('receptions.code') }}
                                <div v-if="sortKey === 'code'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('version')"
                            >
                                {{ $t('commons.version') }}
                                <div v-if="sortKey === 'version'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                <span>{{ $t('commons.name') }}</span>
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 10rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emitter')"
                            >
                                {{ $t('bundles.emitter') }}
                                <div v-if="sortKey === 'emitter'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDueDate')"
                            >
                                {{ $t('commons.expectedOn') }}
                                <div v-if="sortKey === 'emissionDueDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDate')"
                            >
                                {{ $t('commons.emittedOn') }}
                                <div v-if="sortKey === 'emissionDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="reception in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + reception.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="reception.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(reception)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ reception.code }}
                            </td>
                            <td class="border-r p-1">
                                {{ reception.versions.length }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'followReception',
                                            params: {
                                                ...$route.params,
                                                receptionId: reception.id,
                                            },
                                        }"
                                    >
                                        {{ reception.name }}
                                        <span v-if="!reception.name || reception.name.trim().length === 0">...</span>
                                    </router-link>
                                    <div class="flex" v-if="reception.emissionDate && reception.url">
                                        <app-file-link
                                            :url="reception.url"
                                            :fileName="reception.fileName"
                                            :showName="false"
                                        ></app-file-link>
                                    </div>
                                </div>
                            </td>
                            <td class="border-r p-1 truncate hidden sm:table-cell">
                                <div v-if="reception.bundle" style="max-width: 10rem" class="truncate">
                                    <span class="">
                                        {{
                                            reception.bundle.reference
                                                ? '#' + reception.bundle.reference + ' ' + reception.bundle.name
                                                : reception.bundle.name
                                        }}
                                    </span>
                                </div>
                            </td>
                            <td
                                class="text-center border-r p-1 hidden sm:table-cell"
                                :class="{
                                    'text-red-600 font-bold':
                                        !reception.emissionDate && reception.emissionDueDate < new Date(),
                                }"
                            >
                                <app-date-link
                                    @enter="focusToNextEmissionDueDate(reception)"
                                    :ref="'emissionDueDate_' + reception.id"
                                    :disabled="readOnly"
                                    :label="$t('receptions.emissionDueDate')"
                                    :show-label="false"
                                    v-model="reception.emissionDueDate"
                                    @input="updateEmissionDueDate(reception, $event)"
                                ></app-date-link>
                            </td>
                            <td class="text-center border-r p-1 hidden sm:table-cell">
                                <app-date-link
                                    @enter="focusToNextDate(reception)"
                                    :ref="'date_' + reception.id"
                                    :disabled="readOnly"
                                    :label="$t('receptions.emissionDate')"
                                    :show-label="false"
                                    v-model="reception.emissionDate"
                                    @input="updateEmissionDate(reception, $event)"
                                ></app-date-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-bundle-picker v-model="editedReception.bundle" :options="bundles"></app-bundle-picker>

                <app-date-input
                    v-model="editedReception.emissionDueDate"
                    :label="$t('receptions.emissionDueDate')"
                ></app-date-input>
                <app-date-input v-model="editedReception.emissionDate" :label="$t('receptions.emissionDate')" />
                <app-footer @click="saveMultiple()" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import { isMobile } from '@/state/state';
import { sortBy, filterMatch } from '@/services/sanitize.service';
import { differenceInWorkingDays } from '@/services/duration.service';
import locationService from '../../services/location.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { createReception, getReceptions, removeReceptionGroup, updateReception } from './receptions.service';

import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { combineLatest } from 'rxjs';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppSelect,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppQuickActions,
        AppDateLink,
        AppFileLink,
        AppCheckbox,
        AppMultiPicker,
        AppButton,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_receptions');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'code') {
                        return item.code;
                    } else if (this.sortKey === 'version') {
                        return item.index;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    } else if (this.sortKey === 'emissionDueDate') {
                        return item.emissionDueDate;
                    } else if (this.sortKey === 'emissionDate') {
                        return item.emissionDate;
                    } else if (this.sortKey === 'emitter') {
                        return item.bundle ? item.bundle.reference + ' ' + item.bundle.name : '';
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: 'statusCriteriaType',
                    children: [
                        {
                            name: this.$t('project.follow.noEmitter'),
                            id: 'noEmitter',
                            criteriaType: '',
                            firstOfCriteriaType: false,
                            _isStatusTypeCriteriaType: true,
                        } /*
                        {
                            name: this.$t('commons.noCR'),
                            id: 'noCR',
                            criteriaType: '',
                            firstOfCriteriaType: false,
                            _isStatusTypeCriteriaType: true,
                        },*/,
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.reference ? bundle.reference + ' - ' + bundle.name : +bundle.name,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: this.locationOptions.map((location, index) => ({
                        ...location,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.locationCriteriaType'),
                        _isLocationCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getLocationsTree(this.$route.params.projectId).subscribe((folders) => {
                    this.locationOptions = locationService.buildLocationOptions(folders);
                }),
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getReceptions(this.$route.params.projectId),
                ]).subscribe(([bundleMap, receptions]) => {
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.items = receptions.map((reception) => {
                        return {
                            ...reception,
                            bundle: bundleMap[reception.bundleId],
                            filterString: [
                                reception.code,
                                reception.name,
                                reception.bundleId && bundleMap[reception.bundleId]
                                    ? bundleMap[reception.bundleId].reference + '' + bundleMap[reception.bundleId].name
                                    : '',
                            ].join(),
                            isSelected: this.selection.includes(reception.id),
                        };
                    });
                    this.doneCount = this.items.filter((item) => this.isDone(item)).length;
                    this.farCount = this.items.filter((item) => this.isFar(item)).length;
                    this.comingCount = this.items.filter((item) => !this.isDone(item) && !this.isFar(item)).length;
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        scrollToLastVisited() {
            const lastVisitedReceptionId = localStorage.getItem(
                'reception.lastVisitedReceptionId.' + this.$route.params.projectId,
            );
            if (lastVisitedReceptionId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedReceptionId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
        },
        saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            const patch = {};
            if (
                this.editedReception.emissionDueDate &&
                this.editedReception.emissionDueDate !== this.getSelectionCommonEmissionDueDate(selectedItems)
            ) {
                patch.emissionDueDate = this.editedReception.emissionDueDate;
            }
            if (
                this.editedReception.bundle &&
                this.editedReception.bundle !== this.getSelectionCommonEmitter(selectedItems)
            ) {
                patch.bundleId = this.editedReception.bundle.id;
            }
            if (
                this.editedReception.emissionDate &&
                this.editedReception.emissionDate !== this.getSelectionCommonEmissionDate(selectedItems)
            ) {
                patch.emissionDate = this.editedReception.emissionDate;
            }

            this.selection.map(async (itemId) => {
                if (Object.keys(patch).length > 0) {
                    await updateReception(this.$route.params.projectId, { id: itemId, ...patch });
                }
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeReceptionGroup(this.$route.params.projectId, item.groupId)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedReception = {
                    emissionDueDate: this.getSelectionCommonEmissionDueDate(selectedItems),
                    emissionDate: this.getSelectionCommonEmissionDate(selectedItems),
                    bundle: this.getSelectionCommonEmitter(selectedItems),
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        getSelectionCommonEmitter(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.bundle);
            if (firstItem && selectedItems.every((item) => item.bundle && item.bundle.id === firstItem.bundle.id)) {
                return firstItem.bundle;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.emissionDate && item.emissionDate.getTime() === firstItem.emissionDate.getTime(),
                )
            ) {
                return firstItem.emissionDate;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDueDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) =>
                        item.emissionDueDate && item.emissionDueDate.getTime() === firstItem.emissionDueDate.getTime(),
                )
            ) {
                return firstItem.emissionDueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'receptions.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('receptions.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addReception() {
            const result = await createReception(this.$route.params.projectId, {
                name: this.$t('receptions.newName'),
            });
            await this.$router.push({
                name: 'followReception',
                params: {
                    projectId: this.$route.params.projectId,
                    receptionId: result.id,
                },
            });
        },
        isDone(item) {
            return item.emissionDate;
        },
        isFar(item) {
            const diff = differenceInWorkingDays(
                item.emissionDueDate,
                new Date(),
                this.agenda,
                item.bundle ? item.bundle.id : null,
            );
            return !item.emissionDate && diff > 1;
        },
        matchBundleFilter(item, bundleIds) {
            return item.bundle && bundleIds.includes(item.bundle.id);
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        matchStatusFilter(item) {
            const isFar = this.isFar(item);
            const isDone = this.isDone(item);
            return (!isDone && !isFar && this.coming) || (isFar && this.far) || (isDone && this.done);
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'receptions_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    done: this.done,
                    coming: this.coming,
                    far: this.far,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('receptions_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.done = !!parsedCache.done;
                this.coming = !!parsedCache.coming;
                this.far = !!parsedCache.far;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);
            const noEmitterCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'noEmitter',
            );
            const noCRCriteria = filter.filter(
                (aCriteria) => aCriteria._isStatusTypeCriteriaType && aCriteria.id === 'noCR',
            );
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchLocationCriteria: locationService.matchLocationCriteria(
                        locationCriteria,
                        [item.zone, item.room].filter((a) => !!a),
                    ),
                    matchString: this.matchString(stringCriteria, item),
                    matchNoEmitter: this.matchNoEmitter(noEmitterCriteria, item),
                    matchNoCR: this.matchNoCR(noCRCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        matchNoEmitter(noEmitterCriteria, item) {
            return noEmitterCriteria.length === 0 || !item.bundle;
        },
        matchNoCR(noCRCriteria, item) {
            return noCRCriteria.length === 0 || !item.url;
        },
        updateEmissionDueDate(reception, date) {
            updateReception(this.$route.params.projectId, { id: reception.id, emissionDueDate: date });
        },
        updateEmissionDate(reception, date) {
            if (reception.emissionDueDate) {
                updateReception(this.$route.params.projectId, { id: reception.id, emissionDate: date });
            } else {
                updateReception(this.$route.params.projectId, {
                    id: reception.id,
                    emissionDueDate: date,
                    emissionDate: date,
                });
            }
        },
        focusToNextDate(reception) {
            let index = this.filteredItems.findIndex((aReception) => aReception.id === reception.id);
            let nextReception = this.filteredItems[index + 1];
            while (!nextReception && index < this.filteredItems.length) {
                nextReception = this.filteredItems[++index];
            }
            if (nextReception) {
                this.$emit('select', nextReception);
                const element = this.$refs['date_' + nextReception.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextEmissionDueDate(reception) {
            let index = this.filteredItems.findIndex((aReception) => aReception.id === reception.id);
            let nextReception = this.filteredItems[index + 1];
            while (!nextReception && index < this.filteredItems.length) {
                nextReception = this.filteredItems[++index];
            }
            if (nextReception) {
                this.$emit('select', nextReception);
                const element = this.$refs['emissionDueDate_' + nextReception.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            loading: true,
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            subscriptions: [],
            editedReception: {
                emissionDueDate: null,
                emissionDate: null,
                type: null,
                locations: [],
                service: null,
                bundle: null,
            },
            isMobile,
            items: [],
            bundles: [],
            receptions: [],
            agenda: [],
            done: false,
            coming: true,
            far: false,
            doneCount: 0,
            farCount: 0,
            comingCount: 0,
            filterValue: [],
            locationOptions: [],
            quickActions: [
                {
                    name: this.$t('receptions.newReception'),
                    run: () => this.addReception(),
                },
            ],
        };
    },
};
</script>
