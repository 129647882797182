<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
            <span class="text-lg">{{ preparation.code }}</span>
            <span class="text-xl font-bold">{{ preparation.name }}</span>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('commons.version') }} :</th>
                    <td class="p-2">
                        <div class="flex gap-2">
                            <app-select
                                :label="$t('commons.version')"
                                :value="preparation.id"
                                class=""
                                :show-label="false"
                                :disabled="!preparation.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in preparation.versions"
                                    :key="version.id"
                                >
                                    {{ preparation.versions.length - index }} ({{ version.createdAt | humanizeDate }})
                                </option>
                            </app-select>
                            <app-button @click="onNewVersion" :disabled="!preparation.id" v-if="isEmitter">
                                {{ $t('commons.newVersion') }}
                            </app-button>
                        </div>
                    </td>
                </tr>
                <tr v-if="preparation.type">
                    <th role="rowheader" class="p-2 text-right">{{ $t('preparations.type') }} :</th>
                    <td class="p-2">{{ $t('preparations.types.' + preparation.type) }}</td>
                </tr>

                <tr v-if="preparation.emissionDueDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('preparations.emissionDueDate') }} :</th>
                    <td class="p-2">{{ preparation.emissionDueDate | humanizeDate }}</td>
                </tr>
                <tr v-if="preparation.emissionDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('preparations.emissionDate') }} :</th>
                    <td class="p-2">{{ preparation.emissionDate | humanizeDate }}</td>
                </tr>
                <tr v-if="preparation.bundle">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.emitter') }} :</th>
                    <td class="p-2"><app-bundle :bundle="preparation.bundle" /></td>
                </tr>
                <tr v-if="preparation.locations">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('preparations.locations') }} :</th>
                    <td class="p-2 text-sm">
                        <div v-for="location in preparation.locations">
                            {{ location.fullName }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import AppSelect from '../../components/appSelect/AppSelect';
import { createPreparation, getPreparation } from './preparations.service';
import AppBundle from '@/components/app-bundle/appBundle';
import { updateBreadCrumbs } from '@/state/state';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import locationService from '@/services/location.service';
import AppButton from '@/components/appButton/AppButton.vue';
import { createPreparationVisa, getPreparationVisas } from '@/features/preparations/preparationVisas.service';

export default {
    components: {
        AppButton,
        AppBundle,
        AppSelect,
    },
    props: { isEmitter: { type: Boolean, default: false } },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getLocationsTree(this.$route.params.projectId),
                    getBundleMap(this.$route.params.projectId),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisas(this.$route.params.projectId, this.$route.params.preparationId),
                ]).subscribe(([folders, bundleMap, preparation, visas]) => {
                    const locationMap = locationService.getLocationMap(folders);
                    this.preparationVisas = visas;
                    this.preparation = {
                        ...preparation,
                        bundle: bundleMap[preparation.bundleId],
                        locations: preparation.locationIds.map((id) => locationMap[id]),
                    };
                    this.selectedVersion = this.preparation.id;
                    updateBreadCrumbs({
                        preparationName: preparation.name,
                    });
                }),
            ];
        },
        onVersionSelected(preparationId) {
            return this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId,
                },
            });
        },
        async onNewVersion() {
            const preparationEntity = {
                groupId: this.preparation.groupId,
                name: this.preparation.name,
                type: this.preparation.type,
                code: this.preparation.code,
                deliveryDuration: this.preparation.deliveryDuration,
                emissionDate: null,
                emissionDueDate: null,
                bundleId: this.preparation.bundle ? this.preparation.bundle.id : null,
                locationIds: this.preparation.locations.map((location) => location.id),
                phase: this.preparation.phase,
            };
            const result = await createPreparation(this.$route.params.projectId, preparationEntity);
            await Promise.all(
                this.preparationVisas
                    .filter((visa) => visa.emitterId)
                    .map((visa) => {
                        return createPreparationVisa(this.$route.params.projectId, {
                            emitterId: visa.emitterId,
                            preparationId: result.id,
                        });
                    }),
            );
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
    },
    data() {
        return {
            preparationVisas: [],
            subscriptions: [],
            selectedVersion: null,
            preparation: {},
        };
    },
};
</script>
