<template>
    <div style="page-break-before: always" tag="h1">
        <app-separator :label="$t('project.editions.meetingReports.taskProgress')"></app-separator>
        <div class="mt-8">
            <template v-for="firstLevelNode in taskTree">
                <app-separator variant="underline" class="w-full my-2 text-base">
                    {{ firstLevelNode.folder.name }} > {{ firstLevelNode.location.name }}
                </app-separator>
                <table class="w-full text-xs border">
                    <thead class="table-header-group font-bold">
                        <tr class="border">
                            <td class="p-1">
                                <span>{{ $t('commons.bundle') }}</span>
                            </td>
                            <td class="p-1" style="" colspan="2">{{ $t('commons.task') }}</td>
                            <td class="p-1 text-center">
                                {{ $t('project.editions.meetingReports.startDates') }}
                            </td>
                            <td class="p-1 text-center">
                                {{ $t('project.editions.meetingReports.endDates') }}
                            </td>
                            <td class="p-1 text-center">
                                {{ $t('project.editions.meetingReports.progress') }}
                            </td>
                            <td class="p-1 text-center">
                                {{ $t('project.editions.meetingReports.diff') }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="bundle in firstLevelNode.children">
                            <template v-for="(task, index) in bundle.children">
                                <tr class="border">
                                    <td class="p-1 align-top" :rowspan="bundle.children.length" v-if="index === 0">
                                        <div class="flex flex-col">
                                            <span class="font-bold">
                                                #{{ task.service.bundle.reference }} {{ task.service.bundle.name }}
                                            </span>
                                            <span>
                                                {{
                                                    task.service.bundle.company ? task.service.bundle.company.name : ''
                                                }}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="p-1 w-1/2 border">
                                        <div class="flex flex-col">
                                            <span v-if="task.path && task.path.length > 0">{{ task.path }} ></span>
                                            <span class="ml-5">{{ task.service.name }}</span>
                                        </div>
                                    </td>
                                    <td class="py-1">
                                        <div class="flex flex-col">
                                            <span
                                                class="text-right font-bold border-b border-gray-300 whitespace-nowrap"
                                            >
                                                {{ $t('project.editions.meetingReports.planned') }} :
                                            </span>
                                            <span class="text-right font-bold whitespace-nowrap">
                                                {{ $t('project.editions.meetingReports.real') }} :
                                            </span>
                                        </div>
                                    </td>
                                    <td class="py-1">
                                        <div class="flex flex-col justify-center">
                                            <span class="text-center border-b border-gray-300">
                                                {{ task.startDate | humanizeDate }}
                                            </span>
                                            <span class="text-center" v-if="task.realStartDate">
                                                {{ task.realStartDate | humanizeDate }}
                                            </span>
                                            <span class="text-center" v-else>&nbsp;</span>
                                        </div>
                                    </td>
                                    <td class="py-1">
                                        <div class="flex flex-col">
                                            <span class="text-center border-b border-gray-300">
                                                {{ task.endDate | humanizeDate }}
                                            </span>
                                            <span class="text-center" v-if="task.realEndDate || task.realStartDate">
                                                {{
                                                    (task.realEndDate
                                                        ? task.realEndDate
                                                        : task.realStartDate
                                                        ? getEndDate(task, agenda, meeting.date)
                                                        : '') | humanizeDate
                                                }}
                                            </span>
                                            <span class="text-center" v-else>&nbsp;</span>
                                        </div>
                                    </td>
                                    <td class="py-1 text-center">
                                        <div class="flex flex-col">
                                            <div class="text-center border-b border-gray-300">
                                                <span v-if="task.endDate <= meeting.date">100%</span>
                                                <span v-else-if="task.startDate > meeting.date">0%</span>
                                                <span
                                                    v-else-if="
                                                        task.startDate < meeting.date && task.endDate >= meeting.date
                                                    "
                                                >
                                                    {{
                                                        Math.round(
                                                            (getDuration(
                                                                meeting.date,
                                                                task.startDate,
                                                                agenda,
                                                                task.service.bundle.id,
                                                            ) /
                                                                task.duration) *
                                                                100,
                                                        )
                                                    }}%
                                                </span>
                                            </div>
                                            <span class="text-center">{{ task.progress }}%</span>
                                        </div>
                                    </td>
                                    <td class="py-1 text-center">
                                        <span class="font-bold text-red-700" v-if="task.late > 1">
                                            J+{{ task.late }}
                                        </span>
                                        <span v-else-if="task.late === 0" class="font-bold text-green-600">J</span>
                                        <span v-else class="font-bold text-green-600">J{{ task.late }}</span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>
<script>
import { buildTree, getMeetings, queryMeeting } from './meetings.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { getEndDate, getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getDuration } from '@/services/duration.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getDirectories } from '@/features/services/directories.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';

export default {
    components: { AppSeparator },
    async created() {
        this.meeting = await queryMeeting(this.$route.params.projectId, this.$route.params.meetingId);
        this.subscriptions = [
            combineLatest([
                getBundles(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getDirectories(this.$route.params.projectId),
                getCalendar(this.$route.params.projectId),
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getMeetings(this.$route.params.projectId),
            ]).subscribe(([bundles, folders, directories, agenda, tasks, meetings]) => {
                this.agenda = agenda;
                this.taskTree = buildTree(
                    folders,
                    bundles,
                    directories,
                    tasks.filter((task) => this.isPendingTask(task)),
                    agenda,
                    this.meeting.date,
                );
                this.previousMeeting = meetings[meetings.indexOf(this.meeting) - 1];
            }),
        ];
    },
    methods: {
        getDuration,
        getEndDate,
        isPendingTask(task) {
            const endAlreadyReported =
                this.previousMeeting && task.realEndDate && task.realEndDate < this.previousMeeting.date;
            const isPlannedAsIn = task.startDate < this.meeting.date;
            const isRealAsIn = task.realStartDate && task.realStartDate < this.meeting.date;
            const isEnded = !!task.realEndDate;
            return (!endAlreadyReported && isEnded) || (!isEnded && isPlannedAsIn) || (!isEnded && isRealAsIn);
        },
    },
    data() {
        return {
            subscriptions: [],
            taskTree: [],
            meeting: {},
            previousMeeting: null,
            agenda: [],
        };
    },
};
</script>
