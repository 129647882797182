<template>
    <div>
        <div class="border m-10">
            <div class="font-bold m-5 text-center w-full">{{ $t('commons.unknownFormat') }}</div>
            <a
                v-if="document"
                :href="document.url"
                aria-label="download document"
                class="underline flex items-center justify-center text-center w-full"
                target="_blank"
            >
                <icon-download width="16" heigth="16" class="m-2" />
                {{ document.name }}
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: Object,
    },
};
</script>
