<template>
    <div class="w-full">
        <app-separator class="cursor-pointer mb-0" @click.prevent="onToggleDetail">
            <div class="flex items-center font-normal w-full gap-3">
                <icon class="" :name="detailIsOpen ? 'icon-chevron-down' : 'icon-chevron-up'"></icon>
                <div class="flex items-center gap-2">
                    <router-link
                        :to="{
                            name: 'bundle',
                            params: { bundleId: bundle.id, projectId: $route.params.projectId },
                        }"
                        class="underline"
                    >
                        {{ bundle.name }}
                    </router-link>
                    >
                    <span v-if="structure === 'FBL' || structure === 'FLB'">
                        {{ node.location.fullName }}
                    </span>
                </div>
            </div>
        </app-separator>
        <div v-if="detailIsOpen">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import AppSeparator from '../../../components/appSeparator/AppSeparator';
import { queryPlanningState, updatePlanningState } from '@/state/state';

export default {
    components: {
        AppSeparator,
    },
    props: ['bundle', 'structure', 'node'],
    created() {
        this.detailIsOpen = queryPlanningState(this.$route.params.projectId).detailIsOpen;
    },
    methods: {
        async onToggleDetail() {
            this.detailIsOpen = !this.detailIsOpen;
            this.$nextTick(() => this.$emit('change'));
            updatePlanningState(this.$route.params.projectId, {
                detailIsOpen: this.detailIsOpen,
            });
        },
    },
    data() {
        return {
            detailIsOpen: true,
        };
    },
};
</script>
