<template>
    <div class="flex flex-col gap-4 mb-10">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2 gap-4">
                <app-date-input
                    :label="$t('project.planning.minDate')"
                    v-model="meeting.planningStartDate"
                    class="col-span-2"
                    :required="true"
                    @input="save"
                />
                <app-date-input
                    :label="$t('project.follow.planning.endDate')"
                    v-model="meeting.planningEndDate"
                    class="col-span-2"
                    :required="true"
                    @input="save"
                />
                <app-select
                    :label="$t('project.follow.planning.structure')"
                    v-model="meeting.planningStructure"
                    class="col-span-2"
                >
                    <option value="FLB">{{ $t('project.follow.planning.FLBTips') }}</option>
                    <option value="FBL">{{ $t('project.follow.planning.FBLTips') }}</option>
                    <option value="BFL">{{ $t('project.follow.planning.BFLTips') }}</option>
                    <option value="BESL">{{ $t('project.follow.planning.BESL') }}</option>
                </app-select>
                <app-select
                    :label="$t('project.follow.planning.scale')"
                    v-model="meeting.planningScale"
                    @input="save"
                    class="col-span-2"
                >
                    <option value="days">{{ $t('project.follow.planning.scaleDays') }}</option>
                    <option value="weeks">{{ $t('project.follow.planning.scaleWeeks') }}</option>
                    <option value="months">{{ $t('project.follow.planning.scaleMonths') }}</option>
                </app-select>
            </form>

            <app-save-on-leave :dirty="dirty" :saveFn="() => save(true)"></app-save-on-leave>
        </ValidationObserver>
    </div>
</template>

<script>
import { getMeeting, updateMeeting } from './meetings.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppSelect from '@/components/appSelect/AppSelect';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import MeetingPrintableReportPlanningPage from '@/features/meetings/MeetingPrintableReportPlanningPage';
export default {
    components: {
        MeetingPrintableReportPlanningPage,
        AppSaveOnLeave,
        AppSelect,
        AppDateInput,
    },
    created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
                if (!this.meeting.planningStartDate) {
                    this.meeting.planningStartDate = subDays(this.meeting.date, 7);
                }
                if (!this.meeting.planningEndDate) {
                    this.meeting.planningEndDate = addDays(this.meeting.date, 21);
                }
                if (!this.meeting.planningStructure) {
                    this.meeting.planningStructure = 'FLB';
                }
                if (!this.meeting.planningScale) {
                    this.meeting.planningScale = 'weeks';
                }
            }),
        ];
    },
    methods: {
        save() {
            updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                planningStartDate: this.meeting.planningStartDate,
                planningEndDate: this.meeting.planningEndDate,
                planningStructure: this.meeting.planningStructure,
                planningScale: this.meeting.planningScale,
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            meeting: {},
        };
    },
};
</script>
