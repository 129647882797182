<template>
    <app-select
        :label="$t('project.editions.meetingReports.attendance')"
        :show-label="false"
        v-model="convocation.attendance"
        @blur="$emit('blur', convocation)"
    >
        <option value="unknown" class="text-gray-400">
            {{ $t('project.editions.meetingReports.attendanceValues.unknown') }}
        </option>
        <option value="present">
            {{ $t('project.editions.meetingReports.attendanceValues.present') }}
        </option>
        <option value="excusedAbsent">
            {{ $t('project.editions.meetingReports.attendanceValues.excusedAbsent') }}
        </option>
        <option value="unexcusedAbsent">
            {{ $t('project.editions.meetingReports.attendanceValues.unexcusedAbsent') }}
        </option>
    </app-select>
</template>

<script>
import AppSelect from '@/components/appSelect/AppSelect';
export default {
    components: {
        AppSelect,
    },
    props: {
        convocation: Object,
    },
};
</script>
