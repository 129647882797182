var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-label',{staticClass:"app-select",attrs:{"showLabel":_vm.showLabel,"label":_vm.label,"required":_vm.required,"tag":_vm.labelTag || 'label'}},[_c('ValidationProvider',{staticClass:"relative w-full",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes }){return [_c('select',{ref:"input",staticClass:"appearance-none block w-full border-gray-200 focus:ring-2",class:{
                'cursor-pointer': !_vm.disabled,
                'shadow border': !_vm.icon,
                'bg-opacity-0 absolute': _vm.icon,
                [_vm.variantSelectClasses]: true,
                'p-2 pr-6': _vm.size !== 'mini',
                'p-1': _vm.size === 'mini',
            },staticStyle:{"background-color":"inherit"},style:(_vm.size === 'mini' ? 'max-width:25px' : ''),attrs:{"id":_vm.id,"name":_vm.name,"required":_vm.required,"disabled":_vm.disabled,"aria-label":_vm.label,"title":_vm.label},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event.target.value)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;if(!$event.ctrlKey)return null;$event.preventDefault();}}},[_vm._t("default")],2),(!_vm.icon)?_c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700",class:{ 'opacity-50': _vm.disabled, 'pr-1': _vm.size !== 'mini' }},[_c('IconChevronDown',{attrs:{"disabled":_vm.disabled}})],1):_vm._e(),(_vm.icon)?_c('div',{staticClass:"flex justify-between shadow items-center p-1",class:{ 'opacity-50': _vm.disabled, 'pr-1': _vm.size !== 'mini', ..._vm.variantTriggerClasses }},[_c('Icon',{staticClass:"m-1",attrs:{"width":"16","height":"16","name":_vm.icon,"disabled":_vm.disabled}}),_c('IconChevronDown',{attrs:{"disabled":_vm.disabled}})],1):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }