<template>
    <AppList :items="folders" @navigateTo="navigateTo" class="max-w-5xl" :removable="false" :line-height="50">
        <template v-slot:headers>
            <div class="font-bold mb-4">{{ $t('observations.tourFoldersTips') }}</div>
        </template>
        <template v-slot:item="{ item }">
            <span class="text-lg">{{ item.name }}</span>
        </template>
    </AppList>
</template>

<script>
import AppList from '@/components/appList/AppList';
import { getLocationsTree } from '@/features/locations/locations.service';

export default {
    components: { AppList },
    async created() {
        this.subscriptions = [
            getLocationsTree(this.$route.params.projectId).subscribe((folders) => {
                this.folders = folders;
            }),
        ];
    },
    methods: {
        navigateTo: function (folder) {
            this.$router.push({
                name: 'locationsTour',
                params: { projectId: this.$route.params.projectId, folderId: folder.id },
            });
        },
    },
    data() {
        return {
            folders: [],
            subscriptions: [],
        };
    },
};
</script>
