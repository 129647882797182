import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
let insertCounter = -100;
export const requests = {
    schema: {
        title: 'request schema',
        version: 0,
        description: 'describes a request',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                format: 'uuid',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'] },
            description: { type: ['string', 'null'] },
            zoneId: { type: ['string', 'null'] },
            supportId: { type: ['string', 'null'] },
            page: { type: ['number', 'null'] },
            roomId: { type: ['string', 'null'] },
            footprint: { type: ['object', 'null'] },
            index: { type: ['number', 'null'] },
            validatedMOEAt: { type: ['string', 'null'], format: 'date-time' },
            validatedMOAAt: { type: ['string', 'null'], format: 'date-time' },
            rejectedMOEAt: { type: ['string', 'null'], format: 'date-time' },
            rejectedMOAAt: { type: ['string', 'null'], format: 'date-time' },
            resolvedAt: { type: ['string', 'null'], format: 'date-time' },
            obsoleteAt: { type: ['string', 'null'], format: 'date-time' },
            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },
            deletedBy: { type: ['string', 'null'] },
            rootVersion: { type: 'string', format: 'date-time' },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert((doc) => {
                doc.index = doc.index || insertCounter--;
                preInsert(doc);
            }, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
