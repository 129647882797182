import { v4 as uuidv4 } from 'uuid';
import { importFromPaste } from '@/services/paste.service';
import { getBundleIdMapByReference } from '@/features/bundles/bundles.service';

export function importPreparations(event, projectId, phase, $t, bundles) {
    const { rows } = importFromPaste(event);
    if (rows) {
        return rows.map((row) => parsePreparation(row, projectId, phase, $t, bundles));
    }
}
export function parsePreparation(row, projectId, phase, $t, bundles) {
    const bundleMap = getBundleIdMapByReference(bundles);
    const preparation = {
        deliveryDuration: 5,
        phase,
        projectId,
        locationIds: [],
        predecessors: [],
        groupId: uuidv4(),
    };
    let rest = [];
    row.map((cell) => {
        if (bundleMap[cell]) {
            preparation.bundleId = bundleMap[cell];
        } else {
            const type = getType(cell, $t);
            if (type) {
                preparation.type = type;
            } else if (cell.length) {
                rest.push(cell);
            }
        }
    });
    preparation.name = rest.join(' - ') || 'Nouvel Element';
    if (!preparation.type) {
        const type = getTypeIncludedInName(preparation.name, $t);
        if (type) {
            preparation.type = type;
        }
    }
    return preparation;
}
const typesLabelKeys = [
    'diagnostic',
    'note',
    'other',
    'plan',
    'prototype',
    'report',
    'sample',
    'synthesis',
    'technicalDetail',
    'technicalFile',
];
function getType(cell, $t) {
    return typesLabelKeys.find((key) => {
        const label = $t('preparations.types.' + key);
        return label && cell.toLowerCase() === label.toLowerCase();
    });
}
function getTypeIncludedInName(name, $t) {
    return typesLabelKeys.find((key) => {
        const label = $t('preparations.types.' + key);
        return label && name.toLowerCase().includes(label.toLowerCase());
    });
}
