<template>
    <div class="flex flex-col gap-4 p-4">
        <div class="flex flex-col gap-4">
            <div class="flex gap-2">
                <span class="text-lg">{{ weatherIssue.date | humanizeDate }}</span>
                <span class="text-xl font-bold">{{ weatherIssue.name }}</span>
            </div>
            <div class="shadow-md border p-4">
                <table>
                    <tr>
                        <th role="rowheader" class="p-2 text-right">
                            {{ $t('project.follow.weatherIssues.duration') }} :
                        </th>
                        <td class="p-2">{{ weatherIssue.duration }}</td>
                    </tr>
                    <tr>
                        <th role="rowheader" class="p-2 text-right">
                            {{ $t('project.follow.weatherIssues.conclusion') }} :
                        </th>
                        <td class="p-2" v-if="weatherIssue.conclusion === 'validated'">
                            {{ $t('project.follow.weatherIssues.conclusions.validated') }}
                        </td>
                        <td class="p-2" v-else-if="weatherIssue.conclusion === 'rejected'">
                            {{ $t('project.follow.weatherIssues.conclusions.rejected') }}
                        </td>
                        <td class="p-2" v-else></td>
                    </tr>
                    <tr v-if="weatherIssue.emitters">
                        <th role="rowheader" class="p-2 text-right align-top">
                            {{ $t('project.follow.weatherIssues.emitters') }} :
                        </th>
                        <td class="p-2">
                            <div v-for="bundle in weatherIssue.emitters">
                                <app-bundle :bundle="bundle"></app-bundle>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import AppBundle from '@/components/app-bundle/appBundle';
export default {
    components: { AppBundle },
    props: ['weatherIssue'],
};
</script>
