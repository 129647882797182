import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import tasksService from '@/features/tasks/tasks.service';

export async function getCascadeImpacts(projectId, task) {
    const plannedTasks = await firstValueFrom(getPlannedTasks(projectId, new BehaviorSubject(new Date())));
    const fullTask = plannedTasks.find((aTask) => aTask.id === task.id);
    const removedTask = {
        ...fullTask,
        label: fullTask.location.fullName + ' > #' + fullTask.service?.bundle?.label + '>' + fullTask.name,
    };
    const linkedTasks = plannedTasks
        .filter((aTask) => {
            return aTask.predecessors.find((predecessor) => task.id === predecessor.taskId);
        })
        .map((task) => ({ ...task, label: task.location.fullName + ' > ' + task.name }));
    return {
        removedTask,
        linkedTasks,
    };
}

export function removeCascade(projectId, { removedTask, linkedTasks }) {
    return Promise.all([
        tasksService.removeTask(projectId, removedTask.id),
        ...linkedTasks.map((task) =>
            tasksService
                .filterTaskPredecessor(projectId, task, (predecessor) => removedTask.id !== predecessor.taskId)
                .catch((e) => e),
        ),
    ]);
}
