<template>
    <component v-bind:is="tag">
        <template v-for="categoryId in categoryIds">
            <template v-if="groups[categoryId].length > 0">
                <app-separator
                    variant="underline"
                    :label="$t('bundles.categories.' + categoryId)"
                    class="mt-4"
                ></app-separator>
                <template v-for="item in groups[categoryId]">
                    <slot name="item" :item="item"></slot>
                </template>
            </template>
        </template>
    </component>
</template>
<script>
import Icon from '../../icons/Icon';
import AppSeparator from '../appSeparator/AppSeparator';
export default {
    components: { AppSeparator, Icon },
    props: {
        groups: Object,
        tag: {
            type: String,
            default: 'div',
        },
    },
    data() {
        return {
            categoryIds: ['MOA', 'AMOA', 'MOE', 'generalContractor', 'distributor', 'company'],
        };
    },
};
</script>
