<template>
    <main class="m-5 h-full w-full flex flex-col md:items-start md:justify-center md:flex-row gap-y-5">
        <AppCalendar v-model="minDate" :calendar="calendar" class="md:w-2/3" />
        <AgendaForm class="mx-5 flex-grow" />
    </main>
</template>
<script>
import AppCalendar from '../../../components/appCalendar/AppCalendar';
import AgendaForm from './AgendaForm';
import { getCalendar } from './agenda.service';

export default {
    components: { AgendaForm, AppCalendar },
    created() {
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((calendar) => {
                this.calendar = calendar;
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            calendar: [],
            minDate: new Date(),
        };
    },
};
</script>
