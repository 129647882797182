<template>
    <div class="flex flex-col gap-4">
        <app-multiple-upload-button
            class="col-span-2 text-sm offline:hidden"
            icon="icon-paperclip"
            :label="$t('project.editions.meetingReports.newAttachment')"
            :end-point="`/api/projects/${$route.params.projectId}/meetings/${meeting.id}/attachments`"
            :name="$t('project.editions.meetingReports.newAttachment')"
            :resize="1024"
            @loaded="onNewAttachment"
        />
        <div class="flex flex-wrap col-span-2 gap-2">
            <template v-for="attachment in meeting.attachments">
                <app-photo :url="attachment.url" class="mr-8" @delete="onDeleteAttachment(attachment)"></app-photo>
            </template>
        </div>
    </div>
</template>

<script>
import { getMeeting, updateMeeting } from './meetings.service';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import AppPhoto from '@/components/appPhoto/AppPhoto';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
export default {
    components: {
        AppMultipleUploadButton,
        AppPhoto,
        AppUploadButton,
    },
    created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
            }),
        ];
    },
    methods: {
        onNewAttachment(attachments) {
            updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                attachments,
            });
        },
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return updateMeeting(this.$route.params.projectId, {
                    id: this.meeting.id,
                    attachments: this.meeting.attachments.filter((anAttachment) => anAttachment.url !== attachment.url),
                });
            }
        },
    },
    data() {
        return {
            meeting: {},
            subscriptions: [],
        };
    },
};
</script>
