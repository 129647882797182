<template>
    <div class="AppWarnOnLeave hidden"></div>
</template>

<script>
import { invalidForm, saveBeforeLeave } from '../features/dialogs/dialogs.service';

export default {
    components: {},
    beforeMount() {
        window.addEventListener('beforeunload', this.unload);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.unload);
    },
    methods: {
        unload(event) {
            if (this.dirty) {
                event.returnValue = this.$t('commons.unsavedWarning');
                event.preventDefault();
                return this.$t('commons.unsavedWarning');
            }
        },
        async beforeRouteLeave(saveFn, revert, next) {
            if (!this.dirty) {
                next();
            } else {
                if (
                    await saveBeforeLeave(this.$t('commons.saveBeforeLeaveTitle'), this.$t('commons.saveBeforeLeave'))
                ) {
                    if (this.invalid && this.errors) {
                        await invalidForm(this.$t('commons.invalidFormTitle'), this.$t('commons.invalidForm'));
                        next(false);
                    } else {
                        await saveFn();
                        next();
                    }
                } else {
                    await revert();
                    next();
                }
            }
        },
    },
    name: 'AppWarnOnLeave',
    props: {
        errors: [Object],
        invalid: Boolean,
        dirty: Boolean,
    },
};
</script>
