<template>
    <ValidationObserver v-slot="{ errors, invalid, touched }" class="h-full block">
        <form class="flex threeLinesMax p-2 justify-center">
            <table class="flex-grow text-xs table-fixed">
                <thead>
                    <tr class="border-b">
                        <th
                            scope="col"
                            class="pb-1 whitespace-nowrap bg-white"
                            :style="{
                                width: '92px',
                            }"
                        >
                            {{ $t('project.follow.planning.predecessorType') }}
                        </th>
                        <th
                            scope="col"
                            class="pb-1 bg-white"
                            :style="{
                                width: '72px',
                            }"
                        >
                            {{ $t('project.follow.planning.delay') }}
                        </th>
                        <th scope="col" class="pb-1 whitespace-nowrap bg-white">
                            <span>{{ $t('project.follow.planning.successors') }} ({{ successors.length }})</span>
                        </th>
                        <th
                            scope="col"
                            class="pb-1 top-0 bg-white"
                            :style="{
                                width: '70px',
                            }"
                        >
                            <span class="hidden">tools column</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="4">
                            <div class="flex justify-center">
                                <app-tips class="my-2" v-if="successors.length === 0">
                                    {{ $t('project.follow.planning.emptyPredecessors') }}
                                </app-tips>
                            </div>
                        </td>
                    </tr>
                    <template v-for="successor in taskSuccessors">
                        <tr class="border-b">
                            <td class="px-4">
                                <app-drop-down-button
                                    :label="successor.typeLabel"
                                    :show-label="false"
                                    v-if="!disabled"
                                    :disabled="successor.isServiceSuccessor"
                                    :icon="'icon-gantt' + successor.type.toLowerCase()"
                                    :title="$t('services.predecessorType' + successor.type)"
                                    @input="changeSuccessorType(successor)"
                                    v-model="successor.type"
                                >
                                    <option value="ES">
                                        {{ $t('services.predecessorTypeES') }}
                                    </option>
                                    <option value="SS">
                                        {{ $t('services.predecessorTypeSS') }}
                                    </option>
                                    <option value="EE">
                                        {{ $t('services.predecessorTypeEE') }}
                                    </option>
                                </app-drop-down-button>
                                <icon
                                    v-else
                                    :name="'icon-gantt' + successor.type.toLowerCase()"
                                    width="16"
                                    height="16"
                                    :title="$t('services.predecessorType' + successor.type)"
                                />
                            </td>
                            <td class="px-4">
                                <app-number-link
                                    v-model="successor.delay"
                                    format="integer"
                                    :positiveOnly="false"
                                    :disabled="disabled || successor.isServiceSuccessor"
                                    @input="updatePredecessor(successor)"
                                ></app-number-link>
                            </td>
                            <td class="px-2">
                                <div class="flex" v-if="successor.name">
                                    <span class="flex gap-1" :class="{ 'text-red-700': successor.isError }">
                                        {{ successor.name }}
                                        <span
                                            v-if="successors.includes(successor.taskId)"
                                            :title="$t('project.follow.planning.error.predecessor-loop')"
                                        >
                                            <icon-all-inclusive width="16" height="16" />
                                        </span>
                                    </span>
                                    <span class="flex-grow" />
                                    <span class="flex gap-1 text-xs whitespace-no-wrap">
                                        {{ $t('commons.from') }}
                                        {{ successor.startDate | humanizeDate }}
                                        {{ $t('commons.to') }}
                                        {{ successor.estimatedEndDate | humanizeDate }}
                                    </span>
                                </div>
                                <div class="text-red-700 text-xs font-bold flex" v-else>
                                    <icon-link-variant-off width="16" height="16" class="mr-2" />
                                    {{ $t('project.follow.planning.deletedPredecessor') }}
                                </div>
                            </td>
                            <td
                                class="p-1 px-2 flex items-center justify-end mr-1"
                                v-if="!successor.isServiceSuccessor"
                            >
                                <app-button
                                    size="mini"
                                    @click.prevent="removePredecessor(successor)"
                                    icon="icon-trash-can-outline"
                                    variant="danger"
                                    aria-label="delete predecessor"
                                    :disabled="disabled"
                                />
                            </td>
                            <td class="p-1 px-2 flex items-center" v-else>
                                <div :title="$t('project.follow.planning.serviceSuccessorTooltip')" class="p-1">
                                    <icon-information-outline width="16" height="16" />
                                </div>
                                <label class="px-2">
                                    <span class="hidden">
                                        {{
                                            successor.isIgnoredPredecessor
                                                ? $t('project.follow.planning.servicePredecessorConsider')
                                                : $t('project.follow.planning.servicePredecessorIgnore')
                                        }}
                                    </span>
                                    <input
                                        :disabled="disabled"
                                        type="checkbox"
                                        :checked="!successor.isIgnoredPredecessor"
                                        @click.prevent="toggleServicePredecessor(successor)"
                                        :title="
                                            successor.isIgnoredPredecessor
                                                ? $t('project.follow.planning.servicePredecessorConsider')
                                                : $t('project.follow.planning.servicePredecessorIgnore')
                                        "
                                    />
                                </label>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td colspan="4">
                            <div class="my-2 flex justify-center">
                                <app-multi-picker
                                    v-if="!disabled"
                                    :value="successors"
                                    :options="successorsOptions"
                                    size="mini"
                                    variant="button"
                                    @check="onCheckPredecessors"
                                    @uncheck="onUncheckPredecessors"
                                    :show-input="false"
                                    :show-label="false"
                                    :label="$t('project.follow.planning.newSuccessor')"
                                    icon="icon-plus-box-outline"
                                    label-key="path"
                                    class="font-normal"
                                    :disableToolTips="$t('project.follow.planning.isSuccessor')"
                                ></app-multi-picker>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </ValidationObserver>
</template>

<script>
import AppSelect from '../../../components/appSelect/AppSelect';
import { confirm } from '../../dialogs/dialogs.service';
import AppButton from '../../../components/appButton/AppButton';
import AppLabel from '../../../components/appLabel/AppLabel';
import IconLinkVariantOff from '@/icons/IconLinkVariantOff';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import tasksService, { getPredecessorIds } from '@/features/tasks/plannedTasks.service';
import AppTips from '@/components/app-tips/AppTips';
import { getMapById, sortBy } from '@/services/sanitize.service';
import { queryProject } from '@/features/projects/projects.service';
import {
    addTaskPredecessor,
    removeTaskPredecessor,
    updateTask,
    updateTaskPredecessor,
} from '@/features/tasks/tasks.service';
import { combineLatest } from 'rxjs';
import { getServices } from '@/features/services/services.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { getBundleMap } from '@/features/bundles/bundles.service';

export default {
    components: {
        AppDropDownButton,
        AppNumberLink,
        AppTips,
        AppMultiPicker,
        IconLinkVariantOff,
        AppLabel,
        AppButton,
        AppSelect,
    },
    props: {
        task: Object,
        tasks: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.disabled = !project.me.allowedFeatures.includes('project_planning');
        });
        this.subscriptions = [
            combineLatest([
                getServices(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
            ]).subscribe(([services, bundleMap]) => {
                this.serviceMap = getMapById(
                    services.map((service) => ({ ...service, bundle: bundleMap[service.bundleId] })),
                );
            }),
        ];
    },
    computed: {
        successors() {
            return tasksService.getDirectSuccessors(this.task, this.tasks);
        },
        taskSuccessors() {
            return this.successors.map((task) => {
                const predecessorConfig = task.allPredecessors.find((predecessor) => {
                    return predecessor.taskId === this.task.id;
                });
                return {
                    id: task.id,
                    name: task.location.fullName + ' > ' + task.service.bundle.name + ' > ' + task.service.name,
                    startDate: task.realStartDate || task.startDate,
                    estimatedEndDate: task.estimatedEndDate,
                    isError: task.isError,
                    delay: predecessorConfig.delay || 0,
                    type: predecessorConfig.type,
                    isServiceSuccessor: !!predecessorConfig.serviceId,
                    isIgnoredPredecessor: task.ignoredServicePredecessorIds.includes(this.task.serviceId),
                    ignoredServicePredecessorIds: task.ignoredServicePredecessorIds,
                };
            });
        },
        successorsOptions() {
            const predecessors = getPredecessorIds(this.task, this.tasks);
            const result = this.tasks.map((task) => ({
                ...task,
                disabled: predecessors.includes(task.id),
                path: task.location.fullName + ' > ' + task.service.bundle.name + ' > ' + task.service.name,
            }));
            return sortBy(result, (result) => result.path.replace('+', 'z+'));
        },
    },
    data() {
        return {
            serviceMap: [],
            disabled: true,
        };
    },
    methods: {
        changeSuccessorType(successor) {
            updateTaskPredecessor(this.$route.params.projectId, successor.id, {
                type: successor.type,
                taskId: this.task.id,
            });
        },
        onCheckPredecessors(tasks) {
            return tasks.map((task) =>
                addTaskPredecessor(this.$route.params.projectId, task.id, {
                    taskId: this.task.id,
                    delay: 0,
                    type: 'ES',
                }),
            );
        },
        onUncheckPredecessors(tasks) {
            return tasks.map((task) => removeTaskPredecessor(this.$route.params.projectId, task.id, this.task.id));
        },
        predecessorType(value) {
            return {
                EE: this.$t('project.follow.planning.predecessorTypeEE'),
                ES: this.$t('project.follow.planning.predecessorTypeES'),
                SS: this.$t('project.follow.planning.predecessorTypeEE'),
            }[value];
        },
        async removePredecessor(successor) {
            if (await confirm(this.$t('project.follow.planning.confirmPredecessorMessage'))) {
                return removeTaskPredecessor(this.$route.params.projectId, successor.id, this.task.id);
            }
        },
        async toggleServicePredecessor(successor) {
            let newIgnoredServicePredecessorIds = [...successor.ignoredServicePredecessorIds];
            if (newIgnoredServicePredecessorIds.includes(this.task.serviceId)) {
                newIgnoredServicePredecessorIds = newIgnoredServicePredecessorIds.filter(
                    (servicePredecessorId) => servicePredecessorId !== this.task.serviceId,
                );
            } else {
                newIgnoredServicePredecessorIds.push(this.task.serviceId);
            }
            await updateTask(this.$route.params.projectId, {
                id: successor.id,
                ignoredServicePredecessorIds: newIgnoredServicePredecessorIds,
            });
        },
        createPredecessor(successor) {
            return addTaskPredecessor(this.$route.params.projectId, successor.id, successor);
        },
        async updatePredecessor(successor) {
            return updateTaskPredecessor(this.$route.params.projectId, successor.id, {
                taskId: this.task.id,
                delay: successor.delay || 0,
                type: successor.type,
            });
        },
    },
};
</script>
<style scoped>
.threeLinesMax {
    max-height: 10rem;
    overflow-y: scroll;
}
</style>
