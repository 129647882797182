<template>
    <div class="flex flex-col items-center w-full text-xs gap-2 max-w-2xl">
        <app-bar-chart-table v-if="observations.length" :title="$t('observations.title')" :series="observationSeries" />
        <app-bar-chart-table
            v-if="hasOPRObservations"
            :title="$t('observations.OPRTitle')"
            :series="observationOPRSeries"
        />
        <app-bar-chart-table
            v-if="hasReceiptObservations"
            :title="$t('observations.ReceiptTitle')"
            :series="observationReceiptSeries"
        />
        <app-bar-chart-table
            v-if="hasOPLObservations"
            :title="$t('observations.OPLTitle')"
            :series="observationOPLSeries"
        />
        <app-bar-chart-table
            v-if="hasDeliveryObservations"
            :title="$t('observations.DeliveryTitle')"
            :series="observationDeliverySeries"
        />
        <app-bar-chart-table
            v-if="hasAPAObservations"
            :title="$t('observations.APATitle')"
            :series="observationAPASeries"
        />
        <app-bar-chart-table
            v-if="observations.length && hasMOE()"
            :title="$t('observations.toValidate')"
            :show-detail="false"
            :series="observationToValidateSeries"
        />

        <app-bar-chart-table v-if="supports.length" :title="$t('dashboard.supportSerie')" :series="supportSeries" />
        <app-bar-chart-table
            v-if="preparations.length && project_EXE"
            :title="$t('dashboard.preparationSerie')"
            :series="preparationSeries"
        />
        <app-bar-chart-table
            v-if="conceptions.length && project_CON"
            :title="$t('dashboard.conceptionSerie')"
            :series="conceptionsSeries"
        />
        <app-bar-chart-table
            v-if="receptions.length && project_EXE"
            :title="$t('dashboard.receptionSerie')"
            :series="receptionSeries"
        />
        <app-bar-chart-table
            v-if="autoControls.length && project_EXE"
            :title="$t('dashboard.autoControlSerie')"
            :series="autoControlSeries"
        />
        <app-bar-chart-table
            v-if="preparationVisas.length && project_EXE"
            :title="$t('dashboard.preparationVisaSerie')"
            :series="preparationVisaSeries"
        />
        <app-bar-chart-table
            v-if="certificates.length"
            :title="$t('dashboard.certificateSerie')"
            :series="certificateSeries"
        />
        <app-bar-chart-table
            v-if="moeOrMoa"
            :title="$t('dashboard.bundleAssigmentSerie')"
            :series="bundleAssigmentSeries"
        />
        <app-bar-chart-table
            v-if="tasks.length && project_EXE"
            :title="$t('dashboard.tasksSerie')"
            :series="taskSeries"
            class="max-w-2xl"
        ></app-bar-chart-table>
    </div>
</template>

<script>
import AppBarChart from '@/components/app-barChart/AppBarChart';
import { uniqBy } from '@/services/sanitize.service';
import AppBarChartTable from '@/components/appBarChartTable/AppBarChartTable';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getObservationsForGroupDashboard } from '@/features/observations/observation.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getWeatherIssues } from '@/features/planning/weatherIssues/weatherIssues.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import { getSupports } from '@/features/supports/supports.service';
import { getPreparations } from '@/features/preparations/preparations.service';
import { getCertificates } from '@/features/certificates/certificates.service';
import { getReceptions } from '@/features/receptions/receptions.service';
import { getAutoControls } from '@/features/autoControls/autoControls.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getServices } from '@/features/services/services.service';

export default {
    components: { AppBarChartTable, AppBarChart },
    props: {
        selectedBundles: { type: Array, default: () => [] },
        moeOrMoa: { type: Boolean, default: false },
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getProject(this.$route.params.projectId),
                getObservationsForGroupDashboard(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getWeatherIssues(this.$route.params.projectId),
                getAgenda(this.$route.params.projectId),
                getSupports(this.$route.params.projectId),
                getPreparations(this.$route.params.projectId, 'exe'),
                getPreparations(this.$route.params.projectId, 'con'),
                getCertificates(this.$route.params.projectId),
                getReceptions(this.$route.params.projectId),
                getAutoControls(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getServices(this.$route.params.projectId),
            ]).subscribe(
                async ([
                    tasks,
                    project,
                    observations,
                    folders,
                    weatherIssues,
                    agenda,
                    supports,
                    preparations,
                    conceptions,
                    certificates,
                    receptions,
                    autoControls,
                    bundles,
                    services,
                ]) => {
                    this.tasks = tasks;
                    this.preparations = uniqBy(preparations, 'groupId');
                    this.conceptions = uniqBy(conceptions, 'groupId');
                    this.supports = uniqBy(supports, 'groupId');
                    this.receptions = uniqBy(project.receptions, 'groupId');

                    this.project = project;
                    this.observations = observations.filter(
                        (observation) => observation.type !== 'administrative' && observation.type !== 'private',
                    );
                    this.folders = folders;
                    this.weatherIssues = weatherIssues;
                    this.agenda = agenda;
                    this.certificates = certificates;
                    this.autoControls = autoControls;
                    this.receptions = receptions;
                    this.bundles = bundles;
                    this.services = services;
                    this.me = project.me;
                },
            ),
        ];
    },
    methods: {
        hasMOE() {
            return !!this.selectedBundles.find(
                (aBundle) => aBundle.type === 'MOE' || aBundle.type === 'generalContractor',
            );
        },
        genericSeries(items) {
            const emittedOk = items.filter(
                (item) => !!item.emissionDate && (!item.emissionDueDate || item.emissionDate <= item.emissionDueDate),
            ).length;
            const emittedLate = items.filter(
                (item) => !!item.emissionDate && item.emissionDueDate && item.emissionDate > item.emissionDueDate,
            ).length;
            const expectedLate = items.filter(
                (item) => !item.emissionDate && item.emissionDueDate && item.emissionDueDate <= this.referenceDate,
            ).length;
            const coming = items.filter(
                (item) => !item.emissionDate && (!item.emissionDueDate || item.emissionDueDate > this.referenceDate),
            ).length;
            return [
                { label: this.$t('commons.emitted'), values: { ok: emittedOk, late: emittedLate } },
                { label: this.$t('dashboard.expected'), values: { ok: 0, late: expectedLate } },
                { label: this.$t('dashboard.coming'), values: { ok: coming, late: 0 } },
            ];
        },
        getObservationsSeries(observations, phase) {
            const resolvedOk = observations.filter(
                (observation) =>
                    (phase ? observation.phase === phase : true) &&
                    observation.resolvedAt &&
                    (!observation.dueDate || observation.resolvedAt <= observation.dueDate),
            );
            const resolvedLate = observations.filter(
                (observation) =>
                    (phase ? observation.phase === phase : true) &&
                    observation.resolvedAt &&
                    observation.dueDate &&
                    observation.resolvedAt > observation.dueDate,
            );
            const expectedLate = observations.filter(
                (observation) =>
                    (phase ? observation.phase === phase : true) &&
                    !observation.resolvedAt &&
                    observation.dueDate &&
                    observation.dueDate <= this.referenceDate,
            );
            const expectedOk = observations.filter(
                (observation) =>
                    (phase ? observation.phase === phase : true) &&
                    !observation.resolvedAt &&
                    (!observation.dueDate || observation.dueDate > this.referenceDate),
            );
            return [
                {
                    label: this.$t('observations.done'),
                    values: { ok: resolvedOk.length, late: resolvedLate.length },
                },
                {
                    label: this.$t('dashboard.expected'),
                    values: { ok: expectedOk.length, oks: expectedOk, late: expectedLate.length },
                },
            ];
        },
    },
    computed: {
        selectedBundleIds() {
            return this.selectedBundles.map((bundle) => bundle.id);
        },
        project_EXE() {
            return this.project.projectFeatures.includes('project_EXE');
        },
        project_CON() {
            return this.project.projectFeatures.includes('project_CON');
        },
        supportSeries() {
            return this.genericSeries(
                this.supports.filter(
                    (support) =>
                        (!support.emitterId && this.me.allowedFeatures.includes('project_supports')) ||
                        this.selectedBundleIds.includes(support.emitterId),
                ),
            );
        },
        preparationSeries() {
            return this.genericSeries(
                this.preparations.filter((preparation) => this.selectedBundleIds.includes(preparation.bundleId)),
            );
        },
        conceptionsSeries() {
            return this.genericSeries(
                this.conceptions.filter((preparation) => this.selectedBundleIds.includes(preparation.bundleId)),
            );
        },
        receptionSeries() {
            return this.genericSeries(
                this.receptions.filter((reception) => this.selectedBundleIds.includes(reception.bundleId)),
            );
        },
        autoControlSeries() {
            return this.genericSeries(
                this.autoControls.filter((autoControl) => this.selectedBundleIds.includes(autoControl.bundleId)),
            );
        },
        preparationVisaSeries() {
            return this.genericSeries(
                this.preparations.filter(
                    (preparation) =>
                        !!preparation.visas.find((visa) => this.selectedBundleIds.includes(visa.emitterId)),
                ),
            );
        },
        certificateSeries() {
            return this.genericSeries(
                this.certificates.filter((certificate) => this.selectedBundleIds.includes(certificate.emitterId)),
            );
        },
        filteredObservations() {
            return this.observations.filter((observation) =>
                observation.recipientIds.find((recipientId) => this.selectedBundleIds.includes(recipientId)),
            );
        },
        observationSeries() {
            return this.getObservationsSeries(this.filteredObservations, null);
        },
        observationOPRSeries() {
            return this.getObservationsSeries(this.filteredObservations, 'OPR');
        },
        observationDeliverySeries() {
            return this.getObservationsSeries(this.filteredObservations, 'Delivery');
        },
        observationReceiptSeries() {
            return this.getObservationsSeries(this.filteredObservations, 'Receipt');
        },
        observationOPLSeries() {
            return this.getObservationsSeries(this.filteredObservations, 'OPL');
        },
        observationAPASeries() {
            return this.getObservationsSeries(this.filteredObservations, 'APA');
        },
        observationToValidateSeries() {
            const validated = this.filteredObservations.filter(
                (observation) => observation.resolvedAt && !!observation.validatedAt,
            );
            const toValidate = this.filteredObservations.filter(
                (observation) => observation.resolvedAt && !observation.validatedAt,
            );
            return [
                { label: this.$t('observations.validated'), values: { ok: validated.length, late: 0 } },
                { label: this.$t('observations.toValidateShort'), values: { ok: toValidate.length, late: 0 } },
            ];
        },
        bundleAssigmentSeries() {
            const assigned = this.bundles.filter((bundle) => !!bundle.companyId).length;
            const expected = this.bundles.filter((bundle) => !bundle.companyId).length;
            return [
                { label: this.$t('dashboard.assigned'), values: { ok: assigned, late: 0 } },
                { label: this.$t('dashboard.expected'), values: { ok: expected, late: 0 } },
            ];
        },
        taskSeries() {
            const tasks = this.tasks.filter((task) => this.selectedBundleIds.includes(task.service.bundleId));
            const finishedOk = tasks.filter((task) => task.realEndDate && task.late <= 0);
            const finishedLate = tasks.filter((task) => task.realEndDate && task.late > 0);
            const expectedLate = tasks.filter((task) => !task.realEndDate && task.estimatedEndDate > task.endDate);
            const expectedOk = tasks.filter(
                (task) =>
                    !task.realEndDate &&
                    task.estimatedEndDate <= task.endDate &&
                    (task.realStartDate || task.startDate) <= this.referenceDate,
            );
            const coming = tasks.filter(
                (task) => !task.realEndDate && (task.realStartDate || task.startDate) > this.referenceDate,
            );
            return [
                { label: this.$t('dashboard.taskDone'), values: { ok: finishedOk.length, late: finishedLate.length } },
                {
                    label: this.$t('dashboard.expectedTask'),
                    values: { ok: expectedOk.length, late: expectedLate.length },
                },
                { label: this.$t('dashboard.coming'), values: { ok: coming.length, late: 0 } },
            ];
        },
        hasOPRObservations() {
            return !!this.filteredObservations.find((observation) => observation.phase === 'OPR');
        },
        hasDeliveryObservations() {
            return !!this.filteredObservations.find((observation) => observation.phase === 'Delivery');
        },
        hasReceiptObservations() {
            return !!this.filteredObservations.find((observation) => observation.phase === 'Receipt');
        },
        hasOPLObservations() {
            return !!this.filteredObservations.find((observation) => observation.phase === 'OPL');
        },
        hasAPAObservations() {
            return !!this.filteredObservations.find((observation) => observation.phase === 'APA');
        },
    },
    data() {
        return {
            referenceDate: new Date(),
            supports: [],
            bundles: [],
            preparations: [],
            conceptions: [],
            preparationVisas: [],
            receptions: [],
            autoControls: [],
            certificates: [],
            observations: [],
            project: {},
            tasks: [],
        };
    },
};
</script>
