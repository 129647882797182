<template>
    <div class="flex flex-col gap-2">
        <div class="flex flex-col text-xs">
            <div class="flex gap-1">
                <app-date-link
                    v-model="observation.reportedAt"
                    @input="save()"
                    :show-label="false"
                    :required="true"
                    :disabled="!isAdmin"
                />
                <span>{{ $t('project.follow.observation.createdBy') }}</span>
                <app-bundle-picker-link
                    v-model="observation.reporter"
                    @input="save()"
                    :disabled="!isAdmin && (!isReporter || !!observation.validatedAt)"
                    :limited-to-user-bundle="!isAdmin || observation.type === 'private'"
                ></app-bundle-picker-link>
                <span v-if="showPhase">{{ $t('project.follow.observation.duringPhase') }}</span>
                <select
                    v-if="showPhase"
                    class="bg-inherit appearance-none hover:appearance-auto"
                    :class="{ underline: isAdmin }"
                    v-model="observation.reportedOnPhase"
                    @change="savePhase('reportedOnPhase', $event)"
                    :disabled="!isAdmin"
                >
                    <option value="CON" :disabled="!isCONAllowed">{{ $t('commons.phases.CON') }}</option>
                    <option value="EXE" :disabled="!isEXEAllowed">{{ $t('commons.phases.EXE') }}</option>
                    <option value="OPR" :disabled="!isOPRAllowed">{{ $t('commons.phases.OPR') }}</option>
                    <option value="Receipt" :disabled="!isOPRAllowed">{{ $t('commons.phases.Receipt') }}</option>
                    <option value="OPL" :disabled="!isOPLAllowed">{{ $t('commons.phases.OPL') }}</option>
                    <option value="Delivery" :disabled="!isOPLAllowed">{{ $t('commons.phases.Delivery') }}</option>
                    <option value="APA" :disabled="!isAPAAllowed">{{ $t('commons.phases.APA') }}</option>
                </select>
            </div>
            <div class="flex gap-1" v-if="observation.resolvedAt">
                <app-date-link
                    v-model="observation.resolvedAt"
                    @input="save()"
                    :show-label="false"
                    :required="true"
                    :disabled="!isAdmin"
                />
                <span>{{ $t('project.follow.observation.resolvedBy') }}</span>
                <app-bundle-picker-link
                    v-model="observation.resolver"
                    @input="save()"
                    :disabled="!isAdmin && (!isRecipient || !!observation.validatedAt)"
                    :limited-to-user-bundle="!isAdmin"
                ></app-bundle-picker-link>
                <span v-if="showPhase">{{ $t('project.follow.observation.duringPhase') }}</span>
                <select
                    v-if="showPhase"
                    class="bg-inherit appearance-none hover:appearance-auto"
                    v-model="observation.resolvedOnPhase"
                    @change="savePhase('resolvedOnPhase', $event)"
                    :disabled="!isAdmin"
                    :class="{ underline: isAdmin }"
                >
                    <option value="CON" :disabled="!isCONAllowed">{{ $t('commons.phases.CON') }}</option>
                    <option value="EXE" :disabled="!isEXEAllowed">{{ $t('commons.phases.EXE') }}</option>
                    <option value="OPR" :disabled="!isOPRAllowed">{{ $t('commons.phases.OPR') }}</option>
                    <option value="Receipt" :disabled="!isOPRAllowed">{{ $t('commons.phases.Receipt') }}</option>
                    <option value="OPL" :disabled="!isOPLAllowed">{{ $t('commons.phases.OPL') }}</option>
                    <option value="Delivery" :disabled="!isOPLAllowed">{{ $t('commons.phases.Delivery') }}</option>
                    <option value="APA" :disabled="!isAPAAllowed">{{ $t('commons.phases.APA') }}</option>
                </select>
            </div>
            <div class="flex gap-1" v-if="!!observation.validatedAt">
                <app-date-link
                    v-model="observation.validatedAt"
                    @input="save()"
                    :show-label="false"
                    :required="true"
                    :disabled="!isAdmin"
                />
                <span>{{ $t('project.follow.observation.validatedBy') }}</span>
                <app-bundle-picker-link
                    v-model="observation.validator"
                    @input="save()"
                    :disabled="!isAdmin"
                    :limited-to-user-bundle="!isAdmin"
                ></app-bundle-picker-link>
                <span v-if="showPhase">{{ $t('project.follow.observation.duringPhase') }}</span>
                <select
                    v-if="showPhase"
                    class="bg-inherit appearance-none hover:appearance-auto"
                    :class="{ underline: isAdmin }"
                    v-model="observation.validatedOnPhase"
                    @change="savePhase('validatedOnPhase', $event)"
                    :disabled="!isAdmin"
                >
                    <option value="CON" :disabled="!isCONAllowed">{{ $t('commons.phases.CON') }}</option>
                    <option value="EXE" :disabled="!isEXEAllowed">{{ $t('commons.phases.EXE') }}</option>
                    <option value="OPR" :disabled="!isOPRAllowed">{{ $t('commons.phases.OPR') }}</option>
                    <option value="Receipt" :disabled="!isOPRAllowed">{{ $t('commons.phases.Receipt') }}</option>
                    <option value="OPL" :disabled="!isOPLAllowed">{{ $t('commons.phases.OPL') }}</option>
                    <option value="Delivery" :disabled="!isOPLAllowed">{{ $t('commons.phases.Delivery') }}</option>
                    <option value="APA" :disabled="!isAPAAllowed">{{ $t('commons.phases.APA') }}</option>
                </select>
            </div>
            <div class="flex flex-col gap-1" v-if="observation.obsoleteAt">
                <div class="flex gap-1">
                    <app-date-link
                        v-model="observation.obsoleteAt"
                        @input="save()"
                        :show-label="false"
                        :required="true"
                        :disabled="!isAdmin"
                    />
                    <span>{{ $t('project.follow.observation.obsoleteBy') }}</span>
                    <app-bundle-picker-link
                        v-model="observation.obsoleter"
                        @input="save()"
                        :disabled="!isAdmin && !!observation.validatedAt"
                        :limited-to-user-bundle="!isAdmin"
                    ></app-bundle-picker-link>
                </div>
                <div class="ml-4 flex gap-1">
                    <span class="underline" @click="$refs.obsoleteCommentInput.open()">
                        {{ $t('project.follow.observation.obsoleteComment') }} :
                    </span>
                    <app-multiline-text-link
                        ref="obsoleteCommentInput"
                        v-model="observation.obsoleteComment"
                        @input="save()"
                    ></app-multiline-text-link>
                </div>
            </div>
        </div>
        <div class="flex my-2 gap-2" v-if="isAdmin || (!isAdmin && !observation.validatedAt)">
            <app-button
                size="mini"
                icon="icon-undo"
                class="bg-orange-400 text-white"
                :label="$t('project.follow.observation.reOpen')"
                @click="onOpen"
                v-if="
                    !observation.obsoleteAt &&
                    !observation.validatedAt &&
                    observation.resolvedAt &&
                    (isRecipient || isAdmin)
                "
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                class="bg-orange-400 text-white"
                icon-class="mr-2"
                :label="$t('project.follow.observation.invalidate')"
                @click="onInvalidate"
                v-if="!observation.obsoleteAt && observation.validatedAt"
                :disabled="!isValidator"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-check"
                class="bg-green-500 text-white"
                icon-class="mr-2"
                :label="$t('project.follow.observation.resolve')"
                @click="onResolve"
                v-if="!observation.obsoleteAt && !observation.resolvedAt && (isAdmin || isRecipient)"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-check"
                class="bg-green-500 text-white"
                icon-class="mr-2"
                :label="$t('project.follow.observation.validate')"
                @click="onValidate"
                v-if="isValidator && !observation.validatedAt && !observation.obsoleteAt && observation.resolvedAt"
            ></app-button>
            <app-button
                size="mini"
                :label="$t('project.follow.observation.notObsolete')"
                @click="onNotObsolete"
                icon="icon-undo"
                v-if="observation.obsoleteAt && (isReporter || isAdmin)"
            ></app-button>
            <span class="flex-grow"></span>
            <app-button
                size="mini"
                icon="icon-timer-alert-outline"
                :label="$t('project.follow.observation.obsolete')"
                @click="obsolete"
                v-if="!observation.obsoleteAt && (isReporter || isAdmin)"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-trash-can-outline"
                variant="danger"
                @click="$emit('remove', observation)"
                :label="$t('project.follow.observation.removeShort')"
                v-if="isAdmin || isReporter"
            />
        </div>
    </div>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import AppBundlePickerLink from '@/components/appBundlePickerLink';
import AppMultilineTextLink from '@/components/appMultilineTextLink';

export default {
    components: {
        AppMultilineTextLink,
        AppBundlePickerLink,
        AppDateLink,
        AppButton,
    },
    props: {
        observation: Object,
        showPhase: { type: Boolean, default: true },
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.isValidator = project.me.allowedFeatures.includes('project_observations_MOEValidation');
            this.isEXEAllowed = project.projectFeatures.includes('project_EXE');
            this.isOPRAllowed = project.projectFeatures.includes('project_OPR');
            this.isCONAllowed = project.projectFeatures.includes('project_CON');
            this.isOPLAllowed = project.projectFeatures.includes('project_OPL');
            this.isAPAAllowed = project.projectFeatures.includes('project_OPL');
            this.me = project.me;
        });
    },
    computed: {
        isReporter() {
            return this.me && !!this.me.bundleIds.includes(this.observation.reportedBy);
        },
        isRecipient() {
            if (this.me && this.observation.recipientIds && this.observation.recipientIds.length > 0) {
                return !!this.me.bundleIds.some((bundleId) => this.observation.recipientIds.includes(bundleId));
            }
        },
    },
    methods: {
        onResolve() {
            this.resolve();
            this.save();
        },
        resolve() {
            this.observation.resolvedAt = new Date();
            this.observation.resolvedOnPhase = this.observation.phase;
            this.observation.resolvedBy = this.me.bundleIds[0];
        },
        onValidate() {
            this.validate();
            this.save();
        },
        validate() {
            this.observation.validatedAt = new Date();
            this.observation.validatedOnPhase = this.observation.phase;
            this.observation.validatedBy = this.me.bundleIds[0];
        },
        onResolveAndValidate() {
            this.resolve();
            this.validate();
            this.save();
        },
        onInvalidate() {
            this.invalidate();
            this.save();
        },
        invalidate() {
            this.observation.validatedAt = null;
            this.observation.validatedBy = null;
            this.observation.validatedOnPhase = null;
        },
        obsolete() {
            this.observation.obsoleteAt = new Date();
            this.observation.obsoleteBy = this.me.bundleIds[0];
            this.save();
        },
        onNotObsolete() {
            this.notObsolete();
            this.save();
        },
        notObsolete() {
            this.observation.obsoleteAt = null;
            this.observation.obsoleteBy = null;
            this.observation.obsoleteComment = null;
        },
        onOpen() {
            this.open();
            this.invalidate();
            this.notObsolete();
            this.save();
        },
        open() {
            this.observation.resolvedAt = null;
            this.observation.resolvedOnPhase = null;
            this.observation.resolvedBy = null;
        },
        savePhase(key, event) {
            this.$emit('input', {
                id: this.observation.id,
                [key]: event.target.value,
            });
        },
        save() {
            this.$emit('input', {
                id: this.observation.id,
                reportedBy: this.observation.reporter?.id || this.observation.reportedBy,
                reportedAt: this.observation.reportedAt,
                reportedOnPhase: this.observation.reportedOnPhase,
                obsoleteBy: this.observation.obsoleter?.id || this.observation.obsoleteBy,
                obsoleteAt: this.observation.obsoleteAt,
                obsoleteComment: this.observation.obsoleteComment,
                resolvedAt: this.observation.resolvedAt,
                resolvedOnPhase: this.observation.resolvedOnPhase,
                resolvedBy: this.observation.resolver?.id || this.observation.resolvedBy,
                validatedAt: this.observation.validatedAt,
                validatedBy: this.observation.validator?.id || this.observation.validatedBy,
                validatedOnPhase: this.observation.validatedOnPhase,
            });
        },
    },
    data() {
        return {
            isAdmin: false,
            isValidator: false,
            myBundleIds: [],
            me: null,
            isCONAllowed: false,
            isEXEAllowed: false,
            isOPRAllowed: false,
            isAPAAllowed: false,
            isOPLAllowed: false,
        };
    },
};
</script>
