import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapCheckListItem(dbEntity) {
    return dbEntity ? dbEntity.toMutableJSON() : null;
}
export function getCheckListItems(projectId) {
    return db
        .getProjectCollections(projectId)
        .checkListItems.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapCheckListItem)));
}

export async function createCheckListItem(projectId, checkListItem) {
    const result = await db.getProjectCollections(projectId).checkListItems.insert({
        ...checkListItem,
        recipientIds: checkListItem.recipientIds ? JSON.parse(JSON.stringify(checkListItem.recipientIds)) : [],
    });
    return result.toJSON();
}

export async function removeCheckListItem(projectId, checkListItemId) {
    const entity = await db
        .getProjectCollections(projectId)
        .checkListItems.findOne({ selector: { id: checkListItemId } })
        .exec();
    return entity.remove();
}

export async function filterRecipientIds(projectId, checkListItem, bundleId) {
    const dbCheckListItem = await db
        .getProjectCollections(projectId)
        .checkListItems.findOne({ selector: { id: checkListItem.id } })
        .exec();
    return dbCheckListItem.atomicPatch({
        recipientIds: dbCheckListItem.recipientIds.filter((id) => id !== bundleId),
    });
}
export default { filterRecipientIds };
