<template>
    <ReadOnlyServiceInfo v-if="readOnly" :serviceId="serviceId"></ReadOnlyServiceInfo>
    <ValidationObserver v-slot="{ invalid, dirty }" class="h-full block" ref="observer" v-else-if="service">
        <form>
            <app-fieldset :label="$t('services.info')" :show-legend="false">
                <app-input-text
                    :label="$t('commons.name')"
                    v-model="service.name"
                    class="col-span-1"
                    :required="true"
                    @blur="save"
                />
                <app-input-text
                    :label="$t('services.reference')"
                    v-model="service.reference"
                    @blur="save"
                    class="col-span-1"
                />

                <app-number-input
                    :label="$t('services.amount')"
                    v-model="service.amount"
                    class="col-span-2"
                    format="currency"
                    @blur="save"
                />
                <app-open-select
                    v-model="service.unit"
                    :size="3"
                    :label="$t('services.unit')"
                    @blur="save"
                    v-if="!readOnly"
                    class="col-span-2"
                >
                    <template v-slot:default>
                        <option style="color: initial" class="p-1">ml</option>
                        <option style="color: initial" class="p-1">m²</option>
                        <option style="color: initial" class="p-1">m³</option>
                        <option style="color: initial" class="p-1">Kg</option>
                        <option style="color: initial" class="p-1">t</option>
                        <option style="color: initial" class="p-1">ens</option>
                        <option style="color: initial" class="p-1">u</option>
                        <option style="color: initial" class="p-1">ft</option>
                    </template>
                </app-open-select>
                <div class="col-span-2">
                    <app-checkbox v-model="service.isExecution" :label="$t('services.duringExecution')" @input="save" />
                </div>
            </app-fieldset>
        </form>
        <app-save-on-leave :dirty="dirty" :saveFn="save"></app-save-on-leave>
    </ValidationObserver>
</template>

<script>
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppInputText from '../../components/appInputText/AppInputText';
import AppNumberInput from '../../components/appNumberInput/AppNumberInput';

import { queryService, updateService } from './services.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import ReadOnlyServiceInfo from './ReadOnlyServiceInfo';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppOpenSelect from '@/components/appOpenSelect/AppOpenSelect';

export default {
    components: {
        AppOpenSelect,
        AppCheckbox,
        AppSaveOnLeave,
        AppNumberInput,
        AppInputText,
        AppFieldset,
        ReadOnlyServiceInfo,
    },
    props: ['serviceId', 'readOnly'],
    data() {
        return { service: null };
    },
    created() {
        this.refresh();
    },
    watch: {
        serviceId() {
            this.refresh();
        },
    },
    methods: {
        async refresh() {
            this.service = await queryService(this.$route.params.projectId, this.serviceId);
        },
        save: function () {
            updateService(this.$route.params.projectId, {
                id: this.service.id,
                name: this.service.name,
                reference: this.service.reference,
                amount: this.service.amount,
                unit: this.service.unit,
                isExecution: this.service.isExecution,
            });
            this.$refs.observer.reset();
        },
    },
};
</script>
