<template>
    <div class="app-multi-picker">
        <app-label :showLabel="showLabel" :label="label" :required="required" />
        <app-button
            :label="label"
            v-if="variant === 'button'"
            :disabled="disabled"
            @click="onClick"
            :icon="icon"
            :size="size"
        ></app-button>
        <chips-input
            :value="internalValue"
            :disabled="disabled"
            :editable="false"
            :icon="icon"
            class="appearance-none leading-tight w-full"
            @editorInput="onEditorInput"
            @editorFocus="onEditorFocus"
            @uncheck="
                $emit('uncheck', [$event]);
                $emit('removeChips', $event);
            "
            @input="$emit('input', $event)"
            ref="trigger"
            :style="disabled ? 'backgroundColor:#efefef' : ''"
            :class="{ 'shadow border': !disabled, 'p-1': size !== 'mini', hidden: variant === 'hidden' }"
            :labelFunction="pickerLabelFunction"
            :placeholder="internalValue && internalValue.length === 0 && placeholder ? placeholder : ''"
            v-else
        />
        <slot name="tip"></slot>
        <app-autocomplete
            @close="onClose"
            :options="options"
            :allowStringCriteria="allowStringCriteria"
            ref="autocomplete"
            :labelFunction="pickerLabelFunction"
            :value="value"
            :multiple="true"
            @input="$emit('input', $event)"
            @check="$emit('check', $event)"
            @uncheck="$emit('uncheck', $event)"
            :strictMatching="strictMatching"
            :filterStringFunction="filterStringFunction"
            :disableToolTips="disableToolTips"
        >
            <template v-slot:title>
                <app-separator>
                    <h2>{{ title }}</h2>
                    <div class="flex flex-grow"></div>
                    <app-button
                        size="mini"
                        icon="icon-close"
                        aria-label="close popup"
                        @click="$refs.autocomplete.close()"
                    />
                </app-separator>
            </template>
            <template v-slot:item="{ item }">
                <slot :option="item" name="option"></slot>
            </template>
            <template v-slot:footer>
                <slot name="footer"></slot>
            </template>
        </app-autocomplete>
    </div>
</template>
<script>
import AppPicker from '../appPicker/AppPicker';
import ChipsInput from '../chips/ChipsInput';
import AppLabel from '../appLabel/AppLabel';
import AppAutocomplete from '../app-autocomplete/AppAutocomplete';
import AppSeparator from '../appSeparator/AppSeparator';
import AppButton from '../appButton/AppButton';
import AppCheckbox from '../app-checkbox/AppCheckbox';
import { getMapById } from '@/services/sanitize.service';
export default {
    components: { AppButton, AppCheckbox, AppSeparator, AppAutocomplete, AppLabel, ChipsInput, AppPicker },
    data() {
        return {
            autocompleteModel: null,
            internalValue: this.mapCurrentValueWithOptions(this.value || []),
        };
    },
    props: {
        icon: String,
        value: Object | String | Number,
        allowStringCriteria: { type: Boolean, default: false },
        disabled: Boolean,
        showLabel: {
            type: Boolean,
            default: true,
        },
        strictMatching: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: 'input',
        },
        label: String,
        placeholder: String,
        required: Boolean,
        options: {
            type: Array,
            default: () => [],
        },
        title: String,
        disableToolTips: String,
        labelKey: {
            type: String,
            default: 'name',
        },
        labelFunction: {
            type: Function,
        },
        filterStringFunction: {
            type: Function,
        },
        size: {
            type: String,
            default: '',
        },
    },
    watch: {
        value(current) {
            this.internalValue = this.mapCurrentValueWithOptions(current);
        },
    },
    methods: {
        mapCurrentValueWithOptions(current = []) {
            const flattenOptions = getMapById(
                this.options.reduce((acc, item) => (item.isGroup ? [...acc, ...item.children] : [...acc, item]), []),
            );
            return current.map((item) => flattenOptions[item.id] || item);
        },
        pickerLabelFunction(option) {
            if (option._isStringCriteria) {
                return option.name;
            } else if (this.labelFunction) {
                return this.labelFunction(option);
            } else if (this.labelKey) {
                return option[this.labelKey];
            }
        },
        onEditorInput(event) {
            this.$refs.autocomplete.open(event.trim());
            this.$refs.trigger.clearEditor();
        },
        onEditorFocus() {
            this.$refs.autocomplete.open();
        },
        onClose() {
            this.focus();
            this.$emit('close');
        },
        focus(value) {
            if (this.$refs.trigger) {
                this.$refs.trigger.focus();
            }
            if (value) {
                this.$refs.autocomplete.open(value);
            }
        },
        scrollTo() {
            this.$refs.trigger.scrollTo();
        },
        onClick() {
            this.$refs.autocomplete.open();
        },
        open() {
            this.$refs.autocomplete.open();
        },
        close() {
            this.$refs.autocomplete.close();
        },
        onKeyUp(event) {
            this.$refs.autocomplete.onTriggerKeyUp(event);
            this.$emit('keyup', event);
        },
    },
};
</script>
