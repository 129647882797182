export function updateSettings(projectId, state) {
    localStorage.setItem('zoneSettings_' + projectId, JSON.stringify(state));
}
export function getSettings(projectId) {
    const state = localStorage.getItem('zoneSettings_' + projectId);
    return state
        ? JSON.parse(state)
        : {
              viewMode: 'map',
              showEXE: true,
              showOPR: true,
              showReceipt: true,
              showOPL: true,
              showDelivery: true,
              showAPA: true,
              showToFix: true,
              showToFixLate: true,
              showObsolete: false,
              showGeneralObservations: true,
              showLocatedObservations: true,
              showValidated: false,
              selectedSupportId: null,
              showFixed: false,
              filterValue: [],
          };
}
