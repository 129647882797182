import supportsService, { getAllSupports, getSupports } from '@/features/supports/supports.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getMapById, uniqBy } from '@/services/sanitize.service';
import autoControlsService, { getAutoControls } from '@/features/autoControls/autoControls.service';
import receptionsService, { getAllReceptions, getReceptions } from '@/features/receptions/receptions.service';
import certificatesService, { getCertificates } from '@/features/certificates/certificates.service';
import observationService, { getAllObservations } from '@/features/observations/observation.service';
import observationsReportsService, {
    getAllObservationsReports,
} from '@/features/observationsReports/observationsReports.service';
import preparationsService, { getAllPreparations, getPreparations } from '@/features/preparations/preparations.service';
import tasksService from '@/features/tasks/tasks.service';
import preparationsReportsService, {
    getPreparationsReports,
} from '@/features/preparationsReports/preparationsReports.service';
import checkListsService, { getCheckListItems } from '@/features/checkLists/checkLists.service';
import directoriesService, { getDirectories } from '@/features/services/directories.service';
import meetingsService, { getMeetings } from '@/features/meetings/meetings.service';
import agendaService, { getAgenda } from '@/features/planning/agenda/agenda.service';
import preparationVisasService, { getAllPreparationVisas } from '@/features/preparations/preparationVisas.service';
import weatherIssuesService, { getWeatherIssues } from '@/features/planning/weatherIssues/weatherIssues.service';
import servicesService, { getServicesByBundleId } from '@/features/services/services.service';
import contactsService, { getContacts } from '@/features/contacts/contacts.service';
import { removeBundle } from '@/features/bundles/bundles.service';

export function getDescendants(node) {
    const child = node.locations || node.zones || node.rooms || [];
    return child.reduce((acc, children) => [...acc, children, ...getDescendants(children)], []);
}

export async function getCascadeImpacts(projectId, item) {
    const bundleId = item.id;
    const plannedTasks = await firstValueFrom(getPlannedTasks(projectId, new BehaviorSubject(new Date())));
    const directTasks = plannedTasks
        .filter((task) => task.service.bundleId === bundleId)
        .map((task) => ({
            ...task,
            label: task.location.fullName + ' > #' + task.service?.bundle?.label + '>' + task.name,
        }));
    const directTasksIds = getMapById(directTasks);
    const linkedTasks = plannedTasks
        .filter((task) => task.predecessors.find((predecessor) => directTasksIds[predecessor.taskId]))
        .map((task) => ({ ...task, label: task.location.fullName + ' > ' + task.name }));
    const services = await firstValueFrom(getServicesByBundleId(projectId, bundleId));
    const supports = (await firstValueFrom(getSupports(projectId))).filter((support) => support.emitterId === bundleId);
    const allSupportsVersions = (await firstValueFrom(getAllSupports(projectId))).filter(
        (support) => support.emitterId === bundleId,
    );
    const autoControls = (await firstValueFrom(getAutoControls(projectId))).filter(
        (element) => element.bundleId === bundleId,
    );
    const receptions = (await firstValueFrom(getReceptions(projectId))).filter(
        (element) => element.bundleId === bundleId,
    );
    const allReceptionsVersions = (await firstValueFrom(getAllReceptions(projectId))).filter(
        (element) => element.bundleId === bundleId,
    );
    const certificates = (await firstValueFrom(getCertificates(projectId))).filter(
        (element) => element.bundleId === bundleId || element.emitterId === bundleId,
    );
    const observations = (await firstValueFrom(getAllObservations(projectId)))
        .filter((observation) => observation.recipientIds.includes(bundleId) || observation.reportedBy === bundleId)
        .map((observation) => ({
            ...observation,
            label:
                '#' +
                (observation.index + 1 + '').padStart(3, '0') +
                ' ' +
                (observation.title
                    ? observation.title.substring(0, 40) + (observation.title.length > 40 ? '...' : '')
                    : ''),
        }));
    const observationsReports = (await firstValueFrom(getAllObservationsReports(projectId))).filter(
        (observationsReport) => observationsReport.bundleIds.includes(bundleId),
    );
    const preparations = (await firstValueFrom(getPreparations(projectId, 'exe'))).filter(
        (preparation) => preparation.bundleId === bundleId,
    );
    const conceptions = (await firstValueFrom(getPreparations(projectId, 'con'))).filter(
        (preparation) => preparation.bundleId === bundleId,
    );
    const allPreparationsVersions = (await firstValueFrom(getAllPreparations(projectId))).filter(
        (preparation) => preparation.bundleId === bundleId,
    );
    const preparationsReports = (await firstValueFrom(getPreparationsReports(projectId))).filter(
        (preparationsReports) => preparationsReports.bundleIds.includes(bundleId),
    );
    const checkListItems = (await firstValueFrom(getCheckListItems(projectId))).filter((checkListItem) =>
        checkListItem.recipientIds.includes(bundleId),
    );
    const directories = (await firstValueFrom(getDirectories(projectId))).filter(
        (directory) => directory.bundleId === bundleId,
    );
    const meetings = await firstValueFrom(getMeetings(projectId));
    const meetingConvocations = meetings.filter((meeting) =>
        meeting.convocations.find((convocation) => convocation.bundleId === bundleId),
    );
    const meetingRecipients = meetings.filter((meeting) => meeting.recipientIds.includes(bundleId));
    const periods = (await firstValueFrom(getAgenda(projectId))).filter((period) =>
        period.bundleIds.includes(bundleId),
    );
    const preparationVisas = (await firstValueFrom(getAllPreparationVisas(projectId))).filter(
        (preparationVisa) => preparationVisa.emitterId === bundleId,
    );
    const weatherIssues = (await firstValueFrom(getWeatherIssues(projectId))).filter((weatherIssue) =>
        weatherIssue.emitterIds.includes(bundleId),
    );
    const contacts = (await firstValueFrom(getContacts(projectId))).filter((contact) =>
        item.contactIds.includes(contact.id),
    );
    return {
        itemToRemove: item,
        conceptions,
        preparations,
        observationsReports,
        observations,
        receptions,
        autoControls,
        certificates,
        preparationsReports,
        services,
        directTasks,
        linkedTasks,
        supports,
        contacts,
        allSupportsVersions,
        allPreparationsVersions,
        allReceptionsVersions,
        checkListItems,
        directories,
        meetingConvocations,
        meetingRecipients,
        periods,
        preparationVisas,
        weatherIssues,
    };
}

export function removeCascade(
    projectId,
    {
        itemToRemove,
        observationsReports,
        observations,
        allReceptionsVersions,
        autoControls,
        certificates,
        preparationsReports,
        services,
        directTasks,
        linkedTasks,
        allSupportsVersions,
        allPreparationsVersions,
        contacts,
        checkListItems,
        directories,
        meetingConvocations,
        meetingRecipients,
        periods,
        preparationVisas,
        weatherIssues,
    },
) {
    const bundleId = itemToRemove.id;
    const toBeRemovedTaskIdMap = getMapById(directTasks);
    return Promise.all([
        removeBundle(projectId, bundleId),
        tasksService.bulkRemoveTasks(
            projectId,
            directTasks.map((task) => task.id),
        ),
        ...linkedTasks.map((task) =>
            tasksService
                .filterTaskPredecessor(projectId, task, (predecessor) => !toBeRemovedTaskIdMap[predecessor.taskId])
                .catch((e) => e),
        ),
        ...allSupportsVersions.map((support) =>
            supportsService.updateSupport(projectId, { id: support.id, emitterId: null }),
        ),
        ...preparationsReports.map((preparationsReport) =>
            preparationsReportsService.filterBundleIds(projectId, preparationsReport.id, bundleId),
        ),
        ...allPreparationsVersions.map((preparation) =>
            preparationsService.updatePreparation(projectId, { id: preparation.id, bundleId: null }),
        ),
        ...observationsReports.map((observationsReport) =>
            observationsReportsService.filterBundleIds(projectId, observationsReport, bundleId),
        ),
        ...observations.map((observation) =>
            observationService.updateObservation(projectId, {
                id: observation.id,
                reportedBy: observation.reportedBy === bundleId ? null : observation.reportedBy,
                recipientIds: observation.recipientIds.filter((id) => id !== bundleId),
            }),
        ),
        ...allReceptionsVersions.map((reception) =>
            receptionsService.updateReception(projectId, {
                id: reception.id,
                bundleId: null,
                serviceId: null,
                locationIds: [],
            }),
        ),
        ...autoControls.map((autoControl) =>
            autoControlsService.updateAutoControl(projectId, {
                id: autoControl.id,
                bundleId: null,
                serviceId: null,
                locationIds: [],
            }),
        ),
        ...certificates.map((certificate) =>
            certificatesService.updateCertificate(projectId, {
                id: certificate.id,
                bundleId: certificate.bundleId === bundleId ? null : certificate.bundleId,
                emitterId: certificate.emitterId === bundleId ? null : certificate.emitterId,
                serviceId: certificate.bundleId === bundleId ? null : certificate.serviceId,
                locationIds: certificate.bundleId === bundleId ? [] : certificate.locationIds,
            }),
        ),
        servicesService.bulkDelete(
            projectId,
            services.map((service) => service.id),
        ),
        contactsService.bulkDelete(
            projectId,
            contacts.map((contact) => contact.id),
        ),
        ...checkListItems.map((checkListItem) =>
            checkListsService.filterRecipientIds(projectId, checkListItem, bundleId),
        ),
        directoriesService.bulkDelete(
            projectId,
            directories.map((directory) => directory.id),
        ),
        ...uniqBy([...meetingConvocations, ...meetingRecipients], 'id').map((meeting) =>
            meetingsService.updateMeeting(projectId, {
                id: meeting.id,
                convocations: meeting.convocations.filter((convocation) => convocation.bundleId !== bundleId),
                recipientIds: meeting.recipientIds.filter((id) => id !== bundleId),
            }),
        ),
        ...periods.map((period) => agendaService.filterBundleIds(projectId, period, bundleId)),
        ...weatherIssues.map((weatherIssue) =>
            weatherIssuesService.filterEmitterIds(projectId, weatherIssue, bundleId),
        ),
        preparationVisasService.bulkDelete(
            projectId,
            preparationVisas.map((preparationVisa) => preparationVisa.id),
        ),
    ]);
}
