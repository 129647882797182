<template>
    <main class="max-w-5xl m-5 h-full w-full">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form @submit.prevent="">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="reference.name"
                        class="col-span-2"
                        :required="true"
                        :disabled="readOnly"
                        @blur="save"
                    />
                    <app-date-input
                        :label="$t('project.follow.reference.date')"
                        :value="reference.createdAt"
                        class="col-span-2"
                        :disabled="true"
                        @input="save"
                    />
                </app-fieldset>
            </form>

            <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        </ValidationObserver>
    </main>
</template>
<script>
import AppDateInput from '../../../components/appDateInput/AppDateInput';
import AppFieldset from '../../../components/appFieldset/AppFieldset';
import AppInputText from '../../../components/appInputText/AppInputText';
import { updateBreadCrumbs } from '@/state/state';
import { getReference, updateReference } from './references.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppSaveOnLeave,
        AppInputText,
        AppFieldset,
        AppDateInput,
    },
    created() {
        this.init();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
    },
    methods: {
        init() {
            this.subscriptions = [
                getReference(this.$route.params.projectId, this.$route.params.referenceId).subscribe((reference) => {
                    this.reference = reference;
                    updateBreadCrumbs({
                        referenceName: this.reference.name,
                    });
                }),
            ];
        },
        save() {
            if (!this.readOnly) {
                return updateReference(this.$route.params.projectId, {
                    id: this.reference.id,
                    name: this.reference.name,
                });
            }
        },
    },
    data() {
        return {
            reference: {},
            readOnly: true,
        };
    },
};
</script>
