import { getAPIHeaders } from '@/services/api.service';

function mapContract(item) {
    return item
        ? {
              ...item,
              proposedAt: item.proposedAt ? new Date(item.proposedAt) : null,
              signedAt: item.signedAt ? new Date(item.signedAt) : null,
              validatedAt: item.validatedAt ? new Date(item.validatedAt) : null,
              expireAt: item.expireAt ? new Date(item.expireAt) : null,
              createdAt: item.createdAt ? new Date(item.createdAt) : null,
              createdBy: item.createdBy ? new Date(item.createdBy) : null,
              updatedAt: item.updatedAt ? new Date(item.updatedAt) : null,
              updatedBy: item.updatedBy ? new Date(item.updatedBy) : null,
          }
        : null;
}

export async function removeContract(contract) {
    const response = await fetch(
        new Request(`/api/contracts/${contract.id}`, {
            method: 'DELETE',
            headers: getAPIHeaders(),
        }),
    );
    return response.json();
}

export async function createContract(contractorEmail) {
    const response = await fetch(
        new Request(`/api/contracts`, {
            method: 'POST',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify({ email: contractorEmail }),
        }),
    );
    if (response && response.ok) {
        return response.json();
    } else {
        throw new Error('replication pull failed');
    }
}

export async function updateContract(contract) {
    const response = await fetch(
        new Request(`/api/contracts/${contract.id}`, {
            method: 'PUT',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(contract),
        }),
    );
    return mapContract(await response.json());
}
export async function generateContractTemplate(contract) {
    const response = await fetch(
        new Request(`/api/contracts/${contract.id}/templateFile`, {
            method: 'PUT',
            headers: getAPIHeaders(),
        }),
    );
    return mapContract(await response.json());
}

export async function getContracts() {
    const response = await fetch(`/api/contracts`, {
        headers: getAPIHeaders(),
    });
    return (await response.json()).map(mapContract);
}
export async function getContract(contractId) {
    const response = await fetch(`/api/contracts/${contractId}`, {
        headers: getAPIHeaders(),
    });
    return mapContract(await response.json());
}
export async function getContractProjects(contractId) {
    const response = await fetch(`/api/contracts/${contractId}/projects`, {
        headers: getAPIHeaders(),
    });
    return response.json();
}

export async function updatePrices(contractId, prices) {
    const response = await fetch(
        new Request(`/api/contracts/${contractId}/prices`, {
            method: 'PUT',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify(prices),
        }),
    );
    return response.json();
}

export async function getPrices(contractId) {
    const response = await fetch(
        new Request(`/api/contracts/${contractId}/prices`, {
            headers: getAPIHeaders(),
        }),
    );
    return response.json();
}
