<template>
    <div>
        <chartist
            :ratio="isMobile ? 'ct-square' : 'ct-double-octave'"
            type="Line"
            :event-handlers="eventHandlers"
            :data="config.data"
            :options="config.options"
        ></chartist>
    </div>
</template>
<script>
import { isMobile } from '@/state/state';

export default {
    props: {
        config: {
            type: Object,
            default() {
                return {
                    data: {
                        labels: [1, 2, 3, 4, 5, 6, 7, 8],
                        series: [[5, 9, 7, 8, 5, 3, 5, 4]],
                    },
                    options: { low: 0, showArea: true },
                };
            },
        },
        eventHandlers: Array,
    },
    data() {
        return {
            isMobile,
        };
    },
};
</script>
