var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{ref:"button",staticClass:"flex items-center disabled:opacity-50 focus:outline-none focus:ring",class:{
        'py-2 px-4': _vm.size === 'medium' || _vm.size === '',
        'p-1 text-xs': _vm.size === 'mini',
        'text-sm': _vm.size !== 'mini',
        'primary bg-main  text-white': _vm.variant === 'primary',
        default: _vm.variant === 'default',
        danger: _vm.variant === 'danger',
        light: _vm.variant === 'light',
        'border-2 font-bold': _vm.variant !== 'borderless',
        selected: _vm.variant === 'selected',
    },attrs:{"aria-label":_vm.ariaLabel,"type":_vm.type},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('click', $event)},"focus":function($event){return _vm.$emit('focus', $event)}}},[(_vm.icon)?_c('Icon',{class:{ 'mr-3': !_vm.iconClass && _vm.label, [_vm.iconClass]: _vm.iconClass },attrs:{"name":_vm.icon,"width":_vm.size === 'mini' ? '16px' : '24px',"height":_vm.size === 'mini' ? '16px' : '24px'}}):_vm._e(),(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }