<template>
    <chartist ratio="" type="Bar" :data="data" :options="options" class="observation-bar"></chartist>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({
                labels: [1, 2, 3, 4, 5, 6, 7],
                series: [
                    [1, 3, 2, -5, -3, 1, -6],
                    [-5, -2, -4, -1, 2, -3, 1],
                ],
            }),
        },
    },
    mounted() {
        this.options.width = this.$el.offsetWidth;
        this.options.height = this.data?.labels?.length * 25;
    },
    watch: {
        data() {
            this.options.height = this.data?.labels?.length * 25;
        },
    },
    data: function () {
        return {
            options: {
                stackBars: true,
                horizontalBars: true,
                axisX: { scaleMinSpace: 20, onlyInteger: true },
                axisY: {
                    scaleMinSpace: 5,
                    showGrid: false,
                    offset: 200,
                },
                width: 800,
                height: 600,
            },
        };
    },
};
</script>
<style>
.observation-bar .ct-grid {
    stroke-dasharray: none;
}
.observation-bar .ct-bar {
    stroke-width: 15px;
}
.observation-bar .ct-series-a .ct-bar {
    stroke: #feb2b2; /* red-300 */
}
.observation-bar .ct-series-b .ct-bar {
    stroke: rgba(220, 38, 38); /* red-600 */
}
.observation-bar .ct-series-c .ct-bar {
    stroke: #9ae6b4; /* green-300 */
}
.observation-bar .ct-series-d .ct-bar {
    stroke: #48bb78; /* green-500 */
}
.observation-bar .ct-label {
    color: rgba(0, 0, 0, 0.8);
}
</style>
