import safeHtml from 'safe-html';
import { safeHTMLConfig as htmlConfig, importFromHTML } from './pasteHTMLServices.service';
import {
    safeHTMLConfig as msConfig,
    importFromHTML as importFromMsProjectHTML,
} from './pasteMsProjectServices.service';

export function importServices(event) {
    let htmlType;
    let plainType;
    event.clipboardData.types.forEach(function (type) {
        if (type.match('^text/html')) {
            htmlType = type;
        }
        if (type.match('^text/plain')) {
            plainType = type;
        }
    });
    if (htmlType) {
        event.preventDefault();
        const data = event.clipboardData.getData(htmlType);
        if (data.includes('<meta name=Generator content="Microsoft Project">')) {
            const cleanHTML = cleanUpInput(data, msConfig);
            return importFromMsProjectHTML(cleanHTML);
        } else {
            const cleanHTML = cleanUpInput(data, htmlConfig);
            return importFromHTML(cleanHTML);
        }
    } else if (plainType) {
        event.preventDefault();
        return event.clipboardData.getData(plainType).split('\n');
    }
}

export function cleanUpInput(data, config) {
    return safeHtml(data, config).trim();
}
