<template>
    <main class="w-full flex min-h-main">
        <app-img-viewer v-if="isImage" :src="support.url" />
        <iframe v-else-if="isPDF" :src="support.url" class="w-full min-h-screen my-5"></iframe>
        <AppUnknownViewer v-else-if="!loading" :document="support" class="h-full flex-grow" />
        <div class="w-full justify-center flex" v-if="loading">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
    </main>
</template>

<script>
import AppImgViewer from '../../components/app-img-viewer/AppImgViewer';
import { isImage, isPDF } from '@/services/file.service';
import AppUnknownViewer from '../../components/appUnkownViewer/AppUnknownViewer';
import { getSupport } from './supports.service';
import AppButton from '@/components/appButton/AppButton';
import IconRotateRight from '@/icons/IconRotateRight';
import { updateBreadCrumbs } from '@/state/state';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';

export default {
    components: { AppLeafletViewer, IconRotateRight, AppButton, AppUnknownViewer, AppImgViewer },

    async created() {
        this.loading = true;
        this.subscriptions = [
            getSupport(this.$route.params.projectId, this.$route.params.supportId).subscribe((support) => {
                this.support = support;
                updateBreadCrumbs({
                    supportName: this.support.name,
                });
                this.loading = false;
            }),
        ];
    },
    data() {
        return {
            loading: true,
            support: {},
            subscriptions: {},
        };
    },
    computed: {
        isImage: function () {
            return isImage(this.support.url);
        },
        isPDF: function () {
            return isPDF(this.support.url);
        },
    },
};
</script>
