import { handleDirectories } from './tree';

function getFolderNode(folder) {
    return { id: folder.id, name: folder.name, children: [], class: 'font-bold' };
}
function getLocationNode(folder, location) {
    return {
        id: folder.id + '-' + location.id,
        name: location.name,
        nodeType: 'location',
        children: [],
        class: 'font-bold',
        collapsed: false,
        folderId: folder.id,
    };
}
function getBundleNode(folder, location, bundle) {
    return {
        id: folder.id + '-' + location.id + '-' + bundle.id,
        name: bundle.label,
        bundleId: bundle.id,
        location: location,
        nodeType: 'bundle',
        children: [],
        class: 'text-blue-600',
        collapsed: false,
        folderId: folder.id,
        locationId: location.id,
    };
}
export function buildTree(bundles, folders, directories, tasks) {
    const result = [];
    let remainingTasks = [...tasks];
    for (const folder of folders) {
        const folderNode = getFolderNode(folder);
        if (remainingTasks.find((task) => task.location.parentId === folder.id)) {
            result.push(folderNode);
            for (const location of folder.locations) {
                const locationNode = getLocationNode(folder, location);
                if (remainingTasks.find((task) => task.location.id === location.id)) {
                    folderNode.children.push(locationNode);
                    for (const bundle of bundles) {
                        const bundleNode = getBundleNode(folder, location, bundle);
                        const matchingTasks = remainingTasks.filter(
                            (task) => task.service.bundleId === bundle.id && task.locationId === location.id,
                        );
                        if (matchingTasks.length > 0) {
                            locationNode.children.push(bundleNode);
                            remainingTasks = remainingTasks.filter(
                                (task) => !matchingTasks.find((node) => node.id === task.id),
                            );
                            handleDirectories(
                                directories.filter((directory) => directory.bundleId === bundle.id),
                                folder.id + '-' + location.id + '-' + bundle.id,
                                bundleNode.children,
                                matchingTasks,
                            );
                        }
                    }
                }
            }
        }
    }
    return result;
}

export default {
    buildTree,
};
