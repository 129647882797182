<template>
    <div>
        <app-input-text
            :label="$t('commons.name')"
            v-model="internalValue.name"
            ref="firstField"
            @keyup.esc="onCancel"
            @keyup.enter="invalid ? '' : onSave()"
            class="m-2"
        />
        <app-date-input
            :label="$t('project.follow.agenda.exceptions.startDate')"
            v-model="internalValue.startDate"
            :required="true"
            @keyup.esc="onCancel"
            @keyup.enter="invalid ? '' : onSave()"
            @input="onStartDateInput($event)"
            class="m-2"
        />
        <app-date-input
            :label="$t('project.follow.agenda.exceptions.endDate')"
            v-model="internalValue.endDate"
            :required="true"
            :min="internalValue.startDate"
            @keyup.esc="onCancel"
            @keyup.enter="invalid ? '' : onSave()"
            class="m-2"
        />
        <app-multi-picker
            :label="$t('project.follow.agenda.exceptions.bundles')"
            :placeholder="$t('project.follow.agenda.exceptions.allBundles')"
            v-model="internalValue.bundles"
            @keyup.esc="onCancel"
            @keyup.enter="invalid ? '' : onSave()"
            class="m-2"
            :options="bundles"
        ></app-multi-picker>
        <div class="flex justify-between my-4 text-xs w-full md:col-span-2">
            <app-button variant="default" :label="$t('commons.cancel')" size="mini" @click="onCancel" />
            <app-button
                :disabled="invalid"
                :label="$t('commons.validate')"
                @click="onSave"
                variant="primary"
                size="mini"
                class="px-3 mr-5"
                type="submit"
            ></app-button>
        </div>
    </div>
</template>

<script>
import AppInputText from '../../../components/appInputText/AppInputText';
import AppButton from '../../../components/appButton/AppButton';
import AppCancel from '../../../components/appCancel/AppCancel';
import AppDateInput from '../../../components/appDateInput/AppDateInput';
import AppFooter from '../../../components/appFooter/AppFooter';
import AppMultiPicker from '../../../components/appMultiPicker/AppMultiPicker';
import { getBundles } from '@/features/bundles/bundles.service';

export default {
    props: ['invalid', 'touched', 'errors', 'exception'],
    components: {
        AppMultiPicker,
        AppFooter,
        AppDateInput,
        AppCancel,
        AppButton,
        AppInputText,
    },
    created() {
        this.subscriptions = [
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                this.bundles = bundles;
            }),
        ];
    },
    methods: {
        onStartDateInput(value) {
            if (value > this.internalValue.endDate) {
                this.internalValue.endDate = value;
            }
        },
        onCancel() {
            this.$emit('cancel');
        },
        onSave() {
            this.$emit('save', this.internalValue);
        },
    },
    data() {
        return {
            internalValue: { ...this.exception },
            bundles: [],
            subscriptions: [],
        };
    },
};
</script>
