import { replicateRxCollection } from 'rxdb/plugins/replication';
import { reportError } from '@/features/tracker/tracker.service';
import { omit } from '@/services/sanitize.service';
import addToCache from '@/services/cache.service';
import { getAPIHeaders } from '@/services/api.service';
import onMessage from '@/services/socket.service';
import { setCollectionSyncStatus, setProjectsCollectionSyncStatus } from '@/state/state';
export default function (collection) {
    const replicationState = replicateRxCollection({
        live: true,
        liveInterval: 0,
        retryTime: 10000,
        autoStart: false,
        collection,
        replicationIdentifier: 'projects-rest-replication',
        pull: {
            async handler(latestPullDocument) {
                const limitPerPull = 100;
                const minTimestamp = latestPullDocument ? latestPullDocument.updatedAt : '';
                const response = await fetch(`/api/projects?from=${minTimestamp}&limit=${limitPerPull}`, {
                    headers: getAPIHeaders(),
                });
                if (response.ok) {
                    const documents = await response.json();
                    addToCache(documents.map((document) => document.imageUrl).filter((imageUrl) => !!imageUrl));
                    return {
                        documents: documents.map((document) => ({
                            ...document,
                            rootVersion: document.updatedAt,
                            _deleted: !!document.deletedAt,
                            code: document.code || '',
                            name: document.name || '',
                        })),
                        hasMoreDocuments: documents.length === limitPerPull,
                    };
                } else if (response.status === 403 || response.status === 429 || response.status === 404) {
                    return {
                        documents: [],
                        hasMoreDocuments: false,
                    };
                } else {
                    throw new Error('replication pull failed');
                }
            },
        },
        push: {
            async handler(docs) {
                if (
                    docs.length > 0 &&
                    (!docs[0].rootVersion || (docs[0].rootVersion && docs[0].updatedAt !== docs[0].rootVersion))
                ) {
                    const response = await fetch('/api/projects/' + docs[0].id, {
                        method: 'PUT',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(omit(docs[0], ['me'])),
                    });
                    if (!response.ok) {
                        throw new Error(await response.text());
                    }
                }
            },
            /**
             * Batch size, optional
             * Defines how many documents will be given to the push handler at once.
             */
            batchSize: 1,
        },
    });
    replicationState.error$.subscribe((error) => {
        if (navigator.onLine) {
            reportError('projectsReplication ' + error.message);
            console.error(error);
        }
        setCollectionSyncStatus(error.documentsData ? error.documentsData[0]?.id : error.message, 'projects', {
            state: 'error',
        });
    });
    replicationState.active$.subscribe((bool) => {
        setProjectsCollectionSyncStatus({ state: bool ? 'pending' : 'ok' });
    });
    onMessage('projects', () => {
        replicationState.run();
    });
    return replicationState;
}
