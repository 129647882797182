import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';
export const observationsReports = {
    schema: {
        title: 'observationsReports schema',
        version: 5,
        description: 'describes a observationsReports',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                format: 'uuid',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'], format: 'uuid' },
            name: { type: ['string', 'null'] },
            date: { type: ['string', 'null'] },
            showMaps: { type: ['boolean', 'null'] },
            showStats: { type: ['boolean', 'null'] },
            showEmptyZones: { type: ['boolean', 'null'] },
            showTables: { type: ['boolean', 'null'] },
            showPhotos: { type: ['boolean', 'null'] },
            showPending: { type: ['boolean', 'null'] },
            showValidated: { type: ['boolean', 'null'] },
            showResolved: { type: ['boolean', 'null'] },
            showAdministrative: { type: ['boolean', 'null'] },
            showObservationsOnTodoWork: { type: ['boolean', 'null'] },
            showObservationsOnDoneWork: { type: ['boolean', 'null'] },
            showOtherObservations: { type: ['boolean', 'null'] },
            showEXEObservations: { type: ['boolean', 'null'] },
            showOPRObservations: { type: ['boolean', 'null'] },
            showReceiptObservations: { type: ['boolean', 'null'] },
            showOPLObservations: { type: ['boolean', 'null'] },
            showDeliveryObservations: { type: ['boolean', 'null'] },
            showAPAObservations: { type: ['boolean', 'null'] },
            showObservationsWithoutZone: { type: ['boolean', 'null'] },
            files: { type: 'array', items: { type: 'object' } },
            zoneIds: { type: 'array', items: { type: 'string' } },
            bundleIds: { type: 'array', items: { type: 'string' } },
            relatedContentId: { type: ['string', 'null'], format: 'uuid' },
            relatedContentType: { type: ['string', 'null'] },
            index: { type: ['number', 'null'] },
            generateAPDFByBundle: { type: ['boolean', 'null'] },
            emitterIds: { type: 'array', items: { type: 'string' } },
            startDate: { type: ['string', 'null'], format: 'date-time' },
            endDate: { type: ['string', 'null'], format: 'date-time' },
            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'], format: 'uuid' },
            updatedBy: { type: ['string', 'null'], format: 'uuid' },
            deletedBy: { type: ['string', 'null'], format: 'uuid' },
            rootVersion: { type: 'string', format: 'date-time' },
            generationId: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => {
            delete doc.showEmptyTables;
            return doc;
        },
        2: (doc) => {
            doc.showDeliveryObservations = true;
            doc.showReceiptObservations = true;
            return doc;
        },
        3: (doc) => doc,
        4: (doc) => doc,
        5: (doc) => doc,
    },
};
