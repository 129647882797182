<template>
    <app-label
        :show-label="label && (showLabel === true || (showLabel === 'onFocus' && hasFocus))"
        :label="label"
        class="flex gap-1"
        :class="{ 'flex-col': showLabel === 'onFocus' }"
    >
        <slot v-if="!hasFocus">
            <div class="flex" :class="{ underline: !disabled }">
                <span v-if="value">{{ value | humanizeDate }}</span>
                <span v-else>...</span>
            </div>
        </slot>
        <input
            :style="{ opacity, 'max-width': '6em', left: opacity ? null : '-20000px' }"
            :class="{ 'cursor-pointer': !disabled, absolute: !opacity }"
            class="bg-inherit"
            style="width: 100%"
            type="text"
            v-model="internalValue"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="validate"
            @keyup.esc="discard"
            ref="input"
            :disabled.prop="disabled"
        />
    </app-label>
</template>
<script>
import isValid from 'date-fns/isValid';
import AppLabel from '@/components/appLabel/AppLabel';
import format from 'date-fns/format';

import Datepicker from 'vanillajs-datepicker/Datepicker';
import 'vanillajs-datepicker/css/datepicker.css';
import fr from 'vanillajs-datepicker/locales/fr';
Object.assign(Datepicker.locales, fr);
export default {
    components: { AppLabel },
    data() {
        return {
            opacity: 0,
            hasFocus: false,
            hours: this.keepHours(this.value),
            datepicker: null,
            internalValue: '',
            isProgrammaticDateChanged: true,
        };
    },
    watch: {
        value(newValue) {
            this.isProgrammaticDateChanged = true;
            this.datepicker.setDate(newValue || [], { clear: true, revert: true });
            this.isProgrammaticDateChanged = false;
            if (!newValue) {
                this.internalValue = '';
            }
            this.hours = this.keepHours(newValue);
        },
    },
    mounted() {
        const input = this.$refs.input;
        if (!input) {
            return;
        }
        this.datepicker = new Datepicker(input, {
            autohide: true,
            clearButton: true,
            language: 'fr',
            format: {
                toValue: (date) => {
                    if (date instanceof Date) {
                        return date;
                    } else if (typeof date === 'string') {
                        let strResult;
                        if (date.length === 8) {
                            // 10122023
                            strResult =
                                date[4] +
                                date[5] +
                                date[6] +
                                date[7] +
                                '-' +
                                date[2] +
                                date[3] +
                                '-' +
                                date[0] +
                                date[1];
                        } else if (date.length === 6) {
                            // 101223
                            strResult = '20' + date[4] + date[5] + '-' + date[2] + date[3] + '-' + date[0] + date[1];
                        } else if (date.length === 4) {
                            // 1012
                            strResult = new Date().getFullYear() + '-' + date[2] + date[3] + '-' + date[0] + date[1];
                        } else if (date.length === 10) {
                            // 01/01/2022
                            strResult =
                                date[6] +
                                date[7] +
                                date[8] +
                                date[9] +
                                '-' +
                                date[3] +
                                date[4] +
                                '-' +
                                date[0] +
                                date[1];
                        }
                        return new Date(strResult);
                    } else {
                        this.internalValue = '';
                        return null;
                    }
                },
                toDisplay: (date) => {
                    const result =
                        date.getDate().toString().padStart(2, '0') +
                        '/' +
                        (date.getMonth() + 1).toString().padStart(2, '0') +
                        '/' +
                        date.getFullYear().toString();
                    this.internalValue = result;
                    return result;
                },
            },
            minDate: this.min,
            todayButton: true,
            todayButtonMode: 1,
            weekNumbers: 1,
            weekStart: 1,
            container: 'body',
        });
        this.isProgrammaticDateChanged = true;
        this.datepicker.setDate(this.value || [], { clear: true, revert: true });
        this.isProgrammaticDateChanged = false;
        input.addEventListener('changeDate', (event) => {
            if (!this.isProgrammaticDateChanged) this.onDateChanged(event.detail.date);
        });
    },
    methods: {
        onDateChanged(date) {
            if (date) {
                const strDate =
                    date.getFullYear().toString() +
                    '-' +
                    (date.getMonth() + 1).toString().padStart(2, '0') +
                    '-' +
                    date.getDate().toString().padStart(2, '0') +
                    'T' +
                    this.hours;
                this.$emit('input', new Date(strDate));
            } else {
                this.$emit('input', null);
            }
            this.reset();
        },
        discard() {
            this.reset();
        },
        focus() {
            this.$refs.input.focus();
        },
        keepHours(value) {
            if (value && isValid(value)) {
                return format(value, 'HH:mm:00.000xxx');
            } else {
                return format(new Date(), 'HH:mm:00.000xxx');
            }
        },
        validate(event) {
            event.preventDefault();
            this.reset();
            this.$emit('enter');
        },
        reset() {
            this.opacity = 0;
            if (this.$refs.input) {
                this.$refs.input.blur();
            }
        },
        onFocus() {
            if (this.disabled) {
                return;
            }
            this.hasFocus = true;
            this.opacity = 1;
        },
        onBlur() {
            this.opacity = 0;
            this.hasFocus = false;
        },
    },
    props: {
        label: String,
        disabled: Boolean,
        value: Date,
        showLabel: { type: String | Boolean, default: true },
    },
};
</script>
