<template>
    <main class="max-w-5xl m-5 w-full" :class="{ loading }">
        <meeting-printable-report-cover-page />
        <meeting-printable-report-attendance />
        <meeting-printable-report-companies v-if="meeting.showParticipantsIndex" />
        <meeting-printable-report-photos-page v-if="meeting.showGeneralProgress && meeting.attachments.length > 0" />
        <meeting-printable-report-planning-page v-if="meeting.showPlanning" />
        <meeting-printable-report-generals-page v-if="meeting.showGeneralObservations" />
        <meeting-printable-report-task-table-page v-if="meeting.showTasksTable" />
        <template v-for="bundle in bundles">
            <meeting-printable-report-bundle-page :bundle="bundle" />
        </template>
    </main>
</template>

<script>
import MeetingPrintableReport from '@/features/meetings/MeetingPrintableReportCompanies';
import MeetingPrintableReportCoverPage from '@/features/meetings/MeetingPrintableReportCoverPage';
import MeetingPrintableReportCompanies from '@/features/meetings/MeetingPrintableReportCompanies';
import MeetingPrintableReportPhotosPage from '@/features/meetings/MeetingPrintableReportPhotosPage';
import MeetingPrintableReportTaskTablePage from '@/features/meetings/MeetingPrintableReportTaskTablePage';
import { getMeeting } from '@/features/meetings/meetings.service';
import MeetingPrintableReportPlanningPage from '@/features/meetings/MeetingPrintableReportPlanningPage';
import MeetingPrintableReportBundlePage from '@/features/meetings/MeetingPrintableReportBundlePage';
import MeetingPrintableReportGeneralsPage from '@/features/meetings/MeetingPrintableReportGeneralsPage';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import MeetingPrintableReportAttendance from '@/features/meetings/MeetingPrintableReportAttendance';
import { sortBy } from '@/services/sanitize.service';
export default {
    async created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe(
                (meeting) => (this.meeting = meeting),
            ),
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                const bundlesByGroup = bundlesToGroups(sortBy(bundles, 'reference'));
                this.bundles = [
                    ...bundlesByGroup.MOA,
                    ...bundlesByGroup.AMOA,
                    ...bundlesByGroup.MOE,
                    ...bundlesByGroup.company,
                    ...bundlesByGroup.distributor,
                ];
                this.$nextTick(() => (this.loading = false));
            }),
        ];
    },
    data() {
        return {
            meeting: {},
            bundles: [],
            subscriptions: [],
            loading: true,
        };
    },
    components: {
        MeetingPrintableReportAttendance,
        MeetingPrintableReportGeneralsPage,
        MeetingPrintableReportBundlePage,
        MeetingPrintableReportPlanningPage,
        MeetingPrintableReportTaskTablePage,
        MeetingPrintableReportPhotosPage,
        MeetingPrintableReportCompanies,
        MeetingPrintableReportCoverPage,
        MeetingPrintableReport,
    },
};
</script>
