import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const bundles = {
    schema: {
        title: 'bundle schema',
        version: 2,
        description: 'describes a bundle',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            name: {
                type: ['string', 'null'],
            },
            reference: {
                type: ['string', 'null'],
            },
            category: {
                type: ['string', 'null'],
            },
            type: {
                type: ['string', 'null'],
            },
            index: {
                type: ['number', 'null'],
            },
            marketAmount: {
                type: ['number', 'null'],
            },
            visaDelay: {
                type: ['number', 'null'],
            },
            companyId: {
                type: ['string', 'null'],
            },
            marketDate: {
                type: ['string', 'null'],
            },
            serviceOrderDate: {
                type: ['string', 'null'],
            },
            responsibleId: {
                type: ['string', 'null'],
            },
            licensedUsersEmail: {
                type: ['string', 'null'],
            },
            contactIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            hasLicense: {
                type: ['boolean', 'null'],
            },
            hasAtexAccess: {
                type: ['boolean', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => ({
            ...doc,
            hasAtexAccess: true,
        }),
    },
};
