<template>
    <fragment>
        <td class="border pr-1 text-right">
            <p class="text-blue-700">P</p>
        </td>
        <td class="border text-center" :style="task.realStartDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.startDate | humanizeDate }}</span>
        </td>
        <td class="border text-center" :style="task.realEndDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.endDate | humanizeDate }}</span>
        </td>
        <td class="border text-right" v-if="options.showProgressColumn">
            {{ task.expectedProgress ? Math.round(task.expectedProgress) + '%' : '' }}
        </td>
        <td class="border px-1 text-right" v-if="options.showDurationColumn">
            <span
                :title="
                    task.hasEEPredecessor && task.hasXSPredecessor
                        ? $t('project.follow.planning.durationDefinedByPredecessors')
                        : $t('project.follow.planning.plannedDuration')
                "
            >
                {{ task.duration === 0 || !!task.duration ? task.duration : '' }}
            </span>
        </td>
        <td class="border px-1 text-right" v-if="options.showQuantityColumn">
            <span>
                {{ task.quantity }}
            </span>
        </td>
        <td class="border text-right px-1" v-if="options.showLateColumn">
            <span
                v-if="task.type === 'task'"
                class="line-clamp-2"
                :class="{
                    'text-green-600 font-bold': task.late < 0,
                    'text-red-600 font-bold': task.late > 0,
                }"
            >
                {{ task.late > 0 ? '+' + task.late : task.late }}
            </span>
        </td>
    </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
    components: { Fragment },
    props: ['task', 'options'],
};
</script>
