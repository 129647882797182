<template>
    <button
        @click.prevent.stop="$emit('click', $event)"
        @focus="$emit('focus', $event)"
        class="flex items-center disabled:opacity-50 focus:outline-none focus:ring"
        :class="{
            'py-2 px-4': size === 'medium' || size === '',
            'p-1 text-xs': size === 'mini',
            'text-sm': size !== 'mini',
            'primary bg-main  text-white': variant === 'primary',
            default: variant === 'default',
            danger: variant === 'danger',
            light: variant === 'light',
            'border-2 font-bold': variant !== 'borderless',
            selected: variant === 'selected',
        }"
        :aria-label="ariaLabel"
        ref="button"
        :type="type"
    >
        <Icon
            :class="{ 'mr-3': !iconClass && label, [iconClass]: iconClass }"
            v-if="icon"
            :name="icon"
            :width="size === 'mini' ? '16px' : '24px'"
            :height="size === 'mini' ? '16px' : '24px'"
        ></Icon>
        <span v-if="label">{{ label }}</span>
        <slot></slot>
    </button>
</template>
<script>
import Icon from '../../icons/Icon';
export default {
    components: { Icon },
    props: {
        ariaLabel: String,
        label: String,
        icon: String,
        type: {
            type: String,
            default: 'button',
        },
        iconClass: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'medium',
        },
        variant: {
            type: String,
            default: 'default',
        },
        shortcut: {
            type: String,
            default: null,
        },
    },
    created() {
        const handleKeydown = (e) => {
            if (this.shortcut) {
                if (e.key.toLowerCase() === this.shortcut) {
                    this.$emit('click');
                }
            }
        };
        document.addEventListener('keydown', handleKeydown);
        this.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleKeydown);
        });
    },
    methods: {
        focus() {
            this.$refs.button.focus();
        },
    },
};
</script>
<style scoped>
.primary,
.selected {
    @apply border-main;
}
.selected,
.primary:hover:not(:disabled) {
    background-color: #e5f1f6;
    @apply border-main text-main;
    border-width: 2px;
}

.default,
.light {
    border-color: #e5e5e5;
    border-width: 1px;
}

.primary:disabled,
.selected:disabled,
.danger:disabled,
.default:disabled {
    opacity: 0.3;
    cursor: default;
}

.default:hover:not(:disabled),
.default:focus:not(:disabled) {
    border-color: #acabab;
}

.danger {
    background-color: #fff;
    border-color: #e5e5e5;
    border-width: 1px;
    color: red;
}

.danger:hover:not(:disabled),
.danger:focus:not(:disabled) {
    background-color: red;
    border-color: #e3e3e3;
    color: #e5e5e5;
}
</style>
