<template>
    <div class="flex flex-row p-2 gap-2">
        <app-button
            class=""
            :label="$t('services.newService')"
            @click.stop="pickService()"
            icon="icon-plus"
            size="mini"
        />
        <app-autocomplete
            @input="onServiceDefined"
            :options="serviceOptions"
            :allowStringCriteria="true"
            :stringCriteriaPrefix="$t('services.createPrefix')"
            ref="servicePopup"
        >
            <template v-slot:title>
                <app-separator>
                    <h2>{{ $t('services.pickOrCreateService') }}</h2>
                    <div class="flex flex-grow"></div>
                    <app-button
                        size="mini"
                        icon="icon-close"
                        aria-label="close popup"
                        @click="$refs.servicePopup.close()"
                    />
                </app-separator>
            </template>
        </app-autocomplete>
    </div>
</template>

<script>
import AppButton from '@/components/appButton/AppButton';
import { getDirectories } from '@/features/services/directories.service';
import { getSortedChildren, moveItem } from '@/features/services/itemUtils';
import { createService, getServices, reOrder } from '@/features/services/services.service';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import { createTask, getTasks } from '@/features/tasks/tasks.service';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppClamp from '@/components/appClamp/AppClamp.vue';
import { queryProject } from '@/features/projects/projects.service';
import addDays from 'date-fns/addDays';

export default {
    components: { AppClamp, AppSeparator, AppAutocomplete, AppButton },
    props: ['bundleId', 'node'],
    data() {
        return { tasks: [], bundles: [], project: null };
    },
    async created() {
        this.project = await queryProject(this.$route.params.projectId);
        this.subscriptions = [
            combineLatest([
                getDirectories(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getServices(this.$route.params.projectId),
                getTasks(this.$route.params.projectId),
            ]).subscribe(([directories, bundles, services, tasks]) => {
                this.tasks = tasks;
                this.bundles = bundles
                    .map((bundle) => ({
                        ...bundle,
                        services: services.filter((service) => service.bundleId === bundle.id),
                        directories: directories.filter((directory) => directory.bundleId === bundle.id),
                    }))
                    .map((bundle) => ({
                        ...bundle,
                        children: getSortedChildren(bundle, null),
                    }));
            }),
        ];
    },
    computed: {
        serviceOptions() {
            const bundleId = this.bundleId;
            if (this.node.location) {
                // bundle selected on FLB
                const locationId = this.node.location.id;
                const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
                if (bundle) {
                    return bundle.services.map((service) => ({
                        ...service,
                        disabled: !!this.tasks.find(
                            (task) => task.locationId === locationId && task.serviceId === service.id,
                        ),
                    }));
                } else {
                    return [];
                }
            } else {
                // bundle selected on B*
                const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
                if (bundle) {
                    return bundle.services.map((service) => ({
                        ...service,
                        disabled: !!this.tasks.find((task) => task.serviceId === service.id),
                    }));
                } else {
                    return [];
                }
            }
        },
    },
    methods: {
        pickService() {
            this.$refs.servicePopup.open();
        },
        async onServiceDefined(selection) {
            if (selection.disabled) return;
            let service;
            if (selection._isStringCriteria) {
                service = await this.newService(selection.content);
            } else {
                service = selection;
            }
            if (this.node.location) {
                await createTask(this.$route.params.projectId, {
                    serviceId: service.id,
                    duration: 1,
                    startDate: this.project.startDate,
                    endDate: addDays(this.project.startDate, 1),
                    progress: 0,
                    quantity: 0,
                    locationId: this.node.location.id,
                    predecessors: [],
                });
            }
            this.$emit('editService', service.id);
        },
        async newService(name) {
            const projectId = this.$route.params.projectId;
            const bundleId = this.bundleId;
            const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
            const index = bundle.children.length + 1;
            const newService = await createService(projectId, {
                name,
                unit: 'm²',
                amount: 0,
                parentDirectoryId: null,
                index,
                isExecution: true,
                reference: '',
                bundleId,
                predecessors: [],
            });
            const newChildList = moveItem(bundle.children, newService, newService.index);
            await reOrder(this.$route.params.projectId, newChildList);
            return newService;
        },
    },
};
</script>
