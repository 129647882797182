<template>
    <div>
        <app-tips class="flex gap-2 items-center p-1">
            <span v-if="type !== 'other'">{{ $t('project.diffusion.mailTemplates.tokenTips') }} :</span>
            <app-button
                v-if="type === 'meetingReport' || type === 'convocations'"
                :disabled="disabled"
                size="mini"
                label="NomReunion"
                :title="$t('project.diffusion.mailTemplates.tokenNomReunionTips')"
                @mousedown.native.prevent="insertToken('NomReunion')"
            ></app-button>
            <app-button
                v-if="type === 'meetingReport' || type === 'convocations'"
                :disabled="disabled"
                size="mini"
                label="DateReunion"
                :title="$t('project.diffusion.mailTemplates.tokenDateReunionTips')"
                @mousedown.native.prevent="insertToken('DateReunion')"
            ></app-button>
            <app-button
                v-if="(type === 'meetingReport' || type === 'convocations') && isBody"
                :disabled="disabled"
                size="mini"
                label="TableauConvocations"
                :title="$t('project.diffusion.mailTemplates.tokenTableauConvocationsTips')"
                @mousedown.native.prevent="insertToken('TableauConvocations')"
            ></app-button>
            <app-button
                v-if="type === 'meetingReport'"
                :disabled="disabled"
                size="mini"
                label="ProchaineReunion"
                :title="$t('project.diffusion.mailTemplates.tokenProchaineReunionTips')"
                @mousedown.native.prevent="insertToken('ProchaineReunion')"
            ></app-button>
            <app-button
                v-if="type === 'meetingReport' && isBody"
                :disabled="disabled"
                size="mini"
                label="LienCR"
                :title="$t('project.diffusion.mailTemplates.tokenLienCRTips')"
                @mousedown.native.prevent="insertToken('LienCR')"
            ></app-button>
            <app-button
                v-if="type === 'observationsReport' || type === 'preparationsReport'"
                :disabled="disabled"
                size="mini"
                label="NomRapport"
                :title="$t('project.diffusion.mailTemplates.tokenNomRapportTips')"
                @mousedown.native.prevent="insertToken('NomRapport')"
            ></app-button>
            <app-button
                v-if="(type === 'observationsReport' || type === 'preparationsReport') && isBody"
                :disabled="disabled"
                size="mini"
                label="LienRapport"
                :title="$t('project.diffusion.mailTemplates.tokenLienRapportTips')"
                @mousedown.native.prevent="insertToken('LienRapport')"
            ></app-button>
            <app-button
                v-if="type === 'planning'"
                :disabled="disabled"
                size="mini"
                label="NomPlanning"
                :title="$t('project.diffusion.mailTemplates.tokenNomPlanningTips')"
                @mousedown.native.prevent="insertToken('NomPlanning')"
            ></app-button>
            <app-button
                v-if="type === 'preparationVisa'"
                :disabled="disabled"
                size="mini"
                label="EmetteurVisa"
                :title="$t('project.diffusion.mailTemplates.tokenEmetteurVisaTips')"
                @mousedown.native.prevent="insertToken('EmetteurVisa')"
            ></app-button>
            <app-button
                v-if="type === 'preparationVisa'"
                :disabled="disabled"
                size="mini"
                label="NomDocument"
                :title="$t('project.diffusion.mailTemplates.tokenNomDocumentTips')"
                @mousedown.native.prevent="insertToken('NomDocument')"
            ></app-button>
            <app-button
                v-if="type === 'preparationVisa' && isBody"
                :disabled="disabled"
                size="mini"
                label="LienVisa"
                :title="$t('project.diffusion.mailTemplates.tokenLienVisaTips')"
                @mousedown.native.prevent="insertToken('LienVisa')"
            ></app-button>
            <app-button
                v-if="type === 'preparationVisa'"
                :disabled="disabled"
                size="mini"
                label="ConclusionVisa"
                :title="$t('project.diffusion.mailTemplates.tokenConclusionVisaTips')"
                @mousedown.native.prevent="insertToken('ConclusionVisa')"
            ></app-button>
            <app-button
                v-if="type === 'preparationVisa' && isBody"
                :disabled="disabled"
                size="mini"
                label="TableauDocumentsVises"
                :title="$t('project.diffusion.mailTemplates.tokenTableauDocumentsVisesTips')"
                @mousedown.native.prevent="insertToken('TableauDocumentsVises')"
            ></app-button>
        </app-tips>
        <app-tips></app-tips>
    </div>
</template>

<script>
import AppTips from '@/components/app-tips/AppTips.vue';
import AppButton from '@/components/appButton/AppButton.vue';

export default {
    components: { AppButton, AppTips },
    props: ['type', 'isBody', 'disabled'],
    methods: {
        insertToken(token) {
            this.$emit('insertToken', token);
        },
    },
};
</script>
