<template>
    <div class="w-full h-full flex flex-col f flex-grow max-w-5xl">
        <div class="flex justify-center mb-2">
            <app-button
                :class="debug ? 'block' : 'hidden'"
                size="mini"
                label="Tourner marqueurs"
                @click="rotateMarkers()"
            ></app-button>
        </div>

        <div class="h-full w-full flex-grow" v-if="support">
            <app-leaflet-viewer
                v-if="support && support.url"
                :src="support.url"
                :crop-box="support.cropBox"
                :page="1"
                :markers="markers"
                :rotation="support.rotation"
                :allowMarkerMove="!readOnly"
                @markerMoved="saveMarker($event)"
                @markerClick="editObservation($event)"
                @pickPosition="createMarker"
            ></app-leaflet-viewer>
        </div>
        <div v-else class="flex flex-col items-center">
            <span class="text-yellow-500 font-bold">
                {{ $t('zones.supportMissing') }}
            </span>
            <router-link
                class="underline"
                :to="{
                    name: 'supports',
                }"
                v-if="!support"
            >
                {{ $t('zones.gotoSupport') }}
            </router-link>
        </div>
        <create-observation-popup
            ref="popup"
            @closed="onClosed"
            :supportId="support ? support.id : null"
        ></create-observation-popup>
    </div>
</template>

<script>
import CreateObservationPopup from '@/features/tours/CreateObservationPopup';
import { getObservationClass, updateObservation } from '@/features/observations/observation.service';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppButton from '@/components/appButton/AppButton';
import { transposeXYWithRotation } from '@/components/appLeafletViewer/AppLeaflet.service';
import AppSelect from '@/components/appSelect/AppSelect';

export default {
    components: {
        AppSelect,
        AppButton,
        AppLeafletViewer,
        CreateObservationPopup,
    },
    props: { support: { type: Object, default: () => null }, readOnly: Boolean, observations: { type: Array } },
    computed: {
        markers() {
            return this.observations
                .filter((observation) => !!observation.footprint && observation.supportId === this.support.id)
                .map((observation) => ({
                    observationId: observation.id,
                    label: observation.index + 1,
                    title: observation.title,
                    ...observation.footprint,
                    draggable: true,
                    classes: getObservationClass(observation),
                }));
        },
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.onKeyUp);
    },
    methods: {
        onKeyUp(event) {
            if (event.key === 'Esc' || event.key === 'Escape') {
                this.escCount++;
                if (this.escCount === 3) {
                    this.debug = true;
                } else if (this.escCount > 3) {
                    this.debug = false;
                    this.escCount = 0;
                }
            } else {
                this.escCount = 0;
                this.debug = false;
            }
        },
        rotateMarkers() {
            this.markers.map((marker) => {
                const { x, y } = transposeXYWithRotation(marker.x1, marker.y1, 90);
                this.saveMarker({
                    ...marker,
                    x,
                    y,
                });
            });
        },
        onClosed(observationId) {
            const marker = this.markers.find((marker) => marker.observationId === observationId);
            if (marker) {
                marker.classes += ' animate-pulse';
                setTimeout(() => (marker.classes = marker.classes.replace('animate-pulse', '')), 3600);
            }
        },
        saveMarker(marker) {
            if (this.readOnly) return;
            return updateObservation(this.$route.params.projectId, {
                id: marker.observationId,
                footprint: {
                    type: marker.type,
                    page: marker.page,
                    x: marker.x,
                    y: marker.y,
                },
            });
        },
        editObservation(marker) {
            this.$refs.popup.edit(
                this.support.observations.find((observation) => observation.id === marker.observationId),
            );
        },
        createMarker(event) {
            if (this.readOnly) return;
            this.$refs.popup.start(event);
        },
    },
    data() {
        return {
            debug: false,
            escCount: 0,
        };
    },
};
</script>
