<template>
    <app-label :showLabel="showLabel" :label="label" :required="required" class="app-select" :tag="labelTag || 'label'">
        <ValidationProvider tag="div" v-slot="{ errors, classes }" class="relative w-full">
            <select
                style="background-color: inherit"
                class="appearance-none block w-full border-gray-200 focus:ring-2"
                ref="input"
                :id="id"
                :name="name"
                :required="required"
                :disabled="disabled"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                @change="$emit('change', $event.target.value)"
                @keypress="$emit('keypress', $event)"
                @keyup="$emit('keyup', $event)"
                @blur="$emit('blur', $event)"
                @keydown.ctrl.space.prevent=""
                :class="{
                    'cursor-pointer': !disabled,
                    'shadow border': !icon,
                    'bg-opacity-0 absolute': icon,
                    [variantSelectClasses]: true,
                    'p-2 pr-6': size !== 'mini',
                    'p-1': size === 'mini',
                }"
                :aria-label="label"
                :title="label"
                :style="size === 'mini' ? 'max-width:25px' : ''"
            >
                <slot></slot>
            </select>
            <div
                v-if="!icon"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center text-gray-700"
                :class="{ 'opacity-50': disabled, 'pr-1': size !== 'mini' }"
            >
                <IconChevronDown :disabled="disabled" />
            </div>
            <div
                class="flex justify-between shadow items-center p-1"
                v-if="icon"
                :class="{ 'opacity-50': disabled, 'pr-1': size !== 'mini', ...variantTriggerClasses }"
            >
                <Icon class="m-1" width="16" height="16" :name="icon" :disabled="disabled" />
                <IconChevronDown :disabled="disabled" />
            </div>
        </ValidationProvider>
    </app-label>
</template>
<script>
import IconChevronDown from '../../icons/IconChevronDown';
import AppLabel from '../appLabel/AppLabel';
import Icon from '../../icons/Icon';
import AppButton from '../appButton/AppButton';
import { ValidationProvider } from 'vee-validate';
export default {
    components: { AppButton, Icon, IconChevronDown, AppLabel, ValidationProvider },
    props: {
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        id: String,
        name: String,
        showLabel: { type: Boolean, default: true },
        label: String,
        required: {
            type: Boolean,
            default: false,
        },
        icon: String,
        labelTag: String,
        variant: String,
        size: String,
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
    computed: {
        variantSelectClasses() {
            if (this.variant === 'default' || !this.variant) {
                return '';
            } else if (this.variant === 'borderless') {
                return 'hover-bg-white-25';
            }
        },
        variantTriggerClasses() {
            if (this.variant === 'default' || !this.variant) {
                return 'border';
            } else if (this.variant === 'borderless') {
                return '';
            }
        },
    },
};
</script>
<style scoped>
.app-select select:disabled {
    opacity: 0.5;
    background-color: #e0e0e0;
}

.app-select input.invalid {
    border-color: red;
    border-width: 2px;
}

.hover-bg-white-25:hover {
    background-color: rgba(255, 255, 255, 0.19);
}
</style>
