<template>
    <AppList :items="locations" @navigateTo="navigateTo" class="max-w-5xl" :removable="false" :line-height="50">
        <template v-slot:headers>
            <div class="font-bold mb-4">{{ $t('observations.tourLocationsTips') }}</div>
        </template>
        <template v-slot:item="{ item }">
            <span class="text-lg">{{ item.name }}</span>
        </template>
    </AppList>
</template>

<script>
import AppList from '@/components/appList/AppList';
import { updateBreadCrumbs } from '@/state/state';
import { getLocationsTree } from '@/features/locations/locations.service';

export default {
    components: { AppList },
    async created() {
        this.subscriptions = [
            getLocationsTree(this.$route.params.projectId).subscribe((folders) => {
                const folder = folders.find((folder) => folder.id === this.$route.params.folderId);
                this.locations = folder.locations;
                updateBreadCrumbs({ folderName: folder.name });
            }),
        ];
    },
    methods: {
        navigateTo: function (location) {
            this.$router.push({
                name: 'zonesTour',
                params: {
                    projectId: this.$route.params.projectId,
                    folderId: this.$route.params.folderId,
                    phase: this.$route.params.phase,
                    locationId: location.id,
                },
            });
        },
    },
    data() {
        return {
            locations: [],
            subscriptions: [],
        };
    },
};
</script>
