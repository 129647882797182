import supportsService, { getSupports } from '@/features/supports/supports.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getMapById, groupBy } from '@/services/sanitize.service';
import autoControlsService, { getAutoControls } from '@/features/autoControls/autoControls.service';
import receptionsService, { getReceptions } from '@/features/receptions/receptions.service';
import certificatesService, { getCertificates } from '@/features/certificates/certificates.service';
import observationService, { getAllObservations } from '@/features/observations/observation.service';
import observationsReportsService, {
    getAllObservationsReports,
} from '@/features/observationsReports/observationsReports.service';
import preparationsService, { getPreparations } from '@/features/preparations/preparations.service';
import tasksService from '@/features/tasks/tasks.service';
import { bulkDelete } from '@/features/locations/locations.service';
import preparationsReportsService, {
    getPreparationsReports,
} from '@/features/preparationsReports/preparationsReports.service';

export function getDescendants(node) {
    const child = node.locations || node.zones || node.rooms || [];
    return child.reduce((acc, children) => [...acc, children, ...getDescendants(children)], []);
}

export async function getCascadeImpacts(projectId, item) {
    const concernedLocations = [item, ...getDescendants(item)];
    const folders = concernedLocations.filter((location) => location.type === 'folder');
    const locations = concernedLocations.filter((location) => location.type === 'location');
    const zones = concernedLocations.filter((location) => location.type === 'zone');
    const rooms = concernedLocations.filter((location) => location.type === 'room');

    const locationIdMap = getMapById(concernedLocations);
    const plannedTasks = await firstValueFrom(getPlannedTasks(projectId, new BehaviorSubject(new Date())));
    const directTasks = plannedTasks
        .filter((task) => locationIdMap[task.locationId])
        .map((task) => ({
            ...task,
            label: task.location.fullName + ' > #' + task.service?.bundle?.label + '>' + task.name,
        }));
    const directTasksIds = getMapById(directTasks);
    const linkedTasks = plannedTasks
        .filter((task) => task.predecessors.find((predecessor) => directTasksIds[predecessor.taskId]))
        .map((task) => ({ ...task, label: task.location.fullName + ' > ' + task.name }));
    const services = groupBy(directTasks, 'serviceId').map((group) => ({ ...group[0].service }));
    const supports = (await firstValueFrom(getSupports(projectId))).filter((support) =>
        support.locationIds.find((id) => locationIdMap[id]),
    );
    const autoControls = (await firstValueFrom(getAutoControls(projectId))).filter((element) =>
        element.locationIds.find((id) => locationIdMap[id]),
    );
    const receptions = (await firstValueFrom(getReceptions(projectId))).filter((element) =>
        element.locationIds.find((id) => locationIdMap[id]),
    );
    const certificates = (await firstValueFrom(getCertificates(projectId))).filter((element) =>
        element.locationIds.find((id) => locationIdMap[id]),
    );
    const observations = (await firstValueFrom(getAllObservations(projectId)))
        .filter((observation) => locationIdMap[observation.zoneId] || locationIdMap[observation.roomId])
        .map((observation) => ({
            ...observation,
            label:
                '#' +
                (observation.index + 1 + '').padStart(3, '0') +
                ' ' +
                observation.title.substring(0, 40) +
                (observation.title.length > 40 ? '...' : ''),
        }));
    const observationsReports = (await firstValueFrom(getAllObservationsReports(projectId))).filter(
        (observationsReport) => observationsReport.zoneIds.find((id) => locationIdMap[id]),
    );
    const preparations = (await firstValueFrom(getPreparations(projectId, 'exe'))).filter((preparation) =>
        preparation.locationIds.find((id) => locationIdMap[id]),
    );
    const conceptions = (await firstValueFrom(getPreparations(projectId, 'con'))).filter((preparation) =>
        preparation.locationIds.find((id) => locationIdMap[id]),
    );
    const preparationsReports = (await firstValueFrom(getPreparationsReports(projectId))).filter(
        (preparationsReports) => preparationsReports.locationIds.find((id) => locationIdMap[id]),
    );
    return {
        locationIdMap,
        itemToRemove: item,
        conceptions,
        preparations,
        observationsReports,
        observations,
        receptions,
        autoControls,
        certificates,
        preparationsReports,
        services,
        directTasks,
        linkedTasks,
        supports,
        folders,
        locations,
        zones,
        rooms,
    };
}

export function removeLocationCascade(
    projectId,
    {
        conceptions,
        preparations,
        observationsReports,
        observations,
        receptions,
        autoControls,
        certificates,
        preparationsReports,
        directTasks,
        linkedTasks,
        supports,
        locationIdMap,
    },
) {
    const toBeRemovedTaskIdMap = getMapById(directTasks);
    return Promise.all([
        bulkDelete(projectId, Object.keys(locationIdMap)),
        tasksService.bulkRemoveTasks(
            projectId,
            directTasks.map((task) => task.id),
        ),
        ...linkedTasks.map((task) =>
            tasksService
                .filterTaskPredecessor(projectId, task, (predecessor) => !toBeRemovedTaskIdMap[predecessor.taskId])
                .catch((e) => e),
        ),
        ...supports.map((support) =>
            supportsService.filterLocationIds(projectId, support, (locationId) => !locationIdMap[locationId]),
        ),
        ...preparationsReports.map((preparationsReport) =>
            preparationsReportsService.filterLocationIds(
                projectId,
                preparationsReport.id,
                (locationId) => !locationIdMap[locationId],
            ),
        ),
        ...[...conceptions, ...preparations].map((preparation) =>
            preparationsService.filterLocationIds(projectId, preparation, (locationId) => !locationIdMap[locationId]),
        ),
        ...observationsReports.map((observationsReport) =>
            observationsReportsService.filterZoneIds(
                projectId,
                observationsReport,
                (locationId) => !locationIdMap[locationId],
            ),
        ),
        ...observations.map((observation) => {
            let patch;
            if (locationIdMap[observation.zoneId]) {
                patch = { id: observation.id, zoneId: null, roomId: null };
            } else {
                patch = { id: observation.id, roomId: null };
            }
            return observationService.updateObservation(projectId, patch);
        }),
        ...receptions.map((reception) =>
            receptionsService.filterLocationIds(projectId, reception, (locationId) => !locationIdMap[locationId]),
        ),
        ...autoControls.map((autoControl) =>
            autoControlsService.filterLocationIds(projectId, autoControl, (locationId) => !locationIdMap[locationId]),
        ),
        ...certificates.map((certificate) => {
            return certificatesService.filterLocationIds(
                projectId,
                certificate,
                (locationId) => !locationIdMap[locationId],
            );
        }),
    ]);
}
