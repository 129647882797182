<template>
    <div class="flex flex-col w-full p-5 gap-2">
        <template v-if="loading">
            <div class="flex justify-center my-5">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col justify-center gap-2">
                <div class="flex">
                    <app-multi-picker
                        ref="filter"
                        icon="icon-magnify"
                        :show-label="false"
                        :allowStringCriteria="true"
                        class="mx-4 flex-grow"
                        size="mini"
                        :label="$t('commons.filter')"
                        v-model="filterAsArray"
                        :options="filterOptions"
                        @input="saveFilter"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle v-if="option.__typename === 'Bundle'" :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                    <div class="flex items-center">
                        <app-button
                            size="mini"
                            @click="$refs.settings.open()"
                            :aria-label="$t('commons.settings')"
                            :title="$t('commons.settings')"
                            icon="icon-tune"
                        />
                    </div>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        @input="saveFilter"
                        :label="$t('project.follow.follow.done') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        @input="saveFilter"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="far"
                        @input="saveFilter"
                        :label="$t('project.follow.follow.from')"
                        :show-label="false"
                    >
                        <div class="flex font-bold gap-1 text-gray-700">
                            {{ $t('project.follow.follow.from') }}
                            <app-date-link v-model="endDate" @input="saveFrom" />
                            ({{ farCount }})
                        </div>
                    </app-checkbox>
                </div>
            </div>
            <table class="w-full text-xs border">
                <thead class="table-header-group font-bold">
                    <tr class="border border-black">
                        <td class="p-1">
                            <span>{{ $t('commons.location') }}</span>
                        </td>
                        <td class="p-1 sm:hidden">{{ $t('commons.task') }}</td>
                        <td class="p-1 hidden sm:table-cell" colspan="2">{{ $t('commons.task') }}</td>
                        <td class="p-1 text-center hidden sm:table-cell">
                            {{ $t('project.editions.meetingReports.startDates') }}
                        </td>
                        <td class="p-1 text-center hidden sm:table-cell">
                            {{ $t('project.editions.meetingReports.endDates') }}
                        </td>
                        <td class="p-1 text-center hi">%</td>
                        <td class="p-1 text-center hidden sm:table-cell">
                            {{ $t('project.editions.meetingReports.diff') }} (jo)
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="location in taskTree">
                        <template v-for="(task, index) in location.tasks">
                            <tr class="border border-black odd:bg-blue-50">
                                <td class="p-1 align-top bg-white" :rowspan="location.tasks.length" v-if="index === 0">
                                    {{ location.fullName }}
                                </td>
                                <td class="p-1 w-1/2 border border-black">
                                    <div class="flex flex-col">
                                        <div class="flex gap-1">
                                            <a :id="'uuid_' + task.id" style="scroll-margin-top: 3em" />
                                            <span
                                                :title="
                                                    task.bundle.label +
                                                    ' > ' +
                                                    (task.bundle.company ? task.bundle.company.name : '')
                                                "
                                            >
                                                #{{ task.bundle.label }}
                                            </span>
                                            <span v-if="task.path && task.path.length > 0">{{ task.path }} ></span>
                                        </div>
                                        <router-link
                                            class="hover:underline"
                                            :to="{
                                                name: 'followTask',
                                                params: {
                                                    ...$route.params,
                                                    taskId: task.id,
                                                },
                                            }"
                                        >
                                            <span class="ml-5">{{ task.service.name }}</span>
                                        </router-link>
                                    </div>
                                </td>
                                <td class="p-1 hidden sm:table-cell">
                                    <div class="flex flex-col">
                                        <span class="text-right font-bold whitespace-nowrap">P:</span>
                                        <span class="text-right font-bold whitespace-nowrap">R:</span>
                                    </div>
                                </td>
                                <td class="py-1 hidden sm:table-cell">
                                    <div class="flex flex-col justify-center items-center">
                                        <app-date-link
                                            :disabled="!isTaskAdmin"
                                            :label="$t('project.follow.planning.plannedStartDate')"
                                            show-label="onFocus"
                                            v-model="task.startDate"
                                            @input="updateTaskStartDate(task, $event)"
                                        ></app-date-link>
                                        <app-date-link
                                            :disabled="!isTaskAdmin"
                                            v-if="isTaskAdmin"
                                            :label="$t('project.follow.planning.realStartDate')"
                                            show-label="onFocus"
                                            v-model="task.realStartDate"
                                            @input="updateTaskRealStartDate(task, $event)"
                                        ></app-date-link>
                                    </div>
                                </td>
                                <td class="py-1 hidden sm:table-cell">
                                    <div class="flex flex-col items-center">
                                        <app-date-link
                                            :label="$t('project.follow.planning.plannedEndDate')"
                                            :disabled="!isTaskAdmin"
                                            show-label="onFocus"
                                            v-model="task.endDate"
                                            @input="updateTaskEndDate(task, $event)"
                                        ></app-date-link>
                                        <app-date-link
                                            :disabled="!isTaskAdmin"
                                            :label="$t('project.follow.planning.realEndDate')"
                                            show-label="onFocus"
                                            class="text-center"
                                            v-model="task.realEndDate"
                                            @input="updateTaskRealEndDate(task, $event)"
                                        ></app-date-link>
                                    </div>
                                </td>
                                <td class="py-1 text-center">
                                    <app-number-link
                                        :disabled="!isTaskAdmin"
                                        :default-value="task.progress"
                                        :label="$t('project.follow.planning.progress')"
                                        :showLabel="false"
                                        @input="updateTaskProgress(task, $event)"
                                    >
                                        {{ task.progress || 0 }}%
                                    </app-number-link>
                                </td>
                                <td class="py-1 text-center hidden sm:table-cell">
                                    <span class="font-bold text-red-700" v-if="task.late > 1">J+{{ task.late }}</span>
                                    <span v-else-if="task.late === 0" class="font-bold text-green-600">J</span>
                                    <span v-else class="font-bold text-green-600">J{{ task.late }}</span>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>

            <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
                <form class="flex flex-col gap-4 p-2">
                    <fieldset>
                        <legend class="text-gray-700 font-bold flex flex-col gap-1">
                            {{ $t('project.planning.sortBy') }}
                        </legend>
                        <app-label>
                            <input type="radio" value="date" name="sort" v-model="sortKey" @change="saveFilter" />
                            {{ $t('project.planning.sortByDates') }}
                        </app-label>
                        <app-label>
                            <input type="radio" value="name" name="sort" v-model="sortKey" @change="saveFilter" />
                            {{ $t('project.planning.sortByName') }}
                        </app-label>
                    </fieldset>
                    <app-footer @click="$refs.settings.close()"></app-footer>
                </form>
                <div style="padding-bottom: 40px"></div>
            </app-popup>
        </template>
    </div>
</template>

<script>
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { sortTasks, updateTask } from '@/features/tasks/tasks.service';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppBundle from '@/components/app-bundle/appBundle';
import { filterTasks, getFilterOptions } from '@/features/planning/planning/planning.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getProject } from '@/features/projects/projects.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getBundleMap, getBundles } from '@/features/bundles/bundles.service';
import { getDirectories, getDirectoryNamePathMap } from '@/features/services/directories.service';
import { getMapById, groupBy } from '@/services/sanitize.service';
import { getCompanies } from '@/features/companies/companies.service';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import addDays from 'date-fns/addDays';

export default {
    components: {
        AppButton,
        AppFooter,
        AppLabel,
        AppPopup,
        AppBundle,
        AppMultiPicker,
        AppCheckbox,
        AppNumberLink,
        AppDateLink,
    },
    async created() {
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((calendar) => (this.agenda = calendar)),
            combineLatest([
                getProject(this.$route.params.projectId),
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getBundleMap(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
            ]).subscribe(([project, tasks, bundleMap, companies]) => {
                this.project = project;
                this.isTaskAdmin = project.me.allowedFeatures.includes('project_planning');
                const companyMap = getMapById(companies);
                this.tasks = (
                    this.isTaskAdmin
                        ? tasks
                        : tasks.filter((task) => project.me.bundleIds.includes(task.service.bundleId))
                ).map((task) => {
                    const bundle = bundleMap[task.service.bundleId];
                    return {
                        ...task,
                        bundle: bundle
                            ? {
                                  ...bundle,
                                  company: companyMap[bundleMap[task.service.bundleId].companyId],
                              }
                            : null,
                    };
                });
                this.init();
                this.loading = false;
            }),
            combineLatest([
                getLocationsTree(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([folders, bundles]) => {
                this.filterOptions = getFilterOptions((a) => this.$t(a), folders, bundles);
                this.filterAsArray = this.restoreFilter();
            }),
            getDirectories(this.$route.params.projectId).subscribe(
                (directories) => (this.directoryMap = getDirectoryNamePathMap(directories)),
            ),
        ];
    },
    watch: {
        $route() {
            this.init();
        },
    },
    computed: {
        taskTree() {
            const tasksByLocation = groupBy(this.filteredTasks, 'locationId');
            return tasksByLocation.map((tasksForALocation) => ({
                ...tasksForALocation[0].location,
                tasks: sortTasks(
                    tasksForALocation.map((task) => ({
                        ...task,
                        date: !task.realStartDate ? task.startDate : task.estimatedEndDate,
                        path: task.service.parentDirectoryId
                            ? this.directoryNameMap[task.service.parentDirectoryId]
                            : '',
                    })),
                    this.sortKey,
                ),
            }));
        },
        doneCount() {
            return (
                (this.filterAsArray.length > 0
                    ? this.filteredTasks.filter((item) => this.isDone(item)).length + '/'
                    : '') + this.tasks.filter((item) => this.isDone(item)).length
            );
        },
        farCount() {
            return (
                (this.filterAsArray.length > 0
                    ? this.filteredTasks.filter((item) => this.isFar(item)).length + '/'
                    : '') + this.tasks.filter((item) => this.isFar(item)).length
            );
        },
        comingCount() {
            return (
                (this.filterAsArray.length > 0
                    ? this.filteredTasks.filter((item) => this.isComing(item)).length + '/'
                    : '') + this.tasks.filter((item) => this.isComing(item)).length
            );
        },
        filteredTasks() {
            return filterTasks(this.tasks, this.filterAsArray).filter((task) => this.filterFn(task));
        },
    },
    methods: {
        init() {
            const lastVisitedTaskId = localStorage.getItem(
                'planning.lastVisitedTaskId.' + this.$route.params.projectId,
            );
            if (lastVisitedTaskId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedTaskId);
                    if (element) {
                        element.scrollIntoViewIfNeeded ? element.scrollIntoViewIfNeeded() : element.scrollIntoView();
                    }
                });
            }
        },
        saveFrom() {
            this.far = true;
            this.saveFilter();
        },
        saveFilter() {
            localStorage.setItem(
                'follow_planning_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterAsArray: this.filterAsArray,
                    done: this.done,
                    coming: this.coming,
                    far: this.far,
                    endDate: this.endDate,
                    sortKey: this.sortKey,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('follow_planning_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.done = typeof parsedCache.done == 'boolean' ? parsedCache.done : false;
                this.coming = typeof parsedCache.coming == 'boolean' ? parsedCache.coming : false;
                this.far = typeof parsedCache.far == 'boolean' ? parsedCache.far : false;
                this.endDate = parsedCache.endDate ? parsedCache.endDate : addDays(new Date(), 7);
                this.sortKey = parsedCache.sortKey || 'date';
                return parsedCache.filterAsArray || [];
            } else {
                return [];
            }
        },
        filterFn(task) {
            return (
                (this.isDone(task) && this.done) ||
                (this.isFar(task) && this.far) ||
                (this.isComing(task) && this.coming)
            );
        },
        isComing(task) {
            return !this.isDone(task) && (task.realStartDate || task.startDate <= this.now);
        },
        isDone(task) {
            return !!task.realEndDate;
        },
        isFar(task) {
            return !task.realStartDate && task.startDate > this.now && task.startDate < this.endDate;
        },
        updateTaskStartDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, startDate: date });
        },
        updateTaskEndDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, endDate: date });
        },
        updateTaskRealStartDate(task, date) {
            updateTask(this.$route.params.projectId, { id: task.id, realStartDate: date });
        },
        updateTaskProgress(task, progress) {
            if ((progress > 0 && progress < 100 && task.realStartDate) || progress === 0) {
                updateTask(this.$route.params.projectId, { id: task.id, progress });
            } else if (progress > 0 && progress < 100 && !task.realStartDate) {
                updateTask(this.$route.params.projectId, {
                    id: task.id,
                    progress,
                    realStartDate: this.now,
                });
            } else if (progress >= 100 && !task.realEndDate) {
                updateTask(this.$route.params.projectId, {
                    id: task.id,
                    progress: 100,
                    realEndDate: this.now,
                });
            } else if (progress >= 100) {
                updateTask(this.$route.params.projectId, { id: task.id, progress: 100 });
            }
        },
        updateTaskRealEndDate(task, date) {
            if (!task.realEndDate) {
                updateTask(this.$route.params.projectId, {
                    id: task.id,
                    realEndDate: date,
                    progress: 100,
                });
            } else {
                updateTask(this.$route.params.projectId, { id: task.id, realEndDate: date });
            }
        },
    },
    data() {
        return {
            loading: true,
            now: new Date(),
            isTaskAdmin: false,
            filterAsArray: [],
            filterOptions: [],
            agenda: [],
            directoryNameMap: {},
            tasks: [],
            done: false,
            coming: true,
            far: false,
            endDate: addDays(new Date(), 7),
            sortKey: 'date',
        };
    },
};
</script>
