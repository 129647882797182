<template>
    <div
        class="flex flex-col items-center justify-center w-full text-xs flex-wrap gap-2"
        v-if="plannedValues && plannedValues.length"
    >
        <div class="max-w-2xl w-full flex text-center gap-8">
            <div class="flex justify-center w-full" v-if="loading">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <div
                class="flex flex-col flex-grow border sm:p-6 border-blue-500 bg-gray-100"
                v-if="plannedValues && plannedValues.length"
            >
                <span class="sm:text-xl font-bold">{{ $t('dashboard.realEndDate') }}</span>
                <span class="sm:text-lg text-blue-500">
                    {{ plannedValues[plannedValues.length - 1].x | humanizeDate }}
                </span>
            </div>
            <div
                class="flex flex-col flex-grow border sm:p-6 border-green-500 bg-gray-100"
                v-if="referenceValues && referenceValues.length"
            >
                <span class="sm:text-xl font-bold">{{ $t('dashboard.referenceEndDate') }}</span>
                <span class="sm:text-lg text-green-500">
                    {{ referenceValues[referenceValues.length - 1].x | humanizeDate }}
                </span>
            </div>
            <div
                class="flex flex-col flex-grow border sm:p-6 bg-gray-100"
                :class="{ 'border-green-500': late <= 0, 'border-red-500': late > 0 }"
                v-if="referenceValues && referenceValues.length"
            >
                <span class="sm:text-xl font-bold" v-if="late > 0">{{ $t('dashboard.lateTitle') }}</span>
                <span class="sm:text-xl font-bold" v-else>{{ $t('dashboard.advanceTitle') }}</span>
                <span class="sm:text-lg" :class="{ 'text-green-500': late <= 0, 'text-red-500': late > 0 }">
                    {{ late > 0 ? '+' + late : -late }}jo
                </span>
            </div>
        </div>
        <h3 class="mt-4 sm:text-lg font-bold">{{ $t('dashboard.burnDownTitle') }}</h3>
        <app-line-chart
            :eventHandlers="eventHandlers"
            class="max-w-5xl w-full dashboard-burn-down"
            :config="burnDownConfig"
        ></app-line-chart>
    </div>
</template>

<script>
import AppLineChart from '@/components/app-lineChart/AppLineChart';
import { format } from 'date-fns';
import { FixedScaleAxis, AutoScaleAxis } from 'chartist';
import ctAxisTitle from 'chartist-plugin-axistitle';
import { getEndDate } from '@/features/tasks/plannedTasks.service';
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { getLastReference } from '@/features/planning/references/references.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';

let minGridY = 0;
let maxGridY = 0;

export default {
    components: { AppLineChart },
    computed: {
        late() {
            if (
                this.referenceValues &&
                this.referenceValues.length > 0 &&
                this.plannedValues &&
                this.plannedValues.length > 0
            ) {
                const estimatedLastTask = this.tasks.reduce(
                    (acc, task) => (task.estimatedEndDate > acc.estimatedEndDate ? task : acc),
                    this.tasks[0],
                );
                const referenceLastTask = this.tasks.reduce(
                    (acc, task) => (task.referenceEndDate > acc.referenceEndDate ? task : acc),
                    this.tasks[0],
                );
                return differenceInBusinessDays(estimatedLastTask.estimatedEndDate, referenceLastTask.referenceEndDate);
            } else return '';
        },
    },
    methods: {
        onDraw(data) {
            if (data.type === 'grid') {
                minGridY = data.y1 < minGridY ? data.y1 : minGridY;
                maxGridY = data.y2 > maxGridY ? data.y2 : maxGridY;
            } else if (data.type === 'point') {
                if (data.series.name === 'today') {
                    data.group.elem(
                        'line',
                        {
                            x1: data.x,
                            y1: minGridY + 90,
                            x2: data.x,
                            y2: maxGridY,
                        },
                        'ct-line',
                    );
                    data.group
                        .elem(
                            'text',
                            {
                                x: data.x + 5,
                                y: 75,
                                style: 'text-anchor: middle',
                            },
                            'ct-text',
                        )
                        .text(`${this.$t('dashboard.today')} (${format(new Date(data.value.x), 'dd/MM')})`);
                } else if (data.series.name === 'theorical') {
                    data.group.elem(
                        'line',
                        {
                            x1: data.x,
                            x2: data.x,
                            y1: minGridY,
                            y2: maxGridY,
                        },
                        'ct-line',
                    );
                    if (data.index === 0) {
                        data.group
                            .elem(
                                'text',
                                {
                                    x: data.x + 5,
                                    y: 15,
                                    style: 'text-anchor: start',
                                },
                                '',
                            )
                            .text(
                                `${this.$t('dashboard.projectStartDate')} (${format(new Date(data.value.x), 'dd/MM')})`,
                            );
                    } else if (data.index === 1) {
                        data.group
                            .elem(
                                'text',
                                {
                                    x: data.x - 2,
                                    y: 15,
                                    style: 'text-anchor: end',
                                },
                                'ct-text',
                            )
                            .text(
                                `${this.$t('dashboard.projectEndDate')} (${format(new Date(data.value.x), 'dd/MM')})`,
                            );
                    }
                } else if (data.series.name === 'real' && data.index === data.series.data.length - 1) {
                    data.group.elem(
                        'line',
                        {
                            x1: data.x,
                            y1: minGridY + 20,
                            x2: data.x,
                            y2: maxGridY,
                        },
                        'ct-line dash-array',
                    );

                    data.group
                        .elem(
                            'text',
                            {
                                x: data.x - 2,
                                y: 30,
                                style: 'text-anchor: end',
                            },
                            'ct-text',
                        )
                        .text(`${this.$t('dashboard.realEndDate')} (${format(new Date(data.value.x), 'dd/MM')})`);
                } else if (data.series.name === 'reference' && data.index === data.series.data.length - 1) {
                    data.group.elem(
                        'line',
                        {
                            x1: data.x,
                            y1: minGridY + 35,
                            x2: data.x,
                            y2: maxGridY,
                        },
                        'ct-line dash-array',
                    );

                    data.group
                        .elem(
                            'text',
                            {
                                x: data.x - 2,
                                y: 45,
                                style: 'text-anchor: end',
                            },
                            'ct-text',
                        )
                        .text(`${this.$t('dashboard.referenceShort')} (${format(new Date(data.value.x), 'dd/MM')})`);
                }
            }
        },
        computePlannedAbstract(tasks, agenda, reduceFn) {
            const result = [];
            let remainingTasks = tasks
                .filter((task) => task.startDate && task.endDate)
                .map((task) => ({
                    startDate: task.startDate.getTime(),
                    realStartDate: task.realStartDate ? task.realStartDate.getTime() : null,
                    realEndDate: task.realEndDate ? task.realEndDate.getTime() : null,
                    endDate: task.endDate.getTime(),
                    estimatedEndDate: getEndDate(task, agenda, new Date()).getTime(),
                    id: task.id,
                }));
            const keyDates = [];
            for (let task of remainingTasks) {
                if (!keyDates.includes(task.startDate)) {
                    keyDates.push(task.startDate);
                }
                if (!keyDates.includes(task.endDate)) {
                    keyDates.push(task.endDate);
                }
                if (task.realEndDate && !keyDates.includes(task.realEndDate)) {
                    keyDates.push(task.realEndDate);
                }
                if (task.estimatedEndDate && !keyDates.includes(task.estimatedEndDate)) {
                    keyDates.push(task.estimatedEndDate);
                }
            }
            let lastPoint = 0;
            let percentMax = 0;

            for (let currentDate of keyDates.sort()) {
                //const date = new Date(currentDate);
                const remainingNbDays =
                    remainingTasks.reduce((acc, task) => {
                        //const currentAcc = acc / (3600 * 1000 * 24);
                        return reduceFn(acc, task, currentDate);
                    }, 0) /
                    (3600 * 1000 * 24);
                if (!percentMax) {
                    percentMax = remainingNbDays;
                }
                const y = (remainingNbDays / percentMax) * 100;
                if (y !== lastPoint) {
                    result.push({ x: new Date(currentDate), y });
                }
                lastPoint = y;
            }
            return result;
        },
        computeReferenceBurnDown(tasks, agenda) {
            return this.computePlannedAbstract(tasks, agenda, (acc, task, currentDate) => {
                if (task.startDate >= currentDate) {
                    return acc + task.endDate - task.startDate + 3600 * 1000 * 24;
                } else if (task.endDate < currentDate) {
                    return acc;
                } else if (task.startDate <= currentDate && currentDate <= task.endDate) {
                    return acc + task.endDate - currentDate;
                } else {
                    return acc;
                }
            });
        },
        computeRealBurnDown(tasks, agenda) {
            return this.computePlannedAbstract(tasks, agenda, (acc, task, currentDate) => {
                let diff = 0;
                if (task.estimatedEndDate < currentDate) {
                    diff = 0;
                } else if (
                    task.realStartDate &&
                    task.realStartDate <= currentDate &&
                    task.estimatedEndDate >= currentDate
                ) {
                    diff = task.estimatedEndDate - currentDate;
                } else {
                    diff = (task.estimatedEndDate || task.endDate) - (task.realStartDate || task.startDate);
                }
                if (diff < 0) {
                    return acc; //case with errored date (ex: realEndDate but no realStartDate, or ending dates before start dates)
                } else {
                    return acc + diff;
                }
            });
        },
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getProject(this.$route.params.projectId),
                getCalendar(this.$route.params.projectId),
                getLastReference(this.$route.params.projectId),
            ]).subscribe(([tasks, project, calendar, lastReference]) => {
                this.tasks = tasks;
                if (
                    !(project.startDate && project.endDate && project.startDate.getTime() < project.endDate.getTime())
                ) {
                    return;
                }
                const realData = this.computeRealBurnDown(tasks, calendar);
                const projectData = [
                    { x: project.startDate, y: 100 },
                    { x: project.endDate, y: 0 },
                ];
                let todayData = [];
                if (
                    project.startDate.getTime() < new Date().getTime() &&
                    new Date().getTime() < project.endDate.getTime()
                ) {
                    todayData = [{ x: new Date().getTime(), y: 0 }];
                }

                let referenceData = [];
                if (lastReference) {
                    referenceData = this.computeReferenceBurnDown(lastReference.dates, calendar);
                }
                this.burnDownConfig.data.series = [
                    { name: 'today', data: todayData },
                    { name: 'theorical', data: projectData },
                    { name: 'real', data: realData },
                    { name: 'reference', data: referenceData },
                ];
                this.referenceValues = referenceData;
                this.plannedValues = realData;
                this.maxRealDate = new Date(realData[realData.length - 1] ? realData[realData.length - 1].x : null);
                this.minRealDate = new Date(realData[0] ? realData[0].x : null);

                this.loading = false;
            }),
        ];
    },
    data() {
        return {
            loading: true,
            tasks: [],
            referenceValues: null,
            plannedValues: [],
            eventHandlers: [
                {
                    event: 'draw',
                    fn: this.onDraw,
                },
            ],
            minGridY: 0,
            maxGridY: 0,
            minRealDate: null,
            maxRealDate: null,
            subscriptions: [],
            burnDownConfig: {
                data: {
                    series: [[5, 9, 7, 8, 5, 3, 5, 4]],
                },
                options: {
                    low: 0,
                    showArea: true,
                    showLine: false,
                    showPoint: false,
                    divisor: 7,
                    lineSmooth: false,
                    axisY: {
                        type: FixedScaleAxis,
                        showLabel: true,
                        divisor: 10,
                        high: 100,
                        low: 0,
                        //ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                    },
                    axisX: {
                        type: AutoScaleAxis,
                        divisor: 2,
                        scaleMinSpace: 35,
                        labelInterpolationFnc: function (value) {
                            return format(value, 'dd/MM');
                        },
                    },
                    series: {
                        today: {
                            showPoint: true,
                            showLine: true,
                            showArea: false,
                        },
                        theorical: {
                            showPoint: true,
                            showLine: false,
                            showArea: false,
                        },
                        real: {
                            showPoint: true,
                            showLine: true,
                            showArea: false,
                        },
                        reference: {
                            showPoint: true,
                            showLine: true,
                            showArea: false,
                        },
                    },
                    plugins: [
                        ctAxisTitle({
                            axisX: {
                                axisTitle: this.$t('dashboard.timeAxis'),
                                offset: { x: 0, y: 30 },
                            },
                            axisY: {
                                axisTitle: this.$t('dashboard.loadAxis'),
                                offset: { x: 0, y: 10 },
                                flipTitle: true,
                            },
                        }),
                    ],
                },
            },
        };
    },
};
</script>
<style>
.dashboard-burn-down .ct-point,
.dashboard-burn-down .ct-line {
    stroke-width: 1px;
}

.dashboard-burn-down .ct-series-a .ct-line,
.dashboard-burn-down .ct-series-a .ct-point {
    stroke: red;
}

.dashboard-burn-down .ct-series-a .ct-text {
    fill: red;
}

.dashboard-burn-down .ct-series-b .ct-line {
    stroke: black;
    stroke-width: 1px;
}

.dashboard-burn-down .ct-series-c .ct-area {
    fill: #386da5;
    opacity: 0.9;
}

.dashboard-burn-down .ct-series-c .ct-line,
.dashboard-burn-down .ct-series-c .ct-point {
    stroke: #386da5;
}

.dashboard-burn-down .ct-series-c .ct-text {
    fill: #386da5;
}

.dashboard-burn-down .ct-series-d .ct-line,
.dashboard-burn-down .ct-series-d .ct-point {
    stroke: #2e882b;
}
.dashboard-burn-down .ct-series-d .ct-text {
    fill: #2e882b;
}
.dashboard-burn-down .dash-array {
    stroke-dasharray: 4;
}
</style>
