<template>
    <ObservationPage @back="backToReception" @deleted="backToReception" />
</template>

<script>
import ObservationPage from '../observations/ObservationPage';
export default {
    components: {
        ObservationPage,
    },
    methods: {
        backToReception() {
            this.$router.push({
                name: 'followReception',
                params: {
                    projectId: this.$route.params.projectId,
                    receptionId: this.$route.params.receptionId,
                },
            });
        },
    },
};
</script>
