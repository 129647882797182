<template>
    <div class="flex gap-4 md:gap-6 flex-col text-sm">
        <!--  <div class="flex gap-2 flex-col items-center" v-if="byRecipientStats.labels && bundles.length > 1">
            <h3 class="sm:text-base font-bold p-2">{{ $t('dashboard.observationsByBundles') }}</h3>
            <follow-observation-bar :data="byRecipientStats" class="w-full" />
        </div>-->
        <table class="w-full border-gray-300 border-solid border" v-if="!isMobile">
            <thead>
                <tr>
                    <th class="p-1">
                        <span>{{ $t('observations.bundleHeader') }}</span>
                    </th>
                    <!--
                    <th class="p-1 observations-to-do">
                        <span>{{ $t('observations.toDo') }}</span>
                    </th>-->
                    <th class="p-1 observations-to-fix">
                        <span>{{ $t('observations.toFix') }}</span>
                    </th>
                    <th class="p-1 observations-done">
                        <span>{{ $t('observations.done') }}</span>
                    </th>
                    <th class="p-1 observations-validated">
                        <span>{{ $t('observations.validated') }}</span>
                    </th>
                    <th class="p-1">
                        <span>{{ $t('observations.total') }}</span>
                    </th>
                </tr>
            </thead>
            <tr v-for="(label, index) in byRecipientStats.labels">
                <td class="p-1 text-sm border-gray-300 border-solid border">{{ label }}</td>
                <!--<td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[0].data[index] }}
                </td>-->
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[1].data[index] + byRecipientStats.series[0].data[index] }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[2].data[index] }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[3].data[index] }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{
                        byRecipientStats.series[0].data[index] +
                        byRecipientStats.series[1].data[index] +
                        byRecipientStats.series[2].data[index] +
                        byRecipientStats.series[3].data[index]
                    }}
                </td>
            </tr>
            <tr>
                <td class="p-1 text-right border-gray-300 border-solid border">
                    <span>{{ $t('observations.total') }}</span>
                </td>
                <!--
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[0].data.reduce((acc, line) => acc + line, 0) }}
                </td>-->
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{
                        byRecipientStats.series[1].data.reduce((acc, line) => acc + line, 0) +
                        byRecipientStats.series[0].data.reduce((acc, line) => acc + line, 0)
                    }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[2].data.reduce((acc, line) => acc + line, 0) }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{ byRecipientStats.series[3].data.reduce((acc, line) => acc + line, 0) }}
                </td>
                <td class="p-1 text-center border-gray-300 border-solid border">
                    {{
                        byRecipientStats.series[0].data.reduce((acc, line) => acc + line, 0) +
                        byRecipientStats.series[1].data.reduce((acc, line) => acc + line, 0) +
                        byRecipientStats.series[2].data.reduce((acc, line) => acc + line, 0) +
                        byRecipientStats.series[3].data.reduce((acc, line) => acc + line, 0)
                    }}
                </td>
            </tr>
        </table>

        <table class="w-full border-gray-300 border-solid border" v-if="isMobile">
            <template v-for="(label, index) in byRecipientStats.labels">
                <tr v-if="index > 0"><td class="">&nbsp;</td></tr>
                <tr>
                    <td rowspan="4" class="p-1 text-sm border-gray-300 border-solid border align-top">
                        {{ label }}
                    </td>
                    <td class="p-1 observations-to-do text-xs border-solid border">
                        <span class="mr-1">
                            {{ byRecipientStats.series[0].data[index] + byRecipientStats.series[1].data[index] }}
                        </span>
                        <span class="truncate">{{ $t('observations.toFix') }}</span>
                    </td>
                </tr>
                <!--
                <tr class="my-2">
                    <td class="p-1 observations-to-fix text-xs border-solid border">
                        <span class="mr-1">{{ byRecipientStats.series[1].data[index] }}</span>
                        <span class="truncate">{{ $t('observations.toFix') }}</span>
                    </td>
                </tr>-->
                <tr>
                    <td class="p-1 observations-done text-xs border-solid border">
                        <span class="mr-1">{{ byRecipientStats.series[2].data[index] }}</span>
                        <span class="truncate">{{ $t('observations.done') }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="p-1 observations-validated text-xs border-solid border">
                        <span class="mr-1">{{ byRecipientStats.series[3].data[index] }}</span>
                        <span class="truncate">{{ $t('observations.validated') }}</span>
                    </td>
                </tr>
                <tr>
                    <td class="p-1 border-solid border">
                        <div class="text-xs">
                            <span class="mr-1">{{ $t('commons.total') }} :</span>
                            <span>
                                {{
                                    byRecipientStats.series[0].data[index] +
                                    byRecipientStats.series[1].data[index] +
                                    byRecipientStats.series[2].data[index] +
                                    byRecipientStats.series[3].data[index]
                                }}
                            </span>
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import FollowObservationBar from '@/features/follow/FollowObservationBar';
import { isMobile } from '@/state/state';
import { sortBy } from '@/services/sanitize.service';
import { BUNDLE_TYPE_ORDER } from '@/features/bundles/bundles.service';
export default {
    components: { FollowObservationBar },
    props: {
        items: Array,
        bundles: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    created() {
        this.updateData();
    },
    watch: {
        items() {
            this.updateData();
        },
        bundles() {
            this.updateData();
        },
    },
    methods: {
        updateData() {
            const bundleWithObservations = sortBy(
                this.bundles.map((bundle) => ({
                    ...bundle,
                    observations: this.items.filter(
                        (observation) =>
                            observation.recipientIds.includes(bundle.id) &&
                            observation.type !== 'administrative' &&
                            observation.type !== 'private' &&
                            observation.type !== 'general' &&
                            observation.type !== 'preparationVisa',
                    ),
                })),
                (bundle) =>
                    BUNDLE_TYPE_ORDER.indexOf(bundle.type) +
                    '_' +
                    bundle.observations.length.toString().padStart(4, '0') +
                    '_' +
                    bundle.reference,
            );
            this.byRecipientStats = {
                labels: bundleWithObservations.map((bundle) => bundle.label),
                series: [
                    {
                        label: this.$t('observations.toFix'),
                        data: this.sumFilterGroupObservation(
                            bundleWithObservations,
                            (obs) => (!obs.dueDate || obs.dueDate > Date.now()) && !obs.resolvedAt,
                        ),
                    },
                    {
                        label: this.$t('commons.late'),
                        data: this.sumFilterGroupObservation(
                            bundleWithObservations,
                            (obs) => obs.dueDate && obs.dueDate <= Date.now() && !obs.resolvedAt,
                        ),
                    },
                    {
                        label: this.$t('observations.done'),
                        data: this.sumFilterGroupObservation(
                            bundleWithObservations,
                            (obs) => obs.resolvedAt && !obs.validatedAt,
                        ),
                    },
                    {
                        label: this.$t('observations.validated'),
                        data: this.sumFilterGroupObservation(
                            bundleWithObservations,
                            (obs) => obs.resolvedAt && obs.validatedAt,
                        ),
                    },
                ],
            };
        },
        sumFilterGroupObservation(bundleWithObservations, filterFn) {
            return bundleWithObservations.map((bundle) => bundle.observations.filter(filterFn).length);
        },
    },
    data() {
        return {
            isMobile: isMobile,
            byCompanyOptions: {
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                scales: {
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                            },
                            type: 'linear',
                        },
                    ],
                },
                responsive: true,
                maintainAspectRatio: true,
                legend: { position: 'bottom' },
                title: {
                    display: true,
                    fontSize: 9,
                    text: this.$t('observations.byCompanyChart.title'),
                },
            },
            byRecipientOptions: {
                tooltips: {
                    displayColors: true,
                    callbacks: {
                        mode: 'x',
                    },
                },
                scales: {
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                            },
                            type: 'linear',
                        },
                    ],
                },
                responsive: true,
                maintainAspectRatio: true,
                legend: { position: 'bottom' },
                title: {
                    display: true,
                    fontSize: 9,
                    text: this.$t('observations.byRecipientChart.title'),
                },
            },
            byRecipientStats: {
                series: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }],
            },
        };
    },
};
</script>
