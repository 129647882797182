import { get, getMapById, sortBy } from '@/services/sanitize.service';
import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getAPIHeaders } from '@/services/api.service';

export function bundlesToGroups(bundles, categoryAccessor = 'category') {
    const result = {
        company: [],
        distributor: [],
        MOA: [],
        AMOA: [],
        MOE: [],
        generalContractor: [],
    };
    for (const bundle of bundles) {
        if (get(bundle, categoryAccessor) === 'company') {
            result.company.push(bundle);
        } else if (get(bundle, categoryAccessor) === 'MOA') {
            result.MOA.push(bundle);
        } else if (get(bundle, categoryAccessor) === 'MOE') {
            result.MOE.push(bundle);
        } else if (get(bundle, categoryAccessor) === 'generalContractor') {
            result.generalContractor.push(bundle);
        } else if (get(bundle, categoryAccessor) === 'AMOA') {
            result.AMOA.push(bundle);
        } else if (get(bundle, categoryAccessor) === 'distributor') {
            result.distributor.push(bundle);
        }
    }
    return result;
}
function mapBundle(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              marketDate: item.marketDate ? new Date(item.marketDate) : null,
              serviceOrderDate: item.serviceOrderDate ? new Date(item.serviceOrderDate) : null,
              label:
                  item.type === 'company'
                      ? (item.reference ? item.reference.padStart(3, '0') + ' - ' : '') + item.name
                      : (item.reference ? item.reference + ' - ' : '') + item.name,
          }
        : null;
}
export function getBundles(projectId) {
    return db
        .getProjectCollections(projectId)
        .bundles.find()
        .$.pipe(map((bundlesDb) => sortBundles(bundlesDb.map(mapBundle))));
}
export function getBundleMap(projectId) {
    return db
        .getProjectCollections(projectId)
        .bundles.find()
        .$.pipe(map((bundlesDb) => getMapById(bundlesDb.map(mapBundle))));
}
export function getBundleOffline(projectId, bundleId) {
    return db
        .getProjectCollections(projectId)
        .bundles.findOne({ selector: { id: bundleId } })
        .$.pipe(map(mapBundle));
}
export function getNewReference(existingEntities, padding, offset = 0) {
    const newReference = (existingEntities.length + 1 + offset).toString().padStart(padding, '0');
    if (existingEntities.some((entity) => entity.reference === newReference)) {
        return getNewReference(existingEntities, padding, ++offset);
    } else {
        return newReference;
    }
}

export function removeBundle(projectId, bundleId) {
    return db
        .getProjectCollections(projectId)
        .bundles.findOne({ selector: { id: bundleId } })
        .remove();
}
const typeToCategory = {
    company: 'company',
    MOA: 'MOA',
    AMOA: 'MOA',
    MOE: 'MOE',
    BE: 'MOE',
    CT: 'MOE',
    SPS: 'MOE',
    generalContractor: 'generalContractor',
    distributor: 'distributor',
};
export function initNewBundle(type, bundles, translateFn) {
    const existingBundleOfThisType = bundles.filter((aBundle) => aBundle.type === type);
    if (type === 'company') {
        const newReference = getNewReference(existingBundleOfThisType, 3);
        return {
            reference: newReference,
            name: translateFn('bundles.newNamePrefix.' + type) + newReference,
            type,
            category: typeToCategory[type],
            licensedUsersEmail: '',
            marketAmount: 0,
            visaDelay: 5,
            index: 0,
        };
    } else {
        const newReference = getNewReference(existingBundleOfThisType, 1);
        return {
            reference: translateFn('bundles.newNamePrefix.' + type) + newReference,
            name: translateFn('bundles.types.' + type) + ' ' + newReference,
            type,
            category: typeToCategory[type],
            licensedUsersEmail: '',
            marketAmount: 0,
            visaDelay: 5,
        };
    }
}
function sanitizeBundle(bundle) {
    const result = { ...bundle };
    if (bundle.marketDate) {
        result.marketDate = bundle.marketDate.toISOString();
    }
    if (bundle.serviceOrderDate) {
        result.serviceOrderDate = bundle.serviceOrderDate.toISOString();
    }
    return result;
}
export const BUNDLE_TYPE_ORDER = ['MOA', 'AMOA', 'MOE', 'generalContractor', 'BE', 'CT', 'SPS', 'company'];
export function sortBundles(bundles) {
    return sortBy(
        bundles,
        (bundle) =>
            BUNDLE_TYPE_ORDER.indexOf(bundle.type) + '_' + (bundle.index + '').padStart(3, '0') + bundle.reference,
    );
}
export async function createBundle(projectId, bundle) {
    const result = await db.getProjectCollections(projectId).bundles.insert({
        contactIds: [],
        companyId: null,
        ...sanitizeBundle(bundle),
    });
    return result.toMutableJSON();
}

export async function updateBundle(projectId, bundle) {
    const dbBundle = await db
        .getProjectCollections(projectId)
        .bundles.findOne({ selector: { id: bundle.id } })
        .exec();
    return dbBundle.atomicPatch(sanitizeBundle(bundle));
}

export async function fetchBundles(projectId) {
    const response = await fetch(`/api/projects/${projectId}/bundles`, {
        headers: getAPIHeaders(),
    });
    const bundles = await response.json();
    return bundles.map((bundle) => ({
        ...bundle,
        label:
            bundle.type === 'company'
                ? (bundle.reference ? bundle.reference.padStart(3, '0') + ' - ' : '') + bundle.name
                : (bundle.reference ? bundle.reference + ' - ' : '') + bundle.name,
    }));
}

export function getBundleIdMapByReference(bundles) {
    return bundles
        .filter((bundle) => bundle.reference && bundle.reference.length > 0)
        .reduce((acc, bundle) => ({ ...acc, [bundle.reference]: bundle.id }), {});
}
