<template>
    <div class="border" :class="{ hidden: paid || paypalFailed || loading }">
        <app-separator :label="$t('order.project.licenses.payNow')"></app-separator>
        <div class="flex justify-center items-center text-sm gap-2 p-2">
            <slot></slot>
        </div>
        <div id="paypal-button-container" style="padding: 20px"></div>
        <div class="flex flex-row">
            <h2 class="split_title"></h2>
            <span class="split_title_span">ou</span>
            <h2 class="split_title"></h2>
        </div>
        <div class="card_container">
            <form id="card-form" class="flex flex-col">
                <div>
                    <label for="card-number">Numéro de carte</label>
                    <div id="card-number" class="card_field"></div>
                </div>

                <div style="display: flex; flex-direction: row">
                    <div>
                        <label for="expiration-date">Date d'expiration</label>
                        <div id="expiration-date" class="card_field"></div>
                    </div>
                    <div style="margin-left: 10px">
                        <label for="cvv">Cryptogramme / CVV</label>
                        <div id="cvv" class="card_field"></div>
                    </div>
                </div>
                <div>
                    <label for="card-holder-name">Nom</label>
                    <input
                        type="text"
                        id="card-holder-name"
                        name="card-holder-name"
                        autocomplete="off"
                        placeholder="Name on Card"
                        class="card-field"
                        style="font-family: helvetica; font-size: 14px"
                    />
                </div>
                <div class="flex justify-center">
                    <button
                        value="submit"
                        id="submit"
                        class="btn"
                        style="height: 50px; background: #009c74; color: white"
                    >
                        Payer
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';
import { getAPIHeaders } from '@/services/api.service';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import { error, info } from '@/features/toasts/toats.service';
import { reportError } from '@/features/tracker/tracker.service';

export default {
    components: { AppPopup, AppSeparator },
    async created() {
        try {
            const { clientToken, clientId } = await fetch(
                `/api/projects/${this.$route.params.projectId}/licensesOrders/${this.licensesOrderId}/paypalClientToken`,
                { headers: getAPIHeaders() },
            ).then((response) => response.json());
            const paypal = await loadScript({
                'client-id': clientId,
                commit: true,
                currency: 'EUR',
                components: 'buttons,hosted-fields',
                'data-client-token': clientToken,
                'enable-funding': 'card,paylater',
            });
            await paypal
                .Buttons({
                    createOrder: () => this.createOrder(),
                    onApprove: () => this.captureOrder(),
                })
                .render('#paypal-button-container');

            if (paypal.HostedFields.isEligible()) {
                paypal.HostedFields.render({
                    createOrder: () => this.createOrder(),
                    styles: {
                        '.valid': {
                            color: 'green',
                        },
                        '.invalid': {
                            color: 'red',
                        },
                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: '4111 1111 1111 1111',
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: '123',
                        },
                        expirationDate: {
                            selector: '#expiration-date',
                            placeholder: 'MM/YY',
                        },
                    },
                }).then((cardFields) => {
                    document.querySelector('#card-form').addEventListener('submit', (event) => {
                        event.preventDefault();
                        cardFields
                            .submit({
                                // Cardholder's first and last name
                                cardholderName: document.getElementById('card-holder-name').value,
                                contingencies: ['SCA_ALWAYS'],
                            })
                            .then(() => this.captureOrder())
                            .catch((err) => {
                                const msg = err.details ? err.details[0]?.description : err;
                                reportError(msg + ' ' + err.debug_id);
                                return error(this.$t('order.project.licenses.transactionFailed'));
                            });
                    });
                });
                this.loading = false;
            } else {
                reportError('hostedFields are not eligible');
            }
        } catch (e) {
            reportError('paypal disabled');
            this.paypalFailed = true;
        }
    },
    watch: {
        licensesOrderId() {
            this.paid = false;
        },
    },
    props: {
        licensesOrderId: String,
    },
    methods: {
        createOrder() {
            return fetch(
                `/api/projects/${this.$route.params.projectId}/licensesOrders/${this.licensesOrderId}/paypalOrders`,
                { method: 'post', headers: getAPIHeaders() },
            )
                .then((response) => response.json())
                .then((orderData) => {
                    this.orderId = orderData.id;
                    return orderData.id;
                });
        },
        captureOrder() {
            return fetch(
                `/api/projects/${this.$route.params.projectId}/licensesOrders/${this.licensesOrderId}/paypalOrders/${this.orderId}`,
                { method: 'put', headers: getAPIHeaders() },
            )
                .then((response) => response.json())
                .then((orderData) => {
                    const errorDetail = Array.isArray(orderData.details) && orderData.details[0];
                    if (errorDetail) {
                        let msg = 'Sorry, your transaction could not be processed.';
                        if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                        if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                        reportError(msg);
                        return error(this.$t('order.project.licenses.transactionFailed'));
                    }
                    info(this.$t('order.project.licenses.transactionCompleted'));
                    this.paid = true;
                    this.$emit('paid');
                });
        },
    },
    data() {
        return {
            paypal: null,
            paid: false,
            loading: true,
            paypalFailed: false,
            orderId: null,
        };
    },
};
</script>
<style scoped>
.split_title {
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 10px 20px;
    max-width: 500px;
    width: 100%;
}
/*
  from https://www.paypalobjects.com/webstatic/en_US/developer/docs/css/cardfields.css
*/
.paypal-button-container {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}
.card_container {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}
.card_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}
.card_field_50 {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}
.card_field_75 {
    width: 75%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}
.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
}
.col-25 {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
}
.col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
}
input[type='text'],
select,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    height: 40px;
    background: white;
    font-size: 17px;
    color: #3a3a3a;
    font-family: helvetica, tahoma, calibri, sans-serif;
}
input[type='submit'] {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.message_container {
    border-radius: 5px;
    background: #ffffff;
    font-size: 13px;
    font-family: monospace;
    padding: 20px;
}
#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    text-align: center;
}
#loading-image {
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -150px;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px; /* half width of the spinner gif */
    margin-top: -50px; /* half height of the spinner gif */
    text-align: center;
    z-index: 1234;
    overflow: auto;
    width: 100px; /* width of the spinner gif */
    height: 102px; /* height of the spinner gif +2px to fix IE8 issue */
}
.button_container {
    display: flex;
    justify-content: center;
}
button:hover {
    background-color: powderblue;
}
button {
    width: 229px;
    height: 49px;
    background: lightblue;
    border: 1px dotted black;
    font-size: 17px;
    color: #3a3a3a;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
}
.btn_small {
    width: 130px;
    height: 39px;
    background: lightblue;
    border: 1px dotted black;
    font-size: 14px;
    color: #3a3a3a;
}
.btn_small:hover {
    background-color: powderblue;
}
</style>
