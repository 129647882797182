<template>
    <table class="table-fixed w-full">
        <thead class="bg-white table-header-group">
            <tr>
                <th style="width: 5rem" class="text-left border-r p-1">
                    {{ $t('preparations.type') }}
                </th>
                <th class="text-left border-r p-1">{{ $t('preparations.name') }}</th>
                <th style="width: 3rem" class="text-center border-r p-1">
                    <div :title="$t('commons.version')">
                        {{ $t('commons.versionShort') }}
                    </div>
                </th>
                <th class="text-center border-r p-1" style="width: 6rem">
                    {{ $t('preparations.emissionDueDateShort') }}
                </th>
                <th class="text-center border-r p-1" style="width: 6rem">
                    {{ $t('preparations.emissionDateShort') }}
                </th>
                <th v-for="reviewer in reviewers" style="width: 7rem" class="border-r">
                    {{ $t('preparations.visas') }}
                    <div
                        :title="reviewer.name + (reviewer.company ? ' (' + reviewer.company.name + ')' : '')"
                        class="truncate"
                    >
                        {{ reviewer.reference || reviewer.name }}
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="group in groups">
                <template v-for="(preparation, index) in group.versions">
                    <template v-if="showAllVersions || index === 0">
                        <tr class="odd:bg-blue-50" :class="{ 'border-t border-black': index === 0 && showAllVersions }">
                            <td class="border-r p-1">
                                <span v-if="index === 0">
                                    {{ preparation.type ? $t('preparations.types.' + preparation.type) : '' }}
                                </span>
                            </td>
                            <td class="border-r p-1">
                                <span v-if="index === 0">
                                    <span v-if="preparation.code" class="mr-1">#{{ preparation.code }}</span>
                                    <span class="font-bold">{{ preparation.name }}</span>
                                </span>
                            </td>
                            <td class="text-center border-r p-1">
                                {{ group.versions.length - index }}
                            </td>
                            <td
                                class="text-center border-r p-1"
                                :class="{
                                    'text-red-600 font-bold':
                                        !preparation.emissionDate && preparation.emissionDueDate < new Date(),
                                }"
                            >
                                {{ preparation.emissionDueDate | humanizeDate }}
                            </td>
                            <td class="text-center border-r p-1">
                                {{ preparation.emissionDate | humanizeDate }}
                            </td>
                            <td v-for="reviewer in reviewers" class="border-r">
                                <div
                                    class="flex justify-start items-center p-1"
                                    v-if="
                                        getVisaFromReviewer(preparation.visas, reviewer) &&
                                        getVisaFromReviewer(preparation.visas, reviewer).emissionDate
                                    "
                                >
                                    <app-preparation-visa-status
                                        :preparationEmissionDate="preparation.emissionDate"
                                        :visa="getVisaFromReviewer(preparation.visas, reviewer)"
                                    ></app-preparation-visa-status>
                                    <span class="ml-1">
                                        {{
                                            getVisaFromReviewer(preparation.visas, reviewer).emissionDate | humanizeDate
                                        }}
                                    </span>
                                </div>
                                <div class="flex justify-center p-1" v-else>
                                    <app-preparation-visa-status
                                        :preparationEmissionDate="preparation.emissionDate"
                                        :visa="getVisaFromReviewer(preparation.visas, reviewer)"
                                    ></app-preparation-visa-status>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </template>
        </tbody>
    </table>
</template>

<script>
import AppPreparationVisaStatus from '@/components/appPreparationVisaStatus/AppPreparationVisaStatus';
import { sortBy, uniqBy } from '@/services/sanitize.service';
export default {
    components: { AppPreparationVisaStatus },
    props: ['preparations', 'bundle', 'agenda', 'showAllVersions', 'bundleMap'],
    methods: {
        getVisaFromReviewer(visas, reviewer) {
            return visas.filter((visa) => visa.emitterId === reviewer.id).pop();
        },
    },
    computed: {
        groups() {
            return sortBy(uniqBy(sortBy(this.preparations, 'createdAt'), 'groupId'), 'emissionDueDate');
        },
        reviewers() {
            const visaEmittersMap = {};
            let preparations = this.showAllVersions ? this.preparations : this.groups;
            const emitterListWithDup = preparations
                .reduce(
                    (acc, preparation) => [
                        ...acc,
                        ...preparation.visas.reduce((acc, visa) => [...acc, visa.emitterId], []),
                    ],
                    [],
                )
                .filter((a) => !!a);
            for (const emitterId of emitterListWithDup) {
                if (visaEmittersMap[emitterId]) {
                    visaEmittersMap[emitterId].score = visaEmittersMap[emitterId].score + 1;
                } else {
                    visaEmittersMap[emitterId] = { emitterId, score: 0 };
                }
            }
            return sortBy(Object.values(visaEmittersMap), 'score')
                .reverse()
                .map((entity) => this.bundleMap[entity.emitterId]);
        },
    },
};
</script>
