import { getAPIHeaders } from '@/services/api.service';
import addToCache from '@/services/cache.service';
import db from '@/rxdb/database';
import { map } from 'rxjs';
import { createContact } from '@/features/contacts/contacts.service';

function mapCompany(company) {
    return company ? company.toMutableJSON() : null;
}

export function getCompanies(projectId) {
    return db
        .getProjectCollections(projectId)
        .companies.find({ selector: {}, sort: [{ name: 'asc' }] })
        .$.pipe(map((companies) => companies.map(mapCompany)));
}

export async function getKnownCompanies(name) {
    try {
        const result = await fetch(`/api/knownCompanies?query=${name}`, {
            headers: getAPIHeaders(),
        });
        addToCache(['/api/knownCompanies']);
        return result.json();
    } catch (err) {
        return [];
    }
}

export function getCompany(projectId, companyId) {
    return db
        .getProjectCollections(projectId)
        .companies.findOne({ selector: { id: companyId } })
        .$.pipe(map(mapCompany));
}

export async function updateCompany(projectId, company) {
    const dbCompany = await db
        .getProjectCollections(projectId)
        .companies.findOne({ selector: { id: company.id } })
        .exec();
    return dbCompany.atomicPatch(company);
}

export async function copyCompanyAndContacts(projectId, company) {
    const newCompany = await createCompany(projectId, {
        name: company.name,
        siret: company.siret,
        city: company.city,
        postalCode: company.postalCode,
        address1: company.address1,
        address2: company.address2,
        address3: company.address3,
        phone: company.phone,
        email: company.email,
        logoUrl: company.logoUrl,
    });
    const contacts = await Promise.all(
        company.contacts.map((contact) =>
            createContact(projectId, {
                companyId: newCompany.id,
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                phone: contact.phone,
                role: contact.role,
            }),
        ),
    );
    return { ...newCompany, contacts };
}
export async function createCompany(projectId, company) {
    const result = await db.getProjectCollections(projectId).companies.insert(company);
    return result.toJSON();
}
