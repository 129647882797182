import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import addBusinessDays from 'date-fns/addBusinessDays';
import subBusinessDays from 'date-fns/subBusinessDays';
import add from 'date-fns/add';
import isValid from 'date-fns/isValid';

export function applyDuration(date, duration, offDays, bundleId) {
    if (date && !isValid(date)) {
        return null;
    } else if (!duration) {
        return date;
    }
    const initialDate = date;
    let result = new Date(initialDate);
    if (duration > 0) {
        result = addBusinessDays(result, duration - 1);
        while (getDuration(result, initialDate, offDays, bundleId) < duration) {
            result = addBusinessDays(result, 1);
        }
    } else {
        result = subBusinessDays(result, -duration - 1);
        while (getDuration(initialDate, result, offDays, bundleId) < -duration) {
            result = subBusinessDays(result, 1);
        }
    }
    return result;
}
export function applyDelay(date, delay = 0, offDays, bundleId) {
    return applyDuration(date, delay >= 0 ? delay + 1 : delay - 1, offDays, bundleId);
}

export function differenceInWorkingDays(end, start, agenda, bundleId) {
    if (!end || !start || !isValid(end) || !isValid(start)) {
        return null;
    }
    const officialDiff = differenceInBusinessDays(end, start, false);
    let offDays = 0;
    for (const period of agenda) {
        if (
            isConcerned(period, bundleId) &&
            Math.min(start.getTime(), end.getTime()) <= period.time &&
            period.time <= Math.max(start.getTime(), end.getTime())
        ) {
            offDays += 1;
        }
    }
    if (officialDiff > 0) {
        return officialDiff - offDays;
    } else {
        return officialDiff + offDays;
    }
}

export function getDuration(end, start, offDays, bundleId) {
    if (!(end && start && isValid(end) && isValid(start))) {
        return NaN;
    }
    const officialDiff = differenceInBusinessDays(add(end, { days: 1 }), start);
    let customOffDays = 0;
    for (const period of offDays) {
        if (isConcerned(period, bundleId) && start.getTime() <= period.time && period.time <= end.getTime()) {
            customOffDays++;
        }
    }
    return officialDiff - customOffDays;
}

export function getWorkingDaysInRange(period, start, end) {
    if (!start || !end || period.startDate > end || period.endDate < start || !isValid(start) || !isValid(end)) {
        return 0;
    } else {
        const minEnd = period.endDate < end ? period.endDate : end;
        const maxStart = period.startDate > start ? period.startDate : start;
        return differenceInBusinessDays(add(minEnd, { days: 1 }), maxStart);
    }
}
export function isOffDay(date, offDays, bundleId = null) {
    const isWeekEnd = date.getDay() === 0 || date.getDay() === 6;
    if (isWeekEnd) {
        return true;
    }
    if (!offDays || offDays.length === 0) {
        return false;
    } else {
        for (const offDay of offDays) {
            if ((offDay.time || offDay.date.getTime()) === date.getTime() && isConcerned(offDay, bundleId)) {
                return true;
            }
        }
        return false;
    }
}
export function isOffWeek(date, offDays, bundleId = null) {
    if (!offDays || offDays.length === 0) {
        return false;
    }
    let counter = 0;
    for (let index = 0; index < 5; index++) {
        const dayDate = add(date, { days: index });
        for (const offDay of offDays) {
            if (offDay.time === dayDate.getTime() && isConcerned(offDay, bundleId)) {
                counter++;
            }
        }
    }
    return counter === 5;
}

export function isConcerned(offDay, bundleId) {
    return offDay.bundleIds && (offDay.bundleIds.includes('all') || offDay.bundleIds.includes(bundleId));
}

export default {
    differenceInWorkingDays,
    getWorkingDaysInRange,
    isOffDay,
    isOffWeek,
};
