<template>
    <app-label
        class="flex items-center flex-row-reverse justify-end"
        :label="label"
        :label-class="labelClass"
        :show-label="showLabel"
    >
        <slot></slot>
        <input
            type="checkbox"
            class="mx-2"
            :disabled="disabled"
            :indeterminate.prop="indeterminate"
            :checked="value"
            @change="$emit('input', $event.target.checked)"
        />
    </app-label>
</template>
<script>
import AppLabel from '../appLabel/AppLabel';
export default {
    props: {
        value: Boolean,
        disabled: Boolean,
        indeterminate: Boolean,
        showLabel: {
            type: Boolean,
            default: true,
        },
        label: String,
        labelClass: String,
    },
    components: { AppLabel },
};
</script>
