export default {
    install: (Vue) => {
        Vue.filter('index', index);
    },
};
export function index(value) {
    if (Number.isInteger(value)) {
        return (value + 1).toString().padStart(3, '0');
    } else {
        return value;
    }
}
