<template>
    <div>
        <div class="flex col-span-2" v-if="nextMeetings.length === 0">
            <app-button :label="$t('meetings.newMeeting')" @click="onNextMeetingSelect('new')"></app-button>
        </div>
        <app-select
            :label="$t('project.editions.meetingReports.nextMeeting')"
            v-model="meeting.nextMeetingId"
            @input="onNextMeetingSelect"
            v-else
        >
            <option :value="null"></option>
            <template v-for="aMeeting in nextMeetings">
                <option :value="aMeeting.id">
                    {{ format(aMeeting.date) }} {{ aMeeting.date | humanizeDateTimeShort }} -
                    {{ aMeeting.name }}
                </option>
            </template>
            <option value="new">- {{ $t('meetings.newMeeting') }} -</option>
        </app-select>
        <MeetingForm
            class="col-span-2"
            :meetingId="meeting.nextMeetingId"
            v-if="meeting.nextMeetingId"
            :showReportLink="false"
        ></MeetingForm>
    </div>
</template>

<script>
import { createMeeting, getMeeting, getMeetings, initNewMeeting, updateMeeting } from './meetings.service';
import AppSelect from '@/components/appSelect/AppSelect';
import MeetingForm from '@/features/meetings/MeetingForm';
import AppButton from '@/components/appButton/AppButton';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { combineLatest } from 'rxjs';
import { queryProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';

export default {
    components: {
        MeetingForm,
        AppSelect,
        AppButton,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => (this.project = project));
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getMeetings(this.$route.params.projectId),
            ]).subscribe(([meeting, meetings]) => {
                this.meeting = meeting;
                const currentMeetingIndex = meetings.indexOf(
                    meetings.find((meeting) => meeting.id === this.$route.params.meetingId),
                );
                this.nextMeetings = meetings.slice(currentMeetingIndex + 1);
                this.meetings = meetings;
            }),
        ];
    },
    methods: {
        format(date) {
            return format(date, 'EEEE', { locale: fr });
        },
        async onNextMeetingSelect(nextMeetingId) {
            if (!nextMeetingId || nextMeetingId === '') {
                await updateMeeting(this.project.id, { id: this.meeting.id, nextMeetingId: null });
            } else if (nextMeetingId === 'new') {
                const meeting = await initNewMeeting(this.project, this.agenda, this.meetings, (key) => this.$t(key));
                const result = await createMeeting(this.$route.params.projectId, meeting);
                await updateMeeting(this.project.id, { id: this.meeting.id, nextMeetingId: result.id });
            } else {
                await updateMeeting(this.project.id, { id: this.meeting.id, nextMeetingId });
            }
        },
    },
    data() {
        return {
            agenda: [],
            subscriptions: [],
            project: {},
            meetings: [],
            nextMeetings: [],
            meeting: {
                convocations: [],
            },
        };
    },
};
</script>
