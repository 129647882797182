<template>
    <table>
        <caption class="sm:text-base font-bold p-2">{{ title }}</caption>
        <thead>
            <tr>
                <th class="px-1 whitespace-no-wrap" :class="{ border: showDetail }"></th>
                <th class="border px-1 whitespace-no-wrap text-green-600" v-if="showDetail">
                    <span :title="$t('dashboard.inTime')">
                        <icon-clock-check-outline width="16" height="16" />
                    </span>
                </th>
                <th class="border px-1 whitespace-no-wrap text-red-600" v-if="showDetail">
                    <span :title="$t('dashboard.late')"><icon-clock-alert-outline width="16" height="16" /></span>
                </th>

                <th class="px-1 whitespace-no-wrap font-normal" :class="{ border: showDetail }">
                    <span :title="$t('commons.total')" class="text-right" :class="{ hidden: !showDetail }">
                        <icon-sigma width="16" height="16" />
                    </span>
                </th>
                <th class="w-full px-1" :class="{ border: showDetail }"></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="(serie, index) in _series">
                <tr>
                    <th class="border px-1 text-right" role="row">
                        <span class="whitespace-no-wrap">{{ serie.label }}</span>
                    </th>

                    <td class="border px-1 text-right text-green-600" v-if="showDetail">
                        {{ serie.values.ok }}
                    </td>
                    <td class="border px-1 text-right text-red-700" v-if="showDetail">
                        {{ serie.values.late }}
                    </td>
                    <td class="border px-1 text-right">
                        {{ serie.values.total }}
                    </td>
                    <td class="border">
                        <div
                            :style="{
                                width: serie.values.widthPercent + '%',
                                'background-color': index === 0 ? '#2a4365' : '#509cec',
                            }"
                            style="height: 1.1em"
                            :class="{ hashed: index === 1, done: index === 0, planned: index === 2 }"
                            :title="serie.values.widthPercent + '%'"
                        ></div>
                    </td>
                </tr>
            </template>
            <tr>
                <th role="row" class="border px-1 font-normal">
                    <span :title="$t('commons.total')" class="flex justify-end">
                        <icon-sigma width="16" height="16" />
                    </span>
                </th>
                <td class="border px-1 text-right" v-if="showDetail">
                    {{ _series.reduce((acc, serie) => acc + serie.values.ok, 0) }}
                </td>
                <td class="border px-1 text-right" v-if="showDetail">
                    {{ _series.reduce((acc, serie) => acc + serie.values.late, 0) }}
                </td>
                <td class="border px-1 text-right">
                    {{ _series.reduce((acc, serie) => acc + serie.values.total, 0) }}
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import IconClockAlertOutline from '@/icons/IconClockAlertOutline';
import IconClockCheckOutline from '@/icons/IconClockCheckOutline';
import IconSigma from '@/icons/IconSigma';
export default {
    components: { IconSigma, IconClockCheckOutline, IconClockAlertOutline },
    props: {
        title: { type: String, default: 'Titre' },
        showDetail: { type: Boolean, default: true },
        series: {
            type: Array,
            default() {
                return [
                    { label: 'Close', values: { ok: 10, late: 10 } },
                    { label: 'En Cours', values: { ok: 10, late: 10 } },
                    { label: 'A venir', values: { ok: 10, late: 0 } },
                ];
            },
        },
    },
    computed: {
        _series() {
            const total = this.series.reduce((acc, serie) => acc + serie.values.ok + serie.values.late, 0);
            return this.series.map((serie) => {
                return {
                    label: serie.label,
                    values: {
                        ...serie.values,
                        total: serie.values.ok + serie.values.late,
                        widthPercent: total > 0 ? Math.round(((serie.values.ok + serie.values.late) / total) * 100) : 0,
                    },
                };
            });
        },
    },
};
</script>
<style scoped>
.hashed {
    /* https://online-free-tools.com/fr/css_generateur_motif_pattern_background */
    background: linear-gradient(
        45deg,
        #2a4365 5.5%,
        #509cec 5.5%,
        #509cec 44.5%,
        #2a4365 44.5%,
        #2a4365 55.5%,
        #509cec 55.5%,
        #509cec 94.5%,
        #2a4365 94.5%
    );
    background-size: 8px 8px;
    background-position: 50px 50px;
}
.done {
    background-color: #2a4365;
}
.planned {
    background-color: #509cec;
}
</style>
