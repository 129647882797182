<template>
    <a :href="'tel://' + number" rel="nofollow" target="_blank" class="whitespace-nowrap">{{ number | phone }}</a>
</template>
<script>
import { conformToMask } from 'text-mask-core';
export default {
    props: ['number'],
    filters: {
        phone(value) {
            if (!value) return '';
            if (value.length === 10) {
                return conformToMask(value, [
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                ]).conformedValue;
            } else if (value.length === 13) {
                return conformToMask(value, [
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                ]).conformedValue;
            } else {
                return value;
            }
        },
    },
};
</script>
