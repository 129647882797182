<template>
    <app-select
        ref="select"
        :size="size"
        :icon="icon"
        :variant="variant"
        :show-label="showLabel"
        :label="label"
        :disabled="disabled"
        v-model="internalValue"
        @input="onInput"
    >
        <template v-slot:default>
            <option disabled value="" :label="placeholder" v-if="placeholder"></option>
            <slot></slot>
        </template>
    </app-select>
</template>
<script>
import AppSelect from '../appSelect/AppSelect';

export default {
    components: { AppSelect },
    props: {
        placeholder: String,
        label: String,
        disabled: Boolean,
        variant: String,
        size: String,
        icon: String,
        showLabel: Boolean,
    },
    data() {
        return { internalValue: '' };
    },
    methods: {
        focus() {
            this.$refs.select.focus();
        },
        onInput(event) {
            this.$emit('input', event);
            this.internalValue = '';
        },
    },
};
</script>
