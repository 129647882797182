<template>
    <div class="flex items-center">
        <a
            :href="url"
            class="mr-1"
            aria-label="download document"
            target="_blank"
            :download="fileName"
            @click.stop=""
            v-if="showIcon"
        >
            <icon :name="icon" v-if="icon" width="16px" height="16px" />
            <IconDownload width="16" v-else heigth="16" />
        </a>
        <router-link class="underline line-clamp-1 break-all" :to="to" v-if="to" @click.stop="">
            <slot>{{ fileName }}</slot>
        </router-link>
        <a
            v-if="!to && showName"
            :href="url"
            class="line-clamp-1 break-all"
            aria-label="download document"
            target="_blank"
            :download="fileName"
            @click.stop=""
        >
            <slot>{{ fileName }}</slot>
        </a>
        <app-button
            size="mini"
            variant="danger"
            aria-label="remove photo"
            icon="icon-trash-can-outline"
            @click="$emit('delete')"
            v-if="removable"
            class="ml-2"
        />
    </div>
</template>
<script>
import AppButton from '../appButton/AppButton';
export default {
    components: { AppButton },
    props: {
        fileName: String,
        icon: String,
        removable: { type: Boolean, default: false },
        url: String,
        to: { type: Object },
        showIcon: { type: Boolean, default: true },
        showName: { type: Boolean, default: true },
    },
};
</script>
