/**
 * A variant of BehaviorSubject with no initial value.
 * It wraps an observable then subscribers get the last value (if any) immediatly after subscription
 *  *
 * @class BehaviorSubjectWrapper<T>
 */
import { Subject } from 'rxjs';

export class BehaviorSubjectWrapper extends Subject {
    nestedObservable = null;
    lastValue = null;
    constructor(nestedObservable) {
        super();
        this.nestedObservable = nestedObservable;
        this.nestedObservable.subscribe((value) => {
            this.lastValue = value;
            super.next(value);
        });
    }

    /** @internal */
    _subscribe(subscriber) {
        const subscription = super._subscribe(subscriber);
        if (this.lastValue) {
            !subscription.closed && subscriber.next(this.lastValue);
        }
        return subscription;
    }
}
