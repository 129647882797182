import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const services = {
    schema: {
        title: 'service schema',
        version: 0,
        description: 'describes a service',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: {
                type: 'string',
            },
            bundleId: {
                type: 'string',
                final: true,
            },
            amount: {
                type: ['number', 'null'],
            },
            name: {
                type: ['string', 'null'],
            },
            isExecution: {
                type: ['boolean', 'null'],
            },
            index: {
                type: ['number', 'null'],
            },
            unit: {
                type: ['string', 'null'],
            },
            reference: {
                type: ['string', 'null'],
            },
            parentDirectoryId: {
                type: ['string', 'null'],
            },
            predecessors: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        type: { type: 'string' },
                        delay: { type: 'number' },
                        serviceId: { type: 'string' },
                    },
                },
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
    },
    autoMigrate: false,
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
