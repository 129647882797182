import db from '@/rxdb/database';
import { map } from 'rxjs';
import { sortBy } from '@/services/sanitize.service';

function mapTask(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              startDate: item.startDate ? new Date(item.startDate) : null,
              endDate: item.endDate ? new Date(item.endDate) : null,
              realStartDate: item.realStartDate ? new Date(item.realStartDate) : null,
              realEndDate: item.realEndDate ? new Date(item.realEndDate) : null,
              ignoredServicePredecessorIds: item.ignoredServicePredecessorIds || [],
          }
        : null;
}

export function getTasks(projectId) {
    return db
        .getProjectCollections(projectId)
        .tasks.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapTask)));
}

export function prepareTask(task) {
    const patch = { ...task };
    if (task.startDate || task.startDate === null) {
        patch.startDate = task.startDate ? task.startDate.toISOString() : null;
    }
    if (task.endDate || task.endDate === null) {
        patch.endDate = task.endDate ? task.endDate.toISOString() : null;
    }
    if (task.realStartDate || task.realStartDate === null) {
        patch.realStartDate = task.realStartDate ? task.realStartDate.toISOString() : null;
    }
    if (task.realEndDate || task.realEndDate === null) {
        patch.realEndDate = task.realEndDate ? task.realEndDate.toISOString() : null;
    }
    return patch;
}
export async function updateTask(projectId, task) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: task.id } })
        .exec();
    return dbTask.atomicPatch(prepareTask(task));
}
export async function removeTaskPredecessor(projectId, taskId, predecessorTaskId) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: taskId } })
        .exec();
    return dbTask.atomicPatch({
        predecessors: dbTask.predecessors.filter((predecessor) => predecessor.taskId !== predecessorTaskId),
    });
}
export async function filterTaskPredecessor(projectId, task, filterFn) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: task.id } })
        .exec();
    return dbTask.atomicPatch({
        predecessors: dbTask.predecessors.filter(filterFn),
    });
}
export async function addTaskPredecessor(projectId, taskId, predecessor) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: taskId } })
        .exec();
    return dbTask.atomicPatch({
        predecessors: [...dbTask.predecessors, predecessor],
    });
}
export async function addTaskPredecessors(projectId, taskId, predecessors) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: taskId } })
        .exec();
    return dbTask.atomicPatch({
        predecessors: [...dbTask.predecessors, ...predecessors],
    });
}
export async function updateTaskPredecessor(projectId, taskId, predecessor) {
    const dbTask = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: taskId } })
        .exec();
    return dbTask.atomicPatch({
        predecessors: dbTask.predecessors.map((aPredecessor) =>
            aPredecessor.taskId === predecessor.taskId ? { ...aPredecessor, ...predecessor } : aPredecessor,
        ),
    });
}
export async function createTask(projectId, task) {
    const result = await db.getProjectCollections(projectId).tasks.insert({
        predecessors: [],
        ...task,
        startDate: task.startDate ? task.startDate.toISOString() : null,
        endDate: task.endDate ? task.endDate.toISOString() : null,
        realStartDate: task.realStartDate ? task.realStartDate.toISOString() : null,
        realEndDate: task.realEndDate ? task.realEndDate.toISOString() : null,
    });
    return result.toMutableJSON();
}

export async function removeTask(projectId, taskId) {
    const entity = await db
        .getProjectCollections(projectId)
        .tasks.findOne({ selector: { id: taskId } })
        .exec();
    return entity.remove();
}

export function bulkRemoveTasks(projectId, taskIds) {
    return db.getProjectCollections(projectId).tasks.bulkRemove(taskIds);
}

const sortByName = (item) =>
    (item.index || item.index === 0 ? item.index.toString().padStart(3, '0') + '-' : '') +
    (item.name ? item.name.replace('+', 'z+') : '');

const sortDate = (item) => item.realStartDate || item.startDate || item.name;

export function sortTasks(tasks, sortKey) {
    return sortBy(tasks, sortKey === 'name' ? sortByName : sortDate);
}

export default {
    bulkRemoveTasks,
    filterTaskPredecessor,
    removeTask,
};
