<template>
    <ObservationPage @back="backToAutoControl" @deleted="backToAutoControl" />
</template>

<script>
import ObservationPage from '../observations/ObservationPage';
export default {
    components: {
        ObservationPage,
    },
    methods: {
        backToAutoControl() {
            this.$router.push({
                name: 'followAutoControl',
                params: {
                    projectId: this.$route.params.projectId,
                    autoControlId: this.$route.params.autoControlId,
                },
            });
        },
    },
};
</script>
