<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="flex items-center w-full relative">
            <app-back-button @back="goBack" class="mb-2 mr-2" :name="this.$route.query.backName" />
            <h1 class="text-lg flex-grow font-bold absolute left-50 whitespace-nowrap">
                {{ $route.query.fileName }}
            </h1>
        </div>
        <div class="flex w-full flex-grow">
            <iframe v-if="file.url.endsWith('pdf')" :src="file.url" class="w-full min-h-screen my-5"></iframe>
            <app-leaflet-viewer v-else class="w-full" :src="file.url"></app-leaflet-viewer>
        </div>
    </main>
</template>

<script>
import AppBackButton from '@/components/appBackButton/AppBackButton';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';

export default {
    components: { AppLeafletViewer, AppBackButton },
    methods: {
        goBack() {
            window.history.back();
        },
    },
    computed: {
        file() {
            return { url: this.$route.query.url };
        },
    },
};
</script>
