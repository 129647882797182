import { applyDelay } from '@/services/duration.service';
import { filterMatch, groupBy } from '@/services/sanitize.service';
import { humanizeDate } from '@/filters/dateFilter';
import db from '@/rxdb/database';
import { combineLatest, firstValueFrom, map, mergeMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapPreparation(item, visas) {
    if (item) {
        const plainItem = item.toMutableJSON();
        return {
            ...plainItem,
            emissionDueDate: plainItem.emissionDueDate ? new Date(plainItem.emissionDueDate) : null,
            emissionDate: plainItem.emissionDate ? new Date(plainItem.emissionDate) : null,
            conclusion: conclusionResult(plainItem, visas),
            visas: visas.map((visa) => visa.toMutableJSON()),
        };
    } else {
        return null;
    }
}

export async function bulkInsert(projectId, preparations) {
    return db.getProjectCollections(projectId).preparations.bulkInsert(preparations);
}
function mapPreparations(dbPreparations, visas) {
    const preparations = dbPreparations.map((preparation) => {
        return mapPreparation(
            preparation,
            visas.filter((visa) => visa.preparationId === preparation.id),
        );
    });
    return groupBy(preparations, 'groupId').map((versions) => {
        versions.reverse();
        return {
            ...versions[0],
            versions,
        };
    });
}
function mergeToPreparation(preparationId, dbPreparations) {
    const versions = dbPreparations
        .map((preparation) => {
            return mapPreparation(preparation, []);
        })
        .reverse();
    const preparation = versions.find((preparation) => preparation.id === preparationId);
    return {
        ...preparation,
        versions,
    };
}

export function getPreparations(projectId, phase) {
    return getCachedObservable('getPreparations_' + projectId + '_' + phase, () =>
        combineLatest([
            db
                .getProjectCollections(projectId)
                .preparations.find(
                    phase ? { selector: { phase }, sort: [{ createdAt: 'asc' }] } : { sort: [{ createdAt: 'asc' }] },
                ).$,
            db.getProjectCollections(projectId).preparationVisas.find().$,
        ]).pipe(map(([preparations, visas]) => mapPreparations(preparations, visas))),
    );
}

export function getAllPreparations(projectId) {
    return getCachedObservable('getAllPreparations_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .preparations.find({ sort: [{ createdAt: 'asc' }] })
            .$.pipe(map((preparations) => preparations.map((preparation) => preparation.toMutableJSON()))),
    );
}

export function getPreparation(projectId, preparationId) {
    return db
        .getProjectCollections(projectId)
        .preparations.findOne({ selector: { id: preparationId } })
        .$.pipe(
            mergeMap((preparation) =>
                preparation
                    ? combineLatest([
                          firstValueFrom(
                              db.getProjectCollections(projectId).preparations.find({
                                  selector: { groupId: preparation.groupId },
                                  sort: [{ createdAt: 'asc' }],
                              }).$,
                          ),
                      ])
                    : null,
            ),
        )
        .pipe(map(([preparations]) => mergeToPreparation(preparationId, preparations)));
}
export async function queryPreparation(projectId, preparationId) {
    const preparation = await db
        .getProjectCollections(projectId)
        .preparations.findOne({ selector: { id: preparationId } })
        .exec();
    if (preparation) {
        const preparations = await db
            .getProjectCollections(projectId)
            .preparations.find({
                selector: { groupId: preparation.groupId },
                sort: [{ createdAt: 'asc' }],
            })
            .exec();
        return mergeToPreparation(preparationId, preparations);
    } else {
        return null;
    }
}

export function conclusionResult(preparation, visas) {
    if (!preparation) {
        return;
    } else if (!preparation.emissionDate) {
        return 'waitingForDocument';
    } else if ((visas || preparation.visas).some((visa) => !visa.emissionDate)) {
        return 'incompleteVisa';
    } else if ((visas || preparation.visas).length === 0) {
        return 'noConclusion';
    } else if ((visas || preparation.visas).some((visa) => visa.conclusion === 'nonCompliant')) {
        return 'nonCompliant';
    } else if ((visas || preparation.visas).some((visa) => visa.conclusion === 'rejected')) {
        return 'rejected';
    } else if ((visas || preparation.visas).some((visa) => visa.conclusion === 'approvedWithComments')) {
        return 'approvedWithComments';
    } else {
        return 'approved';
    }
}

export function mapPreparationStatus(i18nFn, preparation, date, defaultLabel) {
    const isEmitted = !!preparation.emissionDate;
    const isRejected = preparation.visas.find((visa) => visa.conclusion === 'rejected');
    const nonCompliant = preparation.visas.find((visa) => visa.conclusion === 'nonCompliant');
    const isVisaPending = !isRejected && !!preparation.visas.find((visa) => !visa.emissionDate);
    const noVisa = !isRejected && preparation.visas.length === 0;
    const isReserved = !isRejected && preparation.visas.find((visa) => visa.conclusion === 'approvedWithComments');
    const isApproved = !noVisa && !nonCompliant && !isRejected && !isReserved && !isVisaPending;
    const isLate = !isEmitted && preparation.emissionDueDate && preparation.emissionDueDate < date;
    let label = defaultLabel;
    if (preparation.emissionDueDate) {
        label = i18nFn('commons.expectedOn') + ' ' + humanizeDate(preparation.emissionDueDate);
    }
    if (isEmitted && nonCompliant) {
        label = i18nFn('preparations.conclusionValues.nonCompliant');
    } else if (isEmitted && isRejected) {
        label = i18nFn('preparations.conclusionValues.rejected');
    } else if (isEmitted && isVisaPending) {
        label = i18nFn('preparations.conclusionValues.incompleteVisa');
    } else if (isEmitted && isReserved) {
        label = i18nFn('preparations.conclusionValues.approvedWithComments');
    } else if (isEmitted && isApproved) {
        label = i18nFn('preparations.conclusionValues.approved');
    } else if (isEmitted && noVisa) {
        label = i18nFn('commons.emittedOn') + ' ' + humanizeDate(preparation.emissionDate);
    }
    return {
        ...preparation,
        isEmitted,
        isLate,
        label,
    };
}

const pageScore = (comment) => (comment.scope === 'document' ? 0 : comment.page || 0);
const positionScore = (comment) => Math.round(comment.y || 0);
const indexScore = (comment) => comment.index || 0;
function score(comment) {
    return pageScore(comment) * 1000 * 100 + positionScore(comment) * 1000 + indexScore(comment);
}
export function sortComments(comments) {
    return comments.sort((a, b) => {
        return score(a) - score(b);
    });
}

export function getRecommendedEmissionDate(
    preparationReviewMargin,
    deliveryDuration,
    firstTaskDate,
    visaDuration,
    agenda,
) {
    if ((preparationReviewMargin || preparationReviewMargin === 0) && firstTaskDate) {
        const nbDaysToSub = preparationReviewMargin + (deliveryDuration || 0) + visaDuration;
        return applyDelay(firstTaskDate, -nbDaysToSub, agenda, null);
    }
}

export function removePreparation(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .preparations.findOne({ selector: { id: id } })
        .remove();
}

export async function createPreparation(projectId, item) {
    const result = await db.getProjectCollections(projectId).preparations.insert({
        projectId,
        locationIds: [],
        predecessors: [],
        groupId: uuidv4(),
        ...item,
        emissionDueDate: item.emissionDueDate ? item.emissionDueDate.toISOString() : null,
        emissionDate: item.emissionDate ? item.emissionDate.toISOString() : null,
    });
    return result.toJSON();
}

export async function updatePreparation(projectId, preparation) {
    const dbPreparation = await db
        .getProjectCollections(projectId)
        .preparations.findOne({ selector: { id: preparation.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(preparation)) };
    if (preparation.emissionDate === null || !!preparation.emissionDate) {
        patch.emissionDate = preparation.emissionDate ? preparation.emissionDate.toISOString() : null;
    }
    if (preparation.emissionDueDate === null || !!preparation.emissionDueDate) {
        patch.emissionDueDate = preparation.emissionDueDate ? preparation.emissionDueDate.toISOString() : null;
    }
    delete patch.conclusion;
    return dbPreparation.atomicPatch(patch);
}

export async function removePreparationGroup(projectId, preparationGroupId) {
    const entities = await db
        .getProjectCollections(projectId)
        .preparations.find({ selector: { groupId: preparationGroupId } })
        .exec();
    return Promise.all(entities.map((entity) => entity.remove()));
}
export function getConclusionIds(preparationsReport) {
    if (!preparationsReport) return [];
    const conclusionIds = [];
    if (preparationsReport.showApproved) {
        conclusionIds.push('approved');
    }
    if (preparationsReport.showApprovedWithComments) {
        conclusionIds.push('approvedWithComments');
    }
    if (preparationsReport.showRejected) {
        conclusionIds.push('rejected');
    }
    if (preparationsReport.showToEmit) {
        conclusionIds.push('toEmit');
    }
    if (preparationsReport.showToVisa) {
        conclusionIds.push('toVisa');
    }
    if (preparationsReport.showVised) {
        conclusionIds.push('closed');
    }
    return conclusionIds;
}

export function matchMultiFilter(item, stringCriteria, bundleIds, locationIds, conclusionIds) {
    const bundleMatch =
        bundleIds.length === 0 ||
        bundleIds.includes(item.bundleId) ||
        item.visas.find((visa) => bundleIds.includes(visa.emitterId));
    const stringMatch =
        stringCriteria.length === 0 ||
        !!stringCriteria.find((criteria) => filterMatch(item.code + ' ' + item.name, criteria, true));
    const locationMatch =
        locationIds.length === 0 || !!locationIds.find((locationId) => item.locationIds.includes(locationId));
    const conclusionMatch =
        conclusionIds.length === 0 ||
        !!conclusionIds.find(
            (conclusion) =>
                (conclusion === 'toEmit' && !item.emissionDate) ||
                (conclusion === 'toVisa' && item.emissionDate && item.conclusion === 'incompleteVisa') ||
                (conclusion === 'closed' && item.emissionDate && item.conclusion !== 'incompleteVisa') ||
                (conclusion === 'rejected' && item.emissionDate && item.conclusion === 'rejected') ||
                (conclusion === 'approved' && item.emissionDate && item.conclusion === 'approved') ||
                (conclusion === 'approvedWithComments' &&
                    item.emissionDate &&
                    item.conclusion === 'approvedWithComments'),
        );
    return bundleMatch && stringMatch && locationMatch && conclusionMatch;
}

export async function filterLocationIds(projectId, preparation, filterFn) {
    const dbPreparation = await db
        .getProjectCollections(projectId)
        .preparations.findOne({ selector: { id: preparation.id } })
        .exec();
    return dbPreparation.atomicPatch({
        locationIds: dbPreparation.locationIds.filter(filterFn),
    });
}

export function createPreparationPredecessors(projectId, preparation, predecessors) {
    return updatePreparation(projectId, {
        id: preparation.id,
        predecessors: [
            ...preparation.predecessors.map((predecessor) => ({
                taskId: predecessor.taskId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
            ...predecessors,
        ],
    });
}

export function removePreparationPredecessors(projectId, preparation, taskIds) {
    return updatePreparation(projectId, {
        id: preparation.id,
        predecessors: preparation.predecessors
            .filter((predecessor) => !taskIds.includes(predecessor.taskId))
            .map((predecessor) => ({
                taskId: predecessor.taskId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}

export default {
    filterLocationIds,
    updatePreparation,
};
