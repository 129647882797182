var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-4 md:gap-6 flex-col text-sm"},[(!_vm.isMobile)?_c('table',{staticClass:"w-full border-gray-300 border-solid border"},[_c('thead',[_c('tr',[_c('th',{staticClass:"p-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.bundleHeader')))])]),_c('th',{staticClass:"p-1 observations-to-fix"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.toFix')))])]),_c('th',{staticClass:"p-1 observations-done"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.done')))])]),_c('th',{staticClass:"p-1 observations-validated"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.validated')))])]),_c('th',{staticClass:"p-1"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.total')))])])])]),_vm._l((_vm.byRecipientStats.labels),function(label,index){return _c('tr',[_c('td',{staticClass:"p-1 text-sm border-gray-300 border-solid border"},[_vm._v(_vm._s(label))]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[1].data[index] + _vm.byRecipientStats.series[0].data[index])+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[2].data[index])+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[3].data[index])+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[0].data[index] +
                    _vm.byRecipientStats.series[1].data[index] +
                    _vm.byRecipientStats.series[2].data[index] +
                    _vm.byRecipientStats.series[3].data[index])+"\n            ")])])}),_c('tr',[_c('td',{staticClass:"p-1 text-right border-gray-300 border-solid border"},[_c('span',[_vm._v(_vm._s(_vm.$t('observations.total')))])]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[1].data.reduce((acc, line) => acc + line, 0) +
                    _vm.byRecipientStats.series[0].data.reduce((acc, line) => acc + line, 0))+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[2].data.reduce((acc, line) => acc + line, 0))+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[3].data.reduce((acc, line) => acc + line, 0))+"\n            ")]),_c('td',{staticClass:"p-1 text-center border-gray-300 border-solid border"},[_vm._v("\n                "+_vm._s(_vm.byRecipientStats.series[0].data.reduce((acc, line) => acc + line, 0) +
                    _vm.byRecipientStats.series[1].data.reduce((acc, line) => acc + line, 0) +
                    _vm.byRecipientStats.series[2].data.reduce((acc, line) => acc + line, 0) +
                    _vm.byRecipientStats.series[3].data.reduce((acc, line) => acc + line, 0))+"\n            ")])])],2):_vm._e(),(_vm.isMobile)?_c('table',{staticClass:"w-full border-gray-300 border-solid border"},[_vm._l((_vm.byRecipientStats.labels),function(label,index){return [(index > 0)?_c('tr',[_c('td',{},[_vm._v(" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"p-1 text-sm border-gray-300 border-solid border align-top",attrs:{"rowspan":"4"}},[_vm._v("\n                    "+_vm._s(label)+"\n                ")]),_c('td',{staticClass:"p-1 observations-to-do text-xs border-solid border"},[_c('span',{staticClass:"mr-1"},[_vm._v("\n                        "+_vm._s(_vm.byRecipientStats.series[0].data[index] + _vm.byRecipientStats.series[1].data[index])+"\n                    ")]),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('observations.toFix')))])])]),_c('tr',[_c('td',{staticClass:"p-1 observations-done text-xs border-solid border"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.byRecipientStats.series[2].data[index]))]),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('observations.done')))])])]),_c('tr',[_c('td',{staticClass:"p-1 observations-validated text-xs border-solid border"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.byRecipientStats.series[3].data[index]))]),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.$t('observations.validated')))])])]),_c('tr',[_c('td',{staticClass:"p-1 border-solid border"},[_c('div',{staticClass:"text-xs"},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('commons.total'))+" :")]),_c('span',[_vm._v("\n                            "+_vm._s(_vm.byRecipientStats.series[0].data[index] +
                                _vm.byRecipientStats.series[1].data[index] +
                                _vm.byRecipientStats.series[2].data[index] +
                                _vm.byRecipientStats.series[3].data[index])+"\n                        ")])])])])]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }