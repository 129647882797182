var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-5xl flex flex-col gap-2 flex-grow"},[_c('div',[_c('div',{staticClass:"flex items-center gap-1"},[_c('div',[_c('app-button',{attrs:{"size":"mini","label":_vm.$t('commons.allFemale')},on:{"click":_vm.onApplyAll}})],1),_c('app-tips',{},[_vm._v(_vm._s(_vm.$t('observations.checkListTips')))])],1)]),_c('ul',_vm._l((_vm.checkList),function(item){return _c('li',{staticClass:"hover:bg-gray-200 flex p-1 border items-center w-full"},[_c('div',{staticClass:"flex flex-grow items-center"},[_c('span',{staticClass:"text-sm",on:{"click":function($event){return _vm.apply(item)}}},[_c('app-checkbox',{attrs:{"value":item.checked,"disabled":item.disabled}})],1),_c('div',{staticClass:"flex flex-col md:flex-row flex-wrap md:items-center"},[(item.linkedInstance)?_c('router-link',{attrs:{"to":{
                            name: 'observationTour',
                            params: {
                                ..._vm.$route.params,
                                observationId: item.linkedInstance.id,
                            },
                        }},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',{staticClass:"underline line-clamp-1",domProps:{"innerHTML":_vm._s(item.title)}})]):_c('span',{staticClass:"line-clamp-1",domProps:{"innerHTML":_vm._s(item.title)}}),(item.recipients && item.recipients.length === 1)?_c('span',{staticClass:"text-xs text-gray-500 mx-2 line-clamp-1"},[_vm._v("\n                        #"+_vm._s(item.recipients[0].reference)+" "+_vm._s(item.recipients[0].name)+"\n                    ")]):_vm._e(),(item.recipients && item.recipients.length > 1)?_c('span',{staticClass:"text-xs text-gray-500 mx-2 line-clamp-1 gap-1"},[_c('span',[_vm._v(_vm._s(item.recipients.map((recipient) => '#' + recipient.reference).join(', ')))])]):_vm._e()],1)]),_c('div',{staticClass:"flex items-center justify-end"},[_c('app-button',{attrs:{"size":"mini","variant":"danger","aria-label":"remove item","icon":"icon-trash-can-outline"},on:{"click":function($event){return _vm.onRemoveItem(item)}}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }