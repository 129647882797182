<template>
    <div
        class="app-marker absolute"
        ref="draggable"
        @mouseover="hasBeenHover = true"
        :style="{ left: internalX + '%', top: internalY + '%' }"
        :title="marker.title"
        style="transition: all 0.2s ease-in-out; transition-property: transform; user-select: none"
    >
        <a :id="marker.id" class="absolute" :style="{ top: -iconHeight + 'px' }"></a>
        <div
            :class="{
                'hover:animate-none animate-bounce': active && !rotationMouseDown && !dragMouseDown && !hasBeenHover,
            }"
            class="absolute"
            :style="{
                top: -iconHeight + 'px',
                left: -iconWidth / 2 + 'px',
            }"
        >
            <svg
                :width="iconWidth"
                :height="iconHeight"
                style="transform-origin: 50% 100%"
                :style="{
                    transform: 'rotate(' + degree + 'deg)',
                }"
                ref="svg"
            >
                <g ref="arrow">
                    <rect x="0" y="0" width="100%" height="100%" opacity="0" />

                    <path
                        d="M 14.88672,1.0725e-4 C 6.646722,0.06233825 -2.3498266e-4,6.7598745 1.7343275e-8,15.000107 -6.4982657e-5,16.351814 0.18257802,17.697329 0.54296902,19.000107 v 0 L 1.763673,22.056748 c 0.15233,0.285754 0.313873,0.5665 0.484375,0.841797 l 10.154297,17.599609 c -0.263783,0.45659 -0.402559,0.974643 -0.402344,1.501953 0,1.656854 1.343146,3 3,3 1.656854,0 3,-1.343146 3,-3 -7.21e-4,-0.526826 -0.140163,-1.044172 -0.404297,-1.5 L 27.755859,22.890732 c 0.163257,-0.264006 0.31828,-0.533016 0.464844,-0.806641 l 1.234375,-3.083984 v 0 c 0.36105,-1.302688 0.544351,-2.648204 0.544922,-4 C 30,6.7158365 23.284271,1.0725e-4 15.000001,1.0725e-4 c -0.03776,-1.43e-4 -0.07552,-1.43e-4 -0.113281,0 z"
                        :fill="color"
                    />
                    <circle
                        :style="{ fill: color, cursor: !draggable ? '' : rotationMouseDown ? 'move' : 'pointer' }"
                        r="3"
                        cx="15.5"
                        cy="42"
                        @mousedown="onRotationMouseDown"
                    ></circle>
                </g>
                <circle r="15" cx="50%" cy="15" :style="{ fill: color }"></circle>
                <circle r="10" cx="50%" cy="15" style="fill: #ffffff"></circle>
                <text
                    x="50%"
                    y="15"
                    dy=".3em"
                    text-anchor="middle"
                    :font-size="label.length < 3 ? 12 : 9"
                    style="transform-origin: 50% 15px"
                    :style="{
                        transform: 'rotate(' + -degree + 'deg)',
                    }"
                >
                    {{ label }}
                </text>
                <circle
                    :style="{ cursor: !draggable ? 'pointer' : dragMouseDown ? 'move' : 'grab' }"
                    @click.stop="selectMarker(marker)"
                    style="fill-opacity: 0"
                    r="10"
                    cx="50%"
                    cy="15"
                    ref="handle"
                ></circle>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: { type: Number | String, default: '' },
        marker: Object,
        draggable: { type: Boolean, default: true },
        active: { type: Boolean, default: false },
    },
    watch: {
        marker(value) {
            this.internalX = value.x || 50;
            this.internalY = value.y || 50;
            this.degree = value.degree || 0;
            this.hasBeenHover = false;
            this.color = value.color || '#386da5';
        },
        active(value) {
            if (value) {
                this.hasBeenHover = false;
            }
        },
    },
    mounted() {
        this.internalX = this.marker.x || 50;
        this.internalY = this.marker.y || 50;
        this.degree = this.marker.degree || 0;
        this.color = this.marker.color || '#386da5';
        document.addEventListener('pointerdown', this.onDragMouseDown);
        document.addEventListener('pointermove', this.onPointerMove);

        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('touchend', this.onMouseUp);
    },
    beforeDestroy() {
        document.removeEventListener('pointerdown', this.onDragMouseDown);
        document.removeEventListener('pointermove', this.onPointerMove);

        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('touchend', this.onMouseUp);
    },
    methods: {
        scale(scale) {
            this.$el.style.transform = 'scale(' + scale + ',' + scale + ')';
        },
        selectMarker(marker) {
            if (!this.hasDragged) {
                this.$emit('markerClick', marker);
            }
        },
        onDragMouseDown(event) {
            if (event.target === this.$refs.handle) {
                this.dragMouseDown = true;
                this.dragXDelta = event.clientX;
                this.dragYDelta = event.clientY;
                this.lastX = this.internalX;
                this.lastY = this.internalY;
                event.stopPropagation();
            }
        },
        onRotationMouseDown() {
            this.rotationMouseDown = true;
        },
        onPointerMove(event) {
            if (this.draggable && this.rotationMouseDown) {
                const rect = this.$refs.arrow.getBoundingClientRect();
                const radians = Math.atan2(Math.round(event.clientX - rect.left), Math.round(event.clientY - rect.top));
                this.degree = radians * (180 / Math.PI) * -1;
            } else if (this.draggable && this.dragMouseDown) {
                const rect = this.$refs.draggable.parentElement.getBoundingClientRect();
                const deltaX = event.clientX - this.dragXDelta;
                const newX = this.lastX + (deltaX / rect.width) * 100;
                const XMin = 0;
                const XMax = 100;
                let futureX;
                if (newX > XMin && newX < XMax) {
                    futureX = newX;
                } else if (newX >= XMax) {
                    futureX = XMax;
                } else if (newX <= XMin) {
                    futureX = XMin;
                }
                const deltaY = event.clientY - this.dragYDelta;
                const newY = this.lastY + (deltaY / rect.height) * 100;
                const YMax = 100;
                const YMin = 0;
                let futureY;
                if (newY > YMin && newY < YMax) {
                    futureY = newY;
                } else if (newY >= YMax) {
                    futureY = YMax;
                } else if (newY <= YMin) {
                    futureY = YMin;
                }
                if (Math.max(deltaX, deltaY) > 2) {
                    this.hasDragged = true; // do not set to false if the marker is back to its position
                }
                if (this.hasDragged) {
                    this.internalY = futureY;
                    this.internalX = futureX;
                }
                event.stopPropagation();
                event.preventDefault();
            }
        },
        onMouseUp(event) {
            if (this.draggable && this.hasDragged && (this.dragMouseDown || this.rotationMouseDown)) {
                this.marker.x = this.internalX;
                this.marker.y = this.internalY;
                this.marker.degree = this.degree;
                this.$emit('move', this.marker);
            }
            this.rotationMouseDown = false;
            this.dragMouseDown = false;
            setTimeout(() => {
                this.hasDragged = false;
            });
        },
    },
    data() {
        return {
            iconWidth: 30,
            iconHeight: 45,
            internalX: '50',
            internalY: '50',
            lastX: '50',
            lastY: '50',
            degree: 0,
            rotationMouseDown: false,
            dragMouseDown: false,
            hasDragged: false,
            dragXDelta: null,
            dragYDelta: null,
            hasBeenHover: false,
            color: '#386da5',
        };
    },
};
</script>
