<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex gap-2 flex-col items-start min-h-main">
        <div class="w-full flex-col">
            <div class="flex gap-2 flex-col sm:flex-row">
                <div class="flex flex-col flex-grow">
                    <div class="flex justify-between">
                        <app-button
                            @click="addWeatherIssue()"
                            :label="$t('project.follow.weatherIssues.newWeatherIssue')"
                        />

                        <table class="text-sm">
                            <tr>
                                <th scope="row" class="text-right text-sm">
                                    {{ $t('project.information.generals.nbDaysCCAP') }} :
                                </th>
                                <td class="px-3">
                                    <app-number-link
                                        :label="$t('project.information.generals.nbDaysCCAP')"
                                        v-model="project.nbDaysWeatherIssues"
                                        :show-label="false"
                                        @input="saveCCAP($event)"
                                        :disabled="!isAdmin"
                                    ></app-number-link>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="flex flex-grow">
                        <app-multi-picker
                            ref="filter"
                            icon="icon-magnify"
                            :allowStringCriteria="true"
                            class="w-full my-2"
                            v-model="filterValue"
                            :options="filterOptions"
                            :strictMatching="true"
                        >
                            <template v-slot:option="{ option }">
                                <span>{{ option.name }}</span>
                            </template>
                        </app-multi-picker>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="grid sm:grid-rows-1 grid-cols-1 sm:grid-cols-5 gap-4 my-5">
                    <div class="border shadow p-1">
                        <table class="w-full">
                            <tr>
                                <th scope="row" class="text-right text-sm whitespace-nowrap">
                                    {{ $t('project.follow.weatherIssues.reported') }} :
                                </th>
                                <td class="px-3 whitespace-nowrap">
                                    {{ reportedWeatherIssues }} ({{ reportedDays }}J)
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="border shadow p-1">
                        <table class="w-full">
                            <tr>
                                <th scope="row" class="text-right text-sm whitespace-nowrap">
                                    {{ $t('project.follow.weatherIssues.validated') }} :
                                </th>
                                <td class="px-3 whitespace-nowrap">
                                    {{ validatedWeatherIssues }} ({{ validatedDays }}J)
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="border shadow p-1">
                        <table class="w-full">
                            <tr>
                                <th scope="row" class="text-right text-sm whitespace-nowrap">
                                    {{ $t('project.follow.weatherIssues.rejected') }} :
                                </th>
                                <td class="px-3 whitespace-nowrap">
                                    {{ rejectedWeatherIssues }} ({{ rejectedDays }}J)
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="border shadow p-1">
                        <table class="w-full">
                            <tr>
                                <th scope="row" class="text-right text-sm whitespace-nowrap">
                                    {{ $t('project.follow.weatherIssues.toValidate') }} :
                                </th>
                                <td class="px-3 whitespace-nowrap">
                                    {{ weatherIssuesToValidate }} ({{ daysToValidate }}J)
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="border shadow p-1">
                        <table class="w-full">
                            <tr>
                                <th scope="row" class="text-right text-sm whitespace-nowrap">
                                    {{ $t('project.follow.weatherIssues.CCAPDiff') }} :
                                </th>
                                <td
                                    class="px-3 whitespace-nowrap"
                                    :class="{
                                        'text-red-500': project.nbDaysWeatherIssues - validatedDays <= 0,
                                        'text-green-600': project.nbDaysWeatherIssues - validatedDays > 0,
                                    }"
                                >
                                    {{ project.nbDaysWeatherIssues - validatedDays }}J
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading" :disabled="selection.length === 0" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell">
                            <app-checkbox
                                :value="selection.length === maxFilteredItemIds.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < maxFilteredItemIds.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('date')"
                            >
                                {{ $t('commons.date') }}
                                <div v-if="sortKey === 'date'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                {{ $t('commons.name') }}
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('bundle')"
                            >
                                {{ $t('bundles.bundle') }}
                                <div v-if="sortKey === 'bundle'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <span :title="$t('commons.duration')">
                                {{ $t('commons.duration') }}
                            </span>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <span :title="$t('project.follow.weatherIssues.proof')">
                                <icon-file-pdf-outline width="16" height="16" />
                            </span>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <span>
                                {{ $t('project.follow.weatherIssues.isValidated') }}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="weatherIssue in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell">
                                <a :id="'uuid_' + weatherIssue.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="weatherIssue.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(weatherIssue)"
                                    :disabled="!isAdmin && (!weatherIssue.isEmitter || !!weatherIssue.conclusion)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">{{ weatherIssue.date | humanizeDate }}</td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'weatherIssue',
                                            params: { ...$route.params, weatherIssueId: weatherIssue.id },
                                        }"
                                    >
                                        {{ weatherIssue.name }}
                                        <span v-if="!weatherIssue.name || weatherIssue.name.trim().length === 0">
                                            ...
                                        </span>
                                    </router-link>
                                </div>
                            </td>

                            <td class="border-r p-1">
                                <div
                                    v-if="weatherIssue.emitters.length === 1"
                                    style="max-width: 10rem"
                                    class="truncate"
                                >
                                    <span class="">
                                        {{ weatherIssue.emitters[0].label }}
                                    </span>
                                </div>
                                <div
                                    class="flex flex-col truncate"
                                    v-if="weatherIssue.emitters.length === 2"
                                    style="max-width: 10rem"
                                >
                                    <span>
                                        {{ weatherIssue.emitters[0].label }}
                                    </span>
                                    <span class="">
                                        {{ weatherIssue.emitters[1].label }}
                                    </span>
                                </div>
                                <span
                                    v-if="weatherIssue.emitters.length >= 3"
                                    :title="weatherIssue.emitters.map((recipient) => recipient.label).join('\n')"
                                >
                                    <app-tips>
                                        {{
                                            weatherIssue.emitters.length +
                                            ' ' +
                                            $t('project.follow.weatherIssues.emitters')
                                        }}
                                    </app-tips>
                                </span>
                            </td>
                            <td class="text-center border-r p-1">
                                {{ weatherIssue.duration }}
                            </td>
                            <td class="text-center border-r p-1">
                                <app-file-link
                                    class="justify-center"
                                    icon="icon-file-pdf-outline"
                                    v-if="weatherIssue.attachments.length > 0"
                                    :url="weatherIssue.attachments[0].url"
                                />
                            </td>
                            <td class="text-center border-r p-1">
                                <div
                                    :title="$t('project.follow.weatherIssues.conclusions.validated')"
                                    v-if="weatherIssue.conclusion === 'validated'"
                                >
                                    <icon-check-circle-outline class="text-green-600 mx-2" width="16" />
                                </div>
                                <div
                                    :title="$t('project.follow.weatherIssues.conclusions.rejected')"
                                    v-else-if="weatherIssue.conclusion === 'rejected'"
                                >
                                    <icon-close-circle-outline class="text-red-700 mx-2" width="16" />
                                </div>
                                <div :title="$t('project.follow.weatherIssues.conclusions.expected')" v-else>
                                    <icon-timelapse class="text-secondary mx-2" width="16" />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>

        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-input-text :label="$t('commons.name')" v-model="editedWeatherIssue.name" class="col-span-2" />
                <app-input-text
                    :label="$t('project.follow.weatherIssues.duration')"
                    v-model="editedWeatherIssue.duration"
                    class="col-span-2"
                />
                <app-select
                    :label="$t('project.follow.weatherIssues.conclusion')"
                    v-model="editedWeatherIssue.conclusion"
                    v-if="isAdmin"
                >
                    <option value="">{{ $t('project.follow.weatherIssues.conclusions.none') }}</option>
                    <option value="validated">
                        {{ $t('project.follow.weatherIssues.conclusions.validated') }}
                    </option>
                    <option value="rejected">
                        {{ $t('project.follow.weatherIssues.conclusions.rejected') }}
                    </option>
                </app-select>

                <app-multi-picker
                    class="col-span-2"
                    v-model="editedWeatherIssue.emitters"
                    :options="bundles"
                    :label="$t('project.follow.observation.recipients')"
                    label-key="label"
                >
                    <template v-slot:option="{ option }">
                        <app-bundle :bundle="option" />
                    </template>
                </app-multi-picker>
                <app-upload-button
                    v-if="!file"
                    class="text-sm"
                    :label="$t('project.follow.weatherIssue.addAttachment')"
                    scope="weatherIssues"
                    @select="addAttachment"
                ></app-upload-button>
                <app-footer @click="saveMultiple" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
        <app-popup
            ref="batchAddPopup"
            :show-header="true"
            :title="$t('project.follow.weatherIssue.addFromCalendarPopupTitle')"
        >
            <app-label class="p-4" for="text" :label="$t('project.follow.weatherIssue.label')">
                <div class="flex items-center">
                    <app-button
                        class="p-1 text-gray-600"
                        icon="icon-weather-windy"
                        :variant="weatherIssueLastMode === 'windy' ? 'selected' : 'default'"
                        @click="
                            weatherIssueLastMode = 'windy';
                            weatherIssueLastName = $t('project.follow.weatherIssue.defaultLabelWindy');
                        "
                    ></app-button>
                    <app-button
                        class="p-1 text-blue-200"
                        icon="icon-snowflake-thermometer"
                        :variant="weatherIssueLastMode === 'cold' ? 'selected' : 'default'"
                        @click="
                            weatherIssueLastMode = 'cold';
                            weatherIssueLastName = $t('project.follow.weatherIssue.defaultLabelCold');
                        "
                    ></app-button>
                    <app-button
                        class="p-1 text-red-600"
                        icon="icon-sun-thermometer"
                        :variant="weatherIssueLastMode === 'hot' ? 'selected' : 'default'"
                        @click="
                            weatherIssueLastMode = 'hot';
                            weatherIssueLastName = $t('project.follow.weatherIssue.defaultLabelHot');
                        "
                    ></app-button>
                    <app-button
                        class="p-1 text-blue-600"
                        icon="icon-weather-pouring"
                        :variant="weatherIssueLastMode === 'rainy' ? 'selected' : 'default'"
                        @click="
                            weatherIssueLastMode = 'rainy';
                            weatherIssueLastName = $t('project.follow.weatherIssue.defaultLabelRainy');
                        "
                    ></app-button>
                    <app-input-text id="text" v-model="weatherIssueLastName"></app-input-text>
                </div>
            </app-label>
            <app-calendar
                :showLegend="false"
                v-model="minDate"
                @dayClick="onDayClick($event)"
                :calendar="calendar"
            ></app-calendar>
        </app-popup>
    </main>
</template>

<script>
import { filterMatch, getMapById, sortBy } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { createWeatherIssue, getWeatherIssues, removeWeatherIssue, updateWeatherIssue } from './weatherIssues.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { humanizeDate } from '@/filters/dateFilter';
import { getProject, updateProject } from '@/features/projects/projects.service';
import AppTips from '@/components/app-tips/AppTips';
import IconCheck from '@/icons/IconCheck';
import { combineLatest } from 'rxjs';
import { getBundles, sortBundles } from '@/features/bundles/bundles.service';
import AppInputText from '@/components/appInputText/AppInputText';
import AppBundle from '@/components/app-bundle/appBundle';
import AppFooter from '@/components/appFooter/AppFooter';
import AppPopup from '@/components/app-popup/AppPopup';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import IconFilePdfOutline from '@/icons/IconFilePdfOutline';
import AppCalendar from '@/components/appCalendar/AppCalendar';
import startOfDay from 'date-fns/startOfDay';
import AppLabel from '@/components/appLabel/AppLabel';
import IconCloseCircleOutline from '@/icons/IconCloseCircleOutline.vue';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline.vue';
import IconTimelapse from '@/icons/IconTimelapse.vue';

export default {
    components: {
        IconTimelapse,
        IconCheckCircleOutline,
        IconCloseCircleOutline,
        AppLabel,
        AppCalendar,
        IconFilePdfOutline,
        AppUploadButton,
        AppFileLink,
        AppMultipleUploadButton,
        AppNumberLink,
        AppPopup,
        AppFooter,
        AppBundle,
        AppInputText,
        IconCheck,
        AppTips,
        AppQuickActions,
        AppCheckbox,
        AppSelect,
        AppDateInput,
        AppMultiPicker,
        AppButton,
    },
    async created() {
        this.restoreFilter();
        this.restoreSort();
        this.subscriptions = [
            combineLatest([
                getWeatherIssues(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
            ]).subscribe(([weatherIssues, bundles, project]) => {
                this.project = project;
                this.isAdmin = project.me.allowedFeatures.includes('project_planning');
                const bundleMap = getMapById(bundles);
                this.restoreSelection();
                this.items = weatherIssues.map((weatherIssue) => {
                    return {
                        ...weatherIssue,
                        filterString: [weatherIssue.name, humanizeDate(weatherIssue.date)].join(),
                        isSelected: this.selection.includes(weatherIssue.id),
                        emitters: weatherIssue.emitterIds.map((id) => bundleMap[id]).filter((a) => !!a),
                        isEmitter: project.me.bundleIds.find((bundleId) => weatherIssue.emitterIds.includes(bundleId)),
                    };
                });
                this.cleanupSavedSelection();
                const lastVisitedWeatherIssueId = localStorage.getItem(
                    'weatherIssue.lastVisitedWeatherIssueId.' + this.$route.params.projectId,
                );
                if (lastVisitedWeatherIssueId) {
                    this.$nextTick(() => {
                        const element = this.$el.querySelector('#uuid_' + lastVisitedWeatherIssueId);
                        if (element) element.scrollIntoView();
                    });
                }
                this.bundles = sortBundles(bundles);
                this.loading = false;
            }),
        ];
    },
    computed: {
        calendar() {
            return this.items.map((item) => ({ ...item, time: startOfDay(item.date).getTime(), type: 'weatherIssue' }));
        },
        reported() {
            return this.items.reduce((before, weatherIssue) => {
                return before + weatherIssue.duration;
            }, 0);
        },
        weatherIssuesToValidate() {
            return this.items.reduce((before, weatherIssue) => {
                if (!weatherIssue.conclusion) {
                    return before + 1;
                } else {
                    return before;
                }
            }, 0);
        },
        daysToValidate() {
            return this.items.reduce((before, weatherIssue) => {
                if (!weatherIssue.conclusion) {
                    return before + weatherIssue.duration;
                } else {
                    return before;
                }
            }, 0);
        },
        validatedWeatherIssues() {
            return this.items.reduce((before, weatherIssue) => {
                if (weatherIssue.conclusion === 'validated') {
                    return before + 1;
                } else {
                    return before;
                }
            }, 0);
        },
        validatedDays() {
            return this.items.reduce((before, weatherIssue) => {
                if (weatherIssue.conclusion === 'validated') {
                    return before + weatherIssue.duration;
                } else {
                    return before;
                }
            }, 0);
        },
        rejectedWeatherIssues() {
            return this.items.reduce((before, weatherIssue) => {
                if (weatherIssue.conclusion === 'rejected') {
                    return before + 1;
                } else {
                    return before;
                }
            }, 0);
        },
        rejectedDays() {
            return this.items.reduce((before, weatherIssue) => {
                if (weatherIssue.conclusion === 'rejected') {
                    return before + weatherIssue.duration;
                } else {
                    return before;
                }
            }, 0);
        },
        reportedWeatherIssues() {
            return this.items.length;
        },
        reportedDays() {
            return this.items.reduce((before, weatherIssue) => {
                return before + weatherIssue.duration;
            }, 0);
        },
        quickActions() {
            return [
                {
                    name: this.$t('project.follow.weatherIssues.newWeatherIssue'),
                    run: () => this.addWeatherIssue(),
                },
                {
                    name: this.$t('project.follow.weatherIssues.batchAdd'),
                    run: () => this.$refs.batchAddPopup.open(),
                },
            ];
        },
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'bundle') {
                        return item.index + '_' + item.date?.toISOString();
                    } else if (this.sortKey === 'name') {
                        return item.name + '_' + item.date?.toISOString();
                    } else if (this.sortKey === 'date') {
                        return item.date?.toISOString();
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        maxFilteredItemIds() {
            if (this.isAdmin) {
                return this.filteredItems.map((item) => item.id);
            } else {
                return this.filteredItems.filter((item) => !item.conclusion && item.isEmitter).map((item) => item.id);
            }
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('project.follow.weatherIssues.conclusion'),
                    id: 'validationCriteriaType',
                    children: [
                        {
                            name: this.$t('project.follow.weatherIssues.conclusions.rejected'),
                            id: 'rejected',
                            _validationCriteria: true,
                        },
                        {
                            name: this.$t('project.follow.weatherIssues.conclusions.validated'),
                            id: 'validated',
                            _validationCriteria: true,
                        },
                        {
                            name: this.$t('project.follow.weatherIssues.conclusions.expected'),
                            id: 'expected',
                            _validationCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('project.follow.weatherIssues.attachments'),
                    id: 'attachmentCriteriaType',
                    children: [
                        {
                            name: this.$t('project.follow.weatherIssues.withAttachments'),
                            id: 'withAttachment',
                            _attachmentCriteria: true,
                        },
                        {
                            name: this.$t('project.follow.weatherIssues.noAttachments'),
                            id: 'noAttachment',
                            _attachmentCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.label,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        async onDayClick(date) {
            const existingWeatherIssue = this.items.find((item) => item.time === date.getTime());
            if (existingWeatherIssue) {
                await confirm(this.$t('commons.confirmMessage'));
                await removeWeatherIssue(this.$route.params.projectId, existingWeatherIssue.id);
            } else {
                await createWeatherIssue(this.$route.params.projectId, {
                    date,
                    name: this.weatherIssueLastName,
                    emitterIds: this.isAdmin ? [] : this.project.me.bundleIds,
                });
            }
        },
        addAttachment(file) {
            this.file = file;
        },
        saveCCAP: function (nbDaysWeatherIssues) {
            updateProject({
                id: this.$route.params.projectId,
                nbDaysWeatherIssues,
            });
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeWeatherIssue(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedWeatherIssue = {
                    name: this.getSelectionCommonName(selectedItems),
                    conclusion: this.getSelectionCommonConclusion(selectedItems),
                    emitters: this.getSelectionCommonEmitters(selectedItems),
                    duration: this.getSelectionCommonDuration(selectedItems),
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        getSelectionCommonDuration(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.duration);
            if (firstItem && selectedItems.every((item) => item.duration && item.duration === firstItem.duration)) {
                return firstItem.duration;
            } else {
                return '';
            }
        },
        getSelectionCommonName(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.name);
            if (firstItem && selectedItems.every((item) => item.name && item.name === firstItem.name)) {
                return firstItem.name;
            } else {
                return '';
            }
        },
        getSelectionCommonConclusion(selectedItems) {
            const firstItem = selectedItems[0];
            if (firstItem && selectedItems.every((item) => item.conclusion === firstItem.conclusion)) {
                return firstItem.conclusion;
            } else {
                return '';
            }
        },
        getSelectionCommonEmitters(selectedItems) {
            const emitterIds = selectedItems[0].emitters.map((a) => a.id);
            if (
                selectedItems.every(
                    (item) =>
                        item.emitters.length === emitterIds.length &&
                        item.emitters.every(({ id }) => emitterIds.includes(id)),
                )
            ) {
                return [...selectedItems[0].emitters];
            } else {
                return [];
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.maxFilteredItemIds.length) {
                this.selection = this.maxFilteredItemIds;
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'weatherIssues.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('weatherIssues.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addWeatherIssue() {
            const result = await createWeatherIssue(this.$route.params.projectId, {
                name: this.$t('project.follow.weatherIssues.newName'),
            });
            await this.$router.push({
                name: 'weatherIssue',
                params: { projectId: this.$route.params.projectId, weatherIssueId: result.id },
            });
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        matchAttachment(criteria, item) {
            return (
                criteria.length === 0 ||
                (criteria.includes('withAttachment') && item.attachments.length > 0) ||
                (criteria.includes('noAttachment') && item.attachments.length === 0)
            );
        },
        matchValidation(criteria, item) {
            return (
                criteria.length === 0 ||
                (criteria.includes('validated') && item.conclusion === 'validated') ||
                (criteria.includes('rejected') && item.conclusion === 'rejected') ||
                (criteria.includes('expected') && !item.conclusion)
            );
        },
        matchBundleFilter(item, bundleIds) {
            return item.emitterIds && item.emitterIds.find((id) => bundleIds.includes(id));
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const validationCriteria = filter
                .filter((aCriteria) => aCriteria._validationCriteria)
                .map((criteria) => criteria.id);
            const attachmentCriteria = filter
                .filter((aCriteria) => aCriteria._attachmentCriteria)
                .map((criteria) => criteria.id);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchString: this.matchString(stringCriteria, item),
                    matchAttachment: this.matchAttachment(attachmentCriteria, item),
                    matchValidation: this.matchValidation(validationCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'weatherIssues_filter_' + this.$route.params.projectId,
                JSON.stringify({ filterValue }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('weatherIssues_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        saveSort() {
            localStorage.setItem('weatherIssues_sortKey_' + this.$route.params.projectId, this.sortKey);
            localStorage.setItem('weatherIssues_sortAsc_' + this.$route.params.projectId, this.sortAsc);
        },
        restoreSort() {
            this.sortKey = localStorage.getItem('weatherIssues_sortKey_' + this.$route.params.projectId) || 'date';
            this.sortAsc = localStorage.getItem('weatherIssues_sortAsc_' + this.$route.params.projectId) === 'true';
        },
        async saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            await Promise.all(
                selectedItems.map(async (weatherIssue) => {
                    return updateWeatherIssue(this.$route.params.projectId, {
                        id: weatherIssue.id,
                        name: this.editedWeatherIssue.name !== '' ? this.editedWeatherIssue.name : weatherIssue.name,
                        duration:
                            this.editedWeatherIssue.duration !== ''
                                ? parseInt(this.editedWeatherIssue.duration)
                                : weatherIssue.duration,
                        conclusion:
                            this.editedWeatherIssue.conclusion === 'rejected' ||
                            this.editedWeatherIssue.conclusion === 'validated'
                                ? this.editedWeatherIssue.conclusion
                                : null,
                        emitterIds: (this.editedWeatherIssue.emitters.length > 0
                            ? this.editedWeatherIssue.emitters
                            : weatherIssue.emitters
                        ).map((a) => a.id),
                        attachments: this.file
                            ? [...weatherIssue.attachments, { url: this.file.url, fileName: this.file.name }]
                            : weatherIssue.attachments,
                    });
                }),
            );
            this.$refs.modifyAllPopup.close();
        },
    },
    data() {
        return {
            file: null,
            loading: true,
            sortKey: 'date',
            sortAsc: true,
            isAdmin: false,
            selection: [],
            bundles: [],
            items: [],
            project: {},
            filterValue: [],
            editedWeatherIssue: {},
            minDate: new Date(),
            weatherIssueLastMode: 'windy',
            weatherIssueLastName: this.$t('project.follow.weatherIssue.defaultLabelWindy'),
        };
    },
};
</script>
