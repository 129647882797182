<template>
    <div class="flex gap-1" v-if="variant === 'default'">
        <app-number-link
            @enter="$emit('enter', $event)"
            :value="value"
            :disabled="disabled"
            :label="label || value + ''"
            :show-label="showLabel"
            @input="$emit('input', $event)"
        >
            <slot></slot>
        </app-number-link>
    </div>
    <app-tips v-else class="">
        <span v-if="!backward && referenceDate && dateValue" class="flex">
            {{ referenceDate | humanizeDate }} + {{ value }}J &rarr;
            <b>
                <app-date-link :disabled="disabled" :value="dateValue" @input="onDateInput" :show-label="showLabel" />
            </b>
        </span>
        <span v-else-if="backward && referenceDate && dateValue">
            {{ referenceDate | humanizeDate }} - {{ value }}J &rarr;
            <b>
                <app-date-link :disabled="disabled" :value="dateValue" @input="onDateInput" :show-label="showLabel" />
            </b>
        </span>
        <span v-else>&nbsp;</span>
    </app-tips>
</template>
<script>
import isValid from 'date-fns/isValid';
import { getDuration, applyDuration } from '@/services/duration.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppTips from '@/components/app-tips/AppTips';
import AppDateLink from '@/components/appDateLink/AppDateLink';
export default {
    components: { AppDateLink, AppTips, AppNumberLink },
    data() {
        return {
            dateValue: null,
        };
    },
    created() {
        this.update();
    },
    computed: {
        dateModel: {
            set(value) {
                if (value) {
                    this.dateValue = new Date(value);
                }
            },
            get() {
                return this.dateValue ? this.dateValue.toISOString().substring(0, 10) : null;
            },
        },
    },
    watch: {
        referenceDate() {
            this.update();
        },
        value() {
            this.update();
        },
    },
    methods: {
        update() {
            if (this.value && isValid(this.value) && this.referenceDate) {
                if (this.backward) {
                    this.dateValue = applyDuration(this.referenceDate, -this.value, this.agenda, this.bundleId);
                } else {
                    this.dateValue = applyDuration(this.referenceDate, this.value, this.agenda, this.bundleId);
                }
            }
        },
        onDateInput(value) {
            if (!this.backward && value > this.referenceDate) {
                this.$emit('input', getDuration(value, this.referenceDate, this.agenda, this.bundleId));
            } else if (this.backward && value < this.referenceDate) {
                this.$emit('input', getDuration(this.referenceDate, value, this.agenda, this.bundleId));
            }
        },
    },
    props: {
        referenceDate: Date | String,
        backward: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        defaultValue: Boolean,
        label: String,
        value: Number,
        agenda: {
            type: Array,
            default() {
                return [];
            },
        },
        variant: {
            type: String,
            default: 'default',
        },
        bundleId: String,
    },
};
</script>
