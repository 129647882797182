import { render, staticRenderFns } from "./SaveBeforeLeave.vue?vue&type=template&id=6a580721&scoped=true&"
import script from "./SaveBeforeLeave.vue?vue&type=script&lang=js&"
export * from "./SaveBeforeLeave.vue?vue&type=script&lang=js&"
import style0 from "./SaveBeforeLeave.vue?vue&type=style&index=0&id=6a580721&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a580721",
  null
  
)

export default component.exports