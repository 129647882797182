import db from '@/rxdb/database';
import { map } from 'rxjs';
import { applyIndex } from '@/features/services/itemUtils';
import { updateDirectory } from '@/features/services/directories.service';

function mapService(serviceDb) {
    return serviceDb ? serviceDb.toMutableJSON() : null;
}

export function queryService(projectId, serviceId) {
    return db
        .getProjectCollections(projectId)
        .services.findOne({ selector: { id: serviceId } })
        .exec()
        .then((item) => mapService(item));
}
export function reOrder(projectId, array) {
    return Promise.all(
        applyIndex(array).map((node) => {
            const patch = {
                id: node.id,
                parentDirectoryId: node.parentDirectoryId,
                index: node.index,
            };
            if (node.__typename === 'Directory') {
                return updateDirectory(projectId, patch);
            } else if (node.__typename === 'Service') {
                return updateService(projectId, patch);
            }
        }),
    );
}
export function getServices(projectId) {
    return db
        .getProjectCollections(projectId)
        .services.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapService)));
}
export function getService(projectId, serviceId) {
    return db
        .getProjectCollections(projectId)
        .services.findOne({ selector: { id: serviceId } })
        .$.pipe(map(mapService));
}

export function getServicesByBundleId(projectId, bundleId) {
    return db
        .getProjectCollections(projectId)
        .services.find({ selector: { bundleId } })
        .$.pipe(map((items) => items.map(mapService)));
}
export async function updateService(projectId, service) {
    const dbService = await db
        .getProjectCollections(projectId)
        .services.findOne({ selector: { id: service.id } })
        .exec();
    return dbService.atomicPatch(service);
}

export async function createService(projectId, service) {
    const result = await db.getProjectCollections(projectId).services.insert(service);
    return result.toJSON();
}
export async function bulkInsert(projectId, services) {
    return db.getProjectCollections(projectId).services.bulkInsert(services);
}

export async function removeService(projectId, serviceId) {
    const entity = await db
        .getProjectCollections(projectId)
        .services.findOne({ selector: { id: serviceId } })
        .exec();
    return entity.remove();
}

export function removeServicePredecessor(projectId, service, predecessorServiceId) {
    return updateService(projectId, {
        id: service.id,
        predecessors: service.predecessors
            .filter((predecessor) => predecessor.serviceId !== predecessorServiceId)
            .map((predecessor) => ({
                serviceId: predecessor.serviceId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}

export function removeServicePredecessors(projectId, service, serviceIds) {
    return updateService(projectId, {
        id: service.id,
        predecessors: service.predecessors
            .filter((predecessor) => !serviceIds.includes(predecessor.serviceId))
            .map((predecessor) => ({
                serviceId: predecessor.serviceId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}

export function updateServicePredecessor(projectId, service, predecessor) {
    return updateService(projectId, {
        id: service.id,
        predecessors: service.predecessors
            .map((aPredecessor) => (aPredecessor.serviceId === predecessor.serviceId ? predecessor : aPredecessor))
            .map((predecessor) => ({
                serviceId: predecessor.serviceId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
    });
}
export function createServicePredecessor(projectId, service, predecessor) {
    return updateService(projectId, {
        id: service.id,
        predecessors: [
            ...service.predecessors.map((predecessor) => ({
                serviceId: predecessor.serviceId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
            predecessor,
        ],
    });
}
export function createServicePredecessors(projectId, service, predecessors) {
    return updateService(projectId, {
        id: service.id,
        predecessors: [
            ...service.predecessors.map((predecessor) => ({
                serviceId: predecessor.serviceId,
                delay: predecessor.delay,
                type: predecessor.type,
            })),
            ...predecessors,
        ],
    });
}

export function bulkDelete(projectId, serviceIds) {
    return db.getProjectCollections(projectId).services.bulkRemove(serviceIds);
}
export default {
    bulkDelete,
};
