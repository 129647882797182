<template>
    <form class="p-2 flex flex-col mb-24 gap-4">
        <span class="">
            <observation-status-buttons
                :observation="observation"
                @input="updateObservation($event)"
                @remove="deleteObservation()"
            ></observation-status-buttons>
        </span>
        <app-text-editor
            :label="$t('observations.titleLabel')"
            v-model="observation.title"
            :disabled="!(isAdmin || isReporter)"
            @blur="onTitle"
        ></app-text-editor>
        <app-multi-picker
            v-model="observation.recipients"
            :options="bundles"
            label-key="label"
            @close="updateRecipients"
            @removeChips="updateRecipients"
            :label="$t('preparations.recipients')"
            :disabled="!(isAdmin || isReporter)"
        >
            <template v-slot:option="{ option }">
                <app-bundle :bundle="option" />
            </template>
        </app-multi-picker>
        <app-label class="flex flex-col gap-2" :label="$t('preparations.relatedObservations') + ' :'"></app-label>
        <div v-for="item of relatedObservations" class="flex my-2">
            <div class="flex">
                <span
                    v-if="!item.footprint"
                    class="p-1 px-2 text-xs mr-2 mt-1"
                    :class="{
                        [item.classes]: true,
                        border: item.type === 'general' || item.type === 'administrative',
                    }"
                >
                    {{ (item.index + 1).toString().padStart(3, '0') }}
                </span>
                <span class="px-2" v-else>
                    <app-static-marker :observation="item" class="text-xs mr-2 -mt-1"></app-static-marker>
                </span>
            </div>
            <div class="flex text-xs flex-col">
                <div class="flex gap-2">
                    <app-bundle :bundle="item.recipients[0]" v-if="item.recipients.length > 0" />
                    <router-link
                        :to="{
                            name: 'preparationDocument',
                            params: {
                                projectId: $route.params.projectId,
                                documentId: item.preparationDocumentId,
                                preparationId: item.visa.preparationId,
                                preparationVisaId: item.relatedContentId,
                            },
                        }"
                        v-if="item.preparation"
                        class="underline"
                    >
                        <span>{{ item.preparation.name }}</span>
                        <span>
                            v{{
                                item.preparation.versions.length -
                                item.preparation.versions.findIndex((p) => p.id === item.preparation.id)
                            }}
                        </span>
                    </router-link>
                </div>
                <app-clamp :html-content="item.title" />
            </div>
            <div class="flex flex-grow"></div>
            <div>
                <app-button
                    size="mini"
                    icon="icon-trash-can-outline"
                    variant="danger"
                    @click="onRemoveRelatedObservation(item.id)"
                    v-if="isAdmin || isReporter"
                />
            </div>
        </div>
        <div class="flex justify-start">
            <app-button
                size="mini"
                @click="$refs.autocomplete.open()"
                :label="$t('preparations.addRelatedObservation')"
                icon="icon-plus"
                :disabled="loading"
            ></app-button>
            <app-autocomplete
                v-model="relatedObservations"
                :options="observationOptions"
                :allowStringCriteria="false"
                ref="autocomplete"
                :multiple="true"
                :max-options="100"
                :filterStringFunction="filterStringFunction"
                @input="onRelatedObservationsInput"
            >
                <template v-slot:title>
                    <app-separator>
                        <h2>{{ $t('preparations.addRelatedObservation') }}</h2>
                        <div class="flex flex-grow"></div>
                        <app-button
                            size="mini"
                            icon="icon-close"
                            aria-label="close popup"
                            @click="$refs.autocomplete.close()"
                        />
                    </app-separator>
                </template>
                <template v-slot:item="{ item }">
                    <div class="flex text-xs gap-2">
                        <span class="p-1 px-2" :class="item.classes">
                            {{ (item.index + 1).toString().padStart(3, '0').padStart(4, ' ') }}
                        </span>
                        <app-clamp :html-content="item.title" />
                    </div>
                </template>
            </app-autocomplete>
        </div>
        <div v-if="loading" class="flex justify-center">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
    </form>
</template>

<script>
import {
    getObservation,
    getObservationClass,
    getVisaObservations,
    queryObservations,
    removeObservation,
    updateObservation,
} from '@/features/observations/observation.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppBundle from '@/components/app-bundle/appBundle';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { combineLatest } from 'rxjs';
import { getMapById, uniq } from '@/services/sanitize.service';
import ObservationStatus from '@/features/observations/ObservationStatus';
import ObservationStatusButtons from '@/features/observations/ObservationStatusButtons.vue';
import AppClamp from '@/components/appClamp/AppClamp.vue';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import { queryPreparation } from '@/features/preparations/preparations.service';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { queryPreparationVisa } from '@/features/preparations/preparationVisas.service';

export default {
    props: ['observationId'],
    components: {
        AppSeparator,
        AppAutocomplete,
        AppLabel,
        AppButton,
        AppStaticMarker,
        AppClamp,
        ObservationStatusButtons,
        ObservationStatus,
        AppBundle,
        AppMultiPicker,
        AppTextEditor,
    },
    computed: {
        isReporter() {
            return !!this.myBundleIds.includes(this.observation.reportedBy);
        },
    },
    created() {
        this.loading = true;

        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.myBundleIds = project.me.bundleIds;
        });
        this.subscriptions = [
            combineLatest([
                getObservation(this.$route.params.projectId, this.observationId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(async ([observation, bundles]) => {
                this.bundleMap = getMapById(bundles);
                if (observation) {
                    this.observation = {
                        ...observation,
                        recipients: observation.recipientIds.map((id) => this.bundleMap[id]),
                        validator: this.bundleMap[observation.validatedBy],
                        reporter: this.bundleMap[observation.reportedBy],
                        obsoleter: this.bundleMap[observation.obsoleteBy],
                        resolver: this.bundleMap[observation.resolvedBy],
                    };
                    if (this.observation.relatedObservationIds) {
                        const relatedObservations = await Promise.all(
                            this.observation.relatedObservationIds.map((observationId) =>
                                queryObservations(this.$route.params.projectId, observationId),
                            ),
                        );

                        this.relatedObservations = await Promise.all(relatedObservations.map(this.mapObservation));
                    }
                    const options = await getVisaObservations(this.$route.params.projectId);
                    this.observationOptions = await Promise.all(options.map(this.mapObservation));
                } else {
                    this.observation = { recipientIds: [] };
                }
                this.bundles = bundles;
                this.loading = false;
            }),
        ];
    },
    methods: {
        filterStringFunction(observation) {
            return observation.index + 1 + ' ' + observation.title;
        },
        async mapObservation(observation) {
            const visa = await queryPreparationVisa(this.$route.params.projectId, observation.relatedContentId);
            let preparation = null;
            if (visa) {
                preparation = await queryPreparation(this.$route.params.projectId, visa.preparationId);
            }
            return {
                ...observation,
                recipients: observation.recipientIds.map((id) => this.bundleMap[id]),
                visa,
                preparation,
                classes: getObservationClass(observation),
            };
        },
        updateRecipients() {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                recipientIds: this.observation.recipients.map((bundle) => bundle.id),
            });
        },
        updateObservation(observation) {
            updateObservation(this.$route.params.projectId, {
                ...observation,
                title: this.observation.title,
                /* workaround for blur Quilljs bug.
                    When you change title content and directly click on a new status,
                     the observation title is not saved : just reverted
                     */
            });
        },
        onRemoveRelatedObservation(observationId) {
            updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                relatedObservationIds: this.relatedObservations
                    .map((item) => item.id)
                    .filter((id) => observationId !== id),
            });
        },
        onRelatedObservationsInput() {
            updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                relatedObservationIds: this.relatedObservations.map((item) => item.id),
                recipientIds: uniq([
                    ...this.observation.recipientIds,
                    ...this.relatedObservations.reduce((acc, obs) => [...acc, ...obs.recipientIds], []),
                ]),
            });
        },
        async deleteObservation() {
            if (await confirm(this.$t('observations.removeSynthesis'))) {
                await removeObservation(this.$route.params.projectId, this.observation.id);
                this.$emit('deleted');
            }
        },
        onTitle() {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                title: this.observation.title,
            });
        },
    },
    data() {
        return {
            isAdmin: false,
            observation: { recipientIds: [] },
            subscriptions: [],
            myBundleIds: [],
            bundles: [],
            bundleMap: {},
            relatedObservations: [],
            observationOptions: [],
            loading: true,
        };
    },
};
</script>
