<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button
                    @click="addObservationsReport()"
                    :label="$t('project.editions.observationsReports.new')"
                    v-if="!readOnly"
                />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                </template>
            </app-multi-picker>
            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 6rem" class="text-left border-r p-1">
                            {{ $t('commons.date') }}
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('commons.name') }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="observationsReport in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + observationsReport.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="observationsReport.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(observationsReport)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ observationsReport.date | humanizeDate }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'observationsReport',
                                            params: {
                                                ...$route.params,
                                                observationsReportId: observationsReport.id,
                                            },
                                        }"
                                    >
                                        {{ observationsReport.name }}
                                        <span
                                            v-if="
                                                !observationsReport.name || observationsReport.name.trim().length === 0
                                            "
                                        >
                                            ...
                                        </span>
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { filterMatch } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import {
    createObservationsReport,
    getObservationsReports,
    removeObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import { humanizeDate } from '@/filters/dateFilter';

export default {
    components: {
        AppButton,
        AppMultiPicker,
        AppCheckbox,
        AppSelect,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        this.init();
    },
    computed: {
        filteredItems() {
            return this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
        },
        filterOptions() {
            return [];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getObservationsReports(this.$route.params.projectId, null),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([observationsReports, project]) => {
                    this.items = observationsReports.map((item) => ({
                        ...item,
                        filterString: humanizeDate(item.date) + ' ' + item.name,
                    }));
                    this.readOnly = !project.me.allowedFeatures.includes('project_observationsReports');
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        async addObservationsReport() {
            const result = await createObservationsReport(this.$route.params.projectId, {
                name: this.$t('project.editions.observationsReports.defaultName'),
                date: new Date(),
                showEmptyZones: true,
                showTables: true,
                showPhotos: true,
                showPending: true,
                showAdministrative: true,
                showObservationsOnTodoWork: true,
                showObservationsOnDoneWork: true,
                showOtherObservations: true,
                showObservationsWithoutZone: true,
                showEXEObservations: true,
                showOPRObservations: true,
                showReceiptObservations: true,
                showOPLObservations: true,
                showDeliveryObservations: true,
                showAPAObservations: true,
                showMaps: true,
                showResolved: true,
                showStats: true,
                generateAPDFByBundle: false,
                showValidated: true,
                bundleIds: [],
                zoneIds: [],
                files: [],
            });
            this.$router.push({
                name: 'observationsReport',
                params: {
                    projectId: this.$route.params.projectId,
                    observationsReportId: result.id,
                },
            });
        },
        scrollToLastVisited() {
            const lastVisitedObservationsReportId = localStorage.getItem(
                'observationsReport.lastVisitedObservationsReportId.' + this.$route.params.projectId,
            );
            if (lastVisitedObservationsReportId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedObservationsReportId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeObservationsReport(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'observationsReport.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('observationsReport.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'observationsReports_filter_' + this.$route.params.projectId,
                JSON.stringify({ filterValue }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('observationsReports_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchString: this.matchString(stringCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
    },
    data() {
        return {
            loading: true,
            readOnly: true,
            selection: [],
            items: [],
            filterValue: [],
        };
    },
};
</script>
