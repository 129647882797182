<template>
    <div class="app-picker">
        <div :class="{ underline: !disabled }">
            <span v-if="!value" @click="open">...</span>
            <app-bundle :bundle="value" @click.native="open" ref="trigger" />
        </div>
        <app-autocomplete
            :value="value"
            @input="$emit('input', $event)"
            :options="filteredBundles"
            labelKey="label"
            :allowStringCriteria="false"
            ref="autocomplete"
        >
            <template v-slot:title>
                <app-separator :label="$t('bundles.chooseBundle')" />
            </template>
            <template v-slot:item="{ item }">
                <slot :option="item" name="option"></slot>
            </template>
        </app-autocomplete>
    </div>
</template>
<script>
import AppSeparator from './appSeparator/AppSeparator';
import AppAutocomplete from './app-autocomplete/AppAutocomplete';
import AppBundle from '@/components/app-bundle/appBundle';
import { getBundles } from '@/features/bundles/bundles.service';
import { getProject } from '@/features/projects/projects.service';

export default {
    created() {
        this.subscriptions = [
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                this.bundles = bundles;
            }),
            getProject(this.$route.params.projectId).subscribe((project) => {
                this.project = project;
            }),
        ];
    },
    components: {
        AppBundle,
        AppAutocomplete,
        AppSeparator,
    },
    props: {
        value: Object,
        disabled: Boolean,
        required: Boolean,
        limitedToUserBundle: Boolean,
    },
    data() {
        return {
            bundles: [],
            project: null,
        };
    },
    computed: {
        filteredBundles() {
            if (this.limitedToUserBundle && this.project) {
                return this.bundles.filter((bundle) => this.project.me.bundleIds.includes(bundle.id));
            } else {
                return this.bundles;
            }
        },
    },
    methods: {
        open() {
            if (!this.disabled) {
                this.$refs.autocomplete.open();
            }
        },
        scrollTo() {
            this.$refs.trigger.scrollTo();
        },
    },
};
</script>
