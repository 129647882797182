<template>
    <div class="w-full col-span-2 mt-4 flex justify-center flex-col max-w-5xl">
        <bundle-contact-creation
            :contact="createdContact"
            ref="contactPopup"
            @save="saveContact($event)"
            :showIsRecipient="showResponsible"
        ></bundle-contact-creation>
        <app-label :label="$t('users.users')" v-if="contacts.length > 0"></app-label>
        <div class="flex flex-col w-full">
            <template v-for="contact in contacts">
                <div class="flex items-center gap-2 border p-1 gap-4 hover:bg-gray-200" :v-key="contact.id">
                    <div class="flex items-center justify-center" v-if="showResponsible">
                        <button
                            @click.prevent="changeResponsible(contact)"
                            :aria-label="$t('bundles.isResponsible')"
                            :title="$t('bundles.isResponsible')"
                            :alt="$t('bundles.isResponsible')"
                        >
                            <icon-police-badge
                                class="cursor-pointer"
                                :class="{
                                    'text-yellow-500': responsibleId === contact.id,
                                    'text-gray-300': responsibleId !== contact.id,
                                    'hover:text-yellow-500': responsibleId !== contact.id,
                                }"
                            ></icon-police-badge>
                        </button>
                    </div>
                    <div class="flex items-center justify-center" v-if="showResponsible">
                        <button
                            @click.prevent="toggleIsReportRecipient(contact)"
                            :aria-label="$t('users.isReportRecipient')"
                            :title="$t('users.isReportRecipient')"
                            :alt="$t('users.isReportRecipient')"
                        >
                            <icon-email
                                class="cursor-pointer"
                                :class="{
                                    'text-green-600': contact.isReportRecipient,
                                    'text-gray-300': !contact.isReportRecipient,
                                    'hover:text-green-600': !contact.isReportRecipient,
                                }"
                            ></icon-email>
                        </button>
                    </div>
                    <div class="flex-grow flex">
                        <div class="flex flex-grow flex-col">
                            <a
                                :class="{ 'hover:cursor-pointer hover:underline': !readOnly }"
                                @click="editContact(contact)"
                            >
                                {{ contact.firstName }} {{ contact.lastName }}
                            </a>
                            <app-tips>&nbsp;{{ contact.role }}</app-tips>
                        </div>
                        <div class="flex flex-col sm:flex-row text-sm sm:gap-4">
                            <app-phone :number="contact.phone" />
                            <app-mail :email="contact.email" />
                        </div>
                    </div>
                    <div>
                        <app-button
                            size="mini"
                            icon="icon-account-arrow-down"
                            title="Télécharger le contact"
                            @click="exportVCard(contact)"
                        />
                    </div>
                    <div v-if="!readOnly">
                        <app-button
                            size="mini"
                            @click="removeContact(contact)"
                            variant="danger"
                            aria-label="remove contact"
                            icon="icon-trash-can-outline"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import AppPhone from '@/components/appPhone/AppPhone';
import BundleContactCreation from '@/features/bundles/BundleContactCreation';
import AppMail from '@/components/appMail/AppMail';
import AppTips from '@/components/app-tips/AppTips';
import AppLabel from '@/components/appLabel/AppLabel';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppButton from '@/components/appButton/AppButton';
export default {
    components: {
        AppButton,
        AppLabel,
        AppTips,
        AppMail,
        BundleContactCreation,
        AppPhone,
    },
    props: {
        bundle: Object,
        company: Object,
        project: Object,
        readOnly: Boolean,
        showResponsible: Boolean,
        responsibleId: String,
        contacts: { type: Array, default: () => [] },
    },
    created() {},
    data() {
        return {
            createdContact: {},
        };
    },
    methods: {
        async exportVCard(contact) {
            await this.downloadAsFile(this.getContactVCard(this.project, this.bundle, contact, this.company));
        },
        async downloadAsFile(content) {
            const a = document.createElement('a');
            a.setAttribute('href', 'data:text/vcard;charset=utf-8,' + encodeURIComponent(content));
            a.download = 'contacts.vcf';
            a.dataset.downloadurl = ['text/vcard', a.download, a.href].join(':');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                URL.revokeObjectURL(a.href);
            }, 1500);
        },
        getContactVCard(project, bundle, contact, company) {
            return `BEGIN:VCARD
VERSION:4.0
N;CHARSET=UTF-8:${contact.lastName};${contact.firstName}
FN;CHARSET=UTF-8:${contact.firstName} ${contact.lastName}
ORG;CHARSET=UTF-8:${company ? company.name : ''}
TITLE;CHARSET=UTF-8:${contact.role ? contact.role : ''} ${bundle ? 'Lot ' + bundle.reference : ''} - ${
                bundle ? bundle.name : ''
            }
TEL;TYPE=work,voice;VALUE=uri:${contact.phone ? contact.phone : ''}
EMAIL:${contact.email ? contact.email : ''}
NOTE;CHARSET=UTF-8:Intervenant sur le project ${project.name}
REV:${new Date().toISOString().replace(/[-.:]/g, '')}
END:VCARD
`;
        },
        editContact(contact) {
            if (!this.readOnly) {
                this.createdContact = contact;
                this.$refs.contactPopup.open();
            }
        },
        async removeContact(contact) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                this.$emit('remove', contact);
            }
        },
        async saveContact(contact) {
            this.$emit('save', contact);
        },
        changeResponsible(contact) {
            if (!this.readOnly) {
                this.$emit('responsible', contact);
            }
        },
        toggleIsReportRecipient(contact) {
            contact.isReportRecipient = !contact.isReportRecipient;
            this.$emit('save', contact);
        },
    },
};
</script>
