<template>
    <li class="list-none">
        <div
            :class="{
                'hover:bg-gray-300': isSelected,
                'hover:bg-gray-200': !isSelected && !dragged,
                'bg-gray-300': isSelected || isDropZoneInOver,
                'pb-2 border-b': !(!item.isOpen || !item.children || item.children.length === 0),
                'opacity-25': dragged,
            }"
        >
            <drop
                v-if="index === 0"
                @dragover="onDragOverBefore"
                @dragenter="onDragOverBefore"
                @dragleave="resetDragStatus"
                @drop="onDropBefore"
                class="p-1"
                :class="{
                    'bg-gray-300 p-2': isDropZoneBeforeOver,
                }"
            >
                <span v-if="isDropZoneBeforeOver">&nbsp;</span>
            </drop>
            <component
                :is="item.allowDropIn ? 'drop' : 'div'"
                @dragover="onDragOverIn"
                @dragenter="onDragOverIn"
                @dragleave="resetDragStatus"
                @drop="onDropIn"
            >
                <div
                    class="flex items-center w-full px-2"
                    :class="{ 'pt-2': index > 0 }"
                    :style="{
                        'padding-left': (level - 1) * 2 + 'rem',
                    }"
                    @click="$emit('select', item)"
                    @dblclick="$emit('edit', item)"
                >
                    <button
                        :aria-label="item.isOpen ? 'collapse ' + item.name : 'expand ' + item.name"
                        @click="toggle"
                        v-if="isFolder"
                        class="mx-2"
                    >
                        <icon-minus-box-outline
                            class="cursor-pointer"
                            width="16px"
                            height="16px"
                            v-if="item.isOpen"
                        ></icon-minus-box-outline>
                        <icon-plus-box-outline
                            class="cursor-pointer"
                            width="16px"
                            height="16px"
                            v-else
                        ></icon-plus-box-outline>
                    </button>
                    <span v-else style="width: 16px" class="mx-2"></span>

                    <slot :item="item" name="item">
                        <icon :name="item.icon" v-if="item.icon" width="16px" height="16px" />
                        <span class="pl-2 whitespace-nowrap truncate" :title="item.name">{{ item.name }}</span>
                        <div class="flex-grow"></div>
                    </slot>
                    <drag
                        :draggable="!!item.isDraggable"
                        :transfer-data="item"
                        @dragstart="onDrag"
                        @dragend="dragged = false"
                        class="cursor-grab"
                        :class="{ 'opacity-0': !item.isDraggable, 'cursor-grab': item.isDraggable }"
                    >
                        <IconDrag class="text-gray-400" />
                    </drag>
                </div>
            </component>
            <drop
                v-if="!item.isOpen || !isFolder || item.children.length === 0"
                @dragover="onDragOverAfter"
                @dragenter="onDragOverAfter"
                @dragleave="resetDragStatus"
                @drop="onDropAfter"
                class="p-1 border-b"
                @click.native="$emit('select', item)"
                :class="{
                    'bg-gray-300 p-2': isDropZoneAfterOver,
                }"
            >
                <span v-if="isDropZoneAfterOver">&nbsp;</span>
            </drop>
        </div>
        <ul v-if="item.isOpen && !dragged && isFolder" class="p-0">
            <app-node
                ref="children"
                :selectedId="selectedId"
                :level="level + 1"
                class="item"
                v-for="(child, index) in item.children"
                :key="index"
                :index="index"
                :item="child"
                @select="$emit('select', $event)"
                @drop="$emit('drop', $event)"
                @edit="$emit('edit', $event)"
                @open="$emit('open', $event)"
                @close="$emit('close', $event)"
            >
                <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope" />
                </template>
            </app-node>
        </ul>
    </li>
</template>

<script>
import AppButton from './appButton/AppButton';
import { Drag, Drop } from 'vue-drag-drop';
import IconDrag from '@/icons/IconDrag';
export default {
    name: 'AppNode',
    components: { IconDrag, AppButton, Drag, Drop },
    props: {
        item: Object,
        selectedId: String,
        index: Number,
        level: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            dragged: false,
            isDropZoneAfterOver: false,
            isDropZoneBeforeOver: false,
            isDropZoneInOver: false,
        };
    },
    computed: {
        isSelected() {
            return this.selectedId === this.item.id;
        },
        isFolder() {
            return this.item.children;
        },
    },
    methods: {
        onDrag() {
            if (!this.dragged) {
                this.$emit('select', this.item);
                this.dragged = true;
            }
        },
        allowDropIn(draggedItem) {
            return this.item.allowDropIn && this.item.allowDropIn(draggedItem);
        },
        allowDropAmong(draggedItem) {
            return this.item.allowDropAmong && this.item.allowDropAmong(draggedItem);
        },
        onDragOverBefore(draggedItem) {
            if (this.allowDropAmong(draggedItem)) {
                this.isDropZoneBeforeOver = true;
            }
        },
        onDragOverAfter(draggedItem) {
            if (this.allowDropAmong(draggedItem)) {
                this.isDropZoneAfterOver = true;
            }
        },
        onDragOverIn(draggedItem, nativeEvent) {
            // this method could be called from Component which name != Drop
            if (nativeEvent && draggedItem) {
                if (this.allowDropIn(draggedItem)) {
                    this.isDropZoneInOver = true;
                }
            }
        },
        resetDragStatus() {
            this.isDropZoneInOver = false;
            this.isDropZoneBeforeOver = false;
            this.isDropZoneAfterOver = false;
        },
        onDropBefore(item) {
            this.resetDragStatus();
            let index = this.index;
            if (item.parentDirectoryId === this.item.parentDirectoryId && item.index <= this.index) {
                index--;
            }
            this.$emit('drop', {
                content: item,
                parentDirectoryId: this.item.parentDirectoryId,
                index,
            });
        },
        onDropIn(item) {
            if (this.allowDropIn(item)) {
                this.resetDragStatus();
                this.$emit('drop', {
                    content: item,
                    parentDirectoryId: this.item.id,
                    index: this.item.children.length,
                });
            }
        },
        onDropAfter(item) {
            this.resetDragStatus();
            let index = this.index + 1;
            if (item.parentDirectoryId === this.item.parentDirectoryId && item.index <= this.index) {
                index--;
            }
            this.$emit('drop', {
                content: item,
                parentDirectoryId: this.item.parentDirectoryId,
                index,
            });
        },
        toggle() {
            this.item.isOpen = !this.item.isOpen;
            if (this.item.isOpen) {
                this.$emit('open', this.item);
            } else {
                this.$emit('close', this.item);
            }
        },
    },
};
</script>
