export const getAPIHeaders = (extraHeaders) => {
    let headers = new Headers();
    const token = localStorage.getItem('token');
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }
    headers.append('Accept', `application/json`);
    if (extraHeaders) {
        for (const [key, value] of Object.entries(extraHeaders)) {
            headers.append(key, value);
        }
    }
    return headers;
};
