<template>
    <div class="flex gap-2">
        <app-button
            size="mini"
            icon="icon-check"
            class="bg-green-500 text-white"
            :label="$t('project.follow.observation.resolve')"
            @click="onResolve"
            v-if="!observation.obsoleteAt && !observation.resolvedAt && (isAdmin || isRecipient)"
        ></app-button>
        <app-button
            size="mini"
            icon="icon-undo"
            class="bg-orange-400 text-white"
            :label="$t('project.follow.observation.reOpen')"
            @click="onOpen"
            v-if="
                !observation.obsoleteAt &&
                !observation.validatedAt &&
                observation.resolvedAt &&
                (isRecipient || isAdmin)
            "
        ></app-button>
        <app-button
            size="mini"
            icon="icon-check"
            class="bg-green-500 text-white"
            :label="$t('project.follow.observation.validate')"
            @click="onValidate"
            v-if="isValidator && !observation.validatedAt && !observation.obsoleteAt && observation.resolvedAt"
        ></app-button>
        <app-button
            size="mini"
            icon="icon-undo"
            class="bg-orange-400 text-white"
            icon-class="mr-2"
            :label="$t('project.follow.observation.invalidate')"
            @click="onInvalidate"
            v-if="!observation.obsoleteAt && observation.validatedAt"
            :disabled="!isValidator"
        ></app-button>
        <app-button
            size="mini"
            :label="$t('project.follow.observation.notObsolete')"
            @click="onNotObsolete"
            icon="icon-undo"
            class="bg-orange-400 text-white"
            v-if="observation.obsoleteAt && (isReporter || isAdmin)"
        ></app-button>
        <span class="flex-grow"></span>
        <app-button
            size="mini"
            icon="icon-timer-alert-outline"
            :label="$t('project.follow.observation.obsolete')"
            @click="obsolete"
            v-if="!observation.obsoleteAt && (isReporter || isAdmin)"
        ></app-button>

        <app-button
            size="mini"
            icon="icon-trash-can-outline"
            variant="danger"
            @click="$emit('remove')"
            v-if="isAdmin || isReporter"
        />
    </div>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppButton,
    },
    props: {
        observation: Object,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.isValidator = project.me.allowedFeatures.includes('project_observations_MOEValidation');
            this.me = project.me;
        });
    },
    computed: {
        isReporter() {
            return this.me && !!this.me.bundleIds.includes(this.observation.reportedBy);
        },
        isRecipient() {
            if (this.me && this.observation.recipientIds && this.observation.recipientIds.length > 0) {
                return !!this.me.bundleIds.some((bundleId) => this.observation.recipientIds.includes(bundleId));
            }
        },
    },
    methods: {
        onResolve() {
            this.resolve();
            this.save();
        },
        resolve() {
            this.observation.resolvedAt = new Date();
            this.observation.resolvedOnPhase = this.observation.phase;
            this.observation.resolvedBy = this.me.bundleIds[0];
        },
        onValidate() {
            this.validate();
            this.save();
        },
        validate() {
            this.observation.validatedAt = new Date();
            this.observation.validatedOnPhase = this.observation.phase;
            this.observation.validatedBy = this.me.bundleIds[0];
        },
        onResolveAndValidate() {
            this.resolve();
            this.validate();
            this.save();
        },
        onInvalidate() {
            this.invalidate();
            this.save();
        },
        invalidate() {
            this.observation.validatedAt = null;
            this.observation.validatedBy = null;
            this.observation.validatedOnPhase = null;
        },
        obsolete() {
            this.observation.obsoleteAt = new Date();
            this.observation.obsoleteBy = this.me.bundleIds[0];
            this.save();
        },
        onNotObsolete() {
            this.notObsolete();
            this.save();
        },
        notObsolete() {
            this.observation.obsoleteAt = null;
            this.observation.obsoleteBy = null;
            this.observation.obsoleteComment = null;
        },
        onOpen() {
            this.open();
            this.invalidate();
            this.notObsolete();
            this.save();
        },
        open() {
            this.observation.resolvedAt = null;
            this.observation.resolvedOnPhase = null;
            this.observation.resolvedBy = null;
        },
        save() {
            this.$emit('input', {
                id: this.observation.id,
                reportedBy: this.observation.reporter?.id || this.observation.reportedBy,
                reportedAt: this.observation.reportedAt,
                reportedOnPhase: this.observation.reportedOnPhase,
                obsoleteBy: this.observation.obsoleter?.id || this.observation.obsoleteBy,
                obsoleteAt: this.observation.obsoleteAt,
                obsoleteComment: this.observation.obsoleteComment,
                resolvedAt: this.observation.resolvedAt,
                resolvedOnPhase: this.observation.resolvedOnPhase,
                resolvedBy: this.observation.resolver?.id || this.observation.resolvedBy,
                validatedAt: this.observation.validatedAt,
                validatedBy: this.observation.validator?.id || this.observation.validatedBy,
                validatedOnPhase: this.observation.validatedOnPhase,
            });
        },
    },
    data() {
        return {
            isAdmin: false,
            isValidator: false,
            myBundleIds: [],
            me: null,
        };
    },
};
</script>
