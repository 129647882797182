import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const requestAttachments = {
    schema: {
        title: 'request Attachments  schema',
        version: 0,
        description: 'describes an request Attachments',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            name: { type: ['string', 'null'] },
            pageCount: { type: ['number', 'null'] },
            url: { type: ['string', 'null'] },
            dataUrl: { type: ['string', 'null'] },
            shapes: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                    additionalProperties: true,
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            requestId: {
                type: 'string',
                maxLength: 40,
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
        indexes: ['requestId'],
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId, {
                cacheURLsFn: (documents) =>
                    documents.filter((document) => document.url).map((document) => document.url),
            });
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
