export const syncStates = {
    schema: {
        title: 'sync state schema',
        version: 0,
        description: 'describes a project sync state',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            lastSuccess: {
                type: ['string', 'null'],
            },
            lastTry: {
                type: ['string', 'null'],
            },
            sessionId: {
                type: ['number', 'null'],
            },
            collectionName: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            state: {
                type: ['string', 'null'],
            },
        },
    },
    migrationStrategies: {},
};
