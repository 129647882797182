<template>
    <div class="flex flex-col gap-2">
        <div class="flex flex-col text-xs">
            <div>{{ $t('project.follow.requests.reportedOn') }} {{ request.createdAt | humanizeDate }}</div>
            <div v-if="request.rejectedMOAAt" class="flex gap-1">
                {{ $t('project.follow.requests.rejectedMOAOn') }}
                <app-date-link v-model="request.rejectedMOAAt" @input="save()" :required="true" />
            </div>
            <div v-else-if="request.validatedMOAAt" class="flex gap-1">
                {{ $t('project.follow.requests.validatedMOAOn') }}
                <app-date-link v-model="request.validatedMOAAt" @input="save()" :required="true" />
            </div>
            <div v-if="request.rejectedMOEAt" class="flex gap-1">
                {{ $t('project.follow.requests.rejectedMOEOn') }}
                <app-date-link v-model="request.rejectedMOEAt" @input="save()" :required="true" />
            </div>
            <div v-else-if="request.validatedMOEAt" class="flex gap-1">
                {{ $t('project.follow.requests.validatedMOEOn') }}
                <app-date-link v-model="request.validatedMOEAt" @input="save()" :required="true" />
            </div>
            <div v-if="request.obsoleteAt" class="flex gap-1">
                {{ $t('project.follow.requests.obsoleteOn') }}
                <app-date-link v-model="request.obsoleteAt" @input="save()" :required="true" />
            </div>
            <div v-if="request.resolvedAt" class="flex gap-1">
                {{ $t('project.follow.requests.resolvedAt') }}
                <app-date-link v-model="request.resolvedAt" @input="save()" :required="true" />
            </div>
        </div>
        <div class="flex my-2 gap-2">
            <app-button
                size="mini"
                icon="icon-check"
                icon-class="text-green-600 mr-2"
                :label="$t('project.follow.requests.validate')"
                @click="onValidateMOA"
                v-if="isMOA && !request.validatedMOAAt && !request.obsoleteAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-check"
                icon-class="text-green-600 mr-2"
                :label="$t('project.follow.requests.validate')"
                @click="onValidateMOE"
                v-if="isMOE && request.validatedMOAAt && !request.validatedMOEAt && !request.obsoleteAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-close"
                icon-class="text-red-700 mr-2"
                :label="$t('project.follow.requests.reject')"
                @click="onRejectMOA"
                v-if="isMOA && !request.rejectedMOAAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-close"
                icon-class="text-red-700 mr-2"
                :label="$t('project.follow.requests.reject')"
                @click="onRejectMOE"
                v-if="isMOE && !request.rejectedMOEAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                :label="$t('project.follow.requests.invalidate')"
                @click="onInvalidateMOE"
                v-if="isMOE && request.validatedMOEAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                :label="$t('project.follow.requests.invalidate')"
                @click="onInvalidateMOA"
                v-if="isMOA && request.validatedMOAAt && !request.validatedMOEAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                :label="$t('project.follow.requests.undoRejection')"
                @click="onUndoRejectionMOE"
                v-if="isMOE && request.rejectedMOEAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                :label="$t('project.follow.requests.undoRejection')"
                @click="onUndoRejectionMOA"
                v-if="isMOA && request.rejectedMOAAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-timer-alert-outline"
                :label="$t('project.follow.requests.obsolete')"
                @click="onObsolete"
                v-if="!request.obsoleteAt"
            ></app-button>
            <app-button
                size="mini"
                :label="$t('project.follow.requests.notObsolete')"
                @click="onNotObsolete"
                icon="icon-undo"
                v-if="request.obsoleteAt"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-thumb-up"
                icon-class="text-green-600 mr-2"
                :label="$t('project.follow.requests.resolve')"
                @click="onResolve"
                v-if="request.validatedMOAAt && request.validatedMOEAt && !request.resolvedAt && isMOA"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-undo"
                icon-class="text-green-600 mr-2"
                :label="$t('project.follow.requests.reopen')"
                @click="onOpen"
                v-if="request.resolvedAt && isMOA"
            ></app-button>
            <span class="flex-grow"></span>
            <app-button
                size="mini"
                icon="icon-trash-can-outline"
                variant="danger"
                @click="$emit('remove')"
                v-if="
                    !request.validatedMOAAt &&
                    !request.validatedMOEAt &&
                    !request.rejectedMOAAt &&
                    !request.rejectedMOEAt
                "
                :label="$t('project.follow.requests.removeShort')"
            />
        </div>
    </div>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';
import AppDateLink from '@/components/appDateLink/AppDateLink';

export default {
    components: {
        AppDateLink,
        AppButton,
    },
    props: {
        request: Object,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isMOA = project.me.allowedFeatures.includes('project_requestsMOAValidation');
            this.isMOE = project.me.allowedFeatures.includes('project_requestsMOEValidation');
        });
    },
    methods: {
        onResolve() {
            this.request.resolvedAt = new Date();
            this.save();
        },
        onValidateMOA() {
            this.request.validatedMOAAt = new Date();
            this.request.rejectedMOAAt = null;
            this.save();
        },
        onValidateMOE() {
            this.request.validatedMOEAt = new Date();
            this.request.rejectedMOEAt = null;
            this.save();
        },
        onRejectMOA() {
            this.request.rejectedMOAAt = new Date();
            this.request.validatedMOAAt = null;
            this.save();
        },
        onRejectMOE() {
            this.request.rejectedMOEAt = new Date();
            this.request.validatedMOEAt = null;
            this.save();
        },
        onUndoRejectionMOA() {
            this.request.rejectedMOAAt = null;
            this.save();
        },
        onUndoRejectionMOE() {
            this.request.rejectedMOEAt = null;
            this.save();
        },
        onInvalidateMOA() {
            this.request.validatedMOAAt = null;
            this.save();
        },
        onInvalidateMOE() {
            this.request.validatedMOEAt = null;
            this.save();
        },
        onObsolete() {
            this.request.obsoleteAt = new Date();
            this.save();
        },
        onNotObsolete() {
            this.request.obsoleteAt = null;
            this.save();
        },
        onOpen() {
            this.request.resolvedAt = null;
            this.save();
        },
        save() {
            this.$emit('input', {
                id: this.request.id,
                obsoleteAt: this.request.obsoleteAt,
                resolvedAt: this.request.resolvedAt,
                validatedMOEAt: this.request.validatedMOEAt,
                validatedMOAAt: this.request.validatedMOAAt,
                rejectedMOEAt: this.request.rejectedMOEAt,
                rejectedMOAAt: this.request.rejectedMOAAt,
            });
        },
    },
    data() {
        return {
            hasBeenDeleted: false,
            isMOA: false,
            isMOE: false,
        };
    },
};
</script>
