<template>
    <div class="flex flex-col items-center gap-4">
        <app-upload-button
            class="col-span-2 text-sm"
            icon="icon-paperclip"
            :label="$t('project.editions.meetingReports.changeCoverPhoto')"
            @select="onChangeCoverPhoto"
            :name="$t('project.editions.meetingReports.changeCoverPhoto')"
            :disabled="!meeting.showCoverPageWithPhoto"
        />

        <img class="max-w-full md:max-w-2/3" :src="meeting.coverPhotoUrl" v-if="meeting.showCoverPageWithPhoto" />
    </div>
</template>

<script>
import AppUploadButton from '../../components/app-uploadButton/AppUploadButton';
import { getMeeting, updateMeeting } from './meetings.service';
export default {
    components: {
        AppUploadButton,
    },
    created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
            }),
        ];
    },
    methods: {
        onChangeCoverPhoto(fileResult) {
            updateMeeting(this.$route.params.projectId, { coverPhotoUrl: fileResult.url, id: this.meeting.id });
        },
    },
    data() {
        return {
            meeting: {},
            subscriptions: [],
        };
    },
};
</script>
