<template>
    <div class="flex flex-col items-center">
        <span class="font-bold">{{ format(new Date(month.getUTCFullYear(), month.getUTCMonth(), 1), 'MMMM') }}</span>
        <table>
            <tr>
                <td></td>
                <td v-for="weekDay in weekDays" class="px-1 bg-secondary text-white">
                    <div class="text-center">{{ format(weekDay, 'ccccc') }}</div>
                </td>
            </tr>
            <tr v-for="week in weeksInMonth">
                <template v-for="col in 8">
                    <td v-if="col === 1" class="p-1 text-left">s{{ firstWeekOfMonth + week - 1 }}</td>
                    <td
                        v-else-if="
                            col + (week - 1) * 7 - 1 >= firstDayOfMonth &&
                            col + (week - 1) * 7 - firstDayOfMonth - 1 < daysInMonth
                        "
                        :class="{ ...getDayClass(col + (week - 1) * 7 - firstDayOfMonth) }"
                        class="px-2 text-center cursor-pointer hover:bg-gray-200"
                        @click="onDayClick(col + (week - 1) * 7 - firstDayOfMonth)"
                    >
                        {{ col + (week - 1) * 7 - firstDayOfMonth }}
                    </td>
                    <td v-else class="px-1"></td>
                </template>
            </tr>
        </table>
    </div>
</template>
<script>
import getDaysInMonth from 'date-fns/getDaysInMonth';
import startOfMonth from 'date-fns/startOfMonth';
import getWeeksInMonth from 'date-fns/getWeeksInMonth';
import getISODay from 'date-fns/getISODay';
import format from 'date-fns/format';
import getWeek from 'date-fns/getWeek';
import calendar from './calendar';
export default {
    props: {
        month: {
            type: Date,
            default() {
                return new Date();
            },
        },
        dayClass: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        format,
        getDayClass(dom) {
            return calendar.getDayClass(this.dayClass, this.month, dom);
        },
        onDayClick(cell) {
            const domDate = new Date(this.month);
            domDate.setDate(cell);
            this.$emit('dayClick', domDate);
        },
    },
    data() {
        return {
            weekDays: calendar.getWeekDays(),
        };
    },
    computed: {
        weeksInMonth() {
            return getWeeksInMonth(this.month, { weekStartsOn: 1 });
        },
        firstDayOfMonth() {
            return getISODay(startOfMonth(this.month));
        },
        firstWeekOfMonth() {
            return getWeek(startOfMonth(this.month), { weekStartsOn: 1 });
        },
        daysInMonth() {
            return getDaysInMonth(this.month);
        },
    },
};
</script>
<style scoped>
.weather-issue {
    border-bottom: red solid;
}
</style>
