<template>
    <div style="display: none"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { findRealParent } from 'vue2-leaflet';
export default {
    props: {
        paths: {
            type: Array,
            default: () => [],
        },
        pixelSize: { type: String | Number, default: 10 },
        type: { type: String },
        weight: { type: Number, default: 3 },
        color: { type: String },
    },
    data() {
        return {
            polyline: null,
            polylineHandle: null,
            decorator: null,
            map: null,
        };
    },
    watch: {
        paths() {
            this.polyline.setLatLngs(this.paths);
            this.polylineHandle.setLatLngs(this.paths);
            this.decorator.setPaths(this.paths);

            if (this.polylineHandle.editEnabled()) {
                this.polylineHandle.disableEdit();
                this.$nextTick(() => this.polylineHandle.enableEdit());
            }
        },
        type() {
            this.decorator.setPatterns(this.getPatterns());
        },
        color(color) {
            this.polyline.setStyle({ color });
            this.decorator.setPatterns(this.getPatterns());
        },
    },
    mounted() {
        this.polyline = L.polyline(this.paths, { stroke: true, color: this.color, weight: this.weight });
        this.polylineHandle = L.polyline(this.paths, { stroke: true, weight: 15, opacity: 0 });
        this.decorator = L.polylineDecorator(this.polyline, { patterns: this.getPatterns() });

        L.DomEvent.on(this.decorator, this.$listeners);
        L.DomEvent.on(this.polylineHandle, this.$listeners);

        this.polylineHandle.on('editable:drag editable:dragend editable:editing', () => {
            this.decorator.setPaths(this.polylineHandle);
            this.polyline.setLatLngs(this.polylineHandle.getLatLngs());
        });
        this.polylineHandle.on('editable:dragend editable:vertex:dragend', () => {
            const latLngs = this.polylineHandle.getLatLngs();
            this.$emit('updateX1Y1X2Y2', {
                x1: latLngs[0].lng,
                x2: latLngs[1].lng,
                y1: latLngs[0].lat,
                y2: latLngs[1].lat,
            });
        });

        this.map = findRealParent(this.$parent).mapObject;
        this.map.addLayer(this.polyline);
        this.map.addLayer(this.decorator);
        this.map.addLayer(this.polylineHandle);
    },
    beforeDestroy() {
        this.map.removeLayer(this.polyline);
        this.map.removeLayer(this.decorator);
        this.map.removeLayer(this.polylineHandle);
    },
    methods: {
        getPatterns() {
            return this.type === 'arrow'
                ? [
                      {
                          offset: 0,
                          repeat: 0,
                          symbol: L.Symbol.arrowHead({
                              pixelSize: this.pixelSize,
                              polygon: true,
                              pathOptions: {
                                  stroke: true,
                                  color: this.color,
                                  fill: true,
                                  fillColor: this.color,
                                  fillOpacity: 1,
                              },
                              headAngle: -290,
                          }),
                      },
                  ]
                : [
                      {
                          offset: '100%',
                          repeat: 0,
                          symbol: L.Symbol.arrowHead({
                              pixelSize: this.pixelSize,
                              polygon: true,
                              pathOptions: {
                                  stroke: true,
                                  color: this.color,
                                  fill: true,
                                  fillColor: this.color,
                                  fillOpacity: 1,
                              },
                          }),
                      },
                      {
                          offset: 0,
                          repeat: 0,
                          symbol: L.Symbol.arrowHead({
                              pixelSize: this.pixelSize,
                              polygon: true,
                              pathOptions: {
                                  stroke: true,
                                  color: this.color,
                                  fill: true,
                                  fillColor: this.color,
                                  fillOpacity: 1,
                              },
                              headAngle: -290,
                          }),
                      },
                  ];
        },
        editEnabled() {
            return this.polylineHandle.editEnabled();
        },
        disableEdit() {
            return this.polylineHandle.disableEdit();
        },
        enableEdit() {
            return this.polylineHandle.enableEdit();
        },
        getLatLngs() {
            return this.polylineHandle.getLatLngs();
        },
        select() {
            this.$emit('click', { target: this.polylineHandle });
        },
    },
};
</script>
