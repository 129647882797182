<template>
    <div class="w-full mb-20 flex flex-col gap-4">
        <template v-if="$route.name !== 'myDashboard'">
            <div class="flex flex-col items-start justify-start w-full text-xs mt-4">
                <app-select
                    :label="$t('dashboard.selectedBundles')"
                    class="mx-4"
                    @input="onSelectedBundlesOptionChange($event)"
                    v-model="selectedBundlesOption"
                >
                    <option value="selectAllMOA" v-if="project_dashboard">{{ $t('dashboard.selectAllMOA') }}</option>
                    <option value="selectAllMOE" v-if="project_dashboard">{{ $t('dashboard.selectAllMOE') }}</option>
                    <option value="selectAllCompany" v-if="project_dashboard">
                        {{ $t('dashboard.selectAllCompany') }}
                    </option>
                    <option value="selectMyBundles">
                        {{ $t('dashboard.selectMyBundles') }}
                    </option>
                    <option v-for="bundle in allowedBundles" :value="bundle.id">
                        {{ (bundle.reference ? '#' + bundle.reference + ' ' : '') + bundle.name }}
                    </option>
                </app-select>
            </div>
        </template>
        <template v-if="$route.name === 'myDashboard'">
            <div
                class="p-4 flex flex-col"
                v-if="
                    (((!project.startDate || !project.endDate) && allowGenerals && project.phase === 'EXE') ||
                        bundles.length === 1 ||
                        !isNewlyCreatedLocation ||
                        (requireService && project.phase === 'EXE') ||
                        (requireAgendaExceptions && project.phase === 'EXE')) &&
                    (isBundleAdmin || isLocationAdmin || isPlanningAdmin)
                "
            >
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.projectConfig') }}</h4>
                <ol class="w-full text-sm list-decimal">
                    <li
                        class="my-2"
                        v-if="(!project.startDate || !project.endDate) && allowGenerals && project.phase === 'EXE'"
                    >
                        <router-link :to="{ name: 'project', params: { ...$route.params } }" class="hover:underline">
                            {{ $t('dashboard.items.projectDates') }}
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.projectDatesTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="bundles.length === 1 && isBundleAdmin">
                        <router-link :to="{ name: 'bundles', params: { ...$route.params } }" class="hover:underline">
                            <span>{{ $t('dashboard.items.addBundle') }}</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.addBundleTips') }}</app-tips>
                        <app-tips>{{ $t('dashboard.items.addBundleTips2') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="!isNewlyCreatedLocation && isLocationAdmin">
                        <router-link :to="{ name: 'folders', params: { ...$route.params } }" class="hover:underline">
                            <span>{{ $t('dashboard.items.addLocations') }}</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.addLocationsTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="requireService && project.phase === 'EXE' && isPlanningAdmin">
                        <router-link :to="{ name: 'services', params: { ...$route.params } }" class="hover:underline">
                            <span>{{ $t('dashboard.items.addServices') }}</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.addServicesTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="requireAgendaExceptions && project.phase === 'EXE' && isPlanningAdmin">
                        <router-link :to="{ name: 'agenda', params: { ...$route.params } }" class="hover:underline">
                            <span>{{ $t('dashboard.items.addAgendaException') }}</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.addAgendaExceptionTips') }}</app-tips>
                    </li>
                </ol>
            </div>

            <template v-if="project.phase !== 'CON'">
                <div class="p-4 flex flex-col" v-if="unassignedBundles.length && isBundleAdmin">
                    <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.bundles') }}</h4>
                    <ul class="w-full text-sm list-disc">
                        <li class="my-2" v-if="unassignedBundles.length">
                            <router-link
                                v-if="unassignedBundles.length > 1"
                                :to="{ name: 'bundles', params: { ...$route.params } }"
                                class="hover:underline"
                                :title="unassignedBundles.map((bundle) => bundle.label).join('\n')"
                            >
                                <span class="mr-1">{{ unassignedBundles.length }}</span>
                                <span>{{ $t('dashboard.items.unassignedBundles') }}</span>
                            </router-link>
                            <router-link
                                v-if="unassignedBundles.length === 1"
                                :to="{
                                    name: 'bundle',
                                    params: { ...$route.params, bundleId: unassignedBundles[0].id },
                                }"
                                class="hover:underline"
                            >
                                <span class="mr-1">{{ unassignedBundles.length }}</span>
                                <span>{{ $t('dashboard.items.unassignedBundle') }}</span>
                            </router-link>
                            <app-tips>{{ $t('dashboard.items.unassignedTips') }}</app-tips>
                        </li>
                    </ul>
                </div>
                <div class="p-4 flex flex-col" v-if="unlocatedServices.length && isPlanningAdmin">
                    <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.services') }}</h4>
                    <ul class="w-full text-sm list-disc">
                        <li class="my-2" v-if="unlocatedServices.length">
                            <router-link
                                v-if="unlocatedServices.length > 1"
                                :to="{ name: 'services', params: { ...$route.params } }"
                                class="hover:underline"
                            >
                                <span class="mr-1">{{ unlocatedServices.length }}</span>
                                <span>{{ $t('dashboard.items.unlocatedServices') }}</span>
                            </router-link>
                            <router-link
                                v-if="unlocatedServices.length === 1"
                                :to="{
                                    name: 'services',
                                    params: { ...$route.params, serviceId: unlocatedServices[0].id },
                                }"
                                class="hover:underline"
                            >
                                <span class="mr-1">{{ unlocatedServices.length }}</span>
                                <span>
                                    {{ $t('dashboard.items.unlocatedService') }} ({{ unlocatedServices[0].name }})
                                </span>
                            </router-link>
                            <app-tips>{{ $t('dashboard.items.unlocatedServicesTips') }}</app-tips>
                        </li>
                    </ul>
                </div>
            </template>
            <div
                class="p-4 flex flex-col"
                v-if="untypedSupports.length || noSupportZones.length || supportsToEmit.length"
            >
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.supports') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="untypedSupports.length">
                        <router-link
                            v-if="untypedSupports.length > 1"
                            :to="{ name: 'supports', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ untypedSupports.length }}</span>
                            <span>{{ $t('dashboard.items.untypedSupports') }}</span>
                        </router-link>
                        <router-link
                            v-if="untypedSupports.length === 1"
                            :to="{ name: 'support', params: { ...$route.params, supportId: untypedSupports[0].id } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ untypedSupports.length }}</span>
                            <span>{{ $t('dashboard.items.untypedSupport') }} ({{ untypedSupports[0].name }})</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.untypedSupportsTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="noSupportZones.length">
                        <router-link
                            v-if="noSupportZones.length > 1"
                            :to="{ name: 'supports', params: { ...$route.params } }"
                            class="hover:underline"
                            :title="noSupportZones.map((zone) => zone.fullName).join('\n')"
                        >
                            <span class="mr-1">{{ noSupportZones.length }}</span>
                            <span>{{ $t('dashboard.items.noSupportZones') }}</span>
                        </router-link>
                        <router-link
                            v-if="noSupportZones.length === 1"
                            :to="{ name: 'support', params: { ...$route.params, supportId: noSupportZones[0].id } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ noSupportZones.length }}</span>
                            <span>{{ $t('dashboard.items.noSupportZone') }} ({{ noSupportZones[0].name }})</span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.noSupportZonesTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="supportsToEmit.length">
                        <router-link
                            v-if="supportsToEmit.length > 1"
                            :to="{ name: 'supports', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ supportsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.supportsToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="supportsToEmit.length === 1"
                            :to="{ name: 'support', params: { ...$route.params, supportId: supportsToEmit[0].id } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ supportsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.supportToEmit') }} ({{ supportsToEmit[0].name }})</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div
                class="p-4 flex flex-col"
                v-if="
                    project_EXE &&
                    (preparationsWithNewVersionRequired.length ||
                        preparationsToEmit.length ||
                        preparationsToVisa.length)
                "
            >
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.preparations') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="preparationsWithNewVersionRequired.length">
                        <router-link
                            v-if="preparationsWithNewVersionRequired.length > 1"
                            :to="{ name: 'followPreparations', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsWithNewVersionRequired.length }}</span>
                            <span>{{ $t('dashboard.items.preparationsWithNewVersionRequired') }}</span>
                        </router-link>
                        <router-link
                            v-if="preparationsWithNewVersionRequired.length === 1"
                            :to="{
                                name: 'followPreparation',
                                params: { ...$route.params, preparationId: preparationsWithNewVersionRequired[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsWithNewVersionRequired.length }}</span>
                            <span>
                                {{ $t('dashboard.items.preparationsWithNewVersionRequired') }} ({{
                                    preparationsWithNewVersionRequired[0].name
                                }})
                            </span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.preparationsWithNewVersionRequiredTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="preparationsToEmit.length">
                        <router-link
                            v-if="preparationsToEmit.length > 1"
                            :to="{ name: 'followPreparations', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.preparationsToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="preparationsToEmit.length === 1"
                            :to="{
                                name: 'followPreparation',
                                params: { ...$route.params, preparationId: preparationsToEmit[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsToEmit.length }}</span>
                            <span>
                                {{ $t('dashboard.items.preparationToEmit') }} ({{ preparationsToEmit[0].name }})
                            </span>
                        </router-link>
                    </li>
                    <li class="my-2" v-if="preparationsToVisa.length">
                        <router-link
                            v-if="preparationsToVisa.length > 1"
                            :to="{ name: 'followPreparations', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsToVisa.length }}</span>
                            <span>{{ $t('dashboard.items.preparationsToVisa') }}</span>
                        </router-link>
                        <router-link
                            v-if="preparationsToVisa.length === 1"
                            :to="{
                                name: 'preparationVisa',
                                params: {
                                    ...$route.params,
                                    preparationVisaId: preparationsToVisa[0].id,
                                    preparationId: preparationsToVisa[0].preparation.id,
                                },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ preparationsToVisa.length }}</span>
                            <span>
                                {{ $t('dashboard.items.preparationToVisa') }} ({{
                                    preparationsToVisa[0].preparation.name
                                }})
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div
                class="p-4 flex flex-col"
                v-if="
                    (project_CON && conceptionsWithNewVersionRequired.length) ||
                    conceptionsToEmit.length ||
                    conceptionsToVisa.length
                "
            >
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.conceptions') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="conceptionsWithNewVersionRequired.length">
                        <router-link
                            v-if="conceptionsWithNewVersionRequired.length > 1"
                            :to="{ name: 'conception', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsWithNewVersionRequired.length }}</span>
                            <span>{{ $t('dashboard.items.conceptionsWithNewVersionRequired') }}</span>
                        </router-link>
                        <router-link
                            v-if="conceptionsWithNewVersionRequired.length === 1"
                            :to="{
                                name: 'conception',
                                params: {
                                    ...$route.params,
                                    preparationId: conceptionsWithNewVersionRequired[0].id,
                                },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsWithNewVersionRequired.length }}</span>
                            <span>
                                {{ $t('dashboard.items.conceptionWithNewVersionRequired') }} ({{
                                    conceptionsWithNewVersionRequired[0].name
                                }})
                            </span>
                        </router-link>
                        <app-tips>{{ $t('dashboard.items.conceptionsWithNewVersionRequiredTips') }}</app-tips>
                    </li>
                    <li class="my-2" v-if="conceptionsToEmit.length">
                        <router-link
                            v-if="conceptionsToEmit.length > 1"
                            :to="{ name: 'conception', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.conceptionsToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="conceptionsToEmit.length === 1"
                            :to="{
                                name: 'conception',
                                params: { ...$route.params, preparationId: conceptionsToEmit[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.conceptionsToEmit') }} ({{ conceptionsToEmit[0].name }})</span>
                        </router-link>
                    </li>
                    <li class="my-2" v-if="conceptionsToVisa.length">
                        <router-link
                            v-if="conceptionsToVisa.length > 1"
                            :to="{ name: 'conception', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsToVisa.length }}</span>
                            <span>{{ $t('dashboard.items.conceptionsToVisa') }}</span>
                        </router-link>
                        <router-link
                            v-if="conceptionsToVisa.length === 1"
                            :to="{
                                name: 'conceptionVisa',
                                params: {
                                    ...$route.params,
                                    preparationVisaId: conceptionsToVisa[0].id,
                                    preparationId: conceptionsToVisa[0].preparation.id,
                                },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ conceptionsToVisa.length }}</span>
                            <span>
                                {{ $t('dashboard.items.conceptionsToVisa') }} ({{
                                    conceptionsToVisa[0].preparation.name
                                }})
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="p-4 flex flex-col" v-if="certificatesToEmit.length">
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.certificates') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="certificatesToEmit.length">
                        <router-link
                            v-if="certificatesToEmit.length > 1"
                            :to="{ name: 'followCertificates', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ certificatesToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.certificatesToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="certificatesToEmit.length === 1"
                            :to="{
                                name: 'followCertificate',
                                params: { ...$route.params, certificateId: certificatesToEmit[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ certificatesToEmit.length }}</span>
                            <span>
                                {{ $t('dashboard.items.certificateToEmit') }} ({{ certificatesToEmit[0].name }})
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="p-4 flex flex-col" v-if="receptionsToEmit.length && project_EXE">
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.receptions') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="receptionsToEmit.length">
                        <router-link
                            v-if="receptionsToEmit.length > 1"
                            :to="{ name: 'followReceptions', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ receptionsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.receptionsToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="receptionsToEmit.length === 1"
                            :to="{
                                name: 'followReception',
                                params: { ...$route.params, receptionId: receptionsToEmit[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ receptionsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.receptionToEmit') }} ({{ receptionsToEmit[0].name }})</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="p-4 flex flex-col" v-if="autoControlsToEmit.length && project_EXE">
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.autoControls') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="autoControlsToEmit.length">
                        <router-link
                            v-if="autoControlsToEmit.length > 1"
                            :to="{ name: 'followAutoControls', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ autoControlsToEmit.length }}</span>
                            <span>{{ $t('dashboard.items.autoControlsToEmit') }}</span>
                        </router-link>
                        <router-link
                            v-if="autoControlsToEmit.length === 1"
                            :to="{
                                name: 'followAutoControl',
                                params: { ...$route.params, receptionId: autoControlsToEmit[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ autoControlsToEmit.length }}</span>
                            <span>
                                {{ $t('dashboard.items.autoControlToEmit') }} ({{ autoControlsToEmit[0].name }})
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="p-4 flex flex-col" v-if="filteredTasks.length && project_EXE">
                <h4 class="text-lg text-main font-bold">{{ $t('dashboard.chapters.tasks') }}</h4>
                <ul class="w-full text-sm list-disc">
                    <li class="my-2" v-if="filteredTasks.length">
                        <router-link
                            v-if="filteredTasks.length > 1"
                            :to="{ name: 'followTasks', params: { ...$route.params } }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ filteredTasks.length }}</span>
                            <span>{{ $t('dashboard.items.tasks') }}</span>
                        </router-link>
                        <router-link
                            v-if="filteredTasks.length === 1"
                            :to="{
                                name: 'followTasks',
                                params: { ...$route.params, taskId: filteredTasks[0].id },
                            }"
                            class="hover:underline"
                        >
                            <span class="mr-1">{{ filteredTasks.length }}</span>
                            <span>{{ $t('dashboard.items.task') }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </template>
        <div class="flex flex-col gap-4 items-center">
            <dashboard-delays-comparison-bars :bundles="selectedBundles"></dashboard-delays-comparison-bars>
            <follow-observation-stats
                class="mt-2"
                :bundles="selectedBundles"
                :items="observations"
                v-if="
                    (selectedBundlesOption === 'selectAllMOA' ||
                        selectedBundlesOption === 'selectAllMOE' ||
                        selectedBundlesOption === 'selectAllCompany' ||
                        selectedBundlesOption === 'selectMyBundles') &&
                    this.selectedBundles.length > 1
                "
            />
            <dashboard-emission-bars
                :selectedBundles="selectedBundles"
                :moe-or-moa="allowBundleAssignment"
            ></dashboard-emission-bars>
        </div>
    </div>
</template>
<script>
import AppBarChart from '@/components/app-barChart/AppBarChart';
import AppBundle from '@/components/app-bundle/appBundle';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import IconCheck from '@/icons/IconCheck';
import { sortBy, uniqBy } from '@/services/sanitize.service';
import { getEndDate, getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { conclusionResult, getPreparations } from '@/features/preparations/preparations.service';
import AppTips from '@/components/app-tips/AppTips';
import DashboardEmissionBars from '@/features/dashboards/DashboardEmissionBars';
import DashboardDelaysComparisonBars from './DashboardDelaysComparisonBars';
import AppSelect from '@/components/appSelect/AppSelect';
import FollowObservationStats from '@/features/follow/FollowObservationStats';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getObservations } from '@/features/observations/observation.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getWeatherIssues } from '@/features/planning/weatherIssues/weatherIssues.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import { getSupports } from '@/features/supports/supports.service';
import { getCertificates } from '@/features/certificates/certificates.service';
import { getAutoControls } from '@/features/autoControls/autoControls.service';
import { getReceptions } from '@/features/receptions/receptions.service';
import { getServices } from '@/features/services/services.service';

export default {
    components: {
        FollowObservationStats,
        AppSelect,
        DashboardEmissionBars,
        AppTips,
        IconCheck,
        AppSeparator,
        AppBundle,
        AppBarChart,
        DashboardDelaysComparisonBars,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                getProject(this.$route.params.projectId),
                getObservations(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getWeatherIssues(this.$route.params.projectId),
                getAgenda(this.$route.params.projectId),
                getSupports(this.$route.params.projectId),
                getPreparations(this.$route.params.projectId, 'exe'),
                getPreparations(this.$route.params.projectId, 'con'),
                getCertificates(this.$route.params.projectId),
                getReceptions(this.$route.params.projectId),
                getAutoControls(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getServices(this.$route.params.projectId),
                getSupports(this.$route.params.projectId),
            ]).subscribe(
                async ([
                    tasks,
                    project,
                    observations,
                    folders,
                    weatherIssues,
                    agenda,
                    supports,
                    preparations,
                    conceptions,
                    certificates,
                    receptions,
                    autoControls,
                    bundles,
                    services,
                ]) => {
                    this.tasks = tasks;
                    this.project = project;
                    this.observations = observations.filter((observation) => !observation.obsoleteAt);
                    this.folders = folders;
                    this.weatherIssues = weatherIssues;
                    this.agenda = agenda;
                    this.supports = supports;
                    this.conceptions = conceptions;
                    this.preparations = preparations;
                    this.certificates = certificates;
                    this.autoControls = autoControls;
                    this.receptions = receptions;
                    this.bundles = bundles;
                    this.services = services;
                    this.me = {
                        ...project.me,
                        bundles: project.me.bundleIds.map((bundleId) =>
                            bundles.find((bundle) => bundle.id === bundleId),
                        ),
                    };
                    this.selectedBundles = this.bundles.filter((bundle) => this.me.bundleIds.includes(bundle.id));

                    await this.refresh();
                },
            ),
        ];
    },
    methods: {
        async onSelectedBundlesOptionChange(value) {
            if (value === 'selectAllMOA') {
                this.selectedBundles = this.bundles.filter((bundle) => bundle.category === 'MOA');
            } else if (value === 'selectAllMOE') {
                this.selectedBundles = this.bundles.filter((bundle) => bundle.category === 'MOE');
            } else if (value === 'selectAllCompany') {
                this.selectedBundles = this.bundles.filter((bundle) => bundle.category === 'company');
            } else if (value === 'selectMyBundles') {
                this.selectedBundles = this.me.bundles;
            } else {
                this.selectedBundles = this.bundles.filter((bundle) => bundle.id === value);
            }
            await this.refresh();
        },
        async refresh() {
            if (this.allowBundleAssignment) {
                this.unassignedBundles = this.bundles.filter((item) => !item.companyId || !item.responsibleId);
            } else {
                this.unassignedBundles = [];
            }
            if (this.allowLocations) {
                const locations = this.folders.reduce((acc, folder) => [...acc, ...folder.locations], []);
                this.isNewlyCreatedLocation = !!locations.find(
                    (location) =>
                        new Date(location.createdAt).getTime() > new Date(this.project.createdAt).getTime() + 1000,
                );
            } else {
                this.isNewlyCreatedLocation = true;
            }
            if (this.allowPlanning) {
                this.requireAgendaExceptions = this.agenda.filter((period) => period.type !== 'official').length === 0;
                this.weatherIssuesToValidate = this.weatherIssues.filter((item) => !item.conclusion);
            } else {
                this.requireAgendaExceptions = false;
                this.weatherIssuesToValidate = [];
            }

            const services = this.services;
            if (this.allowServices) {
                this.requireService = services.length === 0;
                this.unlocatedServices = services.filter(
                    (service) => service.isExecution && !this.tasks.find((task) => task.serviceId === service.id),
                );
            } else {
                this.requireService = false;
                this.unlocatedServices = [];
            }

            if (this.allowSupports) {
                this.untypedSupports = uniqBy(this.supports, 'groupId').filter((support) => !support.type);
                const locationIdWithGraphicSupport = uniqBy(this.supports, 'groupId')
                    .filter((support) => support.type === 'graphic')
                    .reduce((acc, support) => [...acc, ...support.locationIds], []);
                const zones = this.folders
                    .reduce((acc, folder) => [...acc, ...folder.locations], [])
                    .reduce((acc, location) => [...acc, ...location.zones], []);
                this.noSupportZones = zones.filter((zone) => !locationIdWithGraphicSupport.includes(zone.id));
            } else {
                this.untypedSupports = [];
                this.noSupportZones = [];
            }
            if (this.allowPreparations) {
                const preparations = uniqBy(this.preparations, 'groupId');
                this.preparationsWithNewVersionRequired = preparations.filter((item) => {
                    const conclusion = conclusionResult(item);
                    return item.emissionDate && (conclusion === 'nonCompliant' || conclusion === 'rejected');
                });
            } else {
                this.preparationsWithNewVersionRequired = [];
            }
            if (this.allowConceptions) {
                const preparations = uniqBy(this.conceptions, 'groupId');
                this.conceptionsWithNewVersionRequired = preparations.filter((item) => {
                    const conclusion = conclusionResult(item);
                    return item.emissionDate && (conclusion === 'nonCompliant' || conclusion === 'rejected');
                });
            } else {
                this.conceptionsWithNewVersionRequired = [];
            }

            this.supportsToEmit = uniqBy(this.supports, 'groupId').filter(
                (item) => item.emitter && this.isBundle(item.emitter) && !item.emissionDate,
            );

            const preparations = uniqBy(this.preparations, 'groupId');
            this.preparationsToEmit = preparations.filter(
                (item) => item.bundle && this.isBundle(item.bundle) && !item.emissionDate,
            );
            this.preparationsToVisa = preparations
                .filter((preparation) => !!preparation.emissionDate)
                .reduce(
                    (acc, preparation) => [...acc, ...preparation.visas.map((visa) => ({ ...visa, preparation }))],
                    [],
                )
                .filter((visa) => this.isBundle(visa.emitter) && visa.conclusion === null);

            const conceptions = uniqBy(this.conceptions, 'groupId');
            this.conceptionsToEmit = conceptions.filter(
                (item) => item.bundle && this.isBundle(item.bundle) && !item.emissionDate,
            );
            this.conceptionsToVisa = conceptions
                .filter((preparation) => !!preparation.emissionDate)
                .reduce(
                    (acc, preparation) => [...acc, ...preparation.visas.map((visa) => ({ ...visa, preparation }))],
                    [],
                )
                .filter((visa) => this.isBundle(visa.emitter) && visa.conclusion === null);

            if (this.allowCertificates) {
                this.certificatesToEmit = this.certificates.filter(
                    (item) => this.isBundle(item.emitter) && !item.emissionDate,
                );
            } else {
                this.certificatesToEmit = [];
            }
            this.receptionsToEmit = uniqBy(this.receptions, 'groupId').filter(
                (item) => this.isBundle(item.bundle) && !item.emissionDate,
            );
            this.autoControlsToEmit = uniqBy(this.autoControls, 'groupId').filter(
                (item) => this.isBundle(item.bundle) && !item.emissionDate,
            );
            if (this.allowValidation) {
                this.observationsToValidate = this.observations.filter(
                    (observation) => observation.resolvedAt && !observation.validatedAt,
                );
            }
        },
        isBundle(recipientIds) {
            return (
                recipientIds &&
                this.selectedBundles.some((bundleId) =>
                    recipientIds.includes ? recipientIds.includes(bundleId) : recipientIds.id === bundleId,
                )
            );
        },
    },
    computed: {
        isBundleAdmin() {
            return this.me.allowedFeatures.includes('project_bundles');
        },
        isPlanningAdmin() {
            return this.me.allowedFeatures.includes('project_planning');
        },
        isLocationAdmin() {
            return this.me.allowedFeatures.includes('project_locations');
        },
        project_EXE() {
            return this.project.projectFeatures.includes('project_EXE');
        },
        project_CON() {
            return this.project.projectFeatures.includes('project_CON');
        },
        project_dashboard() {
            return this.me.allowedFeatures.includes('project_dashboard');
        },
        allowedBundles() {
            if (this.me.allowedFeatures.includes('project_dashboard')) {
                return this.bundles;
            } else {
                return this.me.bundleIds.map((bundleId) => this.bundles.find((aBundle) => aBundle.id === bundleId));
            }
        },
        allowBundleAssignment() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_bundles'))
            );
        },
        allowValidation() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_observations'))
            );
        },
        allowPlanning() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_planning'))
            );
        },
        allowLocations() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_locations'))
            );
        },
        allowServices() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_services'))
            );
        },
        allowSupports() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_supports'))
            );
        },
        allowPreparations() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_preparations'))
            );
        },
        allowConceptions() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_CON'))
            );
        },
        allowCertificates() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_certificates'))
            );
        },
        allowGenerals() {
            return (
                this.selectedBundlesOption === 'selectAllMOA' ||
                this.selectedBundlesOption === 'selectAllMOE' ||
                (this.$route.name === 'myDashboard' && (this.me.allowedFeatures || []).includes('project_generals'))
            );
        },
        filteredTasks() {
            return sortBy(
                this.tasks
                    .filter(
                        (task) =>
                            this.isBundle(task.service && task.service.bundle) &&
                            !task.isError &&
                            !task.realEndDate &&
                            (task.realStartDate || task.startDate.getTime() < new Date().getTime()),
                    )
                    .map((task) => {
                        return { ...task, dueDate: getEndDate(task, this.agenda, null) };
                    }),
                'dueDate',
                { nullIsLower: false },
            );
        },
    },
    data() {
        return {
            me: { bundleIds: [], bundles: [], allowedFeatures: [] },
            selectedBundlesOption: 'selectMyBundles',
            selectedBundles: [],
            services: [],
            folders: [],
            observations: [],
            tasks: [],
            agenda: [],
            bundles: [],
            autoControls: [],
            certificates: [],
            receptions: [],
            preparations: [],
            conceptions: [],
            weatherIssues: [],
            isNewlyCreatedLocation: true,
            requireAgendaExceptions: false,
            requireService: false,
            agendaExceptions: [],
            unlocatedServices: [],
            untypedSupports: [],
            noSupportZones: [],
            unassignedBundles: [],
            preparationsWithNewVersionRequired: [],
            conceptionsWithNewVersionRequired: [],
            supportsToEmit: [],
            preparationsToEmit: [],
            conceptionsToEmit: [],
            certificatesToEmit: [],
            receptionsToEmit: [],
            preparationsToVisa: [],
            conceptionsToVisa: [],
            autoControlsToEmit: [],
            observationsToValidate: [],
            weatherIssuesToValidate: [],
            project: { projectFeatures: [] },
        };
    },
};
</script>
