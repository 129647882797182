import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getAPIHeaders } from '@/services/api.service';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapObservationsReport(observationsReportDb) {
    return observationsReportDb
        ? {
              ...observationsReportDb.toMutableJSON(),
              date: observationsReportDb.date ? new Date(observationsReportDb.date) : null,
              startDate: observationsReportDb.startDate ? new Date(observationsReportDb.startDate) : null,
              endDate: observationsReportDb.endDate ? new Date(observationsReportDb.endDate) : null,
          }
        : null;
}

export async function updateObservationsReport(projectId, observationsReport) {
    const observationsReportDb = await db
        .getProjectCollections(projectId)
        .observationsReports.findOne({ selector: { id: observationsReport.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(observationsReport)) };
    if (observationsReport.date) {
        patch.date = observationsReport.date ? observationsReport.date.toISOString() : null;
    }
    return observationsReportDb.atomicPatch(patch);
}

export function getAllObservationsReports(projectId) {
    return getCachedObservable('getObservationsReports_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .observationsReports.find({ selector: {}, sort: [{ date: 'asc' }] })
            .$.pipe(map((items) => items.map(mapObservationsReport))),
    );
}
export function getObservationsReports(projectId, relatedContentId) {
    return getCachedObservable('getObservationsReports_' + projectId + '_' + relatedContentId, () =>
        db
            .getProjectCollections(projectId)
            .observationsReports.find({
                selector: { relatedContentId },
                sort: [{ date: 'asc' }],
            })
            .$.pipe(map((items) => items.map(mapObservationsReport))),
    );
}

export function getObservationsReport(projectId, observationsReportId) {
    return db
        .getProjectCollections(projectId)
        .observationsReports.findOne({ selector: { id: observationsReportId } })
        .$.pipe(map(mapObservationsReport));
}

export async function createObservationsReport(projectId, observationsReport) {
    const result = await db.getProjectCollections(projectId).observationsReports.insert({
        ...observationsReport,
        date: observationsReport.date ? observationsReport.date.toISOString() : new Date().toISOString(),
    });
    return result.toJSON();
}

export async function removeObservationsReport(projectId, observationsReportId) {
    const entity = await db
        .getProjectCollections(projectId)
        .observationsReports.findOne({ selector: { id: observationsReportId } })
        .exec();
    return entity.remove();
}

export async function generateObservationsReportFiles(projectId, observationsReport) {
    const response = await fetch(
        new Request(`/api/projects/${projectId}/observationsReports/${observationsReport.id}/files`, {
            method: 'PUT',
            headers: getAPIHeaders({ 'content-type': `application/json` }),
            body: JSON.stringify({ name: observationsReport.name }),
        }),
    );
    if (!response.ok) {
        throw new Error('generateObservationsReportFiles');
    }
    return response.json();
}

export async function filterZoneIds(projectId, observationsReport, filterFn) {
    const observationsReportDb = await db
        .getProjectCollections(projectId)
        .observationsReports.findOne({ selector: { id: observationsReport.id } })
        .exec();
    return observationsReportDb.atomicPatch({
        zoneIds: observationsReportDb.zoneIds.filter(filterFn),
    });
}
export async function filterBundleIds(projectId, observationsReport, bundleId) {
    const observationsReportDb = await db
        .getProjectCollections(projectId)
        .observationsReports.findOne({ selector: { id: observationsReport.id } })
        .exec();
    return observationsReportDb.atomicPatch({
        bundleIds: observationsReportDb.bundleIds.filter((id) => id !== bundleId),
    });
}

export function filterObservationFromObservationReportConfig(observations, zoneIds, bundleIds, config) {
    return observations.filter((observation) => {
        return (
            !observation.obsoleteAt &&
            ((observation.type === 'administrative' && config.showAdministrative) ||
                (observation.type === 'other' && config.showOtherObservations) ||
                (observation.type === 'onDoneWork' && config.showObservationsOnDoneWork) ||
                (observation.type === 'onTodoWork' && config.showObservationsOnTodoWork)) &&
            (((observation.phase === 'EXE' ||
                observation.phase === 'reception' ||
                observation.phase === 'autoControl') &&
                config.showEXEObservations) ||
                (observation.phase === 'OPR' && config.showOPRObservations) ||
                (observation.phase === 'Delivery' && config.showDeliveryObservations) ||
                (observation.phase === 'Receipt' && config.showReceiptObservations) ||
                (observation.phase === 'OPL' && config.showOPLObservations) ||
                (observation.phase === 'APA' && config.showAPAObservations)) &&
            observation.type !== 'generals' &&
            ((observation.zoneId && observation.footprint) || config.showObservationsWithoutZone) &&
            (!observation.resolvedAt || config.showResolved) &&
            (!observation.validatedAt || config.showValidated) &&
            ((!observation.validatedAt && !observation.resolvedAt) || config.showPending) &&
            (bundleIds.length === 0 ||
                observation.recipientIds.length === 0 ||
                observation.recipientIds.find((recipientId) => bundleIds.includes(recipientId))) &&
            (!config.emitterIds ||
                config.emitterIds.length === 0 ||
                config.emitterIds.includes(observation.reportedBy)) &&
            (!config.startDate || observation.reportedAt > config.startDate) &&
            (!config.endDate || observation.reportedAt < config.endDate) &&
            (zoneIds.length === 0 || zoneIds.includes(observation.zoneId))
        );
    });
}
export default {
    filterZoneIds,
    filterBundleIds,
};
