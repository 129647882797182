<template>
    <div>
        <AppLegend />
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="weatherIssue.name"
                        class="col-span-2"
                        :required="true"
                        @blur="save"
                        :disabled="isLocked"
                    />
                    <app-date-input
                        :label="$t('project.follow.weatherIssues.date')"
                        v-model="weatherIssue.date"
                        class="col-span-2 md:col-span-1"
                        @input="save"
                        :disabled="isLocked"
                    />
                    <app-duration-input
                        :label="$t('project.follow.weatherIssues.duration')"
                        v-model="weatherIssue.duration"
                        class="col-span-2 md:col-span-1"
                        :reference-date="weatherIssue.date"
                        @blur="save"
                        :disabled="isLocked"
                    />
                    <app-multi-picker
                        class="col-span-2"
                        v-model="weatherIssue.emitters"
                        :options="bundles"
                        :label="$t('project.follow.weatherIssues.emitters')"
                        :title="$t('project.follow.weatherIssues.chooseEmitters')"
                        @input="save"
                        label-key="label"
                        :disabled="isLocked"
                    >
                        <template v-slot:option="{ option }">{{ option.label }}</template>
                    </app-multi-picker>
                    <div class="col-span-2 flex items-end">
                        <app-select
                            :label="$t('project.follow.weatherIssues.conclusion')"
                            v-model="weatherIssue.conclusion"
                            @input="save"
                            :disabled="!isAdmin"
                        >
                            <option value="">{{ $t('project.follow.weatherIssues.conclusions.none') }}</option>
                            <option value="validated">
                                {{ $t('project.follow.weatherIssues.conclusions.validated') }}
                            </option>
                            <option value="rejected">
                                {{ $t('project.follow.weatherIssues.conclusions.rejected') }}
                            </option>
                        </app-select>
                        <div class="ml-4 flex gap-1" v-if="weatherIssue.conclusion === 'rejected'">
                            <span class="underline" @click="$refs.conclusionCommentInput.open()">
                                {{ $t('project.follow.weatherIssues.conclusionComment') }} :
                            </span>
                            <app-multiline-text-link
                                ref="conclusionCommentInput"
                                v-model="weatherIssue.conclusionComment"
                                @input="save()"
                            ></app-multiline-text-link>
                        </div>
                    </div>
                </app-fieldset>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        </ValidationObserver>
    </div>
</template>

<script>
import AppLegend from '../../../components/appLegend/AppLegend';
import AppInputText from '../../../components/appInputText/AppInputText';
import AppFieldset from '../../../components/appFieldset/AppFieldset';
import AppDateInput from '../../../components/appDateInput/AppDateInput';
import AppButton from '../../../components/appButton/AppButton';
import AppMultiPicker from '../../../components/appMultiPicker/AppMultiPicker';
import { updateWeatherIssue } from './weatherIssues.service';
import AppDurationInput from '../../../components/appDurationInput/AppDurationInput';
import AppLabel from '../../../components/appLabel/AppLabel';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppBundle from '@/components/app-bundle/appBundle';
import { getBundles } from '@/features/bundles/bundles.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppMultilineTextLink from '@/components/appMultilineTextLink.vue';
export default {
    components: {
        AppMultilineTextLink,
        AppSelect,
        AppBundle,
        AppSaveOnLeave,
        AppLabel,
        AppDurationInput,
        AppMultiPicker,
        AppButton,
        AppDateInput,
        AppFieldset,
        AppInputText,
        AppLegend,
    },
    props: {
        weatherIssue: { type: Object },
        isAdmin: Boolean,
    },
    async created() {
        this.subscriptions = [
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                this.bundles = bundles;
            }),
        ];
    },
    computed: {
        isLocked() {
            return (!this.isAdmin && !!this.weatherIssue.conclusion) || (!this.isAdmin && !this.weatherIssue.isEmitter);
        },
    },
    methods: {
        save() {
            const weatherIssueEntity = {
                id: this.weatherIssue.id,
                name: this.weatherIssue.name,
                date: this.weatherIssue.date,
                duration: this.weatherIssue.duration,
                conclusion:
                    this.weatherIssue.conclusion === 'rejected' || this.weatherIssue.conclusion === 'validated'
                        ? this.weatherIssue.conclusion
                        : null,
                conclusionComment:
                    this.weatherIssue.conclusion === 'rejected' ? this.weatherIssue.conclusionComment : null,
                emitterIds: this.weatherIssue.emitters ? this.weatherIssue.emitters.map((bundle) => bundle.id) : [],
            };
            updateWeatherIssue(this.$route.params.projectId, weatherIssueEntity);
            this.$refs.observer.reset();
        },
    },
    data() {
        return {
            bundles: [],
            subscriptions: [],
        };
    },
};
</script>
