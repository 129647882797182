import { getAPIHeaders } from '@/services/api.service';

function mapPrice(item) {
    return item
        ? {
              ...item,
              expireAt: item.expireAt ? new Date(item.expireAt) : null,
              createdAt: item.createdAt ? new Date(item.createdAt) : null,
          }
        : null;
}

export async function getDefaultPrices() {
    const response = await fetch(`/api/prices`, {
        headers: getAPIHeaders(),
    });
    return (await response.json()).map(mapPrice);
}
