import db from '@/rxdb/database';
import { sortBy } from '@/services/sanitize.service';
import { map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { getCachedObservable } from '@/rxdb/observablesCache';

export async function updateLocation(projectId, location) {
    const dbLocation = await db
        .getProjectCollections(projectId)
        .locations.findOne({ selector: { id: location.id } })
        .exec();
    return dbLocation.atomicPatch(location);
}

async function create(projectId, location) {
    const result = await db.getProjectCollections(projectId).locations.insert(location);
    return result.toJSON();
}
export function copyBranch(node, parentId) {
    const newNode = { id: uuidv4(), name: node.name, parentId, type: node.type };
    const child = node.locations || node.zones || node.rooms || [];
    return [newNode, ...child.reduce((acc, children) => [...acc, ...copyBranch(children, newNode.id)], [])];
}

export async function bulkInsert(projectId, locations) {
    return await db.getProjectCollections(projectId).locations.bulkInsert(locations);
}
export async function bulkDelete(projectId, locations) {
    return await db.getProjectCollections(projectId).locations.bulkRemove(locations);
}

export function deepCopy(location) {
    return copyBranch(location, location.parentId);
}
export function getNewCopyName(name, index) {
    const currentIterationSuffix = name.match(/\d+/) ? name.match(/\d+/)[0] : null;
    if (currentIterationSuffix) {
        return name.replace(
            currentIterationSuffix,
            (parseInt(currentIterationSuffix) + index + 1).toString().padStart(currentIterationSuffix.length, '0'),
        );
    } else {
        return name + ' ' + (index + 2).toString().padStart(2, '0');
    }
}
export function createFolder(projectId, folder) {
    return create(projectId, { type: 'folder', ...folder });
}
export function createLocation(projectId, location) {
    return create(projectId, { type: 'location', ...location });
}
export function createZone(projectId, zone) {
    return create(projectId, { type: 'zone', ...zone });
}
export function createRoom(projectId, room) {
    return create(projectId, { type: 'room', ...room });
}
export function _buildTree(locations) {
    const folders = {};
    const levels = {};
    const zones = {};
    const rooms = {};
    for (const location of locations) {
        if (location.type === 'folder') {
            folders[location.id] = { ...location, locations: [], fullName: location.name, path: location.id };
        } else if (location.type === 'location') {
            levels[location.id] = { ...location, zones: [] };
        } else if (location.type === 'zone') {
            zones[location.id] = { ...location, rooms: [] };
        } else if (location.type === 'room') {
            rooms[location.id] = { ...location };
        }
    }
    for (const { id, type, parentId, name } of locations) {
        if (type === 'location' && folders[parentId]) {
            levels[id].parent = folders[parentId];
            levels[id].fullName = folders[parentId].name + ' > ' + levels[id].name;
            levels[id].path = parentId + ',' + id;
            folders[parentId].locations.push(levels[id]);
        } else if (type === 'zone' && levels[parentId]) {
            zones[id].parent = levels[parentId];
            zones[id].fullName =
                folders[levels[parentId].parentId].name + ' > ' + levels[parentId].name + ' > ' + zones[id].name;
            zones[id].path = levels[parentId].parentId + ',' + parentId + ',' + id;
            levels[parentId].zones.push(zones[id]);
        } else if (type === 'room' && zones[parentId]) {
            rooms[id].parent = zones[parentId];
            rooms[id].fullName =
                folders[levels[zones[parentId].parentId].parentId].name +
                ' > ' +
                levels[zones[parentId].parentId].name +
                ' > ' +
                zones[parentId].name +
                ' > ' +
                rooms[id].name;
            rooms[id].path =
                levels[zones[parentId].parentId].parentId + ',' + zones[parentId].parentId + ',' + parentId + ',' + id;
            zones[parentId].rooms.push(rooms[id]);
        } else if (type !== 'folder') {
            console.error('orphan location : ', { id, type, name, parentId });
        }
    }
    return sortDeepByName(Object.values(folders));
}
export function getLocationsTree(projectId) {
    return getCachedObservable('getLocationsTree_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .locations.find()
            .$.pipe(
                map((items) => {
                    return _buildTree(items.map((item) => item.toJSON()));
                }),
            ),
    );
}

export function sortDeepByName(folders) {
    const result = sortBy(folders, 'name');
    for (const folder of result) {
        folder.locations = sortByFullName(folder.locations);
        for (const location of folder.locations) {
            location.zones = sortByFullName(location.zones);
            for (const zone of location.zones) {
                zone.rooms = sortByFullName(zone.rooms);
            }
        }
    }
    return result;
}
export function sortByFullName(locations) {
    return sortBy(locations, (key) => key.fullName.replace('+', 'z+'));
}
