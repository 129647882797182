import db from '@/rxdb/database';
import { map } from 'rxjs';

export async function createObservationAttachment(projectId, observationAttachment) {
    const result = await db.getProjectCollections(projectId).observationAttachments.insert({
        projectId,
        id: observationAttachment.id,
        observationId: observationAttachment.observationId,
        name: observationAttachment.url,
        dataUrl: observationAttachment.dataUrl,
        url: observationAttachment.url,
        shapes: observationAttachment.shapes ? JSON.parse(JSON.stringify(observationAttachment.shapes)) : [],
    });
    return result.toJSON();
}

export function getObservationAttachments(projectId) {
    return db
        .getProjectCollections(projectId)
        .observationAttachments.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapObservationAttachment)));
}

function mapObservationAttachment(observationDb) {
    return observationDb ? observationDb.toMutableJSON() : null;
}

export async function updateObservationAttachments(projectId, observationAttachment) {
    const dbObservation = await db
        .getProjectCollections(projectId)
        .observationAttachments.findOne({ selector: { id: observationAttachment.id } })
        .exec();
    return dbObservation.atomicPatch({ shapes: JSON.parse(JSON.stringify(observationAttachment.shapes)) });
}

export function getObservationAttachmentsByObservationId(projectId, observationId) {
    return db
        .getProjectCollections(projectId)
        .observationAttachments.find({ selector: { observationId } })
        .$.pipe(map((items) => items.map(mapObservationAttachment)));
}

export async function removeObservationAttachment(projectId, observationAttachmentId) {
    const entity = await db
        .getProjectCollections(projectId)
        .observationAttachments.findOne({ selector: { id: observationAttachmentId } })
        .exec();
    return entity.remove();
}
