<template>
    <main class="max-w-5xl m-5 max-h-full mb-10 w-full">
        <app-synthesis-observation :observation-id="this.$route.params.observationId" @deleted="backToList()" />
    </main>
</template>

<script>
import AppSynthesisObservation from '@/features/preparations/AppSynthesisObservation.vue';
export default {
    components: {
        AppSynthesisObservation,
    },
    methods: {
        backToList() {
            this.$router.push({ name: 'followSyntheses', params: { ...this.$route.params } });
        },
    },
};
</script>
