<template>
    <div class="w-full mt-4 ml-4 max-w-5xl">
        <AppTree
            class="border"
            ref="tree"
            :root="tree"
            :selectedId="selectedItem ? selectedItem.id : null"
            @select="onSelect"
            @edit="editItem"
            root-classes="top-toolbar"
        >
            <template slot="toolbar">
                <app-expand :show-label="false" @expand="expand" ref="expand" class="mr-4" />

                <app-drop-down-button
                    class="mr-4 text-sm"
                    :label="$t('project.information.folders.new')"
                    @input="addItem"
                    :show-label="false"
                    :placeholder="$t('project.information.folders.new')"
                    v-if="!readOnly"
                >
                    <template>
                        <option value="folder">
                            {{ $t('project.information.folders.newFolderFullLabel') }}
                        </option>
                        <option value="location" :disabled="!selectedItem">
                            {{ $t('project.information.folders.newLocationFullLabel') }}
                        </option>
                        <option value="zone" :disabled="!selectedItem || selectedItem.type === 'folder'">
                            {{ $t('project.information.folders.newZoneFullLabel') }}
                        </option>
                        <option
                            value="room"
                            :disabled="!selectedItem || (selectedItem.type !== 'zone' && selectedItem.type !== 'room')"
                        >
                            {{ $t('project.information.folders.newRoomFullLabel') }}
                        </option>
                    </template>
                </app-drop-down-button>

                <app-button
                    :disabled="!selectedItem"
                    class="mr-4"
                    :title="$t('commons.deleteItem')"
                    @click="onDelete(selectedItem)"
                    icon="icon-trash-can-outline"
                    size="mini"
                    v-if="!readOnly"
                />
            </template>
            <template v-slot:item="{ item }">
                <div v-if="selectedItem && item.id === selectedItem.id && editEnabled">
                    <ValidationObserver v-slot="{ invalid, touched, errors, dirty }" class="w-full" ref="observer">
                        <form class="flex" @submit.prevent="">
                            <app-input-text
                                aria-label="item name"
                                @keyup.esc.stop="cancelEdit(item)"
                                @keyup.enter.stop="onSaveItem(item)"
                                ref="input"
                                v-model="item.name"
                                :show-label="false"
                                @blur="onSaveItem(item)"
                            ></app-input-text>
                            <div class="p-1 flex items-center">
                                <app-button
                                    :label="$t('commons.validate')"
                                    size="mini"
                                    variant="primary"
                                    class="mx-2"
                                ></app-button>
                            </div>
                        </form>
                        <app-save-on-leave :dirty="dirty" :saveFn="() => onSaveItem(item, true)"></app-save-on-leave>
                    </ValidationObserver>
                </div>
                <div v-else class="flex">
                    <span class="ml-1" @click="editItem(item)">{{ item.name }}</span>
                </div>
                <div class="flex-grow"></div>
                <div class="flex">
                    <app-button
                        v-if="(!selectedItem || item.id !== selectedItem.id || !editEnabled) && !readOnly"
                        icon="icon-pencil"
                        size="mini"
                        @click.stop="editItem(item)"
                    ></app-button>
                </div>
            </template>
        </AppTree>
        <app-quick-actions :options="quickActions" @choose="$event.run()" />
        <locations-cascade-popup
            :impacts="impacts"
            ref="confirmPopup"
            @deleteConfirmed="onDeleteConfirmed"
        ></locations-cascade-popup>
    </div>
</template>

<script>
import AppList from '../../components/appList/AppList';
import AppTree from '../../components/AppTree';
import AppButton from '../../components/appButton/AppButton';
import AppInputText from '../../components/appInputText/AppInputText';
import IconPencil from '../../icons/IconPencil';
import AppCancel from '../../components/appCancel/AppCancel';
import {
    bulkInsert,
    createFolder,
    createLocation,
    createRoom,
    createZone,
    deepCopy,
    getLocationsTree,
    getNewCopyName,
    updateLocation,
} from './locations.service';
import IconTableRow from '../../icons/IconTableRow';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppExpand from '@/components/appExpand/AppExpand';
import { debounce } from '@/services/sanitize.service';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import hotkeys from 'hotkeys-js';
import { error, info } from '@/features/toasts/toats.service';
import { queryProject } from '@/features/projects/projects.service';
import { getCascadeImpacts, removeLocationCascade } from '@/features/locations/locationCascades.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppFooter from '@/components/appFooter/AppFooter';
import LocationsCascadePopup from '@/features/locations/LocationsCascadePopup';

export default {
    name: 'folders',
    components: {
        LocationsCascadePopup,
        AppFooter,
        AppPopup,
        AppDropDownButton,
        AppExpand,
        AppSaveOnLeave,
        AppQuickActions,
        IconTableRow,
        AppCancel,
        IconPencil,
        AppInputText,
        AppButton,
        AppTree,
        AppList,
    },
    created() {
        this.subscriptions = [
            getLocationsTree(this.$route.params.projectId).subscribe((folders) => {
                this.folders = folders;
                this.tree = this.buildTree();
                this.$nextTick(() => {
                    this.applySelectedId();
                });
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_locations');
        });
    },
    mounted() {
        hotkeys('backspace,delete', { capture: true }, this.onRemove);
        hotkeys('ctrl+c', { capture: true }, this.onCopy);
        hotkeys('ctrl+v', { capture: true }, this.onPaste);
    },
    beforeDestroy() {
        hotkeys.unbind('backspace,delete');
        hotkeys.unbind('ctrl+c');
        hotkeys.unbind('ctrl+v');
    },
    data() {
        return {
            subscriptions: [],
            impacts: null,
            folders: [],
            tree: {},
            selectedItem: null,
            copiedItem: null,
            editEnabled: false,
            readOnly: true,
        };
    },
    methods: {
        onCopy(event) {
            if (this.selectedItem && event.target.nodeName === 'BODY' && !this.readOnly) {
                this.copy();
            }
        },
        copy() {
            this.copiedItem = this.selectedItem;
            info(this.selectedItem.name + ' ' + this.$t('commons.copied'));
        },
        onPaste(event) {
            if (this.copiedItem && event.target.nodeName === 'BODY' && !this.readOnly) {
                this.paste();
            }
        },
        addItem(type) {
            if (type === 'folder') {
                this.newFolder();
            } else if (type === 'location') {
                this.newLocation();
            } else if (type === 'zone') {
                this.newZone();
            } else if (type === 'room') {
                this.newRoom();
            }
        },
        onRemove(e) {
            if (this.selectedItem && e.target.nodeName === 'BODY' && !this.readOnly) {
                this.onDelete(this.selectedItem);
            }
        },
        expand(level) {
            if (level === 'all') {
                this.$refs.tree.expandAll();
            } else {
                this.$refs.tree.expandToLevel(parseInt(level));
            }
        },
        applySelectedId() {
            const selectedId = this.$route.query.selectedId;
            if (selectedId) {
                this.selectedItem = this.$refs.tree.getItemById(selectedId);
                this.$refs.tree.expandTo(selectedId);
            } else {
                this.selectedItem = null;
            }
            const edit = this.$route.query.edit === 'true' || this.$route.query.edit === true;
            this.editEnabled = edit;
            if (edit) {
                this.$nextTick(() => {
                    if (this.$refs.input) {
                        this.$refs.input.focus();
                        this.$refs.input.select();
                    }
                });
            }
        },
        onSaveItem: debounce(
            function (item, preventRouting) {
                this.editEnabled = false;
                if (this.$refs.observer) {
                    this.$refs.observer.reset();
                }
                this.saveItem(item);
                if (!preventRouting) {
                    return this.$router.push({
                        name: 'folders',
                        params: {
                            projectId: this.$route.params.projectId,
                        },
                        query: { selectedId: item.id, edit: false },
                    });
                }
            },
            100,
            true,
        ),
        saveItem(item) {
            return updateLocation(this.$route.params.projectId, { id: item.id, name: item.name });
        },
        cancelEdit(item) {
            this.editEnabled = false;
            return this.$router.push({
                name: 'folders',
                params: {
                    projectId: this.$route.params.projectId,
                },
                query: { selectedId: item.id, edit: false },
            });
        },
        editItem(item) {
            if ((this.$route.query.selectedId !== item.id || !this.$route.query.edit) && !this.readOnly) {
                return this.$router.push({
                    name: 'folders',
                    params: {
                        projectId: this.$route.params.projectId,
                    },
                    query: { selectedId: item.id, edit: true },
                });
            }
        },
        buildTree() {
            return {
                children: this.folders.map((folder) => ({
                    ...folder,
                    isDraggable: false,
                    children: folder.locations.map((location) => ({
                        ...location,
                        isDraggable: false,
                        children: location.zones.map((zone) => ({
                            ...zone,
                            isDraggable: false,
                            children: zone.rooms.map((room) => ({
                                ...room,
                                isDraggable: false,
                            })),
                        })),
                    })),
                })),
            };
        },
        async onSelect(item) {
            if (this.selectedItem && this.editEnabled && this.selectedItem.id !== item.id && !this.readOnly) {
                await this.saveItem(this.selectedItem);
            }
            if (!this.selectedItem || this.selectedItem.id !== item.id) {
                this.selectedItem = item;
                return this.$router.push({
                    name: 'folders',
                    params: {
                        projectId: this.$route.params.projectId,
                    },
                    query: { edit: false, selectedId: item.id },
                    hash: '/#uuid_' + item.id,
                });
            }
        },
        async onDelete(item) {
            if (!this.readOnly) {
                this.impacts = null;
                this.$refs.confirmPopup.open();
                this.impacts = await getCascadeImpacts(this.$route.params.projectId, item);
            }
        },
        async onDeleteConfirmed() {
            if (!this.readOnly) {
                await removeLocationCascade(this.$route.params.projectId, this.impacts);
                return this.$router.push({
                    name: 'folders',
                    params: {
                        projectId: this.$route.params.projectId,
                    },
                    query: { selectedId: '', edit: false },
                });
            }
        },
        async newFolder() {
            const result = await createFolder(this.$route.params.projectId, {
                name: this.$t('project.information.folders.newFolderName') + ' ' + (this.folders.length + 1),
            });
            this.editItem(result);
        },
        async newLocation() {
            let folder = null;
            if (this.selectedItem.type === 'folder') {
                folder = this.selectedItem;
            } else if (this.selectedItem.type === 'location') {
                folder = this.selectedItem.parent;
            } else if (this.selectedItem.type === 'zone') {
                folder = this.selectedItem.parent.parent;
            } else if (this.selectedItem.type === 'room') {
                folder = this.selectedItem.parent.parent.parent;
            }
            if (folder) {
                const result = await createLocation(this.$route.params.projectId, {
                    parentId: folder.id,
                    name: this.$t('project.information.folders.newLocationName') + (folder.locations.length + 1),
                });

                this.editItem(result);
            }
        },
        async newZone() {
            let location = null;
            if (this.selectedItem.type === 'location') {
                location = this.selectedItem;
            } else if (this.selectedItem.type === 'zone') {
                location = this.selectedItem.parent;
            } else if (this.selectedItem.type === 'room') {
                location = this.selectedItem.parent.parent;
            }
            if (location) {
                const result = await createZone(this.$route.params.projectId, {
                    parentId: location.id,
                    name: this.$t('project.information.folders.newZoneName') + ' ' + (location.zones.length + 1),
                });
                this.editItem(result);
            }
        },
        async newRoom() {
            let zone = null;
            if (this.selectedItem.type === 'zone') {
                zone = this.selectedItem;
            } else if (this.selectedItem.type === 'room') {
                zone = this.selectedItem.parent;
            }
            if (zone) {
                const result = await createRoom(this.$route.params.projectId, {
                    parentId: zone.id,
                    name: this.$t('project.information.folders.newRoomName') + (zone.rooms.length + 1),
                });
                this.editItem(result);
            }
        },
        async duplicate(times = 1) {
            let lastAddedItem = [];
            for (let index = 0; index < times; index++) {
                lastAddedItem = [
                    ...lastAddedItem,
                    ...(await deepCopy({ ...this.selectedItem, name: getNewCopyName(this.selectedItem.name, index) })),
                ];
            }
            await bulkInsert(this.$route.params.projectId, lastAddedItem);
            this.editItem(lastAddedItem[0]);
        },
        async paste() {
            let lastAddedItems = [];
            if (this.copiedItem.type === 'folder') {
                lastAddedItems = await deepCopy(this.copiedItem);
            } else if (this.selectedItem) {
                if (
                    (this.copiedItem.type === 'location' && this.selectedItem.type === 'folder') ||
                    (this.copiedItem.type === 'zone' && this.selectedItem.type === 'location') ||
                    (this.copiedItem.type === 'room' && this.selectedItem.type === 'zone')
                ) {
                    lastAddedItems = await deepCopy({ ...this.copiedItem, parentId: this.selectedItem.id });
                } else if (this.copiedItem.type === this.selectedItem.type) {
                    lastAddedItems = await deepCopy({ ...this.copiedItem, parentId: this.selectedItem.parentId });
                }
            }
            if (lastAddedItems.length) {
                info(this.copiedItem.name + ' ' + this.$t('commons.pasted'));
                await bulkInsert(this.$route.params.projectId, lastAddedItems);
                this.editItem(lastAddedItems[0]);
            } else {
                error(this.$t('commons.canNotPasteHere'));
            }
        },
    },
    watch: {
        $route() {
            this.applySelectedId();
        },
    },
    computed: {
        quickActions() {
            if (this.readOnly) {
                return [];
            }
            const duplicateLabel = this.$t('commons.duplicate');
            const times = this.$t('commons.times');
            const duplicateFn = (times) => this.duplicate(times);
            let result = [
                {
                    name: this.$t('project.information.folders.newFolderFullLabel'),
                    run: () => {
                        this.newFolder();
                    },
                },
                {
                    // /!\ Use of 'this' as pointer to the current option not to the component and fill the param
                    name(item, filter) {
                        if (filter) {
                            const numberOfTime = filter.match(/\d+/g);
                            if (numberOfTime) {
                                this.times = parseInt(numberOfTime);
                                return duplicateLabel + ' ' + numberOfTime[0] + ' ' + times;
                            }
                        }
                        return duplicateLabel;
                    },
                    // /!\ Use of 'this' as pointer to the current option not to the component and fill the param
                    run() {
                        duplicateFn(this.times || 1);
                        this.times = null;
                    },
                },
            ];
            if (this.selectedItem && this.selectedItem.type === 'folder') {
                result.push({
                    name: this.$t('project.information.folders.newLocationFullLabel'),
                    run: () => {
                        this.newLocation();
                    },
                });
            }
            if (this.selectedItem && this.selectedItem.type === 'location') {
                result.push({
                    name: this.$t('project.information.folders.newZoneFullLabel'),
                    run: () => {
                        this.newZone();
                    },
                });
            }
            if (this.selectedItem && this.selectedItem.type === 'zone') {
                result.push({
                    name: this.$t('project.information.folders.newRoomFullLabel'),
                    run: () => {
                        this.newRoom();
                    },
                });
            }
            if (this.selectedItem) {
                result.push({
                    name: this.$t('commons.copy'),
                    tip: 'ctrl+c',
                    run: () => {
                        this.copy();
                    },
                });
            }
            result.push({
                name: this.$t('commons.paste'),
                tip: 'ctrl+v',
                run: () => {
                    this.paste();
                },
            });
            return result;
        },
    },
};
</script>
