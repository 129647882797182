<template>
    <app-popup :title="$t('impacts.title')" variant="alert" ref="popup" :show-header="true">
        <div v-if="impacts === null" class="flex gap-3 w-full p-6">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            {{ $t('impacts.loading') }}
        </div>
        <div class="flex flex-col w-full p-6" v-if="!!impacts">
            <ul class="flex-col flex gap-3" v-if="Object.values(impacts).find((list) => list.length > 0)">
                <li v-if="impacts.contacts.length">
                    <ul class="list-disc p-2">
                        <app-impact
                            :length="impacts.contacts.length"
                            :label="$t('impacts.contacts')"
                            :tooltip="
                                impacts.contacts.map((contact) => contact.firstName + ' ' + contact.lastName).join('\n')
                            "
                        ></app-impact>
                    </ul>
                </li>
                <li>
                    {{ $t('impacts.impactsTip') }} :
                    <ul class="list-disc p-2">
                        <app-impact
                            :length="impacts.preparationVisas.length"
                            :label="
                                impacts.preparationVisas.length === 1
                                    ? $t('impacts.preparationVisa')
                                    : $t('impacts.preparationVisas')
                            "
                        ></app-impact>
                        <app-impact
                            :length="impacts.services.length"
                            :label="
                                impacts.services.length === 1
                                    ? $t('impacts.removedService')
                                    : $t('impacts.removedServices')
                            "
                            :tooltip="impacts.services.map((service) => service.name).join('\n')"
                            ::no-item-label="$t('impacts.noService')"
                        ></app-impact>
                        <app-impact
                            :length="impacts.directTasks.length"
                            :label="
                                impacts.directTasks.length === 1 ? $t('impacts.directTask') : $t('impacts.directTasks')
                            "
                            :tooltip="impacts.directTasks.map((task) => task.label).join('\n')"
                            ::no-item-label=" $t('impacts.noDirectTask')"
                        ></app-impact>
                        <app-impact
                            :length="impacts.linkedTasks.length"
                            :label="
                                impacts.linkedTasks.length === 1 ? $t('impacts.linkedTask') : $t('impacts.linkedTasks')
                            "
                            :tooltip="impacts.linkedTasks.map((task) => task.label).join('\n')"
                        />
                        <app-impact
                            :length="impacts.supports.length"
                            :label="impacts.supports.length === 1 ? $t('impacts.support') : $t('impacts.supports')"
                            :tooltip="impacts.supports.map((support) => support.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.certificates.length"
                            :label="
                                impacts.certificates.length === 1
                                    ? $t('impacts.certificate')
                                    : $t('impacts.certificates')
                            "
                            :tooltip="impacts.certificates.map((certificate) => certificate.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.autoControls.length"
                            :label="
                                impacts.autoControls.length === 1
                                    ? $t('impacts.autoControl')
                                    : $t('impacts.autoControls')
                            "
                            :tooltip="impacts.autoControls.map((autoControl) => autoControl.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.receptions.length"
                            :label="
                                impacts.receptions.length === 1 ? $t('impacts.reception') : $t('impacts.receptions')
                            "
                            :tooltip="impacts.receptions.map((reception) => reception.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.observations.length"
                            :label="
                                impacts.observations.length === 1
                                    ? $t('impacts.observation')
                                    : $t('impacts.observations')
                            "
                            :tooltip="impacts.observations.map((observation) => observation.label).join('\n')"
                        />
                        <app-impact
                            :length="impacts.observationsReports.length"
                            :label="
                                impacts.observationsReports.length === 1
                                    ? $t('impacts.observationsReport')
                                    : $t('impacts.observationsReports')
                            "
                            :tooltip="
                                impacts.observationsReports
                                    .map((observationsReport) => observationsReport.name)
                                    .join('\n')
                            "
                        />
                        <app-impact
                            :length="impacts.conceptions.length"
                            :label="
                                impacts.conceptions.length === 1
                                    ? $t('impacts.conceptions')
                                    : $t('impacts.conceptionss')
                            "
                            :tooltip="impacts.conceptions.map((conception) => conception.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.preparations.length"
                            :label="
                                impacts.preparations.length === 1
                                    ? $t('impacts.preparation')
                                    : $t('impacts.preparations')
                            "
                            :tooltip="impacts.preparations.map((preparation) => preparation.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.preparationsReports.length"
                            :label="
                                impacts.preparationsReports.length === 1
                                    ? $t('impacts.preparationsReport')
                                    : $t('impacts.preparationsReports')
                            "
                            :tooltip="
                                impacts.preparationsReports
                                    .map((preparationsReport) => preparationsReport.name)
                                    .join('\n')
                            "
                        />
                        <app-impact
                            :length="impacts.meetingConvocations.length"
                            :label="
                                impacts.meetingConvocations.length === 1
                                    ? $t('impacts.meetingConvocation')
                                    : $t('impacts.meetingConvocations')
                            "
                            :tooltip="impacts.meetingConvocations.map((meeting) => meeting.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.meetingRecipients.length"
                            :label="
                                impacts.meetingRecipients.length === 1
                                    ? $t('impacts.meetingRecipient')
                                    : $t('impacts.meetingRecipients')
                            "
                            :tooltip="impacts.meetingRecipients.map((meeting) => meeting.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.periods.length"
                            :label="impacts.periods.length === 1 ? $t('impacts.period') : $t('impacts.periods')"
                            :tooltip="impacts.periods.map((meeting) => meeting.name).join('\n')"
                        />
                        <app-impact
                            :length="impacts.weatherIssues.length"
                            :label="
                                impacts.weatherIssues.length === 1
                                    ? $t('impacts.weatherIssue')
                                    : $t('impacts.weatherIssues')
                            "
                            :tooltip="impacts.weatherIssues.map((weatherIssue) => weatherIssue.name).join('\n')"
                        />
                    </ul>
                </li>
            </ul>
            <span v-else>{{ $t('impacts.noImpact') }}</span>
        </div>
        <app-footer
            @click="
                $refs.popup.close();
                $emit('deleteConfirmed');
            "
            :ok-label="$t('impacts.confirm')"
            class="w-full p-2"
            variant="danger"
            :disabled="impacts === null"
        >
            <template v-slot:left>
                <app-button variant="default" :label="$t('commons.cancel')" size="mini" @click="$refs.popup.close()" />
            </template>
        </app-footer>
    </app-popup>
</template>

<script>
import AppFooter from '@/components/appFooter/AppFooter';
import AppButton from '@/components/appButton/AppButton';
import AppPopup from '@/components/app-popup/AppPopup';
import AppImpact from '@/components/app-impact/AppImpact';

export default {
    components: { AppImpact, AppPopup, AppButton, AppFooter },
    props: ['impacts'],
    methods: {
        open() {
            this.$refs.popup.open();
        },
    },
};
</script>
