<template>
    <main class="h-full w-full p-2">
        <div class="flex flex-row relative gap-2">
            <AppTree
                class="flex-grow border overflow-auto max-h-main pb-20"
                :style="{ 'max-width': !isMobile ? '50%' : '' }"
                ref="tree"
                :root="tree"
                :selectedId="selectedItem ? selectedItem.id : null"
                @select="onSelect($event, false)"
                @drop="onItemMoved"
                root-classes="top-0"
                :class="{ hidden: isMobile && $route.name !== 'services' }"
            >
                <template v-slot:item="{ item }">
                    <div class="flex-grow flex">
                        <span class="flex-grow">{{ item.name }}</span>
                        <div class="flex gap-1 items-center">
                            <template v-if="!isMobile">
                                <app-tips
                                    v-if="item.hasWarning"
                                    class="text-xs text-yellow-500 flex gap-1"
                                    :title="$t('services.unlocalizedServicesCount')"
                                >
                                    <icon-alert-circle-outline width="16" height="16"></icon-alert-circle-outline>
                                </app-tips>
                                <app-tips
                                    v-if="item.hasLoopError"
                                    class="text-xs text-red-700 flex"
                                    :title="$t('project.follow.planning.error.predecessor-loop')"
                                >
                                    <icon-all-inclusive width="16" height="16" />
                                </app-tips>
                                <app-tips v-if="item.services" class="text-xs text-right" style="width: 3ch">
                                    {{ item.services.length }}
                                </app-tips>
                                <span style="width: 3ch" class="text-xs" v-else></span>
                            </template>
                            <app-button
                                icon="icon-chevron-right"
                                size="mini"
                                class="text-xs sm:hidden"
                                @click.stop="onSelect(item, true)"
                                :aria-label="$t('commons.showDetailOf') + item.name"
                            ></app-button>
                        </div>
                    </div>
                </template>
                <template slot="toolbar">
                    <app-expand :show-label="false" @expand="expand" class="" />
                    <app-button
                        class=""
                        :label="$t('services.newService')"
                        @click.stop="newService()"
                        icon="icon-plus"
                        size="mini"
                        :disabled="!selectedItem"
                        v-if="!readOnly"
                    />
                    <app-button
                        class=""
                        :title="$t('services.newDirectory')"
                        @click.stop="newDirectory()"
                        icon="icon-folder-plus-outline"
                        size="mini"
                        :disabled="!selectedItem"
                        v-if="!readOnly"
                    />
                    <app-button
                        :disabled="!selectedItem || (selectedItem && selectedItem.__typename === 'Bundle')"
                        class=""
                        :title="$t('commons.deleteItem')"
                        @click="onDelete(selectedItem)"
                        icon="icon-trash-can-outline"
                        size="mini"
                        v-if="!readOnly"
                    />
                    <span class="flex-grow"></span>
                    <div class="flex-col items-end text-xs hidden sm:flex">
                        <span>{{ servicesCount }} {{ $t('services.servicesCount') }}</span>
                        <app-tips
                            v-if="unlocalizedServicesCount > 0"
                            class="text-xs flex gap-1 text-yellow-500"
                            :title="$t('services.unlocalizedServicesCount')"
                        >
                            <icon-alert-circle-outline width="16" height="16"></icon-alert-circle-outline>
                            {{ $t('services.unlocalizedServicesCount') }} ({{ unlocalizedServicesCount }})
                        </app-tips>
                    </div>
                </template>
            </AppTree>
            <div
                class="overflow-auto max-h-main"
                :class="{ 'w-1/2': !isMobile, 'w-full': isMobile }"
                v-if="!isMobile || $route.name !== 'services'"
            >
                <div>
                    <div class="">
                        <div v-if="selectedItem" class="flex flex-col h-full border">
                            <app-separator :label="selectedItem.name" class="mb-2" />
                            <router-view></router-view>
                        </div>
                        <div
                            v-else
                            class="border w-full h-full text-sm italic flex items-center justify-center text-gray-700"
                        >
                            {{ $t('services.empty') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <services-cascade-popup
            :impacts="impacts"
            ref="confirmPopup"
            @deleteConfirmed="onDeleteConfirmed"
        ></services-cascade-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()" v-if="!readOnly"></app-quick-actions>
    </main>
</template>

<script>
import AppTree from '../../components/AppTree';
import AppTabs from '../../components/appTabs/AppTabs';
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import { bulkInsert, createService, getServices, reOrder } from './services.service';
import { getCascadeImpacts, removeCascade } from './servicesCascades.service';
import { couldDropTo, expandTo, getIndexPrefix, getSortedChildren, moveItem } from './itemUtils';
import { isMobile } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AppSelect from '../../components/appSelect/AppSelect';
import AppExpand from '../../components/appExpand/AppExpand';
import { sortBy } from '@/services/sanitize.service';
import AppTips from '@/components/app-tips/AppTips';
import IconAlertCircleOutline from '@/icons/IconAlertCircleOutline';
import { importServices } from '@/features/services/pasteServices.service';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { createDirectory, getDirectories } from '@/features/services/directories.service';
import { getTasks } from '@/features/tasks/tasks.service';
import { getServiceSuccessorIds } from '@/features/tasks/plannedTasks.service';
import ServicesCascadePopup from '@/features/services/ServicesCascadePopup';

export default {
    async created() {
        document.addEventListener('paste', this.onPaste);
        this.$once('hook:beforeDestroy', () => {
            document.removeEventListener('paste', this.onPaste);
        });
        this.subscriptions = [
            combineLatest([
                getServices(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getDirectories(this.$route.params.projectId),
                getTasks(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
            ]).subscribe(([services, bundles, directories, tasks, project]) => {
                this.readOnly = !project.me.allowedFeatures.includes('project_services');
                this.services = services.map((service) => {
                    const successors = getServiceSuccessorIds(service, services);
                    const serviceTasks = tasks.filter((task) => task.serviceId === service.id);
                    const hasLoopError = service.predecessors.find((predecessor) =>
                        successors.includes(predecessor.serviceId),
                    );
                    const hasWarning = serviceTasks.length === 0 && service.isExecution;
                    return {
                        ...service,
                        tasks: serviceTasks,
                        __typename: 'Service',
                        hasWarning,
                        hasLoopError,
                    };
                });
                this.bundles = bundles.map((bundle) => ({
                    ...bundle,
                    services: this.services.filter(({ bundleId }) => bundleId === bundle.id),
                    directories: directories
                        .filter(({ bundleId }) => bundleId === bundle.id)
                        .map((directory) => ({ ...directory, __typename: 'Directory' })),
                    __typename: 'Bundle',
                }));
                this.tree = this.populateTree(sortBy(this.bundles, 'reference'));
                if (!this.selectedItem) {
                    this.$nextTick(() => this.loadSelectedItemFromRoute());
                }
            }),
        ];
    },
    components: {
        ServicesCascadePopup,
        IconAlertCircleOutline,
        AppTips,
        AppExpand,
        AppSelect,
        AppQuickActions,
        AppButton,
        AppTabs,
        AppTree,
        AppSeparator,
    },
    data() {
        return {
            isMobile: isMobile,
            readOnly: true,
            subscriptions: [],
            services: [],
            directories: [],
            bundles: [],
            tree: {},
            selectedItem: null,
            impacts: null,
            project: {},
            nodeMap: {},
        };
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        document.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
        document.removeEventListener('keyup', this.onKeyUp);
    },
    watch: {
        $route() {
            this.loadSelectedItemFromRoute();
        },
    },
    computed: {
        servicesCount() {
            return this.services.length;
        },
        unlocalizedServicesCount() {
            return this.services.filter((service) => service.isExecution && service.tasks.length === 0).length;
        },
        quickActions() {
            const actions = [
                {
                    name: this.$t('commons.collapseAll'),
                    run: () => {
                        this.collapseAll();
                    },
                },
            ];
            if (this.selectedItem) {
                actions.push({
                    name: this.$t('services.newName'),
                    run: () => {
                        this.newService();
                    },
                });
                actions.push({
                    name: this.$t('services.newDirectory'),
                    run: () => {
                        this.newDirectory();
                    },
                });
            }
            if (this.selectedItem && this.selectedItem.__typename !== 'Bundle') {
                actions.push({
                    name: this.$t('services.removeService'),
                    run: () => {
                        this.onDelete(this.selectedItem);
                    },
                });
            }
            return actions;
        },
    },
    methods: {
        async onPaste(e) {
            const bundleId = this.$route.params.bundleId;
            if (bundleId && this.selectedItem && this.$refs.tree.$el.contains(e.target)) {
                const { index, parentDirectoryId } = this.getNewItemIndex();
                const services = importServices(e).map((name, idx) => ({
                    name,
                    unit: 'm²',
                    amount: 0,
                    parentDirectoryId,
                    index: index + idx,
                    isExecution: true,
                    reference: '',
                    bundleId,
                    predecessors: [],
                }));
                await this.createServiceBatch(services);
            }
        },
        onKeyUp(e) {
            if (this.selectedItem && e.target.nodeName === 'BODY' && (e.key === 'Backspace' || e.key === 'Delete')) {
                this.onDelete(this.selectedItem);
            }
        },
        expand(level) {
            if (level === 'all') {
                this.$refs.tree.expandAll();
            } else {
                this.$refs.tree.expandToLevel(parseInt(level));
            }
        },
        collapseAll() {
            for (const node of Object.values(this.nodeMap)) {
                node.isOpen = false;
            }
        },
        async onItemMoved(event) {
            const item = event.content;
            const newParentId = event.parentDirectoryId;
            const previousParentId = item.parentDirectoryId;
            const newIndex = event.index;
            const bundle = this.bundles.find((bundle) => bundle.id === item.bundleId);

            const newChildList = moveItem(
                getSortedChildren(bundle, newParentId),
                { ...item, parentDirectoryId: newParentId },
                newIndex,
            );

            let listToUpdate;
            if (previousParentId === newParentId) {
                listToUpdate = newChildList;
                await reOrder(this.$route.params.projectId, listToUpdate);
            } else {
                const oldChildList = getSortedChildren(bundle, previousParentId).filter(
                    (child) => child.id !== item.id,
                );
                await reOrder(this.$route.params.projectId, newChildList);
                await reOrder(this.$route.params.projectId, oldChildList);
            }
            this.selectedItem = this.getNode(item.id);
            expandTo(this.tree, item.id);
        },
        loadSelectedItemFromRoute() {
            const itemId =
                this.$route.params.serviceId || this.$route.params.directoryId || this.$route.params.bundleId;
            this.selectedItem = this.getNode(itemId);
            if (this.selectedItem && (this.$route.params.serviceId || this.$route.params.directoryId)) {
                expandTo(this.tree, itemId);
            }
        },
        getNode(id) {
            return this.nodeMap[id];
        },
        getNewItemIndex() {
            const directoryId = this.$route.params.directoryId;
            const serviceId = this.$route.params.serviceId;
            let index;
            let parentDirectoryId;
            if (serviceId) {
                index = this.selectedItem.index + 1;
                parentDirectoryId = this.selectedItem.parentDirectoryId;
            } else if (directoryId) {
                index = this.selectedItem.children.length;
                parentDirectoryId = directoryId;
            } else {
                index = this.selectedItem.children.length;
                parentDirectoryId = null;
            }
            return { index, parentDirectoryId };
        },
        async newDirectory() {
            const bundleId = this.$route.params.bundleId;
            const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
            const projectId = this.$route.params.projectId;
            const { index, parentDirectoryId } = this.getNewItemIndex();
            const newDirectory = await createDirectory(projectId, {
                bundleId,
                name: this.$t('services.newDirectory'),
                parentDirectoryId,
                index,
            });
            const newChildList = moveItem(
                getSortedChildren(bundle, parentDirectoryId),
                newDirectory,
                newDirectory.index,
            );
            await reOrder(this.$route.params.projectId, newChildList);

            await this.$router.push({
                name: 'serviceDirectoryInfo',
                params: { projectId, bundleId, directoryId: newDirectory.id },
            });
        },
        async newService(name = this.$t('services.newName')) {
            const projectId = this.$route.params.projectId;
            const bundleId = this.$route.params.bundleId;
            const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
            const { index, parentDirectoryId } = this.getNewItemIndex();
            const newService = await createService(projectId, {
                name,
                unit: 'm²',
                amount: 0,
                parentDirectoryId,
                index,
                isExecution: true,
                reference: '',
                bundleId,
                predecessors: [],
            });
            const newChildList = moveItem(getSortedChildren(bundle, parentDirectoryId), newService, newService.index);

            await reOrder(this.$route.params.projectId, newChildList);

            return this.$router.push({
                name: 'service',
                params: { projectId, bundleId, serviceId: newService.id },
            });
        },
        async createServiceBatch(services) {
            const projectId = this.$route.params.projectId;
            const bundleId = this.$route.params.bundleId;
            const bundle = this.bundles.find((bundle) => bundle.id === bundleId);
            const { index, parentDirectoryId } = this.getNewItemIndex();
            const newServices = (await bulkInsert(projectId, services)).success;
            let childList = getSortedChildren(bundle, parentDirectoryId);
            for (let idx = newServices.length - 1; idx >= 0; idx--) {
                childList = moveItem(childList, newServices[idx], index);
            }
            return reOrder(this.$route.params.projectId, childList);
        },
        async onSelect(item, navigate) {
            const bundleId = item.bundleId || item.id;
            this.selectedItem = item;
            if (navigate || !isMobile) {
                const projectId = this.$route.params.projectId;
                if (
                    item.__typename === 'Bundle' &&
                    (this.$route.name !== 'serviceBundleInfo' || this.$route.params.bundleId !== item.id)
                ) {
                    await this.$router.push({ name: 'serviceBundleInfo', params: { projectId, bundleId } });
                } else if (
                    item.__typename === 'Service' &&
                    (this.$route.name !== 'service' || this.$route.params.serviceId !== item.id)
                ) {
                    await this.$router.push({
                        name: 'service',
                        params: { projectId, bundleId, serviceId: item.id },
                    });
                } else if (
                    item.__typename === 'Directory' &&
                    (this.$route.name !== 'serviceDirectoryInfo' || this.$route.params.directoryId !== item.id)
                ) {
                    await this.$router.push({
                        name: 'serviceDirectoryInfo',
                        params: { projectId, bundleId, directoryId: item.id },
                    });
                }
            }
        },
        populateTree(bundles) {
            const serviceThis = this;
            return {
                name: '',
                children: bundles.map((bundle) => {
                    const node = {
                        ...bundle,
                        name: bundle.reference.toString().padStart(3, '0') + ' ' + bundle.name,
                        allowDropIn: null,
                        allowDropAmong: null,
                        isDraggable: false,
                        isOpen: this.nodeMap[bundle.id] ? this.nodeMap[bundle.id].isOpen : false,
                        children: getSortedChildren(bundle, null).map((child) => {
                            if (child.__typename === 'Service') {
                                const childNode = {
                                    ...child,
                                    name: getIndexPrefix(child) + ' ' + child.name,
                                    isDraggable: !isMobile && !serviceThis.readOnly,
                                    allowDropIn: null,
                                    hasWarning: child.hasWarning,
                                    hasLoopError: child.hasLoopError,
                                    isOpen: this.nodeMap[child.id] ? this.nodeMap[child.id].isOpen : false,
                                    allowDropAmong: (item) => couldDropTo(child, item),
                                };
                                this.nodeMap[child.id] = childNode;
                                return childNode;
                            } else {
                                return this.populateDirectory(bundle, child);
                            }
                        }),
                        hasWarning: bundle.services.find((service) => service.hasWarning),
                        hasLoopError: bundle.services.find((service) => service.hasLoopError),
                    };
                    this.nodeMap[bundle.id] = node;
                    return node;
                }),
            };
        },
        populateDirectory(bundle, directory, parentIndexPrefix = '') {
            const directoryIndexPrefix = getIndexPrefix(directory);
            const indexPrefix =
                parentIndexPrefix.length > 0 ? parentIndexPrefix + '.' + directoryIndexPrefix : directoryIndexPrefix;
            const node = {
                ...directory,
                name: indexPrefix + ' ' + directory.name,
                isDraggable: !isMobile && !this.readOnly,
                isOpen: this.nodeMap[directory.id] ? this.nodeMap[directory.id].isOpen : false,
                allowDropIn: (item) => couldDropTo(directory, item),
                allowDropAmong: (item) => couldDropTo(directory, item),
                children: getSortedChildren(bundle, directory.id).map((child) => {
                    if (child.__typename === 'Service') {
                        const node = {
                            ...child,
                            isOpen: this.nodeMap[child.id] ? this.nodeMap[child.id].isOpen : false,
                            name: indexPrefix + '.' + getIndexPrefix(child) + ' ' + child.name,
                            isDraggable: !isMobile && !this.readOnly,
                            allowDropIn: null,
                            allowDropAmong: (item) => couldDropTo(child, item),
                            hasWarning: child.hasWarning,
                            hasLoopError: child.hasLoopError,
                        };
                        this.nodeMap[child.id] = node;
                        return node;
                    } else {
                        return this.populateDirectory(bundle, child, indexPrefix);
                    }
                }),
            };
            node.hasWarning = node.children.find((child) => child.hasWarning);
            node.hasLoopError = node.children.find((child) => child.hasLoopError);
            this.nodeMap[directory.id] = node;
            return node;
        },

        async onDelete(selection) {
            if (!this.readOnly) {
                this.impacts = null;
                this.$refs.confirmPopup.open();
                this.impacts = await getCascadeImpacts(this.$route.params.projectId, selection);
            }
        },
        async onDeleteConfirmed() {
            if (!this.readOnly) {
                await removeCascade(this.$route.params.projectId, this.impacts);
                if (this.selectedItem.parentDirectoryId) {
                    await this.$router.push({
                        name: 'serviceDirectoryInfo',
                        params: {
                            projectId: this.$route.params.projectId,
                            bundleId: this.selectedItem.bundleId,
                            parentDirectoryId: this.selectedItem.parentDirectoryId,
                        },
                    });
                } else {
                    await this.$router.push({
                        name: 'serviceBundleInfo',
                        params: { projectId: this.$route.params.projectId, bundleId: this.selectedItem.bundleId },
                    });
                }
            }
        },
    },
};
</script>
