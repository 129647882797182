import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';
let insertCounter = -100;
export const observations = {
    schema: {
        title: 'observation schema',
        version: 4,
        description: 'describes a observation',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                format: 'uuid',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'], format: 'uuid' },
            phase: { type: ['string', 'null'] },
            title: { type: ['string', 'null'] },
            description: { type: ['string', 'null'] },
            dueDate: { type: ['string', 'null'], format: 'date-time' },
            type: { type: ['string', 'null'] },
            roomId: { type: ['string', 'null'], format: 'uuid' },
            supportId: { type: ['string', 'null'], format: 'uuid' },
            relatedContentId: { type: ['string', 'null'], format: 'uuid' },
            page: { type: ['number', 'null'] },
            footprint: { type: ['object', 'null'] },
            zoneId: { type: ['string', 'null'], format: 'uuid' },
            index: { type: ['number', 'null'] },
            recipientIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                    format: 'uuid',
                },
            },
            relatedContentType: { type: ['string', 'null'] },
            preparationDocumentId: { type: ['string', 'null'], format: 'uuid' },

            relatedPreparationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                    format: 'uuid',
                },
            },
            relatedObservationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                    format: 'uuid',
                },
            },

            obsoleteAt: { type: ['string', 'null'] },
            obsoleteBy: { type: ['string', 'null'], format: 'uuid' },
            obsoleteComment: { type: ['string', 'null'] },

            reportedAt: { type: ['string', 'null'], format: 'date-time' },
            reportedBy: { type: ['string', 'null'], format: 'uuid' },
            reportedOnPhase: { type: ['string', 'null'] },

            resolvedBy: { type: ['string', 'null'], format: 'uuid' },
            resolvedAt: { type: ['string', 'null'], format: 'date-time' },
            resolvedOnPhase: { type: ['string', 'null'] },

            validatedAt: { type: ['string', 'null'], format: 'date-time' },
            validatedBy: { type: ['string', 'null'], format: 'uuid' },
            validatedOnPhase: { type: ['string', 'null'] },

            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'], format: 'uuid' },
            updatedBy: { type: ['string', 'null'], format: 'uuid' },
            deletedBy: { type: ['string', 'null'], format: 'uuid' },
            rootVersion: { type: 'string', format: 'date-time' },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert((doc) => {
                doc.index = doc.index || insertCounter--;
                preInsert(doc);
            }, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => {
            const newDoc = {
                ...doc,
                reportedBy: doc.emitterId,
                resolvedAt: doc.realEndDate,
                reportedOnPhase: doc.phase,
            };
            delete newDoc.emitterId;
            delete newDoc.realEndDate;
            delete newDoc.isValidated;
            return newDoc;
        },
        2: (doc) => doc,
        3: (doc) => doc,
        4: (doc) => doc,
    },
};
