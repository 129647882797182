<template>
    <div class="block app-text-editor">
        <div class="flex flex-col w-full h-full">
            <div class="flex w-full items-end h-full">
                <div class="flex flex-col w-full h-full">
                    <app-label :showLabel="showLabel" :label="label" :required="required" :label-for="uid"></app-label>
                    <ValidationProvider
                        :rules="rules"
                        v-slot="{ errors, classes }"
                        ref="root"
                        class="w-full validated h-full"
                    >
                        <vue-editor
                            :id="uid"
                            :disabled="disabled"
                            :useCustomImageHandler="true"
                            @image-added="handleImageAdded"
                            :name="label"
                            :class="{ ...classes, 'no-toolbar': !showToolbar, inline }"
                            class="outline-none shadow"
                            ref="input"
                            :value="value"
                            :editor-toolbar="customToolbar"
                            :editorOptions="editorOptions"
                            :customModules="customModules"
                            @input="$emit('input', safeHTML($event))"
                            @blur="onBlur"
                            @focus="onFocus"
                        ></vue-editor>
                    </ValidationProvider>
                </div>
                <slot name="companion"></slot>
            </div>
            <slot name="tip"></slot>
        </div>
        <app-errors :errors="errors" v-if="showErrors" />
    </div>
</template>
<script>
import AppErrors from '../appErrors/AppErrors';
import AppLabel from '../appLabel/AppLabel';
import { ValidationProvider } from 'vee-validate';
import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js';
import 'vue2-editor/dist/vue2-editor.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import safeHtml from 'safe-html';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { getAPIHeaders } from '@/services/api.service';
export default {
    mounted() {
        // https://github.com/quilljs/quill/issues/2186
        this.$refs.input.quill.editor.scroll.domNode.addEventListener('blur', (e) => {
            if (!e.relatedTarget /* before paste event case*/) {
                this.onBlur(e);
            }
        });
        window.addEventListener('mousedown', this.listenClick);
    },
    beforeDestroy() {
        window.removeEventListener('mousedown', this.listenClick);
    },
    methods: {
        onFocus() {
            this.hasFocus = true;
        },
        listenClick(event) {
            if (this.hasFocus && !this.$refs.root.$el.contains(event.target)) {
                this.onBlur();
            }
        },
        onBlur(event) {
            this.hasFocus = false;
            this.$emit('blur', event);
        },
        getText() {
            return this.$refs.input.quill.getText();
        },
        handleImageAdded: async function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData from https://github.com/davidroyer/vue2-editor
            if (!this.endPoint) {
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const result = await fetch(`/api/files/${this.$route.params.projectId}/${this.endPoint}`, {
                method: 'POST',
                body: formData,
                headers: getAPIHeaders(),
            });

            const url = (await result.json()).url;
            if (Editor) {
                Editor.insertEmbed(cursorLocation, 'image', url);
                resetUploader();
            } else {
                const quill = this.$refs.input.quill;
                let index = (quill.getSelection() || {}).index;
                if (index === undefined || index < 0) index = quill.getLength();
                quill.insertEmbed(index, 'image', url, 'user');
            }
            this.$emit('blur');
        },
        setErrors(errors) {
            this.errors = errors;
        },
        focus() {
            this.$refs.input.quill.setSelection(0, this.value.length);
            this.$refs.input.quill.focus();
        },
        scrollTo() {
            this.$refs.input.scrollTo();
        },
        select() {
            this.$refs.input.select();
        },
        safeHTML(html) {
            const allowImage = !!this.endPoint;
            return safeHtml(html.replaceAll('<li>', '- ').replaceAll('</li>', ''), {
                allowedTags: [
                    'p',
                    's',
                    'img',
                    'strong',
                    'u',
                    'em',
                    'ol',
                    'span',
                    'div',
                    'b',
                    'i',
                    'a',
                    'table',
                    'thead',
                    'tbody',
                    'tr',
                    'td',
                    'th',
                ],
                allowedAttributes: {
                    style: {
                        allTags: true,
                    },
                    class: {
                        allTags: true,
                    },
                    src: {
                        allowedTags: ['img'],
                        filter: function (value) {
                            if (value.startsWith('/api/file') && allowImage) {
                                return value;
                            } else return '';
                        },
                    },
                    href: {
                        allowedTags: ['a'],
                        filter: function (value) {
                            if (value.startsWith('http://') || value.startsWith('https://')) {
                                return value;
                            } else return '';
                        },
                    },
                    download: {
                        allowedTags: ['a'],
                    },
                },
            });
        },
        insert(text) {
            const index = this.$refs.input.quill.getSelection()?.index || this.value.length;
            this.$refs.input.quill.insertText(index, text);
            this.$refs.input.quill.setSelection(index + text.length);
        },
        insertEmbed(index, type, value) {
            const result = this.$refs.input.quill.insertEmbed(index, type, value, 'user');
            this.$refs.input.quill.setSelection(index);
            return result;
        },
        getIndex() {
            return this.$refs.input.quill.getSelection()?.index || this.value.length;
        },
    },
    components: { AppLabel, AppErrors, ValidationProvider, VueEditor },
    data() {
        return {
            hasFocus: false,
            errors: [],
            customModules: [{ alias: 'imageDropAndPaste', module: QuillImageDropAndPaste }],
            editorOptions: {
                placeholder: this.placeholder,
                modules: {
                    resize: {
                        locale: {
                            altTip: "Maintenez la touche alt enfoncée pour mettre à l'échelle",
                            inputTip: '',
                            floatLeft: 'aligner à gauche',
                            floatRight: 'aligner à droite',
                            center: 'centrer',
                            restore: 'reset',
                        },
                    },
                    imageDropAndPaste: {
                        // add an custom image handler
                        handler: (imageDataUrl, type, imageData) => this.handleImageAdded(imageData.toFile()),
                    },
                },
                formats: [
                    'background',
                    'color',
                    'bold',
                    'header',
                    'size',
                    'underline',
                    'script',
                    'strike',
                    'italic',
                    'link',
                    'blockquote',
                    'image',
                    'table',
                ],
            },
            customToolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [
                    {
                        color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff'],
                    },
                    {
                        background: [
                            '#000000',
                            '#ffffff',
                            '#e60000',
                            '#ff9900',
                            '#ffff00',
                            '#008a00',
                            '#0066cc',
                            '#9933ff',
                            '#f06666',
                            '#ffc266',
                            '#ffff66',
                            '#66b966',
                            '#66a3e0',
                            '#c285ff',
                            '#888888',
                        ],
                    },
                ],
                ['clean'],
                ['image'],
                ['link'],
            ],
        };
    },
    props: {
        showToolbar: Boolean,
        endPoint: String,
        inline: Boolean,
        rules: String,
        placeholder: String,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: String,
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        uid: {
            type: String | Number,
            default: function () {
                return Math.random().toString().substring(2);
            },
        },
    },
};
</script>
<style>
.app-input-text .validated input:disabled {
    box-shadow: none;
    opacity: 0.5;
}
.app-input-text .validated input.invalid {
    border-color: red;
    border-width: 2px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #e5e7eb;
}
.ql-editor:focus-within {
    @apply ring-2 shadow;
}
.ql-container {
    @apply shadow;
}
.no-toolbar .ql-toolbar {
    display: none;
}
.inline .ql-editor {
    min-height: inherit;
    padding: 5px 11px;
    display: flex;
    flex-direction: column;
}
</style>
