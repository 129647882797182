<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
            <div class="flex gap-2">
                <span class="text-lg">{{ autoControl.code }}</span>
                <span class="text-xl font-bold">{{ autoControl.name }}</span>
            </div>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('autoControls.emissionDueDate') }} :</th>
                    <td class="p-2">{{ autoControl.emissionDueDate | humanizeDate }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('autoControls.emissionDate') }} :</th>
                    <td class="p-2">{{ autoControl.emissionDate | humanizeDate }}</td>
                </tr>
                <tr v-if="autoControl.bundle">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.emitter') }} :</th>
                    <td class="p-2"><app-bundle :bundle="autoControl.bundle" /></td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('autoControls.service') }} :</th>
                    <td class="p-2">
                        <div v-for="service in autoControl.services">
                            {{ service.name }}
                        </div>
                        <div v-if="autoControl.services.length === 0">{{ $t('autoControls.AllServices') }}</div>
                    </td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('autoControls.locations') }} :</th>
                    <td class="p-2">
                        <div v-for="location in autoControl.locations">
                            {{ location.fullName }}
                        </div>
                        <div v-if="autoControl.locations.length === 0">{{ $t('autoControls.AllLocations') }}</div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import locationService from '@/services/location.service';
import { getAutoControl } from './autoControls.service';
import AppBundle from '@/components/app-bundle/appBundle';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getServices } from '@/features/services/services.service';
import { getMapById } from '@/services/sanitize.service';
export default {
    components: {
        AppBundle,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                    getAutoControl(this.$route.params.projectId, this.$route.params.autoControlId),
                    getServices(this.$route.params.projectId),
                ]).subscribe(([bundleMap, folders, autoControl, services]) => {
                    const serviceMap = getMapById(services);
                    const locationMap = locationService.getLocationMap(folders);
                    this.autoControl = {
                        ...autoControl,
                        bundle: bundleMap[autoControl.bundleId],
                        locations: autoControl.locationIds.map((id) => locationMap[id]),
                        services: autoControl.serviceIds.map((serviceId) => serviceMap[serviceId]),
                    };
                }),
            ];
        },
    },
    data() {
        return {
            subscriptions: [],
            autoControl: {
                name: this.$t('autoControls.newName'),
                bundle: null,
                code: '',
                services: [],
                locations: [],
                emissionDueDate: null,
                emissionDate: null,
                observations: [],
            },
        };
    },
};
</script>
