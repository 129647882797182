<template>
    <div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer" class="mb-5">
            <form>
                <app-fieldset :label="$t('project.follow.agenda.title')" class="mb-2">
                    <app-select
                        :label="$t('project.follow.agenda.officialAgenda')"
                        v-model="selectedOfficialAgendaId"
                        @input="onOfficialAgendaChanged"
                        class="col-span-2"
                        :disabled="readOnly || !isOnline"
                    >
                        <option :value="agenda.id" :key="agenda.id" v-for="agenda in agendas">{{ agenda.name }}</option>
                    </app-select>
                </app-fieldset>
            </form>
        </ValidationObserver>
        <Exceptions :disabled="readOnly"></Exceptions>
    </div>
</template>

<script>
import AppSelect from '../../../components/appSelect/AppSelect';
import AppFieldset from '../../../components/appFieldset/AppFieldset';
import Exceptions from './Exceptions';
import { getProject, updateProject } from '@/features/projects/projects.service';
import { getAPIHeaders } from '@/services/api.service';
import addToCache from '@/services/cache.service';
import { networkStatus } from '@/state/state';
export default {
    components: {
        Exceptions,
        AppFieldset,
        AppSelect,
    },
    data() {
        return {
            selectedOfficialAgendaId: null,
            agendas: [],
            readOnly: true,
            isOnline: true,
            subscriptions: [],
        };
    },
    async created() {
        this.agendas = await this.getAgendas(this.$route.params.projectId);
        this.subscriptions = [
            getProject(this.$route.params.projectId).subscribe((project) => {
                this.readOnly = !project.me.allowedFeatures.includes('project_planning');
                this.selectedOfficialAgendaId = project.officialAgendaId;
            }),
            networkStatus.subscribe((isOnline) => (this.isOnline = isOnline)),
        ];
    },
    methods: {
        async getAgendas() {
            const result = await fetch('/api/agendas', {
                headers: getAPIHeaders(),
            });
            addToCache(['/api/agendas']);
            return result.json();
        },
        onOfficialAgendaChanged(officialAgendaId) {
            updateProject({
                id: this.$route.params.projectId,
                officialAgendaId,
            });
        },
    },
};
</script>
