<template>
    <div class="flex flex-col gap-4" v-if="project">
        <div class="flex gap-4 shadow-md border p-4">
            <div>
                <img
                    ref="image"
                    class="object-contain"
                    :src="project.imageUrl || '/defaultImage.svg'"
                    :alt="$t('project.information.generals.imageAlt')"
                />
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex gap-2">
                    <span class="text-lg">{{ project.code }}</span>
                    <span class="text-xl font-bold">{{ project.name }}</span>
                </div>
                <div>
                    <p>{{ project.address1 }}</p>
                    <p v-if="project.address2">{{ project.address2 }}</p>
                    <p v-if="project.address3">{{ project.address3 }}</p>
                    <p>{{ project.postalCode }} {{ project.city }}</p>
                </div>
            </div>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('project.information.generals.type') }} :</th>
                    <td class="p-2">{{ project.type }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">
                        {{ $t('project.information.generals.typeOfWork') }} :
                    </th>
                    <td class="p-2">{{ project.typeOfWork }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('commons.phase') }} :</th>
                    <td class="p-2">
                        <span>{{ project.phase ? $t('commons.phases.' + project.phase) : '' }}</span>
                    </td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">
                        {{ $t('project.information.generals.startDate') }} :
                    </th>
                    <td class="p-2">{{ project.startDate | humanizeDate }}</td>
                </tr>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('project.information.generals.endDate') }} :</th>
                    <td class="p-2">{{ project.endDate | humanizeDate }}</td>
                </tr>
            </table>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['project'],
};
</script>
