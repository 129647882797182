<template>
    <app-number-input
        :label="label"
        :showErrors="showErrors"
        :showLabel="showLabel"
        :disabled="disabled"
        :required="required"
        :value="value"
        :size="size"
        :rules="rules"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
        @keypress="$emit('keypress', $event)"
        format="integer"
    >
        <template v-slot:link>
            <div class="" v-if="referenceDate">
                <label>
                    <span class="hidden">{{ $t('commons.durationPickerButton') }}</span>
                    <div style="max-height: 2.5rem">
                        <IconCalendar
                            width="16"
                            height="16"
                            class="absolute text-gray-600"
                            :title="$t('commons.durationPickerButton')"
                            style="margin-left: 50%; top: 50%; transform: translate(-50%, -50%)"
                        />
                        <input
                            class="opacity-0"
                            :class="{ 'cursor-pointer': !disabled }"
                            style="max-width: 50px; max-height: 2.5rem"
                            ref="datePicker"
                            tabindex="-1"
                            :disabled.prop="disabled"
                            type="date"
                            v-model="dateModel"
                            @input="onDateInput"
                            :min="referenceDate.toISOString().substring(0, 10)"
                        />
                    </div>
                </label>
            </div>
        </template>
        <template v-slot:tip>
            <div class="text-xs text-gray-700 italic mx-2">
                <span v-if="!backward && referenceDate && dateValue">
                    {{ referenceDate | humanizeDate }} + {{ value }}J &rarr;
                    <b>{{ dateValue | humanizeDate }}</b>
                </span>
                <span v-else-if="backward && referenceDate && dateValue">
                    {{ referenceDate | humanizeDate }} - {{ value }}J &rarr;
                    <b>{{ dateValue | humanizeDate }}</b>
                </span>
                <span v-else>&nbsp;</span>
            </div>
        </template>
    </app-number-input>
</template>
<script>
import AppDateInput from '../appDateInput/AppDateInput';
import AppNumberInput from '../appNumberInput/AppNumberInput';
import IconCalendar from '../../icons/IconCalendar';
import AppButton from '../appButton/AppButton';
import isValid from 'date-fns/isValid';
import { getDuration, applyDuration } from '@/services/duration.service';
export default {
    components: { AppButton, AppNumberInput, AppDateInput, IconCalendar },
    data() {
        return {
            dateValue: null,
        };
    },
    created() {
        this.update();
    },
    computed: {
        dateModel: {
            set(value) {
                if (value) {
                    this.dateValue = new Date(value);
                }
            },
            get() {
                return this.dateValue ? this.dateValue.toISOString().substring(0, 10) : null;
            },
        },
    },
    watch: {
        referenceDate() {
            this.update();
        },
        value() {
            this.update();
        },
    },
    methods: {
        update() {
            if (this.value && isValid(this.value) && this.referenceDate) {
                if (this.backward) {
                    this.dateValue = applyDuration(this.referenceDate, -this.value, this.agenda, this.bundleId);
                } else {
                    this.dateValue = applyDuration(this.referenceDate, this.value, this.agenda, this.bundleId);
                }
            }
        },
        onDateInput($event) {
            if (!this.backward && $event.target.valueAsDate > this.referenceDate) {
                this.$emit(
                    'input',
                    getDuration($event.target.valueAsDate, this.referenceDate, this.agenda, this.bundleId),
                );
            } else if (this.backward && $event.target.valueAsDate < this.referenceDate) {
                this.$emit(
                    'input',
                    getDuration(this.referenceDate, $event.target.valueAsDate, this.agenda, this.bundleId),
                );
            }
        },
    },
    props: {
        referenceDate: Date | String,
        backward: {
            type: Boolean,
            default: false,
        },
        size: String,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: Number | String,
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        rules: {
            type: String,
            default: 'min_value:1|min:1',
        },
        agenda: {
            type: Array,
            default() {
                return [];
            },
        },
        bundleId: String,
    },
};
</script>
