<template>
    <div class="w-full mb-20 p-2 sm:p-4">
        <div class="flex flex-col items-center justify-center w-full text-xs flex-wrap gap-4 mt-4">
            <dashboard-burn-down v-if="project_EXE" />
            <dashboard-emission-bars :selected-bundles="bundles"></dashboard-emission-bars>
            <dashboard-delays-comparison-bars
                :bundles="bundles"
                v-if="project_EXE"
                class="max-w-5xl"
            ></dashboard-delays-comparison-bars>
        </div>
    </div>
</template>

<script>
import DashboardBurnDown from '@/features/dashboards/DashboardBurnDown';
import AppSelect from '@/components/appSelect/AppSelect';
import DashboardEmissionBars from '@/features/dashboards/DashboardEmissionBars';
import DashboardDelaysComparisonBars from './DashboardDelaysComparisonBars';
import { getBundles } from '@/features/bundles/bundles.service';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        DashboardDelaysComparisonBars,
        DashboardEmissionBars,
        AppSelect,
        DashboardBurnDown,
    },
    async created() {
        this.subscriptions = [
            getBundles(this.$route.params.projectId).subscribe((bundles) => (this.bundles = bundles)),
            getProject(this.$route.params.projectId).subscribe((project) => {
                this.project_EXE = project.projectFeatures.includes('project_EXE');
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            bundles: [],
            references: [],
            project_EXE: false,
        };
    },
};
</script>
