<template>
    <div class="flex flex-col grid app-bar-chart" style="grid-template-columns: max-content auto max-content">
        <template v-for="serie in series">
            <div class="p-1 my-2 abc-label">{{ serie.label }}</div>
            <div class="flex flex-grow border-black border my-2">
                <div
                    class="flex-grow flex justify-center items-center"
                    v-for="(value, index) in serie._values"
                    :style="{ width: value + '%' }"
                    :class="data.options.classes[index]"
                >
                    <span
                        class="abc-value"
                        :class="serie.values[index] === 0 ? 'hidden' : 'block'"
                        :title="serie.values[index] + ' = ' + Math.round(value) + '%'"
                    >
                        {{ serie.values[index] }}
                    </span>
                </div>
            </div>
            <div v-if="serie.companionLabel" class="flex items-center ml-2 my-2">
                {{ serie.companionLabel }}
            </div>
            <div v-else></div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default() {
                return {
                    series: [
                        { label: 'Serie 1', values: [5, 10, 40], companionLabel: 5 + 10 + '%' },
                        { label: 'Serie 2', values: [2, 5, 65] },
                    ],
                    options: {
                        normalize: true,
                        classes: ['bg-green-500', 'bg-yellow-500', 'bg-gray-100'],
                    },
                };
            },
        },
    },
    methods: {
        normalizeSeriesValues(series) {
            return series
                .map((serie) => {
                    const localSum = serie.values.reduce((acc, value) => value + acc, 0);
                    if (localSum > 0) {
                        return {
                            ...serie,
                            _values: serie.values.map((value) => (value / localSum) * 100),
                            localSum,
                        };
                    } else {
                        return {
                            ...serie,
                            _values: [100],
                            localSum,
                        };
                    }
                })
                .filter((serie) => serie.localSum !== 0);
        },
        compareSeriesValues(series) {
            const globalMax = series
                .map((acc, serie) => serie.values.reduce((acc, value) => (value > acc ? value : acc), Number.MIN_VALUE))
                .reduce((acc, value) => (value > acc ? value : acc), Number.MIN_VALUE);
            return series.map((serie) => ({
                ...serie,
                _values: serie.values.map((value) => (value / globalMax) * 100),
            }));
        },
    },
    computed: {
        series() {
            if (this.data.options.normalize) {
                return this.normalizeSeriesValues(this.data.series);
            } else {
                return this.compareSeriesValues(this.data.series);
            }
        },
    },
};
</script>
