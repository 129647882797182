<template>
    <div
        :class="classes"
        class="marker"
        :style="size === 'mini' ? 'min-height: 25px; min-width: 25px;' : 'min-height: 30px; min-width: 30px;'"
    >
        <div>{{ observation.index + 1 }}</div>
    </div>
</template>

<script>
import { getObservationClass } from '@/features/observations/observation.service';

export default {
    props: {
        observation: { type: Object },
        size: { type: String },
    },
    computed: {
        classes() {
            return getObservationClass(this.observation);
        },
    },
};
</script>
<style>
.marker {
    position: relative;
    padding: 2px;

    border-radius: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.marker:before {
    content: '\25BC';
    position: absolute;
    top: calc(100% - 0.5em);
}
</style>
