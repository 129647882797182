export function computeCycleDetection(tasks, taskMapById) {
    //prepare trajan by adding successors
    addSuccessors(tasks, taskMapById);
    // apply trajan algo to find groups and filter to get loops among groups
    const cycles = filterCycles(tarjan(tasks));
    //Add errors to tasks part of cycles
    for (const cycle of cycles) {
        for (const task of cycle) {
            addCycleError(task);
        }
    }
    return cycles;
}
export function addSuccessors(tasks, taskMapById) {
    const successorsByTaskId = {};
    for (const task of tasks) {
        for (const predecessor of task.allPredecessors) {
            if (successorsByTaskId[predecessor.taskId]) {
                successorsByTaskId[predecessor.taskId].push(taskMapById[task.id]);
            } else {
                successorsByTaskId[predecessor.taskId] = [taskMapById[task.id]];
            }
        }
    }
    for (const task of tasks) {
        task.successors = successorsByTaskId[task.id] || [];
    }
    return tasks;
}

export function filterCycles(cycles) {
    return cycles.filter(
        (cycle) =>
            cycle.length > 1 || !!cycle[0].allPredecessors.find((predecessor) => predecessor.taskId === cycle[0].id),
    );
}

// implementation from https://github.com/dagrejs/graphlib/blob/master/lib/alg/tarjan.ts
export function tarjan(tasks) {
    let index = 0;
    let stack = [];
    let visited = new Map();
    let results = [];

    function dfs(v) {
        let entry = {
            onStack: true,
            lowlink: index,
            index: index++,
        };
        visited.set(v.id, entry);
        stack.push(v);

        for (const successor of v.successors) {
            if (!visited.has(successor.id)) {
                dfs(successor);
                entry.lowlink = Math.min(entry.lowlink, visited.get(successor.id).lowlink);
            } else if (visited.get(successor.id).onStack) {
                entry.lowlink = Math.min(entry.lowlink, visited.get(successor.id).index);
            }
        }
        if (entry.lowlink === entry.index) {
            let cmpt = [];
            let w;
            do {
                w = stack.pop();
                visited.get(w.id).onStack = false;
                cmpt.push(w);
            } while (v !== w);
            results.push(cmpt);
        }
    }

    tasks.forEach(function (v) {
        if (!visited.has(v.id)) {
            dfs(v);
        }
    });
    return results;
}

export function addCycleError(task) {
    task.isError = true;
    task.errorMessage = 'predecessor-loop';
}
