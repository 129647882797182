export function moveItem(array, item, index) {
    const result = array.filter((a) => a.id !== item.id);
    result.splice(index, 0, item);
    return result;
}
export function sortByIndex(a, b) {
    if (a.index === null && b.index === null) {
        return 0;
    } else if (a.index === null) {
        return 1;
    } else if (b.index === null) {
        return -1;
    } else {
        return a.index - b.index;
    }
}
export function applyIndex(array) {
    return array.map((item, index) => ({ ...item, index: index }));
}

export function collapseAll(node) {
    node.isOpen = false;
    if (node.children) {
        node.children.forEach((child) => collapseAll(child));
    }
}

export function expandTo(node, nodeId) {
    if (node.children) {
        if (node.children.find((child) => child.id === nodeId)) {
            node.isOpen = true;
            return true;
        }
        const isInPath = node.children.find((child) => expandTo(child, nodeId));
        if (isInPath) {
            node.isOpen = true;
            return true;
        }
    }
    return false;
}

export function couldDropTo(item, itemToDrop) {
    return (
        itemToDrop.id !== item.id &&
        itemToDrop.bundleId === item.bundleId &&
        (itemToDrop.__typename === 'Service' || itemToDrop.__typename === 'Directory')
    );
}

export function getSortedChildren(bundle, parentId) {
    return [
        ...bundle.services
            .filter((service) => service.parentDirectoryId === parentId)
            .map((service) => ({ ...service, __typename: 'Service' })),
        ...bundle.directories
            .filter((directory) => directory.parentDirectoryId === parentId)
            .map((service) => ({ ...service, __typename: 'Directory' })),
    ].sort(sortByIndex);
}

export function getIndexPrefix(node) {
    return (node.index + 1 + '').padStart(2, '0');
}
