import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const observationAttachments = {
    schema: {
        title: 'observation Attachments  schema',
        version: 1,
        description: 'describes an observation Attachments',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            name: {
                type: ['string', 'null'],
            },
            url: {
                type: ['string', 'null'],
            },
            dataUrl: {
                type: ['string', 'null'],
            },
            pageCount: {
                type: ['number', 'null'],
            },
            shapes: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: ['string', 'null'],
            },
            observationId: {
                type: 'string',
                maxLength: 40,
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
        indexes: ['observationId'],
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId, {
                cacheURLsFn: (documents) =>
                    documents.filter((document) => document.url).map((document) => document.url),
            });
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => doc,
    },
};
