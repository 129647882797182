export async function getToken(email, password) {
    let headers = new Headers();
    headers.append('Authorization', `Basic ${btoa(email + ':' + password)}`);
    headers.append('Accept', `application/json`);
    const response = await fetch(
        new Request('/api/token', {
            method: 'GET',
            headers: headers,
        }),
    );
    if (response.ok) {
        const { token } = await response.json();
        const cguApprovalRequired = response.headers.get('x-cgu-approval-required') === 'true';
        return { token, cguApprovalRequired };
    } else if (response.status >= 500) {
        throw new Error('Error 504');
    } else {
        return null;
    }
}
export function getLoggedUser() {
    const tokenValue = localStorage.getItem('token');
    return parseToken(tokenValue);
}
export function parseToken(tokenValue) {
    if (tokenValue) {
        const base64Url = tokenValue.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );
        return JSON.parse(jsonPayload);
    } else {
        return null;
    }
}
