<template>
    <div>
        <div class="flex justify-between flex-col-reverse sm:flex-row sm:items-center">
            <div class="flex sm:items-center flex-col sm:flex-row">
                <table class="mx-2">
                    <tr>
                        <th scope="row" class="text-right text-sm whitespace-nowrap">{{ $t('commons.version') }} :</th>
                        <td class="px-3">
                            <app-select
                                :label="$t('commons.version')"
                                :value="reception.id"
                                class=""
                                :show-label="false"
                                :disabled="!reception.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in reception.versions"
                                    :key="version.id"
                                >
                                    {{ index + 1 }} ({{ (version.emissionDate || version.createdAt) | humanizeDate }})
                                </option>
                            </app-select>
                        </td>
                    </tr>
                </table>

                <div class="m-2 sm:my-0">
                    <app-button @click="onNewVersion" :disabled="!reception.id">
                        {{ $t('commons.newVersion') }}
                    </app-button>
                </div>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="reception.name"
                        class="col-span-2 md:col-span-1"
                        @blur="save()"
                        :required="true"
                    />
                    <app-input-text
                        :label="$t('receptions.code')"
                        @blur="save()"
                        v-model="reception.code"
                        class="col-span-2 md:col-span-1"
                    />
                    <app-select :label="$t('receptions.conclusion')" v-model="reception.conclusion" @blur="save()">
                        <option value="">{{ $t('receptions.conclusions.pending') }}</option>
                        <option value="approvedWithComments">
                            {{ $t('receptions.conclusions.approvedWithObservations') }}
                        </option>
                        <option value="approved">{{ $t('receptions.conclusions.approved') }}</option>
                        <option value="rejected">{{ $t('receptions.conclusions.rejected') }}</option>
                        <option value="tacitApproval">{{ $t('receptions.conclusions.tacitApproval') }}</option>
                    </app-select>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('receptions.perimeter')">
                    <app-bundle-picker
                        class="col-span-2"
                        v-model="reception.bundle"
                        :options="bundles"
                        @input="onBundleChange"
                    ></app-bundle-picker>

                    <ValidationProvider v-slot="{ errors, classes }" class="col-span-2">
                        <app-picker
                            :disabled="!reception.bundle"
                            v-model="reception.service"
                            :options="services"
                            :show-label="true"
                            @input="onServiceChange"
                            :label="$t('receptions.service')"
                            :placeholder="$t('commons.all')"
                            :title="$t('receptions.chooseService')"
                        ></app-picker>
                        <app-errors :errors="errors" />
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors, classes }" class="col-span-2">
                        <app-multi-picker
                            :disabled="!reception.bundle"
                            :placeholder="$t('receptions.AllLocations')"
                            v-model="reception.locations"
                            @input="save()"
                            :options="locations"
                            :show-label="true"
                            :label="$t('receptions.locations')"
                            :title="$t('receptions.chooseLocations')"
                            label-key="fullName"
                        ></app-multi-picker>
                        <app-errors :errors="errors" />
                    </ValidationProvider>
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-5" :label="$t('receptions.productionDates')">
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="reception.emissionDueDate"
                        @input="save()"
                        :label="$t('receptions.emissionDueDate')"
                        :warning="reception.emissionDueDate > recommendedEmissionDate"
                    ></app-date-input>

                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="reception.emissionDate"
                        @input="save()"
                        :label="$t('receptions.emissionDate')"
                    />
                    <div
                        class="col-span-2 flex items-center px-2 text-xs border"
                        v-if="firstTask && recommendedEmissionDate"
                        :class="reception.emissionDueDate > recommendedEmissionDate ? 'bg-yellow-100' : 'bg-green-50'"
                    >
                        {{ $t('receptions.recommendedDate') }}
                        <app-button @click="applyRecommendedDate()" size="mini" variant="none" class="m-1">
                            {{ recommendedEmissionDate | humanizeDate }}
                        </app-button>
                    </div>
                </app-fieldset>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        </ValidationObserver>
    </div>
</template>

<script>
import { getMapById, sanitize, sortBy, uniqBy } from '@/services/sanitize.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppWarnOnLeave from '../../components/AppWarnOnLeave';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppButton from '../../components/appButton/AppButton';
import { getFirstExecutionTask, getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import AppBundlePicker from '../../components/appBundlePicker';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import { createReception, getReception, updateReception } from './receptions.service';
import { applyDelay } from '@/services/duration.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { getServices } from '@/features/services/services.service';
import { getTasks } from '@/features/tasks/tasks.service';
export default {
    components: {
        AppSaveOnLeave,
        AppMultiPicker,
        AppBundlePicker,
        AppButton,
        AppPicker,
        AppDateInput,
        AppErrors,
        AppWarnOnLeave,
        AppFieldset,
        AppInputText,
        AppSelect,
    },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.warnOnLeave.beforeRouteLeave(
            () => this.save(),
            () => {},
            next,
        );
    },
    computed: {
        services() {
            if (this.reception.bundle) {
                return uniqBy(
                    this.tasks
                        .filter((task) => task.service.bundleId === this.reception.bundle.id)
                        .map((task) => this.serviceMap[task.serviceId]),
                    'id',
                );
            } else {
                return [];
            }
        },
        locations() {
            if (this.reception.bundle) {
                return this.getAllowedLocation(this.reception.serviceId);
            } else {
                return [];
            }
        },
        firstTask() {
            let firstTask = null;
            if (this.planning) {
                firstTask = getFirstExecutionTask(
                    this.planning,
                    this.reception.bundle,
                    this.reception.service ? [this.reception.service] : [],
                    this.reception.locations,
                );
                this.recommendedEmissionDate = this.getRecommendedEmissionDate(firstTask);
            }
            return firstTask;
        },
    },
    methods: {
        getAllowedLocation(serviceId) {
            let bundleTasks = this.tasks.filter((task) => serviceId === task.serviceId);
            return uniqBy(bundleTasks, 'locationId').map((task) => this.locationMap[task.locationId]);
        },
        init() {
            this.subscriptions = [
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())).subscribe((planning) => {
                    this.planning = planning;
                }),
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId).pipe(
                        map((folders) => locationService.getLocationMap(folders)),
                    ),
                    getReception(this.$route.params.projectId, this.$route.params.receptionId),
                    getServices(this.$route.params.projectId).pipe(map((services) => getMapById(services))),
                    getTasks(this.$route.params.projectId),
                ]).subscribe(([bundleMap, locationMap, reception, serviceMap, tasks]) => {
                    this.serviceMap = serviceMap;
                    this.tasks = tasks
                        .map((task) => ({ ...task, service: this.serviceMap[task.serviceId] }))
                        .filter((task) => task.service);
                    this.taskMapByServiceId = this.tasks.reduce(
                        (acc, task) => ({
                            ...acc,
                            [task.serviceId]: acc[task.serviceId] ? [...acc[task.serviceId], task] : [task],
                        }),
                        {},
                    );
                    this.locationMap = locationMap;
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.reception = {
                        ...reception,
                        bundle: bundleMap[reception.bundleId],
                        locations: reception.locationIds.map((id) => this.locationMap[id]),
                        service: this.serviceMap[reception.serviceId],
                    };
                }),
            ];
        },
        applyRecommendedDate() {
            updateReception(this.$route.params.projectId, {
                id: this.$route.params.receptionId,
                emissionDueDate: this.recommendedEmissionDate,
            });
        },
        getRecommendedEmissionDate(firstTask) {
            if (firstTask) {
                return applyDelay(
                    firstTask.realStartDate || firstTask.startDate,
                    -this.receptionReviewMargin,
                    this.agenda,
                    null,
                );
            } else null;
        },
        async onNewVersion() {
            const receptionEntity = {
                groupId: this.reception.groupId,
                name: this.reception.name,
                code: this.reception.code,
                index: this.reception.versions.length + 1,
                emissionDate: null,
                emissionDueDate: null,
                bundleId: this.reception.bundle ? this.reception.bundle.id : null,
                serviceId: this.reception.service ? this.reception.service.id : null,
                locationIds: this.reception.locations.map((location) => location.id),
            };
            const result = await createReception(this.$route.params.projectId, receptionEntity);
            await this.$router.push({
                name: 'followReception',
                params: {
                    ...this.$route.params,
                    receptionId: result.id,
                },
            });
        },
        onVersionSelected(receptionId) {
            return this.$router.push({
                name: 'followReception',
                params: {
                    ...this.$route.params,
                    receptionId,
                },
            });
        },
        onBundleChange(bundle) {
            updateReception(this.$route.params.projectId, {
                id: this.$route.params.receptionId,
                bundleId: bundle ? bundle.id : null,
                serviceId: null,
                locationIds: [],
            });
        },
        onServiceChange(service) {
            if (service) {
                const allowedLocation = this.getAllowedLocation(service.id).map((location) => location.id);
                updateReception(this.$route.params.projectId, {
                    id: this.$route.params.receptionId,
                    serviceId: service.id,
                    locationIds: this.reception.locationIds.filter((id) => allowedLocation.includes(id)),
                });
            } else {
                updateReception(this.$route.params.projectId, {
                    id: this.$route.params.receptionId,
                    serviceId: null,
                    locationIds: [],
                });
            }
        },
        sanitizeReception(reception, toOmit = []) {
            return {
                ...sanitize(reception, [
                    'bundle',
                    'locations',
                    'service',
                    'observations',
                    'createdAt',
                    'versions',
                    ...toOmit,
                ]),
                bundleId: reception.bundle ? reception.bundle.id : null,
                serviceId: reception.service ? reception.service.id : null,
                locationIds: reception.locations.map((location) => location.id),
                emissionDueDate: reception.emissionDueDate || this.recommendedEmissionDate || null,
            };
        },
        async save() {
            const receptionEntity = this.sanitizeReception(this.reception);
            await updateReception(this.$route.params.projectId, { id: this.reception.id, ...receptionEntity });
            this.$refs.observer.reset();
        },
        async duplicate() {
            const receptionEntity = this.sanitizeReception(
                {
                    ...this.reception,
                    name: this.reception.name + ' ' + this.$t('receptions.newCopySuffixName'),
                    emissionDate: null,
                    conclusion: 'pending',
                },
                ['groupId'],
            );
            const result = await createReception(this.$route.params.projectId, receptionEntity);

            this.$refs.observer.reset();
            await this.$router.push({
                name: 'followReception',
                params: {
                    projectId: this.$route.params.projectId,
                    receptionId: result.id,
                },
            });
        },
    },
    data() {
        return {
            tasks: [],
            locationMap: {},
            serviceMap: {},
            bundles: [],
            planning: [],
            agenda: [],
            receptionReviewMargin: null,
            recommendedEmissionDate: null,
            reception: {
                name: this.$t('receptions.newName'),
                bundle: null,
                code: '',
                service: {},
                locations: [],
                emissionDueDate: null,
                emissionDate: null,
                observations: [],
                conclusion: 'pending',
            },
        };
    },
};
</script>
