<template>
    <app-input-text
        :rules="rules"
        :size="size"
        :placeholder="placeholder"
        :pattern="pattern"
        :disabled="disabled"
        :required="required"
        :label="label"
        :uid="uid"
        :value="internalValue"
        @input="onInput($event)"
        @keypress="$emit('keypress', $event)"
        @keyup="$emit('keyup', $event)"
        :showErrors="showErrors"
        :showLabel="showLabel"
        @blur="onBlur"
        :maxLength="maxLength"
        @focus="onFocus"
    >
        <template slot="companion"><slot name="companion"></slot></template>
        <template slot="tip">
            <slot name="tip">
                <span class="text-xs text-gray-700 italic" v-if="showTips && hasFocus">
                    {{ $n(internalValue, formatConfig) }}
                </span>
            </slot>
        </template>
        <template slot="link"><slot name="link"></slot></template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';
import { replaceFollowingDot } from '../../services/sanitize.service';

export default {
    components: { AppInputText },
    watch: {
        value(newValue) {
            if (newValue !== null) {
                if (this.hasFocus) {
                    this.internalValue = newValue;
                } else {
                    this.internalValue = this.$n(this.value, this.formatConfig);
                }
            } else {
                this.internalValue = null;
            }
        },
        format() {
            this.formatConfig = this.getFormat();
        },
    },
    data() {
        return {
            formatConfig: this.getFormat(),
            internalValue: this.$n(this.value, this.getFormat()),
            hasFocus: false,
        };
    },
    created() {
        if (this.value !== null && this.value !== undefined) {
            this.internalValue = this.$n(this.value, this.formatConfig);
        } else {
            this.internalValue = null;
        }
    },
    methods: {
        getFormat() {
            if (typeof this.format === 'string') {
                if (this.format === 'currency') {
                    return {
                        currency: 'EUR',
                        currencyDisplay: 'symbol',
                        style: 'currency',
                        minimumFractionDigits: 2,
                    };
                } else if (this.format === 'decimal') {
                    return {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                    };
                } else if (this.format === 'integer') {
                    return {
                        style: 'decimal',
                        minimumFractionDigits: 0,
                    };
                }
            } else {
                return (
                    this.format || {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                    }
                );
            }
        },
        onBlur($event) {
            this.hasFocus = false;
            this.internalValue = this.$n(this.value, this.formatConfig);
            this.$emit('blur', $event);
        },
        onFocus($event) {
            this.hasFocus = true;
            this.internalValue = this.value;
            this.$emit('focus', $event);
        },
        onInput($event) {
            if ($event.length) {
                let cleanString;
                if (this.formatConfig.minimumFractionDigits > 0) {
                    cleanString = replaceFollowingDot(
                        $event
                            //normalize decimal separator to ,
                            .replace(/,/g, '.')
                            // remove forbidden chars
                            .replace(/[^0-9 .]/g, ''),
                    );
                } else if (this.allowsNegative) {
                    cleanString = $event
                        // remove forbidden chars
                        .replace(/[^0-9\-]/g, '');
                } else {
                    cleanString = $event
                        // remove forbidden chars
                        .replace(/[^0-9]/g, '');
                }
                const newValue = this.toNumeric(cleanString);
                const oldMaskedValueAsNumber = this.toNumeric(this.internalValue);
                if (!(this.allowsNegative && cleanString === '-') && newValue !== oldMaskedValueAsNumber) {
                    this.$emit('input', Math.round((newValue + Number.EPSILON) * 100) / 100);
                }
                this.internalValue = cleanString;
            } else {
                this.internalValue = '';
            }
        },
        toNumeric(value) {
            try {
                const cleanedStringValue = value.replace(/\s/g, '');
                const numericForm = parseFloat(cleanedStringValue);
                if (Number.isFinite(numericForm)) {
                    return numericForm;
                } else {
                    return null;
                }
            } catch (err) {
                return value;
            }
        },
    },
    props: {
        rules: String,
        allowsNegative: Boolean,
        format: {
            type: String | Object,
        },
        size: String,
        placeholder: String,
        pattern: {
            type: String,
            default: '-?[0-9 ]+[,.]?[0-9 ]*',
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: String | Number,
        uid: String | Number,
        maxLength: String | Number,
        showTips: {
            type: Boolean,
            default: true,
        },
        showErrors: {
            type: Boolean,
            default: true,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
