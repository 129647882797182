<template>
    <router-view></router-view>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
    name: 'App',
});
</script>
