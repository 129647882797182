import projectsReplication from '@/rxdb/projectsReplication';

export const projects = {
    schema: {
        title: 'project schema',
        version: 10,
        description: 'describes a project',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            shareId: {
                type: ['string', 'null'],
            },
            size: {
                type: ['number', 'null'],
            },
            name: {
                type: ['string', 'null'],
            },
            code: {
                type: ['string', 'null'],
            },
            type: {
                type: ['string', 'null'],
            },
            phase: {
                type: ['string', 'null'],
            },
            preparationReviewMargin: {
                type: ['number', 'null'],
            },
            receptionReviewMargin: {
                type: ['number', 'null'],
            },
            address1: {
                type: ['string', 'null'],
            },
            address2: {
                type: ['string', 'null'],
            },
            address3: {
                type: ['string', 'null'],
            },
            postalCode: {
                type: ['string', 'null'],
            },
            city: {
                type: ['string', 'null'],
            },
            startDate: {
                type: ['string', 'null'],
            },
            endDate: {
                type: ['string', 'null'],
            },
            ifc: {
                type: ['string', 'null'],
            },
            bimetre: {
                type: ['string', 'null'],
            },
            url: {
                type: ['string', 'null'],
            },
            imageUrl: {
                type: ['string', 'null'],
            },
            nbDaysWeatherIssues: {
                type: ['number', 'null'],
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            officialAgendaId: {
                type: ['string', 'null'],
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            expireAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
            isFavorite: {
                type: ['boolean', 'null'],
            },
            isWebsiteCandidate: {
                type: ['boolean', 'null'],
            },
            projectFeatures: {
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            me: {
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                    },
                    firstName: {
                        type: 'string',
                    },
                    lastName: {
                        type: 'string',
                    },
                    allowedFeatures: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                    bundleIds: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                    },
                },
            },
        },
    },
    autoMigrate: false,
    options: {
        onCollectionCreated(rxCollection) {
            rxCollection.replicationState = projectsReplication(rxCollection);
        },
    },
    migrationStrategies: {
        1: function (oldDoc) {
            return { ...oldDoc, code: oldDoc.code || '' };
        },
        2: async function (oldDoc) {
            return fetch('/api/projects/' + oldDoc.id + '/me')
                .then((response) => response.json())
                .then((me) => {
                    oldDoc.me = me;
                    delete oldDoc.status;
                    return oldDoc;
                });
        },
        3: async function (oldDoc) {
            return oldDoc;
        },
        4: async function (oldDoc) {
            return oldDoc;
        },
        5: async function (oldDoc) {
            oldDoc.me.bundleIds = oldDoc.me.bundles;
            return oldDoc;
        },
        6: async function (oldDoc) {
            return oldDoc;
        },
        7: async function (oldDoc) {
            oldDoc.isFavorite = false;
            return oldDoc;
        },
        8: async function (oldDoc) {
            return oldDoc;
        },
        9: async function (oldDoc) {
            return oldDoc;
        },
        10: async function (oldDoc) {
            delete oldDoc.me.licences;
            return oldDoc;
        },
    },
};
