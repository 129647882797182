<template>
    <div class="max-w-5xl flex flex-col gap-2 flex-grow">
        <div>
            <div class="flex items-center gap-1">
                <div><app-button size="mini" :label="$t('commons.allFemale')" @click="onApplyAll"></app-button></div>
                <app-tips class="">{{ $t('observations.checkListTips') }}</app-tips>
            </div>
        </div>
        <ul>
            <li class="hover:bg-gray-200 flex p-1 border items-center w-full" v-for="item in checkList">
                <div class="flex flex-grow items-center">
                    <span class="text-sm" @click="apply(item)">
                        <app-checkbox :value="item.checked" :disabled="item.disabled"></app-checkbox>
                    </span>
                    <div class="flex flex-col md:flex-row flex-wrap md:items-center">
                        <router-link
                            :to="{
                                name: 'observationTour',
                                params: {
                                    ...$route.params,
                                    observationId: item.linkedInstance.id,
                                },
                            }"
                            v-if="item.linkedInstance"
                            @click.stop.prevent=""
                        >
                            <span class="underline line-clamp-1" v-html="item.title" />
                        </router-link>
                        <span v-else class="line-clamp-1" v-html="item.title" />
                        <span
                            class="text-xs text-gray-500 mx-2 line-clamp-1"
                            v-if="item.recipients && item.recipients.length === 1"
                        >
                            #{{ item.recipients[0].reference }} {{ item.recipients[0].name }}
                        </span>
                        <span
                            class="text-xs text-gray-500 mx-2 line-clamp-1 gap-1"
                            v-if="item.recipients && item.recipients.length > 1"
                        >
                            <span>{{ item.recipients.map((recipient) => '#' + recipient.reference).join(', ') }}</span>
                        </span>
                    </div>
                </div>
                <div class="flex items-center justify-end">
                    <app-button
                        size="mini"
                        @click="onRemoveItem(item)"
                        variant="danger"
                        aria-label="remove item"
                        icon="icon-trash-can-outline"
                    />
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import AppTips from '@/components/app-tips/AppTips';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppButton from '@/components/appButton/AppButton';
import { getCheckListItems, removeCheckListItem } from '@/features/checkLists/checkLists.service';
import { createObservation, removeObservation } from '@/features/observations/observation.service';
import { applyDuration } from '@/services/duration.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import { queryProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';

export default {
    components: { AppButton, AppCheckbox, AppTips },
    props: {
        support: Object,
        observations: { type: Array, default: () => [] },
    },
    async created() {
        this.subscriptions = [
            getAgenda(this.$route.params.projectId).subscribe((agenda) => {
                this.agenda = agenda;
            }),
            combineLatest([
                getCheckListItems(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
            ]).subscribe(([checkList, bundleMap]) => {
                this.checkListItems = checkList.map((item) => ({
                    ...item,
                    recipients: item.recipientIds.map((id) => bundleMap[id]),
                }));
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.defaultReporterId = project.me.bundleIds[0];
            this.defaultPhase = project.phase;
        });
    },
    computed: {
        checkList() {
            return this.checkListItems.map((item) => ({
                ...item,
                disabled: this.isDisabled(item),
                checked: this.isChecked(item),
                linkedInstance: this.getLinkedInstance(item),
            }));
        },
    },
    methods: {
        isChecked(checkListItem) {
            return !!this.observations.find((observation) => {
                return (
                    observation.recipientIds.every((recipientId) => checkListItem.recipientIds.includes(recipientId)) &&
                    observation.type === checkListItem.type &&
                    observation.title.replaceAll(/(<([^>]+)>)/gi, '') ===
                        checkListItem.title.replaceAll(/(<([^>]+)>)/gi, '')
                );
            });
        },
        getLinkedInstance(checkListItem) {
            return this.observations.find((observation) => {
                return (
                    observation.recipientIds.every((recipientId) => checkListItem.recipientIds.includes(recipientId)) &&
                    observation.type === checkListItem.type &&
                    observation.title.replaceAll(/(<([^>]+)>)/gi, '') ===
                        checkListItem.title.replaceAll(/(<([^>]+)>)/gi, '')
                );
            });
        },
        isDisabled(checkListItem) {
            return (
                this.observations.filter(
                    (observation) =>
                        observation.recipientIds.every((recipientId) =>
                            checkListItem.recipientIds.includes(recipientId),
                        ) &&
                        observation.type === checkListItem.type &&
                        observation.title === checkListItem.title,
                ).length > 1
            );
        },
        async onApplyAll() {
            await Promise.all(
                this.checkList.map((item) => {
                    if (!item.checked) {
                        return this.apply(item, true);
                    }
                }),
            );
        },
        async apply(item) {
            if (!item.checked) {
                item.checked = true;
                await createObservation(this.$route.params.projectId, {
                    zoneId: this.$route.params.zoneId,
                    title: item.title,
                    type: item.type,
                    phase: this.$route.params.phase || this.defaultPhase,
                    dueDate: applyDuration(
                        new Date(),
                        7,
                        this.agenda,
                        item.recipientIds.length === 1 ? item.recipientIds[0] : null,
                    ),
                    recipientIds: item.recipientIds,
                    reportedBy: this.defaultReporterId,
                    reportedOnPhase: this.$route.params.phase || this.defaultPhase,
                });
            } else if (item.linkedInstance) {
                await removeObservation(this.$route.params.projectId, item.linkedInstance.id);
            }
        },
        async onRemoveItem(item) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await removeCheckListItem(this.$route.params.projectId, item.id);
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            checkListItems: [],
            defaultReporterId: null,
            defaultPhase: 'EXE',
        };
    },
};
</script>
