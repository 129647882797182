<template>
    <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center">
            <h3 class="text-xl text-main font-bold">{{ $t('dashboard.myDashboardTitle') }}</h3>
        </div>
        <group-dashboard></group-dashboard>
    </div>
</template>
<script>
import GroupDashboard from '@/features/dashboards/GroupDashboard';
export default {
    components: {
        GroupDashboard,
    },
};
</script>
