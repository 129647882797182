<template>
    <div v-if="visa" :title="legend">
        <template v-if="preparationEmissionDate">
            <icon-check-circle-outline
                class="text-green-600"
                v-if="visa.emissionDate && visa.conclusion === 'approved'"
            ></icon-check-circle-outline>
            <icon-close-octagon
                class="text-red-700"
                v-else-if="visa.emissionDate && visa.conclusion === 'nonCompliant'"
            ></icon-close-octagon>
            <icon-close-circle-outline
                class="text-red-700"
                v-else-if="visa.emissionDate && visa.conclusion === 'rejected'"
            ></icon-close-circle-outline>
            <icon-alert-circle-outline
                class="text-yellow-500"
                v-else-if="visa.emissionDate && visa.conclusion === 'approvedWithComments'"
            ></icon-alert-circle-outline>
            <icon-timelapse v-else class="text-secondary"></icon-timelapse>
        </template>
        <icon-pause-circle-outline class="text-gray-400" v-else></icon-pause-circle-outline>
    </div>
</template>

<script>
import IconTimelapse from '../../icons/IconTimelapse';
import IconCheckCircleOutline from '../../icons/IconCheckCircleOutline';
import AppTips from '../../components/app-tips/AppTips';
import IconAlertCircleOutline from '../../icons/IconAlertCircleOutline';
import IconCloseCircleOutline from '../../icons/IconCloseCircleOutline';
import IconCloseOctagon from '../../icons/IconCloseOctagon';
import IconPauseCircleOutline from '../../icons/IconPauseCircleOutline';
import { humanizeDate } from '../../filters/dateFilter';
export default {
    components: {
        IconPauseCircleOutline,
        IconCloseOctagon,
        IconCloseCircleOutline,
        IconAlertCircleOutline,
        AppTips,
        IconCheckCircleOutline,
        IconTimelapse,
    },
    props: {
        visa: Object,
        preparationEmissionDate: Date,
    },
    computed: {
        legend() {
            if (this.visa) {
                if (this.preparationEmissionDate && this.visa.conclusion && this.visa.emissionDate) {
                    return (
                        this.$t('preparations.conclusionValues.' + this.visa.conclusion) +
                        ' (' +
                        humanizeDate(this.visa.emissionDate) +
                        ')'
                    );
                } else if (!this.preparationEmissionDate) {
                    return this.$t('preparations.expectedVisa');
                } else {
                    return (
                        this.$t('preparations.conclusionValues.incompleteVisa') +
                        (this.visa.emissionDueDate ? ' (' + humanizeDate(this.visa.emissionDueDate) + ')' : '')
                    );
                }
            }
        },
    },
};
</script>
