<template>
    <div>
        <app-separator
            :label="$t('project.follow.weatherIssue.attachments')"
            icon="icon-file-document-outline"
            class="my-5 col-span-4"
        ></app-separator>

        <app-multiple-upload-button
            ref="fileInput"
            icon="icon-paperclip"
            :label="$t('project.follow.weatherIssue.newAttachment')"
            :disabled="!weatherIssue || !weatherIssue.id"
            :end-point="`/api/projects/${$route.params.projectId}/weatherIssues/${weatherIssue.id}/attachment`"
            @error="warnError"
            method="PUT"
            :name="$t('project.follow.weatherIssue.newAttachment')"
            class="text-sm offline:hidden"
            v-if="isAdmin || (!weatherIssue.conclusion && weatherIssue.isEmitter)"
        />
        <table class="w-full my-5" v-if="weatherIssue && weatherIssue.attachments">
            <tr v-if="weatherIssue.attachments.length === 0">
                <td class="p-2 px-4 text-sm italic text-center text-gray-700">
                    <span>{{ $t('project.follow.weatherIssue.emptyDocument') }}</span>
                </td>
            </tr>
            <template v-for="attachment in weatherIssue.attachments">
                <tr :v-key="attachment.id" class="border-b hover:bg-gray-200">
                    <td class="p-2" style="width: 30px">
                        <a :href="attachment.url" aria-label="download document" target="_blank">
                            <IconDownload width="16" heigth="16" />
                        </a>
                    </td>
                    <td class="p-2 text-left">
                        <a :href="attachment.url" class="text-sm" aria-label="download document" target="_blank">
                            {{ attachment.fileName }}
                        </a>
                    </td>
                    <td class="p-2" style="width: 30px">
                        <app-button
                            size="mini"
                            variant="danger"
                            aria-label="remove document"
                            icon="icon-trash-can-outline"
                            @click="remove(attachment.url)"
                            v-if="isAdmin"
                        />
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import AppSeparator from '../../../components/appSeparator/AppSeparator';
import AppButton from '../../../components/appButton/AppButton';
import { error } from '../../toasts/toats.service';
import { confirm } from '../../dialogs/dialogs.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import { updateWeatherIssue } from '@/features/planning/weatherIssues/weatherIssues.service';

export default {
    props: {
        weatherIssue: { type: Object },
        isAdmin: Boolean,
    },
    components: {
        AppMultipleUploadButton,
        AppButton,
        AppSeparator,
    },
    methods: {
        async remove(attachmentUrl) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await updateWeatherIssue(this.$route.params.projectId, {
                    id: this.weatherIssue.id,
                    attachments: this.weatherIssue.attachments
                        .filter((attachment) => attachment.url !== attachmentUrl)
                        .map(({ url, fileName }) => ({ url, fileName })),
                });
            }
        },
        async warnError() {
            error(this.$t('errors.INTERNAL_SERVER_ERROR'));
        },
        newDocument() {
            this.$refs.fileInput.click();
        },
    },
};
</script>
