import { createRxDatabase, addRxPlugin } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';
import { projects } from './projectsCollection';
import { locations } from './locationsCollection';
import { getLoggedUser } from '@/features/tokens/token.service';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { reportError } from '@/features/tracker/tracker.service';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { supports } from '@/rxdb/supportsCollection';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { periods } from '@/rxdb/periodsCollection';
import { weatherIssues } from '@/rxdb/weatherIssuesCollection';
import offDaysService from '@/services/offDays.service';
import { toCamelCase } from '@/services/sanitize.service';
import { bundles } from '@/rxdb/bundlesCollection';
import { contacts } from '@/rxdb/contactsCollection';
import { companies } from '@/rxdb/companiesCollection';
import { preSave } from '@/rxdb/defaultHooks';
import { services } from '@/rxdb/servicesCollection';
import { directories } from '@/rxdb/directoriesCollection';
import { tasks } from '@/rxdb/tasksCollection';
import { syncStates } from '@/rxdb/syncStatesCollection';
import { initSocket } from '@/services/socket.service';
import { observations } from '@/rxdb/observationsCollection';
import { checkListItems } from '@/rxdb/checkListItemsCollection';
import { observationAttachments } from '@/rxdb/observationAttachmentsCollection';
import { autoControls } from '@/rxdb/autoControlsCollection';
import { receptions } from '@/rxdb/receptionsCollection';
import { certificates } from './certificatesCollection';
import { preparations } from '@/rxdb/preparationsCollection';
import { preparationVisas } from '@/rxdb/preparationVisasCollection';
import { preparationAttachments } from '@/rxdb/preparationAttachmentsCollection';
import { references } from './referencesCollection';
import { planningExports } from '@/rxdb/planningExportsCollection';
import { observationsReports } from '@/rxdb/observationsReportsCollection';
import { meetings } from '@/rxdb/meetingsCollection';
import { preparationsReports } from '@/rxdb/preparationsReportsCollection';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { resetPending } from '@/state/state';
import { mailTemplates } from '@/rxdb/mailTemplatesCollection';
import { deliveryContacts } from '@/rxdb/deliveryContactsCollection';
import { requestAttachments } from './requestAttachmentsCollection';
import { requests } from './requestsCollection';
let resolveInit;
let rejectInit;
let database;
const emptyBrowser = !localStorage.getItem('token');
let migrated = localStorage.getItem(`migrated_collectionNameWithUserId`);
export function getCollectionKeySuffix(projectId) {
    if (migrated) {
        return localStorage.getItem('userId') + '_' + projectId;
    } else {
        return projectId;
    }
}
const singleton = {
    projectMap: {},
    collections: null,
    projects: null,
    initDb: new Promise((resolve, reject) => {
        resolveInit = resolve;
        rejectInit = (err) => {
            console.error(err);
            reportError(err);
            reject(err);
        };
    }),
    getProjectCollections(projectId) {
        return singleton.projectMap[getCollectionKeySuffix(projectId)];
    },
    exportBackup() {
        return database.exportJSON();
    },
    async reset() {
        return database.remove();
    },
    replicateProject,
    replicateProjects,
    logout() {
        singleton.projects = null;
    },
};
export default singleton;
export const initGlobalDatabase = async () => {
    try {
        if (!database) {
            initSocket();
            addRxPlugin(RxDBJsonDumpPlugin);
            addRxPlugin(RxDBEncryptionPlugin);
            addRxPlugin(RxDBMigrationPlugin);
            addRxPlugin(RxDBQueryBuilderPlugin);
            addRxPlugin(RxDBLeaderElectionPlugin);
            addRxPlugin(RxDBUpdatePlugin);
            const storage = getRxStorageDexie();
            database = await createRxDatabase({
                name: 'atexdb',
                storage,
                password: 'h=C_rq6GCb8q?wdA',
            });
            if (emptyBrowser) {
                localStorage.setItem(`migrated_collectionNameWithUserId`, 'migrated');
                migrated = 'migrated';
            }
            const collections = await database.addCollections({ sync_states: syncStates });
            singleton.syncStates = collections.sync_states;
            await resetPending();
        }
        await initProjectCollection();
        await replicateProjects();
        resolveInit();
    } catch (err) {
        rejectInit(err);
    }
};
async function initProjectCollection() {
    if (singleton.projects) {
        return;
    }
    let projectCollection = database['projects_' + getCollectionKeySuffix('projects')];
    if (!projectCollection) {
        const collections = await database.addCollections({
            ['projects_' + getCollectionKeySuffix('projects')]: projects,
        });
        projectCollection = collections['projects_' + getCollectionKeySuffix('projects')];
    }
    singleton.projects = projectCollection;
    singleton.projects.preSave(preSave, false);
    singleton.projects.options.onCollectionCreated(singleton.projects);
    await reconnect();
}
export const isReadyProject = (projectId) => !!singleton.projectMap[getCollectionKeySuffix(projectId)];
export const initProjectCollections = async (projectId) => {
    const collectionSuffix = getCollectionKeySuffix(projectId);
    const projectCollections = singleton.projectMap[collectionSuffix];
    if (projectCollections) {
        return;
    }
    singleton.projectMap[collectionSuffix] = true;
    console.time('addCollections');
    try {
        const collections = await database.addCollections({
            ['locations_' + collectionSuffix]: locations,
            ['supports_' + collectionSuffix]: supports,
            ['periods_' + collectionSuffix]: periods,
            ['weather_issues_' + collectionSuffix]: weatherIssues,
            ['bundles_' + collectionSuffix]: bundles,
            ['contacts_' + collectionSuffix]: contacts,
            ['companies_' + collectionSuffix]: companies,
            ['services_' + collectionSuffix]: services,
            ['directories_' + collectionSuffix]: directories,
            ['tasks_' + collectionSuffix]: tasks,
            ['observations_' + collectionSuffix]: observations,
            ['check_list_items_' + collectionSuffix]: checkListItems,
            ['observation_attachments_' + collectionSuffix]: observationAttachments,
            ['auto_controls_' + collectionSuffix]: autoControls,
            ['receptions_' + collectionSuffix]: receptions,
            ['certificates_' + collectionSuffix]: certificates,
            ['preparations_' + collectionSuffix]: preparations,
            ['preparation_visas_' + collectionSuffix]: preparationVisas,
            ['preparation_attachments_' + collectionSuffix]: preparationAttachments,
            ['references_' + collectionSuffix]: references,
            ['planning_exports_' + collectionSuffix]: planningExports,
            ['observations_reports_' + collectionSuffix]: observationsReports,
            ['meetings_' + collectionSuffix]: meetings,
            ['preparations_reports_' + collectionSuffix]: preparationsReports,
            ['mail_templates_' + collectionSuffix]: mailTemplates,
            ['delivery_contacts_' + collectionSuffix]: deliveryContacts,
            ['request_attachments_' + collectionSuffix]: requestAttachments,
            ['requests_' + collectionSuffix]: requests,
        });
        singleton.projectMap[collectionSuffix] = Object.entries(collections).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [toCamelCase(key.replace(`_${collectionSuffix}`, ''))]: value,
            }),
            {},
        );
        console.timeEnd('addCollections');
        offDaysService.sync(
            projectId,
            collections['periods_' + collectionSuffix],
            collections['weather_issues_' + collectionSuffix],
        );
        await Promise.all(
            Object.values(collections)
                .filter((collection) => collection.options.onCollectionCreated)
                .map((collection) => collection.options.onCollectionCreated(projectId, collection)),
        );
    } catch (err) {
        console.error('addCollections error', err);
        singleton.projectMap[collectionSuffix] = null;
    }
    console.log('addCollections finished');
};

export async function replicateProjects() {
    if (singleton.projects.migrationNeeded()) {
        await singleton.projects.migratePromise(10);
    }
    console.log('projects.replicate()');
    console.time('projects.replicate()');
    await singleton.projects.replicationState.run();
    console.timeEnd('projects.replicate()');
}

export async function replicateProject(projectId) {
    const collectionSuffix = getCollectionKeySuffix(projectId);
    let projectCollections = singleton.projectMap[collectionSuffix];
    if (!projectCollections) {
        await initProjectCollections(projectId);
    }
    projectCollections = singleton.projectMap[collectionSuffix];
    const projectLogPrefix = `replicate project[${collectionSuffix}]`;
    console.log(projectLogPrefix);
    console.time(projectLogPrefix);
    await Promise.all(
        Object.values(projectCollections)
            .filter((collection) => !!collection.replicationState)
            .map((collection) => collection.replicationState.run()),
    );
    console.timeEnd(projectLogPrefix);
    console.log(projectLogPrefix + ' Ok');
}

async function reconnectProject() {
    console.log('reconnectProject');
    for (const projectKey of Object.keys(singleton.projectMap)) {
        const projectLogPrefix = `replicate projects[${projectKey}]`;
        console.log(projectLogPrefix);
        console.time(projectLogPrefix);
        await Promise.all(
            Object.entries(singleton.projectMap[projectKey]).map(async ([key, value]) => {
                if (value.replicationState) {
                    const collectionLog = `${projectLogPrefix}.${key}`;
                    console.log(collectionLog);
                    console.time(collectionLog);
                    await value.replicationState.run();
                    console.timeEnd(collectionLog);
                }
            }),
        );
        console.timeEnd(projectLogPrefix);
    }
    console.log('reconnectProject Ok');
}

export async function reconnect() {
    if (checkAuthentication()) {
        if (singleton.projects.migrationNeeded()) {
            await singleton.projects.migratePromise(10);
        }
        return singleton.projects.replicationState.run().catch((err) => reportError(err));
    } else if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/newProject' &&
        window.location.pathname !== '/resetPassword'
    ) {
        console.log('no auth, fallback to login page');
        window.location = '/login';
    }
}

window.addEventListener('online', () => {
    reconnectProject().catch((err) => reportError(err));
});

function checkAuthentication() {
    const token = getLoggedUser();
    return token && token.exp * 1000 > parseInt(new Date().getTime()) + 60000;
}
