import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getMapById } from '@/services/sanitize.service';

function mapDirectory(item) {
    return item ? item.toMutableJSON() : null;
}

export function getDirectories(projectId) {
    return db
        .getProjectCollections(projectId)
        .directories.find({ selector: {} })
        .$.pipe(map((items) => items.map(mapDirectory)));
}
export function getDirectory(projectId, directoryId) {
    return db
        .getProjectCollections(projectId)
        .directories.findOne({ selector: { id: directoryId } })
        .$.pipe(map(mapDirectory));
}

export async function updateDirectory(projectId, directory) {
    const dbSupport = await db
        .getProjectCollections(projectId)
        .directories.findOne({ selector: { id: directory.id } })
        .exec();
    return dbSupport.atomicPatch(directory);
}

export async function createDirectory(projectId, directory) {
    const result = await db.getProjectCollections(projectId).directories.insert(directory);
    return result.toJSON();
}

export async function removeDirectory(projectId, directoryId) {
    const entity = await db
        .getProjectCollections(projectId)
        .directories.findOne({ selector: { id: directoryId } })
        .exec();
    return entity.remove();
}

export function getDirectoryNamePathMap(directories) {
    const directoryMap = getMapById(directories);
    const resultDirectoryPathById = {};
    for (const directory of directories) {
        let name = directory.name;
        let current = directory;
        while (current && current.parentDirectoryId) {
            const parent = directoryMap[current.parentDirectoryId];
            if (parent) {
                name = parent.name + ' > ' + name;
            }
            current = parent;
        }
        resultDirectoryPathById[directory.id] = name;
    }
    return resultDirectoryPathById;
}

export function bulkDelete(projectId, contactIds) {
    return db.getProjectCollections(projectId).directories.bulkRemove(contactIds);
}
export default { bulkDelete };
