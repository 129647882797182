import db from '@/rxdb/database';
import { map } from 'rxjs';
import offDaysService from '@/services/offDays.service';

function mapAgendas(periodsDb) {
    return periodsDb.map((item) => ({
        ...item.toMutableJSON(),
        startDate: item.startDate ? new Date(item.startDate) : null,
        endDate: item.endDate ? new Date(item.endDate) : null,
    }));
}
export function getAgenda(projectId) {
    return db
        .getProjectCollections(projectId)
        .periods.find({ selector: {}, sort: [{ startDate: 'asc' }] })
        .$.pipe(map(mapAgendas));
}

export function getCalendar(projectId) {
    return offDaysService.find(projectId);
}

export async function createException(projectId, period) {
    const result = await db.getProjectCollections(projectId).periods.insert({
        ...period,
        startDate: period.startDate ? period.startDate.toISOString() : null,
        endDate: period.endDate ? period.endDate.toISOString() : null,
        type: 'custom',
    });
    return result.toJSON();
}

export async function saveException(projectId, period) {
    const dbLocation = await db
        .getProjectCollections(projectId)
        .periods.findOne({ selector: { id: period.id } })
        .exec();
    return dbLocation.atomicPatch({
        ...period,
        startDate: period.startDate ? period.startDate.toISOString() : null,
        endDate: period.endDate ? period.endDate.toISOString() : null,
    });
}
export async function removeException(projectId, periodId) {
    const entity = await db
        .getProjectCollections(projectId)
        .periods.findOne({ selector: { id: periodId } })
        .exec();
    return entity.remove();
}

export async function filterBundleIds(projectId, period, bundleId) {
    const dbPeriod = await db
        .getProjectCollections(projectId)
        .periods.findOne({ selector: { id: period.id } })
        .exec();
    return dbPeriod.atomicPatch({
        bundleIds: dbPeriod.bundleIds.filter((id) => id !== bundleId),
    });
}
export default { filterBundleIds };
