var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full col-span-2 mt-4 flex justify-center flex-col max-w-5xl"},[_c('bundle-contact-creation',{ref:"contactPopup",attrs:{"contact":_vm.createdContact,"showIsRecipient":_vm.showResponsible},on:{"save":function($event){return _vm.saveContact($event)}}}),(_vm.contacts.length > 0)?_c('app-label',{attrs:{"label":_vm.$t('users.users')}}):_vm._e(),_c('div',{staticClass:"flex flex-col w-full"},[_vm._l((_vm.contacts),function(contact){return [_c('div',{staticClass:"flex items-center gap-2 border p-1 gap-4 hover:bg-gray-200",attrs:{"v-key":contact.id}},[(_vm.showResponsible)?_c('div',{staticClass:"flex items-center justify-center"},[_c('button',{attrs:{"aria-label":_vm.$t('bundles.isResponsible'),"title":_vm.$t('bundles.isResponsible'),"alt":_vm.$t('bundles.isResponsible')},on:{"click":function($event){$event.preventDefault();return _vm.changeResponsible(contact)}}},[_c('icon-police-badge',{staticClass:"cursor-pointer",class:{
                                'text-yellow-500': _vm.responsibleId === contact.id,
                                'text-gray-300': _vm.responsibleId !== contact.id,
                                'hover:text-yellow-500': _vm.responsibleId !== contact.id,
                            }})],1)]):_vm._e(),(_vm.showResponsible)?_c('div',{staticClass:"flex items-center justify-center"},[_c('button',{attrs:{"aria-label":_vm.$t('users.isReportRecipient'),"title":_vm.$t('users.isReportRecipient'),"alt":_vm.$t('users.isReportRecipient')},on:{"click":function($event){$event.preventDefault();return _vm.toggleIsReportRecipient(contact)}}},[_c('icon-email',{staticClass:"cursor-pointer",class:{
                                'text-green-600': contact.isReportRecipient,
                                'text-gray-300': !contact.isReportRecipient,
                                'hover:text-green-600': !contact.isReportRecipient,
                            }})],1)]):_vm._e(),_c('div',{staticClass:"flex-grow flex"},[_c('div',{staticClass:"flex flex-grow flex-col"},[_c('a',{class:{ 'hover:cursor-pointer hover:underline': !_vm.readOnly },on:{"click":function($event){return _vm.editContact(contact)}}},[_vm._v("\n                            "+_vm._s(contact.firstName)+" "+_vm._s(contact.lastName)+"\n                        ")]),_c('app-tips',[_vm._v(" "+_vm._s(contact.role))])],1),_c('div',{staticClass:"flex flex-col sm:flex-row text-sm sm:gap-4"},[_c('app-phone',{attrs:{"number":contact.phone}}),_c('app-mail',{attrs:{"email":contact.email}})],1)]),_c('div',[_c('app-button',{attrs:{"size":"mini","icon":"icon-account-arrow-down","title":"Télécharger le contact"},on:{"click":function($event){return _vm.exportVCard(contact)}}})],1),(!_vm.readOnly)?_c('div',[_c('app-button',{attrs:{"size":"mini","variant":"danger","aria-label":"remove contact","icon":"icon-trash-can-outline"},on:{"click":function($event){return _vm.removeContact(contact)}}})],1):_vm._e()])]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }