var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-popup',{ref:"popup",attrs:{"title":_vm.$t('impacts.title'),"variant":"alert","show-header":true}},[(_vm.impacts === null)?_c('div',{staticClass:"flex gap-3 w-full p-6"},[_c('icon-rotate-right',{staticClass:"animate animate-spin"}),_vm._v("\n        "+_vm._s(_vm.$t('impacts.loading'))+"\n    ")],1):_vm._e(),(!!_vm.impacts)?_c('div',{staticClass:"w-full p-6"},[(Object.values(_vm.impacts).find((list) => list.length > 0))?_c('div',{staticClass:"flex flex-col w-full"},[_c('ul',{staticClass:"flex-col flex gap-3"},[_c('li',[_c('span',[_vm._v(_vm._s(_vm.$t('impacts.selectionTip'))+" :")]),_c('ul',{staticClass:"list-disc p-2"},[_c('app-impact',{attrs:{"length":_vm.impacts.services.length,"label":_vm.impacts.services.length === 1 ? _vm.$t('impacts.service') : _vm.$t('impacts.services'),"tooltip":_vm.impacts.services.map((service) => service.name).join('\n'),":no-item-label":_vm.$t('impacts.noService')}}),_c('app-impact',{attrs:{"length":_vm.impacts.directories.length,"label":_vm.impacts.directories.length === 1
                                    ? _vm.$t('impacts.directory')
                                    : _vm.$t('impacts.directories'),"tooltip":_vm.impacts.directories.map((directory) => directory.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.directTasks.length,"label":_vm.impacts.directTasks.length === 1
                                    ? _vm.$t('impacts.directTask')
                                    : _vm.$t('impacts.directTasks'),"tooltip":_vm.impacts.directTasks.map((task) => task.label).join('\n'),":no-item-label":_vm.$t('impacts.noDirectTask')}})],1)]),(
                        _vm.impacts.linkedTasks.length ||
                        _vm.impacts.certificates.length ||
                        _vm.impacts.autoControls.length ||
                        _vm.impacts.receptions.length
                    )?_c('li',[_vm._v("\n                    "+_vm._s(_vm.$t('impacts.impactsTip'))+" :\n                    "),_c('ul',{staticClass:"list-disc p-2"},[_c('app-impact',{attrs:{"length":_vm.impacts.linkedTasks.length,"label":_vm.impacts.linkedTasks.length === 1
                                    ? _vm.$t('impacts.linkedTask')
                                    : _vm.$t('impacts.linkedTasks'),"tooltip":_vm.impacts.linkedTasks.map((task) => task.label).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.certificates.length,"label":_vm.impacts.certificates.length === 1
                                    ? _vm.$t('impacts.certificate')
                                    : _vm.$t('impacts.certificates'),"tooltip":_vm.impacts.certificates.map((certificate) => certificate.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.autoControls.length,"label":_vm.impacts.autoControls.length === 1
                                    ? _vm.$t('impacts.autoControl')
                                    : _vm.$t('impacts.autoControls'),"tooltip":_vm.impacts.autoControls.map((autoControl) => autoControl.name).join('\n')}}),_c('app-impact',{attrs:{"length":_vm.impacts.receptions.length,"label":_vm.impacts.receptions.length === 1 ? _vm.$t('impacts.reception') : _vm.$t('impacts.receptions'),"tooltip":_vm.impacts.receptions.map((reception) => reception.name).join('\n')}})],1)]):_vm._e()])]):_c('span',[_vm._v(_vm._s(_vm.$t('impacts.noImpact')))])]):_vm._e(),_c('app-footer',{staticClass:"w-full p-2",attrs:{"ok-label":_vm.$t('impacts.confirm'),"variant":"danger","disabled":_vm.impacts === null},on:{"click":function($event){_vm.$refs.popup.close();
            _vm.$emit('deleteConfirmed');}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('app-button',{attrs:{"variant":"default","label":_vm.$t('commons.cancel'),"size":"mini"},on:{"click":function($event){return _vm.$refs.popup.close()}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }