import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getMapById } from '@/services/sanitize.service';

function mapContact(contact) {
    return contact ? contact.toMutableJSON() : null;
}

export function getContacts(projectId) {
    return db
        .getProjectCollections(projectId)
        .contacts.find({ selector: {} })
        .$.pipe(map((companies) => companies.map(mapContact)));
}
export function getContactMap(projectId) {
    return db
        .getProjectCollections(projectId)
        .contacts.find({ selector: {} })
        .$.pipe(map((companies) => getMapById(companies.map(mapContact))));
}

export async function createContact(projectId, contact) {
    const result = await db.getProjectCollections(projectId).contacts.insert(contact);
    return result.toJSON();
}

export async function updateContact(projectId, contact) {
    const dbContact = await db
        .getProjectCollections(projectId)
        .contacts.findOne({ selector: { id: contact.id } })
        .exec();
    return dbContact.atomicPatch(contact);
}

export async function removeContact(projectId, contactId) {
    return db
        .getProjectCollections(projectId)
        .contacts.findOne({ selector: { id: contactId } })
        .remove();
}

export function bulkDelete(projectId, contactIds) {
    return db.getProjectCollections(projectId).contacts.bulkRemove(contactIds);
}
export default { bulkDelete };
