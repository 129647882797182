import { ImageOverlay, DomUtil, Util } from 'leaflet';
const ImageLayer = ImageOverlay.extend({
    options: {
        customOptions: {
            src: null,
            rotation: 0,
            onLoaded: null,
        },
    },
    resetBounds: function () {
        const imgDomElement = this._image.firstChild;
        if (imgDomElement.naturalWidth === 0 && imgDomElement.naturalHeight === 0) {
            return;
        }
        const mapBound = this._map.getBounds();
        const mapWidth = mapBound.getEast() - mapBound.getWest();
        const mapHeight = mapBound.getNorth() - mapBound.getSouth();

        let imageWidth = imgDomElement.naturalWidth;
        let imageHeight = imgDomElement.naturalHeight;
        if (this.options.customOptions.rotation === 90 || this.options.customOptions.rotation === 270) {
            imageHeight = imgDomElement.naturalWidth;
            imageWidth = imgDomElement.naturalHeight;
        }

        const hMapScale = mapWidth / imageWidth;
        const vMapScale = mapHeight / imageHeight;
        const mapScale = Math.min(hMapScale, vMapScale);

        let bounds = [
            [
                mapBound.getNorth() - imageHeight * mapScale,
                mapBound.getWest() + mapWidth / 2 - (imageWidth * mapScale) / 2,
            ],
            [
                mapBound.getNorth(),
                mapBound.getWest() + imageWidth * mapScale + mapWidth / 2 - (imageWidth * mapScale) / 2,
            ],
        ];
        this.setBounds(bounds);
    },
    loadPage: function (page, rotation, src) {
        this.options.customOptions.rotation = rotation;
        this._image.firstChild.src = src;
        this.resetBounds();
        this.applyRotationAndScale(this.options.customOptions.rotation);
        return Promise.resolve();
    },
    applyRotationAndScale(rotation) {
        let translate = '0';
        if (rotation === 270) {
            translate = '-100%';
        } else if (rotation === 90) {
            translate = '0, -100%';
        } else if (rotation === 180) {
            translate = '-100%, -100%';
        }
        if (rotation === 0 || rotation === 180) {
            let xScale = parseInt(this._image.style.width) / this._image.firstChild.naturalWidth;
            let yScale = parseInt(this._image.style.height) / this._image.firstChild.naturalHeight;
            this.scale = Math.min(xScale, yScale);
            this._image.firstChild.style.width = this._image.firstChild.naturalWidth * this.scale + 'px';
        } else {
            let xScale = parseInt(this._image.style.width) / this._image.firstChild.naturalHeight;
            let yScale = parseInt(this._image.style.height) / this._image.firstChild.naturalWidth;
            this.scale = Math.min(xScale, yScale);
            this._image.firstChild.style.width = this._image.firstChild.naturalWidth * this.scale + 'px';
        }
        this._image.firstChild.style.transform = `rotate(${rotation}deg) translate(${translate})`;
    },
    _initImage: function () {
        this._image = DomUtil.create('div');
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    this.applyRotationAndScale(this.options.customOptions.rotation);
                }
            }
        });
        observer.observe(this._image, { attributes: true });

        let img = DomUtil.create('img');
        this._image.appendChild(img);
        DomUtil.addClass(this._image, 'leaflet-image-layer');
        if (this._zoomAnimated) {
            DomUtil.addClass(this._image, 'leaflet-zoom-animated');
        }
        if (this.options.className) {
            DomUtil.addClass(this._image, this.options.className);
        }

        img.onselectstart = Util.falseFn;
        img.onmousemove = Util.falseFn;
        img.style.height = 'auto';
        img.style.maxWidth = 'none';
        img.style.transformOrigin = 'top left';

        // @event load: Event
        // Fired when the ImageOverlay layer has loaded its image
        const that = this;
        img.onload = function () {
            that.resetBounds();
            that.applyRotationAndScale(that.options.customOptions.rotation);
            Util.bind(that.fire, that, 'load');
            that.options.customOptions.onLoaded
                ? that.options.customOptions.onLoaded({ numPages: 1, img: this })
                : null;
        };
        img.onerror = Util.bind(this._overlayOnError, this, 'error');

        if (this.options.crossOrigin || this.options.crossOrigin === '') {
            img.crossOrigin = this.options.crossOrigin === true ? '' : this.options.crossOrigin;
        }

        if (this.options.zIndex) {
            this._updateZIndex();
        }

        this._url = this.options.customOptions.src;
        img.src = this._url;
        img.alt = this.options.alt;
    },
});

export default function AppLeafletImageOverlay(customOptions) {
    return new ImageLayer(
        null,
        [
            [0, 0],
            [800, 800],
        ],
        {
            customOptions,
        },
    );
}
