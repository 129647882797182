<template>
    <div class="w-full flex">
        <div class="w-2/3 mx-5 overflow-hidden max-h-main min-h-main" ref="page">
            <app-leaflet-viewer
                ref="viewer"
                class="h-full flex-grow"
                :show-rotation="true"
                :rotation="preparationAttachment.rotation"
                :src="preparationAttachment.url"
                :page="currentPage"
                :shapes="shapes"
                @pageLoaded="onPageChanged"
                @pageChanged="onPageChanging"
                @newShape="onCaptureDrawn"
                @captured="onCaptured"
                @rotate="onRotate"
                @selectShape="onSelectShape"
                @pickPosition="onPickPosition"
                :long-press-to-pick="false"
            >
                <template v-slot:extraToolbar>
                    <div class="mr-2 flex w-full gap-6">
                        <div class="flex gap-2" v-if="preparationAttachments.length">
                            <app-button
                                size="mini"
                                icon="icon-chevron-left"
                                :title="$t('preparations.previousDocument')"
                                :disabled="preparationAttachmentIndex === 0"
                                @click="previousPreparationAttachment()"
                            ></app-button>
                            <app-select
                                v-model="selectedPreparationAttachmentId"
                                @input="selectPreparationAttachment"
                                class="text-xs"
                            >
                                <option
                                    v-for="aPreparationAttachment in preparationAttachments"
                                    :value="aPreparationAttachment.id"
                                >
                                    {{ aPreparationAttachment.name }}
                                </option>
                            </app-select>
                            <app-button
                                size="mini"
                                icon="icon-chevron-right"
                                :title="$t('preparations.nextDocument')"
                                :disabled="preparationAttachmentIndex === preparationAttachments.length - 1"
                                @click="nextPreparationAttachment()"
                            ></app-button>
                        </div>
                        <app-button
                            :disabled="disabled || editObservationId"
                            size="mini"
                            :label="$t('preparations.newObservation')"
                            @click="onNewObservation"
                        ></app-button>
                    </div>
                </template>
            </app-leaflet-viewer>
        </div>
        <div class="w-1/3">
            <div class="overflow-y-auto max-h-main pb-10 text-xs">
                <div class="flex gap-2 p-2">
                    <div class="flex gap-2">
                        <div
                            class="px-1 border observations-to-fix-late flex items-center"
                            style="min-width: 3em"
                            :title="$t('observations.toFix')"
                        >
                            {{ toFixCount }}
                        </div>
                        <div
                            class="px-1 border observations-done flex items-center"
                            style="min-width: 3em"
                            :title="$t('observations.fixed')"
                        >
                            {{ fixedCount }}
                        </div>
                        <div
                            class="px-1 border observations-validated flex items-center"
                            style="min-width: 3em"
                            :title="$t('observations.validated')"
                        >
                            {{ validatedCount }}
                        </div>
                        <div
                            class="px-1 border border-gray-600 flex items-center"
                            style="min-width: 3em"
                            :title="$t('observations.obsolete')"
                        >
                            {{ obseleteCount }}
                        </div>
                    </div>
                    <div class="flex-grow"></div>
                    <div>
                        <app-button
                            size="mini"
                            @click="$refs.settings.open()"
                            :aria-label="$t('commons.settings')"
                            :title="$t('commons.settings')"
                            icon="icon-tune"
                        />
                    </div>
                </div>
                <ul class="my-5">
                    <li class="border" v-for="observation in filteredObservations" :key="observation.id">
                        <a :id="'uuid_' + observation.id" />
                        <AppPreparationDocumentObservation
                            :isMultiPages="isMultiplePage"
                            :observation="observation"
                            @save="onSaveObservation"
                            @close="onClose"
                            @delete="onDeleteObservation"
                            @edit="onEdit"
                            :edit="!disabled && observation.id === editObservationId"
                            @startCapture="onStartCapture"
                            @startPickPosition="onStartPickPosition($event, observation)"
                            @removeMarker="removeMarker"
                            @input="onSaveObservation"
                            :selected="selectedObservationId === observation.id"
                            :disabled="disabled"
                            @gotoPage="
                                onPageChanged($event);
                                selectedObservationId = observation.id;
                            "
                        />
                    </li>
                </ul>
            </div>
        </div>
        <app-popup ref="popup" :showHeader="true" :title="$t('photo.annotate')">
            <app-photo-editor
                v-if="selectedObservation && selectedObservation.attachments[0]"
                :attachment="selectedObservation.attachments[0]"
                :read-only="false"
            ></app-photo-editor>
        </app-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
            <form class="flex flex-col gap-4 p-2">
                <fieldset>
                    <legend class="text-gray-700 font-bold mb-2">{{ $t('commons.showHide') }}</legend>
                    <app-checkbox class="mt-2" :label="$t('observations.toFix')" v-model="showToFix">
                        <span style="width: 1em; height: 1em" class="border observations-to-fix-late mr-1"></span>
                    </app-checkbox>
                    <app-checkbox :label="$t('observations.fixed')" v-model="showFixed">
                        <span style="width: 1em; height: 1em" class="border observations-done mr-1"></span>
                    </app-checkbox>
                    <app-checkbox :label="$t('observations.validatedPlural')" v-model="showValidated">
                        <span style="width: 1em; height: 1em" class="border observations-validated mr-1"></span>
                    </app-checkbox>
                    <app-checkbox :label="$t('observations.obsolete')" v-model="showObsolete">
                        <span style="width: 1em; height: 1em" class="border bg-gray-600 mr-1"></span>
                    </app-checkbox>
                </fieldset>
                <app-footer @click="$refs.settings.close()"></app-footer>
            </form>
            <div style="padding-bottom: 40px"></div>
        </app-popup>
    </div>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import { confirm } from '../dialogs/dialogs.service';
import AppUnknownViewer from '../../components/appUnkownViewer/AppUnknownViewer';
import { getPreparation, sortComments } from './preparations.service';
import { sanitize } from '@/services/sanitize.service';
import AppPreparationDocumentObservation from '@/features/preparations/AppPreparationDocumentObservation';
import {
    createObservation,
    getObservationClass,
    getObservationsForRelatedContent,
    removeObservation,
    updateObservation,
} from '@/features/observations/observation.service';
import AppPopup from '@/components/app-popup/AppPopup';

import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { getAPIHeaders } from '@/services/api.service';
import {
    getPreparationAttachments,
    updatePreparationAttachment,
} from '@/features/preparations/preparationAttachments.service';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getPreparationVisa } from '@/features/preparations/preparationVisas.service';
import { getObservationAttachments } from '@/features/observations/observationAttachments.service';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';

export default {
    components: {
        AppMultiPicker,
        AppFooter,
        AppBundle,
        AppCheckbox,
        AppSelect,
        AppPhotoEditor,
        AppQuickActions,
        AppLeafletViewer,
        AppPopup,
        AppPreparationDocumentObservation,
        AppUnknownViewer,
        AppButton,
        AppSeparator,
    },
    async created() {
        this.init();
    },
    watch: {
        $route() {
            this.refresh();
        },
    },
    data() {
        return {
            preparationAttachment: { url: '', observations: [] },
            observations: [],
            allObservations: [],
            currentPage: 1,
            editObservationId: null,
            selectedObservationId: null,
            visa: null,
            preparation: null,
            disabled: true,
            isMultiplePage: false,
            pickPosition: false,
            subscriptions: [],
            preparationAttachments: [],
            preparationAttachmentIndex: 0,
            selectedPreparationAttachmentId: 0,
            showToFix: true,
            showFixed: true,
            showValidated: true,
            showObsolete: true,
        };
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getBundleMap(this.$route.params.projectId),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisa(this.$route.params.projectId, this.$route.params.preparationVisaId),
                    getPreparationAttachments(this.$route.params.projectId, this.$route.params.preparationId),
                    getObservationsForRelatedContent(
                        this.$route.params.projectId,
                        this.$route.params.preparationVisaId,
                    ),
                    getObservationAttachments(this.$route.params.projectId),
                ]).subscribe(
                    ([
                        project,
                        bundleMap,
                        preparation,
                        visa,
                        preparationAttachments,
                        observations,
                        observationAttachments,
                    ]) => {
                        this.preparation = preparation;
                        this.preparationAttachments = preparationAttachments;
                        this.allObservations = observations.map((observation) => ({
                            ...observation,
                            classes: getObservationClass(observation),
                            validator: bundleMap[observation.validatedBy],
                            reporter: bundleMap[observation.reportedBy],
                            obsoleter: bundleMap[observation.obsoleteBy],
                            resolver: bundleMap[observation.resolvedBy],
                            attachments: observationAttachments.filter(
                                ({ observationId }) => observationId === observation.id,
                            ),
                        }));
                        this.visa = {
                            ...visa,
                            emitter: bundleMap[visa.emitterId],
                        };
                        const isAdmin =
                            this.$route.name === 'preparationDocument'
                                ? project.me.allowedFeatures.includes('project_preparations')
                                : project.me.allowedFeatures.includes('project_conceptions');

                        const isEmitter = project.me.bundleIds.includes(this.visa.emitterId);
                        this.disabled = !isAdmin && !isEmitter;
                        this.refresh();
                        updateBreadCrumbs({
                            preparationName: this.preparation.name,
                            documentName: this.preparationAttachment.name,
                            visaName: this.$t('preparations.visaFrom') + ' ' + this.visa.emitter?.label,
                        });
                        this.loading = false;
                    },
                ),
            ];
        },
        refresh() {
            this.preparationAttachment = this.preparationAttachments.find(
                (preparationAttachment) => preparationAttachment.id === this.$route.params.documentId,
            );
            this.selectedPreparationAttachmentId = this.$route.params.documentId;
            this.preparationAttachmentIndex = this.preparationAttachments.indexOf(this.preparationAttachment);
            this.observations = sortComments(
                this.allObservations.filter(
                    (observation) => observation.preparationDocumentId === this.$route.params.documentId,
                ),
            );
            updateBreadCrumbs({
                documentName: this.preparationAttachment.name,
            });

            const previousHash = window.location.hash;
            window.location.hash = '';
            this.$nextTick(() => {
                window.location.hash = previousHash;
            });
        },
        previousPreparationAttachment() {
            this.$router.push({
                name: this.$route.name,
                params: {
                    ...this.$route.params,
                    documentId: this.preparationAttachments[this.preparationAttachmentIndex - 1].id,
                },
            });
        },
        selectPreparationAttachment(documentId) {
            this.$router.push({
                name: this.$route.name,
                params: { ...this.$route.params, documentId: documentId },
            });
        },
        nextPreparationAttachment() {
            this.$router.push({
                name: this.$route.name,
                params: {
                    ...this.$route.params,
                    documentId: this.preparationAttachments[this.preparationAttachmentIndex + 1].id,
                },
            });
        },
        onStartCapture() {
            this.pickPosition = false;
            this.$refs.viewer.drawRectangle();
        },
        async onStartPickPosition() {
            this.pickPosition = true;
            this.$refs.viewer.stopDrawing();
        },
        async onCaptureDrawn(shape) {
            this.$refs.viewer.capture(shape);
        },
        onSelectShape(shape) {
            this.selectedObservationId = shape.observationId;
            window.location.hash = '#uuid_' + this.selectedObservationId;
        },
        onPickPosition({ x, y }) {
            if (this.pickPosition) {
                return updateObservation(this.$route.params.projectId, {
                    id: this.editObservationId,
                    footprint: {
                        type: 'marker',
                        x,
                        y,
                        page:
                            this.selectedObservation.page === this.currentPage
                                ? this.selectedObservation.page
                                : this.currentPage,
                    },
                    page:
                        this.selectedObservation.page === this.currentPage
                            ? this.selectedObservation.page
                            : this.currentPage,
                });
            }
        },
        async removeMarker(observation) {
            await updateObservation(this.$route.params.projectId, {
                id: observation.id,
                footprint: null,
                page: null,
            });
        },
        async onCaptured(blob, footprint) {
            const formData = new FormData();
            const fileName = 'Capture_' + this.preparationAttachment.name + '.jpg';
            formData.append('files', blob, fileName);
            await fetch(
                `/api/projects/${this.$route.params.projectId}/observations/${this.selectedObservation.id}/attachment`,
                {
                    method: 'POST',
                    body: formData,
                    headers: getAPIHeaders(),
                },
            );
            await updateObservation(this.$route.params.projectId, {
                id: this.editObservationId,
                footprint,
                page:
                    this.selectedObservation.page === this.currentPage
                        ? this.selectedObservation.page
                        : this.currentPage,
            });
            this.$refs.popup.open();
        },
        onClose(observation) {
            this.pickPosition = false;
            this.editObservationId = null;
            this.onSaveObservation(observation);
        },
        async onNewObservation() {
            const result = await createObservation(this.$route.params.projectId, {
                page: this.currentPage,
                preparationDocumentId: this.$route.params.documentId,
                relatedContentId: this.$route.params.preparationVisaId,
                relatedContentType: 'preparationVisa',
                reportedBy: this.visa.emitterId,
                type: 'preparationVisa',
                recipientIds: this.preparation.bundleId ? [this.preparation.bundleId] : [],
            });
            this.editObservationId = result.id;
            this.selectedObservationId = result.id;
            window.location.hash = '#uuid_' + result.id;
            this.pickPosition = true;
        },
        onPageChanging() {
            if (this.editObservationId) {
                const observation = this.observations.find((observation) => observation.id === this.editObservationId);
                this.onSaveObservation(observation);
            }
            this.editObservationId = null;
        },
        onPageChanged(page) {
            this.currentPage = page;
            this.isMultiplePage = this.$refs.viewer.numPages > 1;
        },
        onEdit(comment) {
            if (comment.page && comment.page !== this.currentPage) {
                this.currentPage = comment.page;
            }
            this.editObservationId = comment.id;
            this.selectedObservationId = comment.id;
        },
        onRotate(rotation) {
            this.preparationAttachment.rotation = rotation;
            updatePreparationAttachment(this.$route.params.projectId, { id: this.preparationAttachment.id, rotation });
        },
        onSaveObservation(observation) {
            const observationEntity = sanitize(observation, [
                'index',
                'emitter',
                'user',
                'attachments',
                'relatedContentId',
                'validator',
                'reporter',
                'obsoleter',
                'classes',
                'resolver',
            ]);
            return updateObservation(this.$route.params.projectId, {
                id: observation.id,
                ...observationEntity,
            });
        },
        async onDeleteObservation(observation) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                this.editObservationId = null;
                await removeObservation(this.$route.params.projectId, observation.id);
                this.selectedObservationId = null;
            }
        },
    },
    computed: {
        toFixCount() {
            return this.observations.filter(
                (observation) => !observation.obsoleteAt && !observation.validatedAt && !observation.resolvedAt,
            ).length;
        },
        fixedCount() {
            return this.observations.filter(
                (observation) => !observation.obsoleteAt && !observation.validatedAt && observation.resolvedAt,
            ).length;
        },
        validatedCount() {
            return this.observations.filter((observation) => !observation.obsoleteAt && observation.validatedAt).length;
        },
        obseleteCount() {
            return this.observations.filter((observation) => observation.obsoleteAt).length;
        },
        quickActions() {
            if (this.disabled) {
                return [];
            } else {
                return [
                    {
                        name: this.$t('preparations.newObservation'),
                        run: () => this.onNewObservation(),
                    },
                ];
            }
        },
        filteredObservations() {
            return this.observations.filter(
                (observation) =>
                    (!observation.obsoleteAt &&
                        !observation.validatedAt &&
                        !observation.resolvedAt &&
                        this.showToFix) ||
                    (!observation.obsoleteAt && !observation.validatedAt && observation.resolvedAt && this.showFixed) ||
                    (!observation.obsoleteAt && observation.validatedAt && this.showValidated) ||
                    (observation.obsoleteAt && this.showObsolete),
            );
        },
        selectedObservation() {
            if (this.selectedObservationId) {
                return this.observations.find((observation) => observation.id === this.selectedObservationId);
            } else return null;
        },
        shapes() {
            return this.observations
                .filter((observation) =>
                    this.editObservationId
                        ? observation.id === this.editObservationId &&
                          observation.footprint &&
                          observation.page === this.currentPage
                        : observation.footprint && observation.page === this.currentPage,
                )
                .map((observation) => ({
                    ...observation.footprint,
                    label: observation.index + 1 + '',
                    title: observation.title,
                    htmlTitle: observation.title,
                    style: {
                        color: observation.obsoleteAt
                            ? 'white'
                            : observation.validatedAt
                            ? 'rgb(34 197 94)'
                            : observation.resolvedAt
                            ? ' rgb(134 239 172)'
                            : 'red',
                        border: 'solid 1px',
                        stroke: 1,
                    },
                    observationId: observation.id,
                }));
        },
    },
};
</script>
