<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-drop-down-button
                    :placeholder="$t('project.diffusion.mailTemplates.newMailTemplate') + '...'"
                    @input="addMailTemplate"
                    v-if="!readOnly"
                >
                    <option :value="type" v-for="type in allowedTypes">
                        {{ $t('project.diffusion.mailTemplates.types.' + type) }}
                    </option>
                    <option value="other">
                        {{ $t('project.diffusion.mailTemplates.types.other') }}
                    </option>
                </app-drop-down-button>
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                    <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                    <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    <option value="duplicate" v-if="selection.length === 1">{{ $t('commons.actionDuplicate') }}</option>
                </app-select>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 15rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('type')"
                            >
                                {{ $t('commons.type') }}
                                <div v-if="sortKey === 'type'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                <span>{{ $t('commons.name') }}</span>
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="mailTemplate in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + mailTemplate.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="mailTemplate.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(mailTemplate)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ $t('project.diffusion.mailTemplates.types.' + mailTemplate.type) }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'mailTemplate',
                                            params: {
                                                ...$route.params,
                                                mailTemplateId: mailTemplate.id,
                                            },
                                        }"
                                    >
                                        {{ mailTemplate.name }}
                                        <span v-if="!mailTemplate.name || mailTemplate.name.trim().length === 0">
                                            ...
                                        </span>
                                    </router-link>
                                    <icon-lock
                                        width="16"
                                        height="16"
                                        class="text-gray-400"
                                        v-if="!mailTemplate.projectId"
                                    />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { filterMatch, sortBy, uniq } from '@/services/sanitize.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import { getProject, queryProject } from '@/features/projects/projects.service';
import {
    createMailTemplate,
    getMailTemplates,
    removeMailTemplate,
} from '@/features/mailTemplates/mailTemplates.service';
import { combineLatest } from 'rxjs';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';

export default {
    components: {
        AppDropDownButton,
        AppSelect,
        AppQuickActions,
        AppCheckbox,
        AppMultiPicker,
        AppButton,
    },
    async created() {
        this.restoreSelection();
        this.restoreFilter();
        this.restoreSort();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_diffusion');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'type') {
                        return item.type;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.type'),
                    id: this.$t('commons.type'),
                    children: [
                        'planning',
                        'observationsReport',
                        'preparationVisa',
                        'meetingReport',
                        'preparationsReport',
                        'convocations',
                        'other',
                    ].map((type, index) => ({
                        id: type,
                        name: this.$t('project.diffusion.mailTemplates.types.' + type),
                        firstOfCriteriaType: index === 0,
                        _isTypeCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('project.diffusion.mailTemplates.isDefault'),
                    id: this.$t('project.diffusion.mailTemplates.isDefault'),
                    children: [
                        {
                            id: 'isDefault',
                            name: this.$t('project.diffusion.mailTemplates.isDefault'),
                            firstOfCriteriaType: true,
                            _isDefaultCriteria: true,
                        },
                        {
                            id: 'isCustom',
                            name: this.$t('project.diffusion.mailTemplates.isCustom'),
                            firstOfCriteriaType: false,
                            _isDefaultCriteria: true,
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getMailTemplates(this.$route.params.projectId),
                ]).subscribe(([project, mailTemplates]) => {
                    this.allowedTypes = uniq(
                        mailTemplates
                            .filter((mailTemplate) => mailTemplate.projectId === null)
                            .map((mailTemplate) => mailTemplate.type),
                    );
                    this.items = mailTemplates
                        .filter((mailTemplate) => {
                            return (
                                !mailTemplate.requiredFeatures ||
                                mailTemplate.requiredFeatures.length === 0 ||
                                !!mailTemplate.requiredFeatures?.find((requiredFeature) =>
                                    project.projectFeatures.includes(requiredFeature),
                                )
                            );
                        })
                        .map((mailTemplate) => {
                            return {
                                ...mailTemplate,
                                isSelected: this.selection.includes(mailTemplate.id),
                            };
                        });
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        saveSort() {
            localStorage.setItem(
                'mailTemplates_sort_' + this.$route.params.projectId,
                JSON.stringify({
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreSort() {
            const cache = localStorage.getItem('mailTemplates_sort_' + this.$route.params.projectId);
            if (cache) {
                const sortCache = JSON.parse(cache);
                this.sortKey = sortCache.sortKey || 'index';
                this.sortAsc = sortCache.sortAsc !== false;
            }
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.name, criteria, true));
        },
        scrollToLastVisited() {
            const lastVisitedMailTemplateId = localStorage.getItem(
                'mailTemplates.lastVisitedAutoControlId.' + this.$route.params.projectId,
            );
            if (lastVisitedMailTemplateId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedMailTemplateId);
                    if (element) element.scrollIntoView();
                });
            }
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        async onAction(action) {
            if (action === 'delete') {
                const selectedItems = this.items.filter((item) => this.selection.includes(item.id) && item.isCustom);
                if (await confirm(this.$t('commons.confirmMessageAll', { number: selectedItems.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeMailTemplate(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'duplicate') {
                const selectedItem = this.items.find((item) => this.selection[0] === item.id);
                await createMailTemplate(this.$route.params.projectId, {
                    type: selectedItem.type,
                    name: selectedItem.name.replace('(modèle par défaut)', ' copie'),
                    subject: selectedItem.subject,
                    body: selectedItem.body,
                    to: JSON.parse(JSON.stringify(selectedItem.to)),
                    cc: JSON.parse(JSON.stringify(selectedItem.cc)),
                });
            }
        },
        toggleSelectAll() {
            const customItems = this.filteredItems.filter((item) => item.isCustom).map((item) => item.id);
            if (this.selection.length < customItems.length) {
                this.selection = customItems;
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'lastVisitedMails.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('lastVisitedMails.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addMailTemplate(type) {
            const defaultModel = this.items.find((item) => item.type === type && item.projectId === null);
            let result;
            if (defaultModel) {
                result = await createMailTemplate(this.$route.params.projectId, {
                    type,
                    name: defaultModel.name.replace('(modèle par défaut)', ' copie'),
                    subject: defaultModel.subject,
                    body: defaultModel.body,
                    to: JSON.parse(JSON.stringify(defaultModel.to)),
                    cc: JSON.parse(JSON.stringify(defaultModel.cc)),
                });
            } else {
                result = await createMailTemplate(this.$route.params.projectId, {
                    name: this.$t('project.diffusion.mailTemplates.newName'),
                    type,
                    to: [],
                    cc: [],
                });
            }
            await this.$router.push({
                name: 'mailTemplate',
                params: {
                    projectId: this.$route.params.projectId,
                    mailTemplateId: result.id,
                },
            });
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'mailTemplates_filter_' + this.$route.params.projectId,
                JSON.stringify({ filterValue }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('mailTemplates_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria).map((criteria) => criteria.id);
            const defaultCriteria = filter
                .filter((aCriteria) => aCriteria._isDefaultCriteria)
                .map((criteria) => criteria.id);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchTypeCriteria: typeCriteria.length === 0 || typeCriteria.includes(item.type),
                    matchDefaultCriteria:
                        defaultCriteria.length === 0 ||
                        defaultCriteria.includes(item.isCustom ? 'isCustom' : 'isDefault'),
                    matchString: this.matchString(stringCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
    },
    data() {
        return {
            sortKey: 'name',
            sortAsc: true,
            loading: true,
            readOnly: true,
            selection: [],
            allowedTypes: [],
            subscriptions: [],
            items: [],
            filterValue: [],
            quickActions: [
                {
                    name: this.$t('project.diffusion.mailTemplates.newMailTemplate'),
                    run: () => this.addMailTemplate(),
                },
            ],
        };
    },
};
</script>
