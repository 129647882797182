<template>
    <nav aria-label="Tabs">
        <ul class="h-full" :class="{ flex: !vertical, 'border-b': !vertical }">
            <li
                v-for="(value, index) in values"
                :key="index"
                class="mr-1 bg-white flex-grow flex cursor-pointer hover:bg-gray-200"
                :class="{
                    '-mb-px hover:bg-transparent border-l border-r border-t font-bold':
                        !vertical && (selectedId === value.id || (!selectedId && index === 0)),
                    'hover:bg-transparent border-b border-t font-bold border-l-4 border-main text-main ':
                        vertical && (selectedId === value.id || (!selectedId && index === 0)),
                    ' border-r border-main': vertical && (selectedId !== value.id || (!selectedId && index !== 0)),
                    'opacity-50 disabled': value.disabled,
                    'flex justify-center': !vertical,
                }"
                @click.prevent="!value.disabled ? onSelectTab(value) : null"
            >
                <span class="px-4" v-if="value.disabled" :class="{ 'py-2 inline-block': !vertical, 'py-4': vertical }">
                    {{ value[labelProp] }}
                </span>
                <a v-else class="px-4" href="#" :class="{ 'py-2 inline-block': !vertical, 'py-4': vertical }">
                    {{ value[labelProp] }}
                </a>
            </li>
            <li v-if="vertical" class="mr-1 bg-white flex-grow flex border-r min-h-full border-main"></li>
        </ul>
    </nav>
</template>
<script>
export default {
    props: {
        values: Array,
        selectedId: String,
        labelProp: String,
        vertical: false,
    },
    methods: {
        async onSelectTab($event) {
            await this.$router.push({ name: $event.id });
            this.$emit('select', $event);
        },
    },
};
</script>
