var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('app-separator',{staticClass:"cursor-pointer mb-0",on:{"click":function($event){$event.preventDefault();return _vm.onToggleDetail.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center font-normal w-full gap-1"},[_c('icon',{staticClass:"mr-3",attrs:{"name":_vm.detailIsOpen ? 'icon-chevron-down' : 'icon-chevron-up'}}),(_vm.structure === 'FLB')?_c('div',{staticClass:"flex items-center mr-3"},[_c('router-link',{staticClass:"underline flex",attrs:{"to":{
                        name: 'folders',
                        params: { projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.location.fullName)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'bundle',
                        params: { bundleId: _vm.task.service.bundleId, projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.service.bundle.label)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'service',
                        params: {
                            bundleId: _vm.task.service.bundleId,
                            serviceId: _vm.task.serviceId,
                            projectId: _vm.$route.params.projectId,
                        },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.task.name))])])],1):(_vm.structure === 'FBL')?_c('div',{staticClass:"flex items-center mr-3"},[_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'folders',
                        params: { projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.location.fullName)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'bundle',
                        params: { bundleId: _vm.task.service.bundleId, projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.service.bundle.label)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'service',
                        params: {
                            bundleId: _vm.task.service.bundleId,
                            serviceId: _vm.task.serviceId,
                            projectId: _vm.$route.params.projectId,
                        },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.task.name))])])],1):_c('div',{staticClass:"flex items-center mr-3"},[_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'bundle',
                        params: { bundleId: _vm.task.service.bundleId, projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.service.bundle.label)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'folders',
                        params: { projectId: _vm.$route.params.projectId },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.task.location.fullName)+"\n                ")]),_c('icon-chevron-right'),_c('router-link',{staticClass:"underline",attrs:{"to":{
                        name: 'service',
                        params: {
                            bundleId: _vm.task.service.bundleId,
                            serviceId: _vm.task.serviceId,
                            projectId: _vm.$route.params.projectId,
                        },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.task.name))])])],1),_c('div',{staticClass:"flex gap-1 items-center text-sm"},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.from')))]),_c('span',{staticClass:"underline",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('navigateToDate', _vm.startDate)}}},[_vm._v("\n                    "+_vm._s(_vm._f("humanizeDate")(_vm.startDate))+"\n                ")]),(_vm.endDate)?_c('span',[_vm._v(_vm._s(_vm.$t('commons.to')))]):_vm._e(),_c('span',{staticClass:"underline",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('navigateToDate', _vm.endDate)}}},[_vm._v("\n                    "+_vm._s(_vm._f("humanizeDate")(_vm.endDate))+"\n                ")])])],1)]),(_vm.detailIsOpen)?_c('div',[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }