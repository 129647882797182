export function transposeShapeRotation(shape, rotation) {
    if (shape.type === 'rectangle') {
        if (rotation === 90 || rotation === -270) {
            return { ...shape, x1: 100 - shape.y2, y1: shape.x1, x2: 100 - shape.y1, y2: shape.x2 };
        } else if (rotation === 180 || rotation === -180) {
            return { ...shape, x1: 100 - shape.x2, y1: 100 - shape.y2, x2: 100 - shape.x1, y2: 100 - shape.y1 };
        } else if (rotation === 270 || rotation === -90) {
            return { ...shape, x1: shape.y1, y1: 100 - shape.x2, x2: shape.y2, y2: 100 - shape.x1 };
        }
    } else if (shape.type === 'arrow' || shape.type === 'doubleArrow' || shape.type === 'segment') {
        if (rotation === 90 || rotation === -270) {
            return { ...shape, x1: 100 - shape.y1, y1: shape.x1, x2: 100 - shape.y2, y2: shape.x2 };
        } else if (rotation === 180 || rotation === -180) {
            return { ...shape, x1: 100 - shape.x1, y1: 100 - shape.y1, x2: 100 - shape.x2, y2: 100 - shape.y2 };
        } else if (rotation === 270 || rotation === -90) {
            return { ...shape, x1: shape.y1, y1: 100 - shape.x1, x2: shape.y2, y2: 100 - shape.x2 };
        }
    } else if (shape.type === 'ellipse') {
        if (rotation === 90 || rotation === -270) {
            return { ...shape, cx: 100 - shape.cy, cy: shape.cx, rx: shape.ry, ry: shape.rx };
        } else if (rotation === 180 || rotation === -180) {
            return {
                ...shape,
                cx: 100 - shape.cx,
                cy: 100 - shape.cy,
                rx: shape.rx,
                ry: shape.ry,
            };
        } else if (rotation === 270 || rotation === -90) {
            return { ...shape, cx: shape.cy, cy: 100 - shape.cx, rx: shape.ry, ry: shape.rx };
        }
    } else if (shape.type === 'marker' || shape.type === 'filledMarker') {
        const { x, y } = transposeXYWithRotation(shape.x, shape.y, rotation);
        return { ...shape, x, y };
    } else if (shape.type === 'polyline' || shape.type === 'polygon') {
        const isMultiDimensionalArray = shape.latLngs.every((subTable) => subTable.every(Array.isArray));
        return {
            ...shape,
            latLngs: isMultiDimensionalArray
                ? shape.latLngs.map((subShape) =>
                      subShape.map((latLng) => {
                          const { x, y } = transposeXYWithRotation(latLng[1], latLng[0], rotation);
                          return [x, y];
                      }),
                  )
                : shape.latLngs.map((latLng) => {
                      const { x, y } = transposeXYWithRotation(latLng[1], latLng[0], rotation);
                      return [x, y];
                  }),
        };
    }
    return shape;
}
export function transposeXYWithRotation(x, y, rotation) {
    if (rotation === 90 || rotation === -270) {
        return { x: 100 - y, y: x };
    } else if (rotation === 180 || rotation === -180) {
        return { x: 100 - x, y: 100 - y };
    } else if (rotation === 270 || rotation === -90) {
        return { x: y, y: 100 - x };
    } else {
        return { x, y };
    }
}
