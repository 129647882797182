<template>
    <main class="flex flex-col w-full gap-2 max-h-main min-h-main items-center overflow-hidden">
        <create-observation-popup ref="popup"></create-observation-popup>
        <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
            <form class="flex flex-col gap-4 p-2">
                <fieldset>
                    <legend class="text-gray-700 font-bold mb-2">{{ $t('commons.showHide') }}</legend>
                    <app-checkbox
                        :label="$t('observations.EXEObservations')"
                        v-model="settings.showEXE"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.OPRObservations')"
                        v-model="settings.showOPR"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.ReceiptObservations')"
                        v-model="settings.showReceipt"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.OPLObservations')"
                        v-model="settings.showOPL"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.DeliveryObservations')"
                        v-model="settings.showDelivery"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        :label="$t('observations.APAObservations')"
                        v-model="settings.showAPA"
                        @input="updateState"
                    ></app-checkbox>
                    <app-checkbox
                        class="mt-2"
                        :label="$t('observations.toFix')"
                        v-model="settings.showToFix"
                        @input="updateState"
                    >
                        <span style="width: 1em; height: 1em" class="border observations-to-fix-late mr-1"></span>
                    </app-checkbox>
                    <app-checkbox :label="$t('observations.fixed')" v-model="settings.showFixed" @input="updateState">
                        <span style="width: 1em; height: 1em" class="border observations-done mr-1"></span>
                    </app-checkbox>
                    <app-checkbox
                        :label="$t('observations.validatedPlural')"
                        v-model="settings.showValidated"
                        @input="updateState"
                    >
                        <span style="width: 1em; height: 1em" class="border observations-validated mr-1"></span>
                    </app-checkbox>
                    <app-checkbox
                        :label="$t('observations.obsolete')"
                        v-model="settings.showObsolete"
                        @input="updateState"
                    >
                        <span style="width: 1em; height: 1em" class="border bg-gray-600 mr-1"></span>
                    </app-checkbox>
                    <template v-if="settings.viewMode === 'list'">
                        <app-checkbox
                            class="mt-2"
                            :label="$t('observations.showLocatedObservations')"
                            v-model="settings.showLocatedObservations"
                            @input="updateState"
                        ></app-checkbox>
                        <app-checkbox
                            :label="$t('observations.showGeneralObservations')"
                            v-model="settings.showGeneralObservations"
                            @input="updateState"
                        ></app-checkbox>
                    </template>
                    <app-multi-picker
                        ref="filter"
                        icon="icon-magnify"
                        :allowStringCriteria="true"
                        class="w-full my-2"
                        v-model="settings.bundleFilterValue"
                        :options="filterOptions"
                        :strictMatching="true"
                        @input="onFilterUpdated"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option" />
                        </template>
                    </app-multi-picker>
                </fieldset>
                <app-footer @click="$refs.settings.close()"></app-footer>
            </form>
            <div style="padding-bottom: 40px"></div>
        </app-popup>
        <div class="flex gap-4 items-center w-full">
            <div class="flex flex-col w-full">
                <div class="flex justify-between mx-4">
                    <app-button
                        class="flex md:hidden"
                        size="mini"
                        icon="icon-chevron-left"
                        :title="$t('zones.previousZone')"
                        :disabled="zoneIndex === 0"
                        @click="previousZone()"
                    ></app-button>
                    <app-button
                        size="mini"
                        icon="icon-chevron-left"
                        class="hidden md:flex"
                        :title="$t('zones.previousZone')"
                        :label="$t('zones.previousZone')"
                        :disabled="zoneIndex === 0"
                        @click="previousZone()"
                    ></app-button>
                    <div class="flex gap-4 items-center">
                        <zone-view-mode-toolbar v-model="settings.viewMode" @input="changeViewMode" />
                        <app-button
                            size="mini"
                            @click="$refs.settings.open()"
                            :aria-label="$t('commons.settings')"
                            :title="$t('commons.settings')"
                            icon="icon-tune"
                        />
                    </div>
                    <app-button
                        size="mini"
                        class="md:flex-row-reverse flex md:hidden"
                        icon="icon-chevron-right"
                        :title="$t('zones.nextZone')"
                        :disabled="zoneIndex === zones.length - 1"
                        @click="nextZone()"
                        iconClass="md:ml-4"
                    ></app-button>
                    <app-button
                        size="mini"
                        icon="icon-chevron-right"
                        :title="$t('zones.nextZone')"
                        :disabled="zoneIndex === zones.length - 1"
                        :label="$t('zones.nextZone')"
                        @click="nextZone()"
                        iconClass="ml-4"
                        class="flex-row-reverse hidden md:flex"
                    ></app-button>
                </div>

                <div
                    class="flex justify-center items-center gap-2"
                    v-if="settings.viewMode === 'map' || settings.viewMode === 'list'"
                >
                    <div class="flex flex-col items-center" v-if="settings.viewMode === 'map'">
                        <app-select
                            v-if="selectedSupport && loaded"
                            @input="onSupportIdSelected"
                            class="text-xs"
                            v-model="settings.selectedSupportId"
                        >
                            <template v-for="support in supports">
                                <option :value="support.id">
                                    {{ support.name }} ({{ support.observations.length }})
                                </option>
                            </template>
                        </app-select>
                    </div>
                    <app-button
                        size="mini"
                        v-if="settings.viewMode === 'list'"
                        :label="$t('observations.newObservation')"
                        @click="$refs.popup.start()"
                    ></app-button>

                    <app-button
                        icon="icon-format-list-checks"
                        size="mini"
                        :label="$t('observations.tourLists.checkList.title')"
                        @click="openCheckList()"
                        v-if="isCheckListAllowed && settings.viewMode === 'list'"
                    ></app-button>
                    <observations-counter-badge
                        :items="openObservations"
                        class="px-1 observations-to-do-late text-white text-xs"
                        :title="$t('observations.toFix')"
                    />
                    <observations-counter-badge
                        :items="fixedObservations"
                        class="px-1 observations-done text-white text-xs"
                        :title="$t('observations.fixed')"
                    />
                    <observations-counter-badge
                        :items="closedObservations"
                        class="px-1 observations-validated text-xs"
                        :title="$t('observations.validatedPlural')"
                    />
                </div>
            </div>
        </div>
        <div class="w-full h-full flex justify-center overflow-auto">
            <zone-map
                v-if="settings.viewMode === 'map'"
                @openGenerals="openGenerals"
                :readOnly="readOnly"
                :support="selectedSupport"
                :observations="filteredObservations"
            />
            <zone-list
                v-else-if="settings.viewMode === 'list'"
                :observations="filteredObservations"
                :isMOE="isMOE"
                :me="me"
            />
        </div>
        <app-popup ref="checkList" :showHeader="true" :title="$t('observations.tourLists.checkList.title')">
            <div class="p-2 pb-32">
                <zone-check-list :observations="observations" />
            </div>
        </app-popup>
    </main>
</template>

<script>
import AppTips from '@/components/app-tips/AppTips';
import { updateBreadCrumbs } from '@/state/state';
import ZoneMap from '@/features/tours/ZoneMap';
import ZoneList from '@/features/tours/ZoneList';
import ZoneCheckList from '@/features/tours/ZoneCheckList';
import AppButton from '@/components/appButton/AppButton';
import ZoneViewModeToolbar from '@/features/tours/ZoneViewModeToolbar';
import ObservationsCounterBadge from '@/features/tours/ObservationsCounterBadge';
import AppFooter from '@/components/appFooter/AppFooter';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppPopup from '@/components/app-popup/AppPopup';
import CreateObservationPopup from '@/features/tours/CreateObservationPopup';
import { combineLatest } from 'rxjs';
import { getSupports } from '@/features/supports/supports.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getObservations } from '@/features/observations/observation.service';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import { getMapById } from '@/services/sanitize.service';
import { getSettings, updateSettings } from '@/features/tours/tours.service';

export default {
    components: {
        AppMultiPicker,
        AppBundle,
        AppSelect,
        CreateObservationPopup,
        AppPopup,
        AppCheckbox,
        AppFooter,
        ObservationsCounterBadge,
        ZoneViewModeToolbar,
        AppButton,
        ZoneCheckList,
        ZoneList,
        ZoneMap,
        AppTips,
    },
    async created() {
        const settings = getSettings(this.$route.params.projectId);
        this.settings = {
            ...settings,
            bundleFilterValue: (settings.filterValue || []).filter((criteria) => criteria._isBundleCriteria),
        };
        queryProject(this.$route.params.projectId).then((project) => {
            this.isCheckListAllowed = project.me.allowedFeatures.includes('project_observations_checkList');
            this.me = project.me;
            this.isMOE = project.me.allowedFeatures.includes('project_observations_MOEValidation');
        });
        this.subscriptions = [
            combineLatest([
                getSupports(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getObservations(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
            ]).subscribe(([supports, folders, observations, bundles]) => {
                this.bundles = bundles;
                const bundleMap = getMapById(bundles);
                this.data = { supports, folders, observations, bundleMap };
                this.refresh();
                this.loaded = true;
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_observations');
        });
    },
    computed: {
        filteredObservations() {
            return this.observations.filter((observation) => {
                return (
                    (!observation.obsoleteAt || this.settings.showObsolete) &&
                    ((observation.phase === 'EXE' && this.settings.showEXE) ||
                        (observation.phase === 'OPR' && this.settings.showOPR) ||
                        (observation.phase === 'Receipt' && this.settings.showReceipt) ||
                        (observation.phase === 'Delivery' && this.settings.showDelivery) ||
                        (observation.phase === 'OPL' && this.settings.showOPL) ||
                        (observation.phase === 'APA' && this.settings.showAPA)) &&
                    ((!observation.resolvedAt && this.settings.showToFix) ||
                        (!!observation.resolvedAt && this.settings.showFixed && !observation.validatedAt) ||
                        (!!observation.resolvedAt && this.settings.showValidated && observation.validatedAt)) &&
                    (this.settings.viewMode !== 'list' ||
                        (!observation.footprint && this.settings.showGeneralObservations) ||
                        (!!observation.footprint && this.settings.showLocatedObservations))
                );
            });
        },
        selectedSupport() {
            return this.supports.find((support) => support.id === this.settings.selectedSupportId);
        },
        filterOptions() {
            if (!this.bundles) {
                return [];
            }
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.reference ? bundle.reference + ' - ' + bundle.name : +bundle.name,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
            ];
        },
    },
    watch: {
        $route() {
            this.refresh();
        },
    },
    methods: {
        refresh() {
            const folder = this.data.folders.find((folder) => folder.id === this.$route.params.folderId);
            const location = folder.locations.find((folder) => folder.id === this.$route.params.locationId);
            this.zones = location.zones;
            const zone = this.zones.find((folder) => folder.id === this.$route.params.zoneId);
            this.zoneIndex = this.zones.indexOf(zone);
            this.observations = this.data.observations
                .filter(
                    (observation) =>
                        observation.zoneId === zone.id &&
                        (this.settings.bundleFilterValue.length === 0 ||
                            this.settings.bundleFilterValue.find((bundle) =>
                                observation.recipientIds.includes(bundle.id),
                            )),
                )
                .map((observation) => ({
                    ...observation,
                    recipients: observation.recipientIds.map((recipientId) => this.data.bundleMap[recipientId]),
                    zone,
                    room: observation.roomId ? zone.rooms.find((room) => room.id === observation.roomId) : null,
                }));
            this.openObservations = this.observations.filter(
                (observation) => !observation.obsoleteAt && !observation.resolvedAt,
            );
            this.fixedObservations = this.observations.filter(
                (observation) => !observation.obsoleteAt && observation.resolvedAt && !observation.validatedAt,
            );
            this.closedObservations = this.observations.filter(
                (observation) => !observation.obsoleteAt && observation.validatedAt,
            );
            this.generalObservations = this.observations.filter(
                (observation) => !observation.obsoleteAt && !observation.footprint,
            );
            this.supports = this.data.supports
                .filter((support) => support.locationIds.includes(zone.id))
                .map((support) => ({
                    ...support,
                    observations: this.observations.filter((observation) => observation.supportId === support.id),
                }));
            updateBreadCrumbs({
                zoneName: zone.name,
                locationName: zone.parent.name,
                folderName: zone.parent.parent.name,
            });
            if (
                this.settings.selectedSupportId &&
                this.supports.find((support) => support.id === this.settings.selectedSupportId)
            ) {
                this.onSupportIdSelected(this.settings.selectedSupportId);
            } else if (this.supports.length > 0) {
                this.onSupportIdSelected(
                    this.supports.reduce(
                        (acc, support) => (acc.observations.length > support.observations.length ? acc : support),
                        this.supports[0],
                    ).id,
                );
            }
        },

        openCheckList() {
            this.$refs.checkList.open();
        },
        onSupportIdSelected(id) {
            this.settings.selectedSupportId = id;
            this.updateState();
        },
        updateState() {
            const { bundleFilterValue, ...settings } = this.settings;
            updateSettings(this.$route.params.projectId, {
                ...settings,
                filterValue: [
                    ...(settings.filterValue || []).filter((criteria) => !criteria._isBundleCriteria),
                    ...bundleFilterValue,
                ],
            });
        },
        onFilterUpdated() {
            this.refresh();
            this.updateState();
        },
        changeViewMode(to) {
            this.settings.viewMode = to;
            this.updateState();
        },
        openGenerals() {
            this.settings.showLocatedObservations = false;
            this.settings.showGeneralObservations = true;
            this.settings.viewMode = 'list';
        },
        navigateTo: function (name) {
            this.$router.push({
                name,
                params: {
                    ...this.$route.params,
                },
            });
        },
        nextZone() {
            this.$router.push({
                name,
                params: {
                    ...this.$route.params,
                    zoneId: this.zones[++this.zoneIndex].id,
                },
            });
        },
        previousZone() {
            this.$router.push({
                name,
                params: {
                    ...this.$route.params,
                    zoneId: this.zones[--this.zoneIndex].id,
                },
            });
        },
    },
    data() {
        return {
            settings: {
                viewMode: 'map',
                showEXE: true,
                showOPR: true,
                showReceipt: true,
                showOPL: true,
                showDelivery: true,
                showAPA: true,
                showToFix: true,
                showObsolete: false,
                showGeneralObservations: true,
                showLocatedObservations: true,
                showValidated: false,
                selectedSupportId: null,
                showFixed: false,
                filterValue: [],
                bundleFilterValue: [],
            },
            zoneIndex: 0,
            zones: [],
            supports: [],
            bundles: [],
            checkList: [],
            subscriptions: [],
            observations: [],
            closedObservations: [],
            fixedObservations: [],
            openObservations: [],
            generalObservations: [],
            readOnly: true,
            loaded: false,
            isCheckListAllowed: false,
            isMOE: false,
            data: {},
            me: null,
        };
    },
};
</script>
