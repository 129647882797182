<template>
    <div class="chip-container text-sm focus-within:ring-2" @click="reportFocus($event)">
        <div class="flex flex-wrap items-center">
            <Icon :name="icon" v-if="icon" class="ml-2 text-gray-500" />
            <template v-for="chip of value">
                <div class="chip px-1 m-1 flex items-center text-white" :key="chip.id">
                    <label v-if="!disabled && editable">
                        <span class="hidden">edit tag {{ labelFunction(chip) }}</span>
                        <input
                            type="text"
                            v-model="chip.name"
                            class="bg-transparent font-mono text-sm chip-input"
                            :style="{ 'min-width': '2em', width: chip.name ? chip.name.length + 'ch' : 0 }"
                            @keypress.prevent.enter=""
                        />
                    </label>
                    <span v-else class="cursor-default">{{ labelFunction(chip) }}</span>
                    <div @click="deleteChip(chip)" v-if="!disabled" class="cursor-pointer hover:text-red-700 ml-1">
                        <IconTrashCanOutline width="16" heigth="16"></IconTrashCanOutline>
                        <span class="hidden">remove tag {{ labelFunction(chip) }}</span>
                    </div>
                </div>
            </template>
            <div class="h-8 opacity-50 ml-2 flex items-center" v-if="disabled">
                {{ placeholder ? placeholder : '' }}
            </div>
            <label v-if="!disabled" class="flex-grow">
                <span class="hidden">enter new tag name</span>
                <input
                    class="bg-transparent font-mono new p-2 text-sm outline-none w-full"
                    v-model="currentInput"
                    :disabled="disabled"
                    :style="{ 'min-width': currentInput ? currentInput.length + 'ch' : '2em' }"
                    @keypress.enter="saveChip"
                    @keydown.delete="backspaceDelete"
                    @keyup="$emit('keyup', $event)"
                    @input="$emit('editorInput', $event.target.value)"
                    ref="input"
                    :placeholder="placeholder"
                />
            </label>
        </div>
    </div>
</template>

<script>
import IconTrashCanOutline from '../../icons/IconTrashCanOutline';
export default {
    props: {
        value: {
            type: Array,
            default: () => {},
        },
        disabled: Boolean,
        placeholder: String,
        icon: String,
        editable: { type: Boolean, default: true },
        labelFunction: {
            type: Function,
            default: (item) => item.name,
        },
    },
    components: {
        IconTrashCanOutline,
    },
    name: 'chips-input',
    data() {
        return {
            currentInput: '',
        };
    },

    methods: {
        focus() {
            if (!this.disabled) {
                this.$refs.input.focus();
            }
        },
        clearEditor() {
            this.currentInput = '';
        },
        reportFocus($event) {
            if (
                !this.disabled &&
                ($event.currentTarget.firstChild === $event.target || $event.target === this.$refs.input)
            ) {
                this.$refs.input.focus();
                this.$emit('editorFocus');
            }
        },
        saveChip(event) {
            const content = this.currentInput.trim();
            if (content.length > 0) {
                event.preventDefault();
                const alreadyIn = this.value.find((chip) => chip.name === content);
                if (!alreadyIn) {
                    this.value.push({ name: content });
                    this.currentInput = '';
                }
            }
        },
        deleteChip(chipToRemove) {
            const result = this.value.filter((aValue) => aValue !== chipToRemove);
            this.$emit('input', result);
            this.$emit('uncheck', chipToRemove);
        },
        backspaceDelete({ which }) {
            which === 8 && this.currentInput === '' && this.value.splice(this.value.length - 1);
            this.$emit('input', this.value);
        },
    },
};
</script>

<style scoped>
.chip {
    background-color: #0075a8;
    border-color: #0075a8;
}
</style>
