<template>
    <li v-if="noItemLabel || length >= 1">
        <div class="flex w-full gap-1" v-if="length === 0">
            {{ noItemLabel }}
        </div>
        <div class="flex w-full gap-1" v-else>
            <span :title="tooltip" class="underline">
                {{ length }}
            </span>
            {{ label }}
        </div>
    </li>
</template>
<script>
export default {
    props: {
        noItemLabel: String,
        tooltip: String,
        label: String,
        length: { type: Number },
    },
};
</script>
