<template>
    <fragment>
        <td class="border pr-1 text-right">
            <p style="color: #f74c14">Ref</p>
        </td>
        <td class="border text-center" :style="task.realStartDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.referenceStartDate | humanizeDate }}</span>
        </td>
        <td class="border text-center" :style="task.realEndDate ? 'background-color:rgba(56, 109, 165, 0.11);' : ''">
            <span>{{ task.referenceEndDate | humanizeDate }}</span>
        </td>
        <td class="border text-right" v-if="options.showProgressColumn">
            {{ task.referenceProgress ? Math.round(task.referenceProgress) + '%' : '' }}
        </td>
        <td class="border" v-if="options.showQuantityColumn"></td>
        <td class="border px-1 text-right" v-if="options.showDurationColumn">
            <span>
                {{ task.referenceDuration === 0 || !!task.referenceDuration ? task.referenceDuration : '' }}
            </span>
        </td>
        <td class="border text-right px-1" v-if="options.showLateColumn">
            <span class="" v-if="!task.referenceLate || task.referenceLate === 0">&nbsp;</span>
            <span
                v-else
                :class="{
                    'text-green-600 font-bold': task.referenceLate < 0,
                    'text-red-600 font-bold': task.referenceLate > 0,
                }"
            >
                {{
                    task.referenceLate === 0
                        ? ''
                        : task.referenceLate > 0
                        ? '+' + task.referenceLate
                        : task.referenceLate
                }}
            </span>
        </td>
    </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

export default {
    components: { Fragment },
    props: ['task', 'options'],
};
</script>
