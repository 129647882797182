<template>
    <div class="flex flex-col w-full whitespace-nowrap">
        <span :title="$t('project.follow.planning.referenceStartDate')" v-if="showReferenceData">
            <span class="opacity-0">↦</span>
            <span>
                {{ task.referenceStartDate | humanizeDate }}
            </span>
        </span>
        <span
            :title="
                task.hasXSPredecessor
                    ? $t('project.follow.planning.plannedStartDateWithPredecessor')
                    : $t('project.follow.planning.plannedStartDate')
            "
            v-if="showPlannedData"
        >
            <app-date-link
                v-model="task.startDate"
                :show-label="false"
                :label="
                    task.hasXSPredecessor
                        ? $t('project.follow.planning.plannedStartDateWithPredecessor')
                        : $t('project.follow.planning.plannedStartDate')
                "
                @input="$emit('updateStartDate', { id: task.id, startDate: $event })"
                :disabled="task.children?.length > 0 || task.hasXSPredecessor || disabled"
            >
                <span :class="{ 'opacity-0': !task.hasXSPredecessor }">↦</span>
                <span :class="{ underline: !disabled && !(task.children?.length > 0 || task.hasXSPredecessor) }">
                    {{ (task.startDate || (task.children?.length > 0 ? '' : '...')) | humanizeDate }}
                </span>
            </app-date-link>
        </span>
        <span :title="$t('project.follow.planning.realStartDate')" v-if="showRealData">
            <app-date-link
                v-if="showRealStartDate"
                v-model="task.realStartDate"
                :disabled="task.children?.length > 0 || disabled"
                :label="$t('project.follow.planning.realStartDate')"
                :show-label="false"
                @input="$emit('updateRealStartDate', { id: task.id, realStartDate: $event })"
            >
                <span class="opacity-0">↦</span>
                <span :class="{ underline: !disabled && !(task.children?.length > 0) }">
                    {{ (task.realStartDate || (task.children?.length > 0 ? '' : '...')) | humanizeDate }}
                </span>
            </app-date-link>
            <span v-else>&nbsp</span>
        </span>
    </div>
</template>

<script>
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { humanizeDate } from '@/filters/dateFilter';
export default {
    components: { AppDateLink },
    props: ['task', 'showRealData', 'showReferenceData', 'showPlannedData', 'disabled'],
    methods: {
        humanizeDate,
    },
    computed: {
        showRealStartDate() {
            if (this.task.children?.length > 0) {
                return this.task.children?.some((task) => task.realStartDate) || [];
            } else return true;
        },
    },
};
</script>
