export default async function addToCache(urls) {
    if (navigator.serviceWorker?.ready) {
        await navigator.serviceWorker.ready;
    }
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
            type: 'CACHE_ADD_ALL',
            urls: Array.isArray(urls) ? urls : [urls],
        });
    }
}
