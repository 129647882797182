<template>
    <div class="flex flex-col w-full" :class="state" style="min-height: 1300px">
        <app-separator variant="underline" class="text-lg my-4" v-if="$route.query.showTitle === true">
            {{ zone.fullName }}
        </app-separator>
        <template v-if="observations.length === 0">
            <span>
                {{ $t('project.editions.observationsReports.empty') }}
            </span>
        </template>
        <template v-else>
            <app-leaflet-viewer
                class="w-full my-4"
                v-if="observations.length > 0"
                :markers="observations"
                :src="support.url"
                :page="1"
                :rotation="support.rotation"
                :crop-box="support.cropBox"
                :preview="true"
            ></app-leaflet-viewer>
        </template>
    </div>
</template>
<script>
import {
    getObservationClass,
    getObservations,
    getObservationsForRelatedContent,
} from '@/features/observations/observation.service';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { combineLatest } from 'rxjs';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import {
    filterObservationFromObservationReportConfig,
    getObservationsReport,
} from '@/features/observationsReports/observationsReports.service';
import { getSupports } from '@/features/supports/supports.service';

export default {
    components: { AppSeparator, AppLeafletViewer },
    props: {
        zoneId: String,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getObservationsReport(this.$route.params.projectId, this.$route.params.observationsReportId),
                getLocationsTree(this.$route.params.projectId),
                this.$route.query.relatedContentId
                    ? getObservationsForRelatedContent(this.$route.params.projectId, this.$route.query.relatedContentId)
                    : getObservations(this.$route.params.projectId),
                getSupports(this.$route.params.projectId),
            ]).subscribe(([observationReport, folders, observations, supports]) => {
                this.observationsReport = observationReport;
                let bundleIds;
                if (this.$route.query.bundleId) {
                    bundleIds = [this.$route.query.bundleId];
                } else {
                    bundleIds = this.observationsReport.bundleIds;
                }
                this.zone = locationService
                    .buildLocationOptions(folders)
                    .find((zone) => zone.id === (this.zoneId || this.$route.params.zoneId));

                this.observations = this.observations = filterObservationFromObservationReportConfig(
                    observations,
                    [this.zone.id],
                    bundleIds,
                    this.observationsReport,
                )
                    .filter((observation) => observation.supportId === this.$route.query.supportId)
                    .map((observation) => {
                        return {
                            ...observation.footprint,
                            title: observation.title,
                            label: observation.index + 1,
                            classes: getObservationClass(observation),
                        };
                    });
                this.support = supports.find((support) => support.id === this.$route.query.supportId);
                this.state = '';
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            zone: {},
            observations: [],
            support: [],
            state: 'loading',
        };
    },
};
</script>
