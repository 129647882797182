<template>
    <div class="flex flex-col sm:grid app-double-bar-chart p-4 border w-full" style="grid-template-columns: auto auto">
        <template v-for="serie in series">
            <div class="p-1 my-2 truncate sm:text-clip">{{ serie.label }}</div>
            <div class="flex flex-grow mb-2">
                <div class="flex justify-end items-center" style="width: 50%">
                    <span class="px-1">{{ serie.values[0] }}</span>
                    <div
                        :title="serie.values[0] + ' = ' + Math.round(serie._values[0]) + '%'"
                        :style="{ width: 'calc(' + serie._values[0] + '% - 4ch)' }"
                        class="justify-start flex items-center border-black border"
                        :class="data.options.classes[0]"
                    >
                        &nbsp;
                    </div>
                </div>
                <div class="flex justify-start items-center" style="width: calc(50% - 4ch)">
                    <div
                        :title="serie.values[1] + ' = ' + Math.round(serie._values[1]) + '%'"
                        :style="{ width: 'calc(' + serie._values[1] + '% - 4ch)' }"
                        class="justify-end flex items-center border-black border"
                        :class="data.options.classes[1]"
                    >
                        &nbsp;
                    </div>
                    <span class="px-1">{{ serie.values[1] }}</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default() {
                return {
                    series: [
                        { label: 'Serie 1', values: [5, 10] },
                        { label: 'Serie 2', values: [2, 5] },
                    ],
                    options: {
                        classes: ['bg-green-500', 'bg-red-500'],
                    },
                };
            },
        },
    },
    methods: {
        normalizeSeriesValues(series) {
            const globalMax = series
                .map((serie) => serie.values.reduce((acc, value) => (value > acc ? value : acc), 0))
                .reduce((acc, value) => (value > acc ? value : acc), 0);
            return series.map((serie) => ({
                ...serie,
                _values: [
                    globalMax ? (serie.values[0] / globalMax) * 100 : 0,
                    globalMax ? (serie.values[1] / globalMax) * 100 : 0,
                ],
            }));
        },
    },
    computed: {
        series() {
            return this.normalizeSeriesValues(this.data.series);
        },
    },
};
</script>
