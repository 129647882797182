<template>
    <div style="page-break-inside: avoid">
        <app-separator
            class="mt-4 font-bold"
            variant="underline"
            :label="$t('project.editions.preparationList.legend.title')"
        />

        <table class="text-xs mr-4 mt-4 border">
            <tr class="border" v-if="showDocumentToEmit">
                <td class="p-2"><icon-pause-circle-outline class="text-gray-400" /></td>
                <th class="p-2">
                    <div class="flex justify-start">
                        {{ $t('project.editions.preparationList.legend.documentToEmit') }}
                    </div>
                </th>
            </tr>
            <tr class="border">
                <td class="p-2"><icon-timelapse class="text-secondary" /></td>
                <th class="p-2">
                    <div class="flex justify-start">
                        {{ $t('project.editions.preparationList.legend.incompleteVisa') }}
                    </div>
                </th>
            </tr>
            <tr class="border">
                <td class="p-2"><icon-check-circle-outline class="text-green-600" /></td>
                <th class="p-2">
                    <div class="flex flex-col items-start">
                        <span>{{ $t('project.editions.preparationList.legend.approved') }}</span>
                    </div>
                </th>
            </tr>
            <tr class="border">
                <td class="p-2"><icon-alert-circle-outline class="text-yellow-500" /></td>
                <th class="p-2">
                    <div class="flex flex-col items-start">
                        <span>{{ $t('project.editions.preparationList.legend.approvedWithComments') }}</span>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.approvedWithCommentsTips') }}
                        </app-tips>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.approvedWithCommentsTips2') }}
                        </app-tips>
                    </div>
                </th>
            </tr>
            <tr class="border">
                <td class="p-2"><icon-close-circle-outline class="text-red-700" /></td>
                <th class="p-2">
                    <div class="flex flex-col items-start">
                        <span>{{ $t('project.editions.preparationList.legend.rejected') }}</span>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.rejectedTips') }}
                        </app-tips>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.rejectedTips2') }}
                        </app-tips>
                    </div>
                </th>
            </tr>
            <tr class="border">
                <td class="p-2"><icon-close-octagon class="text-red-700" /></td>
                <th class="p-2">
                    <div class="flex flex-col items-start">
                        <span>{{ $t('project.editions.preparationList.legend.nonCompliant') }}</span>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.nonCompliantTips') }}
                        </app-tips>
                        <app-tips class="font-normal text-left">
                            {{ $t('project.editions.preparationList.legend.nonCompliantTips2') }}
                        </app-tips>
                    </div>
                </th>
            </tr>
        </table>
    </div>
</template>

<script>
import IconTimelapse from '@/icons/IconTimelapse';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline';
import AppTips from '@/components/app-tips/AppTips';
import IconAlertCircleOutline from '@/icons/IconAlertCircleOutline';
import IconCloseCircleOutline from '@/icons/IconCloseCircleOutline';
import IconCloseOctagon from '@/icons/IconCloseOctagon';
import IconFileDocumentOutline from '@/icons/IconFileDocumentOutline';
import IconFileHidden from '@/icons/IconFileHidden';
import AppSeparator from '@/components/appSeparator/AppSeparator';
export default {
    props: {
        showDocumentToEmit: { type: Boolean, default: false },
    },
    components: {
        AppSeparator,
        IconFileHidden,
        IconFileDocumentOutline,
        IconCloseOctagon,
        IconCloseCircleOutline,
        IconAlertCircleOutline,
        AppTips,
        IconCheckCircleOutline,
        IconTimelapse,
    },
};
</script>
