<template>
    <app-select
        :label="$t('toolbar.accountMenu')"
        :show-label="false"
        labelTag="div"
        class="col-span-2 text-xs"
        @input="handleChoice($event)"
        icon="icon-user"
        variant="borderless"
    >
        <option value="user" v-if="$route.name !== 'user'" style="color: initial">{{ $t('user.title') }}</option>
        <option value="logout" v-if="$route.name !== 'login'" style="color: initial">{{ $t('toolbar.logout') }}</option>
    </app-select>
</template>
<script>
import AppSelect from './appSelect/AppSelect';
import database from '@/rxdb/database';
export default {
    name: 'user-dropdown',
    components: { AppSelect },
    methods: {
        handleChoice(choice) {
            if (choice === 'logout') {
                this.logout();
            } else if (choice === 'user') {
                this.$router.push({ name: 'user' });
            }
        },
        logout: function () {
            localStorage.removeItem('token');
            database.logout();
            this.$router.push({
                name: 'login',
            });
        },
    },
};
</script>
