<template>
    <div class="flex flex-col gap-2">
        <div class="flex flex-col text-xs">
            <div class="flex gap-1">
                <span class="pl-1">{{ certificate.createdAt | humanizeDate }}</span>
                <span>{{ $t('commons.createdBy') }}</span>
                <app-bundle-picker-link
                    v-model="reporter"
                    @input="save()"
                    :disabled="!isAdmin"
                    :limited-to-user-bundle="!isAdmin"
                ></app-bundle-picker-link>
            </div>
            <div class="flex flex-col gap-1" v-if="certificate.obsoleteAt">
                <div class="flex gap-1">
                    <app-date-link
                        v-model="certificate.obsoleteAt"
                        @input="save()"
                        :show-label="false"
                        :required="true"
                        :disabled="!isAdmin"
                    />
                    <span>{{ $t('commons.obsoleteBy') }}</span>
                    <app-bundle-picker-link
                        v-model="obsoleter"
                        @input="save()"
                        :disabled="!isAdmin"
                        :limited-to-user-bundle="!isAdmin"
                    ></app-bundle-picker-link>
                </div>
                <div class="ml-4 flex gap-1">
                    <span class="underline" @click="$refs.obsoleteCommentInput.open()">
                        {{ $t('commons.obsoleteComment') }} :
                    </span>
                    <app-multiline-text-link
                        ref="obsoleteCommentInput"
                        v-model="certificate.obsoleteComment"
                        @input="save()"
                    ></app-multiline-text-link>
                </div>
            </div>
        </div>
        <div class="flex my-2 gap-2" v-if="isAdmin || !isAdmin">
            <app-button
                size="mini"
                icon="icon-timer-alert-outline"
                :label="$t('commons.obsolete')"
                @click="obsolete"
                v-if="!certificate.obsoleteAt && isAdmin"
            ></app-button>
            <app-button
                size="mini"
                :label="$t('commons.notObsolete')"
                @click="onNotObsolete"
                icon="icon-undo"
                v-if="certificate.obsoleteAt && isAdmin"
            ></app-button>
            <app-button
                size="mini"
                icon="icon-trash-can-outline"
                variant="danger"
                @click="$emit('remove')"
                :label="$t('commons.removeShort')"
                v-if="isAdmin"
            />
        </div>
    </div>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import { queryProject } from '@/features/projects/projects.service';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import AppBundlePickerLink from '@/components/appBundlePickerLink';
import AppMultilineTextLink from '@/components/appMultilineTextLink';
import { getBundleMap } from '@/features/bundles/bundles.service';

export default {
    components: {
        AppMultilineTextLink,
        AppBundlePickerLink,
        AppDateLink,
        AppButton,
    },
    props: {
        certificate: Object,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_certificates');
            this.me = project.me;
        });
        this.subscriptions = getBundleMap(this.$route.params.projectId).subscribe((bundleMap) => {
            this.bundleMap = bundleMap;
            this.reporter = this.bundleMap[this.certificate.reportedBy];
            this.obsoleter = this.bundleMap[this.certificate.obsoleteBy];
        });
    },
    watch: {
        certificate() {
            this.reporter = this.bundleMap[this.certificate.reportedBy];
            this.obsoleter = this.bundleMap[this.certificate.obsoleteBy];
        },
    },
    methods: {
        obsolete() {
            this.certificate.obsoleteAt = new Date();
            this.certificate.obsoleteBy = this.me.bundleIds[0];
            this.save();
        },
        onNotObsolete() {
            this.notObsolete();
            this.save();
        },
        notObsolete() {
            this.certificate.obsoleteAt = null;
            this.certificate.obsoleteBy = null;
            this.certificate.obsoleteComment = null;
        },
        save() {
            this.$emit('input', {
                id: this.certificate.id,
                reportedBy: this.reporter?.id || this.certificate.reportedBy,
                obsoleteBy: this.obsoleter?.id || this.certificate.obsoleteBy,
                obsoleteAt: this.certificate.obsoleteAt,
                obsoleteComment: this.certificate.obsoleteComment,
            });
        },
    },
    data() {
        return {
            isAdmin: false,
            me: null,
            bundleMap: {},
            obsoleter: null,
            reporter: null,
        };
    },
};
</script>
