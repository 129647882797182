import db from '@/rxdb/database';
import { v4 as uuidv4 } from 'uuid';
import { groupBy } from '@/services/sanitize.service';
import { map, mergeMap } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

export function getSupport(projectId, supportId) {
    return db
        .getProjectCollections(projectId)
        .supports.findOne({ selector: { id: supportId } })
        .$.pipe(
            mergeMap(async (support) => {
                if (!support) {
                    throw new Error(`unknown support [${supportId}]`);
                }
                const versions = await db
                    .getProjectCollections(projectId)
                    .supports.find({ selector: { groupId: support.groupId }, sort: [{ createdAt: 'asc' }] })
                    .exec();
                return {
                    ...mapSupport(support),
                    versions: versions.map((version) => version.toJSON()),
                };
            }),
        );
}

export async function removeSupportGroup(projectId, supportGroupId) {
    const entities = await db
        .getProjectCollections(projectId)
        .supports.find({ selector: { groupId: supportGroupId } })
        .exec();
    return Promise.all(entities.map((entity) => entity.remove()));
}

function mapSupport(supportDb) {
    return {
        ...supportDb.toMutableJSON(),
        emissionDate: supportDb.emissionDate ? new Date(supportDb.emissionDate) : null,
        emissionDueDate: supportDb.emissionDueDate ? new Date(supportDb.emissionDueDate) : null,
    };
}
function mapSupports(supportsDb) {
    const supports = supportsDb.map(mapSupport);
    return groupBy(supports, 'groupId').map((versions) => {
        const lastSupport = versions[versions.length - 1];
        return {
            ...lastSupport,
            versions,
        };
    });
}

export function getSupports(projectId) {
    return getCachedObservable('getSupports_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .supports.find({ selector: {}, sort: [{ createdAt: 'asc' }] })
            .$.pipe(map(mapSupports)),
    );
}
export function getAllSupports(projectId) {
    return getCachedObservable('getAllSupports_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .supports.find({ selector: {}, sort: [{ createdAt: 'asc' }] })
            .$.pipe(map((supports) => supports.map((support) => support.toMutableJSON()))),
    );
}

export function getGraphicSupports(projectId) {
    return getSupports(projectId).pipe(
        map((supports) =>
            supports.filter((support) => support.url && support.emissionDate && support.type === 'graphic'),
        ),
    );
}

export async function updateSupport(projectId, support) {
    const dbSupport = await db
        .getProjectCollections(projectId)
        .supports.findOne({ selector: { id: support.id } })
        .exec();
    const patch = { ...support };
    if (support.emissionDueDate === null || !!support.emissionDueDate) {
        patch.emissionDueDate = support.emissionDueDate ? support.emissionDueDate.toISOString() : null;
    }
    if (support.emissionDate === null || !!support.emissionDate) {
        patch.emissionDate = support.emissionDate ? support.emissionDate.toISOString() : null;
    }
    return dbSupport.atomicPatch(patch);
}
export async function removeSupport(projectId, supportId) {
    const entity = await db
        .getProjectCollections(projectId)
        .supports.findOne({ selector: { id: supportId } })
        .exec();
    return entity.remove();
}
export async function createSupport(projectId, support) {
    const result = await db.getProjectCollections(projectId).supports.insert({
        groupId: support.groupId || uuidv4(),
        locationIds: [],
        ...support,
        emissionDueDate: support.emissionDueDate ? support.emissionDueDate.toISOString() : new Date().toISOString(),
    });
    return result.toJSON();
}

export async function filterLocationIds(projectId, support, filterFn) {
    const dbSupport = await db
        .getProjectCollections(projectId)
        .supports.findOne({ selector: { id: support.id } })
        .exec();
    return dbSupport.atomicPatch({
        locationIds: dbSupport.locationIds.filter(filterFn),
    });
}

export default {
    filterLocationIds,
    updateSupport,
};
