import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const periods = {
    schema: {
        title: 'period schema',
        version: 0,
        description: 'describes off days as periods',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            type: {
                type: 'string',
                final: true,
            },
            projectId: {
                type: ['string', 'null'],
            },
            name: {
                type: ['string', 'null'],
            },
            startDate: {
                type: ['string', 'null'],
            },
            endDate: {
                type: ['string', 'null'],
            },
            bundleIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            rootVersion: {
                type: ['string', 'null'],
            },
        },
        required: ['id'],
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
