<template>
    <ValidationObserver class="block" v-slot="{ invalid, errors, dirty }" ref="observer">
        <form class="m-auto p-2 grid grid-cols-2 mb-24 gap-y-2">
            <div class="flex m-1 col-span-2 text-xs font-bold gap-2">
                <app-label label="Zone : ">
                    {{ zone.name }}
                </app-label>
            </div>
            <span class="col-span-2">
                <observation-status-buttons
                    :observation="observation"
                    @input="updateObservation($event)"
                    @remove="deleteObservation()"
                ></observation-status-buttons>
            </span>
            <app-text-editor
                class="col-span-2"
                :label="$t('observations.titleLabel')"
                v-model="observation.title"
                :inline="true"
                :showToolbar="false"
                @blur="onTitle"
                size="4"
            ></app-text-editor>

            <app-multi-picker
                class="col-span-2 mt-4"
                v-model="observation.recipients"
                :options="bundles"
                label-key="label"
                @close="updateRecipients"
                @removeChips="updateRecipients"
                :label="$t('project.follow.observation.recipients')"
                v-if="observation.type !== 'autoControl' && observation.type !== 'general'"
                :placeholder="$t('commons.all')"
                :disabled="!(isAdmin || isReporter)"
            >
                <template v-slot:option="{ option }">
                    <app-bundle :bundle="option" />
                </template>
            </app-multi-picker>

            <div class="flex flex-col col-span-2 mt-4" v-if="isAdmin || isReporter">
                <app-label :label="$t('commons.phase')"></app-label>
                <div class="flex col-span-2">
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('EXE')"
                        :variant="observation.phase === 'EXE' ? 'selected' : 'default'"
                        :disabled="!isEXEAllowed"
                    >
                        {{ $t('commons.phases.EXE') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('OPR')"
                        :variant="observation.phase === 'OPR' ? 'selected' : 'default'"
                        :disabled="!isOPRAllowed"
                    >
                        {{ $t('commons.phases.OPR') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('Receipt')"
                        :variant="observation.phase === 'Receipt' ? 'selected' : 'default'"
                        :disabled="!isOPRAllowed"
                    >
                        {{ $t('commons.phases.Receipt') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('OPL')"
                        :variant="observation.phase === 'OPL' ? 'selected' : 'default'"
                        :disabled="!isOPLAllowed"
                    >
                        {{ $t('commons.phases.OPL') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('Delivery')"
                        :variant="observation.phase === 'Delivery' ? 'selected' : 'default'"
                        :disabled="!isOPLAllowed"
                    >
                        {{ $t('commons.phases.Delivery') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onPhase('APA')"
                        :variant="observation.phase === 'APA' ? 'selected' : 'default'"
                        :disabled="!isAPAAllowed"
                    >
                        {{ $t('commons.phases.APA') }}
                    </app-button>
                </div>
            </div>
            <div class="flex flex-col col-span-2 mt-4" v-if="isAdmin || isReporter">
                <app-label :label="$t('observations.observationOn')"></app-label>
                <div class="flex col-span-2">
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onType('onDoneWork')"
                        :variant="observation.type === 'onDoneWork' ? 'selected' : 'default'"
                    >
                        {{ $t('observations.types.onDoneWorkShort') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onType('onTodoWork')"
                        :variant="observation.type === 'onTodoWork' ? 'selected' : 'default'"
                    >
                        {{ $t('observations.types.onTodoWorkShort') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onType('other')"
                        :variant="observation.type === 'other' ? 'selected' : 'default'"
                    >
                        {{ $t('observations.types.other') }}
                    </app-button>
                    <app-button
                        class="px-3"
                        size="mini"
                        @click="onType('private')"
                        :variant="observation.type === 'private' ? 'selected' : 'default'"
                    >
                        {{ $t('observations.types.private') }}
                    </app-button>
                </div>
            </div>
            <div class="flex flex-col col-span-2 mt-4" v-if="isAdmin || isReporter">
                <app-date-link
                    :label="$t('commons.expectedOn')"
                    v-model="observation.dueDate"
                    @input="onDueDate"
                ></app-date-link>
            </div>
            <app-select
                class="col-span-2 mt-4"
                :label="$t('observations.room')"
                :value="observation.roomId"
                @input="onSelectRoom"
                v-if="observation.roomId && (isAdmin || isReporter)"
            >
                <option></option>
                <template v-for="room in zone.rooms">
                    <option :value="room.id">{{ room.name }}</option>
                </template>
            </app-select>
            <div class="mt-4 flex-col flex gap-4">
                <app-button
                    :disabled="observation.recipientIds.length === 0 || !observation.title || isInCheckList"
                    size="mini"
                    @click="onRecurrent"
                    :label="$t('observations.recurrent')"
                    v-if="isCheckListAllowed"
                ></app-button>
                <app-button
                    size="mini"
                    @click="removeFromMap"
                    :label="$t('observations.removeFromMap')"
                    v-if="observation.footprint && (isAdmin || isReporter)"
                ></app-button>
            </div>
            <app-fieldset :label="$t('project.follow.observation.attachments')" class="col-span-2 mt-4">
                <div class="col-span-2 flex">
                    <app-multiple-upload-button
                        ref="fileInput"
                        icon="icon-paperclip"
                        size="mini"
                        :label="$t('observations.newAttachment')"
                        :end-point="`/api/projects/${$route.params.projectId}/observations/${observation.id}/attachment`"
                        :resize="1024"
                        :name="$t('project.follow.weatherIssue.newAttachment')"
                        class="text-xs offline:hidden"
                        @loaded="onNewAttachment"
                    />
                    <app-offline-upload-button
                        ref="fileInput"
                        icon="icon-paperclip"
                        size="mini"
                        :label="$t('observations.newAttachment')"
                        class="text-xs hidden offline:block"
                        @select="onNewOfflineAttachment"
                    />
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 col-span-2 mt-4">
                    <template v-for="photo in observation.attachments">
                        <div class="flex justify-around p-2 border m-1">
                            <div
                                :style="'background-image: url(\'' + (photo.url || photo.dataUrl) + '\');'"
                                @click="goToFullScreenPhoto(photo)"
                                class="cursor-pointer flex-grow"
                                style="
                                    height: 200px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: contain;
                                "
                            ></div>
                            <div class="flex items-start">
                                <app-button
                                    size="mini"
                                    variant="danger"
                                    aria-label="remove photo"
                                    icon="icon-trash-can-outline"
                                    @click="onRemoveAttachment(photo)"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </app-fieldset>
            <app-fieldset :label="$t('commons.status')" class="col-span-2 mt-4">
                <observation-status
                    class="col-span-2 flex flex-col gap-4"
                    :observation="observation"
                    @input="updateObservation($event)"
                    @remove="deleteObservation()"
                ></observation-status>
            </app-fieldset>
        </form>
    </ValidationObserver>
</template>

<script>
import { getObservations, removeObservation, updateObservation } from '@/features/observations/observation.service';
import AppLabel from '@/components/appLabel/AppLabel';
import AppButton from '@/components/appButton/AppButton';
import AppSelect from '@/components/appSelect/AppSelect';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import { confirm } from '@/features/dialogs/dialogs.service';
import { createCheckListItem, getCheckListItems } from '@/features/checkLists/checkLists.service';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppBundle from '@/components/app-bundle/appBundle';
import { queryProject } from '@/features/projects/projects.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { getBundles } from '@/features/bundles/bundles.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import {
    createObservationAttachment,
    getObservationAttachments,
    removeObservationAttachment,
} from '@/features/observations/observationAttachments.service';
import { combineLatest } from 'rxjs';
import { getMapById } from '@/services/sanitize.service';
import AppOfflineUploadButton from '@/components/app-offlineUploadButton/AppOfflineUploadButton';
import AppTips from '@/components/app-tips/AppTips';
import ObservationStatus from '@/features/observations/ObservationStatus';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import ObservationStatusButtons from '@/features/observations/ObservationStatusButtons.vue';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';

export default {
    props: ['observationId'],
    components: {
        AppDateLink,
        ObservationStatusButtons,
        AppFieldset,
        ObservationStatus,
        AppTips,
        AppOfflineUploadButton,
        AppMultipleUploadButton,
        AppBundle,
        AppMultiPicker,
        AppTextEditor,
        AppDateInput,
        AppUploadButton,
        AppSelect,
        AppButton,
        AppLabel,
    },
    watch: {
        observationId(observationId) {
            this.refresh(observationId);
        },
    },
    computed: {
        isInCheckList() {
            return !!this.checkList.find(
                (checkListItem) =>
                    checkListItem.title === this.observation.title &&
                    checkListItem.type === this.observation.type &&
                    checkListItem.recipientIds.every((recipientId) =>
                        this.observation.recipientIds.includes(recipientId),
                    ),
            );
        },
        isReporter() {
            return !!this.myBundleIds.includes(this.observation.reportedBy);
        },
    },
    created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.isMOE = project.me.allowedFeatures.includes('project_observations_MOEValidation');
            this.allowedFeatures = project.me.allowedFeatures;
            this.myBundleIds = project.me.bundleIds;
            this.isCheckListAllowed = project.me.allowedFeatures.includes('project_observations_checkList');
            this.isEXEAllowed = project.projectFeatures.includes('project_EXE');
            this.isOPRAllowed = project.projectFeatures.includes('project_OPR');
            this.isOPLAllowed = project.projectFeatures.includes('project_OPL');
            this.isAPAAllowed = project.projectFeatures.includes('project_OPL');
        });
        this.subscriptions = [
            getLocationsTree(this.$route.params.projectId).subscribe(
                (folders) => (this.zone = locationService.getLocationMap(folders)[this.$route.params.zoneId]),
            ),
            getCheckListItems(this.$route.params.projectId).subscribe((checkList) => {
                this.checkList = checkList;
            }),
            combineLatest([
                getObservations(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getObservationAttachments(this.$route.params.projectId),
            ]).subscribe(([observations, bundles, attachments]) => {
                const bundleMap = getMapById(bundles);
                this.observations = observations.map((observation) => ({
                    ...observation,
                    attachments: attachments.filter((attachment) => attachment.observationId === observation.id),
                    recipients: observation.recipientIds.map((id) => bundleMap[id]),
                    validator: bundleMap[observation.validatedBy],
                    reporter: bundleMap[observation.reportedBy],
                    obsoleter: bundleMap[observation.obsoleteBy],
                    resolver: bundleMap[observation.resolvedBy],
                }));
                this.bundles = bundles;
                this.refresh(this.observationId);
            }),
        ];
    },
    methods: {
        onNewAttachment(attachment) {
            createObservationAttachment(this.$route.params.projectId, attachment);
        },
        onNewOfflineAttachment({ dataUrl }) {
            createObservationAttachment(this.$route.params.projectId, { dataUrl, observationId: this.observation.id });
        },
        refresh(observationId) {
            this.observation = this.observations.find((observation) => observation.id === observationId) || {
                recipientIds: [],
            };
        },
        updateRecipients() {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                recipientIds: this.observation.recipients.map((bundle) => bundle.id),
            });
        },
        async onRecurrent() {
            await createCheckListItem(this.$route.params.projectId, {
                title: this.observation.title,
                type: this.observation.type,
                recipientIds: this.observation.recipientIds,
            });
        },
        removeFromMap() {
            updateObservation(this.$route.params.projectId, { id: this.observation.id, footprint: null, page: null });
        },
        updateObservation(observation) {
            updateObservation(this.$route.params.projectId, {
                ...observation,
                title: this.observation.title,
                /* workaround for blur Quilljs bug.
                            When you change title content and directly click on a new status,
                             the observation title is not saved : just reverted
                             */
            });
        },
        async deleteObservation() {
            if (await confirm(this.$t('observations.remove'))) {
                await removeObservation(this.$route.params.projectId, this.observation.id);
                this.$emit('deleted');
            }
        },
        async goToFullScreenPhoto(attachment) {
            this.$emit('openPhoto', attachment);
        },
        onRemoveAttachment(attachment) {
            return removeObservationAttachment(this.$route.params.projectId, attachment.id);
        },
        async onSelectRoom(roomId) {
            await updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                roomId,
            });
            this.$emit('roomPicked', { roomId, zoneId: this.observation.zoneId });
        },
        onTitle() {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                title: this.observation.title,
            });
        },
        onPhase(phase) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                phase,
            });
        },
        onDueDate(dueDate) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                dueDate,
            });
        },
        onType(type) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                type,
            });
        },
    },
    data() {
        return {
            isEXEAllowed: false,
            isAdmin: false,
            isMOE: false,
            isOPRAllowed: false,
            isAPAAllowed: false,
            isOPLAllowed: false,
            isCheckListAllowed: false,
            zone: {},
            checkList: [],
            observation: { recipientIds: [] },
            resolveDate: new Date(),
            allowedFeatures: [],
            subscriptions: [],
            myBundleIds: [],
            bundles: [],
        };
    },
};
</script>
