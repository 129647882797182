<template>
    <ul class="text-sm text-red-700 font-semibold">
        <li v-for="error in errors">{{ error }}</li>
    </ul>
</template>
<script>
export default {
    props: ['errors'],
    name: 'app-errors',
};
</script>
