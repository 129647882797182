import db from '@/rxdb/database';
import { map } from 'rxjs';

export async function createPlanningExport(projectId, planningExport) {
    const result = await db.getProjectCollections(projectId).planningExports.insert({
        ...planningExport,
        collapsed: planningExport.collapsed ? JSON.parse(JSON.stringify(planningExport.collapsed)) : [],
        filter: planningExport.filter ? JSON.parse(JSON.stringify(planningExport.filter)) : [],
    });
    return result.toJSON();
}

export function getLastPlanningExport(projectId) {
    return db
        .getProjectCollections(projectId)
        .planningExports.findOne({ selector: {}, sort: [{ createdAt: 'desc' }] })
        .$.pipe(map((planningExport) => mapPlanningExport(planningExport)));
}
export function queryLastPlanningExport(projectId) {
    return db
        .getProjectCollections(projectId)
        .planningExports.findOne({ selector: { projectId }, sort: [{ createdAt: 'desc' }] })
        .exec()
        .then((item) => mapPlanningExport(item));
}
export function getPlanningExports(projectId) {
    return db
        .getProjectCollections(projectId)
        .planningExports.find({ selector: {}, sort: [{ createdAt: 'desc' }] })
        .$.pipe(map((entities) => entities.map(mapPlanningExport)));
}
export function removePlanningExports(projectId, ids) {
    return db.getProjectCollections(projectId).planningExports.bulkRemove(ids);
}

function mapPlanningExport(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              createdAt: item.createdAt ? new Date(item.createdAt) : null,
              minDate: item.minDate ? new Date(item.minDate) : null,
              maxDate: item.maxDate ? new Date(item.maxDate) : null,
              refDate: item.refDate ? new Date(item.refDate) : null,
          }
        : null;
}
