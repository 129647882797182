import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const preparationsReports = {
    schema: {
        title: 'preparationsReport schema',
        version: 1,
        description: 'describes a preparationsReport',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'] },
            name: { type: ['string', 'null'] },
            phase: { type: ['string', 'null'] },
            url: { type: ['string', 'null'] },
            showToEmit: { type: ['boolean', 'null'] },
            showToVisa: { type: ['boolean', 'null'] },
            showApproved: { type: ['boolean', 'null'] },
            showApprovedWithComments: { type: ['boolean', 'null'] },
            showRejected: { type: ['boolean', 'null'] },
            showVised: { type: ['boolean', 'null'] },
            showLocations: { type: ['boolean', 'null'] },
            showAllVersions: { type: ['boolean', 'null'] },
            generationSucceed: { type: ['boolean', 'null'] },
            generationStartDate: { type: ['string', 'null'] },
            generationEndDate: { type: ['string', 'null'] },
            date: { type: ['string', 'null'], format: 'date-time' },
            locationIds: { type: ['array', 'null'], items: { type: 'string' } },
            bundleIds: { type: ['array', 'null'], items: { type: 'string' } },
            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'], format: 'uuid' },
            updatedBy: { type: ['string', 'null'], format: 'uuid' },
            deletedBy: { type: ['string', 'null'], format: 'uuid' },
            rootVersion: { type: ['string', 'null'], format: 'date-time' },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: { 1: (doc) => doc },
};
